import useLifeStyleService from "@dating/ui/pages/register/lifeStyle/service/useLifeStyleService";
import LifeStyleContentsView from "./views/LifeStyleContentsView";
import LifeStyleFooterView from "./views/LifeStyleFooterView";
import LifeStyleHeaderView from "./views/LifeStyleHeaderView";
import LifeStylePopupView from "./views/LifeStylePopupView";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const LifeStyleContainer = () => {
    const {
        fnSaveUserStyle,
        isPopOn,
        popOnHandler,
        interestList,
        interestHandler,
        lifeStyleList,
        lifeStyleHandler,
        popOnSaveHandler,
        customInterestList,
        customInterestValue,
        customInterestHandler,
        customLifeStyleList,
        customLifeStyleValue,
        customLifeStyleHandler,
        customVal,
        setCustomVal,
        statusSaveUserStyle,
        dataSaveUserStyle,
        drinkingValue,
        smokingValue,
        religionValue,
        initReligion,
        initDrinkingState,
        initSmokingState,
        setSmokingValue,
        setReligionValue,
        setDrinkingValue,
        userWaiting,
        lifeStyleValue,
        interestValue,
    } = useLifeStyleService();

    const navigate = useNavigate();
    useEffect(() => {
        // 라이프스타일 저장 후 가입승인상태 다시 요청해서 true면 시작하기, false 면 waiting
        if (statusSaveUserStyle === "success" && dataSaveUserStyle) {
            navigate("/metaverse/dating/register/waiting");
        }
    }, [statusSaveUserStyle, dataSaveUserStyle]);

    return (
        <div className="wrap noFooter ">
            <LifeStyleHeaderView/>
            <LifeStyleContentsView
                popOnHandler={popOnHandler}
                interestList={interestList}
                interestHandler={interestHandler}
                lifeStyleList={lifeStyleList}
                lifeStyleHandler={lifeStyleHandler}
                customInterestList={customInterestList}
                customInterestValue={customInterestValue}
                customInterestHandler={customInterestHandler}
                customLifeStyleList={customLifeStyleList}
                customLifeStyleValue={customLifeStyleValue}
                customLifeStyleHandler={customLifeStyleHandler}
                drinkingValue={drinkingValue}
                smokingValue={smokingValue}
                religionValue={religionValue}
                initReligion={initReligion}
                initDrinkingState={initDrinkingState}
                initSmokingState={initSmokingState}
                setSmokingValue={setSmokingValue}
                setReligionValue={setReligionValue}
                setDrinkingValue={setDrinkingValue}
                userWaiting={userWaiting}
                lifeStyleValue={lifeStyleValue}
                interestValue={interestValue}
            />
            <LifeStyleFooterView
                fnSaveUserStyle={fnSaveUserStyle}
            />

            {/*나만의 항목 추가 팝업*/}
            <LifeStylePopupView
                isPopOn={isPopOn}
                popOnHandler={popOnHandler}
                popOnSaveHandler={popOnSaveHandler}
                customVal={customVal}
                setCustomVal={setCustomVal}
            />
        </div>
    )
};

export default LifeStyleContainer;