import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';

export const Policy01Container = () => {
	return (
		<div className="rightShow active">
			<ButtonHeaderView right={false} />

			<div className="popConWrap bdNone">
				<div className="inner">
					<div className="agreeWrap">
						<div className="titleWrap">
							<h2 className="title01">서비스 이용약관</h2>
							<p className="titleSub01">
								<i>시행일자 : 2024.10.31 ~ 시행</i>
							</p>
						</div>

						<div className="section section01">
							<h3 className="title02 margin-t40">1. 목적</h3>
							<p>
								이 약관은 주식회사 베리드 코리아 (이하 '회사' 라고 합니다)가 제공하는 제반 서비스의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타
								필요한 사항을 규정함을 목적으로 합니다.
							</p>
						</div>

						<div className="section section02">
							<h3 className="title02 margin-t40">2. 정의</h3>
							<p>
								이 약관에서 사용하는 주요 용어의 정의는 다음과 같습니다.
							</p>
							<ul className="num">
								<li>'서비스'라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 '이용자'가 이용할 수 있는 회사가 제공하는
									제반 서비스를 의미합니다.
								</li>
								<li>'이용자'란 이 약관에 따라 회사가 제공하는 서비스를 받는 '개인회원', '기업회원' 및 '비회원'을 말합니다.</li>
								<li>'개인회원'은 회사에 개인정보를 제공하여 회원등록을 한 사람으로, 회사로부터 지속적으로 정보를 제공받고 '회사'가 제공하는 서비스를 계속적으로 이용할
									수 있는 자를 말합니다.
								</li>
								<li>'기업회원'은 회사에 기업정보 및 개인정보를 제공하여 회원등록을 한 사람으로, 회사로부터 지속적으로 정보를 제공받고 회사가 제공하는 서비스를 계속적으로
									이용할 수 있는 자를 말합니다.
								</li>
								<li>'비회원'은 회원가입 없이 회사가 제공하는 서비스를 이용하는 자를 말합니다.</li>
								<li>'아이디(ID)'라 함은 회원의 식별과 서비스 이용을 위하여 회원이 정하고 회사가 승인하는 문자 또는 문자와 숫자의 조합을 의미합니다.</li>
								<li>'비밀번호'라 함은 회원이 부여받은 아이디와 일치되는 회원임을 확인하고 비밀의 보호를 위해 회원 자신이 정한 문자(특수문자 포함)와 숫자의 조합을
									의미합니다.
								</li>
								<li>'유료서비스'라 함은 회사가 유료로 제공하는 제반 서비스를 의미합니다.</li>
								<li>'결제'란 회사가 제공하는 유료서비스를 이용하기 위하여 회원이 지불수단을 선택하고, 금융정보를 입력하는 행위를 말합니다.</li>
								<li>'할인쿠폰'은 이용자가 회사의 서비스를 이용하면서 그 대가를 지급하는데 사용하기 위하여 회사가 발행 및 관리하는 지급수단을 말합니다.</li>
								<li>'베리드'(은)는 회사가 제공하는 유료서비스를 이용하기 위해 구매하는 인터넷 상의 결제수단으로 현금 100원당 베리드 1개의 비율로 환산되며, 회원의
									아이디(ID)를 기준으로 충전, 결제, 환불 등이 가능합니다.
								</li>
								<li>'콘텐츠'란 정보통신망법의 규정에 따라 정보통신망에서 사용되는 부호 ·문자·음성·음향·이미지 또는 영상 등으로 정보 형태의 글, 사진, 동영상 및 각종
									파일과 링크 등을 말합니다.
								</li>
							</ul>
						</div>

						<div className="section section03">
							<h3 className="title02 margin-t40">3. 약관 외 준칙</h3>
							<p>이 약관에서 정하지 아니한 사항에 대해서는 법령 또는 회사가 정한 서비스의 개별약관, 운영정책 및 규칙 등(이하 세부지침)의 규정에 따릅니다. 또한 본 약관과
								세부지침이 충돌할 경우에는 세부지침에 따릅니다.</p>
						</div>

						<div className="section section04">
							<h3 className="title02 margin-t40">4. 약관의 효력과 변경</h3>
							<ul className="num">
								<li>이 약관은 주식회사 베리드 코리아(이)가 제공하는 모든 인터넷서비스에 게시하여 공시합니다. 회사는 '전자상거래등에서의 소비자보호에 관한 법률(이하
									'전자상거래법'이라 함)', '약관의 규제에 관한 법률(이하' 약관규제법'이라 함)', '정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하
									'정보통신망법'이라 함)' 등 본 서비스와 관련된 법령에 위배되지 않는 범위에서 이 약관을 변경할 수 있으며, 회사는 약관이 변경되는 경우에 변경된
									약관의 내용과 시행일을 정하여, 그 시행일로부터 최소 7일 (이용자에게 불리하거나 중대한 사항의 변경은 30일) 이전부터 시행일 후 상당한 기간 동안
									공지하고, 기존 이용자에게는 변경된 약관, 적용일자 및 변경사유(변경될 내용 중 중요사항에 대한 설명을 포함)를 별도의 전자적 수단(전자우편,
									문자메시지, 서비스 내 전자쪽지발송, 알림 메시지를 띄우는 등의 방법)으로 개별 통지합니다. 변경된 약관은 공지하거나 통지한 시행일로부터 효력이
									발생합니다.
								</li>
								<li>회사가 제1항에 따라 개정약관을 공지 또는 통지하는 경우 '변경에 동의하지 아니한 경우 공지일 또는 통지를 받은 날로부터 7일(이용자에게 불리하거나
									중대한 사항의 변경인 경우에는 30일) 내에 계약을 해지할 수 있으며, 계약해지의 의사표시를 하지 아니한 경우에는 변경에 동의한 것으로 본다.' 라는
									취지의 내용을 함께 통지합니다.
								</li>
								<li>이용자가 제2항의 공지일 또는 통지를 받은 날로부터 7일(또는 이용자에게 불리하거나 중대한 사항의 변경인 경우에는 30일)내에 변경된 약관에 대해
									거절의 의사를 표시하지 않았을 때에는 본 약관의 변경에 동의한 것으로 간주합니다.
								</li>
							</ul>
						</div>

						<div className="section section05">
							<h3 className="title02 margin-t40">5. 이용자에 대한 통지</h3>
							<ul className="num">
								<li>회사는 이 약관에 별도 규정이 없는 한 이용자에게 전자우편, 문자메시지(SMS), 전자쪽지, 푸쉬(Push)알림 등의 전자적 수단을 이용하여 통지할 수
									있습니다.
								</li>
								<li>회사는 이용자 전체에 대한 통지의 경우 7일 이상 회사가 운영하는 웹사이트 내의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 다만,
									이용자 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 제1항의 개별 통지를 합니다.
								</li>
								<li>회사는 이용자의 연락처 미기재, 변경 후 미수정, 오기재 등으로 인하여 개별 통지가 어려운 경우에 한하여 전항의 공지를 함으로써 개별 통지를 한 것으로
									간주합니다.
								</li>
							</ul>
						</div>

						<div className="section section06">
							<h3 className="title02 margin-t40">6. 이용계약의 체결</h3>
							<p>이용계약은 다음의 경우에 체결됩니다.</p>
							<ul className="num">
								<li>이용자가 회원으로 가입하고자 하는 경우 이용자가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 회사가 이러한 신청에 대하여 승낙한 때
								</li>
								<li>이용자가 회원 가입 없이 이용할 수 있는 서비스에 대하여 회원 가입의 신청없이 서비스를 이용하고자 하는 경우에는 회사 서비스 이용을 위해 결제하는
									때
								</li>
								<li>이용자가 회원가입 없이 이용할 수 있는 서비스에 대하여 회원가입의 신청없이 무료 서비스를 이용하고자 하는 경우에는 그 무료 서비스와 관련된 사항의 저장
									등 부가서비스를 이용하면서 위 1호 및 2호의 절차를 진행한 때
								</li>
							</ul>
						</div>

						<div className="section section07">
							<h3 className="title02 margin-t40">7. 회원가입에 대한 승낙</h3>
							<ul className="num">
								<li>회사는 이용계약에 대한 요청이 있을 때 서비스 이용을 승낙함을 원칙으로 합니다.</li>
								<li>전항에도 불구하고, 다음 각호의 사유에 해당하는 경우 회사는 회원가입을 보류하거나 거절하는 등 제한할 수 있습니다.
									<ul className="abc">
										<li>가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우(단, 회사의 재가입 승낙을 얻은 경우에는 예외로 함)</li>
										<li>실명이 아니거나 타인의 명의를 도용한 경우</li>
										<li>회사가 정하는 필수정보를 누락하거나 허위로 기재한 경우</li>
										<li>만 14세 미만의 아동, 만 19세 미만의 미성년자, 피한정후견인, 피성년후견인이 법정대리인의 동의를 얻지 않은 경우</li>
										<li>이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 이 약관 등 회사가 규정한 운영원칙을 위반한 경우</li>
										<li>신용정보의 이용과 보호에 관한 법률에 따라 PC통신, 인터넷서비스의 신용불량자로 등록되어 있는 경우</li>
										<li>정보통신윤리위원회에 PC통신, 인터넷서비스의 불량이용자로 등록되어 있는 경우</li>
										<li>이미 사용 중인 회원정보 또는 공서양속을 저해하는 아이디를 사용하고자 하는 경우</li>
									</ul>
								</li>
								<li>제1항에 따른 신청에 있어 회사는 서비스 제공에 필요한 경우 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.</li>
								<li>회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.</li>
								<li>제2항과 제4항에 따라 서비스 이용을 승낙하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를 서비스 이용 신청자에게 알리도록 합니다. 단, 회사의
									귀책사유 없이 이용자에게 알릴 수 없는 경우에는 예외로 합니다.
								</li>
								<li>이용계약의 성립 시기는 제6조 제1호의 경우에는 회사가 가입완료를 신청절차 상에서 표시한 시점, 제6조 제2호의 경우에는 결제가 완료되었다는 표시가 된
									시점으로 합니다.
								</li>
								<li>회사는 회원에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.</li>
								<li>회사는 회원에 대하여 '영화및비디오물의진흥에관한법률' 및 '청소년보호법' 등에 따른 등급 및 연령 준수를 위하여 이용제한이나 등급별 제한을 둘 수
									있습니다.
								</li>
							</ul>
						</div>

						<div className="section section08">
							<h3 className="title02 margin-t40">8. 회원가입에 대한 승낙</h3>
							<ul className="num">
								<li>회원은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 아이디 등은 수정이
									불가능합니다.
								</li>
								<li>회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.
								</li>
								<li>제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여는 회원에게 책임이 있습니다.</li>
							</ul>
						</div>

						<div className="section section09">
							<h3 className="title02 margin-t40">9. 회원정보의 관리 및 보호</h3>
							<ul className="num">
								<li>회원의 아이디(ID)와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.</li>
								<li>회사는 회원의 아이디(ID)가 개인정보 유출 우려가 있거나, 반사회적 또는 공서양속에 어긋나거나, 회사 또는 서비스의 운영자로 오인할 우려가 있는
									경우, 해당 아이디(ID)의 이용을 제한할 수 있습니다.
								</li>
								<li>회원은 아이디(ID) 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 안내에 따라야 합니다.</li>
								<li>제3항의 경우 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지하였으나 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지
									않습니다.
								</li>
							</ul>
						</div>

						<div className="section section10">
							<h3 className="title02 margin-t40">10. 회사의 의무</h3>
							<ul className="num">
								<li>회사는 계속적이고 안정적인 서비스의 제공을 위하여 설비에 장애가 생기거나 멸실된 때에는 이를 지체 없이 수리 또는 복구하며, 다음 각 호의 사유 발생
									시 부득이한 경우 예고 없이 서비스의 전부 또는 일부의 제공을 일시 중지할 수 있습니다. 이 경우 그 사유 및 중지 기간 등을 이용자에게 지체 없이
									사후 공지합니다.
									<ul className="abc">
										<li>시스템의 긴급점검, 증설, 교체, 시설의 보수 또는 공사를 하기 위하여 필요한 경우</li>
										<li>새로운 서비스를 제공하기 위하여 시스템교체가 필요하다고 판단되는 경우</li>
										<li>시스템 또는 기타 서비스 설비의 장애, 유무선 Network 장애 등으로 정상적인 서비스 제공이 불가능할 경우</li>
										<li>국가비상사태, 정전, 불가항력적 사유로 인한 경우</li>
									</ul>
								</li>
								<li>회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약관련 절차 및 내용 등에 있어 이용자에게 편의를 제공하도록 노력합니다.</li>
								<li>회사는 대표자의 성명, 상호, 주소, 전화번호, 모사전송번호(FAX), 통신판매업 신고번호, 이용약관, 개인정보취급방침 등을 이용자가 쉽게 알 수
									있도록 온라인 서비스 초기화면에 게시합니다.
								</li>
							</ul>
						</div>

						<div className="section section11">
							<h3 className="title02 margin-t40">11. 개인정보보호</h3>
							<ul className="num">
								<li>회사는 이용자들의 개인정보를 중요시하며, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련 법규를 준수하기 위해 노력합니다.
									회사는 개인정보보호정책을 통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지
									알려드립니다.
								</li>
								<li>회사는 최종 사용일로부터 연속하여 1년 동안 서비스 사용 이력이 없는 경우 '개인정보보호법' 및 같은 법 시행령의 규정에 따라 이용자정보를 다른
									이용자의 개인정보와 분리하여 별도로 저장 및 관리할 수 있습니다. 이때 분리 저장된 이용자의 개인정보는 이용자가 회원탈퇴신청 또는 개인정보삭제 요청을
									할때까지 보관됩니다.
								</li>
								<li>회사가 이용자의 개인정보의 보호 및 사용에 대해서 관련 법규 및 회사의 개인정보처리방침을 적용합니다. 다만, 회사에서 운영하는 웹 사이트 등에서 링크된
									외부 웹페이지에서는 회사의 개인정보처리방침이 적용되지 않습니다.
								</li>
							</ul>
						</div>

						<div className="section section12">
							<h3 className="title02 margin-t40">12. 이용자의 의무</h3>
							<ul className="num">
								<li>이용자는 이용자가입을 통해 이용신청을 하는 경우 사실에 근거하여 신청서를 작성해야 합니다. 이용자가 허위, 또는 타인의 정보를 등록한 경우 회사에
									대하여 일체의 권리를 주장할 수 없으며, 회사는 이로 인하여 발생한 손해에 대하여 책임을 부담하지 않습니다.
								</li>
								<li>이용자는 본 약관에서 규정하는 사항과 기타 회사가 정한 제반 규정, 회사가 공지하는 사항을 준수하여야 합니다. 또한 이용자는 회사의 업무를 방해하는
									행위 및 회사의 명예를 훼손하는 행위를 하여서는 안 됩니다.
								</li>
								<li>이용자는 주소, 연락처, 전자우편 주소 등 회원정보가 변경된 경우 즉시 온라인을 통해 이를 수정해야 합니다. 이 때 변경된 정보를 수정하지 않거나
									수정이 지연되어 발생하는 책임은 이용자가 지게 됩니다.
								</li>
								<li>이용자는 이용자에게 부여된 아이디와 비밀번호를 직접 관리해야 합니다. 이용자의 관리 소홀로 발생한 문제는 회사가 책임을 부담하지 않습니다.</li>
								<li>이용자가 아이디, 닉네임, 기타 서비스 내에서 사용되는 명칭 등을 선정할 때에는 다음 각 호에 해당하는 행위를 해서는 안 됩니다.
									<ul className="ABC">
										<li>회사가 제공하는 서비스의 공식 운영자를 XXX 칭하거나 이와 유사한 명칭을 사용하여 다른 이용자에게 혼란을 주는 행위</li>
										<li>선정적이고 음란한 내용이 포함된 명칭을 사용하는 행위 3. 제3자의 상표권, 저작권 등 권리를 침해할 가능성이 있는 명칭을 사용하는
											행위
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};