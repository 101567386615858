type Listener<T extends Array<any>> = (...args: T) => void;

export class MyEventEmitter<EventMap extends Record<string, Array<any>>> {
	private eventListeners: {
		[K in keyof EventMap]?: Set<Listener<EventMap[K]>>;
	} = {};

	// 이벤트 리스너 등록 메소드
	// eventName: 이벤트 이름
	// listener: 이벤트 핸들러 함수
	// context: 이벤트 핸들러 함수 실행 시의 컨텍스트
	on<K extends keyof EventMap>(
		eventName: K,
		listener: Listener<EventMap[K]>,
		context: any = null,
	) {
		const listeners = this.eventListeners[eventName] ?? new Set();
		listeners.add(context ? listener.bind(context) : listener);
		this.eventListeners[eventName] = listeners;
	}

	// 이벤트 발생 메소드
	// eventName: 이벤트 이름
	// args: 이벤트 핸들러 함수에 전달할 인자
	emit<K extends keyof EventMap>(eventName: K, ...args: EventMap[K]) {
		const listeners = this.eventListeners[eventName] ?? new Set();
		listeners.forEach((listener) => listener(...args));
	}

	// 이벤트 리스너 삭제 메소드
	// eventName: 이벤트 이름
	// listener: 삭제할 이벤트 핸들러 함수
	off<K extends keyof EventMap>(eventName: K, listener: Listener<EventMap[K]>) {
		const listeners = this.eventListeners[eventName] ?? new Set();
		listeners.delete(listener);
	}

	// 모든 이벤트 리스너 삭제 메소드
	// eventName: 이벤트 이름
	offAll<K extends keyof EventMap>(eventName: K) {
		this.eventListeners[eventName] = new Set();
	}

	// 모든 이벤트 리스너 삭제 및 MyEventEmitter 객체 삭제 메소드
	shutdown() {
		this.eventListeners = {};
	}
}
