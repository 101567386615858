import FeedProfBoxView from './FeedProfBoxView';
import {PostInfoCOVO, UserProfileCOVO} from '@/site/api';

interface props {
    post: PostInfoCOVO;
    menuDotClickHandler: () => void;
    setOpenReport: (openReport: boolean) => void;
    setOpenFeedHidePop: (openFeedHidePop: boolean) => void;
    commentListHandler: (
        postId: string,
        postUser: { userUid: string; nickname: string },
    ) => void;
    commentViewHandler: (state: boolean) => void;
    deleteContent: (id: string) => void;
    myProfile: UserProfileCOVO;
    openMenuPop: boolean;
    srcPrefix: string;
    commentCount: number | undefined;
    setUserProfileOpen: (userProfileOpen: string) => void;
    setBlockUserUid: (blockUserUid: string) => void;
    currentUserUid: string;
    setModifyPostId: (postId: string) => void;
    setMyProfileOpen: (myProfileOpen: boolean) => void;
    fnCommonConfirmPopOn: (message: string, func: any, param: any) => any;
    setBlockPostId: (blockPostId: string) => void;
    fnFeedEditHandler: (postId: string) => void;
    feedEditPops: {};
}

const FeedContentView = ({
                             post,
                             menuDotClickHandler,
                             setOpenReport,
                             setOpenFeedHidePop,
                             commentListHandler,
                             deleteContent,
                             myProfile,
                             openMenuPop,
                             srcPrefix,
                             commentCount,
                             commentViewHandler,
                             setUserProfileOpen,
                             setBlockUserUid,
                             currentUserUid,
                             setModifyPostId,
                             setMyProfileOpen,
                             fnCommonConfirmPopOn,
                             setBlockPostId,
                             fnFeedEditHandler,
                             feedEditPops,
                         }: props) => {
    return (
        <div className="inner padding-t8">
            <FeedProfBoxView
                key={post.postId}
                menuDotClickHandler={menuDotClickHandler}
                setOpenReport={setOpenReport}
                setOpenFeedHidePop={setOpenFeedHidePop}
                post={post}
                deleteContent={deleteContent}
                openMenuPop={openMenuPop}
                srcPrefix={srcPrefix}
                setUserProfileOpen={setUserProfileOpen}
                setBlockUserUid={setBlockUserUid}
                currentUserUid={currentUserUid}
                setModifyPostId={setModifyPostId}
                setMyProfileOpen={setMyProfileOpen}
                fnCommonConfirmPopOn={fnCommonConfirmPopOn}
                setBlockPostId={setBlockPostId}
                fnFeedEditHandler={fnFeedEditHandler}
                feedEditPops={feedEditPops}
            />

            <div className="protxt01">
                {/*내용*/}
                <p className="fz14 color-666">{post.content}</p>

                {/*태그*/}
                <div className="tagList">
                    {post.tag?.map((item, i) => {
                        return (
                            <a key={item + i} href="#n">
                                {item}
                            </a>
                        );
                    })}
                </div>

                {/*코멘트*/}
                <div className="commentWrap">
                    {commentCount && commentCount > 0 ? (
                        <button
                            type="button"
                            className="fz12 color-666 commentAdd"
                            onClick={() => {
                                commentListHandler(post.postId, {
                                    userUid: post.postUserUid,
                                    nickname: post.postNickName,
                                });
                                commentViewHandler(true);
                            }}
                        >
                            댓글 {commentCount}개 모두 보기
                        </button>
                    ) : null}
                    <div className="commentPro margin-t8">
                        {/*<Link to="/metaverse/dating/profile">*/}
                        <div className="proImg Sm">
                            <img
                                src={
                                    myProfile && myProfile?.profileUrl[0]
                                        ? srcPrefix + myProfile?.profileUrl[0]
                                        : '/assets/img/test/Rectangle93.jpg'
                                }
                                alt="테스트이미지"
                            />
                        </div>
                        {/*</Link>*/}
                        <button
                            type="button"
                            className="commentAdd"
                            onClick={() => {
                                commentListHandler(post.postId, {
                                    userUid: post.postUserUid,
                                    nickname: post.postNickName,
                                });
                                commentViewHandler(true);
                            }}
                        >
                            <p className="fz14 color-666">댓글 추가...</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeedContentView;
