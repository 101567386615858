import { CSSTransition } from 'react-transition-group';
import LikeConfirmView from './views/LikeConfirmView';
import OpenMessagePopView from './views/OpenMessagePopView';
import PaymentPopView from './views/PaymentPopView';
import MessagePopView from './views/MessagePopView';
import { usePaymentService } from '@dating/ui/components/payment/service/usePaymentService';
import { useDirectMessageService } from '@/ui/components/dm/service/useDirectMessageService';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentContainer = () => {
	const {
		fnSaveUserLike,
		openPaymentPop,
		openPaymentPopUp,
		openMessageConfirm,
		openLikeConfirm,
		setOpenPaymentPop,
		setOpenPaymentPopUp,
		setOpenMessageConfirm,
		setOpenLikeConfirm,
		srcPrefix,
		activeItem,
		fnSendLikePopClose,
		messageTargetUser,
	} = usePaymentService();

	const { messageInputValue, handleChange, handlePaymentClick } =
		useDirectMessageService();

	return (
		<div
			className={`${
				openPaymentPop ||
				openPaymentPopUp ||
				openMessageConfirm ||
				openLikeConfirm
					? 'paymentPopOn'
					: 'paymentPop'
			}`}
		>
			<div
				className="paymentDim"
				onClick={() => {
					setOpenPaymentPop(false);
					setOpenPaymentPopUp(false);
					setOpenMessageConfirm(false);
					setOpenLikeConfirm(false);
				}}
			/>
			<CSSTransition
				in={openPaymentPop}
				classNames="messageWrap-transition"
				timeout={100}
			>
				<MessagePopView
					messageInputValue={messageInputValue}
					handleChange={handleChange}
					handlePaymentClick={handlePaymentClick}
					messageTargetUser={messageTargetUser}
				/>
			</CSSTransition>

			<CSSTransition
				in={openPaymentPopUp}
				classNames="openPaymentPopUp-transition"
				timeout={100}
			>
				<PaymentPopView />
			</CSSTransition>

			<CSSTransition
				in={openLikeConfirm}
				classNames="likeWrap-transition"
				timeout={100}
			>
				<LikeConfirmView
					fnSaveUserLike={fnSaveUserLike}
					srcPrefix={srcPrefix}
					activeItem={activeItem}
					fnSendLikePopClose={fnSendLikePopClose}
				/>
			</CSSTransition>
		</div>
	);
};

export default PaymentContainer;
