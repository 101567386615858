import {useHushPickRepo} from "@dating/repository/hushPick/useHushPickRepo";
import {useDatingRepo} from "@dating/repository/dating/useDatingRepo";
import {useHomeAdapter} from "@dating/adapter/home/useHomeAdapter";
import {useEffect} from "react";

export const usePerfectPicksService = () => {
    const {
        pastPreferUsers,
        setPastPreferUsers,
    } = useHushPickRepo();

    const {
        srcPrefix,
        setUserProfileOpen,
        myProfile,
    } = useDatingRepo();

    const {
        getPastPreferUser,
    } = useHomeAdapter();

    /*
     * fnGetPastPerferUsers
     * 메인 이상형추천 히스토리 (2일치)
     */
    const fnGetPastPerferUsers = () => {
        getPastPreferUser().then((response) => {
            if (response.length > 0) {
                setPastPreferUsers(response);
            }
        });
    };

    useEffect(() => {
        fnGetPastPerferUsers();
    }, []);

    return {
        pastPreferUsers,
        srcPrefix,
        setUserProfileOpen,
        myProfile,
    };
};