import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LikeService, PostLikeCIVO, type RemoveLikeViewCIVO, UserLikeCIVO } from '@/site/api';

export const useLikeAdapter = () => {
	const queryClient = useQueryClient();

	/*
	 * saveUserLike
	 * 좋아요 버튼 클릭
	 */
	const saveUserLike = (map: UserLikeCIVO) => {
		return LikeService.saveUserLike(map);
	};

	/*
	 * savePostLike
	 * 게시글 좋아요 클릭
	 */
	const savePostLike = useMutation({
		mutationFn: (map: PostLikeCIVO) => {
			return LikeService.savePostLike(map);
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getPosts'] });
		},
	});

	/*
	 * saveSinglePostLike
	 * 게시글 상세 좋아요 클릭
	 */
	const saveSinglePostLike = (map: PostLikeCIVO) => {
		return LikeService.savePostLike(map);
	};

	/*
	 * getReceiveUserLike
	 * 받은 유저 좋아요
	 */
	const getReceiveUserLike = (page: number, limit: number, dateTime: number) => {
		return LikeService.getReceiveUserLike(page, limit, dateTime);
	};

	/*
	 * getReceiveUserHighScore
	 * 높게 받은 평가
	 */
	const getReceiveUserHighScore = (page: number, limit: number, dateTime: number) => {
		return LikeService.getReceiveUserHighScore(page, limit, dateTime);
	};

	/*
	 * getReceivePostLike
	 * 좋아요 받은 게시글
	 */
	const getReceivePostLike = (page: number, limit: number, dateTime: number) => {
		return LikeService.getReceivePostLike(page, limit, dateTime);
	};

	/*
	 * getReceivePostComment
	 * 댓글 달린 게시글
	 */
	const getReceivePostComment = (page: number, limit: number, dateTime: number) => {
		return LikeService.getReceivePostComment(page, limit, dateTime);
	};

	/*
	 * getSendUserLike
	 * 보낸 유저 좋아요
	 */
	const getSendUserLike = (page: number, limit: number, dateTime: number) => {
		return LikeService.getSendUserLike(page, limit, dateTime);
	};

	/*
	 * getSendUserHighScore
	 * 높게 평가한 유저
	 */
	const getSendUserHighScore = (page: number, limit: number, dateTime: number) => {
		return LikeService.getSendUserHighScore(page, limit, dateTime);
	};

	/*
	 * getLikedPosts
	 * 좋아요 보낸 게시물 조회
	 */
	const getLikedPosts = (page: number, limit: number, dateTime: number) => {
		return LikeService.getLikedPosts(page, limit, dateTime);
	};

	/*
	 * getSendPostComment
	 * 댓글 단 게시물 조회
	 */
	const getSendPostComment = (page: number, limit: number, dateTime: number) => {
		return LikeService.getSendPostComment(page, limit, dateTime);
	};

	/*
	 * savePostLike
	 * 좋아요 View 삭제
	 */
	const saveRemoveLikeView = (map: RemoveLikeViewCIVO) => {
		return LikeService.saveRemoveLikeView(map);
	};

	return {
		saveUserLike,
		savePostLike: savePostLike.mutate,
		statusSavePostLike: savePostLike.status,
		dataSavePostLike: savePostLike.data,
		getReceiveUserLike,
		getReceiveUserHighScore,
		getReceivePostLike,
		getReceivePostComment,
		getSendUserLike,
		getSendUserHighScore,
		getLikedPosts,
		getSendPostComment,
		//
		removeSendUserLike: saveRemoveLikeView,
		removeSendHighlyRatedUsers: saveRemoveLikeView,
		removeReceiveUserLike: saveRemoveLikeView,
		removeReceiveHighlyRatedUsers: saveRemoveLikeView,
		saveSinglePostLike,
	};
};