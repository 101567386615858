const MenListView = ({ headCount, players, srcPrefix }) => {
	const maxMenCount = headCount === 4 ? 2 : 3;
	const menPlayers = players.filter((player) => player.gender === 'M');

	return (
		<div className="menList">
			{menPlayers.map((profile) => (
				<div key={profile.uuid}>
					<img src={srcPrefix + profile.profileUrl} alt="Profile" />
				</div>
			))}

			{[...Array(maxMenCount - menPlayers.length)].map((_, index) => (
				<div key={`empty-${index}`}></div>
			))}
		</div>
	);
};

export default MenListView;
