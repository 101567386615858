import {useState} from "react";

export const useHeightStateRepo = () => {
    // focus된 input
    const [activeIndex, setActiveIndex] = useState<number>(0);

    // 다음 버튼 활성화
    const [btnActivate, setBtnActivate] = useState<boolean>(true);

    // api 응답결과
    const [response, setResponse] = useState<boolean>(false);

    return {
        activeIndex,
        setActiveIndex,
        btnActivate,
        setBtnActivate,
        response,
        setResponse,
    };
};