import {Link} from "react-router-dom";
import {ReceiveUserHighScoreCOVO} from "@/site/api";

interface props {
    highlyRatedUsers: ReceiveUserHighScoreCOVO[];
    srcPrefix: string;
    setUserProfileOpen: (userProfileOpen: string) => void;
    fnSaveUserLike: (userUid: string, state: boolean) => void;
    fnRemoveReceiveHighlyRatedUsers: (id: string, type: string) => void;
    fnSendLikePopOpen: (userUid: string, profileUrl: string, nickName: string) => void;
};

const HighlyRatedListView = ({
                                 highlyRatedUsers,
                                 srcPrefix,
                                 setUserProfileOpen,
                                 fnSaveUserLike,
                                 fnRemoveReceiveHighlyRatedUsers,
                                 fnSendLikePopOpen,
                             }: props) => {

    return (
        <div className={highlyRatedUsers.length === 0 ? "likeProfileList-empty" : "likeProfileList"}>
            {
                highlyRatedUsers.length > 0 ?
                    highlyRatedUsers.map((item, i) => {
                        return (
                            <div
                                key={item.nickName + i}
                                className="profileType"
                            >
                                <div className="proLeft">
                                    <div className="proImg">
                                        <Link to="/metaverse/dating/profile"
                                              onClick={() => {
                                                  setUserProfileOpen(item.scoreUserUid);
                                              }}
                                              state={{
                                                  isOtherProfile: true,
                                              }}
                                        >
                                            <img src={srcPrefix + item.profileUrl}/>
                                        </Link>
                                    </div>
                                    <div className="proName margin-l12">
                                        <p className="bold padding-b4">{item.nickName}</p>
                                        <p className="fz14 color-666">
                                            {/*{item.age + '세, ' + (item.location ? item.location : "위치 정보 없음")}*/}
                                            {item.age + '세'}
                                        </p>
                                    </div>
                                </div>
                                <div className="ProRight">
                                    <button
                                        type="button"
                                        className="likeBtn fixOn"
                                        onClick={() => {
                                            // fnSaveUserLike(item.scoreUserUid, true);
                                            if (item.scoreUserUid != "") {
                                                fnSendLikePopOpen(item.scoreUserUid, item.profileUrl, item.nickName);
                                            }
                                        }}
                                    ></button>
                                    <button
                                        type="button"
                                        className="closeBtn margin-l5"
                                        onClick={() => {
                                            fnRemoveReceiveHighlyRatedUsers(item.likeViewId, "received");
                                        }}
                                    ></button>
                                </div>
                            </div>
                        );
                    })
                    : null
            }
        </div>
    );
};

export default HighlyRatedListView;
