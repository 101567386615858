import { PhaserApp } from '@/ui/PhaserApp';
import AppFooterWrapContainer from './ui/components/footer/AppFooterWrapContainer';
import { Outlet } from 'react-router-dom';

const MetaverseLayout = () => {
	return (
		<>
			<PhaserApp />
			<Outlet />
			<AppFooterWrapContainer />
		</>
	);
};

export default MetaverseLayout;
