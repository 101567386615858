import {useRef} from "react";

interface props {
    previewURLs: string[];
    updateFile: (index: number, file: File) => void;
};

const PhotoImageAddView = ({
                               previewURLs,
                               updateFile,
                           }: props) => {

    const imgRefs = [
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
    ];

    return (
        <div>
            <div className="imgAddBox">
                {previewURLs[0] ? (
                    <div onClick={() => {
                        imgRefs[0].current?.click();
                    }}>
                        <label htmlFor="img0"></label>
                        <img
                            src={previewURLs[0]}
                            alt="preview-img"
                            className="img0"
                            style={{width: "100%", height: "100%", objectFit: "cover"}}
                        />
                        <input
                            type="file"
                            id="img0"
                            ref={imgRefs[0]}
                            onChange={(e) => {
                                e.preventDefault();
                                const currentRef = imgRefs[0]?.current;
                                if (currentRef && currentRef.files) {
                                    const file = currentRef.files[0];
                                    updateFile(0, file);
                                    e.target.value = "";
                                }
                            }}
                            style={{display: "none"}}
                        />
                    </div>
                ) : (
                    <>
                        <div>
                            <label htmlFor="img0"></label>
                            <input
                                type="file"
                                id="img0"
                                ref={imgRefs[0]}
                                onChange={(e) => {
                                    e.preventDefault();
                                    const currentRef = imgRefs[0]?.current;
                                    if (currentRef && currentRef.files) {
                                        const file = currentRef.files[0];
                                        updateFile(0, file);
                                        e.target.value = "";
                                    }
                                }}
                                style={{display: "none"}}
                            />
                            <span className="fz12">필수</span>
                        </div>
                    </>
                )}

                {previewURLs[1] ? (
                    <div onClick={() => {
                        imgRefs[1].current?.click();
                    }}>
                        <label htmlFor="img1"></label>
                        <img
                            src={previewURLs[1]}
                            alt="preview-img"
                            className="img1"
                            style={{width: "100%", height: "100%", objectFit: "cover"}}
                        />
                        <input
                            type="file"
                            id="img1"
                            ref={imgRefs[1]}
                            onChange={(e) => {
                                e.preventDefault();
                                const currentRef = imgRefs[1]?.current;
                                if (currentRef && currentRef.files) {
                                    const file = currentRef.files[0];
                                    updateFile(1, file);
                                    e.target.value = "";
                                }
                            }}
                            style={{display: "none"}}
                        />
                    </div>
                ) : (
                    <>
                        <div>
                            <label htmlFor="img1"></label>
                            <input
                                type="file"
                                id="img1"
                                ref={imgRefs[1]}
                                onChange={(e) => {
                                    e.preventDefault();
                                    const currentRef = imgRefs[1]?.current;
                                    if (currentRef && currentRef.files) {
                                        const file = currentRef.files[0];
                                        updateFile(1, file);
                                        e.target.value = "";
                                    }
                                }}
                                style={{display: "none"}}
                            />
                        </div>
                    </>
                )}
            </div>

            <ul className="exTxtList">
                <li>
                    밝기, 노이즈, 과도한 보정 등으로 인해 인식이 어렵거나 일부 가려진
                    경우 가입이 제한될 수 있습니다.
                </li>
                <li>
                    업로드한 이미지에 대한 타인 이미지 도용 등 법적 책임은 본인에게 있습니다.
                </li>
            </ul>
        </div>
    )
};

export default PhotoImageAddView;