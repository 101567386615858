import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { censor } from '@/utils/censor';
import { formatTime, generateMessageId } from '@/utils/util';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useMessageRepo } from '@dating/repository/message/useMessageRepo';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	IDirectMessage,
	IUser,
} from '../../../../../../../types/IDirectMessageState';
import { useMessageAdapter } from '@dating/adapter/message/useMessageAdapter';
import { MessageCIVO } from '@/site/api';
export interface NavigationState {
	billingStatus: number;
	dmRoomType: string;
	roomId: string;
	visibleTo: number;
	isUserLeft: boolean;
	isOtherUserLeft: boolean;
	user1Info: {
		uuid: string;
		name: string;
		profileUrl: string;
		location: string;
		age: string;
	};
	user2Info: {
		uuid: string;
		name: string;
		profileUrl: string;
		location: string;
		age: string;
	};
	otherUserInfo: {
		uuid: string;
		name: string;
		profileUrl: string;
		location: string;
		age: string;
	};
	createdAt: string;
}

export const useMessageDetailService = () => {
	const {
		openBlock,
		groupedMessages,
		focused,
		openDirectMessageDetail,
		dmSessionId,
		directMessageList,
		inputRef,
		showDirectMessageList,
		messageInputValue,
		openLeaveChat,
		managerEmpty,
		messagesEndRef,
		setOpenReport,
		setOpenBlock,
		modifyMenuPop,
		setOpenLeaveChat,
	} = useMessageRepo();

	const {
		updateMessageRoom,
		saveMessage,
		leaveMessageRoom,
		updateMessageReadStatus,
	} = useMessageAdapter();

	const { setMessageInputValue } = useDirectMessageRepo();
	const location = useLocation();
	const navigate = useNavigate();
	const messageId = generateMessageId();
	const locationState = location.state;

	const {
		myProfile,
		userProfile,
		srcPrefix,
		userProfileOpen,
		setUserProfileOpen,
		setUserProfile,
	} = useDatingRepo();

	const { getUserProfile } = useProfileAdapter();
	useEffect(() => {
		if (userProfileOpen != '') {
			getUserProfile(userProfileOpen).then((value) => {
				setUserProfile(value);
			});
		}
	}, []);

	const navigationRoom = useMemo(() => {
		const baseNavigationState: NavigationState = {
			billingStatus: 0,
			dmRoomType: '',
			roomId: '',
			visibleTo: 0,
			isUserLeft: false,
			isOtherUserLeft: false,
			user1Info: {
				uuid: '',
				name: '',
				profileUrl: '',
				location: '',
				age: '',
			},
			user2Info: {
				uuid: '',
				name: '',
				profileUrl: '',
				location: '',
				age: '',
			},
			otherUserInfo: {
				uuid: '',
				name: '',
				profileUrl: '',
				location: '',
				age: '',
			},
			createdAt: '',
		};

		// MessageListView에서 온 state 확인
		if (locationState?.messageListState) {
			return {
				...baseNavigationState,
				...locationState.messageListState,
			};
		}

		// 매칭 성공에서 온 state 확인
		if (locationState && !locationState.messageListState) {
			return {
				...baseNavigationState,
				...locationState,
			};
		}

		return baseNavigationState;
	}, [locationState]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMessageInputValue(event.target.value);
	};
	const handleSubmit = (
		event:
			| React.FormEvent<HTMLFormElement>
			| React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();

		const val = censor(messageInputValue.trim());
		setMessageInputValue('');
		if (val && myProfile?.userUid && userProfile?.userUid) {
			// 송신자 정보
			const sender: Partial<IUser> = {
				uuid: myProfile.userUid,
				name: myProfile.nickName,
				profileUrl: myProfile.profileUrl[0],
				location: myProfile?.location,
				age: myProfile.age,
			};

			// 수신자 정보
			const receiver: Partial<IUser> = {
				uuid: navigationRoom.otherUserInfo.uuid,
				name: navigationRoom.otherUserInfo.name,
				profileUrl: navigationRoom.otherUserInfo.profileUrl,
				location: navigationRoom.otherUserInfo.location,
				age: navigationRoom.otherUserInfo.age,
			};

			const roomData = {
				roomId: navigationRoom.roomId,
				messageId: messageId,
				sender: sender,
				receiver: receiver,
				content: val,
				createdAt: Date.now().toString(),
				read: false,
			};
			reactEventEmitter.emit('react-send-direct-message', roomData);

			// 로컬 UI 즉시 업데이트
			const currentRoom = groupedMessages[navigationRoom.roomId];
			if (currentRoom) {
				const updatedMessages = [...currentRoom.messages, roomData];
				const updatedRoom = {
					...currentRoom,
					messages: updatedMessages,
				};
				useDirectMessageRepo.getState().setGroupedMessages({
					...groupedMessages,
					[navigationRoom.roomId]: updatedRoom,
				});
			}

			const map: MessageCIVO = {
				roomId: navigationRoom.roomId,
				content: val,
				read: false,
				createdAt: Date.now().toString(),
			};
			saveMessage(map);
		}
	};

	const handleLeaveChat = () => {
		reactEventEmitter.emit('react-leave-direct-message', {
			roomId: navigationRoom.roomId,
			leaverId: myProfile?.userUid,
		});
		leaveMessageRoom(navigationRoom.roomId);
		navigate('/metaverse/messenger');
		setOpenLeaveChat(false);
	};

	const handleExitChat = () => {
		reactEventEmitter.emit('react-exit-direct-message', {
			roomId: navigationRoom.roomId,
			exit: true,
		});
		leaveMessageRoom(navigationRoom.roomId);
		navigate('/metaverse/messenger');
		setOpenLeaveChat(false);
	};

	/**
	 * 상호 결제 처리 함수
	 */
	const handleMutualPayment = () => {
		const currentRoom = groupedMessages[navigationRoom.roomId];
		const isUser1 = currentRoom?.user1Id === myProfile.userUid;
		// 상호 결제 가능 상태 체크
		const canCompleteMutualPayment =
			(isUser1 && currentRoom.billingStatus === 2) ||
			(!isUser1 && currentRoom.billingStatus === 1);

		if (canCompleteMutualPayment) {
			// 상호 결제 완료 이벤트
			reactEventEmitter.emit('react-mutual-payment-completed', {
				roomId: navigationRoom.roomId,
				paid: true,
			});

			// 방 타입 변경
			reactEventEmitter.emit('react-change-direct-room-type', {
				roomId: navigationRoom.roomId,
				dmRoomType: 'LAST',
			});

			// 결제 상태 최종 업데이트
			updateMessageRoom({
				roomId: navigationRoom.roomId,
				userUid: myProfile.userUid,
				billingStatus: 3,
			});
		}
	};

	/**
	 * 단일 결제 처리 함수 (LIKE 타입에서만 사용)
	 */
	const handleSinglePayment = () => {
		const currentRoom = groupedMessages[navigationRoom.roomId];
		const isUser1 = currentRoom?.user1Id === myProfile.userUid;

		// LIKE 타입이 아닌 경우
		if (!currentRoom || currentRoom.dmRoomType !== 'LIKE') {
			return;
		}

		// 이미 결제한 경우 체크
		if (
			(isUser1 && currentRoom.billingStatus === 1) ||
			(!isUser1 && currentRoom.billingStatus === 2)
		) {
			console.log('이미 결제하셨습니다.');
			return;
		}

		// 초기 상태 (아무도 결제하지 않은 상태)
		if (currentRoom.billingStatus === 0) {
			const newBillingStatus = isUser1 ? 1 : 2;

			reactEventEmitter.emit('react-single-payment-completed', {
				roomId: navigationRoom.roomId,
				payerId: myProfile.userUid,
			});

			updateMessageRoom({
				roomId: navigationRoom.roomId,
				userUid: myProfile.userUid,
				billingStatus: newBillingStatus,
			});
		}
	};

	// 결제 버튼 클릭 핸들러
	const handlePaymentClick = () => {
		const currentRoom = groupedMessages[navigationRoom.roomId];

		try {
			// currentRoom이 없는 경우 navigation state 사용
			const roomState = currentRoom || navigationRoom;

			// LIKE 타입일 경우
			if (roomState?.dmRoomType === 'LIKE') {
				// 초기 상태 (아무도 결제하지 않은 상태)
				if (roomState.billingStatus === 0) {
					handleSinglePayment();
				}
				// 상대방이 이미 결제한 경우
				else if (
					roomState.billingStatus === 1 ||
					roomState.billingStatus === 2
				) {
					handleMutualPayment();
				}
			}
			// FIRST 타입일 경우
			else if (roomState?.dmRoomType === 'FIRST') {
				handleMutualPayment();
			}
		} catch (error) {
			console.error('Payment error:', error);
		}
	};

	const roomData = useMemo(() => {
		if (!navigationRoom?.roomId) return null;

		const found = directMessageList.find(
			(room) => room.roomId === navigationRoom.roomId,
		);

		if (found) {
			return {
				...found,
				billingStatus: found.billingStatus ?? navigationRoom.billingStatus,
				dmRoomType: found.dmRoomType || navigationRoom.dmRoomType,
				visibleTo: found.visibleTo ?? navigationRoom.visibleTo,
				isUserLeft: found.isUserLeft ?? navigationRoom.isUserLeft,
				isOtherUserLeft:
					found.isOtherUserLeft ?? navigationRoom.isOtherUserLeft,
			};
		}

		return navigationRoom;
	}, [directMessageList, navigationRoom]);

	const currentRoomMessages = useMemo(() => {
		return roomData?.messages
			? [...roomData.messages].sort(
					(a, b) => parseInt(a.createdAt) - parseInt(b.createdAt),
			  )
			: [];
	}, [roomData]);

	const processGroupedMessagesDetail = useCallback(() => {
		interface GroupedMessage {
			isMyMessage: boolean;
			time: string;
			messages: IDirectMessage[];
			visibleTo: number;
		}

		const result = {
			my: [] as GroupedMessage[],
			other: [] as GroupedMessage[],
		};

		let lastGroupedMessage: GroupedMessage | undefined;

		// 메시지를 시간 순으로 정렬
		const sortedMessages = currentRoomMessages.sort((a, b) => {
			return parseInt(a.createdAt) - parseInt(b.createdAt);
		});

		sortedMessages.forEach((message: IDirectMessage) => {
			const isMyMessage = message.sender?.uuid === myProfile?.userUid;
			const groupKey = isMyMessage ? 'my' : 'other';
			const time = formatTime(message.createdAt);

			// 읽음 처리
			if (
				message.receiver?.uuid === myProfile?.userUid &&
				openDirectMessageDetail
			) {
				reactEventEmitter.emit('react-read-direct-message', {
					roomId: navigationRoom.roomId,
					messageId: message.messageId,
					read: true,
				});
				updateMessageReadStatus({
					roomId: navigationRoom.roomId,
					read: true,
					userUid: myProfile.userUid,
				});
			}

			// 메시지 그룹화
			if (
				lastGroupedMessage &&
				lastGroupedMessage.time === time &&
				lastGroupedMessage.isMyMessage === isMyMessage
			) {
				lastGroupedMessage.messages.push(message);
			} else {
				lastGroupedMessage = {
					isMyMessage,
					time,
					messages: [message],
					visibleTo: roomData.visibleTo,
				};
				result[groupKey].push(lastGroupedMessage);
			}
		});

		return result;
	}, [
		currentRoomMessages,
		myProfile?.userUid,
		openDirectMessageDetail,
		navigationRoom,
		roomData,
	]);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	// 메시지 상세 정보
	const groupedMessagesDetail = useMemo(() => {
		return processGroupedMessagesDetail();
	}, [processGroupedMessagesDetail]);

	// 스크롤 처리
	useEffect(() => {
		if (
			groupedMessagesDetail.my.length > 0 ||
			groupedMessagesDetail.other.length > 0
		) {
			scrollToBottom();
		}
	}, [groupedMessagesDetail]);

	const shouldExitChat = useMemo(() => {
		return roomData?.visibleTo === 1 || roomData?.visibleTo === 2;
	}, [roomData?.visibleTo]);

	// 현재 방의 상태를 확인
	const currentRoomState = useMemo(() => {
		const currentRoom = groupedMessages[navigationRoom.roomId];

		// groupedMessages에 방이 없는 경우 navigation state 사용
		if (!currentRoom && navigationRoom) {
			const isUser1 = navigationRoom.otherUserInfo?.uuid
				? myProfile.userUid !== navigationRoom.otherUserInfo.uuid
				: false;

			return {
				dmRoomType: navigationRoom.dmRoomType,
				billingStatus: navigationRoom.billingStatus,
				isUser1,
			};
		}

		// groupedMessages에 방이 있는 경우
		if (currentRoom) {
			const isUser1 = currentRoom.user1Id === myProfile.userUid;
			return {
				dmRoomType: currentRoom.dmRoomType,
				billingStatus: currentRoom.billingStatus,
				isUser1,
			};
		}

		// 둘 다 없는 경우 null 반환
		return null;
	}, [groupedMessages, navigationRoom, myProfile.userUid, location.state]);

	const showPaymentView = useMemo(() => {
		// navigation state가 없으면 기본적으로 결제 화면 표시
		if (!currentRoomState) return true;

		const { dmRoomType, billingStatus, isUser1 } = currentRoomState;

		// LIKE 타입이고 초기 상태일 때만 결제 화면 표시
		const isInitialLikeState =
			dmRoomType === 'LIKE' &&
			(billingStatus === 0 || // 초기 상태
				(isUser1 && billingStatus === 2) || // 상대방만 결제 (내가 user1인 경우)
				(!isUser1 && billingStatus === 1)); // 상대방만 결제 (내가 user2인 경우)

		// FIRST 타입이고 아직 결제하지 않은 상태일 때
		const isUnpaidFirstState =
			dmRoomType === 'FIRST' &&
			((isUser1 && billingStatus === 2) || (!isUser1 && billingStatus === 1));

		const shouldShow = isInitialLikeState || isUnpaidFirstState;

		return shouldShow;
	}, [currentRoomState]);

	const isOtherUserLeft = useMemo(() => {
		const isUser1 = roomData?.user1Id === myProfile.userUid;
		const currentVisibleTo = roomData?.visibleTo;
		return (
			(isUser1 && currentVisibleTo === 2) ||
			(!isUser1 && currentVisibleTo === 1)
		);
	}, [
		groupedMessagesDetail,
		roomData?.user1Id,
		myProfile.userUid,
		roomData?.visibleTo,
	]);

	useEffect(() => {
		scrollToBottom();
		inputRef.current?.focus();
	}, [groupedMessages]);

	const matchingTime = useMemo(() => {
		const currentRoom = groupedMessages[navigationRoom.roomId];
		return currentRoom ? currentRoom.createdAt : null;
	}, [roomData]);
	const currentMessageLength = useMemo(() => {
		return currentRoomMessages.length;
	}, [currentRoomMessages, navigationRoom]);
	return {
		showPaymentView,
		userProfileOpen,
		isOtherUserLeft,
		navigationRoom,
		groupedMessagesDetail,
		handlePaymentClick,
		setUserProfileOpen,
		handleMutualPayment,
		setOpenReport,
		setOpenLeaveChat,
		setOpenBlock,
		modifyMenuPop,
		handleSubmit,
		handleLeaveChat,
		handleExitChat,
		handleChange,
		shouldExitChat,
		focused,
		dmSessionId,
		directMessageList,
		inputRef,
		showDirectMessageList,
		messageInputValue,
		openLeaveChat,
		openBlock,
		userProfile,
		srcPrefix,
		managerEmpty,
		messagesEndRef,
		myProfile,
		matchingTime,
		currentMessageLength,
		roomData,
	};
};
