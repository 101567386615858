import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import {useSettingsService} from './useSettingsService';
import {SettingsProfileSuggestionView} from "@dating/ui/pages/settings/views/SettingsProfileSuggestionView";
import {SettingsBlockView} from "@dating/ui/pages/settings/views/SettingsBlockView";
import SettingCheckboxListView from "@dating/ui/pages/settings/views/SettingCheckboxListView";
import {Link} from "react-router-dom";
import {SettingsPolicyView} from "@dating/ui/pages/settings/views/SettingsPolicyView";

const SettingsContainer = () => {
    const {
        userSettings,
        title,
        settingsSaveHandler,
        termsList,
        setSelectedTerm,
    } = useSettingsService();

    return (
        <>
            <div className="rightShow active">
                <ButtonHeaderView title={title} right={true}/>

                <div className="popConWrap padding-b0">
                    <div className="inner">
                        <div className="settingWrap">
                            {/*프로필 공개*/}
                            <SettingsProfileSuggestionView
                                userSettings={userSettings}
                                settingsSaveHandler={settingsSaveHandler}
                            />

                            {/*차단관리, 지인 차단하기*/}
                            <SettingsBlockView/>

                            <div className="settingList">
                                {/*알림 설정*/}
                                <SettingCheckboxListView
                                    userSettings={userSettings}
                                    settingsSaveHandler={settingsSaveHandler}
                                />

                                {/*정책(이용약관)*/}
                                <SettingsPolicyView
                                    termsList={termsList}
                                    setSelectedTerm={setSelectedTerm}
                                />

                                <Link to={'/metaverse/dating/settings/accountSetting'}>
                                    <div className="leftRightTxt arrowBg">
                                        <span className="fz18 bold">계정 설정</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SettingsContainer;
