interface props {
    hidePopup: (action: string, type: string) => void;
    drinkingState: { code: string, value: string };
    handleDrinkingStateSelect: (drinkingState: { code: string, value: string }) => void;
    initDrinkingState: {code: string, value: string}[] | string[];
}

const IdealDrinkingStatusPopView = ({
                                        hidePopup,
                                        drinkingState,
                                        handleDrinkingStateSelect,
                                        initDrinkingState,
                                    }: props) => {
    return (
        <div className="popupInfo">
            <p className="fz20 bold-500 text-c padding-t8">음주</p>
            <ul className="popCheckList">

                {
                    initDrinkingState && initDrinkingState.length > 0 ?
                        initDrinkingState.map((item, i) => {
                            const key = item.code + "-drinking";
                            return (
                                <li key={key}>
                                    <span className="bold-500">{item.value}</span>
                                    <div>
                                        <input
                                            id={key}
                                            className="checkbox"
                                            name="drink-option"
                                            type="checkbox"
                                            checked={drinkingState.code === item.code}
                                            onChange={() => handleDrinkingStateSelect(item)}
                                        />
                                        <label htmlFor={key} className="checkbox-label"></label>
                                    </div>
                                </li>
                            );
                        })
                        : null
                }

            </ul>
            <div className="popFtBtnWrap">
                <button
                    className="popCloseBtn btn btnBig btnCCC"
                    onClick={() => {
                         hidePopup("cancel", "drinking");
                    }}
                >
                    <span>취소</span>
                </button>
                <button
                    className="popCloseBtn btn btnBig btnBlack"
                    onClick={() => {
                         hidePopup("save", "drinking");
                    }}
                >
                    <span>확 인</span>
                </button>
            </div>
        </div>
    );
};

export default IdealDrinkingStatusPopView;
