import { Client, Room } from 'colyseus.js';
import {
	IDirectMessageState,
	IDMRoom,
	IUser,
} from '../../../../types/IDirectMessageState';
import { RoomType } from '../../../../types/Rooms';
import { CreateClient } from '../network/CreateClient';
import { Message } from '../../../../types/Messages';
import { StoreService } from '../StoreService';
import {
	REACT_JOIN_OR_CREATE_DM_ROOM,
	reactEventEmitter,
} from '@/events/ReactEventEmitter';
import { DirectMessageEventSetup } from './DirectMessageEventSetup';

export default class DirectMessageManager {
	private static instance: DirectMessageManager | null = null;
	private client: Client;
	public dmRoom: Room<IDirectMessageState> | null = null;
	public storeService: StoreService;
	private constructor() {
		this.client = CreateClient.create();
		this.storeService = new StoreService();
	}
	private boundJoinOrCreateDMRoom:
		| ((args: REACT_JOIN_OR_CREATE_DM_ROOM) => void)
		| null = null;

	public static getInstance(): DirectMessageManager {
		if (!DirectMessageManager.instance) {
			DirectMessageManager.instance = new DirectMessageManager();
		}
		return DirectMessageManager.instance;
	}

	get sessionId() {
		return this.dmRoom?.sessionId ?? '';
	}

	async joinOrCreateDMRoom(
		uuid: string,
		nickName: string,
		profileUrl: string,
		age: string,
		location: string,
	): Promise<void> {
		if (!this.dmRoom) {
			this.dmRoom = await this.client.joinOrCreate(RoomType.DM, {
				uuid,
				nickName,
				profileUrl,
				age,
				location,
			});
			const eventSetup = new DirectMessageEventSetup(
				this.dmRoom,
				this.storeService,
			);
			eventSetup.setup();
		}
	}

	setupEventListeners() {
		reactEventEmitter.on('react-join-or-create-dm-room', (args) => {
			const { uuid, nickName, profileUrl, age, location } = args;
			this.joinOrCreateDMRoom(uuid, nickName, profileUrl, age, location);
		});
	}

	leaveDMRoom() {
		if (this.dmRoom) {
			this.dmRoom.leave();
			if (this.boundJoinOrCreateDMRoom) {
				reactEventEmitter.off(
					'react-join-or-create-dm-room',
					this.boundJoinOrCreateDMRoom,
				);
				this.boundJoinOrCreateDMRoom = null;
			}
			this.dmRoom = null;
		}
	}

	// direct message 방 생성
	createDirectMessageRoom(
		billingStatus: number,
		dmRoomType: string,
		roomId: string,
		user1Id: string,
		user2Id: string,
		user1Info: Partial<IUser>,
		user2Info: Partial<IUser>,
		createdAt: string,
	) {
		this.dmRoom?.send(Message.CREATE_DM_ROOM, {
			billingStatus,
			dmRoomType,
			roomId,
			user1Id,
			user2Id,
			user1Info,
			user2Info,
			createdAt,
		});
	}

	// direct message 방 조회
	getDirectMessageRoom() {
		this.dmRoom?.send(Message.GET_DM_ROOMS);
	}

	// direct message 추가
	sendDirectMessage(
		roomId: string,
		messageId: number,
		sender: Partial<IUser>,
		receiver: Partial<IUser>,
		content: string,
		createdAt: string,
		read: boolean,
	) {
		this.dmRoom?.send(Message.SEND_DIRECT_MESSAGE, {
			roomId,
			messageId,
			sender,
			receiver,
			content,
			createdAt,
			read,
		});
	}

	// direct message 수신
	receiveDirectMessage() {
		this.dmRoom?.onMessage(Message.RECEIVE_DIRECT_MESSAGE, (message) => {
			this.storeService.setDirectMessageReceived(message);
		});
	}

	// direct message 읽음
	readDirectMessage(roomId: string, messageId: number, read: boolean) {
		this.dmRoom?.send(Message.READ_DIRECT_MESSAGE, {
			roomId,
			messageId,
			read,
		});
	}

	// 읽지 않은 메시지 수 요청
	requestUnreadMessageCount(roomId: string) {
		this.dmRoom?.send(Message.REQUEST_UNREAD_COUNT, { roomId });
	}

	// direct message 나가기
	leaveDirectMessage(roomId: string, leaverId: string) {
		this.dmRoom?.send(Message.LEAVE_DIRECT_MESSAGE, {
			roomId,
			leaverId,
		});
	}

	// direct message 완전히 나가기
	exitDirectMessage(roomId: string, exit: boolean) {
		this.dmRoom?.send(Message.EXIT_DIRECT_MESSAGE, {
			roomId,
			exit,
		});
	}

	// direct message 단일 결제
	singlePaymentCompleted(roomId: string, payerId: string) {
		this.dmRoom?.send(Message.SINGLE_PAYMENT_COMPLETED, {
			roomId,
			payerId,
		});
	}

	// direct message 쌍방 결제
	mutualPaymentCompleted(roomId: string, paid: boolean) {
		this.dmRoom?.send(Message.MUTUAL_PAYMENT_COMPLETED, {
			roomId,
			paid,
		});
	}

	// direct message 방 타입 변경
	changeDirectRoomType(roomId: string, dmRoomType: string) {
		this.dmRoom?.send(Message.CHANGE_DM_ROOM_TYPE, {
			roomId,
			dmRoomType,
		});
	}

	// 상대방 온라인 상태 확인
	checkUserSession(targetUuid: string) {
		this.dmRoom?.send(Message.CHECK_USER_SESSION, {
			targetUuid,
		});
	}

	updateDirectMessageRoom(room: IDMRoom): void {
		this.dmRoom?.send(Message.DM_ROOM_UPDATED, room);
	}

	removeDirectMessageRoom(roomId: string): void {
		this.dmRoom?.send(Message.DM_ROOM_REMOVED, roomId);
	}
}
