// 플레이어 이름을 표시하는 클래스
export class PlayerName {
	playerName: Phaser.GameObjects.Text;

	constructor(
		private scene: Phaser.Scene,
		private playerContainer: Phaser.GameObjects.Container,
	) {
		// 플레이어 이름을 playerContainer에 추가
		this.playerName = this.scene.add
			.text(0, 0, '')
			.setFontSize(12)
			.setFontStyle('normal')
			.setFontFamily('NotoSansKR')
			.setResolution(2)
			.setColor('#fff') // 텍스트 색상 설정
			.setOrigin(0.5, 0.8)
			.setShadow(2, 2, '#27262e', 2, false, true); // 텍스트 그림자 설정

		this.playerContainer.add(this.playerName);
	}
	destroy() {
		this.playerName.destroy();
		if (this.playerContainer) {
			this.playerContainer.remove(this.playerName);
		}
	}
	// 플레이어 이름 텍스트의 높이 반환
	get height() {
		return this.playerName.height;
	}

	// 플레이어 이름 텍스트 설정
	set textName(name: string) {
		this.playerName.setText(name);
	}
}
