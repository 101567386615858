import {useState} from "react";

export const usePhoneVerificationStateRepo = () => {
    // input 입력값
    const [inputValues, setInputValues] = useState<string[]>(Array(6).fill(""));

    // 인증번호 입력 제한시간
    const [time, setTime] = useState(180); // 기본값: 180, 테스트용: 5

    //푸터 버튼 style
    const [occupancyFooterViewStyle, setOccupancyFooterViewStyle] = useState({
        notReturnNumber: {
            display: "block",
        },
        checkBtn: {
            display: "none",
        },
    });

    // 입력중인 input index
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const [formattedTime, setFormattedTime] = useState<string>("");

    // 인증 실패 여부
    const [phoneVeriFailed, setPhoneVeriFailed] = useState<boolean>(false);

    // 인증 실패 회수
    const [phoneVeriFailedCnt, setPhoneVeriFailedCnt] = useState<number>(0);

    // 인증 입력시간 초과
    const [inputTimeout, setInputTimeout] = useState<boolean>(false);

    // 인증번호 인증 실패
    const [notValidCode, setNotValidCode] = useState<boolean>(false);

    // 인증번호 재발송
    const [codeResend, setCodeResend] = useState<boolean>(false);

    return {
        codeResend,
        setCodeResend,
        notValidCode,
        setNotValidCode,
        inputValues,
        setInputValues,
        time,
        setTime,
        occupancyFooterViewStyle,
        setOccupancyFooterViewStyle,
        activeIndex,
        setActiveIndex,
        formattedTime,
        setFormattedTime,
        phoneVeriFailed,
        setPhoneVeriFailed,
        phoneVeriFailedCnt,
        setPhoneVeriFailedCnt,
        inputTimeout,
        setInputTimeout,
    }
}