import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import MenListView from './MenListView';
import RoomListItemBtnView from './RoomListItemBtnView';
import WomenListView from './WomenListView';

const MeetingPeopleListView = ({
	myGender,
	roomKey,
	locked,
	roomTheme,
	headCount,
	hasPassword,
	players,
	joinedRoomData,
	remainingTime,
	handleClickMeta,
	handleJoinClick,
}) => {
	const { srcPrefix } = useDatingRepo();
	return (
		<>
			<div className="mtPeopleList">
				<MenListView
					headCount={headCount}
					players={players}
					srcPrefix={srcPrefix}
				/>
				<WomenListView
					headCount={headCount}
					players={players}
					srcPrefix={srcPrefix}
				/>
			</div>
			<RoomListItemBtnView
				myGender={myGender}
				roomKey={roomKey}
				headCount={headCount}
				players={players}
				roomTheme={roomTheme}
				remainingTime={remainingTime}
				hasPassword={hasPassword}
				joinedRoomData={joinedRoomData}
				handleClickMeta={handleClickMeta}
				handleJoinClick={handleJoinClick}
				locked={locked}
			/>
		</>
	);
};

export default MeetingPeopleListView;
