import { useVideoService } from '../service/useVideoService';

const CamExpansionView = () => {
	const {
		videoElement,
		isMyCamVideo,
		mediaConnected,
		videoRef,
		myMetaUser,
		audioControl,
		playerInfo,
		setOpenCamExpansion,
	} = useVideoService();

	return (
		<div className="camPop" id={videoElement?.id}>
			<div className="camPopInfo">
				<div className="camScreen">
					{mediaConnected ? (
						<video
							ref={videoRef}
							autoPlay
							playsInline
							style={{
								transform: 'scaleX(-1)',
							}}
						/>
					) : (
						<div className="black-screen" />
					)}
					<span
						className={
							isMyCamVideo
								? audioControl
									? ''
									: 'voiceBtn'
								: playerInfo?.audioStatus
								? ''
								: 'voiceBtn'
						}
					/>
					<div className="txtBox">
						<p>{isMyCamVideo ? myMetaUser.nickName : playerInfo?.name}</p>
					</div>
				</div>
				<div className="text-c margin-t24">
					<button
						className="camPopClose"
						onClick={() => {
							setOpenCamExpansion(false, null);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default CamExpansionView;
