import LeaveChatButtonView from "./views/LeaveChatButtonView";

const LeaveChatContainer = ({ setOpenLeaveChat, setOpenBlock }) => {
  return (
    <LeaveChatButtonView
      setOpenLeaveChat={setOpenLeaveChat}
      setOpenBlock={setOpenBlock}
    />
  );
};

export default LeaveChatContainer;
