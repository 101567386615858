import FeedContentView from './FeedContentView';
import FeedImgView from './FeedImgView';
import {PostInfoCOVO, UserProfileCOVO} from '@/site/api';

interface props {
    post: PostInfoCOVO;
    deleteContent: (id: string) => void;
    myProfile: UserProfileCOVO;
    menuDotClickHandler: () => void;
    setOpenReport: (openReport: boolean) => void;
    setOpenFeedHidePop: (openFeedHidePop: boolean) => void;
    commentListHandler: (
        postId: string,
        postUser: { userUid: string; nickname: string },
    ) => void;
    commentViewHandler: (state: boolean) => void;
    openMenuPop: boolean;
    selectedOption: string;
    handleOptionSelect: (option: string) => void;
    sliderSettings: {};
    srcPrefix: string;
    commentCount: number | undefined;
    setUserProfileOpen: (userProfileOpen: string) => void;
    fnSavePostLike: (postId: string, likeYn: boolean) => void;
    setBlockUserUid: (blockUserUid: string) => void;
    currentUserUid: string;
    setModifyPostId: (postId: string) => void;
    setMyProfileOpen: (myProfileOpen: boolean) => void;
    fnCommonConfirmPopOn: (message: string, func: any, param: any) => any;
    setBlockPostId: (blockPostId: string) => void;
    fnFeedEditHandler: (postId: string) => void;
    feedEditPops: {};
}

const FeedListView = ({
                          post,
                          deleteContent,
                          myProfile,
                          menuDotClickHandler,
                          setOpenReport,
                          setOpenFeedHidePop,
                          commentListHandler,
                          openMenuPop,
                          sliderSettings,
                          srcPrefix,
                          commentCount,
                          commentViewHandler,
                          setUserProfileOpen,
                          fnSavePostLike,
                          setBlockUserUid,
                          currentUserUid,
                          setModifyPostId,
                          setMyProfileOpen,
                          fnCommonConfirmPopOn,
                          setBlockPostId,
                          fnFeedEditHandler,
                          feedEditPops,
                      }: props) => {
    return (
        <>
            <div className="feedBox" key={post.postId}>
                <FeedImgView
                    post={post}
                    sliderSettings={sliderSettings}
                    srcPrefix={srcPrefix}
                    fnSavePostLike={fnSavePostLike}
                />
                <FeedContentView
                    menuDotClickHandler={menuDotClickHandler}
                    setOpenReport={setOpenReport}
                    setOpenFeedHidePop={setOpenFeedHidePop}
                    commentListHandler={commentListHandler}
                    commentViewHandler={commentViewHandler}
                    deleteContent={deleteContent}
                    post={post}
                    myProfile={myProfile}
                    openMenuPop={openMenuPop}
                    srcPrefix={srcPrefix}
                    commentCount={commentCount}
                    setUserProfileOpen={setUserProfileOpen}
                    setBlockUserUid={setBlockUserUid}
                    currentUserUid={currentUserUid}
                    setModifyPostId={setModifyPostId}
                    setMyProfileOpen={setMyProfileOpen}
                    fnCommonConfirmPopOn={fnCommonConfirmPopOn}
                    setBlockPostId={setBlockPostId}
                    fnFeedEditHandler={fnFeedEditHandler}
                    feedEditPops={feedEditPops}
                />
            </div>
        </>
    );
};

export default FeedListView;
