import { UserLikeCIVO } from '@/site/api';
import { useLikeAdapter } from '@dating/adapter/like/useLikeAdapter';
import {usePaymentRepo} from '@dating/stores/PaymentStore';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useHomeRepo } from '@dating/repository/home/useHomeRepo';
import { useLikeMatchingService } from '@dating/ui/pages/like/likeMatcing/useLikeMatchingService';

export const usePaymentService = () => {
	const {
		openPaymentPop,
		openPaymentPopUp,
		openMessageConfirm,
		openLikeConfirm,
		setOpenPaymentPop,
		setOpenPaymentPopUp,
		setOpenMessageConfirm,
		setOpenLikeConfirm,
		activeItem,
		setActiveItem,
		clearActiveItem,
		setMatchedItem,
		messageTargetUser,
		setMessageTargetUser,
		matchingSuccess,
		setMatchingSuccess,
		likedLocation,
		setLikedLocation,
	} = usePaymentRepo();

	const {
		isIdealLikeds,
		setIsIdealLikeds,
		isAddIdealLikeds,
		setIsAddIdealLikeds,
		isTodayLikeds,
		setIsTodayLikeds,
		isProfileLiked,
		setIsProfileLiked,
	} = useHomeRepo();

	const { userProfile, srcPrefix } = useDatingRepo();

	const { saveUserLike } = useLikeAdapter();

	const { createMatingDMRoom, checkAndGetRoomId } = useLikeMatchingService();
	/////////////////////////////////////////

	/*
	 * fnSaveUserLike
	 * 프로필, 좋아요 탭에서 좋아요 보내기 (과금)
	 */
	const fnSaveUserLike = (userUid: string, state: boolean) => {
		const map: UserLikeCIVO = {
			likeUserUid: userUid,
			likeYn: state,
		};
		saveUserLike(map).then(async (value) => {
			// 좋아요 과금 팝업 닫기
			setOpenLikeConfirm(false);

			if (value.matchingYn === 'success') {
				const finalRoomId = checkAndGetRoomId(value.roomId);
				setMatchedItem({
					roomId: value.roomId,
					myProfileUrl: value.myProfileUrl,
					toProfileUrl: value.toProfileUrl,
					matchingYn: value.matchingYn,
				});
				setMatchingSuccess(true);
				createMatingDMRoom(await finalRoomId);
			}

			switch (likedLocation) {
				case 'ideal':
					if (value.matchingYn === 'fail') {
						const newItem = isIdealLikeds;
						newItem[value.toUserUid] = true;
						setIsIdealLikeds(newItem);
					}
					break;
				case 'addIdeal':
					if (value.matchingYn === 'fail') {
						const newItem = isAddIdealLikeds;
						newItem[value.toUserUid] = true;
						setIsAddIdealLikeds(newItem);
					}
					break;
				case 'today':
					if (value.matchingYn === 'fail') {
						const newItem = isTodayLikeds;
						newItem[value.toUserUid] = true;
						setIsTodayLikeds(newItem);
					}
					break;
				case 'profile':
					if (value.matchingYn === 'fail') {
						setIsProfileLiked(true);
					}
					break;
				default:
					break;
			}
		});
	};

	/*
	 * fnSendLikePopOpen
	 * 좋아요 과금 팝업 호출
	 */
	const fnSendLikePopOpen = (
		userUid: string,
		profileUrl: string,
		nickName: string,
	) => {
		setActiveItem({
			userUid: userUid,
			profileUrl: profileUrl,
			nickName: nickName,
		});

		setOpenLikeConfirm(true);
	};

	/*
	 * fnSendLikePopClose
	 * 좋아요 과금 팝업 닫기 및 activeItem 초기화
	 */
	const fnSendLikePopClose = () => {
		clearActiveItem();
		setOpenLikeConfirm(false);
	};

	return {
		fnSaveUserLike,
		openPaymentPop,
		openPaymentPopUp,
		openMessageConfirm,
		openLikeConfirm,
		setOpenPaymentPop,
		setOpenPaymentPopUp,
		setOpenMessageConfirm,
		setOpenLikeConfirm,
		srcPrefix,
		fnSendLikePopOpen,
		fnSendLikePopClose,
		activeItem,
		matchingSuccess,
		messageTargetUser,
		setMessageTargetUser,
		likedLocation,
		setLikedLocation,
	};
};
