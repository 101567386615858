import useImgCropRepo from "@dating/repository/imgCrop/useImgCropRepo";
import { useEffect, useState } from "react";
import {useDatingRepo} from "@dating/repository/dating/useDatingRepo";
import { useProfileAddStateRepo } from "@dating/ui/pages/profile/addProfile/useProfileAddStateRepo";

export const useProfileAddService = () => {
    const title = '인증 항목 추가';

    const {
        btnActivate,
        setBtnActivate,
        response,
        setResponse,
        profileAddTitle,
        setProfileAddTitle,
        profileAddDetail,
        setProfileAddDetail,
    } = useProfileAddStateRepo();

    const {
        previewURLs,
        setPreviewURLs,
        cropFiles,
        setCropFiles,
        clearCropFiles,
    } = useImgCropRepo();

    const {
        srcPrefix,
    } = useDatingRepo();

    /*
    * fnSaveAddProfile
    * 프로필 인증 항목 추가 버튼
    */
    function fnSaveAddProfile () {
        // setResponse(true);
    };

    /*
     * removeImg
     * 프로필 사진 1개 삭제
     */
    const removeImg = (id: number) => {
        // 미리보기 리스트에서 해당하는 인덱스 찾아서 지우기
        const newUrls = previewURLs;
        previewURLs.splice(id, 1);
        setPreviewURLs(newUrls);

        // 서버로 삭제할 파일 정보 전송
        const newFiles = cropFiles;
        cropFiles.splice(id, 1);
        setCropFiles(newFiles);
    };

    useEffect(() => {
        if (cropFiles.length > 0 || cropFiles[0]?.size > 0) {
            setBtnActivate(true);
        } else {
            setBtnActivate(false);
        }
    }, [cropFiles]);

    // useEffect(() => {
    //     setBtnActivate(false);
    // }, []);

    return {
        title,
        previewURLs,
        btnActivate,
        fnSaveAddProfile,
        srcPrefix,
        removeImg,
        response,
        profileAddTitle,
        setProfileAddTitle,
        profileAddDetail,
        setProfileAddDetail,
        clearCropFiles,
    };
};