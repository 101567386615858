import { Client, Room } from 'colyseus.js';
import { IMetaverseState } from '../../../types/IMetaverseState';
import { Message } from '../../../types/Messages';
import { IRoomData, RoomType } from '../../../types/Rooms';
import { phaserEventEmitter } from '../events/PhaserEventEmitter';
import PermissionManager from '../web/PermissionManager';
import WebRTC from '../web/WebRTC';
import { PlayerEventProcess } from './metaverse/PlayerEventProcess';
import { RoomEventSetup } from './metaverse/RoomEventSetup';
import { StoreService } from './StoreService';
import { CreateClient } from './network/CreateClient';
import GameScene from '@/scenes/GameScene';
import DirectMessageManager from './directMessage/DirectMessageManager';
import { DirectMessageEventController } from '@/scenes/setup-game/DirectMessageEventController';
import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { IDMRoom, IUser } from '../../../types/IDirectMessageState';
import BootstrapScene from '@/scenes/BootstrapScene';
import { SceneName } from '@/types/SceneName';
import { useRoomRepo } from '@/stores/useRoomRepo';

export default class Network implements RoomService {
	public readonly scene: GameScene;
	private client: Client;
	public lobby!: Room;
	private room: Room<IMetaverseState> | null = null;
	private _webRTC?: WebRTC;
	private roomType: RoomType | null = null;
	public static instance: Network | null = null;
	public storeService: StoreService;
	public playerEventProcess?: PlayerEventProcess;
	public dmManager: DirectMessageManager;
	private dmEventController: DirectMessageEventController;

	public static getInstance(scene: GameScene): Network {
		if (!Network.instance) {
			Network.instance = new Network(scene);
		}
		return Network.instance;
	}
	private constructor(scene: GameScene) {
		console.log('Network constructor called');
		this.scene = scene;
		console.log('Creating new Colyseus client...');
		this.client = CreateClient.create();
		this.storeService = new StoreService();
		this.dmManager = DirectMessageManager.getInstance();
		this.dmManager.setupEventListeners();
		this.dmEventController = DirectMessageEventController.getInstance(this);
		this.dmEventController.listen();
		console.log('Joining lobby room...');
		this.joinLobbyRoom();
	}

	get webRTC() {
		return this._webRTC;
	}

	get sessionId() {
		return this.room!.sessionId;
	}

	get dmSessionId() {
		return this.dmManager.sessionId;
	}

	get currentRoomType() {
		return this.roomType;
	}

	/* ---------- Lobby Room ---------- */
	async joinLobbyRoom() {
		this.lobby = await this.client.joinOrCreate(RoomType.LOBBY);
		this.lobby.onMessage('rooms', (rooms) => {
			this.storeService.setAvailableRoomsData(rooms);
		});
		this.lobby.onMessage('+', ([roomId, room]) => {
			this.storeService.setAddAvailableRoomData({ roomId, room });
		});
		this.lobby.onMessage('-', (roomId) => {
			this.storeService.setRemoveAvailableRoomData(roomId);
		});
	}

	/* ---------- WebRTC ---------- */
	private initRoomAndWebRTC() {
		new RoomEventSetup(this.room!, this.sessionId, this._webRTC).setup();
		const permissionManager = new PermissionManager(this._webRTC!, this);
		permissionManager.checkPermissions();
	}

	/* ---------- Phaser Event ---------- */
	private initEvent() {
		if (!this.room) return;
		const networkEventProcess = new PlayerEventProcess(
			this.room,
			this._webRTC!,
		);
		networkEventProcess.listen();
	}

	/* ---------- Setup Room ---------- */
	private async setupRoom(uuid: string) {
		this.storeService.setPlayerUUID(uuid);
		this.storeService.setSessionId(this.sessionId);
		this.storeService.clearAllPlayerNameMap();
		this._webRTC = new WebRTC(this.sessionId, this);
		this.initEvent();
		this.initRoomAndWebRTC();
		this.storeService.resetState();
	}

	/* ---------- DirectMessage Room ---------- */
	async joinOrCreateDMRoom(
		uuid: string,
		nickName: string,
		profileUrl: string,
		age: string,
		location: string,
	): Promise<void> {
		await this.dmManager.joinOrCreateDMRoom(
			uuid,
			nickName,
			profileUrl,
			age,
			location,
		);
		reactEventEmitter.emit('react-dm-room-joined');
	}

	/* ---------- Public Room ---------- */
	async joinOrCreatePublic(
		uuid: string,
		nickName: string,
		gender: string,
		profileUrl: string,
		x: number,
		y: number,
		anim: string,
		readyToConnect: boolean,
		mediaConnected: boolean,
		statusMessage: string,
		audioStatus: boolean,
	) {
		console.log('Attempting to join or create public room...');
		try {
			this.room = await this.client.create(RoomType.PUBLIC, {
				uuid,
				nickName,
				gender,
				profileUrl,
				x,
				y,
				anim,
				readyToConnect,
				mediaConnected,
				statusMessage,
				audioStatus,
			});
			console.log('Successfully joined/created public room:', this.room.id);

			this.roomType = RoomType.PUBLIC;
			await this.setupRoom(uuid);
		} catch (error) {
			console.error('Failed to join/create public room:', error);
		}
	}

	/* ---------- Custom Room ---------- */
	async createCustomRoom(
		roomData: IRoomData,
		uuid: string,
		nickName: string,
		gender: string,
		profileUrl: string,
		x: number,
		y: number,
		anim: string,
		readyToConnect: boolean,
		mediaConnected: boolean,
		statusMessage: string,
		audioStatus: boolean,
	) {
		const { name, password, autoDispose, headCount, roomTheme, remainingTime } =
			roomData;
		this.room = await this.client.create(RoomType.CUSTOM, {
			name,
			password,
			autoDispose,
			headCount,
			roomTheme,
			remainingTime,
			uuid,
			nickName,
			gender,
			profileUrl,
			x,
			y,
			anim,
			readyToConnect,
			mediaConnected,
			statusMessage,
			audioStatus,
		});
		this.roomType = RoomType.CUSTOM;
		await this.setupRoom(uuid);
	}
	async joinCustomById(
		roomId: string,
		password: string | null,
		uuid: string,
		nickName: string,
		gender: string,
		profileUrl: string,
		x: number,
		y: number,
		anim: string,
		readyToConnect: boolean,
		mediaConnected: boolean,
		statusMessage: string,
		audioStatus: boolean,
	) {
		this.room = await this.client.joinById(roomId, {
			password,
			uuid,
			nickName,
			gender,
			profileUrl,
			x,
			y,
			anim,
			readyToConnect,
			mediaConnected,
			statusMessage,
			audioStatus,
		});
		this.roomType = RoomType.CUSTOM;
		await this.setupRoom(uuid);
	}

	/* ---------- Leave Room & Cleanup ---------- */
	private cleanupWebRTC() {
		if (this._webRTC) {
			this._webRTC.cleanup();
			this._webRTC = undefined;
		}
	}
	async leaveCurrentRoom() {
		if (this.room) {
			const roomId = this.room.id;
			console.log(`Leaving current room: ${roomId}`);
			reactEventEmitter.emit('react-leave-room', roomId);
			reactEventEmitter.emit('react-change-room-data', false);

			try {
				await this.room.leave();
				// room 객체 정리
				this.room = null;
				this.roomType = null;

				// WebRTC 정리
				this.cleanupWebRTC();

				console.log(`Successfully left room: ${roomId}`);
			} catch (error) {
				console.error('Error leaving room:', error);
			}
		}
	}

	async leaveAllRooms() {
		try {
			// 현재 방 정리
			await this.leaveCurrentRoom();

			// Lobby Room 정리
			if (this.lobby) {
				await this.lobby.leave();
			}

			// DM 방 정리
			if (this.dmManager.dmRoom) {
				await this.dmManager.leaveDMRoom();
				this.dmManager.dmRoom = null;
			}

			// DM 이벤트 컨트롤러 리스너 제거
			this.dmEventController.removeAllListeners();
		} catch (error) {
			console.error('Error while leaving all rooms:', error);
		}
	}
	async cleanup() {
		try {
			console.log('=== Starting Network Cleanup ===');

			// 모든 Room 및 Lobby와의 연결 종료
			await this.leaveAllRooms();

			this.cleanupWebRTC();

			const game = this.scene.game;
			if (game) {
				// GameScene 상태 체크
				const gameScene = game.scene.getScene(SceneName.GameScene);

				if (gameScene) {
					console.log('Shutting down GameScene...');
					(gameScene as GameScene).shutdown();
					game.scene.stop(SceneName.GameScene);
					game.scene.remove(SceneName.GameScene);
					console.log('GameScene removed successfully');
				}

				// BootstrapScene 상태 체크
				const bootstrapScene = game.scene.getScene(SceneName.BootstrapScene);
				console.log('Current BootstrapScene status:', {
					exists: !!bootstrapScene,
					active: bootstrapScene?.scene?.isActive(),
					isVisible: bootstrapScene?.scene?.isVisible(),
				});

				if (bootstrapScene) {
					console.log('Cleaning up BootstrapScene listeners...');
					(
						bootstrapScene as BootstrapScene
					).startRoomListener?.removeAllListeners?.();
					console.log('BootstrapScene listeners removed');
				}

				// client 연결 종료
				if (this.client) {
					this.client = null!; // client 참조 제거
				}
				Network.instance = null;

				// 게임 씬 목록 확인
				console.log(
					'All current scenes:',
					game.scene.scenes.map((scene) => scene.scene.key),
				);
			}
			// 5. Store 초기화
			this.storeService.resetState();
			this.storeService.clearAllPlayerNameMap();
			this.storeService.setPlayerUUID('');
			this.storeService.setSessionId('');
			useRoomRepo.getState().setIsMetaOpen(false);

			this.cleanupEventListeners();

			this._webRTC = undefined;

			console.log('=== Network Cleanup Completed ===');
		} catch (error) {
			console.error('Network cleanup failed:', error);
			throw error;
		}
	}

	public static async cleanup() {
		console.log('Starting static Network cleanup...');

		if (Network.instance) {
			await Network.instance.cleanup();
			console.log('Network instance cleaned up and nullified');
		}
	}

	private cleanupEventListeners() {
		console.log('=== Starting Event Listeners Cleanup ===');

		try {
			// 1. Phaser 이벤트 정리
			phaserEventEmitter.shutdown();
			console.log('Phaser events cleaned up');

			// 2. Player 이벤트 정리
			if (this.playerEventProcess) {
				this.playerEventProcess.removeListeners();
				console.log('Player events cleaned up');
			}

			// 3. Room 이벤트 리스너 정리
			if (this.room) {
				this.room.removeAllListeners();
				console.log('Room events cleaned up');
			}

			// 4. DM 이벤트 정리
			if (this.dmEventController) {
				this.dmEventController.removeAllListeners();
				console.log('DM events cleaned up');
			}

			console.log('=== Event Listeners Cleanup Completed ===');
		} catch (error) {
			console.error('Event listener cleanup failed:', error);
		}
	}

	/* ---------- Player ---------- */
	setUpUUID() {
		this.room?.send(Message.UPDATE_PLAYER_UUID);
		phaserEventEmitter.emit('set-up-player-uuid');
	}
	readyToConnect() {
		this.room?.send(Message.READY_TO_CONNECT);
		phaserEventEmitter.emit('my-player-ready');
	}
	mediaConnected() {
		this.room?.send(Message.VIDEO_CONNECTED);
		phaserEventEmitter.emit('my-player-video-connected');
	}

	/* ---------- computer ---------- */
	connectToComputer(id: string) {
		this.room?.send(Message.CONNECT_TO_COMPUTER, { computerId: id });
	}
	disconnectFromComputer(id: string) {
		this.room?.send(Message.DISCONNECT_FROM_COMPUTER, { computerId: id });
	}

	/* ---------- Whiteboard ---------- */
	connectToWhiteboard(id: string) {
		this.room?.send(Message.CONNECT_TO_WHITEBOARD, { whiteboardId: id });
	}
	disconnectFromWhiteboard(id: string) {
		this.room?.send(Message.DISCONNECT_FROM_WHITEBOARD, { whiteboardId: id });
	}

	/* ---------- ShareScreen ---------- */
	stopScreenShare(id: string) {
		this.room?.send(Message.STOP_SCREEN_SHARE, { computerId: id });
	}

	/* ---------- Chat ---------- */
	addChatMessage(content: string) {
		this.room?.send(Message.ADD_CHAT_MESSAGE, {
			content: content,
		});
	}
	receiveChatMessage() {
		this.room?.onMessage(Message.ADD_CHAT_MESSAGE, (message) => {
			this.storeService.setChatMessage(message);
		});
	}

	/* ---------- DirectMessage ---------- */
	getDmRooms() {
		this.dmManager.getDirectMessageRoom();
	}
	checkUserSession(targetUuid: string) {
		this.dmManager.checkUserSession(targetUuid);
	}
	createDirectMessageRoom(
		billingStatus: number,
		dmRoomType: string,
		roomId: string,
		user1Id: string,
		user2Id: string,
		user1Info: Partial<IUser>,
		user2Info: Partial<IUser>,
		createdAt: string,
	) {
		this.dmManager.createDirectMessageRoom(
			billingStatus,
			dmRoomType,
			roomId,
			user1Id,
			user2Id,
			user1Info,
			user2Info,
			createdAt,
		);
	}
	getDirectMessageRooms() {
		this.dmManager.getDirectMessageRoom();
	}
	sendDirectMessage(
		roomId: string,
		messageId: number,
		sender: Partial<IUser>,
		receiver: Partial<IUser>,
		content: string,
		createdAt: string,
		read: boolean,
	): void {
		this.dmManager.sendDirectMessage(
			roomId,
			messageId,
			sender,
			receiver,
			content,
			createdAt,
			read,
		);
	}
	receiveDirectMessage(): void {
		this.dmManager.receiveDirectMessage();
	}
	readDirectMessage(roomId: string, messageId: number, read: boolean): void {
		this.dmManager.readDirectMessage(roomId, messageId, read);
	}
	leaveDirectMessage(roomId: string, leaverId: string): void {
		this.dmManager.leaveDirectMessage(roomId, leaverId);
	}
	exitDirectMessage(roomId: string, exit: boolean): void {
		this.dmManager.exitDirectMessage(roomId, exit);
	}
	singlePaymentCompleted(roomId: string, payerId: string): void {
		this.dmManager.singlePaymentCompleted(roomId, payerId);
	}
	mutualPaymentCompleted(roomId: string, paid: boolean): void {
		this.dmManager.mutualPaymentCompleted(roomId, paid);
	}
	changeDirectRoomType(roomId: string, dmRoomType: string): void {
		this.dmManager.changeDirectRoomType(roomId, dmRoomType);
	}
	updateDirectMessageRoom(room: IDMRoom): void {
		this.dmManager.updateDirectMessageRoom(room);
	}
	removeDirectMessageRoom(roomId: string): void {
		this.dmManager.removeDirectMessageRoom(roomId);
	}

	/* ---------- Emoji ---------- */
	sendEmoji(senderId: string, emoji: string) {
		this.room?.send(Message.SEND_EMOJI_MESSAGE, { senderId, emoji });
	}

	/* ---------- Chair ---------- */
	getChairState() {
		return this.room?.state.chairs;
	}
	updateChairStatus(chairId?: string, status?: boolean) {
		this.room?.send(Message.UPDATE_CHAIR_STATUS, { chairId, status });
	}
	getPlayers() {
		return this.room?.state.players;
	}
}

/* ---------- roomService ---------- */
export function createRoomService(scene: GameScene): RoomService {
	return Network.getInstance(scene);
}

/* ---------- roomService interface---------- */
export interface RoomService {
	readonly scene: GameScene;
	get webRTC(): WebRTC | undefined;
	get sessionId(): string;
	get dmSessionId(): string;

	joinLobbyRoom(): Promise<void>;

	joinOrCreateDMRoom(
		uuid: string,
		nickName: string,
		profileUrl: string,
		age: string,
		location: string,
	): Promise<void>;

	leaveCurrentRoom(): Promise<void>;

	createCustomRoom(
		roomData: IRoomData,
		uuid: string,
		nickName: string,
		gender: string,
		profileUrl: string,
		x: number,
		y: number,
		anim: string,
		readyToConnect: boolean,
		mediaConnected: boolean,
		statusMessage: string,
		audioStatus: boolean,
	): Promise<void>;

	joinCustomById(
		roomId: string,
		password: string | null,
		uuid: string,
		nickName: string,
		gender: string,
		profileUrl: string,
		x: number,
		y: number,
		anim: string,
		readyToConnect: boolean,
		mediaConnected: boolean,
		statusMessage: string,
		audioStatus: boolean,
	): Promise<void>;

	joinOrCreatePublic(
		uuid: string,
		nickName: string,
		gender: string,
		profileUrl: string,
		x: number,
		y: number,
		anim: string,
		readyToConnect: boolean,
		mediaConnected: boolean,
		statusMessage: string,
		audioStatus: boolean,
	): Promise<void>;

	/* ---------- Player ---------- */
	setUpUUID(): void;
	readyToConnect(): void;
	mediaConnected(): void;

	/* ---------- Computer ---------- */
	connectToComputer(id: string): void;
	disconnectFromComputer(id: string): void;

	/* ---------- Whiteboard ---------- */
	connectToWhiteboard(id: string): void;
	disconnectFromWhiteboard(id: string): void;

	/* ---------- ShareScreen ---------- */
	stopScreenShare(id: string): void;

	/* ---------- Chat ---------- */
	addChatMessage(content: string): void;
	receiveChatMessage(): void;

	/* ---------- DirectMessage ---------- */
	getDmRooms(): void;
	checkUserSession(targetUuid: string): void;
	createDirectMessageRoom(
		billingStatus: number,
		dmRoomType: string,
		roomId: string,
		user1Id: string,
		user2Id: string,
		user1Info: Partial<IUser>,
		user2Info: Partial<IUser>,
		createdAt: string,
		unreadCount?: number,
	): void;
	getDirectMessageRooms(): void;
	sendDirectMessage(
		roomId: string,
		messageId: number,
		sender: Partial<IUser>,
		receiver: Partial<IUser>,
		content: string,
		createdAt: string,
		read: boolean,
	): void;
	receiveDirectMessage(): void;
	readDirectMessage(roomId: string, messageId: number, read: boolean): void;
	leaveDirectMessage(roomId: string, leaverId: string): void;
	exitDirectMessage(roomId: string, exit: boolean): void;
	singlePaymentCompleted(roomId: string, payerId: string): void;
	mutualPaymentCompleted(roomId: string, paid: boolean): void;
	changeDirectRoomType(roomId: string, dmRoomType: string): void;
	updateDirectMessageRoom(room: IDMRoom): void;
	removeDirectMessageRoom(roomId: string): void;

	/* ---------- Emoji ---------- */
	sendEmoji(senderId: string, emoji: string): void;

	/* ---------- Chair ---------- */
	getChairState(): any;
	getPlayers(): any;
	updateChairStatus(chairId?: string, status?: boolean): void;
}
