import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';
import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import { MobileCheckCIVO } from '@/site/api';
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { useJoystickRepo } from '@/stores/useJoystickRepo';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { createPhaser } from '@/createPhaser';

export const useRegisterService = () => {
	const [isAbleToLogin, setIsAbleToLogin] = useState<boolean>(true);

	const { resetRegisterInfo } = useRegisterRepo();

	const { mobileCheck } = useRegisterAdapter();

	/* ---------- Metaverse ----------*/
	const { setJoystickPosition, setIsDragging } = useJoystickRepo();
	const gameRef = useRef<Phaser.Game | null>(null);
	const isGameInitialized = useRef(false);
	const handleGameClick = (pointer: Phaser.Input.Pointer) => {
		const x = pointer.x;
		const y = pointer.y;
		const JOYSTICK_SIZE = 120;
		const touchX = x + window.scrollX - JOYSTICK_SIZE / 2;
		const touchY =
			window.innerHeight - (y + window.scrollY) - JOYSTICK_SIZE / 2;
		setJoystickPosition({ x: touchX, y: touchY });
		setIsDragging(true);
		reactEventEmitter.emit('react-click-screen', { x, y });
	};

	const addClickListener = () => {
		if (gameRef.current && gameRef.current.scene.scenes.length > 0) {
			const scene = gameRef.current.scene.scenes[0];
			scene.input.on('pointerdown', handleGameClick);
		} else {
			setTimeout(addClickListener, 100);
		}
	};
	const { setMyProfile, myProfile } = useDatingRepo();
	const { getMyProfile } = useProfileAdapter();

	const initializeGame = () => {
		if (!isGameInitialized.current) {
			if (gameRef.current) {
				gameRef.current.destroy(true);
				gameRef.current = null;
			}
			gameRef.current = createPhaser();
			isGameInitialized.current = true;
			addClickListener();
		}
	};

	const navigate = useNavigate();
	const fnHasPhoneNumber = (response: any) => {};

	return {
		fnHasPhoneNumber,
		isAbleToLogin,
		resetRegisterInfo,
		mobileCheck,
		setIsAbleToLogin,
	};
};
