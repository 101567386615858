/*
    key: name + '_idle_right',
    key: name + '_idle_up',
    key: name + '_idle_left',
    key: name + '_idle_down',
   
    key: name + '_idle_hello',

    key: name + '_idle_dancing',
  
    key: name + '_run_right',
    key: name + '_run_up',
    key: name + '_run_left',
    key: name + '_run_down',

    key: name + '_sit_down',
    key: name + '_sit_left',  
    key: name + '_sit_right',
    key: name + '_sit_up',

    key: name + '_jump_down',
    key: name + '_jump_left',
    key: name + '_jump_right',
    key: name + '_jump_up',

*/

export function animationFrame(
  anims: Phaser.Animations.AnimationManager,
  name: string
) {
  const configs: Phaser.Types.Animations.Animation[] = [
    {
      key: name + "_run_left",
      frames: anims.generateFrameNumbers(name, {
        start: 8,
        end: 11,
      }),
      frameRate: 10,
      repeat: -1,
    },
    {
      key: name + "_idle_left",
      frames: anims.generateFrameNumbers(name, {
        start: 8,
        end: 8,
      }),

      repeat: 1,
    },
    {
      key: name + "_run_right",
      frames: anims.generateFrameNumbers(name, {
        start: 8,
        end: 11,
      }),
      frameRate: 10,
      repeat: -1,
    },
    {
      key: name + "_idle_right",
      frames: anims.generateFrameNumbers(name, {
        start: 8,
        end: 8,
      }),

      repeat: 1,
    },
    {
      key: name + "_run_down",
      frames: anims.generateFrameNumbers(name, {
        start: 0,
        end: 3,
      }),
      frameRate: 10,
      repeat: -1,
    },
    {
      key: name + "_idle_down",
      frames: anims.generateFrameNumbers(name, {
        start: 0,
        end: 0,
      }),
      frameRate: 10,
      repeat: 1,
    },
    {
      key: name + "_run_up",
      frames: anims.generateFrameNumbers(name, {
        start: 4,
        end: 7,
      }),
      frameRate: 10,
      repeat: -1,
    },
    {
      key: name + "_idle_up",
      frames: anims.generateFrameNumbers(name, {
        start: 4,
        end: 4,
      }),
      frameRate: 10,
      repeat: 1,
    },
    {
      key: name + "_idle_hello",
      frames: anims.generateFrameNumbers(name, {
        start: 18,
        end: 24,
      }),
      frameRate: 8,
      repeat: -1,
    },
    {
      key: name + "_idle_dancing",
      frames: anims.generateFrameNumbers(name, {
        start: 25,
        end: 38,
      }),
      frameRate: 9,
      repeat: -1,
    },
    {
      key: name + "_sit_down",
      frames: anims.generateFrameNumbers(name, {
        start: 12,
        end: 12,
      }),
      repeat: 1,
    },
    {
      key: name + "_sit_left",
      frames: anims.generateFrameNumbers(name, {
        start: 14,
        end: 14,
      }),

      repeat: 1,
    },
    {
      key: name + "_sit_right",
      frames: anims.generateFrameNumbers(name, {
        start: 14,
        end: 14,
      }),

      repeat: 1,
    },
    {
      key: name + "_sit_up",
      frames: anims.generateFrameNumbers(name, {
        start: 13,
        end: 13,
      }),

      repeat: 1,
    },
    {
      key: name + "_jump_down",
      frames: anims.generateFrameNumbers(name, {
        start: 15,
        end: 15,
      }),
      repeat: 0,
      frameRate: 1.9,
    },

    {
      key: name + "_jump_up",
      frames: anims.generateFrameNumbers(name, {
        start: 16,
        end: 16,
      }),
      repeat: 0,
      frameRate: 1.9,
    },
    {
      key: name + "_jump_left",
      frames: anims.generateFrameNumbers(name, {
        start: 17,
        end: 17,
      }),
      repeat: 0,
      frameRate: 1.9,
    },
    {
      key: name + "_jump_right",
      frames: anims.generateFrameNumbers(name, {
        start: 17,
        end: 17,
      }),
      repeat: 0,
      frameRate: 1.9,
    },
  ];

  return configs;
}
