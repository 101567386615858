import FeedFormImgView from "@dating/ui/components/feedFrom/views/FeedFormImgView";
import FeedFormView from "@dating/ui/components/feedFrom/views/FeedFormView";
import FeedFormTagView from "@dating/ui/components/feedFrom/views/FeedFormTagView";
import FeedFormFooterView from "@dating/ui/components/feedFrom/views/FeedFormFooterView";
import {useFeedFormService} from "@dating/ui/components/feedFrom/service/useFeedFormService";
import FeedFormHeaderView from "@dating/ui/components/feedFrom/views/FeedFormHeaderView";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useImgCropService} from "@dating/ui/pages/imgCrop/service/useImgCropService";

export const FeedFormContainer = () => {
    const {
        backStep,
        handleContentChange,
        saveBtnDisabled,
        removeHashTag,
        inputHashTag,
        changeHashTagInput,
        addHashTag,
        previewURLs,
        feedForm,
        prev,
        setPrev,
        fnSavePost,
        fnModifyPost,
        srcPrefix,
    } = useFeedFormService();

    const {
        updateFile,
    } = useImgCropService();

    const navigate = useNavigate();
    useEffect(() => {
        if (prev) {
            navigate(-1);
            setPrev(false);
        }
    }, [prev]);

    return (
        <>
            <div className="wrap h-fixed">
                <FeedFormHeaderView backStep={backStep} postId={feedForm.postId}/>
                <div id="conWrap" style={{padding: 0}}>
                    <div className="inner padding-t16 border-t">
                        <FeedFormImgView
                            previewURLs={previewURLs}
                            updateFile={updateFile}
                            postId={feedForm.postId}
                            srcPrefix={srcPrefix}
                        />
                        <FeedFormView
                            feedForm={feedForm}
                            handleContentChange={handleContentChange}
                        />
                        <FeedFormTagView
                            feedForm={feedForm}
                            removeHashTag={removeHashTag}
                            inputHashTag={inputHashTag}
                            changeHashTagInput={changeHashTagInput}
                            addHashTag={addHashTag}
                        />
                        <FeedFormFooterView
                            saveBtnDisabled={saveBtnDisabled}
                            postId={feedForm.postId}
                            fnSavePost={fnSavePost}
                            fnModifyPost={fnModifyPost}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};