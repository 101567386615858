import IdealDrinkingStatusPopView from "./IdealDrinkingStatusPopView";
import IdealReligionPopView from "./IdealReligionPopView";
import IdealSmokingStatusPopView from "./IdealSmokingStatusPopView";

interface props {
    isPopupVisible: boolean;
    hidePopup: (action: string, type: string) => void;
    popupType: string;
    religion: {code: string, value: string},
    handleReligionSelect: (religion: {code: string, value: string}) => void;
    drinkingState: {code: string, value: string};
    handleDrinkingStateSelect: (drinkingState: {code: string, value: string}) => void;
    smokingState: {code: string, value: string};
    handleSmokingStateSelect: (smokingState: {code: string, value: string}) => void;
    initReligion: {code: string, value: string}[] | string[];
    initDrinkingState: {code: string, value: string}[] | string[];
    initSmokingState: {code: string, value: string}[] | string[];
}

const IdealPopUpView = ({
                            isPopupVisible,
                            hidePopup,
                            popupType,
                            religion,
                            handleReligionSelect,
                            drinkingState,
                            handleDrinkingStateSelect,
                            smokingState,
                            handleSmokingStateSelect,
                            initReligion,
                            initDrinkingState,
                            initSmokingState,
                        }: props) => {
    return (
        <div
            className="popWrap"
            style={{
                display: isPopupVisible ? "flex" : "",
            }}
        >
            {popupType === "religion" && (
                <IdealReligionPopView
                    hidePopup={hidePopup}
                    religion={religion}
                    handleReligionSelect={handleReligionSelect}
                    initReligion={initReligion}
                />
            )}
            {popupType === "drinking" && (
                <IdealDrinkingStatusPopView
                    hidePopup={hidePopup}
                    drinkingState={drinkingState}
                    handleDrinkingStateSelect={handleDrinkingStateSelect}
                    initDrinkingState={initDrinkingState}
                />
            )}
            {popupType === "smoking" && (
                <IdealSmokingStatusPopView
                    hidePopup={hidePopup}
                    smokingState={smokingState}
                    handleSmokingStateSelect={handleSmokingStateSelect}
                    initSmokingState={initSmokingState}
                />
            )}
        </div>
    );
};

export default IdealPopUpView;
