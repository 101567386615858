import { Link, useNavigate } from 'react-router-dom';
import BlockReportButtonView from '@dating/ui/components/blockReport/views/BlockReportButtonView';
import { UserProfileCOVO } from '@/site/api';

interface props {
	setOpenReport: (openReport: boolean) => void;
	setOpenBlock: (openBlock: boolean) => void;
	menuDotClickHandler: () => void;
	setOpenMenuPop: (openMenuPop: boolean) => void;
	setSettingOpen: (settingOpen: boolean) => void;
	openMenuPop: boolean;
	isOtherProfile?: boolean;
	profile: UserProfileCOVO | undefined;
	setBlockUserUid: (blockUserUid: string) => void;
	initProfile: () => void;
}

const ProfileHeaderWrapView = ({
	setOpenReport,
	setOpenBlock,
	menuDotClickHandler,
	setOpenMenuPop,
	setSettingOpen,
	openMenuPop,
	setBlockUserUid,
	profile,
	isOtherProfile,
	initProfile,
}: props) => {
	const navigate = useNavigate();

	return (
		<header>
			<div className="headerWrap">
				<div className="h-left">
					<button
						type="button"
						className="btnBack btnBack_w"
						onClick={() => {
							setOpenMenuPop(false);
							initProfile();
							navigate(-1);
						}}
					/>
				</div>
				<div className="h-center"></div>
				<div className="h-right">
					{isOtherProfile ? (
						<div className="menuDotWap top">
							<button
								type="button"
								className="menuDot_w"
								onClick={(e) => {
									e.stopPropagation();
									setBlockUserUid(profile!.userUid);
									menuDotClickHandler();
								}}
							/>
							<BlockReportButtonView
								setOpenReport={setOpenReport}
								setOpenBlock={setOpenBlock}
								openMenuPop={openMenuPop}
							/>
						</div>
					) : (
						<Link
							to="/metaverse/dating/settings"
							onClick={() => {
								setSettingOpen(true);
							}}
						>
							<button type="button" className="btnSetting btnSetting_w" />
						</Link>
					)}
				</div>
			</div>
		</header>
	);
};

export default ProfileHeaderWrapView;
