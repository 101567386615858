/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlockPhoneNumberCIVO } from '../models/BlockPhoneNumberCIVO';
import type { BlockPostCIVO } from '../models/BlockPostCIVO';
import type { BlockUserCIVO } from '../models/BlockUserCIVO';
import type { BlockUserCOVO } from '../models/BlockUserCOVO';
import type { JobListCOVO } from '../models/JobListCOVO';
import type { ReportCIVO } from '../models/ReportCIVO';
import type { ReportUiCOVO } from '../models/ReportUiCOVO';
import type { SettingOnOffCIVO } from '../models/SettingOnOffCIVO';
import type { SettingPreferCOVO } from '../models/SettingPreferCOVO';
import type { SettingStylesCOVO } from '../models/SettingStylesCOVO';
import type { SettingTermsCOVO } from '../models/SettingTermsCOVO';
import type { UserSettingCOVO } from '../models/UserSettingCOVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SettingService {
    /**
     * 유저 세팅정보 조회
     * @returns UserSettingCOVO
     * @throws ApiError
     */
    public static getUserSetting(): CancelablePromise<UserSettingCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getUserSetting',
        });
    }
    /**
     * 차단 유저 조회
     * @returns BlockUserCOVO
     * @throws ApiError
     */
    public static getBlockUser(): CancelablePromise<Array<BlockUserCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getBlockUser',
        });
    }
    /**
     * 차단 유저 저장
     * @param requestBody 차단 유저 Uid
     * @returns boolean
     * @throws ApiError
     */
    public static saveBlockUser(
        requestBody: BlockUserCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveBlockUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 프로필 공개 설정
     * @param requestBody 프로필 공개 설정 On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetProfileOpen(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetProfileOpen',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 알람/소리 설정
     * @param requestBody 알람/소리 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetSound(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetSound',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 오늘의 제안 설정
     * @param requestBody 오늘의 제안 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetTodaySuggest(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetTodaySuggest',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 좋아요 받음 설정
     * @param requestBody 좋아요 받음 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetReceivedLike(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetReceivedLike',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 받은 관심 설정
     * @param requestBody 받은 관심 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetReceivedHighScore(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetReceivedHighScore',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 연결됨 설정
     * @param requestBody 연결됨 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetConnectedLike(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetConnectedLike',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 보낸 좋아요 확인 설정
     * @param requestBody 보낸 좋아요 확인 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetSendLikeChk(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetSendLikeChk',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 대화 메세지 설정 설정
     * @param requestBody 대화 메세지 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetChatMessage(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetChatMessage',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 라운지 설정
     * @param requestBody 라운지 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetLounge(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetLounge',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 마케팅 설정
     * @param requestBody 마케팅 설정  On/Off
     * @returns boolean
     * @throws ApiError
     */
    public static saveSetMarketing(
        requestBody: SettingOnOffCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveSetMarketing',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 신고 하기
     * @param requestBody
     * @returns boolean
     * @throws ApiError
     */
    public static saveReport(
        requestBody: ReportCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveReport',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 신고하기 메뉴 조회
     * @returns ReportUiCOVO
     * @throws ApiError
     */
    public static getCommonCode(): CancelablePromise<Array<ReportUiCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getReportList',
        });
    }
    /**
     * 직업 선택 리스트 조회
     * @returns JobListCOVO
     * @throws ApiError
     */
    public static getJobList(): CancelablePromise<Array<JobListCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getJobList',
        });
    }
    /**
     * 약관 정보 조회
     * @returns SettingTermsCOVO
     * @throws ApiError
     */
    public static getTerms(): CancelablePromise<Array<SettingTermsCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getTerms',
        });
    }
    /**
     * 스타일 정보 조회
     * @returns SettingStylesCOVO
     * @throws ApiError
     */
    public static getStyles(): CancelablePromise<SettingStylesCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getStyles',
        });
    }
    /**
     * 이상형 정보 조회
     * @returns SettingPreferCOVO
     * @throws ApiError
     */
    public static getPrefer(): CancelablePromise<SettingPreferCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/setting/getPrefer',
        });
    }
    /**
     * 연락처 목록 차단하기
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static saveBlockPhoneNumberUser(
        requestBody: BlockPhoneNumberCIVO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveBlockPhoneNumberUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 유저 차단 해제
     * @param requestBody
     * @returns boolean
     * @throws ApiError
     */
    public static saveUnblockUser(
        requestBody: BlockUserCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveUnblockUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 연락처 목록 차단 해제
     * @returns boolean
     * @throws ApiError
     */
    public static saveUnblockPhoneNumberUser(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveUnblockPhoneNumberUser',
        });
    }
    /**
     * 게시글 숨기기
     * @param requestBody 숨긴 게시글 id
     * @returns boolean
     * @throws ApiError
     */
    public static saveBlockPost(
        requestBody: BlockPostCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/saveBlockPost',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 로그아웃
     * @returns boolean
     * @throws ApiError
     */
    public static logout(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/setting/logout',
        });
    }
}
