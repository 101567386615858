import {useDatingRepo} from "@dating/repository/dating/useDatingRepo";
import { useState } from "react";
import { useSettingRepo } from '@dating/stores/SettingStore';
import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';

export const useAccountSettingService = () => {
    const title = '계정 설정';
    const [response, setResponse] = useState<boolean>(false);

    const {
        openLogoutPop,
        setOpenLogoutPop,
    } = useSettingRepo();

    const {
        myProfile,
    } = useDatingRepo();

    const {
        logout,
    } = useSettingAdapter();

    /*
     * fnLogout
     * 로그아웃 후 메인으로 이동
     */
    const fnLogout = () => {
        logout().then(value => {
            setResponse(true);
        });
    };

    return {
        title,
        setOpenLogoutPop,
        myProfile,
        openLogoutPop,
        fnLogout,
        response,
    };
};