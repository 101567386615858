import { PlayerBehavior } from '@/types/PlayerBehavior';
import { MyPlayerState, StateParams } from '../../MyPlayerState';

export class StopHelloState implements MyPlayerState {
	constructor(private param: StateParams) {}
	update() {
		const { myKeyboard, myPlayer } = this.param;
		// R 키 입력 상태를 초기화
		// myKeyboard.resetR();
		myKeyboard.resetClickActionR();

		const parts = myPlayer.anims.currentAnim!.key.split('_');
		parts[1] = 'idle';
		parts[2] = 'down';

		const aniKey = parts.join('_');
		myPlayer.playWrab(aniKey, true);

		myPlayer.playerBehavior = PlayerBehavior.IDLE;

		// 플레이어의 이동 상태를 업데이트
		myPlayer.updateMove(aniKey);

		// R 키 입력 상태를 초기화
		// myKeyboard.resetR();
		myKeyboard.resetClickActionR();
	}
}
