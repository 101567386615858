import { useNavigate } from 'react-router-dom';
import { LocationInfoCIVO } from '@/site/api';

interface props {
	fnSaveUserLocation: (response: any) => void;
	setLocation: (location: LocationInfoCIVO) => void;
}

const LocationInfoFooterView = ({
									fnSaveUserLocation,
									setLocation,
								}: props) => {

	const navigate = useNavigate();

	return (
		<footer>
			<div className="f-btnWrap">
				<button
					type="button"
					className="btn btnBig btnBlack"
					onClick={() => {
						if (window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
							window.flutter_inappwebview.callHandler('requestLocation', 'Hello from Web')
								.then(function(response) {
									// Flutter로부터 받은 응답 처리
									console.log('Received response from Flutter: ' + response);
									if (response == null) {
										navigate("/metaverse/dating/register/locationUnable");
										return;
									}
									const map: LocationInfoCIVO = {
										latitude: response.latitude,
										altitude: response.longitude,
									};
									setLocation(map);

									// 서버로 전송
									fnSaveUserLocation(map);
								})
								.catch((error) => console.error('Error calling handler:', error));
						} else {
							// PC 브라우저 환경 (InAppWebView가 없는 경우)
							console.warn(
								'PC 브라우저에서는 flutter_inappwebview가 지원되지 않습니다.',
							);
							const map: LocationInfoCIVO = {
								latitude: "37",
								altitude: "127",
							};
							fnSaveUserLocation(map);
						}
					}}>
					<span>허 용</span>
				</button>
			</div>
		</footer>
	);
};

export default LocationInfoFooterView;