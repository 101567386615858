import {EditType} from "@dating/repository/profile/useEditProfileRepo";

interface props {
    editPop: EditType;
    setEditPop: (editPop: EditType) => void;
    focusItem: { label: string, value: string | string[]};
    introduceValue: string;
    setIntroduceValue: (introduceValue: string) => void;
    fnModifyProfileIntroduce: () => void;
    closeEditPopup: (key: string) => void;
}

const EditIntroducePopView = ({
                                  focusItem,
                                  introduceValue,
                                  setIntroduceValue,
                                  fnModifyProfileIntroduce,
                                  closeEditPopup,
                              }: props) => {


    return (
        <div className="popWrap editProfilePop">
            <div className="popupInfo padding-b16">
                <div className="frameWrap">
                    <div className="titleWrap">
                        <h2 className="title01">자기소개</h2>
                        <p className="titleSub01">나를 소개해 보세요</p>
                    </div>
                    <textarea cols={30} rows={10}
                              placeholder="내용을 입력해 주세요"
                              value={introduceValue ? introduceValue : ""}
                              onChange={(e) => {
                                  setIntroduceValue(e.target.value);
                              }}
                    />
                </div>

                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            closeEditPopup(focusItem.label);
                        }}
                    >
                        <span>취소</span>
                    </button>
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
                            fnModifyProfileIntroduce();
                        }}
                    >
                        <span>확 인</span>
                    </button>
                </div>
            </div>
        </div>
    )
};

export default EditIntroducePopView;