import WithdrawalFooterView from './views/WithdrawalFooterView';
import WithdrawalWrapView from './views/WithdrawalWrapView';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import { useWithdrawalService } from "@dating/ui/pages/settings/withdrawal/useWithdrawalService";
import { useCommonAlertPopService } from "@dating/ui/components/commonAlertPop/service/useCommonAlertPopService";
import { useEffect } from "react";

const WithdrawalContainer = () => {

    const {
        fnDeactivateUser,
        response,
    } = useWithdrawalService();

    const {
        fnCommonAlertPopOn,
    } = useCommonAlertPopService();

    useEffect(() => {
        if (response) {
            fnCommonAlertPopOn("계정이 삭제되었습니다.");
        }
    }, [response]);

    return (
        <>
            <div className="rightShow active">
                <ButtonHeaderView right={false}/>
                <WithdrawalWrapView/>
                <WithdrawalFooterView
                    fnDeactivateUser={fnDeactivateUser}
                />
            </div>
        </>
    );
};

export default WithdrawalContainer;
