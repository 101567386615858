import ItemAbst, { ItemConfig } from "./ItemAbst";

export type ItemCreator<U extends ItemAbst> = new (itemConfig: ItemConfig) => U;

// 아이템을 생성하는 함수
function typeItemFactory<T extends ItemAbst>(
  ctor: new (itemConfig: ItemConfig) => T,
  ...args: any[]
): T {
  const [scene, x, y, texture, frame] = args;
  const itemConfig: ItemConfig = {
    scene,
    x,
    y,
    texture,
    frame,
  };

  return new ctor(itemConfig);
}

// 아이템 생성 함수를 반환하는 함수
export function itemFactory<S extends ItemAbst>(
  ctor: ItemCreator<S>
): typeof typeItemFactory<S> {
  return typeItemFactory.bind(null, ctor) as typeof typeItemFactory<S>;
}
