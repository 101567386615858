import {CSSTransition} from 'react-transition-group';
import {useProfileService} from '@dating/ui/pages/profile/service/useProfileService';
import {useBlockReportService} from '@dating/ui/components/blockReport/service/useBlockReportService';
import ProfileHeaderWrapView from '@dating/ui/pages/profile/views/ProfileHeaderWrapView';
import ProfileBannerView from '@dating/ui/pages/profile/views/ProfileBannerView';
import ProInfo01View from '@dating/ui/pages/profile/views/ProInfo01View';
import ProInfo02View from '@dating/ui/pages/profile/views/ProInfo02View';
import ProfileFooterBtnView from '@dating/ui/pages/profile/views/ProfileFooterBtnView';
import ReportPopView from '@dating/ui/components/blockReport/views/ReportPopView';
import {useRef} from 'react';
import {useCommonConfirmPopService} from '@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService';
import {usePaymentService} from '@dating/ui/components/payment/service/usePaymentService';

const ProfileContainer = () => {
    const {
        fadeIn,
        scrollHandler,
        setSettingOpen,
        myPosts,
        deleteContent,
        sliderSettings,
        srcPrefix,
        myProfile,
        userProfile,
        fnSavePostLike,
        profileDetails,
        isOtherProfile,
        setModifyPostId,
        initProfile,
        editBtnShow,
        isChecked,
        setIsChecked,
        evalHandler,
        fnFeedEditHandler,
        feedEditPops,
        initFeedEditPops,
        isClickedFeedMenu,
        setLikedLocation,
        isProfileLiked,
    } = useProfileService();

    const {
        setOpenMenuPop,
        openMenuPop,
        openReport,
        setOpenReport,
        openBlock,
        setOpenBlock,
        menuDotClickHandler,
        selectedOption,
        handleOptionSelect,
        commonReportCodes,
        reportValue,
        reportInputHandler,
        inputDisabled,
        fnSaveBlockUser,
        setBlockUserUid,
    } = useBlockReportService();

    const {fnCommonConfirmPopOn} = useCommonConfirmPopService();

    const {
        fnSendLikePopOpen,
        setOpenPaymentPop,
        setMessageTargetUser,
    } = usePaymentService();

    const proInfo01 = useRef<HTMLDivElement>(null);

    return (
        <>
            <div
                className="wrap noFooter"
                style={{
                    overflowY: 'auto',
                    overflow: isClickedFeedMenu.state ? 'hidden' : 'auto',
                }}
                onScroll={(e) => {
                    if (proInfo01.current) {
                        const y = e.currentTarget.scrollTop;
                        let rectTop = proInfo01?.current.getBoundingClientRect().top;
                        scrollHandler(y, rectTop);
                    }
                }}
            >
                <div
                    className={`profileWrap spinnerContent ${fadeIn ? 'fade-in' : ''}`}
                    onClick={() => {
                        initFeedEditPops(myPosts);
                    }}
                >
                    <ProfileHeaderWrapView
                        isOtherProfile={isOtherProfile}
                        menuDotClickHandler={menuDotClickHandler}
                        setOpenReport={setOpenReport}
                        setOpenBlock={setOpenBlock}
                        setOpenMenuPop={setOpenMenuPop}
                        setSettingOpen={setSettingOpen}
                        openMenuPop={openMenuPop}
                        setBlockUserUid={setBlockUserUid}
                        profile={isOtherProfile ? userProfile : myProfile}
                        initProfile={initProfile}
                    />

                    <ProfileBannerView
                        profile={isOtherProfile ? userProfile : myProfile}
                        srcPrefix={srcPrefix}
                        sliderSettings={sliderSettings}
                    />

                    <div className={`profileInfo ${isOtherProfile ? 'youProfile' : ''}`}>
                        {/*좋아요 버튼, 메세지 버튼, 닉네임, 나이, 지역*/}
                        <ProInfo01View
                            setOpenPaymentPop={setOpenPaymentPop}
                            profile={isOtherProfile ? userProfile : myProfile}
                            proInfo01={proInfo01}
                            fnSendLikePopOpen={isOtherProfile ? fnSendLikePopOpen : undefined}
                            setMessageTargetUser={setMessageTargetUser}
                            isLiked={isProfileLiked}
                            type={"profile"}
                            setLikedLocation={setLikedLocation}
                        />

                        {/*별점평가, 자기소개, 뱃지, 프로필요약, 추가인증정보, 내가 올린 라운지 게시글*/}
                        <ProInfo02View
                            posts={isOtherProfile ? undefined : myPosts}
                            openMenuPop={openMenuPop}
                            deleteContent={deleteContent}
                            menuDotClickHandler={menuDotClickHandler}
                            sliderSettings={sliderSettings}
                            srcPrefix={srcPrefix}
                            profile={isOtherProfile ? userProfile : myProfile}
                            starBoxHide={isOtherProfile ? false : true}
                            fnSavePostLike={fnSavePostLike}
                            profileDetails={profileDetails}
                            setModifyPostId={setModifyPostId}
                            fnCommonConfirmPopOn={fnCommonConfirmPopOn}
                            isChecked={isChecked}
                            setIsChecked={setIsChecked}
                            evalHandler={evalHandler}
                            fnFeedEditHandler={fnFeedEditHandler}
                            feedEditPops={feedEditPops}
                            isClickedFeedMenu={isClickedFeedMenu}
                        />
                    </div>
                </div>

                {/*프로필 수정 버튼*/}
                {!isOtherProfile && (
                    <ProfileFooterBtnView fadeIn={fadeIn} editBtnShow={editBtnShow}/>
                )}
            </div>

            {/* 신고하기 */}
            <CSSTransition
                in={openReport}
                classNames="popWrap-transition"
                timeout={100}
            >
                <ReportPopView
                    setOpenReport={setOpenReport}
                    selectedOption={selectedOption}
                    handleOptionSelect={handleOptionSelect}
                    commonReportCodes={commonReportCodes}
                    reportValue={reportValue}
                    reportInputHandler={reportInputHandler}
                    inputDisabled={inputDisabled}
                />
            </CSSTransition>
        </>
    );
};

export default ProfileContainer;
