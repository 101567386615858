import { useState } from 'react';

export const useRegisterWaitingStateRepo = () => {
	// 버튼 비활성화 여부
	const [btnDisabled, setBtnDisabled] = useState<boolean>(false);

	// 가입 승인된 계정인지 여부
	const [approvedAccount, setApprovedAccount] = useState<boolean>(true);

	// 시작하기 버튼 클릭 여부 저장
	const [response, setResponse] = useState<boolean>(false);

	return {
		response,
		setResponse,
		btnDisabled,
		setBtnDisabled,
		approvedAccount,
		setApprovedAccount,
	};
};
