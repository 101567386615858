declare global {
	interface HTMLButtonElement {
		clickHandler?: (this: HTMLButtonElement, ev: MouseEvent) => any;
	}
}
export default class WebRTCButtons {
	private cameraBtn: NodeListOf<HTMLButtonElement>;
	private voiceBtn: NodeListOf<HTMLButtonElement>;
	private audioTrack?: MediaStreamTrack;
	private videoTrack?: MediaStreamTrack;

	constructor(myStream: MediaStream) {
		this.cameraBtn = document.querySelectorAll('.cameraBtn');
		this.voiceBtn = document.querySelectorAll('.voiceBtn');

		if (myStream) {
			this.audioTrack = myStream.getAudioTracks()[0];
			this.videoTrack = myStream.getVideoTracks()[0];

			if (this.audioTrack) {
				this.setupButtonListeners(this.voiceBtn, this.audioTrack);
			}

			if (this.videoTrack) {
				this.setupButtonListeners(this.cameraBtn, this.videoTrack);
			}
		}
	}

	private setupButtonListeners(
		buttons: NodeListOf<HTMLButtonElement>,
		track: MediaStreamTrack,
	) {
		buttons.forEach((button) => {
			const clickHandler = () => {
				track.enabled = !track.enabled;
			};
			button.addEventListener('click', clickHandler);
			// 나중에 cleanup을 위해 이벤트 리스너 참조를 저장
			button.clickHandler = clickHandler;
		});
	}

	cleanup() {
		// 오디오 버튼 이벤트 리스너 제거
		this.voiceBtn.forEach((button) => {
			if (button.clickHandler) {
				button.removeEventListener('click', button.clickHandler);
				delete button.clickHandler;
			}
		});

		// 비디오 버튼 이벤트 리스너 제거
		this.cameraBtn.forEach((button) => {
			if (button.clickHandler) {
				button.removeEventListener('click', button.clickHandler);
				delete button.clickHandler;
			}
		});

		// 트랙 정리
		if (this.audioTrack) {
			this.audioTrack.stop();
			this.audioTrack = undefined;
		}
		if (this.videoTrack) {
			this.videoTrack.stop();
			this.videoTrack = undefined;
		}
	}
}
