import MessageDetailHeaderView from './views/MessageDetailHeaderView';
import { CSSTransition } from 'react-transition-group';
import LeaveChatPopView from '@dating/ui/components/leaveChat/views/LeaveChatPopView';
import MessageInputView from './views/MessageInputView';
import MessageDetailView from './views/MessageDetailView';
import { useMessageDetailService } from './service/useMessageDetailService';
import PartialPaymentMessageView from './views/PartialPaymentMessageView';

const MessageDetailContainer = () => {
	const {
		showPaymentView,
		isOtherUserLeft,
		groupedMessagesDetail,
		handlePaymentClick,
		setUserProfileOpen,
		setOpenLeaveChat,
		setOpenBlock,
		modifyMenuPop,
		handleSubmit,
		handleLeaveChat,
		handleExitChat,
		handleChange,
		shouldExitChat,
		focused,
		messageInputValue,
		openLeaveChat,
		srcPrefix,
		messagesEndRef,
		myProfile,
		matchingTime,
		currentMessageLength,
		navigationRoom,
	} = useMessageDetailService();

	return (
		<div className="wrap">
			<MessageDetailHeaderView
				setOpenLeaveChat={setOpenLeaveChat}
				setOpenBlock={setOpenBlock}
				modifyMenuPop={modifyMenuPop}
				navigationRoom={navigationRoom}
			/>
			<div id="conWrap" className="chatWrap">
				<div className="messengerWrap">
					{showPaymentView ? (
						<PartialPaymentMessageView
							currentMessageLength={currentMessageLength}
							matchingTime={matchingTime}
							myProfile={myProfile}
							groupedMessagesDetail={groupedMessagesDetail}
							srcPrefix={srcPrefix}
							navigationRoom={navigationRoom}
							setUserProfileOpen={setUserProfileOpen}
							handlePaymentClick={handlePaymentClick}
						/>
					) : (
						<form onSubmit={handleSubmit}>
							<MessageDetailView
								setUserProfileOpen={setUserProfileOpen}
								groupedMessagesDetail={groupedMessagesDetail}
								srcPrefix={srcPrefix}
								myProfile={myProfile}
								navigationRoom={navigationRoom}
								isOtherUserLeft={isOtherUserLeft}
								messagesEndRef={messagesEndRef}
								handleExitChat={handleExitChat}
							/>
							<MessageInputView
								handleSubmit={handleSubmit}
								messageInputValue={messageInputValue}
								handleChange={handleChange}
								focused={focused}
								isOtherUserLeft={isOtherUserLeft}
							/>
						</form>
					)}
				</div>
			</div>

			<CSSTransition
				in={openLeaveChat}
				classNames="leaveChatPopWrap-transition"
				timeout={100}
			>
				<LeaveChatPopView
					shouldExitChat={shouldExitChat}
					handleExitChat={handleExitChat}
					setOpenLeaveChat={setOpenLeaveChat}
					handleLeaveChat={handleLeaveChat}
				/>
			</CSSTransition>
		</div>
	);
};

export default MessageDetailContainer;
