import LikedPostListView from './LikedPostListView';
import LikeMoreButtonView from '../../views/LikeMoreButtonView';
import LikeTitleView from '../../views/LikeTitleView';
import {ReceivePostLikeCOVO} from "@/site/api";

interface props {
    titSet: { mainTit: string, subTit: string };
    index: number;
    scrollYIndex: number;
    noDataPost: boolean;
    fnGetReceivePostLike: () => void;
    likePosts: ReceivePostLikeCOVO[];
    srcPrefix: string;
    setUserProfileOpen: (UserProfileOpen: string) => void;
    currentUserUid: string;
    setMyProfileOpen: (myProfileOpen: boolean) => void;
    setSingleFeedId: (singleFeedId: string) => void;
};

const LikedPostView = ({
                           titSet,
                           index,
                           scrollYIndex,
                           noDataPost,
                           fnGetReceivePostLike,
                           likePosts,
                           srcPrefix,
                           setUserProfileOpen,
                           currentUserUid,
                           setMyProfileOpen,
                           setSingleFeedId,
                       }: props) => {
    return (
        <>
            <LikeTitleView
                titSet={titSet}
                scrollYIndex={scrollYIndex}
                index={index}
            />

            <LikedPostListView
                likePosts={likePosts}
                srcPrefix={srcPrefix}
                setUserProfileOpen={setUserProfileOpen}
                currentUserUid={currentUserUid}
                setMyProfileOpen={setMyProfileOpen}
                setSingleFeedId={setSingleFeedId}
            />

            <LikeMoreButtonView
                fnGetData={fnGetReceivePostLike}
                btnHandle={noDataPost}
            />
        </>
    );
};

export default LikedPostView;
