import LikeMatchingContainer from '@dating/ui/pages/like/likeMatcing/LikeMatchingContainer';
import { Route, Routes, useLocation } from 'react-router-dom';
import LikeSendContainer from "@dating/ui/pages/like/likeSend/LikeSendContainer";
import LikeReceiveContainer from "@dating/ui/pages/like/likeReceive/LikeReceiveContainer";

const LikeRouter = () => {
	const location = useLocation();
	return (
		<Routes location={location}>
			<Route path="/receivedLike" element={<LikeReceiveContainer/>} />
			<Route path="/sentLike" element={<LikeSendContainer/>} />
			<Route path="/matchingSuccess" element={<LikeMatchingContainer />} />
		</Routes>
	);
};

export default LikeRouter;
