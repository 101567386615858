import {UserSettingCOVO} from "@/site/api";

interface props {
    userSettings: UserSettingCOVO | null;
    settingsSaveHandler: (type: string, state: boolean) => void;
}

const SettingCheckboxListView = ({
                                     userSettings,
                                     settingsSaveHandler,
                                 }: props) => {


    return (
        <>
            <p className="title02 padding-b16">알림</p>

            <div className="leftRightTxt">
                <span className="fz16Fw500">소리 및 진동</span>
                <input
                    type="checkbox"
                    className="switch"
                    checked={userSettings?.soundAlarmYn ? userSettings?.soundAlarmYn : false}
                    onChange={() => {
                        settingsSaveHandler("soundAlam", !userSettings?.soundAlarmYn);
                    }}
                />
            </div>

            <div className="leftRightTxt">
                <span className="fz16Fw500">오늘의 추천</span>
                <input
                    type="checkbox"
                    className="switch"
                    checked={userSettings?.todaySuggestYn ? userSettings?.todaySuggestYn : false}
                    onChange={() => settingsSaveHandler("todaySuggest", !userSettings?.todaySuggestYn)}
                />
            </div>

            <div className="leftRightTxt">
                <span className="fz16Fw500">좋아요 받음</span>
                <input
                    type="checkbox"
                    className="switch"
                    checked={userSettings?.receivedLikeYn ? userSettings?.receivedLikeYn : false}
                    onChange={() => settingsSaveHandler("receivedLike", !userSettings?.receivedLikeYn)}
                />
            </div>

            <div className="leftRightTxt">
                <span className="fz16Fw500">보낸 좋아요 확인</span>
                <input
                    type="checkbox"
                    className="switch"
                    checked={userSettings?.sendLikeChkYn ? userSettings?.sendLikeChkYn : false}
                    onChange={() => settingsSaveHandler("sendLikeChk", !userSettings?.sendLikeChkYn)}
                />



            </div>

            <div className="leftRightTxt">
                <span className="fz16Fw500">받은 관심</span>
                <input
                    type="checkbox"
                    className="switch"
                    checked={userSettings?.receivedHighScoreYn ? userSettings?.receivedHighScoreYn : false}
                    onChange={() => settingsSaveHandler("receivedHighScoreYn", !userSettings?.receivedHighScoreYn)}
                />
            </div>

            <div className="leftRightTxt">
                <span className="fz16Fw500">연결됨</span>
                <input
                    type="checkbox"
                    className="switch"
                    checked={userSettings?.connectedLikeYn ? userSettings?.connectedLikeYn : false}
                    onChange={() => settingsSaveHandler("connectedLikeYn", !userSettings?.connectedLikeYn)}
                />
            </div>

            <div className="leftRightTxt">
                <span className="fz16Fw500">대화 메시지</span>
                <input type="checkbox" className="switch"
                       checked={userSettings?.chatMessageYn ? userSettings?.chatMessageYn : false}
                       onChange={() => settingsSaveHandler("chatMessage", !userSettings?.chatMessageYn)}
                />
            </div>

            <div className="leftRightTxt">
                <span className="fz16Fw500">라운지</span>
                <input type="checkbox" className="switch"
                       checked={userSettings?.loungeYn ? userSettings?.loungeYn : false}
                       onChange={() => settingsSaveHandler("lounge", !userSettings?.loungeYn)}
                />
            </div>

            <div className="leftRightTxt">
                <span className="fz16Fw500">마케팅 수신 동의</span>
                <input type="checkbox" className="switch"
                       checked={userSettings?.marketingYn ? userSettings?.marketingYn : false}
                       onChange={() => settingsSaveHandler("marketing", !userSettings?.marketingYn)}
                />
            </div>
        </>
    );
};

export default SettingCheckboxListView;
