interface props {
	profileDetails: { key: string; title: string; value: string | number }[];
}

const StepLastBoxView = ({ profileDetails }: props) => {
	return (
		<div className="stepLastBox">
			<ul className="infoList">
				{profileDetails.length > 0
					? profileDetails.map((detail, i) => {
							return (
								<li
									key={detail.key + i}
									className={`ic-${detail.key} ${detail.value ? 'on' : ''}`}
								>
									{detail.value ? detail.value : detail.title + ' 미등록'}
								</li>
							);
					  })
					: null}
			</ul>
		</div>
	);
};

export default StepLastBoxView;
