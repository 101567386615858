const StoreWrapView = ({ handleSendFirstTimeReceive }) => {
	return (
		<div className="popConWrap">
			<div className="inner">
				<h2 className="title02 margin-t24">커플 매니저 서비스</h2>
				<div className="storeWrap">
					<div className="topBanner padding-t16">
						<img
							src="/assets/img/common/cp-banner02.png"
							alt="단순 채팅을 넘어 그 이상을 원한다면
            아래의 커플 매니저 서비스를 이용해 보세요"
						/>
					</div>
					<div className="storeMgList">
						<div className="mgBox hotBox">
							<button>
								<p className="ic-offline">
									<strong className="en">OFFLINE</strong> 매칭 매니저
								</p>
							</button>
						</div>
						<div className="mgBox hotBox">
							<button>
								<p className="ic-screen">
									<strong className="en">SCREEN</strong> 매칭 매니저
								</p>
							</button>
						</div>
						<div className="mgBox hotBox">
							<button>
								<p className="ic-voice">
									<strong className="en">VOICE</strong> 매칭 매니저
								</p>
							</button>
						</div>
					</div>
					<div className="refillWrap">
						<h2 className="title02 margin-t24">베리드 충전</h2>
						<div className="refillList">
							<button className="rfBox" onClick={handleSendFirstTimeReceive}>
								<div>
									<p className="ic-berith md">100개</p>
								</div>
								<span className="price">10,000원</span>
							</button>
							<button className="rfBox bestBox">
								<div className="d-flex">
									<p className="ic-berith md">315개</p>
									<p className="cancell">300개</p>
								</div>
								<span className="price">30,000원</span>
							</button>
						</div>
					</div>
					<p className="margin-t48 bold-500">유의사항</p>
					<ul className="exTxtList">
						<li>
							회원님의 발급코드로 가입이 완료된 신규회원에 따라 각각 베리드
							보상이 주어집니다.
						</li>
						<li>
							이벤트 취지에 맞지 않는 부정행위가 확인되는 경우 이벤트 회수 혹은
							이벤트 참여 제한 등의 조치가 적용될 수 있습니다.
						</li>
						<li>본 이벤트는 사전 예고 없이 변경되거나 종료될 수 있습니다.</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default StoreWrapView;
