import {useRegisterAdapter} from "@dating/adapter/register/useRegisterAdapter";
import {UserGenderCIVO} from "@/site/api";
import {useGenderSelectStateRepo} from "@dating/ui/pages/register/gender/useGenderSelectStateRepo";
import {defaultAvatarMan, defaultAvatarWoman} from "@/utils/avatarUtils";
import {useEffect} from "react";
import {useRegisterRepo} from "@dating/repository/register/useRegisterRepo";

export const useGenderSelectService = () => {
    const {
        btnActivate,
        setBtnActivate,
        response,
        setResponse,
    } = useGenderSelectStateRepo();

    const {
        setStep,
        selectedGender,
        setSelectedGender,
        registerSteps,
    } = useRegisterRepo();

    const {
        saveUserGender,
        statusSaveUserGender,
        dataSaveUserGender,
    } = useRegisterAdapter();

    const fnSaveUserGender = () => {
        const map: UserGenderCIVO = {
            gender: selectedGender
        }
        saveUserGender(map);
    }

    useEffect(() => {
        if (statusSaveUserGender === "success" && dataSaveUserGender) {
            setResponse(true);
            setStep(2);
        }
    }, [statusSaveUserGender, dataSaveUserGender]);

    useEffect(() => {
        // 선택한 성별이 변경될 때마다 다음 버튼의 disabled 속성을 업데이트
        if (selectedGender === 'M' || selectedGender === 'F') {
            setBtnActivate(false);
        } else {
            setBtnActivate(true);
        }
    }, [selectedGender]);

    return {
        selectedGender,
        setSelectedGender,
        btnActivate,
        setBtnActivate,
        fnSaveUserGender,
        statusSaveUserGender,
        dataSaveUserGender,
        setStep,
        response,
        registerSteps,
    };
};
