import {Route, Routes, useLocation, useNavigationType} from "react-router-dom";
import ImgCropContainer from "@dating/ui/pages/imgCrop/ImgCropContainer";

export default function ImgCropRouter() {
    const location = useLocation();

    return (
        <>
            <Routes location={location}>
                <Route path="/" element={
                    <ImgCropContainer/>
                }/>
            </Routes>
        </>
    );
};