import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import { useSingleFeedStateRepo } from '@dating/ui/pages/lounge/singleFeed/useSingleFeedStateRepo';
import { useLoungeModifyAdapter } from '@dating/adapter/lounge/useLoungeModifyAdapter';
import { useEffect } from 'react';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useLoungeAdapter } from '@dating/adapter/lounge/useLoungeAdapter';
import { PostInfoCOVO, PostLikeCIVO } from '@/site/api';
import { useLikeAdapter } from '@dating/adapter/like/useLikeAdapter';

export const useSingleFeedService = () => {
	const {
		feedDetail,
		setFeedDetail,
		isClickedFeedMenu,
		setIsClickedFeedMenu,
	} = useSingleFeedStateRepo();

	const {
		singleFeedId,
		feedEditPops,
		setFeedEditPops,
	} = useLoungeRepo();

	const {
		sliderSettings,
		srcPrefix,
	} = useDatingRepo();

	const {
		getPostDetail,
	} = useLoungeAdapter();

	const {
		saveSinglePostLike,
	} = useLikeAdapter();

	useEffect(() => {
		if (singleFeedId != '') {
			getPostDetail(singleFeedId).then(value => {
				setFeedDetail(value);
				let newItem = {};
				newItem[value.postId] = false;
				setFeedEditPops(newItem);
				setIsClickedFeedMenu({ postId: '', state: false });
			});
		}
	}, [singleFeedId]);

	/*
	 * initFeedEditPops
	 * feedEditPop 값 전부 false로 초기화
	 */
	const initFeedEditPops = (feed: PostInfoCOVO) => {
		let newItem = {};
		newItem[feed.postId] = false;
		setFeedEditPops(newItem);
		setIsClickedFeedMenu({ postId: '', state: false });
	};

	/*
	 * fnFeedEditHandler
	 * 클릭한 피드의 케밥만 open
	 */
	const fnFeedEditHandler = (postId: string) => {
		if (feedDetail) {
			initFeedEditPops(feedDetail);
			setIsClickedFeedMenu({ postId: postId, state: true });
		}
	};

	useEffect(() => {
		if (isClickedFeedMenu.state && isClickedFeedMenu.postId != '') {
			let newItem = feedEditPops;
			newItem[isClickedFeedMenu.postId] = true;
			setFeedEditPops(newItem);
		}
	}, [feedEditPops, isClickedFeedMenu]);

	/*
	 * fnSinglePostLike
	 * 게시글 좋아요
	 */
	const fnSinglePostLike = (postId: string, likeYn: boolean) => {
		const map: PostLikeCIVO = {
			likePostId: postId,
			likeYn: likeYn,
		};
		saveSinglePostLike(map).then(value => {
			if (singleFeedId != '') {
				getPostDetail(singleFeedId).then(value => {
					setFeedDetail(value);
					let newItem = {};
					newItem[value.postId] = false;
					setFeedEditPops(newItem);
					setIsClickedFeedMenu({ postId: '', state: false });
				});
			}
		});
	};

	return {
		singleFeedId,
		feedDetail,
		sliderSettings,
		srcPrefix,
		isClickedFeedMenu,
		initFeedEditPops,
		fnFeedEditHandler,
		fnSinglePostLike,
	};
};