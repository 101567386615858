import { secondsToHMS } from '@/utils/util';

const RoomListItemBtnView = ({
	roomKey,
	myGender,
	headCount,
	players,
	locked,
	roomTheme,
	hasPassword,
	joinedRoomData,
	remainingTime,
	handleClickMeta,
	handleJoinClick,
}) => {
	const maxPerGender = headCount === 4 ? 2 : 3;
	const menCount = players.filter(
		(player: { gender: string }) => player.gender === 'M',
	).length;
	const womenCount = players.filter(
		(player: { gender: string }) => player.gender === 'F',
	).length;
	const isMenFull = menCount >= maxPerGender;
	const isWomenFull = womenCount >= maxPerGender;
	const isFull = isMenFull && isWomenFull;

	const isUserGenderFull = myGender === 'M' ? isMenFull : isWomenFull;
	const buttonDisabled = locked || isFull || isUserGenderFull;

	const getButtonText = () => {
		if (isFull) return '방이 가득 찼어요';
		if (isUserGenderFull)
			return `${myGender === 'M' ? '남성' : '여성'} 자리가 모두 찼어요`;
		if (locked) return '진행중';
		return '참가하기';
	};

	return (
		<div className="RoomListItemBtm">
			<div className="RoomTimeBox text-c">
				<p className="fz20">{secondsToHMS(remainingTime)}</p>
				<p className="fz12 color-999 margin-t8">
					모든 참여자가 준비되어야 메타버스 공간이 열립니다. <br />
					2시간 동안 방이 개설되지 않을 경우 자동으로 취소됩니다.
				</p>
			</div>
			{roomKey !== joinedRoomData?.id && (
				<button
					className={`btnBig ${buttonDisabled ? 'btnCCC' : 'btnBlack'}`}
					disabled={buttonDisabled}
					onClick={() => {
						if (!buttonDisabled) {
							handleJoinClick(roomKey, hasPassword, roomTheme);
						}
					}}
				>
					<span>{getButtonText()}</span>
				</button>
			)}
			{roomKey === joinedRoomData?.id && (
				<button
					className="btnBig btnBlack"
					onClick={() => {
						handleClickMeta();
					}}
				>
					<span>나가기</span>
				</button>
			)}
		</div>
	);
};

export default RoomListItemBtnView;
