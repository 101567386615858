export enum Message {
  UPDATE_PLAYER,
  UPDATE_PLAYER_NAME,
  UPDATE_PLAYER_UUID,
  UPDATE_PLAYER_STATUS_MESSAGE,
  READY_TO_CONNECT,
  DISCONNECT_STREAM,
  CONNECT_TO_COMPUTER,
  DISCONNECT_FROM_COMPUTER,
  STOP_SCREEN_SHARE,
  CONNECT_TO_WHITEBOARD,
  DISCONNECT_FROM_WHITEBOARD,
  UPDATE_CHAIR_STATUS,
  VIDEO_CONNECTED,
  ADD_CHAT_MESSAGE,
  SEND_ROOM_DATA,
  SEND_DIRECT_MESSAGE,
  SEND_EMOJI_MESSAGE,
  RECEIVE_DIRECT_MESSAGE,
  READ_DIRECT_MESSAGE,
  REQUEST_UNREAD_COUNT,
  UNREAD_COUNT_UPDATE,
  AUDIO_STATUS,
  LEAVE_DIRECT_MESSAGE,
  EXIT_DIRECT_MESSAGE,
  FIRST_DIRECT_MESSAGE,
  RECEIVE_FIRST_DIRECT_MESSAGE,
  DM_SESSION_INFO,
  UPDATE_PLAYER_PROFILE_URL,
  CREATE_DM_ROOM,
  GET_DM_ROOMS,
  DM_ROOM_ADDED,
  DM_ROOM_UPDATED,
  DM_ROOM_REMOVED,
  SINGLE_PAYMENT_COMPLETED,
  MUTUAL_PAYMENT_COMPLETED,
  CHANGE_DM_ROOM_TYPE,
  CHECK_USER_SESSION,
  USER_SESSION_STATUS,
  FORCE_DISCONNECT_DM,
  DELETE_PLAYER,
}
