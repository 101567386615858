import { MyEventEmitter } from '@/events/MyEventEmitter';
import Computer from '@/items/impl/Computer';
import { IRoomData } from '../../../types/Rooms';
import { IUser } from '../../../types/IDirectMessageState';

export type REACT_LEAVE_ROOM = string;
export type REACT_CHANGE_ROOM_DATA = boolean;
export type REACT_JOIN_OR_CREATE_DM_ROOM = {
	uuid: string;
	nickName: string;
	profileUrl: string;
	location: string;
	age: string;
};
export type REACT_DM_ROOM_JOINED = void;
export type REACT_ADD_CHAT_MESSAGE = string;
export type REACT_RESET_MESSAGE_COUNT = void;
export type REACT_AUDIO_STATUS = boolean;

// Direct Message
export type REACT_GET_DM_ROOMS = void;
export type REACT_CHECK_USER_SESSION = {
	targetUuid: string;
};
export type REACT_CREATE_DM_ROOM = {
	billingStatus: number;
	dmRoomType: string;
	roomId: string;
	user1Id: string;
	user2Id: string;
	user1Info: Partial<IUser>;
	user2Info: Partial<IUser>;
	createdAt: string;
	unreadCount?: number;
};
export type REACT_CREATED_DM_ROOM = void;
export type REACT_SEND_DIRECT_MESSAGE = {
	roomId: string;
	messageId: number;
	sender: Partial<IUser>;
	receiver: Partial<IUser>;
	content: string;
	createdAt: string;
	read: boolean;
};
export type REACT_READ_DIRECT_MESSAGE = {
	roomId: string;
	messageId: number;
	read: boolean;
};
export type REACT_LEAVE_DIRECT_MESSAGE = {
	roomId: string;
	leaverId: string;
};
export type REACT_EXIT_DIRECT_MESSAGE = {
	roomId: string;
	exit: boolean;
};
export type REACT_SINGLE_PAYMENT_COMPLETED = {
	roomId: string;
	payerId: string;
};
export type REACT_MUTUAL_PAYMENT_COMPLETED = {
	roomId: string;
	paid: boolean;
};
export type REACT_CHANGE_DIRECT_ROOM_TYPE = {
	roomId: string;
	dmRoomType: string;
};
export type REACT_DISCONNECT_FROM_COMPUTER = string;
export type REACT_START_ROOM = {
	roomType: string;
	uuid: string;
	nickName: string;
	gender: string;
	profileUrl: string;
	x: number;
	y: number;
	anim: string;
	readyToConnect: boolean;
	mediaConnected: boolean;
	statusMessage: string;
	audioStatus: boolean;
	room?: IRoomData;
	name?: string;
	autoDispose?: boolean;
	headCount?: number;
	roomId?: string;
	password?: string;
	roomTheme?: {};
	remainingTime?: number;
};
export type REACT_JOIN_ROOM = {
	sessionId: string;
	uuid: string;
	nickName: string;
	gender: string;
	profileUrl: string;
	name: string;
	avatarName: string;
	statusMessage: string;
	audioStatus: boolean;
};
export type REACT_ENABLE_KEY = boolean;
export type REACT_USER_MEDIA = void;
export type REACT_DISCONNECT_FROM_WHITEBOARD = string;
export type REACT_STOP_SCREEN_SHARE = string;
export type REACT_OFFER_COMPUTER_MAP = void;
export type REACT_SEND_COMPUTER_MAP = Map<string, Computer>;
export type REACT_PLAYER_ACTION = void;
export type REACT_MY_PLAYER_CHANGE_NAME = string;
export type REACT_MY_PLAYER_CHANGE_AVATAR = string;
export type REACT_MY_PLAYER_CHANGE_STATUS_MESSAGE = string;
export type REACT_PLAYER_CHANGE_PROFILE_URL = string;
export type REACT_CLICK_EMOJI = {
	senderId: string;
	emoji: string;
};
export type REACT_GAME_SCENE_READY = void;
export type REACT_CLICK_SCREEN = {
	x: number;
	y: number;
};

// 이벤트 타입에 대한 맵 정의
type EventMap = {
	['react-leave-room']: [args: REACT_LEAVE_ROOM];
	['react-change-room-data']: [args: REACT_CHANGE_ROOM_DATA];
	['react-join-or-create-dm-room']: [args: REACT_JOIN_OR_CREATE_DM_ROOM];
	['react-dm-room-joined']: [args: REACT_DM_ROOM_JOINED];
	['react-audio-status']: [args: REACT_AUDIO_STATUS];
	['react-add-chat-message']: [args: REACT_ADD_CHAT_MESSAGE];
	['react-create-dm-room']: [args: REACT_CREATE_DM_ROOM];
	['react-created-dm-room']: [args: REACT_CREATED_DM_ROOM];
	['react-reset-message-count']: [args: REACT_RESET_MESSAGE_COUNT];
	['react-send-direct-message']: [args: REACT_SEND_DIRECT_MESSAGE];
	['react-read-direct-message']: [args: REACT_READ_DIRECT_MESSAGE];
	['react-leave-direct-message']: [args: REACT_LEAVE_DIRECT_MESSAGE];
	['react-exit-direct-message']: [args: REACT_EXIT_DIRECT_MESSAGE];
	['react-single-payment-completed']: [args: REACT_SINGLE_PAYMENT_COMPLETED];
	['react-mutual-payment-completed']: [args: REACT_MUTUAL_PAYMENT_COMPLETED];
	['react-change-direct-room-type']: [args: REACT_CHANGE_DIRECT_ROOM_TYPE];
	['react-start-room']: [args: REACT_START_ROOM];
	['react-join-room']: [args: REACT_JOIN_ROOM];
	['react-user-media']: [args: REACT_USER_MEDIA];
	['react-enable-key']: [args: REACT_ENABLE_KEY];
	['react-disconnect-from-computer']: [args: REACT_DISCONNECT_FROM_COMPUTER];
	['react-disconnect-from-whiteboard']: [
		args: REACT_DISCONNECT_FROM_WHITEBOARD,
	];
	['react-stop-screen-share']: [args: REACT_STOP_SCREEN_SHARE];
	['react-offer-computer-map']: [args: REACT_OFFER_COMPUTER_MAP];
	['react-send-computer-map']: [args: REACT_SEND_COMPUTER_MAP];
	['react-player-action']: [args: REACT_PLAYER_ACTION];
	['react-my-player-change-name']: [args: REACT_MY_PLAYER_CHANGE_NAME];
	['react-my-player-change-avatar']: [args: REACT_MY_PLAYER_CHANGE_AVATAR];
	['react-my-player-change-status-message']: [
		args: REACT_MY_PLAYER_CHANGE_STATUS_MESSAGE,
	];
	['react-player-change-profile-url']: [args: REACT_PLAYER_CHANGE_PROFILE_URL];
	['react-click-emoji']: [args: REACT_CLICK_EMOJI];
	['react-game-scene-ready']: [args: REACT_GAME_SCENE_READY];
	['react-click-screen']: [args: REACT_CLICK_SCREEN];
	['react-check-user-session']: [args: REACT_CHECK_USER_SESSION];
	['react-get-dm-rooms']: [args: REACT_GET_DM_ROOMS];
};

export const reactEventEmitter = new MyEventEmitter<EventMap>();
