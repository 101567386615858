import {UserWaitingInfoCOVO} from "@/site/api";
import Slider from "react-slick";

interface props {
    userData: UserWaitingInfoCOVO | null;
    srcPrefix: string;
    sliderSettings: {};
}

const WaitingProfileImgView = ({
                                   userData,
                                   srcPrefix,
                                   sliderSettings,
                               }: props) => {
    return (
        <div className="profileBox">
            <div className="profileSlide">
                <Slider {...sliderSettings}>
                    {
                        userData?.profileUrl.map((img, i) => {
                            return <img key={i} src={srcPrefix + img} alt={userData?.nickName} className="imgHlg"/>;
                        })
                    }
                </Slider>
            </div>
            <div className="txtBox">
                <p className="txt01 fz20 color-fff">
                    {userData?.nickName}
                </p>
                <p className="txt02 fz16 color-fff">
                    {/*36세, 162cm, 서울시 영등포구*/}
                    {/*{userData?.age + "세, " + userData?.height + "cm, " + (userData?.location ? userData?.location : "지역 정보 없음")}*/}
                    {userData?.age + "세, " + userData?.height + "cm"}
                </p>
            </div>
        </div>
    )
};

export default WaitingProfileImgView;