import {Link} from "react-router-dom";
import {ReceivePostLikeCOVO} from "@/site/api";

interface props {
    likePosts: ReceivePostLikeCOVO[];
    srcPrefix: string;
    setUserProfileOpen: (UserProfileOpen: string) => void;
    currentUserUid: string;
    setMyProfileOpen: (myProfileOpen: boolean) => void;
    setSingleFeedId: (singleFeedId: string) => void;
}

const LikedPostListView = ({
                               likePosts,
                               srcPrefix,
                               setUserProfileOpen,
                               currentUserUid,
                               setMyProfileOpen,
                               setSingleFeedId,
                           }: props) => {
    return (
        <div className={likePosts.length === 0 ? "likeProfileList-empty" : "likeProfileList"}>
            {
                likePosts.length > 0 ?
                    likePosts.map((item, i) => {
                        if (item.profileUrl) {
                            return (
                                <div
                                    key={item.likePostId + i}
                                    className="profileType"
                                >
                                    <div className="proLeft">
                                        <div className="proImg">
                                            <Link to="/metaverse/dating/profile"
                                                  onClick={() => {
                                                      item.userUid !== currentUserUid ? setUserProfileOpen(item.userUid) : setMyProfileOpen(true)
                                                  }}
                                                  state={{
                                                      isOtherProfile: item.userUid !== currentUserUid,
                                                  }}
                                            >
                                                <img src={srcPrefix + item.profileUrl}/>
                                            </Link>
                                        </div>
                                        <div className="proName margin-l12">
                                            <p className="bold padding-b4">{item.nickName}</p>
                                            <p className="fz14 color-666">
                                                {/*{item.age + '세, ' + (item.location ? item.location : "위치 정보 없음")}*/}
                                                {item.age + '세'}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ProRight">
                                        <div className="likeSmImg">
                                            <Link to="/metaverse/dating/lounge/singleFeed"
                                                  onClick={() => {
                                                      setSingleFeedId(item.likePostId);
                                                  }}
                                            >
                                                <img src={srcPrefix + item.postPhotoUrl} alt={item.nickName}/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })
                    : <div>게시글이 없습니다</div>
            }
        </div>
    );
};

export default LikedPostListView;
