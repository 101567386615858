import {useEffect, useRef} from "react";

interface props {
    phoneNumber: string,
    handlePhoneNumberChange: (inputText: string) => void,
};

const PhoneNumberInputView: any = ({
                                       phoneNumber,
                                       handlePhoneNumberChange,
                                   }: props) => {

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    return (
        <div className="content">
            <div className="inputWrap">
                <div className="input">
                    <input
                        type="number"
                        pattern="\d*"
                        value={phoneNumber}
                        onChange={(e) => {
                            const inputText = e.target.value;
                            handlePhoneNumberChange(inputText);
                        }}
                        ref={inputRef}
                        placeholder="숫자만 입력해 주세요"
                    />
                </div>
            </div>
        </div>
    );
};

export default PhoneNumberInputView;
