import {Link} from "react-router-dom";
import {ReceiveCommentPostCOVO} from "@/site/api";

interface props {
    commentPost: ReceiveCommentPostCOVO[];
    srcPrefix: string;
    setUserProfileOpen: (userUid: string) => void;
    currentUserUid: string;
    setMyProfileOpen: (myProfileOpen: boolean) => void;
    setSingleFeedId: (singleFeedId: string) => void;
};

const PostWithCommentListView = ({
                                     commentPost,
                                     srcPrefix,
                                     setUserProfileOpen,
                                     currentUserUid,
                                     setMyProfileOpen,
                                     setSingleFeedId,
                                 }: props) => {
    return (
        <div className="likeProfileList">
            {/*content*/}
            {
                commentPost.length > 0 ?
                    commentPost.map((item, i) => {
                        if (item.profileUrl) {
                            return (
                                <div
                                    key={item.commentPostId + item.likeViewId}
                                    className="profileType"
                                >
                                    <div className="proLeft">
                                        <div className="proImg">
                                            <Link
                                                to="/metaverse/dating/profile"
                                                onClick={() => {
                                                    item.userUid !== currentUserUid ? setUserProfileOpen(item.userUid) : setMyProfileOpen(true)
                                                }}
                                                state={{
                                                    isOtherProfile: item.userUid !== currentUserUid,
                                                }}
                                            >
                                                <img
                                                    src={srcPrefix + item.profileUrl}
                                                    alt="profile-img"
                                                />
                                            </Link>
                                        </div>
                                        <div className="proName margin-l12">
                                            <p className="fz12Fw500">
                                                {item.nickName}
                                                <span className="padding-l8 color-999 fz12">
                                                {item.commentCreateAt}
                                            </span>
                                            </p>
                                            <p className="fz14Fw500 color-666">{item.comment}</p>
                                        </div>
                                    </div>
                                    <div className="ProRight">
                                        <div className="likeSmImg">
                                            <Link to="/metaverse/dating/lounge/singleFeed"
                                                  onClick={() => {
                                                      setSingleFeedId(item.commentPostId);
                                                  }}
                                            >
                                                <img src={srcPrefix + item.postPhotoUrl} alt={item.nickName}/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })
                    : null
            }
        </div>
    );
};

export default PostWithCommentListView;
