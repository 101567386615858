interface props {
    setOpenReport: (openReport: boolean) => void;
    setOpenFeedHidePop: (openFeedHidePop: boolean) => void;
    openMenuPop: boolean;
    setBlockPostId: (blockPostId: string) => void;
    postId: string;
};

const FeedMenuView = ({
                          setOpenReport,
                          setOpenFeedHidePop,
                          openMenuPop,
                          setBlockPostId,
                          postId,
                      }: props) => {
    return (
        <div className={`${openMenuPop ? 'menuPop active' : 'menuPop'}`}>
            <button
                type="button"
                className="popOnBtn"
                onClick={() => {
                    setOpenReport(true);
                }}
            >
                신고하기
            </button>
            <a
                onClick={() => {
                    setOpenFeedHidePop(true);
                    setBlockPostId(postId);
                }}
            >
                게시글 숨기기
            </a>
        </div>
    );
};

export default FeedMenuView;
