import BtnMenuView from './views/BtnMenuView';
import BtnFilterView from './views/BtnFilterView';
import ProfileSmImgView from './views/ProfileSmImgView';
import {UserProfileCOVO} from '@/site/api';

interface props {
    myProfile: UserProfileCOVO;
    srcPrefix: string;
    setMyProfileOpen: (myProfileOpen: boolean) => void;
}

// 데이팅앱 헤더
const DatingHeaderView = ({
                              myProfile,
                              srcPrefix,
                              setMyProfileOpen,
                          }: props
) => {
    return (
        <header className="datingHeader">
            <div className="headerWrap">
                <div className="h-left">
                    <BtnMenuView/>
                </div>
                <div className="h-center"/>
                <div className="h-right">
                    <BtnFilterView/>

                    {/*내 프로필*/}
                    <ProfileSmImgView
                        myProfile={myProfile}
                        srcPrefix={srcPrefix}
                        setMyProfileOpen={setMyProfileOpen}
                    />
                </div>
            </div>
        </header>
    );
};

export default DatingHeaderView;
