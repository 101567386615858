import { useState } from "react";

export const useBirthdayStateRepo = () => {
    // 다음 버튼 활성화 여부
    const [btnActivate, setBtnActivate] = useState(true);

    // focus된 input
    const [activeIndex, setActiveIndex] = useState(0);

    // 생년월일 validation 결과 에러메세지
    const [birthCheckMessage, setBirthCheckMessage] = useState<string>("");

    return {
        btnActivate,
        setBtnActivate,
        activeIndex,
        setActiveIndex,
        birthCheckMessage,
        setBirthCheckMessage
    };
};