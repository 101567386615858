import { useState } from "react";

export const useProfileAddStateRepo = () => {
    const [btnActivate, setBtnActivate] = useState<boolean>(false);
    const [response, setResponse] = useState<boolean>(false);
    const [profileAddTitle, setProfileAddTitle] = useState<string>("");
    const [profileAddDetail, setProfileAddDetail] = useState<string>("");

    return {
        btnActivate,
        setBtnActivate,
        response,
        setResponse,
        profileAddTitle,
        setProfileAddTitle,
        profileAddDetail,
        setProfileAddDetail,
    };
};