interface props {
    confirmPopMessage: string;
    fnCommonConfirmPopExecute: () => void;
    fnCommonConfirmPopOff: () => void;
}

export const CommonConfirmPopView = ({
                                         confirmPopMessage,
                                         fnCommonConfirmPopExecute,
                                         fnCommonConfirmPopOff,
                                     }: props) => {
    return (
        <div className="logoutWrap">
            <div className="popupInfo padding-t36">
                <p className="blackTxt text-c bold-500">
                    {confirmPopMessage}
                </p>
                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            fnCommonConfirmPopOff();
                        }}
                    >
                        <span>취 소</span>
                    </button>
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
                            fnCommonConfirmPopExecute();
                        }}
                    >
                        <span>확 인</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
