import SettingsContainer from '@dating/ui/pages/settings/SettingsContainer';
import AccountSettingContainer from '@dating/ui/pages/settings/accountSetting/AccountSettingContainer';
import AgreeContainer from '@dating/ui/pages/settings/agree/AgreeContainer';
import BlockAcquaintanceContainer from '@dating/ui/pages/settings/blockAcquaintance/BlockAcquaintanceContainer';
import BlockListContainer from '@dating/ui/pages/settings/blockList/BlockListContainer';
import IdealSettingEditContainer from '@dating/ui/pages/settings/idealSettingEdit/IdealSettingEditContainer';
import WithdrawalContainer from '@dating/ui/pages/settings/withdrawal/WithdrawalContainer';
import {
	Route,
	Routes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import RouteTransition from './RouteTransition';
import { Policy01Container } from '@dating/ui/pages/settings/agree/Policy01Container';
import { Policy02Container } from '@dating/ui/pages/settings/agree/Policy02Container';
import { Policy03Container } from '@dating/ui/pages/settings/agree/Policy03Container';
import { Policy04Container } from '@dating/ui/pages/settings/agree/Policy04Container';
const SettingRouter = () => {
	const location = useLocation();
	const navigationType = useNavigationType();

	return (
		<RouteTransition location={location} navigationType={navigationType}>
			<Routes location={location}>
				<Route path="/" element={<SettingsContainer />} />
				<Route path="/accountSetting" element={<AccountSettingContainer />} />

				<Route path="/agree" element={<AgreeContainer />} />
				<Route
					path="/blockAcquaintance"
					element={<BlockAcquaintanceContainer />}
				/>
				<Route path="/blockList" element={<BlockListContainer />} />
				<Route path="/withdrawal" element={<WithdrawalContainer />} />
				<Route
					path="/idealSettingEdit"
					element={<IdealSettingEditContainer />}
				/>

				<Route path="/policy01" element={<Policy01Container/>} />
				<Route path="/policy02" element={<Policy02Container/>} />
				<Route path="/policy03" element={<Policy03Container/>} />
				<Route path="/policy04" element={<Policy04Container/>} />
			</Routes>
		</RouteTransition>
	);
};

export default SettingRouter;
