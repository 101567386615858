/*
 * bufferArrayToBlobArray
 * bufferArray를 Blob[] 으로 변환하여 미리보기 url, blob[] 을 리턴
 * 이미지 파일 수정 시 사용
 */
export const bufferArrayToBlobArray = (files: string[]) => {
    const result: {
        blobs: Blob[],
        urls: string[],
    } = {
        blobs: [],
        urls: [],
    };

    files.forEach((file) => {
        //@ts-ignore
        const blobFile = new Blob([new Uint8Array(file.data)], {type: "image/jpg"});
        result.blobs.push(blobFile);
        result.urls.push(window.URL.createObjectURL(blobFile));
    }); 

    return result;
};