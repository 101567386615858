import { PlayerBehavior } from '@/types/PlayerBehavior';
import { MyPlayerState, StateParams } from '../../MyPlayerState';

export class DanceState implements MyPlayerState {
	constructor(private param: StateParams) {}
	update() {
		const { myPlayer, myKeyboard } = this.param;
		// E 키 입력 상태를 초기화
		// myKeyboard.resetE();
		myKeyboard.resetClickActionE();

		myPlayer.playerBehavior = PlayerBehavior.DANCING;

		myPlayer.playWrab(`${myPlayer.playerTexture}_idle_dancing`, true);

		// E 키 입력 상태를 초기화
		// myKeyboard.resetE();
		myKeyboard.resetClickActionE();
	}
}
