import { create } from 'zustand';
import { reactEventEmitter } from '../events/ReactEventEmitter';

export interface ItemRepoState<T> {
	command: string;
	modal: boolean;
	data: T | null;
}

interface ItemRepo<T> extends ItemRepoState<T> {
	startAction: (payload: ItemRepoState<T>) => void;
	endAction: (command: string) => void;
}

const initialState: ItemRepoState<any> = {
	command: '',
	modal: false,
	data: null,
};

export const useItemRepo = create<ItemRepo<any>>((set, get) => ({
	...initialState,

	startAction: (payload) => {
		if (payload.modal) {
			reactEventEmitter.emit('react-enable-key', false);
		}
		set(payload);
	},

	endAction: (command) => {
		const state = get();
		if (state.command !== command) {
			return;
		}

		if (state.modal) {
			reactEventEmitter.emit('react-enable-key', true);
		}

		set(initialState);
	},
}));
