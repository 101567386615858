import {Link} from "react-router-dom";
import {SettingTermsCOVO} from "@/site/api";

interface props {
    termsList: SettingTermsCOVO[];
    setSelectedTerm: (selectedTerm) => void;
};

export const SettingsPolicyView = ({
                                       termsList,
                                       setSelectedTerm,
                                   }: props) => {
    return (
        <>
            {
                termsList?.map((item, i) => {
                    if (item.required) {
                        return (
                            <Link
                                key={i}
                                to={"/metaverse/dating/settings/policy0" + (i + 1).toString()}
                                onClick={() => {
                                    setSelectedTerm(item.contents);
                                }}>
                                <div className="leftRightTxt arrowBg">
                                    <span className="fz18 bold">{item.contents}</span>
                                </div>
                            </Link>
                        )
                    }
                })
            }
            {/*<Link to={'/metaverse/dating/settings/agree'}>*/}
            {/*    <div className="leftRightTxt arrowBg">*/}
            {/*        <span className="fz18 bold">이용약관</span>*/}
            {/*    </div>*/}
            {/*</Link>*/}
            {/*<div className="leftRightTxt arrowBg">*/}
            {/*    <a className="fz18 bold">개인정보취급방침</a>*/}
            {/*</div>*/}
            {/*<div className="leftRightTxt arrowBg">*/}
            {/*    <a className="fz18 bold">위치 기반 서비스 이용약관</a>*/}
            {/*</div>*/}
        </>
    )
};