import {useSettingRepo} from "@dating/stores/SettingStore";
import {useSettingAdapter} from "@dating/adapter/setting/useSettingAdapter";
import {useEffect} from "react";
import {BlockPhoneNumberCIVO} from "@/site/api";
import {useBlockReportRepo} from "@dating/repository/dating/useBlockReportRepo";

export const useBlockAcquaintanceService = () => {
    const title = '지인 차단';

    const {
        blockedUserCount,
        setBlockedUserCount,
    } = useBlockReportRepo();

    const {
        setOpenBlockAcquaintancePop,
    } = useSettingRepo();

    const {
        saveBlockPhoneNumberUser,
        statusSaveBlockPhoneNumberUser,
        dataSaveBlockPhoneNumberUser,
    } = useSettingAdapter();

    /*
     * fnSaveBlockAcquaintance
     * 내 연락처 전체 차단하기 버튼 클릭 - 차단 목록 저장
     */
    const fnSaveBlockAcquaintance = () => {
        const map: BlockPhoneNumberCIVO = {
            blockPhoneNumber: ["1", "2"]
        };
        saveBlockPhoneNumberUser(map);
        setBlockedUserCount(map.blockPhoneNumber.length);
    };

    useEffect(() => {
        if (statusSaveBlockPhoneNumberUser === "success" && dataSaveBlockPhoneNumberUser) {
            setOpenBlockAcquaintancePop(true);
        }
    }, [statusSaveBlockPhoneNumberUser, dataSaveBlockPhoneNumberUser]);

    return {
        title,
        fnSaveBlockAcquaintance,
        statusSaveBlockPhoneNumberUser,
        dataSaveBlockPhoneNumberUser,
        blockedUserCount,
    };
};