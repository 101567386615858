import {useEffect} from "react";
import {MobilePhoneOccupancyCIVO} from "@/site/api";
import {useRegisterAdapter} from "@dating/adapter/register/useRegisterAdapter";
import {usePhoneNumberStateRepo} from "@dating/ui/pages/register/phoneNumber/usePhoneNumberStateRepo";
import {useRegisterRepo} from "@dating/repository/register/useRegisterRepo";

/*
 * /metaverse/dating/register/phone
 * PhoneNumberContainer
 * 핸드폰번호입력
 */
export const usePhoneNumberService = () => {
    const {
        footerBtnDisable,
        setFooterBtnDisable,
    } = usePhoneNumberStateRepo();

    const {
        phoneNumber,
        setPhoneNumber,
        verifyReqCount,
        setVerifyReqCount,
        resetRegisterInfo,
    } = useRegisterRepo();

    const {
        sendVerificationCode,
    } = useRegisterAdapter();

    const handlePhoneNumberChange = (inputText: string) => {
        if (inputText.length > 11) {
            return;
        }
        if (!isNaN(Number(inputText))) {
            setPhoneNumber(inputText);
        }
    };

    const fnSendVerificationCode = () => {
        const map: MobilePhoneOccupancyCIVO = {
            mobilePhoneNumber: phoneNumber
        }
        sendVerificationCode(map).then((value) => {
            if (value && verifyReqCount === 0) {
                setVerifyReqCount(verifyReqCount + 1);
            }
        });
    };

    useEffect(() => {
        if (phoneNumber == "") {
            setFooterBtnDisable(true);
        } else {
            setFooterBtnDisable(false);
        }
    }, [phoneNumber]);

    return {
        phoneNumber,
        handlePhoneNumberChange,
        footerBtnDisable,
        fnSendVerificationCode,
        setFooterBtnDisable,
        verifyReqCount,
        resetRegisterInfo,
    };
}