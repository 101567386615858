import { reactEventEmitter } from '@/events/ReactEventEmitter';
import Network from '@/services/Network';
import {
	MetaDisposeRoomCIVO,
	MetaRoomCIVO,
	MetaRoomUserInCIVO,
	MetaRoomUserOutCIVO,
} from '@/site/api';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { useRoomRepo } from '@/stores/useRoomRepo';
import { useMetaRoomAdapter } from '@dating/adapter/meta/useMetaRoomAdapter';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useRoomService = () => {
	const [isRoomDataReady, setIsRoomDataReady] = useState(false);
	const { sessionId, myMetaUser, audioControl } = useMetaUserRepo();
	const {
		isMetaOpen,
		availableRooms,
		joinedRoomData,
		preLeftRoomId,
		setIsMetaOpen,
		setPreLeftRoomId,
		setDisposedRoomId,
	} = useRoomRepo();
	const { saveCreateRoom, saveRoomUserIn, saveRoomUserOut, saveDisposeRoom } =
		useMetaRoomAdapter();
	const hasCalledSaveRoomUserOut = useRef(false);
	const [shouldCreateRoom, setShouldCreateRoom] = useState(false);
	const [joinedRoomId, setJoinedRoomId] = useState<string | null>(null);
	const publicRoom = availableRooms.find(
		(room) => room.name.toLowerCase() === 'public',
	);
	const [isJoining, setIsJoining] = useState(false);

	// 메타버스 입장 함수
	const handleEnterMeta = useCallback(() => {
		if (isJoining) return;
		console.log('Entering meta, checking Network state...');

		// Network 인스턴스가 준비되었는지 확인
		const checkNetworkAndEmit = () => {
			if (Network.instance && Network.instance.lobby) {
				setIsJoining(true);
				try {
					console.log(
						'Network ready, emitting start room event handleEnterMeta...',
					);
					reactEventEmitter.emit('react-start-room', {
						roomType: 'PUBLIC',
						uuid: myMetaUser!.userUid,
						nickName: myMetaUser.nickName,
						gender: myMetaUser.gender,
						profileUrl: myMetaUser.profileUrl,
						x: 1024,
						y: 1376,
						anim: myMetaUser.avatarName,
						readyToConnect: myMetaUser.readyToConnect,
						mediaConnected: myMetaUser.mediaConnected,
						statusMessage: myMetaUser.introduce,
						audioStatus: audioControl,
					});
					reactEventEmitter.emit('react-join-room', {
						sessionId,
						uuid: myMetaUser.userUid,
						nickName: myMetaUser.nickName,
						gender: myMetaUser.gender,
						profileUrl: myMetaUser.profileUrl,
						name: myMetaUser.nickName,
						avatarName: myMetaUser!.avatarName,
						statusMessage: myMetaUser!.introduce,
						audioStatus: audioControl,
					});
				} finally {
					setIsJoining(false);
				}
				setShouldCreateRoom(true);
			} else {
				console.log('Network not ready, retrying in 500ms...');
				setTimeout(checkNetworkAndEmit, 500);
			}
		};

		checkNetworkAndEmit();
	}, [myMetaUser, sessionId, audioControl]);

	// 공개 방 참여 함수
	const handleJoinPublicRoom = useCallback(() => {
		console.log(
			'Joining public room, checking Network state handleJoinPublicRoom...',
		);
		const checkNetworkAndEmit = () => {
			if (Network.instance && Network.instance.lobby) {
				reactEventEmitter.emit('react-start-room', {
					roomType: 'PUBLIC',
					uuid: myMetaUser.userUid,
					nickName: myMetaUser.nickName,
					gender: myMetaUser.gender,
					profileUrl: myMetaUser.profileUrl,
					x: 1024,
					y: 1376,
					anim: myMetaUser.avatarName,
					readyToConnect: myMetaUser.readyToConnect,
					mediaConnected: myMetaUser.mediaConnected,
					statusMessage: myMetaUser.introduce,
					audioStatus: audioControl,
					roomId: publicRoom!.roomId,
					password: undefined,
				});
				reactEventEmitter.emit('react-join-room', {
					sessionId,
					uuid: myMetaUser.userUid,
					nickName: myMetaUser.nickName,
					gender: myMetaUser.gender,
					profileUrl: myMetaUser.profileUrl,
					name: myMetaUser.nickName,
					avatarName: myMetaUser.avatarName,
					statusMessage: myMetaUser.introduce,
					audioStatus: audioControl,
				});
				setShouldCreateRoom(false);
				setJoinedRoomId(publicRoom!.roomId);
			} else {
				console.log('Network not ready, retrying in 500ms...');
				setTimeout(checkNetworkAndEmit, 500);
			}
		};
		checkNetworkAndEmit();
	}, [myMetaUser, sessionId, audioControl, publicRoom]);

	// 공개 방 생성 함수
	const handleCreatePublicRoom = useCallback(async () => {
		if (joinedRoomData?.id) {
			const param: MetaRoomCIVO = {
				roomId: joinedRoomData.id,
				roomName: 'PUBLIC',
				isSecret: false,
				password: '',
				roomMaxUsers: Infinity,
				roomThemeId: 4,
			};
			saveCreateRoom(param);
			setJoinedRoomId(joinedRoomData.id);
		}
	}, [joinedRoomData, saveCreateRoom]);

	// 방 입장 저장 함수
	const handleSaveRoomUserIn = useCallback(() => {
		if (joinedRoomId) {
			const param: MetaRoomUserInCIVO = {
				roomId: joinedRoomId,
				password: '',
			};
			saveRoomUserIn(param);
		}
	}, [joinedRoomId, saveRoomUserIn]);

	// 방 퇴장 저장 함수
	const handleSaveRoomUserOut = useCallback(async () => {
		const currentRoomId = joinedRoomData?.id;
		if (currentRoomId && !hasCalledSaveRoomUserOut.current) {
			hasCalledSaveRoomUserOut.current = true;
			const param: MetaRoomUserOutCIVO = {
				roomId: currentRoomId,
			};
			try {
				saveRoomUserOut(param);
			} catch (error) {
				console.error('saveRoomUserOut 호출 실패:', error);
			} finally {
				setPreLeftRoomId(currentRoomId);
				hasCalledSaveRoomUserOut.current = false;
			}
		}
	}, [joinedRoomData, saveRoomUserOut, setPreLeftRoomId]);

	// 방 폐기 함수
	const handleDisposeRoom = useCallback(
		async (roomId: string) => {
			const param: MetaDisposeRoomCIVO = {
				roomId: roomId,
				isDispose: true,
			};
			try {
				saveDisposeRoom(param);
			} catch (error) {
				console.error('saveDisposeRoom 호출 실패:', error);
			} finally {
				setPreLeftRoomId('');
				setDisposedRoomId(roomId);
			}
		},
		[saveDisposeRoom, setPreLeftRoomId, setDisposedRoomId],
	);

	useEffect(() => {
		if (preLeftRoomId) {
			const roomExists = availableRooms.some(
				(room) => room.roomId === preLeftRoomId,
			);
			if (!roomExists) {
				handleDisposeRoom(preLeftRoomId);
			}
		}
	}, [preLeftRoomId, availableRooms, handleDisposeRoom]);

	// 메타버스 클릭 함수
	const handleClickMeta = useCallback(async () => {
		if (!Network.instance || !Network.instance.lobby || !myMetaUser) return;

		try {
			await handleSaveRoomUserOut();

			// 잠시 대기 - cleanup이 완료될 때까지
			await new Promise((resolve) => setTimeout(resolve, 100));

			if (publicRoom?.roomId && publicRoom.roomId !== joinedRoomId) {
				handleJoinPublicRoom();
			} else {
				handleEnterMeta();
			}
		} catch (error) {
			console.error('Error in handleClickMeta:', error);
		}
	}, [
		publicRoom,
		joinedRoomId,
		handleJoinPublicRoom,
		handleEnterMeta,
		handleSaveRoomUserOut,
		myMetaUser,
	]);
	useEffect(() => {
		const handleRoomDataChange = (isReady: boolean) => {
			setIsRoomDataReady(isReady);
		};
		const handleLeaveRoomId = (roomId: string) => {
			setPreLeftRoomId(roomId);
		};
		reactEventEmitter.on('react-leave-room', handleLeaveRoomId);
		reactEventEmitter.on('react-change-room-data', handleRoomDataChange);
		return () => {
			reactEventEmitter.off('react-leave-room', handleLeaveRoomId);
			reactEventEmitter.off('react-change-room-data', handleRoomDataChange);
		};
	}, [setPreLeftRoomId]);

	useEffect(() => {
		if (shouldCreateRoom && isRoomDataReady && joinedRoomData?.id) {
			handleCreatePublicRoom();
			setShouldCreateRoom(false);
		}
	}, [
		joinedRoomData,
		handleCreatePublicRoom,
		isRoomDataReady,
		shouldCreateRoom,
	]);

	useEffect(() => {
		if (publicRoom?.roomId === joinedRoomId) {
			handleSaveRoomUserIn();
		}
	}, [publicRoom?.roomId, joinedRoomId, handleSaveRoomUserIn]);

	return {
		isMetaOpen,
		setIsMetaOpen,
		handleJoinPublicRoom,
		handleClickMeta,
	};
};
