import {useLikeSendStateRepo} from '@dating/ui/pages/like/likeSend/useLikeSendStateRepo';
import {useLikeAdapter} from '@dating/adapter/like/useLikeAdapter';
import {useDatingRepo} from '@dating/repository/dating/useDatingRepo';
import {useLikeRepo} from '@dating/repository/like/useLikeRepo';
import {type RemoveLikeViewCIVO, UserLikeCIVO} from '@/site/api';
import {useEffect} from 'react';
import {usePaymentRepo} from "@dating/stores/PaymentStore";
import useLoungeRepo from "@dating/repository/lounge/useLoungeRepo";

export const useLikeSendService = () => {
    const limit = 3;

    const {
        noDataUserLike,
        setNoDataUserLike,
        likeUsers,
        setLikeUsers,
        likeUserPage,
        setLikeUserPage,
        noDataHighlyRated,
        setNoDataHighlyRated,
        highlyRatedUsers,
        setHighlyRatedUsers,
        highlyRatedPage,
        setHighlyRatedPage,
        noDataPost,
        setNoDataPost,
        likedPosts,
        setLikedPosts,
        likedPostPage,
        setLikedPostPage,
        noDataComments,
        setNoDataComments,
        commentPosts,
        setCommentPosts,
        commentPostsPage,
        setCommentPostsPage,
        fadeIn,
        setFadeIn,
    } = useLikeSendStateRepo();

    const {
        sentLikeTitSets,
    } = useLikeRepo();

    const {
        setOpenPaymentPop,
    } = usePaymentRepo();

    const {
        setSingleFeedId,
    } = useLoungeRepo();

    const {
        srcPrefix,
        setUserProfileOpen,
    } = useDatingRepo();

    const {
        getSendUserLike,
        getSendUserHighScore,
        saveUserLike,
        removeSendUserLike,
        removeSendHighlyRatedUsers,
        getLikedPosts,
        getSendPostComment,
    } = useLikeAdapter();
    /////////////////////////////////////////

    /*
     * fnGetSendUserLike
     * 좋아요 보낸 유저 리스트 조회
     */
    const fnGetSendUserLike = () => {
        const dateTime = new Date().getTime();
        getSendUserLike(likeUserPage, limit, dateTime).then((value) => {
            if (value.length > 0) {
                const newItem = likeUsers;
                const list = newItem.concat(value);
                setLikeUsers(list);
                setLikeUserPage(likeUserPage + 1);
                setNoDataUserLike(value[0].addButtonOnOff);
            }
        });
    };

    /*
     * fnGetSendUserHighScore
     * 내가 높게 평가한 유저 리스트 조회
     */
    const fnGetSendUserHighScore = () => {
        const dateTime = new Date().getTime();
        getSendUserHighScore(highlyRatedPage, limit, dateTime).then((value) => {
            if (value.length > 0) {
                const newItem = highlyRatedUsers;
                const list = newItem.concat(value);
                setHighlyRatedUsers(list);
                setHighlyRatedPage(likeUserPage + 1);
                setNoDataHighlyRated(value[0].addButtonOnOff);
            }
        });
    };

    /*
     * fnGetLikedPosts
     * 좋아요 보낸 게시물 조회
     */
    const fnGetLikedPosts = () => {
        const dateTime = new Date().getTime();
        getLikedPosts(likedPostPage, limit, dateTime).then((value) => {
            if (value.length > 0) {
                const newItem = likedPosts;
                const list = newItem.concat(value);
                setLikedPosts(list);
                setLikedPostPage(likeUserPage + 1);
                setNoDataPost(value[0].addButtonOnOff);
            }
        });
    };

    /*
     * fnGetSendPostComment
     * 댓글 단 게시물 조회
     */
    const fnGetSendPostComment = () => {
        const dateTime = new Date().getTime();
        getSendPostComment(commentPostsPage, limit, dateTime).then((value) => {
            if (value.length > 0) {
                const newItem = commentPosts;
                const list = newItem.concat(value);
                setCommentPosts(list);
                setCommentPostsPage(likeUserPage + 1);
                setNoDataComments(value[0].addButtonOnOff);
            }
        });
    };

    /*
     * fnSaveUserLike
     * 프로필 카드 좋아요 버튼 클릭
     */
    const fnSaveUserLike = (userUid: string, state: boolean) => {
        const map: UserLikeCIVO = {
            likeUserUid: userUid,
            likeYn: state,
        };
        saveUserLike(map);
    };

    /*
     * fnRemoveSendUserLike
     * 보낸 좋아요 뷰 삭제
     */
    const fnRemoveSendUserLike = (id: string, type: string) => {
        const map: RemoveLikeViewCIVO = {
            likeViewId: id,
            removeType: type,
        };
        removeSendUserLike(map).then((value) => {
            if (value) {
                setLikeUsers([]);
                setLikeUserPage(1);
            }
        });
    };

    /*
     * fnRemoveSendHighlyRatedUsers
     * 높게 준 평가 뷰 삭제
     */
    const fnRemoveSendHighlyRatedUsers = (id: string, type: string) => {
        const map: RemoveLikeViewCIVO = {
            likeViewId: id,
            removeType: type,
        };
        removeSendHighlyRatedUsers(map).then((value) => {
            if (value) {
                setHighlyRatedUsers([]);
                setHighlyRatedPage(1);
            }
        });
    };

    useEffect(() => {
        if (likeUsers.length === 0 && likeUserPage === 1) {
            fnGetSendUserLike();
        }
    }, [likeUsers,
        likeUserPage]);

    useEffect(() => {
        if (highlyRatedUsers.length === 0 && highlyRatedPage === 1) {
            fnGetSendUserHighScore();
        }
    }, [highlyRatedUsers, highlyRatedPage]);

    useEffect(() => {
        if (likedPosts.length === 0 && likedPostPage === 1) {
            fnGetLikedPosts();
        }
    }, [likedPosts, likedPostPage]);

    useEffect(() => {
        if (commentPosts.length === 0 && commentPostsPage === 1) {
            fnGetSendPostComment();
        }
    }, [commentPosts, commentPostsPage]);

    useEffect(() => {
        setFadeIn(true);
    }, []);

    return {
        sentLikeTitSets,
        noDataUserLike,
        fnGetSendUserLike,
        likeUsers,
        noDataHighlyRated,
        highlyRatedUsers,
        fnGetSendUserHighScore,
        srcPrefix,
        setUserProfileOpen,
        fnSaveUserLike,
        fnRemoveSendUserLike,
        fnRemoveSendHighlyRatedUsers,
        setOpenPaymentPop,
        fnGetLikedPosts,
        noDataPost,
        likedPosts,
        fnGetSendPostComment,
        noDataComments,
        commentPosts,
        fadeIn,
        setSingleFeedId,
    };
};
