import React, {
	createContext,
	useState,
	useMemo,
	ReactNode,
	useContext,
	useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';

interface MetaverseContextType {
	isInMetaverseRoot: boolean;
	setIsInMetaverseRoot: React.Dispatch<React.SetStateAction<boolean>>;
}

const MetaverseContext = createContext<MetaverseContextType | undefined>(
	undefined,
);

interface MetaverseProviderProps {
	children: ReactNode;
}

export const MetaverseProvider: React.FC<MetaverseProviderProps> = ({
	children,
}) => {
	const [isInMetaverseRoot, setIsInMetaverseRoot] = useState(false);
	const location = useLocation();

	useEffect(() => {
		const newIsInMetaverseRoot = location.pathname === '/metaverse';
		if (newIsInMetaverseRoot !== isInMetaverseRoot) {
			setIsInMetaverseRoot(newIsInMetaverseRoot);
		}
	}, [location, isInMetaverseRoot]);

	const value = useMemo(
		() => ({
			isInMetaverseRoot,
			setIsInMetaverseRoot,
		}),
		[isInMetaverseRoot],
	);

	return (
		<MetaverseContext.Provider value={value}>
			{children}
		</MetaverseContext.Provider>
	);
};

export const useMetaverse = () => {
	const context = useContext(MetaverseContext);
	if (context === undefined) {
		throw new Error('useMetaverse must be used within a MetaverseProvider');
	}
	return context;
};
