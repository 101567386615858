import GameScene from "../GameScene";

export class CreateWallObjectLayer {
  constructor(private scene: GameScene) {}

  // 오브젝트 레이어에서 벽 오브젝트를 생성하는 함수
  add(
    objectLayerName: string, // 오브젝트 레이어 이름
    key: string, // 벽 오브젝트의 이미지 키
    tilesetName: string, // 타일셋 이름
    collidable: boolean // 플레이어와 충돌하는지 여부
  ) {
    const { physics, map } = this.scene;
    const group = physics.add.staticGroup();
    const objectLayer = map.getObjectLayer(objectLayerName);

    objectLayer?.objects.forEach((object) => {
      const actualX = object.x! + object.width! * 0.5;
      const actualY = object.y! - object.height! * 0.5;
      group
        .get(
          actualX,
          actualY,
          key,
          object.gid! - map.getTileset(tilesetName)!.firstgid
        )
        .setDepth(actualY + 150);
    });

    // 플레이어와 충돌하는 경우 충돌체 추가
    if (this.scene.myPlayer && collidable) {
      physics.add.collider([this.scene.myPlayer], group);
    }
  }
}
