import useLeaveChatStore from '@dating/stores/LeaveChatStore';

const LeaveChatButtonView = ({ setOpenLeaveChat, setOpenBlock }) => {
	const { openChatMenuPop } = useLeaveChatStore();
	return (
		<div className={`${openChatMenuPop ? 'menuPop active' : 'menuPop'}`}>
			<button
				type="button"
				className="popOnBtn color-red"
				onClick={() => {
					setOpenLeaveChat(true);
				}}
			>
				방 나가기
			</button>
			{/* <a
				onClick={() => {
					setOpenBlock(true);
				}}
			>
				신고하기
			</a> */}
			{/* <a
				onClick={() => {
					setOpenBlock(true);
				}}
			>
				차단하기
			</a> */}
		</div>
	);
};

export default LeaveChatButtonView;
