import ButtonHeaderView from "@dating/ui/components/buttonHeader/ButtonHeaderView";
import AgreeListView from "@dating/ui/pages/settings/agree/views/AgreeListView";
import {useAgreeService} from "@dating/ui/pages/settings/agree/useAgreeService";

const AgreeContainer = () => {
	const {
		selectedTerm,
	} = useAgreeService();

	return (
		<div className="rightShow active">
			<ButtonHeaderView right={false}/>
			<AgreeListView
				selectedTerm={selectedTerm}
			/>
		</div>
	);
};

export default AgreeContainer;
