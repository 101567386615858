import {
	registerGameObjects,
	unregisterGameObjects,
} from '@/characters/phaserFactoryRegister';
import GameScene from '../GameScene';
import { AssetLoader } from './AssetLoader';
import { GameSceneHandler } from './GameSceneHandlers';
import { GameSceneNetwork } from './GameSceneNetwork';
import { GameSceneSetup } from './GameSceneSetup';

export class SceneInitializer {
	private scene: GameScene;

	constructor(scene: GameScene) {
		this.scene = scene;
	}

	init(data: { roomTheme: any }) {
		this.scene.roomTheme = data.roomTheme;
		unregisterGameObjects();
		registerGameObjects();
		this.scene.sceneSetup = new GameSceneSetup(
			this.scene,
			this.scene.roomTheme,
		);
		this.scene.network = new GameSceneNetwork(this.scene);
		this.scene.handler = new GameSceneHandler(this.scene);
		this.scene.assetLoader = new AssetLoader(this.scene, this.scene.roomTheme);
	}

	initializeScene() {
		this.scene.network.networkCallback();
		this.scene.map = this.scene.sceneSetup.setupTileMap();
		this.scene.sceneSetup.setupPlayer();
		this.scene.sceneSetup.setupCollisionLayer();
		this.scene.sceneSetup.setupObjectLayer();
		this.scene.sceneSetup.setupWall();
		this.scene.sceneSetup.setupCamera();
		this.scene.sceneSetup.setupKeyboard();
		this.scene.phaserEventController.onGameSceneReady();
	}

	shutdown() {
		console.log('Shutting down SceneInitializer...');

		// 1. 플레이어 객체 파괴
		if (this.scene.myPlayer) {
			this.scene.myPlayer.destroy();
		}

		// 2. otherPlayerMap에 있는 모든 플레이어 객체 파괴
		this.scene.otherPlayerMap.forEach((otherPlayer) => otherPlayer.destroy());
		this.scene.otherPlayerMap.clear();

		// 3. 각종 맵에 있는 게임 객체 제거
		this.scene.chairMap.forEach((chair) => chair.destroy());
		this.scene.chairMap.clear();

		this.scene.computerMap.forEach((computer) => computer.destroy());
		this.scene.computerMap.clear();

		this.scene.whiteboardMap.forEach((whiteboard) => whiteboard.destroy());
		this.scene.whiteboardMap.clear();

		// 4. 네트워크 리스너 및 리소스 정리
		this.scene.roomReceiveProcess?.resetListeners();
		this.scene.events.off('shutdown', this.shutdown, this);

		// 5. 씬에서 텍스처와 스프라이트 제거
		this.scene.children.removeAll(); // 씬의 모든 자식 객체 제거

		console.log('Scene resources cleaned up and destroyed.');
	}
}
