import { Link } from 'react-router-dom';
import { UserProfileCOVO } from '@/site/api';

interface props {
    myProfile: UserProfileCOVO;
    srcPrefix: string;
    setMyProfileOpen: (myProfileOpen: boolean) => void;
}

const SmallProfileContainer = ({
                                   myProfile,
                                   srcPrefix,
                                   setMyProfileOpen,
                               }: props) => {
    return (
        <div>
            <Link
                to="/metaverse/dating/profile"
                onClick={() => {
                    setMyProfileOpen(true);
                }}
            >
                <button type="button" className="profileSmImg">
                    <img
                        src={
                            myProfile && myProfile.profileUrl.length > 0
                                ? srcPrefix + myProfile.profileUrl[0]
                                : '/assets/img/test/boy.png'
                        }
                        alt="profile-img"
                    />
                </button>
            </Link>
        </div>
    );
};

export default SmallProfileContainer;
