import { Link } from 'react-router-dom';
import { PostInfoCOVO } from "@/site/api";

interface props {
    post: PostInfoCOVO;
    deleteContent: (id: string) => void;
    openMenuPop: boolean;
    setModifyPostId: (postId: string) => void;
    fnCommonConfirmPopOn: (message: string, func: any, param: any) => any;
}

const FeedEditMenuView = ({
                              post,
                              deleteContent,
                              openMenuPop,
                              setModifyPostId,
                              fnCommonConfirmPopOn,
                          }: props) => {


    return (
        <div className={`${openMenuPop ? 'menuPop active' : 'menuPop'}`}>
            {
                post.isEditable ?
                    (
                        <button
                            type="button"
                            className="popOnBtn"
                        >
                            <Link
                                to="/metaverse/dating/lounge/form"
                                onClick={() => {
                                    setModifyPostId(post.postId);
                                }}
                            >
                                수정
                            </Link>
                        </button>
                    ) :
                    null
            }

            {
                post.isDeletable ?
                    (
                        <button
                            type="button"
                            className="popOnBtn"
                            // onClick={() => deleteContent(post.postId)}
                            onClick={() => fnCommonConfirmPopOn("게시글을 삭제하시겠습니까?", deleteContent, post.postId)}
                        >
                            삭제
                        </button>
                    )
                    :
                    null
            }
        </div>
    );
};

export default FeedEditMenuView;
