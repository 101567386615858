import { UserWaitingInfoCOVO } from '@/site/api';

interface props {
	userData: UserWaitingInfoCOVO;
}

const WaitingProfileInfoView = ({ userData }: props) => {
	return (
		<div className="stepLastBox">
			<ul className="infoList">

				{/*직업*/}
				<li className={`ic-job ${userData?.job ? 'on' : ''}`}>
					{userData?.job ? userData?.job : '개발자'}
				</li>

				{/*키*/}
				<li className={`ic-height ${userData?.height ? 'on' : ''}`}>
					{(userData?.height ? userData?.height : '') + 'cm'}
				</li>

				{
					// 	라이프스타일
					userData?.lifeStyle.length > 0 ?
						<li className="ic-life on">
							{userData?.lifeStyle.length > 0 ?
								userData?.lifeStyle.join(', ')
								: null
							}
						</li>
						: null
				}

				{
					// 	관심사
					userData?.interest.length > 0 ?
						<li className="ic-like on">
							{userData?.interest.length > 0 ?
								userData?.interest.join(', ')
								: null
							}
						</li>
						: null
				}

				{
					// 	흡연
					userData?.smoke != '' ?
						<li className="ic-smoking on">
							{userData?.smoke}
						</li>
						: null
				}
			</ul>
		</div>
	);
};

export default WaitingProfileInfoView;
