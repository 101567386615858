import useLoungeRepo from "@dating/repository/lounge/useLoungeRepo";

export const useFeedSearchService = () => {

    const {
        searchResult,
    } = useLoungeRepo();

    /*
     * thumbnailClick
     * 포스트 썸네일 클릭 시 포스트로 이동
     */
    const thumbnailClick = (postId: string) => {
    };

    return {
        searchResult,
        thumbnailClick,
    };
};