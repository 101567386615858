import { CheckState } from "./CheckState";
import { MyPlayerState, StateParams } from "./MyPlayerState";
import { StopHelloState } from "./impl/hello/StopHelloState";
import { DanceState } from "./impl/dance/DanceState";
import { IntegratedState } from "./impl/IntegratedState";
import { JumpState } from "./impl/jump/JumpState";
import { MoveState } from "./impl/move/MoveState";
import { SittingState } from "./impl/sit/SittingState";
import { UnChairSittingState } from "./impl/sit/UnChairSittingState";
import { StopDanceState } from "./impl/dance/StopDanceState";
import { HelloState } from "./impl/hello/HelloState";

export class MyPlayerStateFactory {
  constructor() {}

  /**
   * 주어진 StateParams를 바탕으로 적절한 MyPlayerState 인스턴스를 생성하여 반환함.
   * @param param MyPlayerState 생성에 필요한 매개변수들을 포함한 객체
   * @returns 적절한 MyPlayerState 인스턴스
   */
  createState(param: StateParams): MyPlayerState {
    const checker = new CheckState(param);

    if (checker.isOverlapF() || checker.isChangeSitting()) {
      return new IntegratedState(param);
    }

    if (checker.isChangeUnSitting()) {
      return new UnChairSittingState(param);
    }

    if (checker.isSitting()) {
      return new SittingState();
    }

    if (checker.sayHello()) {
      return new HelloState(param);
    }

    if (checker.stopHello()) {
      return new StopHelloState(param);
    }

    if (checker.dancing()) {
      return new DanceState(param);
    }

    if (checker.stopDance()) {
      return new StopDanceState(param);
    }

    if (checker.jumping()) {
      return new JumpState(param);
    }

    return new MoveState(param);
  }
}
