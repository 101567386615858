import {Link} from 'react-router-dom';
import {ReceiveLikeUserCOVO} from '@/site/api';

interface props {
    setOpenPaymentPop: (openPaymentPop: boolean) => void;
    likeUsers: ReceiveLikeUserCOVO[];
    srcPrefix: string;
    setUserProfileOpen: (userUid: string) => void;
    fnRemoveSendUserLike: (id: string, type: string) => void;
    setMessageTargetUser: (messageTargetUser: string) => void;
}

const SentLikeListView = ({
                              setOpenPaymentPop,
                              likeUsers,
                              srcPrefix,
                              setUserProfileOpen,
                              fnRemoveSendUserLike,
                              setMessageTargetUser,
                          }: props) => {
    return (
        <div
            className={`likeProfileList ${
                likeUsers.length === 0 ? 'likeProfileList-empty' : ''
            }`}
        >
            {likeUsers.length > 0
                ? likeUsers.map((item, i) => {
                    return (
                        <div
                            className="profileType"
                            // style={{
                            //     display: 'flex',
                            // }}
                            key={item.likeUserUid + i}
                        >
                            <div className="proLeft">
                                <div className="proImg">
                                    <Link
                                        to="/metaverse/dating/profile"
                                        onClick={() => {
                                            setUserProfileOpen(item.likeUserUid);
                                        }}
                                        state={{
                                            isOtherProfile: true,
                                        }}
                                    >
                                        <img
                                            src={srcPrefix + item.profileUrl}
                                            alt="profile-img"
                                        />
                                    </Link>
                                </div>
                                <div className="proName margin-l12">
                                    <p className="bold padding-b4">{item.nickName}</p>
                                    <p className="fz14 color-666">
                                        {/*{item.age + '세, ' + (item.location ? item.location : "위치 정보 없음")}*/}
                                        {item.age + '세'}
                                    </p>
                                </div>
                            </div>
                            <div className="ProRight">
                                <button
                                    type="button"
                                    className="shareBtn fixOn"
                                    onClick={() => {
                                        if (item.likeUserUid != "") {
                                            setOpenPaymentPop(true);
                                            setMessageTargetUser(item.likeUserUid);
                                        }
                                    }}
                                />
                                <button
                                    type="button"
                                    className="closeBtn margin-l5"
                                    onClick={() => {
                                        fnRemoveSendUserLike(item.likeViewId, "send");
                                    }}
                                />
                            </div>
                        </div>
                    );
                    likeUsers;
                })
                : null}
        </div>
    );
};

export default SentLikeListView;
