import {FeedFormType} from "@dating/model/LoungeModel";

interface props {
    feedForm: FeedFormType;
    handleContentChange: (value: string) => void;
};

const FeedFormView = ({
                          feedForm,
                          handleContentChange,
                      }: props) => {
    return (
        <div className="input padding-t16">
            <textarea
                defaultValue={feedForm.content}
                onChange={(e) => {
                    handleContentChange(e.target.value);
                }}
                placeholder="내용을 입력해 주세요"
            />
        </div>
    );
};

export default FeedFormView;
