import { useEffect, useRef } from 'react';
import { useChatService } from './service/useChatService';
import ChatListView from './views/ChatListView';

const ChatContainer = () => {
	const { focused, showChat, chatMessages, handleCloseChat } = useChatService();
	const messagesEndRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (focused) {
			inputRef.current?.focus();
		} else {
			inputRef.current?.blur();
		}
	}, [focused]);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		scrollToBottom();
		inputRef.current?.focus();
	}, [chatMessages]);

	return (
		<div className="PlayChat_chat_container">
			{showChat && (
				<ChatListView
					handleCloseChat={handleCloseChat}
					messagesEndRef={messagesEndRef}
					chatMessages={chatMessages}
				/>
			)}
		</div>
	);
};

export default ChatContainer;
