interface props {
    backStep: () => void;
    postId: string | undefined;
}

const FeedFormHeaderView = ({
                                backStep,
                                postId,
                            }: props) => {
    return (
        <header>
            <div className="headerWrap">
                <div className="h-left"></div>
                <div className="h-center">
                    <span className="fz18">
                        {
                            postId === "" || postId === undefined ?
                                "새 게시글"
                                : "게시글 수정"
                        }
                    </span>
                </div>
                <div className="h-right">
                    <button
                        type="button"
                        className="btnClose"
                        onClick={backStep}
                    />
                </div>
            </div>
        </header>
    );
};

export default FeedFormHeaderView;
