const ReactionButtonView = ({
	closeButton,
	setCloseButton,
	handleDanceAction,
	handleHelloAction,
	handleReactionClick,
}) => {
	return (
		<div className="emtTopBox">
			<div
				className={`emtViewer ${closeButton ? 'active' : ''}`}
				onClick={() => {
					setCloseButton(!closeButton);
				}}
			/>
			<div className="emtList">
				<button onClick={handleDanceAction}>춤</button>
				<button onClick={handleHelloAction}>인사</button>
				<button onClick={() => handleReactionClick('👍')}>👍</button>
				<button onClick={() => handleReactionClick('👏')}>👏</button>
				<button onClick={() => handleReactionClick('❤️')}>❤️</button>
				<button onClick={() => handleReactionClick('😆')}>😆</button>
				<button onClick={() => handleReactionClick('😂')}>😂</button>
			</div>
		</div>
	);
};

export default ReactionButtonView;
