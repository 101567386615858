import { useState } from 'react';

export const useGenderSelectStateRepo = () => {
	const [btnActivate, setBtnActivate] = useState(true);

	// api 응답결과
	const [response, setResponse] = useState<boolean>(false);

	return {
		btnActivate,
		setBtnActivate,
		response,
		setResponse,
	};
};
