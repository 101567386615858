import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import AgreeListView from '@dating/ui/pages/settings/agree/views/AgreeListView';

export const Policy03Container = () => {
	return (
		<div className="rightShow active">
			<ButtonHeaderView right={false} />

			<div className="popConWrap bdNone">
				<div className="inner">
					<div className="agreeWrap">
						<div className="titleWrap">
							<h2 className="title01">민감정보 수집 및 이용동의</h2>
							<p className="titleSub01">
								<i>시행일자 : 2024.10.31 ~ 시행</i>
							</p>
						</div>

						<p>
							‘HUSH 서비스(이하 ‘본 서비스’)’를 운영하는 베리드코리아(이하’회사’)는 수집한 개인정보를 관리함에 있어서 「개인정보 보호법」에서 규정하고 있는 책임과 의무를
							준수하고 있습니다. 개인정보 수집 및 이용 외 민감정보 수집 및 이용과 관련하여 다음의 몇가지 사항을 안내 드리니 내용을 숙지하신 후 해당 내용에 동의하여 주시기
							바랍니다.
						</p>

						<div className="section section01">
							<h3 className="title02 margin-t40">민감정보 수집 및 이용</h3>
							<div className="tbl-list">
								<table>
									<thead>
									<tr>
										<th>수집목적</th>
										<th>필수항목</th>
										<th>보유기간</th>
									</tr>
									</thead>
									<tbody>
									<tr>
										<td>이용자 및 선호하는 사람의 선호도 매칭</td>
										<td>종교</td>
										<td>회원 탈퇴 시까지</td>
									</tr>
									<tr>
										<td>본인 인증을 하기로 선택한 경우 사용자의 신원 확인</td>
										<td>얼굴 구조 정보</td>
										<td>회원 탈퇴 시까지</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};