import { usePhoneNumberService } from "@dating/ui/pages/register/phoneNumber/service/usePhoneNumberService";
import PhoneNumberInputView from "@dating/ui/pages/register/phoneNumber/views/PhoneNumberInputView";
import PhoneNumberFooterView from "@dating/ui/pages/register/phoneNumber/views/PhoneNumberFooterView";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const PhoneNumberContainer = () => {
    const {
        phoneNumber,
        handlePhoneNumberChange,
        footerBtnDisable,
        fnSendVerificationCode,
        verifyReqCount,
        resetRegisterInfo,
    } = usePhoneNumberService();

    const navigate = useNavigate();
    useEffect(() => {
        if (verifyReqCount > 0) {
            navigate("/metaverse/dating/register/occupancy");
        }
    }, [verifyReqCount]);

    return (
        <div className="wrap noFooter">
            <header className="h-fixed">
                <div className="headerWrap">
                    <div className="h-left">
                        <Link to={"/metaverse/dating/register"}
                              onClick={() => {
                                  resetRegisterInfo();
                              }}
                        >
                            <button
                                className="btnClose"
                                type="button"
                            />
                        </Link>
                    </div>
                    <div className="h-center"></div>
                </div>
            </header>

            <div className="contentsWrap">
                <div className="titleWrap">
                    <h2 className="title01">전화번호 인증</h2>
                    <p className="titleSub01">
                        본인인증용이며 타인에게 절대 공개되지 않습니다
                    </p>
                </div>
                <PhoneNumberInputView
                    phoneNumber={phoneNumber || ''}
                    handlePhoneNumberChange={handlePhoneNumberChange}
                />
            </div>
            <PhoneNumberFooterView
                fnSendVerificationCode={fnSendVerificationCode}
                footerBtnDisable={footerBtnDisable}
            />
        </div>
    );
};

export default PhoneNumberContainer;
