import React from "react";

interface props {
    formattedTime: string;
}

const PhoneVerificationTimeView = ({
                                       formattedTime,
                                   }: props) => {
    return (
        <div className="margin-t16 text-c">
            <span className="fz14 color-red">{formattedTime}</span>
        </div>
    );
};

export default PhoneVerificationTimeView;
