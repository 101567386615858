export class PlayerReactionBubble {
  playerReactionBubble: Phaser.GameObjects.Container;
  private tween?: Phaser.Tweens.Tween;

  constructor(
    private scene: Phaser.Scene,
    private playerContainer: Phaser.GameObjects.Container
  ) {
    // 이모지 상자 버블을 위한 새로운 컨테이너를 생성
    this.playerReactionBubble = this.scene.add.container(0, -25).setDepth(6000);
    // 생성한 컨테이너를 플레이어 컨테이너에 추가
    this.playerContainer.add(this.playerReactionBubble);
  }

  updateReactionBubble(emoji: string) {
    // 기존 Tween이 있다면 중지
    if (this.tween) {
      this.tween.stop();
    }

    // 이전 표시된 이모지 상자 버블이 있다면 삭제
    this.clearReactionBubble();

    // 내부 텍스트 생성
    const innerText = this.scene.add
      .text(0, 0, emoji)
      .setFontSize(24)
      .setResolution(2)
      .setOrigin(0.5);

    this.playerReactionBubble.add(innerText);

    // 새로운 Tween 시작
    this.tween = this.scene.tweens.add({
      targets: this.playerReactionBubble,
      alpha: 0,
      y: -50,
      duration: 2000,
      onComplete: () => {
        this.clearReactionBubble();
      },
    });
  }

  private clearReactionBubble() {
    this.playerReactionBubble.removeAll(true);
    this.playerReactionBubble = this.scene.add.container(0, -25).setDepth(6000);
    this.playerContainer.add(this.playerReactionBubble);
  }
}
