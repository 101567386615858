import { Link } from "react-router-dom";

interface props {
    previewURLs: string[];
}

const EditProfileImgView = ({
                                previewURLs,
                            }: props) => {
    return (
        <>
            <div className="imgAddBox">
                {[0, 1].map((id, index) => {
                    if (id === 0) {
                        return (
                            <div key={id + index}>
                                <Link
                                    to="/metaverse/dating/profile/editProfileImg"
                                >
                                    <label htmlFor={'img' + id}></label>
                                    <img
                                        src={previewURLs[id]}
                                        alt="preview-img"
                                        style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                    />
                                </Link>
                                <span className="fz12">필수</span>
                            </div>
                        );
                    } else {
                        if (previewURLs[id]) {
                            return (
                                <div key={id + index}>
                                    <Link
                                        to="/metaverse/dating/profile/editProfileImg"
                                    >
                                        <label htmlFor={'img' + id}></label>
                                        <img
                                            src={previewURLs[id]}
                                            alt="preview-img"
                                            style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                        />
                                    </Link>
                                </div>
                            );
                        } else {
                            return (
                                <div key={id + index}>
                                    <Link
                                        to="/metaverse/dating/profile/editProfileImg"
                                    >
                                        <label htmlFor={'img' + id}></label>
                                    </Link>
                                </div>
                            );
                        }
                    }
                })}
            </div>
            <div className="imgAddBox box4 margin-t8 margin-b32">
                {[2, 3, 4, 5].map((id, index) => {
                    if (previewURLs[id]) {
                        return (
                            <div key={id + index}>
                                <Link
                                    to="/metaverse/dating/profile/editProfileImg"
                                >
                                    <label htmlFor={'img' + id}></label>
                                    <img
                                        src={previewURLs[id]}
                                        alt="preview-img"
                                        style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                    />
                                </Link>
                            </div>
                        );
                    } else {
                        return (
                            <div key={id + index}>
                                <Link
                                    to="/metaverse/dating/profile/editProfileImg"
                                >
                                    <label htmlFor={'img' + id}></label>
                                </Link>
                            </div>
                        );
                    }
                })}
            </div>
        </>
    );
};

export default EditProfileImgView;
