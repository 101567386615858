const WithdrawalWrapView = () => {
	return (
		<div className="popConWrap hiddenCon pb148">
			<div className="inner padding-t8">
				<div className="titleWrap">
					<h2 className="title01">정말 계정을 삭제하시겠어요?</h2>
					<p className="titleSub01">
						계정을 삭제하면 5일 동안 재가입이 불가하며,
						<br />
						잔여 비즈를 포함한 모든 데이터가 삭제되어 복구가 불가합니다. 신중히
						결정해주세요.
					</p>
				</div>
				<div className="icoCenter text-c">
					<img src="/assets/img/common/ico-id-delete.svg" width="100" alt="" />
				</div>
			</div>
		</div>
	);
};

export default WithdrawalWrapView;
