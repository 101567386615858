import {useState} from "react";
import {SettingTermsCOVO, UserTermsCIVO} from "@/site/api";

export const useAgreementTermsStateRepo = () => {
    // 버튼활성화
    const [btnDisabled, setBtnDisabled] = useState(true);

    // 전체동의
    const [allAgreement, setAllAgreement] = useState(false);

    return {
        btnDisabled,
        setBtnDisabled,
        allAgreement,
        setAllAgreement,
    };
};