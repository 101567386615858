import { useNavigate } from "react-router-dom";

export const IdealMatchFinderHeaderView = () => {
    const navigate = useNavigate();

    return (
        <header>
            <div className="headerWrap">
                <div className="h-left">
                    <button type="button" className="btnBack" onClick={() => {
                        navigate('/metaverse/dating/home/ideal');
                    }}/>
                </div>
                <div className="h-center">
                    <p className="title02">HUSH 추천</p>
                </div>
            </div>
        </header>
    );
};