const RoomThemeBoxView = ({
	activeRoomThemeIndex,
	handleRoomThemeChange,
	roomThemeSelectedList,
}) => {
	return (
		<div className="themeBox">
			<p className="fz16Fw700 padding-b8">테마 선택</p>
			<div className="themeList">
				{roomThemeSelectedList
					.slice(0, -1)
					.map((roomTheme: any, index: number) => (
						<div
							key={roomTheme.id}
							className={`themeItem ${
								activeRoomThemeIndex === index ? 'active' : ''
							}`}
							onClick={() => handleRoomThemeChange(index)}
						>
							<img src={roomTheme.img} alt={roomTheme.label} />
							<span>{roomTheme.label}</span>
						</div>
					))}
			</div>
		</div>
	);
};

export default RoomThemeBoxView;
