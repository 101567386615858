interface props {
	selectedTerm: string;
}

const AgreeListView = ({
						   selectedTerm,
					   }: props) => {
	return (
		<div className="popConWrap bdNone">
			<div className="inner">
				<div className="agreeWrap">
					<div className="titleWrap">
						{/*<h2 className="title01">HUSH 서비스 이용약관</h2>*/}
						<h2 className="title01">
							{selectedTerm}
						</h2>
						<p className="titleSub01">
							{/*<i>시행일자 : 2024.02.15 ~ 시행</i>*/}
						</p>
					</div>
					<h4 className="title02 margin-t40">
						{/*제1장 총칙*/}
					</h4>
					<div>
						<p className="titleSub02">
							{/*제1조 목적*/}
						</p>
						<p>
							{/*HUSH를 서비스하는 Berith(이하 ‘회사’)는 여러분이 H를 서비스하는*/}
							{/*Berith(이하 ‘회사’)는 여러분이 HUSH를 서비스하는 Berith(이하*/}
							{/*‘회사’)는 여러분이 HUSH를 서스하는 Berith(이하 ‘회사’)는 여러분이*/}
							{/*HUSH를 서비스하는 Berith(이하 ‘회사’)는 여러분이 HUSH를 서비스하는*/}
							{/*ith(이하 ‘회사’)는 여러분이*/}
						</p>
					</div>
					<div>
						<p className="titleSub02">
							{/*제2조 약관의 게시와 효력, 개정*/}
						</p>
						<p>
							{/*HUSH를 서비스하는 Berith(이하 ‘회사’)는 여러분이 H를 서비스하는*/}
							{/*Berith(이하 ‘회사’)는 여러분이 HUSH를 서비스하는 Berith(이하*/}
							{/*‘회사’)는 여러분이 HUSH를 서스하는 Berith(이하 ‘회사’)는 여러분이*/}
							{/*HUSH를 서비스하는 Berith(이하 ‘회사’)는 여러분이 HUSH를 서비스하는*/}
							{/*ith(이하 ‘회사’)는 여러분이*/}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AgreeListView;
