import {PostInfoCOVO} from "@/site/api";

interface props {
    results: PostInfoCOVO[],
    thumbnailClick: (postId: string) => void;
    srcPrefix: string;
}

const FeedSearchResultView = ({
                                  results,
                                  thumbnailClick,
                                  srcPrefix,
                              }: props) => {
    return (
        <div className="metaPopContent gallList">
            <div>
                {results?.map((post, i) => {
                    if (i < 6) {
                        return (
                            <a href="#" key={i} onClick={() => {thumbnailClick(post.postId)}}>
                                <img src={srcPrefix + post.filesUrl[0]} alt="테스트이미지"/>
                            </a>
                        )
                    }
                })}
            </div>
            <div>
                {results?.map((post, i) => {
                    if (i === 6 || i === 8) {
                        return (
                            <a href="#" key={i} className="gall-big" onClick={() => {thumbnailClick(post.postId)}}>
                                <img src={srcPrefix + post.filesUrl[0]} alt="테스트이미지"/>
                            </a>
                        )
                    } else if (i === 7 || i === 9) {
                        return (
                            <a href="#" key={i} onClick={() => {thumbnailClick(post.postId)}}>
                                <img src={srcPrefix + post.filesUrl[0]} alt="테스트이미지"/>
                            </a>
                        )
                    }
                })}
            </div>
        </div>
    )
};

export default FeedSearchResultView;
