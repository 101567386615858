import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { useRef } from 'react';

const MessageInputView = ({
	handleSubmit,
	messageInputValue,
	handleChange,
	focused,
	isOtherUserLeft,
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const { setFocused } = useDirectMessageRepo();
	return (
		<div className="chatInput input">
			<input
				type="text"
				ref={inputRef}
				value={messageInputValue}
				maxLength={192}
				onChange={handleChange}
				placeholder={isOtherUserLeft ? '종료된 대화예요' : '대화를 입력하세요'}
				disabled={isOtherUserLeft}
				enterKeyHint="send"
				onFocus={() => {
					if (!focused) {
						setFocused(true);
					}
				}}
				onBlur={() => {
					setFocused(false);
				}}
			/>
			<button className="submitBtn" type="button" onClick={handleSubmit} />
		</div>
	);
};

export default MessageInputView;
