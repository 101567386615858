import PhotoImageGuideView from "@dating/ui/pages/register/photo/views/PhotoImageGuideView";
import React, { useEffect } from "react";
import { useImgCropService } from "@dating/ui/pages/imgCrop/service/useImgCropService";
import { useNavigate } from "react-router-dom";
import EditProfileImgAddBoxView from "@dating/ui/pages/profile/editProfile/views/EditProfileImgAddBoxView";
import { useProfileImgEditService } from "@dating/ui/pages/profile/editProfile/service/useProfileImgEditService";
import { useCommonAlertPopService } from "@dating/ui/components/commonAlertPop/service/useCommonAlertPopService";

export const ProfileImgEditContainer = () => {
    const {
        fnProfileImgUpdate,
        previewURLs,
        btnActivate,
        srcPrefix,
        removeImg,
        response,
    } = useProfileImgEditService();

    const {
        updateFile,
        clearCropFiles,
        cropFiles,
    } = useImgCropService();

    const {
        fnCommonAlertPopOn,
    } = useCommonAlertPopService();

    const navigate = useNavigate();
    useEffect(() => {
        if (response) {
            navigate(-1);
        }
    }, [response]);

    return (
        <div className="wrap noFooter" style={{overflowY: "auto"}}>
            <header className="h-fixed">
                <div className="headerWrap bg-fff">
                    <div className="h-left">
                        <button
                            type="button"
                            className="btnBack"
                            onClick={() => {
                                navigate(-1);
                                clearCropFiles();
                            }}
                        />
                    </div>
                </div>
            </header>

            <div className="contentsWrap">
                <div className="titleWrap">
                    <h2 className="title01">사진 수정</h2>
                    <p className="titleSub01">프로필 사진 1장은 필수입니다</p>
                </div>

                <div className="content">
                    {/*<PhotoImageAddView*/}
                    {/*    previewURLs={previewURLs}*/}
                    {/*    updateFile={updateFile}*/}
                    {/*/>*/}
                    <div className="imgAddBox">
                        {[0, 1].map((id, index) => {
                            if (id === 0) {
                                return (
                                    <EditProfileImgAddBoxView
                                        key={id + index}
                                        id={id}
                                        required={true}
                                        previewURLs={previewURLs}
                                        updateFile={updateFile}
                                        srcPrefix={srcPrefix}
                                        removeImg={removeImg}
                                    />
                                );
                            } else {
                                return (
                                    <EditProfileImgAddBoxView
                                        key={id + index}
                                        id={id}
                                        required={false}
                                        previewURLs={previewURLs}
                                        updateFile={updateFile}
                                        srcPrefix={srcPrefix}
                                        removeImg={removeImg}
                                    />
                                );
                            }
                        })}
                    </div>

                    <div className="imgAddBox box4 margin-t8 margin-b32">
                        {[2, 3, 4, 5].map((id, index) => {
                            return (
                                <EditProfileImgAddBoxView
                                    key={id + index}
                                    id={id}
                                    required={false}
                                    previewURLs={previewURLs}
                                    updateFile={updateFile}
                                    srcPrefix={srcPrefix}
                                    removeImg={removeImg}
                                />
                            );
                        })}
                    </div>

                    <ul className="exTxtList">
                        <li>
                            밝기, 노이즈, 과도한 보정 등으로 인해 인식이 어렵거나 일부 가려진
                            경우 가입이 제한될 수 있습니다.
                        </li>
                        <li>
                            업로드한 이미지에 대한 타인 이미지 도용 등 법적 책임은 본인에게 있습니다.
                        </li>
                    </ul>

                    <PhotoImageGuideView/>
                </div>
            </div>

            <footer>
                <div className="f-btnWrap bg-fff">
                    <button
                        type="button"
                        className="btn btnBig btnBlack"
                        onClick={() => {
                            if (cropFiles.length === 0) {
                                fnCommonAlertPopOn("프로필 사진 1장은 필수입니다.");
                                return;
                            }
                            fnProfileImgUpdate();
                        }}
                        disabled={btnActivate}
                    >
                        <span>프로필 사진 등록</span>
                    </button>
                </div>
            </footer>
        </div>
    );
};