import { HomeMatchingCOVO } from '@/site/api';
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

interface props {
	preferUser: HomeMatchingCOVO;
	srcPrefix: string;
	setUserProfileOpen: (userProfileOpen: string) => void;
	currentUserUid: string;
	setOpenPaymentPop: (openPaymentPop: boolean) => void;
	fnSendLikePopOpen: (userUid: string, profileUrl: string, nickName: string) => void;
	sliderSettings: {};
	setMessageTargetUser: (messageTargetUser: string) => void;
	isLiked: boolean;
	type: "" | "ideal" | "addIdeal" | "today" | "profile";
	setLikedLocation: (likedLocation: "" | "ideal" | "addIdeal" | "today" | "profile") => void;
}

const ProfileBoxView = ({
							preferUser,
							srcPrefix,
							setUserProfileOpen,
							currentUserUid,
							setOpenPaymentPop,
							fnSendLikePopOpen,
							sliderSettings,
							setMessageTargetUser,
							isLiked,
							setLikedLocation,
							type,
						}: props) => {
	return (
		<>
			<div className="profileBox">
				<div className="profileSlide">
					<Link
						to="/metaverse/dating/profile"
						onClick={() => {
							setUserProfileOpen(preferUser.matchingUserUid);
						}}
						state={{
							isOtherProfile: preferUser.matchingUserUid !== currentUserUid,
						}}
					>
						<Slider {...sliderSettings}>
							{
								preferUser.profileUrls.map((item, i) => {
									return (
										<img
											key={item + i}
											src={srcPrefix + item}
											alt={preferUser.nickName}
										/>
									);
								})
							}
						</Slider>
					</Link>

				</div>
				<div className="txtBox">
					<p className="txt01 fz20 color-fff">{preferUser.nickName}</p>
					<p className="txt02 fz16 color-fff">
						{/*{preferUser.age}세, {preferUser.location ? preferUser.location : "위치 정보 없음"}*/}
						{preferUser.age + '세'}
					</p>
					<div className="profileLikeBox">
						{/*좋아요 버튼*/}
						<button
							type="button"
							className={`likeBtn bigCircleBtn ${isLiked ? 'on' : ''}`}
							onClick={(e) => {
								if (!e.currentTarget.classList.contains('on') && preferUser.matchingUserUid !== '') {
									setLikedLocation(type);
									fnSendLikePopOpen(preferUser.matchingUserUid, preferUser.profileUrls[0], preferUser.nickName);
								}
							}}
						/>

						{/*메세지 보내기 버튼*/}
						<button
							type="button"
							// className={`shareBtn bigCircleBtn ${SendMessage ? 'on' : ''}`}
							className="shareBtn bigCircleBtn"
							onClick={(e) => {
								if (!e.currentTarget.classList.contains('on') && preferUser.matchingUserUid !== '') {
									setOpenPaymentPop(true);
									setMessageTargetUser(preferUser.matchingUserUid);
								}
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProfileBoxView;
