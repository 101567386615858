import {create} from 'zustand';
import type {UserProfileCOVO} from '@/site/api';

type DatingRepoState = {
    //이미지 src prefix
    srcPrefix: string;

    // 내 프로필
    myProfile: UserProfileCOVO;
    setMyProfile: (myProfile: UserProfileCOVO) => void;

    // 유저 프로필
    userProfile: UserProfileCOVO;
    setUserProfile: (userProfile: UserProfileCOVO) => void;

    // 내 프로필 조회
    myProfileOpen: boolean;
    setMyProfileOpen: (myProfileOpen: boolean) => void;

    // 유저 프로필 조회
    userProfileOpen: string;
    setUserProfileOpen: (userProfileOpen: string) => void;

    // 확인 팝업 message
    alertPopMessage: string;
    setAlertPopMessage: (alertPopMessage: string) => void;

    // 확인 팝업 open/clolse
    alertPopOpen: boolean;
    setAlertPopOpen: (alertPopOpen: boolean) => void;

    // 확인취소 팝업 message
    confirmPopMessage: string;
    setConfirmPopMessage: (confirmPopMessage: string) => void;

    // 확인취소 팝업 open/clolse
    confirmPopOpen: boolean;
    setConfirmPopOpen: (confirmPopOpen: boolean) => void;

    // 확인취소 팝업 확인 함수
    confirmPopFunc: {param: any, func: (param?: any) => any | void};
    setConfirmPopFunc: (confirmPopFunc: {param: any, func: () => any}) => void;
    initConfirmPopFunc: () => void;

    // 이미지 슬라이더 설정값
    sliderSettings: {};
};
export const useDatingRepo = create<DatingRepoState>((set) => ({
    srcPrefix:  import.meta.env.MODE != 'prd' ? 'https://dev-metaverse.ibizsoftware.net/imgs/' : 'https://hush.berith.co/imgs/',

    myProfile: {
        userUid: "",
        nickName: "",
        age: "0",
        birthday: "",
        location: "",
        profileUrl: [],
        gender: "",
        introduce: "",
        job: "",
        height: 0,
        interest: [],
        lifeStyle: [],
        extraInterest: [],
        extraLifeStyle: [],
        smoking: "",
        drink: "",
        religion: "",
        ceo: false,
        house: false,
        salary: false,
        profession: false,
        car: false,
        phoneNumber: "",
        LatestScore: 0,
        AverageScore: 0,
    },
    setMyProfile: (myProfile) => set({myProfile}),

    userProfile: {
        userUid: "",
        nickName: "",
        age: "0",
        birthday: "",
        location: "",
        profileUrl: [],
        gender: "",
        introduce: "",
        job: "",
        height: 0,
        interest: [],
        lifeStyle: [],
        extraInterest: [],
        extraLifeStyle: [],
        smoking: "",
        drink: "",
        religion: "",
        ceo: false,
        house: false,
        salary: false,
        profession: false,
        car: false,
        phoneNumber: "",
        LatestScore: 0,
        AverageScore: 0,
    },
    setUserProfile: (userProfile) => set({userProfile}),

    myProfileOpen: false,
    setMyProfileOpen: (myProfileOpen) => set({myProfileOpen}),

    userProfileOpen: '',
    setUserProfileOpen: (userProfileOpen) => set({userProfileOpen}),

    alertPopMessage: "",
    setAlertPopMessage: (alertPopMessage: string) => set({alertPopMessage}),

    alertPopOpen: false,
    setAlertPopOpen: (alertPopOpen: boolean) => set({alertPopOpen}),

    confirmPopMessage: "",
    setConfirmPopMessage: (confirmPopMessage: string) => set({confirmPopMessage}),

    confirmPopOpen: false,
    setConfirmPopOpen: (confirmPopOpen: boolean) => set({confirmPopOpen}),

    confirmPopFunc: {param: null, func: (param: any) => null},
    setConfirmPopFunc: (confirmPopFunc: {param: any, func: (param: any) => any}) => set({confirmPopFunc}),
    initConfirmPopFunc: () => set({confirmPopFunc: {param: null, func: (param: any) => null}}),

    sliderSettings: {
        arrows: false,
        dots: true,
        autoplay: false,
        infinite: false,
        slidestoshow: 1,
        swipesensitivity: 0.1,
    },
}));
