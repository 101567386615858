import { create } from 'zustand';

interface LikeState {
	scrollY: number;
	setScrollY: (scrollY: number) => void;

	scrollYIndex: number;
	setScrollYIndex: (scrollY: any) => void;

	receivedLikeTitSets: {
		mainTit: string;
		subTit: string;
	}[];

	sentLikeTitSets: {
		mainTit: string;
		subTit: string;
	}[];

	// 받은관심/보낸관심 탭 선택
	isReceived: boolean;
	setIsReceived: (isReceived: boolean) => void;
}

export const useLikeRepo = create<LikeState>((set) => ({
	isReceived: true,
	setIsReceived: (isReceived: boolean) => set({ isReceived }),

	scrollY: 0,
	setScrollY: (scrollY: number) => set({ scrollY }),

	scrollYIndex: -1,
	setScrollYIndex: (scrollYIndex: number) => set({ scrollYIndex }),

	receivedLikeTitSets: [
		{
			mainTit: '받은 좋아요',
			subTit: '회원님이 좋아요를 누르면 바로 연결됩니다',
		},
		{
			mainTit: '높게 받은 평가',
			subTit: '좋아요를 보내 연결 확률을 높여보세요',
		},
		{
			mainTit: '좋아요 받은 게시글',
			subTit: '최근 일주일간의 목록입니다',
		},
		{
			mainTit: '댓글 달린 게시글',
			subTit: '최근 일주일간의 목록입니다',
		},
	],
	sentLikeTitSets: [
		{
			mainTit: '보낸 좋아요',
			subTit: '메시지를 보내 연결 확률을 더욱 높여보세요',
		},
		{
			mainTit: '높게 준 평가',
			subTit: '좋아요를 보내 연결 확률을 높여보세요',
		},
        {
            mainTit: '좋아요 보낸 게시글',
            subTit: '최근 일주일간의 목록입니다',
        },
        {
            mainTit: '댓글 단 게시글',
            subTit: '최근 일주일간의 목록입니다',
        },
	],
}));
