import {useState} from "react";
export const useLifeStyleStateRepo = () => {

    // 나만의 항목 추가 팝업 show, hide
    const [isPopOn, setIsPopOn] = useState({id: "", active: false});

    // 공통 관심사 리스트 상태 관리
    const [interestList, setInterestList] = useState<string[] | { id: string, styleValue: string }[]>([]);

    // 유저가 추가한 관심사 리스트
    const [customInterestList, setCustomInterestList] = useState<{ id: string, styleValue: string }[]>([]);

    // 유저가 추가한 관심사 리스트 상태 관리
    const [customInterestValue, setCustomInterestValue] = useState<{id: string, state: boolean}[]>([]);

    // 공통 라이프 스타일 리스트 상태 관리
    const [lifeStyleList, setLifeStyleList] = useState<string[] | { id: string, styleValue: string }[]>([]);

    // 유저가 추가한 라이프 스타일 리스트
    const [customLifeStyleList, setCustomLifeStyleList] = useState<{ id: string, styleValue: string }[]>([]);

    // 유저가 추가한 라이프 스타일 리스트 상태 관리
    const [customLifeStyleValue, setCustomLifeStyleValue] = useState<{id: string, state: boolean}[]>([]);

    // 흡연 여부
    const [drinkingValue, setDrinkingValue] = useState<string>("");

    // 음주 여부
    const [smokingValue, setSmokingValue] = useState<string>("");

    // 종교
    const [religionValue, setReligionValue] = useState<string>("");

    // 나만의 항목 추가 팝업 input value
    const [customVal, setCustomVal] = useState<string>("");

    const [initReligion, setInitReligion] = useState<{code: string, value: string}[] | string[]>([]);
    const [initDrinkingState, setInitDrinkingState] = useState<{code: string, value: string}[] | string[]>([]);
    const [initSmokingState, setInitSmokingState] = useState<{code: string, value: string}[] | string[]>([]);

    // 선택한 공통 관심사
    const [interestValue, setInterestValue] = useState<{[key: string]: boolean}[]>([]);

    // 선택한 공통 라이프스타일
    const [lifeStyleValue, setLifeStyleValue] = useState<{[key: string]: boolean}[]>([]);

    return {
        lifeStyleValue,
        setLifeStyleValue,
        interestValue,
        setInterestValue,
        customVal,
        setCustomVal,
        drinkingValue,
        setDrinkingValue,
        smokingValue,
        setSmokingValue,
        religionValue,
        setReligionValue,
        customLifeStyleList,
        setCustomLifeStyleList,
        customLifeStyleValue,
        setCustomLifeStyleValue,
        lifeStyleList,
        setLifeStyleList,
        customInterestList,
        customInterestValue,
        setCustomInterestList,
        setCustomInterestValue,
        interestList,
        setInterestList,
        isPopOn,
        setIsPopOn,
        initReligion,
        setInitReligion,
        initDrinkingState,
        setInitDrinkingState,
        initSmokingState,
        setInitSmokingState,
    };
}