import {Link} from "react-router-dom";

interface props {

};

export const SettingsBlockView = ({}: props) => {
    return (
        <>
            <Link to={'/metaverse/dating/settings/blockList'}>
                <div className="leftRightTxt arrowBg">
                    <span className="fz16Fw500">차단 관리</span>
                </div>
            </Link>

            <Link to={'/metaverse/dating/settings/blockAcquaintance'}>
                <div className="leftRightTxt arrowBg margin-b32">
                    <span className="fz16Fw500">지인 차단하기</span>
                </div>
            </Link>
        </>
    )
};