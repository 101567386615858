const FriendWrapView = () => {
	return (
		<div className="popConWrap padding-b0">
			<div className="inner">
				<h3 className="title01 text-c margin-t32">
					친구 초대하면
					<br />두 분 모두에게 500베리드 증정
				</h3>
				<div className="friendWrap">
					<div className="friendTicket text-c">
						<div className="eventCodeWrap">
							<div className="toolTipWrap">
								발급 코드 <span className="ToolTipBtn"></span>
								<div className="toolTipPop">
									<p className="fz14 color-666">
										밑의 코드를 터치하면 클립보드에 자동 복사 됩니다
									</p>
								</div>
							</div>
							<p className="eventCode">5H32TD</p>
						</div>
					</div>
					<p className="margin-t32 fz14 color-999 text-c">
						내 발급 코드를 통해서 친구가 가입을 하면
						<br />
						친구와 회원님께 각각 베리드를 드려요
					</p>
					<p className="margin-t48 bold-500">유의사항</p>
					<ul className="exTxtList">
						<li>
							회원님의 발급코드로 가입이 완료된 신규회원에 따라 각각 베리드
							보상이 주어집니다.
						</li>
						<li>
							이벤트 취지에 맞지 않는 부정행위가 확인되는 경우 이벤트 회수 혹은
							이벤트 참여 제한 등의 조치가 적용될 수 있습니다.
						</li>
						<li>본 이벤트는 사전 예고 없이 변경되거나 종료될 수 있습니다.</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default FriendWrapView;
