import {useEffect} from "react";
import type {UserBirthdayCIVO} from "@/site/api";
import {useRegisterAdapter} from "@dating/adapter/register/useRegisterAdapter";
import {useBirthdayStateRepo} from "@dating/ui/pages/register/birthday/useBirthdayStateRepo";
import {useNavigate} from "react-router-dom";
import {useRegisterRepo} from "@dating/repository/register/useRegisterRepo";

export const useBirthdayService = () => {
    const {
        btnActivate,
        setBtnActivate,
        activeIndex,
        setActiveIndex,
        birthCheckMessage,
        setBirthCheckMessage
    } = useBirthdayStateRepo();

    const {
        inputBirthday,
        setInputBirthday,
        setStep,
        registerSteps,
    } = useRegisterRepo();

    const {
        saveBirthday,
        saveBirthdayStatus,
        saveBirthdayData,
    } = useRegisterAdapter();

    /////////////////////////////////////////////////////////
    const fnSaveBirthday = () => {
        let birthday: string = inputBirthday.join("");
        const map: UserBirthdayCIVO = {
            birthday: birthday,
        }
        saveBirthday(map);
    };

    /*
     * handleInputChange
     * input에 값 입력 시 길이 체크, 입력중인 input 인덱스 set, 입력한 값 set
     */
    const handleInputChange = (index: number, eventValue: string) => {
        if (/^\d$/.test(eventValue)) {
            const newInputValues = [...inputBirthday];
            newInputValues[index] = eventValue;
            setInputBirthday(newInputValues);

            if (index < inputBirthday.length - 1 && eventValue !== "") {
                setActiveIndex(index + 1);
            }
        }
    };

    /*
     * handleInputKeyDown
     * input에 이벤트 키 입력 시 input입력값 변경, input index 변경
     */
    const handleInputKeyDown = (index: number, eventKey: string) => {
        if (eventKey === "Backspace" || eventKey === "Delete") {
            setBirthCheckMessage("");
            const newInputValues = [...inputBirthday];
            newInputValues[index] = "";
            setInputBirthday(newInputValues);
            setActiveIndex(index - 1);
        }

        if (eventKey === "ArrowLeft" && index > 0) {
            setActiveIndex(index - 1);
        } else if (eventKey === "ArrowRight" && index < inputBirthday.length - 1) {
            setActiveIndex(index + 1);
        }
    };

    return {
        inputBirthday,
        setInputBirthday,
        fnSaveBirthday,
        handleInputChange,
        handleInputKeyDown,
        btnActivate,
        setBtnActivate,
        activeIndex,
        setActiveIndex,
        birthCheckMessage,
        saveBirthdayStatus,
        saveBirthdayData,
        setStep,
        registerSteps,
        setBirthCheckMessage,
    };
};
