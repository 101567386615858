import RegisterHeaderView from "../common/registerHeader/views/RegisterHeaderView";
import IdealSettingFooterView from "./views/IdealSettingFooterView";
import IdealPopUpView from "./views/IdealPopUpView";
import useIdealSettingService from "./service/useIdealSettingService";
import IdealSettingView from "@dating/ui/pages/register/Ideal/views/IdealSettingView";
import React, { useEffect } from "react";
import {
    useRegisterHeaderService
} from "@dating/ui/pages/register/common/registerHeader/service/useRegisterHeaderService";
import StepProgressView from "@dating/ui/pages/register/common/stepProgress/views/StepProgressView";
import { useNavigate } from "react-router-dom";

const IdealSettingContainer = () => {
    const {
        isPopupVisible,
        popupType,
        setPopupType,
        showPopup,
        hidePopup,
        fnSaveUserPrefer,
        formatAgeValue,
        ageRange,
        handleAgeChange,
        formatDistanceValue,
        nearRange,
        handleNearChange,
        formatHeightValue,
        heightRange,
        handleHeightChange,
        religion,
        handleReligionSelect,
        drinkingState,
        handleDrinkingStateSelect,
        smokingState,
        handleSmokingStateSelect,
        initAgeRange,
        initNearRange,
        initHeightRange,
        initReligion,
        initDrinkingState,
        initSmokingState,
        response,
        nearOverRange,
        ageOverRange,
        heightOverRange,
        registerSteps,
    } = useIdealSettingService();

    const {
        referralCodeOpen,
        setReferralCodeOpen,
        skipHandler,
        step,
    } = useRegisterHeaderService();

    const navigate = useNavigate();
    useEffect(() => {
        if (response) {
            navigate(registerSteps[8].url);
        }
    }, [response]);

    return (
        <>
            <div className="wrap noFooter">
                <RegisterHeaderView
                    referralCodeOpen={referralCodeOpen}
                    setReferralCodeOpen={setReferralCodeOpen}
                    skipHandler={skipHandler}
                />
                <div className="contentsWrap">
                    <div className="titleWrap">
                        <StepProgressView step={step}/>
                        <h2 className="title01">이상형 설정</h2>
                        <p className="color-999 fz16 margin-t16">
                            지나치게 한정적으로 설정 시 원활한 추천이 되지 않을 수 있어요
                        </p>
                    </div>
                    <IdealSettingView
                        step={step}
                        showPopup={showPopup}
                        setPopupType={setPopupType}
                        formatAgeValue={formatAgeValue}
                        ageRange={ageRange}
                        handleAgeChange={handleAgeChange}
                        formatDistanceValue={formatDistanceValue}
                        nearRange={nearRange}
                        handleNearChange={handleNearChange}
                        formatHeightValue={formatHeightValue}
                        heightRange={heightRange}
                        handleHeightChange={handleHeightChange}
                        religion={religion}
                        drinkingState={drinkingState}
                        smokingState={smokingState}
                        initAgeRange={initAgeRange}
                        initNearRange={initNearRange}
                        initHeightRange={initHeightRange}
                        nearOverRange={nearOverRange}
                        ageOverRange={ageOverRange}
                        heightOverRange={heightOverRange}
                    />
                    <IdealSettingFooterView
                        fnSaveUserPrefer={fnSaveUserPrefer}
                    />
                </div>
            </div>

            {isPopupVisible && (
                <IdealPopUpView
                    isPopupVisible={isPopupVisible}
                    hidePopup={hidePopup}
                    popupType={popupType}
                    religion={religion}
                    handleReligionSelect={handleReligionSelect}
                    drinkingState={drinkingState}
                    handleDrinkingStateSelect={handleDrinkingStateSelect}
                    smokingState={smokingState}
                    handleSmokingStateSelect={handleSmokingStateSelect}
                    initReligion={initReligion}
                    initDrinkingState={initDrinkingState}
                    initSmokingState={initSmokingState}
                />
            )}
        </>
    );
};

export default IdealSettingContainer;