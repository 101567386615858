// 좌석에 앉았을 때 캐릭터가 이동하는 위치 정보
export const sittingShiftData = {
  up: [0, 2, -10],
  down: [4, 6, 1],
  left: [0, -8, 14],
  right: [0, -8, 14],
};

// 앉은 상태에서 캐릭터 애니메이션의 frame 번호를 반환하는 함수
export function getSitAnimationNum(direction: string) {
  return sittingShiftData[direction];
}
