import GameScene from '@/scenes/GameScene';
import { PlayerBehavior } from '../../../../../types/PlayerBehavior';
import { MyPlayerState, StateParams } from '../../MyPlayerState';
import MyPlayer from '@/characters/myplayer/MyPlayer';

export class UnChairSittingState implements MyPlayerState {
	constructor(private param: StateParams) {}

	update() {
		const { myKeyboard, myPlayer, roomService: network, scene } = this.param;

		myKeyboard.resetClickActionF();

		const parts = myPlayer.anims.currentAnim!.key.split('_');
		parts[1] = 'idle';
		const aniKey = parts.join('_');
		myPlayer.playWrab(aniKey, true);

		myPlayer.playerBehavior = PlayerBehavior.IDLE;

		myPlayer.chairOnSit?.clearDialogBox();

		const safePosition = this.findSafePosition(myPlayer, scene as GameScene);

		myPlayer.setPosition(safePosition.x, safePosition.y);

		myPlayer.collision.setPosition(safePosition.x, safePosition.y);
		myPlayer.collision.update(myPlayer);

		network.updateChairStatus(myPlayer.chairOnSit?.chairId, false);

		myPlayer.updateMove(aniKey);

		myPlayer.chairOnSit = undefined;

		myKeyboard.resetClickActionF();
	}

	private findSafePosition(
		player: MyPlayer,
		scene: GameScene,
	): { x: number; y: number } {
		const tileSize = 32;
		const playerSize = { width: player.width, height: player.height };

		const positions = [
			{ x: player.x, y: player.y },
			{ x: player.x - tileSize, y: player.y },
			{ x: player.x + tileSize, y: player.y },
			{ x: player.x, y: player.y - tileSize },
			{ x: player.x, y: player.y + tileSize },
		];

		for (const pos of positions) {
			if (this.isPositionSafe(pos, playerSize, scene)) {
				return pos;
			}
		}

		return { x: player.x, y: player.y };
	}

	private isPositionSafe(
		position: { x: number; y: number },
		playerSize: { width: number; height: number },
		scene: GameScene,
	): boolean {
		if (!scene.collisionLayer) {
			console.warn('No collision layer found in the scene');
			return true;
		}

		const bounds = new Phaser.Geom.Rectangle(
			position.x - playerSize.width / 2,
			position.y - playerSize.height / 2,
			playerSize.width,
			playerSize.height,
		);

		const tiles = scene.collisionLayer.getTilesWithinShape(bounds);

		const isSafe = tiles.every((tile) => !tile.collides);

		return isSafe;
	}
}
