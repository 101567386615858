import Phaser from 'phaser';
import MyPlayer from '../characters/myplayer/MyPlayer';
import OtherPlayer from '../characters/otherplayer/OtherPlayer';
import '../characters/phaserFactoryRegister';
import {
	PHASER_PLAYER_JOINED,
	PHASER_PLAYER_UPDATED,
	PHASER_UPDATE_DIALOG_BUBBLE,
	PHASER_UPDATE_EMOJI_BUBBLE,
} from '../events/PhaserEventEmitter';
import { REACT_START_ROOM } from '../events/ReactEventEmitter';
import Computer from '../items/impl/Computer';
import Whiteboard from '../items/impl/Whiteboard';
import { RoomService, createRoomService } from '../services/Network';
import { RoomReceiveProcess } from '../services/metaverse/RoomReceiveProcess';
import { SceneName } from '../types/SceneName';
import { PhaserEventController } from './setup-game/PhaserEventController';
import Chair from '@/items/impl/Chair';
import { GameSceneSetup } from './game/GameSceneSetup';
import { GameSceneNetwork } from './game/GameSceneNetwork';
import { GameSceneHandler } from './game/GameSceneHandlers';
import { AssetLoader } from './game/AssetLoader';
import { SceneInitializer } from './game/SceneInitializer';

export default class GameScene extends Phaser.Scene {
	map!: Phaser.Tilemaps.Tilemap;
	myPlayer!: MyPlayer;
	otherPlayers!: Phaser.Physics.Arcade.Group;
	otherPlayerMap = new Map<string, OtherPlayer>();
	chairMap = new Map<string, Chair>();
	computerMap = new Map<string, Computer>();
	whiteboardMap = new Map<string, Whiteboard>();
	roomService: RoomService;
	phaserEventController: PhaserEventController;
	roomReceiveProcess!: RoomReceiveProcess;
	roomTheme: any;
	collisionLayer!: Phaser.Tilemaps.TilemapLayer;
	public sceneSetup!: GameSceneSetup;
	public network!: GameSceneNetwork;
	public handler!: GameSceneHandler;
	public assetLoader!: AssetLoader;
	private sceneInitializer: SceneInitializer;

	constructor() {
		super(SceneName.GameScene);
		this.roomService = createRoomService(this);
		this.phaserEventController = new PhaserEventController(this);
		this.phaserEventController.listen();
		this.sceneInitializer = new SceneInitializer(this);
	}

	init(data: { roomTheme: any }) {
		this.sceneInitializer.init(data);
	}

	preload() {
		this.assetLoader.preload();
	}

	async create(arg: REACT_START_ROOM) {
		await this.assetLoader.loadAssets();
		await this.network.setupNetwork(arg);
		this.sceneInitializer.initializeScene();
	}

	resetEventListeners() {
		this.phaserEventController.removeAllListeners();
		this.phaserEventController.listen();
	}

	networkCallback() {
		new RoomReceiveProcess(this, this.roomService).listen();
	}

	async leaveCurrentRoom() {
		if (this.roomService) {
			await this.roomService.leaveCurrentRoom();
			this.shutdown();
		}
	}

	// 이벤트 핸들러 메서드들은 GameSceneHandler로 위임
	handlePlayerJoined(arg: PHASER_PLAYER_JOINED) {
		this.handler.handlePlayerJoined(arg);
	}

	// 퇴장한 플레이어를 otherPlayers 그룹에서 제거
	handlePlayerLeft(id: string) {
		this.handler.handlePlayerLeft(id);
	}

	// 내 플레이어가 연결 준비가 되었음을 처리
	handleMyPlayerReady() {
		this.handler.handleMyPlayerReady();
	}

	// 내 비디오가 연결되었음을 처리
	handleMyMediaConnected() {
		this.handler.handleMyMediaConnected();
	}

	// 플레이어 업데이트를 받았을 때 타겟 위치를 업데이트
	handlePlayerUpdated(arg: PHASER_PLAYER_UPDATED) {
		this.handler.handlePlayerUpdated(arg);
	}

	// 플레이어들이 겹쳤을 때 이벤트를 처리
	handlePlayersOverlap(myPlayer, otherPlayer) {
		this.handler.handlePlayersOverlap(myPlayer, otherPlayer);
	}

	// 채팅 메시지가 추가되었을 때 이벤트를 처리
	handleChatMessageAdded(arg: PHASER_UPDATE_DIALOG_BUBBLE) {
		this.handler.handleChatMessageAdded(arg);
	}

	// 이모지가 추가되었을 때 이벤트를 처리
	handleEmojiAdded(arg: PHASER_UPDATE_EMOJI_BUBBLE) {
		this.handler.handleEmojiAdded(arg);
	}

	handleRoomChanged() {
		this.handler.handleRoomChanged();
	}
	shutdown() {
		this.sceneInitializer.shutdown();
	}

	update(t: number, dt: number) {
		this.myPlayer?.update(this.roomService);
	}
}
