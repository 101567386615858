import { UserProfileCOVO } from '@/site/api';

interface props {
    setOpenPaymentPop: (openPaymentPop: boolean) => void;
    profile: UserProfileCOVO;
    proInfo01: React.RefObject<HTMLDivElement>;
    fnSendLikePopOpen: ((userUid: string, profileUrl: string, nickName: string) => void) | undefined;
    setMessageTargetUser: (messageTargetUser: string) => void;
    isLiked: boolean;
    type: '' | 'ideal' | 'addIdeal' | 'today' | 'profile';
    setLikedLocation: (likedLocation: '' | 'ideal' | 'addIdeal' | 'today' | 'profile') => void;
}

const ProInfo01View = ({
                           setOpenPaymentPop,
                           profile,
                           proInfo01,
                           fnSendLikePopOpen,
                           setMessageTargetUser,
                           isLiked,
                           setLikedLocation,
                           type,
                       }: props) => {
    return (
        <div className="proInfo01" ref={proInfo01}>
            <div className="inner">
                <div className="profileLikeBox">
                    <button
                        type="button"
                        className={`likeBtn bigCircleBtn ${isLiked ? 'on' : ''}`}
                        onClick={(e) => {
                            // setOpenLikeConfirm(true);
                            if (!e.currentTarget.classList.contains('on') && fnSendLikePopOpen && profile.userUid != '') {
                                setLikedLocation(type);
                                fnSendLikePopOpen(profile.userUid, profile.profileUrl[0], profile.nickName);
                            }
                        }}
                    />
                    <button
                        type="button"
                        className="shareBtn bigCircleBtn"
                        onClick={() => {
                            if (profile.userUid != '') {
                                setOpenPaymentPop(true);
                                setMessageTargetUser(profile.userUid);
                            }
                        }}
                    />
                </div>
                <div className="text-c">
                    <p className="proFz20 bold margin-b8">{profile.nickName}</p>
                    <p className="fz16 color-999 bold-500">
                        {/*{profile.age + '세, ' + (profile.location ? profile.location : "위치 정보 없음")}*/}
                        {profile.age + '세'}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ProInfo01View;
