import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';

export const useCommonConfirmPopService = () => {
	const {
		confirmPopMessage,
		setConfirmPopMessage,
		confirmPopOpen,
		setConfirmPopOpen,
		confirmPopFunc,
		setConfirmPopFunc,
		initConfirmPopFunc,
	} = useDatingRepo();

	/*
	 * fnCommonConfirmPopOn
	 * 팝업 열기 - 메세지, 실행함수 세팅
	 */
	const fnCommonConfirmPopOn = (message: string, func: any, param: any) => {
		setConfirmPopMessage(message);
		setConfirmPopFunc({ param: param, func: func });
		setConfirmPopOpen(true);
	};

	/*
	 * fnCommonConfirmPopOff
	 * 팝업 닫기(취소) - 메세지, 실행함수 초기화
	 */
	const fnCommonConfirmPopOff = () => {
		setConfirmPopMessage('');
		initConfirmPopFunc();
		setConfirmPopOpen(false);
	};

	/*
	 * fnCommonConfirmPopExecute
	 * 확인 버튼 클릭 (함수 실행 및 팝업 닫기)
	 */
	const fnCommonConfirmPopExecute = () => {
		if (confirmPopFunc.param != null) {
			confirmPopFunc.func(confirmPopFunc.param);
		} else {
			confirmPopFunc.func();
		}
		setConfirmPopOpen(false);
	};

	return {
		confirmPopMessage,
		confirmPopOpen,
		setConfirmPopOpen,
		fnCommonConfirmPopOn,
		fnCommonConfirmPopOff,
		confirmPopFunc,
		fnCommonConfirmPopExecute,
	};
};
