import OtherPlayer from './OtherPlayer';

export class OtherPlayerMove {
	constructor(
		private otherPlayer: OtherPlayer,
		private targetPosition: [number, number],
		readonly speed = 200,
	) {}

	getVelocity(dt: number) {
		const delta = (this.speed / 1000) * dt;
		let dx = this.targetPosition[0] - this.otherPlayer.x;
		let dy = this.targetPosition[1] - this.otherPlayer.y;

		if (Math.abs(dx) < delta) {
			this.otherPlayer.x = this.targetPosition[0];
			this.otherPlayer.playerContainer?.setX(this.targetPosition[0]);
			dx = 0;
		}
		if (Math.abs(dy) < delta) {
			this.otherPlayer.y = this.targetPosition[1];
			this.otherPlayer.playerContainer?.setY(this.targetPosition[1] - 30);
			dy = 0;
		}

		let vx = 0;
		let vy = 0;
		if (dx > 0) vx += this.speed;
		else if (dx < 0) vx -= this.speed;
		if (dy > 0) vy += this.speed;
		else if (dy < 0) vy -= this.speed;

		return { vx, vy };
	}
}
