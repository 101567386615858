import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import RoomThemeBoxView from './views/RoomThemeBoxView';
import RoomNameBoxView from './views/RoomNameBoxView';
import RoomHeadCountBoxView from './views/RoomHeadCountBoxView';
import RoomPwdSettingBoxView from './views/RoomPwdSettingBoxView';
import { useMakeRoomService } from './service/useMakeRoomService';

const MakeRoomContainer = () => {
	const {
		title,
		values,
		customRoomNameResult,
		activeHeadCountIndex,
		headCountSelectedList,
		roomThemeSelectedList,
		roomPwdToggle,
		roomPwdResult,
		activeRoomThemeIndex,
		handleRoomNameChange,
		handleHeadCountChange,
		handleRoomThemeChange,
		handleRoomPwdChange,
		toggleRoomPwdBox,
		handleSubmit,
	} = useMakeRoomService();

	return (
		<div className="rightShow active">
			<ButtonHeaderView title={title} right={false} />
			<div className="popConWrap">
				<div className="roomSettingWrap">
					<div className="inner">
						<form onSubmit={handleSubmit}>
							<RoomThemeBoxView
								roomThemeSelectedList={roomThemeSelectedList}
								activeRoomThemeIndex={activeRoomThemeIndex}
								handleRoomThemeChange={handleRoomThemeChange}
							/>
							<RoomNameBoxView
								handleRoomNameChange={handleRoomNameChange}
								customRoomNameResult={customRoomNameResult}
							/>
							<RoomHeadCountBoxView
								headCountSelectedList={headCountSelectedList}
								activeHeadCountIndex={activeHeadCountIndex}
								handleHeadCountChange={handleHeadCountChange}
							/>
							<RoomPwdSettingBoxView
								toggleRoomPwdBox={toggleRoomPwdBox}
								roomPwdToggle={roomPwdToggle}
								roomPwdResult={roomPwdResult}
								handleRoomPwdChange={handleRoomPwdChange}
							/>
							<button
								className={`btnBlack btnBig ${
									values.name.length > 0 ? '' : 'btnCCC'
								}`}
								type="submit"
								role="button"
							>
								저 장
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MakeRoomContainer;
