import { Link } from 'react-router-dom';

const LeftMenuFooterView = () => {
	return (
		<footer>
			<div className="f-btnWrap">
				<Link to="/metaverse/dating/sideBar/makeRoom">
					<button className="btn btnBig btnBlack">
						<span>미팅 방 만들기</span>
					</button>
				</Link>
			</div>
		</footer>
	);
};

export default LeftMenuFooterView;
