interface props {
    fnCommonConfirmPopOn: (message: string, func: any, param: any) => any;
    fnSaveBlockAcquaintance: () => void;
    blockedUserCount: number;
};

const BlockAcquaintanceFooterView = ({
                                         fnCommonConfirmPopOn,
                                         fnSaveBlockAcquaintance,
                                         blockedUserCount,
                                     }: props) => {
    return (
        <footer>
            <div className="f-btnWrap ">
                <button
                    type="button"
                    className="btn btnBig btnBlack popOnBtn"
                    onClick={() => {
                        fnCommonConfirmPopOn(blockedUserCount + "명의 연락처가 있습니다.\r\n전체 차단하시겠습니까?", fnSaveBlockAcquaintance, null);
                    }}
                >
                    <span>내 연락처 전체 차단하기</span>
                </button>
            </div>
        </footer>
    );
};

export default BlockAcquaintanceFooterView;
