import { Joystick } from 'react-joystick-component';
import { useJoyStickService } from './service/useJoyStickService';

const JoyStickContainer = () => {
	const { position, joystickRef, handleJoyStick } = useJoyStickService();

	return (
		<div
			className="joystickWrapper"
			style={{
				position: 'absolute',
				left: `${position.x}px`,
				zIndex: '60',
				bottom: `${position.y}px`,
			}}
		>
			<Joystick
				size={120}
				stickSize={50}
				baseColor={'rgba(0,0,0,0.4)'}
				stickImage={'/assets/joystick/ic-joy.svg'}
				throttle={50}
				move={handleJoyStick}
				stop={handleJoyStick}
				start={handleJoyStick}
				minDistance={10}
				ref={joystickRef}
			/>
		</div>
	);
};

export default JoyStickContainer;
