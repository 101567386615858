import {useState} from "react";
import {HomePastMatchingUserCOVO, Top10PerUserCOVO} from "@/site/api";

export const useLatestPicksStateRepo = () => {
    // 최근 접속 유저 히스토리
    const [pastRecentLoginUsers, setPastRecentLoginUsers] = useState<HomePastMatchingUserCOVO[]>([]);

    // 최근 접속 유저 리스트
    const [recentLoginUsers, setRecentLoginUsers] = useState<Top10PerUserCOVO[]>([]);

    return {
        pastRecentLoginUsers,
        setPastRecentLoginUsers,
        recentLoginUsers,
        setRecentLoginUsers,
    };
};