import {useNavigate} from "react-router-dom";

interface props {
    referralCodeOpen: boolean;
    setReferralCodeOpen: (referralCodeOpen: boolean) => void;
    skipHandler: () => void;
};

const RegisterHeaderView = ({
                                referralCodeOpen,
                                setReferralCodeOpen,
                                skipHandler,
                            }: props) => {
    const navigate = useNavigate();

    return (
        <header className="h-fixed">
            <div className="headerWrap">
                <div className="h-left">
                    <button
                        className="btnBack"
                        onClick={() => {
                            navigate(-1);
                            setReferralCodeOpen(false);
                        }}
                    />
                </div>
                <div className="h-center"></div>
                {referralCodeOpen && (
                    <div className="h-right">
                        <button
                            type="button"
                            className="fz18 bold-500"
                            onClick={skipHandler}
                        >
                            건너뛰기
                        </button>
                    </div>
                )}
            </div>
        </header>
    );
};

export default RegisterHeaderView;
