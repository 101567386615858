import { Link } from 'react-router-dom';
import { ReceiveLikeUserCOVO } from '@/site/api';

interface props {
	likeUsers: ReceiveLikeUserCOVO[];
	srcPrefix: string;
	setUserProfileOpen: (UserProfileOpen: string) => void;
	fnSaveUserLike: (userUid: string, state: boolean) => void;
	fnRemoveReceiveUserLike: (id: string, type: string) => void;
	fnSendLikePopOpen: (
		userUid: string,
		profileUrl: string,
		nickName: string,
	) => void;
	setMessageTargetUser: (messageTargetUser: string) => void;
}

const ReceivedLikeListView = ({
	likeUsers,
	srcPrefix,
	setUserProfileOpen,
	fnSaveUserLike,
	fnRemoveReceiveUserLike,
	fnSendLikePopOpen,
	setMessageTargetUser,
}: props) => {
	return (
		<div
			className={`likeProfileList ${
				likeUsers.length === 0 ? 'likeProfileList-empty' : ''
			}`}
		>
			{likeUsers.length > 0 ? (
				likeUsers.map((item, i) => {
					return (
						<div className="profileType" key={i}>
							<div className="proLeft">
								<div className="proImg">
									<Link
										to="/metaverse/dating/profile"
										onClick={() => {
											setUserProfileOpen(item.likeUserUid);
										}}
										state={{
											isOtherProfile: true,
										}}
									>
										<img src={srcPrefix + item.profileUrl} />
									</Link>
								</div>
								<div className="proName margin-l12">
									<p className="bold padding-b4">{item.nickName}</p>
									<p className="fz14 color-666">
										{/*{item.age + '세, ' + (item.location ? item.location : '위치 정보 없음')}*/}
										{item.age + '세'}
									</p>
								</div>
							</div>
							<div className="ProRight">
								<button
									type="button"
									className="likeBtn fixOn"
									onClick={() => {
										// fnSaveUserLike(item.likeUserUid, true);
										if (item.likeUserUid != '') {
											fnSendLikePopOpen(
												item.likeUserUid,
												item.profileUrl,
												item.nickName,
											);
											setMessageTargetUser(item.likeUserUid);
										}
									}}
								/>
								<button
									type="button"
									className="closeBtn margin-l5"
									onClick={() => {
										fnRemoveReceiveUserLike(item.likeViewId, 'received');
									}}
								/>
							</div>
						</div>
					);
				})
			) : (
				<div>받은 좋아요가 없습니다</div>
			)}
		</div>
	);
};

export default ReceivedLikeListView;
