import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
interface RouteTransitionProps {
	location: any;
	children: any;
	navigationType: string;
}
const RouteTransition = ({
	location,
	children,
	navigationType,
}: RouteTransitionProps) => {
	const pathname = location.pathname;
	return (
		<TransitionGroup
			className={'transition-wrapper'}
			childFactory={(child) => {
				return React.cloneElement(child, {
					classNames:
						navigationType === 'POP' ? 'rightShow-pop' : 'rightShow-push',
				});
			}}
		>
			<CSSTransition exact key={pathname} timeout={300}>
				{children}
			</CSSTransition>
		</TransitionGroup>
	);
};

export default RouteTransition;
