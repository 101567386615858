import RoomPwdInputWrapView from './RoomPwdInputWrapView';

const RoomPwdSettingBoxView = ({
	roomPwdResult,
	roomPwdToggle,
	toggleRoomPwdBox,
	handleRoomPwdChange,
}) => {
	return (
		<div className="settingList margin-t24">
			<div className="leftRightTxt">
				<span className="fz16Fw700">비밀번호 설정</span>
				<input
					type="checkbox"
					className="switch"
					checked={roomPwdToggle}
					onChange={toggleRoomPwdBox}
				/>
			</div>
			<p className="main-color fz12 padding-t4">
				커플 매니저만 사용 가능한 설정입니다.
			</p>
			{roomPwdToggle && (
				<RoomPwdInputWrapView
					roomPwdResult={roomPwdResult}
					handleRoomPwdChange={handleRoomPwdChange}
				/>
			)}
		</div>
	);
};

export default RoomPwdSettingBoxView;
