import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import LeaveChatContainer from '@dating/ui/components/leaveChat/LeaveChatContainer';
import { useNavigate } from 'react-router-dom';

const MessageDetailHeaderView = ({
	setOpenLeaveChat,
	setOpenBlock,
	modifyMenuPop,
	navigationRoom,
}) => {
	const navigate = useNavigate();
	const { setShowDirectMessageList } = useDirectMessageRepo();
	return (
		<header className="h-fixed">
			<div className="headerWrap">
				<div className="h-left d-flex">
					<button
						className="btnBack"
						onClick={() => {
							navigate('/metaverse/messenger');
							setShowDirectMessageList(true);
						}}
					/>
					<p className="bold margin-l8">{navigationRoom.otherUserInfo.name}</p>
				</div>
				<div className="h-right">
					<div className="menuDotWap top">
						<button className="menuDot" onClick={(e) => modifyMenuPop(e)} />
						<LeaveChatContainer
							setOpenLeaveChat={setOpenLeaveChat}
							setOpenBlock={setOpenBlock}
						/>
					</div>
				</div>
			</div>
		</header>
	);
};

export default MessageDetailHeaderView;
