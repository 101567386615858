import { useSingleFeedService } from '@dating/ui/pages/lounge/singleFeed/useSingleFeedService';
import { CSSTransition } from 'react-transition-group';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import FeedListView from '@dating/ui/pages/lounge/feedList/views/FeedListView';
import { useFeedListService } from '@dating/ui/pages/lounge/feedList/service/useFeedListService';
import { useBlockReportService } from '@dating/ui/components/blockReport/service/useBlockReportService';
import { useFeedFormService } from '@dating/ui/components/feedFrom/service/useFeedFormService';
import { useCommonConfirmPopService } from '@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService';
import FeedCommentView from '@dating/ui/pages/lounge/feedComment/views/FeedCommentView';

export const SingleFeedContainer = () => {
	const {
		feedDetail,
		sliderSettings,
		srcPrefix,
		initFeedEditPops,
		fnFeedEditHandler,
		fnSinglePostLike,
	} = useSingleFeedService();

	const {
		deleteContent,
		commentListHandler,
		commentViewHandler,
		openComment,
		fnSaveComment,
		focusedPostId,
		commentResponse,
		commentValue,
		setCommentValue,
		postUser,
		setUserProfileOpen,
		setMyProfileOpen,
		myProfile,
		setOpenFeedHidePop,
		setBlockPostId,
		feedEditPops,
		fnDeleteComment,
	} = useFeedListService();

	const {
		openMenuPop,
		setOpenReport,
		menuDotClickHandler,
		selectedOption,
		handleOptionSelect,
		setBlockUserUid,
	} = useBlockReportService();

	const { initFeedForm, setModifyPostId } = useFeedFormService();

	const {
		fnCommonConfirmPopOn,
	} = useCommonConfirmPopService();

	return (
		<div className="wrap">
			<ButtonHeaderView title={'게시글'} right={false} />
			<div
				id="conWrap"
				className="metaPopWrap"
				onClick={() => {
					if (feedDetail) {
						initFeedEditPops(feedDetail);
					}
				}}
			>
				<div className="feedWrap">
					{
						feedDetail ?
							<FeedListView
								post={feedDetail}
								deleteContent={deleteContent}
								myProfile={myProfile}
								menuDotClickHandler={menuDotClickHandler}
								setOpenReport={setOpenReport}
								setOpenFeedHidePop={setOpenFeedHidePop}
								commentListHandler={commentListHandler}
								openMenuPop={openMenuPop}
								selectedOption={selectedOption}
								handleOptionSelect={handleOptionSelect}
								sliderSettings={sliderSettings}
								srcPrefix={srcPrefix}
								commentCount={feedDetail.commentCount}
								commentViewHandler={commentViewHandler}
								setUserProfileOpen={setUserProfileOpen}
								fnSavePostLike={fnSinglePostLike}
								setBlockUserUid={setBlockUserUid}
								currentUserUid={myProfile?.userUid}
								setModifyPostId={setModifyPostId}
								setMyProfileOpen={setMyProfileOpen}
								fnCommonConfirmPopOn={fnCommonConfirmPopOn}
								setBlockPostId={setBlockPostId}
								fnFeedEditHandler={fnFeedEditHandler}
								feedEditPops={feedEditPops}
							/>
							: null
					}

					{/* 댓글창 */}
					<div className={`${openComment ? 'commentPopOn' : 'commentPop'}`}>
						<div
							className="commentDim"
							onClick={() => {
								commentListHandler('', { userUid: '', nickname: '' });
								commentViewHandler(false);
							}}
						></div>
						<CSSTransition
							in={openComment}
							classNames="commentInfo-transition"
							timeout={100}
						>
							<FeedCommentView
								key={focusedPostId}
								focusedPostId={focusedPostId}
								myProfile={myProfile}
								fnSaveComment={fnSaveComment}
								commentResponse={commentResponse}
								srcPrefix={srcPrefix}
								commentValue={commentValue}
								setCommentValue={setCommentValue}
								commentViewHandler={commentViewHandler}
								postUser={postUser}
								setMyProfileOpen={setMyProfileOpen}
								setUserProfileOpen={setUserProfileOpen}
								currentUserUid={myProfile?.userUid}
								fnCommonConfirmPopOn={fnCommonConfirmPopOn}
								fnDeleteComment={fnDeleteComment}
							/>
						</CSSTransition>
					</div>
				</div>
			</div>
		</div>
	);
};