import useImgCropRepo from "@dating/repository/imgCrop/useImgCropRepo";
import {useRegisterAdapter} from "@dating/adapter/register/useRegisterAdapter";
import {usePhotoStateRepo} from "@dating/ui/pages/register/photo/usePhotoStateRepo";
import {useEffect} from "react";
import {useRegisterRepo} from "@dating/repository/register/useRegisterRepo";

export const usePhotoService = () => {
    const {
        btnActivate,
        setBtnActivate,
        response,
        setResponse,
    } = usePhotoStateRepo();

    const {
        showImgCrop,
        previewURLs,
        clearPreviewURLs,
        cropFiles,
    } = useImgCropRepo();

    const {
        setStep,
        registerSteps,
    } = useRegisterRepo();

    const {
        saveUserProfile,
        saveUserProfileStatus,
        saveUserProfileData,
    } = useRegisterAdapter();

    ////////////////////////////////////////////////////////////

    /*
     * fnProfileUpload
     * 업로드한 프로필사진 서버에 저장
     */
    const fnProfileUpload = () => {
        if (cropFiles[0].size === 0) {
            alert("프로필 사진 1장은 필수입니다.");
            return;
        }
        const map = {
            files: cropFiles
        }
        saveUserProfile(map);
    };

    useEffect(() => {
        if (saveUserProfileStatus === "success" && saveUserProfileData) {
            if (saveUserProfileData.result) {
                setStep(7);
                clearPreviewURLs();
                setResponse(true);
            } else {
                alert(saveUserProfileData.resultCode);
            }
        } else if (saveUserProfileStatus === "error") {

        }
    }, [saveUserProfileStatus, saveUserProfileData]);

    useEffect(() => {
        if (cropFiles.length > 0 || cropFiles[0]?.size > 0) {
            setBtnActivate(false);
        }
    }, [cropFiles]);

    useEffect(() => {
        setBtnActivate(false);
    }, []);

    return {
        fnProfileUpload,
        previewURLs,
        btnActivate,
        showImgCrop,
        response,
        registerSteps,
    }
};