import { useLikeMatchingService } from '@dating/ui/pages/like/likeMatcing/useLikeMatchingService';

interface props {
	fnSaveUserLike: (userUid: string, state: boolean) => void;
	srcPrefix: string;
	activeItem: {
		userUid: string;
		profileUrl: string;
		nickName: string;
	};
	fnSendLikePopClose: () => void;
}

const LikeConfirmView = ({
	fnSaveUserLike,
	srcPrefix,
	activeItem,
	fnSendLikePopClose,
}: props) => {
	return (
		<div className="likeWrap">
			<div className="popupInfo padding-b16">
				<p
					style={{
						margin: '10px',
						fontSize: '24px',
						fontWeight: '700',
					}}
				>
					좋아요를 보내시겠어요?
				</p>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '16px',
					}}
				>
					<div className="proImg">
						<img
							src={srcPrefix + activeItem?.profileUrl}
							alt={activeItem?.nickName}
						/>
					</div>
				</div>
				<p
					className="proFz20 margin-b8"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						paddingBottom: '50px',
					}}
				>
					{activeItem?.nickName}
				</p>
				<div className="popFtBtnWrap">
					<button
						type="button"
						className="btnBig btnCCC radiusNone"
						onClick={() => {
							fnSendLikePopClose();
						}}
					>
						<span
							style={{
								fontSize: '16px',
							}}
						>
							다음에 할게요
						</span>
					</button>
					<button
						type="button"
						className="btnBig btnBlack radiusNone"
						onClick={() => {
							fnSaveUserLike(activeItem.userUid, true);
						}}
					>
						<span
							className="flexXYcenter"
							style={{
								fontSize: '16px',
							}}
						>
							좋아요 &nbsp;
							<span
								style={{
									fontSize: '10px',
								}}
							>
								&nbsp;
								{/*<span>100</span>*/}
							</span>
							<span className="ic-berith">0</span>
						</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default LikeConfirmView;
