import {useEffect, useRef} from "react";

interface props {
    inputBirthday: string[];
    handleInputChange: (index: number, eventValue: string) => void;
    handleInputKeyDown: (index: number, eventKey: string) => void;
    setBtnActivate: (btnActivate: boolean) => void;
    activeIndex: number;
    birthCheckMessage: string;
}

const BirthdayContentsView = ({
                                  inputBirthday,
                                  handleInputChange,
                                  handleInputKeyDown,
                                  setBtnActivate,
                                  activeIndex,
                                  birthCheckMessage,
                              }: props) => {

    const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null));

    useEffect(() => {
        if (inputBirthday.indexOf("") == -1) {
            setBtnActivate(false);
        } else {
            setBtnActivate(true);
        }
    }, [inputBirthday]);

    useEffect(() => {
        inputRefs.current[activeIndex]?.focus();
    }, [activeIndex]);

    return (
        <div className="content">
            <div className="input certificationBox">
                {inputBirthday.map((value, index) => (
                    <input
                        type="number"
                        pattern="\d*"
                        className="text-c padding-0"
                        key={index}
                        value={value}
                        onChange={(e) => {
                            const eventValue: string = e.target.value;
                            handleInputChange(index, eventValue);
                        }}
                        onKeyDown={(e) => {
                            const eventKey: string = e.key;
                            handleInputKeyDown(index, eventKey);
                        }}
                        ref={(ref) => (inputRefs.current[index] = ref)}
                        placeholder={
                            index === 0
                                ? "Y"
                                : index === 1
                                    ? "Y"
                                    : index === 2
                                        ? "M"
                                        : index === 3
                                            ? "M"
                                            : index === 4
                                                ? "D"
                                                : index === 5
                                                    ? "D"
                                                    : ""
                        }
                    />
                ))}
            </div>
            <p className="sub-color fz12 padding-t8">
                {birthCheckMessage}
            </p>
        </div>
);
};

export default BirthdayContentsView;
