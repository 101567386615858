import { create } from 'zustand';

type JoystickAction = 'stop' | string;
type JoystickDirection = '' | string;
type JoystickPosition = { x: number; y: number };

interface JoystickRepoState {
	showJoystick: boolean;
	disableJoystick: boolean;
	action: JoystickAction;
	direction: JoystickDirection;
	moreDirection: JoystickDirection;
	position: JoystickPosition;
	isDragging: boolean;
	resetJoystickState: () => void;
	setShowJoystick: (show: boolean) => void;
	setDisableJoystick: (disable: boolean) => void;
	setJoystickAction: (action: JoystickAction) => void;
	setJoystickDirection: (direction: JoystickDirection) => void;
	setJoystickMoreDirection: (moreDirection: JoystickDirection) => void;
	setJoystickPosition: (position: JoystickPosition) => void;
	setIsDragging: (dragging: boolean) => void;
}

const initialState = {
	showJoystick: true,
	disableJoystick: false,
	action: 'stop' as JoystickAction,
	direction: '' as JoystickDirection,
	moreDirection: '' as JoystickDirection,
	position: { x: 20, y: 124 } as JoystickPosition,
	isDragging: false,
};

export const useJoystickRepo = create<JoystickRepoState>((set) => ({
	...initialState,

	resetJoystickState: () => set(initialState),

	setShowJoystick: (show) => set({ showJoystick: show }),

	setDisableJoystick: (disable) => set({ disableJoystick: disable }),

	setJoystickAction: (action) => set({ action }),

	setJoystickDirection: (direction) => set({ direction }),

	setJoystickMoreDirection: (moreDirection) => set({ moreDirection }),

	setJoystickPosition: (position) => set({ position }),

	setIsDragging: (dragging) => set({ isDragging: dragging }),
}));
