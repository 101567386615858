interface props {
    fnSaveUserTerms: () => void;
    btnDisabled: boolean;
}

const AgreementTermsFooterView = ({
                                      fnSaveUserTerms,
                                      btnDisabled
                                  }: props) => {
    return (
        <footer>
            <div className="f-btnWrap">
                <button
                    type="button"
                    className="btn btnBig btnBlack"
                    onClick={fnSaveUserTerms}
                    disabled={btnDisabled}
                >
                    <span>다음</span>
                </button>
            </div>
        </footer>
    );
};

export default AgreementTermsFooterView;