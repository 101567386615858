import {useRegisterRepo} from "@dating/repository/register/useRegisterRepo";

export const useAgreeService = () => {
    const {
        selectedTerm,
    } = useRegisterRepo();

    return {
        selectedTerm,
    };
};