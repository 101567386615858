import ReceivedLikeListView from './ReceivedLikeListView';
import LikeMoreButtonView from '../../views/LikeMoreButtonView';
import LikeTitleView from '../../views/LikeTitleView';
import { ReceiveLikeUserCOVO } from '@/site/api';

interface props {
	titSet: { mainTit: string; subTit: string };
	index: number;
	scrollYIndex: number;
	noDataUserLike: boolean;
	fnGetReceiveUserLike: () => void;
	likeUsers: ReceiveLikeUserCOVO[];
	srcPrefix: string;
	setUserProfileOpen: (UserProfileOpen: string) => void;
	fnSaveUserLike: (userUid: string, state: boolean) => void;
	fnRemoveReceiveUserLike: (id: string, type: string) => void;
	fnSendLikePopOpen: (
		userUid: string,
		profileUrl: string,
		nickName: string,
	) => void;
	setMessageTargetUser: (messageTargetUser: string) => void;
}

const ReceivedUserLikeView = ({
	titSet,
	index,
	scrollYIndex,
	noDataUserLike,
	fnGetReceiveUserLike,
	likeUsers,
	srcPrefix,
	setUserProfileOpen,
	fnSaveUserLike,
	fnRemoveReceiveUserLike,
	fnSendLikePopOpen,
	setMessageTargetUser,
}: props) => {
	return (
		<>
			<LikeTitleView
				titSet={titSet}
				scrollYIndex={scrollYIndex}
				index={index}
			/>
			<ReceivedLikeListView
				likeUsers={likeUsers}
				srcPrefix={srcPrefix}
				setUserProfileOpen={setUserProfileOpen}
				fnSaveUserLike={fnSaveUserLike}
				fnRemoveReceiveUserLike={fnRemoveReceiveUserLike}
				fnSendLikePopOpen={fnSendLikePopOpen}
				setMessageTargetUser={setMessageTargetUser}
			/>
			<LikeMoreButtonView
				fnGetData={fnGetReceiveUserLike}
				btnHandle={noDataUserLike}
			/>
		</>
	);
};
export default ReceivedUserLikeView;
