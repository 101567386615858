import {useRegisterAdapter} from "@dating/adapter/register/useRegisterAdapter";
import type {UserRecommenderCIVO} from "@/site/api";
import {useEffect} from "react";
import {useRecommendCodeStateRepo} from "@dating/ui/pages/register/recommendCode/useRecommendCodeStateRepo";
import {useRegisterRepo} from "@dating/repository/register/useRegisterRepo";

export const useRecommendCodeService = () => {
    const {
        recommendCode,
        setRecommendCode,
        codeFailed,
        setCodeFailed,
        response,
        setResponse,
    } = useRecommendCodeStateRepo();

    const {
        setStep,
        registerSteps,
    } = useRegisterRepo();

    const {
        saveRecommender,
        statusSaveRecommender,
        dataSaveRecommender,
    } = useRegisterAdapter();

    /////////////////////////////////////////////
    const fnSaveRecommender = () => {
        if (recommendCode.length === 0) {
            alert("추천인 코드를 입력해 주세요");
            return;
        }
        const map: UserRecommenderCIVO = {
            recommenderCode: recommendCode
        }
        saveRecommender(map);
    };

    useEffect(() => {
        if (statusSaveRecommender === "success") {
            if (dataSaveRecommender != undefined) {
                if (dataSaveRecommender.result) {
                    setStep(9);
                    setResponse(true);
                } else {
                    alert(dataSaveRecommender.resultCode);
                    setCodeFailed(true);
                }
            }
        } else if (statusSaveRecommender === "error") {

        }
    }, [statusSaveRecommender, dataSaveRecommender]);

    return {
        setRecommendCode,
        fnSaveRecommender,
        codeFailed,
        response,
        registerSteps,
    }
}