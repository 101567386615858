import { useEffect, useRef } from 'react';
import { usePhoneVerificationStateRepo } from '@dating/ui/pages/register/phoneVerification/usePhoneVerificationStateRepo';
import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import {
	MobilePhoneOccupancyCIVO,
	MobilePhoneOccupancyVerificationCIVO,
} from '@/site/api';
import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { useJoystickRepo } from '@/stores/useJoystickRepo';
import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { createPhaser } from '@/createPhaser';
import { useNavigate } from 'react-router-dom';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';

export const usePhoneVerificationService = () => {
	const {
		inputValues,
		setInputValues,
		time,
		setTime,
		occupancyFooterViewStyle,
		setOccupancyFooterViewStyle,
		activeIndex,
		setActiveIndex,
		formattedTime,
		setFormattedTime,
		phoneVeriFailed,
		setPhoneVeriFailed,
		phoneVeriFailedCnt,
		setPhoneVeriFailedCnt,
		inputTimeout,
		setInputTimeout,
		notValidCode,
		setNotValidCode,
		codeResend,
		setCodeResend,
	} = usePhoneVerificationStateRepo();

	const {
		phoneNumber,
		registerSteps,
		setStep,
		verifyReqCount,
		setVerifyReqCount,
		resetRegisterInfo,
	} = useRegisterRepo();

	const { setMyProfile } = useDatingRepo();

	const { setMyMetaUserOpen, myMetaUserOpen } = useMetaUserRepo();

	const {
		verifyVerificationCode,
		verifyVerificationCodeStatus,
		verifyVerificationCodeData,
		sendVerificationCode,
	} = useRegisterAdapter();

	const { getMyProfile } = useProfileAdapter();
	//////////////////////////////////////////////////

	/*
	 * handleInputKeyDown
	 * input에 이벤트 키 입력 시 input입력값 변경, input index 변경
	 */
	const handleInputKeyDown = (index: number, eventKey: string) => {
		if (/^\d$/.test(eventKey)) {
			setInputValues((preValues) => {
				const newInputValues = [...preValues];
				newInputValues[index] = eventKey;
				return newInputValues;
			});

			if (index < inputValues.length - 1 && eventKey !== '') {
				setActiveIndex(index + 1);
			}
		}
	};

	/*
	 * fnVerifyVerificationCode
	 * 확인 버튼 클릭, 인증번호 인증 요청
	 */
	const fnVerifyVerificationCode = () => {
		let code: string = inputValues.join('');
		const map: MobilePhoneOccupancyVerificationCIVO = {
			mobilePhoneNumber: phoneNumber,
			verificationCode: code, // 개발에서는 코드 123456 입력하면 통과됨
		};
		verifyVerificationCode(map);
	};

	useEffect(() => {
		if (inputValues.indexOf('') != -1) {
			setOccupancyFooterViewStyle({
				notReturnNumber: {
					display: 'block',
				},
				checkBtn: {
					display: 'none',
				},
			});
		} else {
			setOccupancyFooterViewStyle({
				notReturnNumber: {
					display: 'none',
				},
				checkBtn: {
					display: 'block',
				},
			});
		}
	}, [inputValues]);

	/* ---------- Metaverse ----------*/
	const { setJoystickPosition, setIsDragging } = useJoystickRepo();
	const gameRef = useRef<Phaser.Game | null>(null);
	const isGameInitialized = useRef(false);
	const handleGameClick = (pointer: Phaser.Input.Pointer) => {
		const x = pointer.x;
		const y = pointer.y;
		const JOYSTICK_SIZE = 120;
		const touchX = x + window.scrollX - JOYSTICK_SIZE / 2;
		const touchY =
			window.innerHeight - (y + window.scrollY) - JOYSTICK_SIZE / 2;
		setJoystickPosition({ x: touchX, y: touchY });
		setIsDragging(true);
		reactEventEmitter.emit('react-click-screen', { x, y });
	};

	const addClickListener = () => {
		if (gameRef.current && gameRef.current.scene.scenes.length > 0) {
			const scene = gameRef.current.scene.scenes[0];
			scene.input.on('pointerdown', handleGameClick);
		} else {
			setTimeout(addClickListener, 100);
		}
	};

	const initializeGame = () => {
		if (!isGameInitialized.current) {
			if (gameRef.current) {
				gameRef.current.destroy(true);
				gameRef.current = null;
			}
			gameRef.current = createPhaser();
			isGameInitialized.current = true;
			addClickListener();
		}
	};

	/*
	 * timeFormatting
	 * 인증번호 입력 제한시간 표시형식 set
	 */
	function timeFormatting() {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;
		setFormattedTime(
			String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0'),
		);
	}

	/*
	 * fnSendVerificationCode
	 * 인증번호 요청 횟수 체크 후 인증번호 발송
	 */
	const fnSendVerificationCode = () => {
		if (verifyReqCount > 5) {
			resetRegisterInfo();
			setPhoneVeriFailed(true);
			return;
		}

		const map: MobilePhoneOccupancyCIVO = {
			mobilePhoneNumber: phoneNumber,
		};
		sendVerificationCode(map).then((value) => {
			if (value && verifyReqCount > 0) {
				setCodeResend(true);
				setVerifyReqCount(verifyReqCount + 1);
			}
		});
	};

	useEffect(() => {
		timeFormatting();
	}, [time]);

	// 인증번호 입력 제한시간 set
	useEffect(() => {
		const intervalId = setInterval(() => {
			setTime((prevTime) => {
				if (prevTime > 0) {
					return prevTime - 1;
				} else {
					clearInterval(intervalId);
					setInputTimeout(true);
					return 0;
				}
			});
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	const navigate = useNavigate();
	useEffect(() => {
		if (
			verifyVerificationCodeStatus === 'success' &&
			verifyVerificationCodeData
		) {
			if (verifyVerificationCodeData?.signUpStatus) {
				// 이미 회원가입 완료 후 승인대기 또는 승인된 회원일 때 => 허쉬 메인으로 이동

				// 로그인한 회원 정보 set
				getMyProfile(phoneNumber).then((value) => {
					setMyProfile(value);

					// 프로필 설정 후 바로 emit 실행
					reactEventEmitter.emit('react-join-or-create-dm-room', {
						uuid: value.userUid,
						nickName: value.nickName,
						profileUrl: value.profileUrl[0],
						age: value.age,
						location: value.location,
					});
					reactEventEmitter.emit('react-get-dm-rooms');
				});
				initializeGame();

				if (!myMetaUserOpen) {
					setMyMetaUserOpen(true);
				}

				if (verifyVerificationCodeData.startButtonYn) {
					navigate('/metaverse/dating/home/ideal');
				} else {
					navigate('/metaverse/dating/register/waiting');
				}
			} else if (!verifyVerificationCodeData?.signUpStatus) {
				if (verifyVerificationCodeData?.signUpSteps) {
					setStep(Number(verifyVerificationCodeData.signUpSteps));
					navigate(registerSteps[verifyVerificationCodeData.signUpSteps].url);
				} else {
					setStep(0);
					navigate(registerSteps[0].url);
				}
			}
		} else if (verifyVerificationCodeStatus === 'error') {
			setNotValidCode(true);
			setInputValues(Array(6).fill(''));
			setPhoneVeriFailedCnt(phoneVeriFailedCnt + 1);
		}
	}, [verifyVerificationCodeStatus, verifyVerificationCodeData]);

	return {
		inputValues,
		formattedTime,
		occupancyFooterViewStyle,
		handleInputKeyDown,
		fnVerifyVerificationCode,
		activeIndex,
		fnSendVerificationCode,
		resetRegisterInfo,
		phoneVeriFailed,
		phoneVeriFailedCnt,
		inputTimeout,
		notValidCode,
		setNotValidCode,
		codeResend,
		setCodeResend,
		phoneNumber,
		verifyVerificationCodeStatus,
		verifyVerificationCodeData,
		myMetaUserOpen,
		setMyMetaUserOpen,
		registerSteps,
		setInputValues,
		setPhoneVeriFailedCnt,
		setStep,
	};
};
