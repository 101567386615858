// https://peerjs.com/docs.html#peer-id

import GameScene from '@/scenes/GameScene';

/**
 * PeerJS에서 사용 가능한 형식으로 userId를 변환
 * 숫자와 알파벳이 아닌 모든 문자를 'G'로 대체
 * @param userId 변환할 사용자 ID
 * @returns 변환된 사용자 ID
 */
export function replaceInvalidId(userId: string) {
	return userId.replace(/[^0-9a-z]/gi, 'G');
}

/**
 * sessionId를 정제
 * '-ss'로 끝나는 9자 이상의 문자열에서 '-ss'를 제거하고,
 * 숫자와 알파벳이 아닌 모든 문자를 'G'로 대체
 * @param id 정제할 세션 ID
 * @returns 정제된 세션 ID
 */
export function sanitizeId(id: string) {
	let sanitized = id;

	if (sanitized.length > 9 && sanitized.endsWith('-ss')) {
		sanitized = sanitized.substring(0, sanitized.length - 3);
	}

	return sanitized.replace(/[^0-9a-z]/gi, 'G');
}

/**
 * 날짜 문자열을 시간 형식으로 포맷팅
 * @param dateString 날짜 문자열 또는 숫자 (밀리초)
 * @returns 포맷팅된 시간 문자열 (예: "오후 2:30") 또는 오류 메시지
 */
export const formatTime = (dateString: string | number | undefined): string => {
	if (dateString === undefined || dateString === null) {
		return '유효하지 않은 날짜';
	}

	let timestamp: number;

	if (typeof dateString === 'number') {
		timestamp = dateString;
	} else {
		// 문자열을 숫자로 변환
		timestamp = parseInt(dateString, 10);
	}

	// 숫자로 변환할 수 없거나 유효하지 않은 타임스탬프인 경우
	if (isNaN(timestamp) || timestamp < 0) {
		return '유효하지 않은 날짜';
	}

	const date = new Date(timestamp);

	// Date 객체가 유효하지 않은 경우
	if (isNaN(date.getTime())) {
		return '유효하지 않은 날짜';
	}

	const options: Intl.DateTimeFormatOptions = {
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	};

	try {
		const formattedTime = new Intl.DateTimeFormat('ko-KR', options).format(
			date,
		);
		return formattedTime;
	} catch (error) {
		console.error('시간 포맷팅 오류:', error);
		return '시간 포맷팅 오류';
	}
};
/**
 * 두 사용자 ID로 직접 메시지 룸 ID를 생성
 * 두 ID를 정렬하여 항상 동일한 룸 ID가 생성되도록 함
 * @param senderId 발신자 ID
 * @param receiverId 수신자 ID
 * @returns 생성된 룸 ID
 */
export const generateDirectMessageRoomId = (
	senderId: string,
	receiverId: string,
) => {
	const [id1, id2] = [senderId, receiverId].sort();
	return id1 + id2;
};

/**
 * 고유한 메시지 ID를 생성
 * @returns 생성된 메시지 ID
 */
let messageIdCounter = 0;
export const generateMessageId = () => {
	return messageIdCounter++;
};

/**
 * 초 단위의 시간을 시:분:초 형식의 문자열로 변환
 * @param seconds 초 단위의 시간
 * @returns 시:분:초 형식의 문자열
 */
export function secondsToHMS(seconds: number) {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;

	const padZero = (num) => num.toString().padStart(2, '0');

	return `${padZero(hours)}:${padZero(minutes)}:${padZero(remainingSeconds)}`;
}

/**
 * 게임 씬이 준비되었는지 확인
 * @param scene 확인할 게임 씬
 * @returns 게임 씬이 준비되었는지 여부
 */
export class SceneReadyUtil {
	static isSceneReady(scene: GameScene): boolean {
		return !!(
			scene &&
			scene.sys.isActive() &&
			scene.myPlayer &&
			scene.roomService
		);
	}

	static async waitForSceneReady(scene: GameScene): Promise<void> {
		return new Promise((resolve) => {
			const checkReady = () => {
				if (SceneReadyUtil.isSceneReady(scene)) {
					resolve();
				} else {
					setTimeout(checkReady, 100);
				}
			};
			checkReady();
		});
	}
}

//매칭 시간 : 1분 전 ~ 59분 전, 1시간 ~ 24시간, 1일 전 ~
export const formatMatchingTime = (matchingTimeStr: string): string => {
	// 한국 시간대로 매칭 시간 파싱
	const matchingTime = new Date(Number(matchingTimeStr));
	const now = new Date();

	// 시간 차이 계산 (밀리초)
	const diffInMs = now.getTime() - matchingTime.getTime();
	const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
	const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
	const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

	// 1시간 이내인 경우
	if (diffInHours < 1) {
		if (diffInMinutes < 1) return '방금';
		return `${diffInMinutes}분`;
	} else if (diffInHours < 24) {
		// 하루 이내 (1시간 ~ 24시간)
		return `${diffInHours}시간`;
	} else {
		// 하루 이상 지난 경우 (1일 ~)
		// 오전/오후 형식으로 변환
		// const hours = matchingTime.getHours();
		// const formattedDay = hours % 12 || 12; //12시간 형식?
		return `${diffInDays}일`;
	}
};
export const normalizeToTimestamp = (date: any): number => {
	if (!date) return Date.now();

	// 이미 숫자(타임스탬프)인 경우
	if (typeof date === 'number') return date;

	// 문자열인 경우
	if (typeof date === 'string') {
		// 이미 타임스탬프 문자열인 경우
		if (!isNaN(Number(date))) return Number(date);

		// Date 문자열인 경우
		const parsed = Date.parse(date);
		if (!isNaN(parsed)) return parsed;
	}

	// 다른 모든 경우
	return Date.now();
};
