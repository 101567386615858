import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { generateCharacterImages, getCharacter } from '@/utils/avatarUtils';
import { useMetaUserAdapter } from '@dating/adapter/meta/useMetaUserAdapter';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

const MetaProfileView = () => {
	const { myMetaUser, myMetaUserOpen, otherMetaUser, setMyMetaUser } =
		useMetaUserRepo();
	const { statusGetMyMetaUser, dataGetMyMetaUser } = useMetaUserAdapter({
		myMetaUserOpen,
		otherMetaUser,
	});
	const {
		costumeColor,
		faceColor,
		hairColor,
		customizeAvatarOpen,
		setCostumeColor,
		setFaceColor,
		setHairColor,
		setModifyCostumeColor,
		setModifyFaceColor,
		setModifyHairColor,
		setCustomizeAvatarOpen,
	} = useMetaUserRepo();

	useEffect(() => {
		const { colors } = getCharacter(myMetaUser.avatarName);
		if (customizeAvatarOpen) {
			setModifyCostumeColor(colors.costumeColor ?? '');
			setModifyFaceColor(colors.faceColor ?? '');
			setModifyHairColor(colors.hairColor ?? '');
		} else {
			setCostumeColor(colors.costumeColor ?? '');
			setFaceColor(colors.faceColor ?? '');
			setHairColor(colors.hairColor ?? '');
		}
	}, [myMetaUser.avatarName]);

	const characterImages = useMemo(() => {
		return generateCharacterImages(
			myMetaUser.avatarName,
			costumeColor,
			faceColor,
			hairColor,
		);
	}, [myMetaUser.avatarName, costumeColor, faceColor, hairColor]);

	useEffect(() => {
		if (statusGetMyMetaUser === 'success') {
			if (dataGetMyMetaUser) {
				setMyMetaUser(dataGetMyMetaUser);
			}
		}
	}, [statusGetMyMetaUser, dataGetMyMetaUser]);
	return (
		<div className="metaProfile">
			<div className="metaProImg">
				<div className="avatar_character avatar_front">
					{characterImages.map((img, index) => (
						<img
							key={index}
							className="avatar_profile_object"
							style={{
								background: `url('${img}')`,
							}}
						/>
					))}
				</div>
			</div>
			<div className="metaProTxt">
				<p className="metaProGender">
					{myMetaUser.gender === 'M' ? (
						<span className="men">남성</span>
					) : (
						<span className="women">여성</span>
					)}
				</p>
				<p className="nickname padding-b4">{myMetaUser.nickName}</p>
				{myMetaUser.introduce === null ? (
					<p className="introTxt">상태 메시지가 없습니다.</p>
				) : (
					<p className="introTxt">{myMetaUser.introduce}</p>
				)}
			</div>
			<div className="metaProBtn">
				<button
					className="fffGrayBdBtn"
					onClick={() => {
						setCustomizeAvatarOpen(true);
					}}
				>
					<Link to="/metaverse/dating/sideBar/avatar">수정</Link>
				</button>
			</div>
		</div>
	);
};

export default MetaProfileView;
