import { create } from 'zustand';
import { reactEventEmitter } from '../events/ReactEventEmitter';

interface WhiteboardRepoState {
	whiteboardDialogOpen: boolean;
	whiteboardId: string | null;
	whiteboardUrl: string | null;
	urls: Map<string, string>;
	openWhiteboardDialog: (whiteboardId: string) => void;
	closeWhiteboardDialog: () => void;
	setWhiteboardUrls: (params: { whiteboardId: string; roomId: string }) => void;
}

export const useWhiteboardRepo = create<WhiteboardRepoState>((set, get) => ({
	whiteboardDialogOpen: false,
	whiteboardId: null,
	whiteboardUrl: null,
	urls: new Map(),

	openWhiteboardDialog: (whiteboardId: string) => {
		const urls = get().urls;
		const url = urls.get(whiteboardId);
		set({
			whiteboardDialogOpen: true,
			whiteboardId,
			whiteboardUrl: url || null,
		});
		reactEventEmitter.emit('react-enable-key', false);
	},

	closeWhiteboardDialog: () => {
		const { whiteboardId } = get();
		reactEventEmitter.emit('react-enable-key', true);
		if (whiteboardId) {
			reactEventEmitter.emit('react-disconnect-from-whiteboard', whiteboardId);
		}
		set({
			whiteboardDialogOpen: false,
			whiteboardId: null,
			whiteboardUrl: null,
		});
	},

	setWhiteboardUrls: ({ whiteboardId, roomId }) => {
		set((state) => {
			const newUrls = new Map(state.urls);
			newUrls.set(
				whiteboardId,
				`https://wbo.ophir.dev/boards/sky-office-${roomId}`,
			);
			return { urls: newUrls };
		});
	},
}));
