// 키보드 입력을 받아 플레이어의 움직임을 제어하는 클래스
import { MyDirectionCursor } from "./MyDirectionCursor";

export class MyMove {
  constructor(
    private myCursor: MyDirectionCursor,
    public _speed = 200 // 플레이어 이동 속도(기본값: 200)
  ) {}

  // 현재 이동 방향에 따라 x축, y축 속도를 계산하고 반환
  get velocity() {
    let vx = 0;
    let vy = 0;

    if (this.myCursor.left) {
      // 왼쪽 방향키가 눌렸을 때
      vx -= this._speed; // x축 속도를 음수로 설정하여 왼쪽으로 이동
    }

    if (this.myCursor.right) {
      // 오른쪽 방향키가 눌렸을 때
      vx += this._speed; // x축 속도를 양수로 설정하여 오른쪽으로 이동
    }

    if (this.myCursor.up) {
      // 위쪽 방향키가 눌렸을 때
      vy -= this._speed; // y축 속도를 음수로 설정하여 위쪽으로 이동
    }

    if (this.myCursor.down) {
      // 아래쪽 방향키가 눌렸을 때
      vy += this._speed; // y축 속도를 양수로 설정하여 아래쪽으로 이동
    }
    return { vx, vy };
  }
  get speed() {
    return this._speed;
  }
  set speed(value: number) {
    this._speed = value;
  }
}
