import { CSSTransition } from 'react-transition-group';
import { useHushPickService } from '@dating/ui/pages/hushPick/services/useHushPickService';
import { useTop10PicksService } from '@dating/ui/pages/hushPick/top10Picks/useTop10PicksService';
import { HushPickConfirmPopView } from '@dating/ui/pages/hushPick/HushPickConfirmPopView';
import { IdealMatchFinderHeaderView } from '@dating/ui/pages/hushPick/views/IdealMatchFinderHeaderView';
import IdealMatchFinderTabView from '@dating/ui/pages/hushPick/views/IdealMatchFinderTabView';
import { DdayProfileBoxView } from '@dating/ui/pages/hushPick/views/DdayProfileBoxView';

const Top10PicksContainer = () => {
	const {
		activeTab,
		tabOptions,
		setActiveTab,
		hushPickPopMessage,
		hushPickPopOpen,
		setHushPickPopOpen,
		fnHushPickPopOn,
		fnHushPickPopOff,
		fnHushPickPopExecute,
	} = useHushPickService();

	const {
		top10Users,
		srcPrefix,
		setUserProfileOpen,
		myProfile,
		fnGetTop10PercentUsers,
		pastTop10Users,
	} = useTop10PicksService();

	return (
		<>
			<div className="wrap noFooter">
				<IdealMatchFinderHeaderView />

				<IdealMatchFinderTabView
					activeTab={activeTab}
					tabOptions={tabOptions}
					setActiveTab={setActiveTab}
				/>

				<div className="toDayWrap padding-b0 headerExceptH">
					<div className="tabConWrap inner">
						<div className="tabContent">
							<div className="tabCon tabCon01 active">
								<div className="profileList imgSmList">
									{/*상위 10%이성 추천 리스트*/}
									{top10Users.length > 0
										? top10Users.map((profile, i) => {
											return (
												<DdayProfileBoxView
													key={profile.nickName + i}
													type={"top10"}
													profile={profile}
													srcPrefix={srcPrefix}
													setUserProfileOpen={setUserProfileOpen}
													currentUserUid={myProfile.userUid}
												/>
											);
										})
										: null}

									{/*상위 10%이성 추천 히스토리 리스트*/}
									{pastTop10Users.length > 0
										? pastTop10Users.map((profile, i) => {
											return (
												<DdayProfileBoxView
													key={profile.nickName + i}
													type={'top10'}
													profile={profile}
													srcPrefix={srcPrefix}
													setUserProfileOpen={setUserProfileOpen}
													currentUserUid={myProfile.userUid}
												/>
											);
										})
										: null}
								</div>
								<div className="hushRecoBox grayBox">
									<p className="title02">상위 10%의 이성을 더 만나고 싶다면</p>
									<button
										type="button"
										className="blackRoundBtn"
										onClick={() => {
											// 과금팝업호출
											setHushPickPopOpen(true);
										}}
									>
										상위 10% 이성 추천받기
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				className={`${
					hushPickPopOpen ? 'settingPopOn' : 'settingPop'
				}`}
			>
				<div className="settingDim" />
				<CSSTransition
					in={hushPickPopOpen}
					classNames="logoutWrap-transition"
					timeout={100}
				>
					<HushPickConfirmPopView
						popTitle={'최상위 매력'}
						popDetail={'상위 10%의 이성을 추천해 드려요'}
						fnCommonConfirmPopExecute={fnGetTop10PercentUsers}
						fnHushPickPopOff={fnHushPickPopOff}
					/>
				</CSSTransition>
			</div>
		</>
	);
};

export default Top10PicksContainer;
