import { getRange } from '@/utils/avatarUtils';
import { FC, useEffect, useState } from 'react';

interface AvatarItemTabPanelViewProps {
	type: string;
	length: number;
	onSelectAvatar: (index: number) => void;
	initialAvatar: number;
	costumeColor?: string;
	faceColor?: string;
	hairColor?: string;
	modifyCostumeColor?: string;
	modifyHairColor?: string;
	modifyFaceColor?: string;
}
const AvatarItemTabPanelView: FC<AvatarItemTabPanelViewProps> = ({
	type,
	length,
	onSelectAvatar,
	initialAvatar,
	costumeColor,
	faceColor,
	hairColor,
	modifyCostumeColor,
	modifyHairColor,
	modifyFaceColor,
}) => {
	const [selectedAvatar, setSelectedAvatar] = useState<number | null>(
		initialAvatar,
	);
	useEffect(() => {
		setSelectedAvatar(initialAvatar - 1);
	}, [initialAvatar]);

	const onClickAvatar = (idx: number) => {
		setSelectedAvatar(idx); // 클릭한 아바타 이미지 값 저장
		onSelectAvatar(idx); // 부모 컴포넌트의 state 변경 함수 호출
	};
	return (
		<>
			{getRange(length).map((_, i) => (
				<div
					className={`avatarItem ${selectedAvatar === i ? 'active' : ''}`}
					key={i}
					onClick={() => onClickAvatar(i)}
				>
					{hairColor ?? modifyHairColor ? (
						<img
							src={`/assets/character/avatar/preview/hairs/${
								hairColor ?? modifyHairColor
							}/${type}${i + 1}.png`}
							alt={`Avatar ${i + 1}`}
						/>
					) : costumeColor ?? modifyCostumeColor ? (
						<img
							src={`/assets/character/avatar/preview/costumes/${
								costumeColor ?? modifyCostumeColor
							}/${type}${i + 1}.png`}
							alt={`Avatar ${i + 1}`}
						/>
					) : faceColor ?? modifyFaceColor ? (
						<img
							src={`/assets/character/avatar/preview/faces/${
								faceColor ?? modifyFaceColor
							}/${type}${i + 1}.png`}
							alt={`Avatar ${i + 1}`}
						/>
					) : (
						<img
							src={`/assets/character/avatar/preview/body/${type}${i + 1}.png`}
							alt={`Avatar ${i + 1}`}
						/>
					)}
				</div>
			))}
		</>
	);
};

export default AvatarItemTabPanelView;
