import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useHushPickRepo} from "@dating/repository/hushPick/useHushPickRepo";

export const useHushPickService = () => {
    const location = useLocation();

    const {
        activeTab,
        setActiveTab,
        tabOptions,
        hushPickPopMessage,
        setHushPickPopMessage,
        clearHushPickPopMessage,
        hushPickPopOpen,
        setHushPickPopOpen,
        hushPickPopFunc,
        setHushPickPopFunc,
        initHushPickPopFunc,
    } = useHushPickRepo();

    /*
        * fnHushPickPopOn
        * 팝업 열기 - 메세지, 실행함수 세팅
        */
    const fnHushPickPopOn = (message1: string, message2: string, func:any, param: any) => {
        setHushPickPopMessage({
            title: message1,
            detail: message2,
        });
        setHushPickPopFunc({param: param, func: func});
        setHushPickPopOpen(true);
    };

    /*
     * fnHushPickPopOff
     * 팝업 닫기(취소) - 메세지, 실행함수 초기화
     */
    const fnHushPickPopOff = () => {
        clearHushPickPopMessage();
        initHushPickPopFunc();
        setHushPickPopOpen(false);
    };

    /*
     * fnHushPickPopExecute
     * 확인 버튼 클릭 (함수 실행 및 팝업 닫기)
     */
    const fnHushPickPopExecute = () => {
        if (hushPickPopFunc.param != null) {
            hushPickPopFunc.func(hushPickPopFunc.param);
        } else {
            hushPickPopFunc.func();
        }
        setHushPickPopOpen(false);
    };
    
    useEffect(() => {
        setActiveTab("top10");
    }, []);

    useEffect(() => {
        if (location.pathname.includes('top-10-picks')) {
            setActiveTab('top10');
        } else if (location.pathname.includes('latest-picks')) {
            setActiveTab('recent');
        } else if (location.pathname.includes('perfect-picks')) {
            setActiveTab('ideal');
        }
    }, [location]);

    return {
        activeTab,
        tabOptions,
        setActiveTab,
        hushPickPopMessage,
        hushPickPopOpen,
        setHushPickPopOpen,
        fnHushPickPopOn,
        fnHushPickPopOff,
        fnHushPickPopExecute,
    };
};
