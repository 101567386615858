import {Link} from 'react-router-dom';

interface props {
    fadeIn: boolean;
    editBtnShow: boolean;
}

const ProfileFooterBtnView = ({
                                  fadeIn,
                                  editBtnShow,
                              }: props) => {
    return (
        <div className={`proBtmBtnWrap spinnerContent ${fadeIn && editBtnShow ? 'fade-in' : ''}`}>
            <Link to="/metaverse/dating/profile/editProfile">
                <button type="button" className="btnBig btnBlack radiusNone">
                    프로필 수정
                </button>
            </Link>
        </div>
    );
};

export default ProfileFooterBtnView;
