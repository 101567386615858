import { BeatLoader } from 'react-spinners';
const Spinner = () => {
	return (
		<div className="spinnerWrapper">
			<BeatLoader color="#f81f23" margin={5} size={15} />
		</div>
	);
};

export default Spinner;
