import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { censor } from '@/utils/censor';
import { generateMessageId } from '@/utils/util';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import {usePaymentRepo} from '@dating/stores/PaymentStore';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../../../../types/IDirectMessageState';
import { useAppFooterService } from '../../footer/service/useAppFooterService';
import { useMessageRepo } from '@dating/repository/message/useMessageRepo';
import useApiDirectMessageRepo from '@dating/repository/message/useApiDirectMessageRepo';
import { useMessageAdapter } from '@dating/adapter/message/useMessageAdapter';
import { CreateMessageRoomCIVO, MessageCIVO } from '@/site/api';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';

export const useDirectMessageService = () => {
	const { setOpenOtherPlayerProfile } = useMetaUserRepo();
	const { handleMessageSendClick } = useAppFooterService();
	const { relatedRoomIds, messageInputValue, setMessageInputValue } =
		useDirectMessageRepo();
	const messageId = generateMessageId();
	const navigate = useNavigate();
	const {
		openPaymentPop,
		openLikeConfirm,
		messageTargetUser,
		setOpenPaymentPop,
	} = usePaymentRepo();
	const { myProfile, userProfile, setUserProfile } = useDatingRepo();
	const { groupedMessages } = useMessageRepo();
	const { directMessageRoomList } = useDirectMessageRepo();
	const { createdDMRoomId, setCreatedDMRoomId, setIsExistingRoom } =
		useApiDirectMessageRepo();

	// API 호출을 통해 방 생성
	const { createMessageRoomId, createMessageRoom, isMessageRoom, saveMessage } =
		useMessageAdapter();
	const { getUserProfile } = useProfileAdapter();

	useEffect(() => {
		if (messageTargetUser != '') {
			getUserProfile(messageTargetUser).then((value) => {
				setUserProfile(value);
			});
		}
	}, [messageTargetUser]);

	useEffect(() => {
		const getMessageRoomId = async () => {
			try {
				const data = createMessageRoomId();
				if (data) {
					setCreatedDMRoomId(await data);
				}
			} catch (err) {
				console.error('Failed to create message room ID:', err);
			}
		};
		if (openPaymentPop) {
			getMessageRoomId();
		}
	}, [openPaymentPop]);

	useEffect(() => {
		const getMessageRoomId = async () => {
			try {
				const data = createMessageRoomId();
				if (data) {
					setCreatedDMRoomId(await data);
				}
			} catch (err) {
				console.error('Failed to create message room ID:', err);
			}
		};
		if (openLikeConfirm) {
			getMessageRoomId();
		}
	}, [openLikeConfirm]);

	useEffect(() => {
		const getIsMessageRoom = async () => {
			try {
				const toUserUid: string = userProfile.userUid;
				const userUid: string = myProfile.userUid;
				const data = await isMessageRoom(toUserUid, userUid);
				if (data) {
					setIsExistingRoom(data);
				}
			} catch (error) {
				console.error('방 존재 여부 확인 실패:', error);
			}
		};

		if (openPaymentPop && userProfile?.userUid && myProfile?.userUid) {
			getIsMessageRoom();
		}
	}, [openPaymentPop, userProfile?.userUid, myProfile?.userUid]);

	const [messageSent, setMessageSent] = useState(false);

	const getCurrentRoomId = () => {
		// 기존 방이 있는지 먼저 확인
		const existingRoom = Object.values(directMessageRoomList).find(
			(room) =>
				(room.user1Id === myProfile.userUid &&
					room.user2Id === userProfile.userUid) ||
				(room.user1Id === userProfile.userUid &&
					room.user2Id === myProfile.userUid),
		);

		// existingRoom이 있고, visibleTo가 1, 2, -1인 경우 새로운 roomId 사용
		if (
			existingRoom &&
			(existingRoom.visibleTo === 1 ||
				existingRoom.visibleTo === 2 ||
				existingRoom.visibleTo === -1)
		) {
			return createdDMRoomId.roomId; // 새로운 roomId 사용
		}

		return existingRoom ? existingRoom.roomId : createdDMRoomId.roomId;
	};

	const handleChange = (value: string) => {
		setMessageInputValue(value);
	};

	const handleNavigation = (roomId: string) => {
		const isExistingRoom = relatedRoomIds.includes(roomId);
		let navigationState = {};
		const roomData = groupedMessages[roomId];

		// 공통으로 사용할 user 정보 객체
		const user1Info = {
			uuid: myProfile.userUid,
			name: myProfile.nickName,
			profileUrl: myProfile.profileUrl[0],
			location: myProfile.location,
			age: myProfile.age,
		};

		const user2Info = {
			uuid: userProfile.userUid,
			name: userProfile.nickName,
			profileUrl: userProfile.profileUrl[0],
			location: userProfile.location,
			age: userProfile.age,
		};

		if (isExistingRoom) {
			// 이미 존재하는 방일 경우
			const isUser1 = myProfile.userUid === roomData.user1Id;
			navigationState = {
				billingStatus: roomData.billingStatus,
				myPaymentStatus: isUser1
					? roomData.billingStatus === 1
					: roomData.billingStatus === 2,
				otherPaymentStatus: isUser1
					? roomData.billingStatus === 2
					: roomData.billingStatus === 1,
				dmRoomType: roomData.dmRoomType,
				roomId: roomId,
				visibleTo: roomData.visibleTo,
				isUserLeft: isUser1
					? roomData.visibleTo === 1
					: roomData.visibleTo === 2,
				isOtherUserLeft: isUser1
					? roomData.visibleTo === 2
					: roomData.visibleTo === 1,
				user1Info: roomData.user1Info || user1Info,
				user2Info: roomData.user2Info || user2Info,
				otherUserInfo: isUser1
					? roomData.user2Info || user2Info
					: roomData.user1Info || user1Info,
				createdAt: roomData.createdAt || new Date().toString(),
			};
		} else {
			// 새로운 방일 경우 (첫 메시지)
			navigationState = {
				billingStatus: 1,
				myPaymentStatus: true,
				otherPaymentStatus: false,
				dmRoomType: 'FIRST',
				roomId: roomId,
				visibleTo: 0,
				isUserLeft: false,
				isOtherUserLeft: false,
				user1Info: user1Info,
				user2Info: user2Info,
				otherUserInfo: user2Info, // 첫 메시지를 보내는 사람이 user1이므로, 상대방은 항상 user2
				createdAt: new Date().toString(),
			};
		}

		const map: CreateMessageRoomCIVO = {
			roomId: roomId,
			userUid: myProfile.userUid,
			toUserUid: userProfile.userUid,
			billingStatus: 1,
			roomType: 'FIRST',
		};
		createMessageRoom(map);

		navigate(`/metaverse/messenger/message/${roomId}`, {
			state: navigationState,
		});
		setOpenPaymentPop(false);
		handleMessageSendClick();
	};

	const handlePaymentClick = () => {
		if (messageInputValue.trim() === '') {
			setOpenPaymentPop(false);
		} else {
			const currentRoomId = getCurrentRoomId();
			handleSubmit(currentRoomId);
			handleNavigation(currentRoomId);
			setMessageSent(true);
		}
	};

	const handleSubmit = async (currentRoomId: string) => {
		const val = censor(messageInputValue.trim());
		if (val) {
			const sender: Partial<IUser> = {
				uuid: myProfile.userUid,
				name: myProfile.nickName,
				profileUrl: myProfile.profileUrl[0],
				location: myProfile?.location,
				age: myProfile.age,
			};

			const receiver: Partial<IUser> = {
				uuid: userProfile.userUid,
				name: userProfile.nickName,
				profileUrl: userProfile.profileUrl[0],
				location: userProfile.location,
				age: userProfile.age,
			};

			const roomData = {
				billingStatus: 1,
				dmRoomType: 'FIRST',
				roomId: currentRoomId,
				user1Id: myProfile.userUid,
				user2Id: userProfile.userUid,
				user1Info: sender,
				user2Info: receiver,
				createdAt: Date.now().toString(),
				otherUserInfo: receiver,
			};

			if (!relatedRoomIds.includes(currentRoomId)) {
				reactEventEmitter.emit('react-create-dm-room', roomData);
			}

			const messageData = {
				roomId: currentRoomId,
				messageId: messageId,
				sender: sender,
				receiver: receiver,
				content: val,
				createdAt: Date.now().toString(),
				read: false,
			};

			reactEventEmitter.emit('react-send-direct-message', messageData);

			if (!relatedRoomIds.includes(currentRoomId)) {
				await new Promise((resolve) => setTimeout(resolve, 100));
				reactEventEmitter.emit('react-single-payment-completed', {
					roomId: currentRoomId,
					payerId: myProfile.userUid,
				});
			}

			const map: MessageCIVO = {
				roomId: currentRoomId,
				content: val,
				read: false,
				createdAt: new Date().toString(),
			};
			saveMessage(map);

			setMessageInputValue('');
		}
	};

	useEffect(() => {
		if (messageSent) {
			const currentRoomId = getCurrentRoomId();
			handleNavigation(currentRoomId);
			setOpenOtherPlayerProfile(false, null);
			setMessageSent(false);
		}
	}, [messageSent]);

	return {
		messageInputValue,
		handleChange,
		handleSubmit,
		handlePaymentClick,
	};
};
