const BlockAcquaintanceWrapView = () => {
	return (
		<div className="popConWrap hiddenCon">
			<div className="inner padding-t8">
				<div className="titleWrap">
					<h2 className="title01">내 연락처 목록 차단하기</h2>
					<p className="titleSub01">
						등록된 연락처의 회원은 나에게 추천되지 않고,
						<br />
						해당 회원에게도 내가 추천되지 않습니다.
					</p>
				</div>
				<div className="icoCenter text-c">
					<img src="/assets/img/common/ico-black-list.svg" width={100} alt="" />
				</div>
			</div>
		</div>
	);
};

export default BlockAcquaintanceWrapView;
