import { MyPlayerState, StateParams } from '../../MyPlayerState';

export class MoveState implements MyPlayerState {
	constructor(private param: StateParams) {}
	update() {
		const { myPlayer, myKeyboard } = this.param;
		// 캐릭터 속도 업데이트
		const { vx, vy } = myPlayer.myMove.velocity;

		if (vy !== 0) {
			// 캐릭터 뎁스 업데이트
			myPlayer.setDepth(myPlayer.y); // y값이 높을수록 더 위에 그려짐
			// E 키 입력 상태를 초기화
			// myKeyboard.resetE();
			myKeyboard.resetClickActionE();

			// R 키 입력 상태를 초기화
			// myKeyboard.resetR();
			myKeyboard.resetClickActionR();
		}
		myPlayer.setVelocity(vx, vy);

		// 속도에 따라 애니메이션 업데이트 하고, 새 위치와 애니메이션 서버로 전송
		if (vx > 0) {
			myPlayer.playWrab(`${myPlayer.playerTexture}_run_right`, true);
		} else if (vx < 0) {
			myPlayer.playWrab(`${myPlayer.playerTexture}_run_left`, true);
		} else if (vy > 0) {
			myPlayer.playWrab(`${myPlayer.playerTexture}_run_down`, true);
		} else if (vy < 0) {
			myPlayer.playWrab(`${myPlayer.playerTexture}_run_up`, true);
		} else if (myPlayer.anims.currentAnim?.key) {
			const parts = myPlayer.anims.currentAnim.key.split('_');
			parts[1] = 'idle';
			const newAnim = parts.join('_');
			// 캐릭터가 움직이지 않을 때, idle 애니메이션을 실행하고 서버로 새 위치와 애니메이션 전송
			if (myPlayer.anims.currentAnim.key !== newAnim) {
				myPlayer.playWrab(parts.join('_'), true);
				// 서버로 새 위치와 애니메이션 전송
			}
		}
	}
}
