import NoticeListView from './views/NoticeListView';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';

const NoticeContainer = () => {
	const title = '공지사항';
	return (
		<div className="rightShow active">
			<ButtonHeaderView title={title} right={false} />
			<NoticeListView />
		</div>
	);
};

export default NoticeContainer;
