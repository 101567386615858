import {UserWaitingInfoCOVO} from "@/site/api";

interface props {
    popOnHandler: (id: string) => void;
    interestValue: { [key: string]: boolean }[];
    interestHandler: (id: string) => void;
    lifeStyleValue: { [key: string]: boolean }[];
    lifeStyleHandler: (id: string) => void;
    customInterestList: { id: string, styleValue: string }[];
    customInterestValue: string[] | { id: string, state: boolean }[];
    customInterestHandler: (id: string) => void;
    customLifeStyleList: { id: string, styleValue: string }[];
    customLifeStyleValue: string[] | { id: string, state: boolean }[];
    customLifeStyleHandler: (id: string) => void;
    drinkingValue: string,
    smokingValue: string,
    religionValue: string,
    initReligion: { code: string, value: string }[] | string[];
    initDrinkingState: { code: string, value: string }[] | string[];
    initSmokingState: { code: string, value: string }[] | string[];
    setSmokingValue: (smokingValue: string) => void;
    setReligionValue: (religionValue: string) => void;
    setDrinkingValue: (drinkingValue: string) => void;
    userWaiting: UserWaitingInfoCOVO;
    lifeStyleList: string[] | { id: string, styleValue: string }[];
    interestList: string[] | { id: string, styleValue: string }[];
};

const LifeStyleContentsView = ({
                                   popOnHandler,
                                   interestHandler,
                                   interestValue,
                                   lifeStyleValue,
                                   lifeStyleHandler,
                                   customInterestList,
                                   customInterestValue,
                                   customInterestHandler,
                                   customLifeStyleList,
                                   customLifeStyleValue,
                                   customLifeStyleHandler,
                                   drinkingValue,
                                   smokingValue,
                                   religionValue,
                                   initReligion,
                                   initDrinkingState,
                                   initSmokingState,
                                   setSmokingValue,
                                   setReligionValue,
                                   setDrinkingValue,
                                   userWaiting,
                                   lifeStyleList,
                                   interestList,
                               }: props) => {

    return (
        <div className="contentsWrap">
            <div className="content margin-t16">
                <div className="cateTitBox">
                    <span className="cateTit01 like">관심사</span>
                    {/*<button className="subAddBtn popOnBtn" onClick={() => {*/}
                    {/*    popOnHandler("interest");*/}
                    {/*}}>*/}
                    {/*    나만의 항목 추가*/}
                    {/*</button>*/}
                </div>

                <div className="myCateList">
                    {
                        interestList.length > 0 ?
                            interestList.map((item, i) => {
                                return (
                                    <button
                                        type="button"
                                        key={item.id + item.styleValue}
                                        className={
                                            interestValue[item.id] ? "active" : ""
                                        }
                                        onClick={() => {
                                            interestHandler(item.id);
                                        }}
                                    >
                                        {item.styleValue}
                                    </button>
                                );
                            })
                            : null
                    }

                    {
                        customInterestList.length > 0 ?
                            customInterestList?.map((item) => {
                                return (
                                    <button
                                        key={item.id}
                                        type="button"
                                        className={
                                            customInterestValue[item.id] ? "active" : ""
                                        }
                                        onClick={() => {
                                            customInterestHandler(item.id)
                                        }}
                                    >
                                        {item.styleValue}
                                    </button>
                                );
                            })
                            : null
                    }
                </div>

                <div className="cateTitBox">
                    <span className="cateTit01 life">라이프 스타일</span>
                    {/*<button className="subAddBtn popOnBtn" onClick={() => {*/}
                    {/*    popOnHandler("lifeStyle");*/}
                    {/*}}>*/}
                    {/*    나만의 항목 추가*/}
                    {/*</button>*/}
                </div>
                <div className="myCateList">
                    {
                        lifeStyleList.length > 0 ?
                            lifeStyleList.map((item) => {
                                return (
                                    <button
                                        key={item.id + item.styleValue}
                                        type="button"
                                        className={
                                            lifeStyleValue[item.id] ? "active" : ""
                                        }
                                        onClick={() => {
                                            lifeStyleHandler(item.id);
                                        }}
                                    >
                                        {item.styleValue}
                                    </button>
                                );
                            })
                            : null
                    }

                    {
                        customLifeStyleList.length > 0 ?
                            customLifeStyleList?.map((item) => {
                                return (
                                    <button
                                        key={item.id}
                                        type="button"
                                        className={
                                            customLifeStyleValue[item.id] ? "active" : ""
                                        }
                                        onClick={() => {
                                            customLifeStyleHandler(item.id);
                                        }}
                                    >
                                        {item.styleValue}
                                    </button>
                                );
                            })
                            : null
                    }
                </div>

                <div className="cateTitBox">
                    <span className="cateTit01 smoking">흡연 여부</span>
                </div>
                <div className="myCateList">
                    {
                        initSmokingState.map((item) => {
                            return (
                                <button
                                    key={item.id + item.value}
                                    className={item.code.toString() === smokingValue ? "active" : ""}
                                    onClick={() => {
                                        setSmokingValue(item.code);
                                    }}>
                                    {item.value}
                                </button>
                            )
                        })

                    }
                </div>
            </div>
        </div>
    )
};

export default LifeStyleContentsView;