import {useDatingRepo} from "@dating/repository/dating/useDatingRepo";
import {useHomeAdapter} from "@dating/adapter/home/useHomeAdapter";
import {useEffect} from "react";
import {useLatestPicksStateRepo} from "@dating/ui/pages/hushPick/latestPicks/useLatestPicksStateRepo";
import { Top10PerUserCOVO } from '@/site/api';

export const useLatestPicksService = () => {
    const {
        pastRecentLoginUsers,
        setPastRecentLoginUsers,
        recentLoginUsers,
        setRecentLoginUsers,
    } = useLatestPicksStateRepo();

    const {
        srcPrefix,
        setUserProfileOpen,
        myProfile,
    } = useDatingRepo();

    const {
        getPastRecentLoginUser,
        getRecentLoginUsers,
    } = useHomeAdapter();

    /*
      * fnGetPastRecentLoginUser
      * 최근 접속한 이성 추천 히스토리
      */
    const fnGetPastRecentLoginUser = () => {
        getPastRecentLoginUser().then((response) => {
            if (response.length > 0) {
                setPastRecentLoginUsers(response);
            }
        });
    };

    /*
     * fnGetRecentLoginUsers
     * 최근 접속한 이성 추천(과금)
     */
    const fnGetRecentLoginUsers = () => {
        getRecentLoginUsers().then((response) => {
            if (response.length > 0) {
                let newItem: Top10PerUserCOVO[] = [];
                newItem = [...recentLoginUsers, ...response];
                setRecentLoginUsers(newItem);
            }
        });
    };

    useEffect(() => {
        fnGetPastRecentLoginUser();
    }, []);

    return {
        pastRecentLoginUsers,
        recentLoginUsers,
        srcPrefix,
        setUserProfileOpen,
        myProfile,
        fnGetRecentLoginUsers,
    };
};