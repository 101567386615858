import React from "react";

interface props {
    setRecommendCode: (recommendCode: string) => void;
    codeFailed: boolean;
}

const RecommendCodeContentsView = ({
                                       setRecommendCode,
                                       codeFailed,
                                   }: props) => {
    return (
        <div className="content">
            <div className="inputWrap">
                <div className="input">
                    <input
                        id="firstFocus"
                        type="text"
                        placeholder="발급코드를 입력해 주세요"
                        onChange={(e) => {
                            setRecommendCode(e.target.value)
                        }}
                    />
                </div>
                {
                    codeFailed ?
                        (
                            <p className="sub-color fz12 padding-t8">
                                유효하지 않은 코드입니다.
                            </p>
                        )
                        : ""
                }
            </div>
            <div className="exTxtWrap margin-t24">
                <p className="fz16Fw500">유의사항</p>
                <ul className="exTxtList margin-t8">
                    <li>가입 완료 후에는 추천인 입력이 불가합니다.</li>
                    <li>가입 승인까지 완료된 후에 베리드 보상이 주어집니다.</li>
                    <li>
                        이벤트 취지에 맞지 않는 부정행위가 확인되는 경우 이벤트 지급 재화 등의 회수 <br/>
                        혹은 이벤트 참여 제한 등의 조치가 적용될 수 있습니다.
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default RecommendCodeContentsView;