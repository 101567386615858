import HighlyRatedListView from './HighlyRatedListView';
import LikeMoreButtonView from '../../views/LikeMoreButtonView';
import LikeTitleView from '../../views/LikeTitleView';
import {ReceiveUserHighScoreCOVO} from "@/site/api";

interface props {
    titSet: { mainTit: string; subTit: string };
    index: number;
    scrollYIndex: number;
    noDataHighlyRated: boolean;
    highlyRatedUsers: ReceiveUserHighScoreCOVO[];
    fnGetReceiveUserHighScore: () => void;
    srcPrefix: string;
    setUserProfileOpen: (userProfileOpen: string) => void;
    fnSaveUserLike: (userUid: string, state: boolean) => void;
    fnRemoveReceiveHighlyRatedUsers: (id: string, type: string) => void;
    fnSendLikePopOpen: (userUid: string, profileUrl: string, nickName: string) => void;
};

const HighlyRatedView = ({
                             titSet,
                             index,
                             scrollYIndex,
                             noDataHighlyRated,
                             highlyRatedUsers,
                             fnGetReceiveUserHighScore,
                             srcPrefix,
                             setUserProfileOpen,
                             fnSaveUserLike,
                             fnRemoveReceiveHighlyRatedUsers,
                             fnSendLikePopOpen,
                         }: props) => {
    return (
        <>
            <LikeTitleView
                titSet={titSet}
                scrollYIndex={scrollYIndex}
                index={index}
            />

            <HighlyRatedListView
                highlyRatedUsers={highlyRatedUsers}
                srcPrefix={srcPrefix}
                setUserProfileOpen={setUserProfileOpen}
                fnSaveUserLike={fnSaveUserLike}
                fnRemoveReceiveHighlyRatedUsers={fnRemoveReceiveHighlyRatedUsers}
                fnSendLikePopOpen={fnSendLikePopOpen}
            />

            <LikeMoreButtonView
                fnGetData={fnGetReceiveUserHighScore}
                btnHandle={noDataHighlyRated}
            />
        </>
    );
};

export default HighlyRatedView;
