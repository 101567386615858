const AuthAddWrapView = () => {
    const auths = [
        {
            category: "대기업 인증",
            title: "삼성전자 재무팀",
        },
        {
            category: "학력/학교 인증",
            title: "서울 대학교 / 법학과",
        },
    ];
    return (
        <div className="authAddWrap padding-t32">
            <div className="inner">
                <p className="title02 text-c">추가 인증 사항</p>
                <p className="titleSub03 text-c">사실 증명을 통해 인증된 사항입니다.</p>

                <div className="authList margin-t16">
                    {auths.map((auth, i) => {
                        return (
                            <div key={auth.category} className="leftRightTxt">
                                <div>
                                    <p className="fz14 bold-500">{auth.category}</p>
                                    <p className="fz12 color-666 margin-t4">{auth.title}</p>
                                </div>
                                <div>
                                    <button className="ic-check" disabled/>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default AuthAddWrapView;
