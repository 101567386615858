import { Link } from 'react-router-dom';

interface props {
    resetRegisterInfo: () => void;
}
const RegisterMainView = ({ resetRegisterInfo }: props) => {
	return (
		<div className="mainBtm">
			<button type="button" className="btn btnBig btnBlack fz16">
				<Link
					to={'/metaverse/dating/register/phone'}
					onClick={() => {
                        resetRegisterInfo();
                    }}
				>
					전화번호로 시작하기
				</Link>
			</button>
			<div className="mainSnsList">
				{/*<Link*/}
				{/*	to={'/metaverse/dating/register/phone'}*/}
				{/*	onClick={() => setPhoneNumber('')}*/}
				{/*>*/}
				{/*	<img*/}
				{/*		src="/assets/img/common/ico-google.png"*/}
				{/*		width="50"*/}
				{/*		height="50"*/}
				{/*		alt="구글 로그인"*/}
				{/*	/>*/}
				{/*</Link>*/}
				{/*<Link*/}
				{/*	to={'/metaverse/dating/register/phone'}*/}
				{/*	onClick={() => setPhoneNumber('')}*/}
				{/*>*/}
				{/*	<img*/}
				{/*		src="/assets/img/common/ico-kakao.png"*/}
				{/*		width="50"*/}
				{/*		height="50"*/}
				{/*		alt="카카오톡 로그인"*/}
				{/*	/>*/}
				{/*</Link>*/}
				{/*<Link*/}
				{/*	to={'/metaverse/dating/register/phone'}*/}
				{/*	onClick={() => setPhoneNumber('')}*/}
				{/*>*/}
				{/*	<img*/}
				{/*		src="/assets/img/common/ico-naver.png"*/}
				{/*		width="50"*/}
				{/*		height="50"*/}
				{/*		alt="네이버 로그인"*/}
				{/*	/>*/}
				{/*</Link>*/}
			</div>
		</div>
	);
};

export default RegisterMainView;
