import { useNavigate } from 'react-router-dom';

interface props {
    fnSaveUserLocation: (response: any) => void;
}

const LocationInfoUnableFooterView = ({
                                          fnSaveUserLocation,
                                      }: props) => {
    const navigate = useNavigate();
    return (
        <footer>
            <div className="f-btnWrap">
                <button className="btn btnBig btnBlack" onClick={() => {
                    let map: any = null;
                    if (window.flutter_inappwebview && window.flutter_inappwebview.callHandler) {
                        window.flutter_inappwebview.callHandler('requestLocation', 'Hello from Web')
                            .then(function(response) {
                                // Flutter로부터 받은 응답 처리
                                console.log('Received response from Flutter: ' + response);
                                alert('위치정보 ===> ' + response);
                                map = response;
                                if (map == null) {
                                    navigate("/metaverse/dating/register/locationUnable");
                                    return;
                                }
                                // 서버로 전송
                                fnSaveUserLocation(map);
                            })
                            .catch((error) => console.error('Error calling handler:', error));
                    } else {
                        // PC 브라우저 환경 (InAppWebView가 없는 경우)
                        console.warn(
                            'PC 브라우저에서는 flutter_inappwebview가 지원되지 않습니다.',
                        );
                        fnSaveUserLocation(map);
                    }
                }}>
                    <span>설정 열기</span>
                </button>
            </div>
        </footer>
    )
};

export default LocationInfoUnableFooterView;