import {useEffect} from "react";
import {useHomeAdapter} from "@dating/adapter/home/useHomeAdapter";
import {useDatingRepo} from "@dating/repository/dating/useDatingRepo";
import {useTop10PicksStateRepo} from "@dating/ui/pages/hushPick/top10Picks/useTop10PicksStateRepo";
import { Top10PerUserCOVO } from '@/site/api';

export const useTop10PicksService = () => {
    const {
        top10Users,
        setTop10Users,
        pastTop10Users,
        setPastTop10Users,
    } = useTop10PicksStateRepo();

    const {
        srcPrefix,
        setUserProfileOpen,
        myProfile,
    } = useDatingRepo();

    const {
        getTop10PercentUsers,
        getPastTopUser,
    } = useHomeAdapter();

    /*
     * fnGePastTop10Users
     * 상위 10% 추천 히스토리 가져오기
     */
    const fnGetPastTop10Users = () => {
        getPastTopUser().then((response) => {
            if (response.length > 0) {
                setPastTop10Users(response);
            }
        });
    };

    /*
     * fnGetTop10PercentUsers
     * 상위 10% 추천 받기(과금)
     */
    const fnGetTop10PercentUsers = () => {
        getTop10PercentUsers().then((response) => {
            if (response.length > 0) {
                let newItem: Top10PerUserCOVO[] = [];
                newItem = [...top10Users, ...response];
                setTop10Users(newItem);
            }
        });
    };

    useEffect(() => {
        fnGetPastTop10Users();
    }, []);

    return {
        top10Users,
        srcPrefix,
        setUserProfileOpen,
        myProfile,
        fnGetTop10PercentUsers,
        pastTop10Users,
    };
};