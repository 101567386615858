import {create} from "zustand";
import type {BlockUserCOVO} from "@/site/api";

interface SettingsState {
    blockUsers: BlockUserCOVO[];
    setBlockUsers: (blockUsers: BlockUserCOVO[]) => void;
};

export const useSettingsRepo = create<SettingsState>(
    (set) => ({
        blockUsers: [],
        setBlockUsers: (blockUsers: BlockUserCOVO[]) => set({blockUsers}),
    }),
);