import MissionListView from './views/MissionListView';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';

const MissionContainer = () => {
	const title = '미션';
	return (
		<div className="rightShow active">
			<ButtonHeaderView title={title} right={false} />
			<MissionListView />
		</div>
	);
};

export default MissionContainer;
