import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import { useEffect } from 'react';
import { useRegisterWaitingStateRepo } from '@dating/ui/pages/register/waiting/useRegisterWatingStateRepo';
import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { useAvatarProfileService } from '@/ui/components/avatarProfile/service/useAvatarProfileService';

export const useWaitingService = () => {
	const {
		btnDisabled,
		approvedAccount,
		response,
		setResponse,
	} = useRegisterWaitingStateRepo();

	const {
		step,
		userWaiting,
		setUserWaiting,
		phoneNumber,
	} = useRegisterRepo();

	const {
		srcPrefix,
		sliderSettings,
	} = useDatingRepo();

	const {
		setMyMetaUserOpen,
		myMetaUser,
	} = useMetaUserRepo();

	const {
		getUserWaiting,
		startSignup,
	} = useRegisterAdapter();

	const {handleCreateAvatar} = useAvatarProfileService();
	/////////////////////////////////////////////////

	useEffect(() => {
		// if (step === 10) {
		getUserWaiting().then((response) => {
			if (response != undefined) {
				setUserWaiting(response);
			}
		});
		// }
	}, [approvedAccount, step]);

	const isEmptyUser = (user) => {
		return (
			!user ||
			Object.values(user).every((x) => x === null || x === '' || x === false)
		);
	};

	/*
     * clickStartBtn
     * 시작하기 버튼 클릭 시
     * myMetaUserOpen true
     * handleCreateAvatar 호출
     */
	const clickStartBtn = () => {
		if (isEmptyUser(myMetaUser)) {
			handleCreateAvatar();
			setMyMetaUserOpen(true);
		}

		startSignup().then(value => {
			setResponse(true);
		});
	};

	return {
		btnDisabled,
		approvedAccount,
		userWaiting,
		srcPrefix,
		clickStartBtn,
		sliderSettings,
		response,
		phoneNumber,
	};
};
