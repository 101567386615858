import { formatMatchingTime, normalizeToTimestamp } from '@/utils/util';
import { Link } from 'react-router-dom';

const PartialPaymentMessageView = ({
	myProfile,
	groupedMessagesDetail,
	srcPrefix,
	navigationRoom,
	handlePaymentClick,
	setUserProfileOpen,
	matchingTime,
	currentMessageLength,
}) => {
	const renderLastMessage = () => {
		if (groupedMessagesDetail.other && groupedMessagesDetail.other.length > 0) {
			const lastGroup =
				groupedMessagesDetail.other[groupedMessagesDetail.other.length - 1];
			if (lastGroup.messages && lastGroup.messages.length > 0) {
				return lastGroup.messages[lastGroup.messages.length - 1].content;
			}
		}
		return;
	};
	const createdTime = normalizeToTimestamp(matchingTime);
	const formattedTime = formatMatchingTime(createdTime.toString());

	return (
		<div className="inner chatRoom">
			<div className="chatList">
				<div className="chatStartBox">
					<div className="startTxt text-c">
						<p className="txt01">
							{navigationRoom.otherUserInfo.name} 님과 {formattedTime} 전 매칭
						</p>
						<p className="txt02 margin-t5">
							따뜻한 인사로
							<br />
							대화를 시작해보세요!
						</p>
					</div>
					<Link
						to="/metaverse/dating/profile"
						onClick={() => {
							setUserProfileOpen(navigationRoom.otherUserInfo.uuid);
						}}
						state={{
							isOtherProfile:
								navigationRoom.otherUserInfo.uuid !== myProfile.uuid,
						}}
					>
						<div className="profileBigImg margin-t10">
							<img
								src={srcPrefix + navigationRoom.otherUserInfo.profileUrl}
								alt={navigationRoom.otherUserInfo.name}
							/>
						</div>
					</Link>
					{currentMessageLength === 0 ? null : (
						<div className="startMsg margin-t8">
							<p>{renderLastMessage()}</p>
						</div>
					)}
				</div>
			</div>
			<div className="chatInput input padding-16 bg-fff">
				<button
					className="btn btnBig btnBlack"
					onClick={() => {
						handlePaymentClick();
					}}
				>
					<span>
						대화열기<span className="ic-berith">0</span>
					</span>
				</button>
			</div>
		</div>
	);
};

export default PartialPaymentMessageView;
