import RegisterHeaderView from "../common/registerHeader/views/RegisterHeaderView";
import {useBirthdayService} from "./service/useBirthdayService";
import BirthdayContentsView from "./views/BirthdayContentsView";
import BirthdayFooterView from "./views/BirthdayFooterView";
import React, { useEffect } from "react";
import {useRegisterHeaderService} from "@dating/ui/pages/register/common/registerHeader/service/useRegisterHeaderService";
import StepProgressView from "@dating/ui/pages/register/common/stepProgress/views/StepProgressView";
import { useNavigate } from "react-router-dom";

const BirthdayContainer = () => {
    const {
        inputBirthday,
        fnSaveBirthday,
        handleInputChange,
        handleInputKeyDown,
        btnActivate,
        activeIndex,
        setBtnActivate,
        birthCheckMessage,
        saveBirthdayStatus,
        saveBirthdayData,
        setStep,
        registerSteps,
        setBirthCheckMessage,
    } = useBirthdayService();

    const {
        referralCodeOpen,
        setReferralCodeOpen,
        skipHandler,
		step,
    } = useRegisterHeaderService();

    const navigate = useNavigate();
    useEffect(() => {
        if (saveBirthdayStatus === "success") {
            if (saveBirthdayData != undefined) {
                if (saveBirthdayData.result) {
                    setStep(4);
                    navigate(registerSteps[4].url);
                } else {
                    setBirthCheckMessage(saveBirthdayData.resultCode);
                }
            }
        } else if (saveBirthdayStatus === "error") {

        }
    }, [saveBirthdayStatus, saveBirthdayData]);

    return (
        <>
            <div className="wrap noFooter">
                <RegisterHeaderView
                    referralCodeOpen={referralCodeOpen}
                    setReferralCodeOpen={setReferralCodeOpen}
                    skipHandler={skipHandler}
                />

                <div className="contentsWrap">
                    <div className="titleWrap">
                        <StepProgressView step={step}/>
                        <h2 className="title01">생년월일 등록</h2>
                        <p className="titleSub01">생년월일 6자리를 입력해 주세요</p>
                    </div>
                    <BirthdayContentsView
                        inputBirthday={inputBirthday}
                        handleInputChange={handleInputChange}
                        handleInputKeyDown={handleInputKeyDown}
                        activeIndex={activeIndex}
                        setBtnActivate={setBtnActivate}
                        birthCheckMessage={birthCheckMessage}
                    />
                </div>

                <BirthdayFooterView
                    fnSaveBirthday={fnSaveBirthday}
                    btnActivate={btnActivate}
                />
            </div>
        </>
    );
};

export default BirthdayContainer;
