import type { LocationInfoCIVO } from '@/site/api';
import { useRegisterAdapter } from '@dating/adapter/register/useRegisterAdapter';
import { useRegisterRepo } from '@dating/repository/register/useRegisterRepo';

export default function useLocationInfoService() {
	const {
		setStep,
		registerSteps,
	} = useRegisterRepo();

	const {
		saveUserLocation,
		statusSaveUserLocation,
		dataSaveUserLocation,
	} = useRegisterAdapter();

	/*
	 * fnSaveUserLocation
	 * 위치정보공유 동의 시 좌표값 저장
	 */
	const fnSaveUserLocation = (map: LocationInfoCIVO) => {
		saveUserLocation(map);
	};

	return {
		fnSaveUserLocation,
		statusSaveUserLocation,
		dataSaveUserLocation,
		setStep,
		registerSteps,
	};
}