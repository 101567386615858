import { MyPlayerState } from "../../MyPlayerState";
/**
 * 플레이어가 의자에 앉아 있는 동안의 상태를 나타내는 SittingState 클래스를 정의함.
 * SittingState 클래스는 MyPlayerState 인터페이스를 구현하고 있으며, update() 메서드를 가지고 있음.
 * update() 메서드는 아무것도 하지 않음.
 * 즉, 플레이어가 SittingState 상태일 때는 아무것도 하지 않음.
 */

export class SittingState implements MyPlayerState {
  update(): void {
    // 아무것도 하지 않음
  }
}
