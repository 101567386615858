/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MetaDisposeRoomCIVO } from '../models/MetaDisposeRoomCIVO';
import type { MetaResultCOVO } from '../models/MetaResultCOVO';
import type { MetaRoomChatCIVO } from '../models/MetaRoomChatCIVO';
import type { MetaRoomCIVO } from '../models/MetaRoomCIVO';
import type { MetaRoomUserCOVO } from '../models/MetaRoomUserCOVO';
import type { MetaRoomUserInCIVO } from '../models/MetaRoomUserInCIVO';
import type { MetaRoomUserOutCIVO } from '../models/MetaRoomUserOutCIVO';
import type { MetaUserAvatarCIVO } from '../models/MetaUserAvatarCIVO';
import type { MetaUserCIVO } from '../models/MetaUserCIVO';
import type { MetaUserCOVO } from '../models/MetaUserCOVO';
import type { MetaUserMediaCIVO } from '../models/MetaUserMediaCIVO';
import type { MetaUserMediaCOVO } from '../models/MetaUserMediaCOVO';
import type { MetaUserWebRTCCIVO } from '../models/MetaUserWebRTCCIVO';
import type { MetaUserWebRTCCOVO } from '../models/MetaUserWebRTCCOVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MetaService {
    /**
     * 메타버스 회원 정보 저장
     * @param requestBody 메타버스 회원 정보 저장 VO
     * @returns MetaResultCOVO
     * @throws ApiError
     */
    public static saveMetaUser(
        requestBody: MetaUserCIVO,
    ): CancelablePromise<MetaResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/meta/saveMetaUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 메타버스 회원 정보 조회
     * @returns MetaUserCOVO 메타버스 회원 정보 조회
     * @throws ApiError
     */
    public static getMyMetaUser(): CancelablePromise<MetaUserCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/meta/getMyMetaUser',
        });
    }
    /**
     * 메타버스 회원 정보 조회
     * @param findUserUid
     * @returns MetaUserCOVO 메타버스 회원 정보 조회
     * @throws ApiError
     */
    public static getMetaUser(
        findUserUid: string,
    ): CancelablePromise<MetaUserCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/meta/getMetaUser/{findUserUid}',
            path: {
                'findUserUid': findUserUid,
            },
        });
    }
    /**
     * 메타버스 room 회원 정보 조회
     * @param findUserUid
     * @returns MetaRoomUserCOVO 메타버스 room 회원 정보 조회
     * @throws ApiError
     */
    public static getMetaRoomUser(
        findUserUid: string,
    ): CancelablePromise<MetaRoomUserCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/meta/getMetaRoomUser/{findUserUid}',
            path: {
                'findUserUid': findUserUid,
            },
        });
    }
    /**
     * 메타버스 회원 아바타 정보 저장
     * @param requestBody 메타버스 회원 아바타 정보 저장 VO
     * @returns MetaResultCOVO
     * @throws ApiError
     */
    public static saveMetaUserAvatar(
        requestBody: MetaUserAvatarCIVO,
    ): CancelablePromise<MetaResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/meta/saveMetaUserAvatar',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 메타버스 회원 미디어 권한 설정 저장
     * @param requestBody 메타버스 회원 미디어 권한 설정 저장 VO
     * @returns MetaResultCOVO
     * @throws ApiError
     */
    public static saveUserMediaConnected(
        requestBody: MetaUserMediaCIVO,
    ): CancelablePromise<MetaResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/meta/saveUserMediaConnected',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 메타버스 회원 미디어 권한 설정 조회
     * @returns MetaUserMediaCOVO
     * @throws ApiError
     */
    public static getUserMediaConnected(): CancelablePromise<MetaUserMediaCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/meta/getUserMediaConnected',
        });
    }
    /**
     * 메타버스 회원 WebRTC 연결 설정 저장
     * @param requestBody 메타버스 회원 미디어 권한 설정 저장 VO
     * @returns MetaResultCOVO
     * @throws ApiError
     */
    public static saveUserWebRtcConnect(
        requestBody: MetaUserWebRTCCIVO,
    ): CancelablePromise<MetaResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/meta/saveUserWebRTCConnect',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 메타버스 회원 미디어 권한 설정 조회
     * @returns MetaUserWebRTCCOVO
     * @throws ApiError
     */
    public static getUserWebRtcConnect(): CancelablePromise<MetaUserWebRTCCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/meta/getUserWebRTCConnected',
        });
    }
    /**
     * 메타버스 방만들기 룸생성 저장
     * @param requestBody 메타버스 방만들기 룸생성 저장 VO
     * @returns MetaResultCOVO
     * @throws ApiError
     */
    public static saveCreateRoom(
        requestBody: MetaRoomCIVO,
    ): CancelablePromise<MetaResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/meta/saveCreateRoom',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 메타버스 사용가능한 룸조회
     * @returns any[]
     * @throws ApiError
     */
    public static getUsableRoom(): CancelablePromise<any[]> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/meta/getUsableRoom',
        });
    }
    /**
     * 메타버스 유저 룸 입장 저장
     * @param requestBody 메타버스 유저 룸 입장 저장 VO
     * @returns MetaResultCOVO
     * @throws ApiError
     */
    public static saveRoomUserIn(
        requestBody: MetaRoomUserInCIVO,
    ): CancelablePromise<MetaResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/meta/saveRoomUserIn',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 메타버스 유저 룸 퇴장 저장
     * @param requestBody 메타버스 유저 룸 퇴장 저장 VO
     * @returns MetaResultCOVO
     * @throws ApiError
     */
    public static saveRoomUserOut(
        requestBody: MetaRoomUserOutCIVO,
    ): CancelablePromise<MetaResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/meta/saveRoomUserOut',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 메타버스 룸 채팅 저장
     * @param requestBody 메타버스 룸 채팅 저장 VO
     * @returns MetaResultCOVO
     * @throws ApiError
     */
    public static saveRoomChat(
        requestBody: MetaRoomChatCIVO,
    ): CancelablePromise<MetaResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/meta/saveRoomChat',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 메타버스 룸 채팅 조회
     * @param roomId
     * @returns any[] 룸 채팅 정보
     * @throws ApiError
     */
    public static getRoomChat(
        roomId: string,
    ): CancelablePromise<any[]> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/meta/getRoomChat',
            query: {
                'roomId': roomId,
            },
        });
    }
    /**
     * 메타버스 룸 삭제 저장
     * @param requestBody 메타버스 룸 삭제 저장 VO
     * @returns MetaResultCOVO
     * @throws ApiError
     */
    public static saveDisposeRoom(
        requestBody: MetaDisposeRoomCIVO,
    ): CancelablePromise<MetaResultCOVO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/meta/saveDisposeRoom',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
