import BlockAcquaintanceFooterView from './views/BlockAcquaintanceFooterView';
import BlockAcquaintanceWrapView from './views/BlockAcquaintanceWrapView';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import {useBlockAcquaintanceService} from "@dating/ui/pages/settings/blockAcquaintance/useBlockAcquaintanceService";
import { useCommonAlertPopService } from "@dating/ui/components/commonAlertPop/service/useCommonAlertPopService";
import { useEffect } from "react";
import { useCommonConfirmPopService } from "@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService";

const BlockAcquaintanceContainer = () => {
    const {
        title,
        fnSaveBlockAcquaintance,
        statusSaveBlockPhoneNumberUser,
        dataSaveBlockPhoneNumberUser,
        blockedUserCount,
    } = useBlockAcquaintanceService();

    const {
        fnCommonAlertPopOn,
    } = useCommonAlertPopService();

    const {
        fnCommonConfirmPopOn,
    } = useCommonConfirmPopService();

    useEffect(() => {
        if (statusSaveBlockPhoneNumberUser === "success" && dataSaveBlockPhoneNumberUser) {
            fnCommonAlertPopOn(blockedUserCount + "명의 번호가 차단되었습니다.");
        }
    }, [statusSaveBlockPhoneNumberUser, dataSaveBlockPhoneNumberUser]);

    return (
        <div className="rightShow active">
            <ButtonHeaderView title={title} right={false}/>
            <BlockAcquaintanceWrapView/>
            <BlockAcquaintanceFooterView
                fnSaveBlockAcquaintance={fnSaveBlockAcquaintance}
                fnCommonConfirmPopOn={fnCommonConfirmPopOn}
                blockedUserCount={blockedUserCount}
            />
        </div>
    );
};
export default BlockAcquaintanceContainer;
