import Network from '@/services/Network';
import WebRTC from './WebRTC';
import { RoomType } from '../../../types/Rooms';

class PermissionManager {
	constructor(private webRTCInstance: WebRTC, private network: Network) {}

	checkPermissions() {
		// 퍼블릭 룸인 경우 WebRTC 사용을 완전히 막음
		if (this.network.currentRoomType === RoomType.PUBLIC) {
			return;
		}

		const cameraPermissionName = 'camera' as PermissionName;
		const microphonePermissionName = 'microphone' as PermissionName;

		navigator.permissions
			?.query({ name: cameraPermissionName })
			.then((cameraPermission) => {
				if (cameraPermission.state === 'granted') {
					return navigator.permissions?.query({
						name: microphonePermissionName,
					});
				} else {
					return;
				}
			})
			.then((microphonePermission) => {
				if (microphonePermission && microphonePermission.state === 'granted') {
					this.webRTCInstance.getUserMedia(false);
				} else {
					return;
				}
			})
			.catch((error) => {
				console.error('Permission error:', error.message);
			});
	}
}

export default PermissionManager;
