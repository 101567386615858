import { MetaUserCIVO } from '@/site/api/models/MetaUserCIVO';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import {
	removeAfterUnderscore,
	getOtherPlayerCharacter,
	getOtherPlayerCharacterFile,
} from '@/utils/avatarUtils';
import { useMetaUserAdapter } from '@dating/adapter/meta/useMetaUserAdapter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useEffect } from 'react';

export const useAvatarProfileService = () => {
	const {
		myMetaUser,
		myMetaUserOpen,
		metaUser,
		otherMetaUser,
		openOtherPlayerProfile,
		setMyMetaUserOpen,
		setOtherMetaUser,
		setMetaUser,
		setOpenOtherPlayerProfile,
	} = useMetaUserRepo();
	const { setUserProfileOpen } = useDatingRepo();
	const { saveMetaUser, statusGetMetaUser, dataGetMetaUser } =
		useMetaUserAdapter({
			myMetaUserOpen,
			otherMetaUser,
		});
	const otherPlayerAnim = metaUser.avatarName;
	const remove_idle = removeAfterUnderscore(otherPlayerAnim);
	const characterData = getOtherPlayerCharacter(remove_idle);
	const getCharacterFile = getOtherPlayerCharacterFile(characterData);

	useEffect(() => {
		if (statusGetMetaUser === 'success' && dataGetMetaUser) {
			setMetaUser(dataGetMetaUser);
		}
	}, [statusGetMetaUser, dataGetMetaUser, setMetaUser, myMetaUserOpen]);

	/*
	 * 메타버스 아바타 생성
	 */
	const handleCreateAvatar = async () => {
		const param: MetaUserCIVO = {
			avatarName: 'b1c1f1h1cc1fc1hc1',
			readyToConnect: false,
			mediaConnected: false,
		};
		try {
			const result = saveMetaUser(param);
		} catch (error) {
			console.error('캐릭터 생성 중 오류 발생:', error);
			throw error;
		}
	};

	const handleInitAvatarProfile = () => {
		setOtherMetaUser('');
		setOpenOtherPlayerProfile(false, null);
		setMetaUser({
			userUid: '',
			nickName: '',
			introduce: '',
			gender: '',
			profileUrl: '',
			avatarName: '',
			readyToConnect: false,
			mediaConnected: false,
		});
		setMyMetaUserOpen(false);
		setUserProfileOpen('');
	};

	return {
		myMetaUser,
		metaUser,
		getCharacterFile,
		openOtherPlayerProfile,
		handleInitAvatarProfile,
		setOpenOtherPlayerProfile,
		handleCreateAvatar,
		setMetaUser,
	};
};
