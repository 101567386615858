import {useState} from "react";
import {UserSettingCOVO} from "@/site/api";

export const useSettingsStateRepo = () => {
     //유저 세팅정보
    const [userSettings, setUserSettings] = useState<UserSettingCOVO | null>(null);

    return {
        userSettings,
        setUserSettings,
    };
};