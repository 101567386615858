import {Link} from "react-router-dom";

const BtnFilterView = () => {
    return (
        <div>
            <Link to="/metaverse/dating/settings/IdealSettingEdit">
                <button type="button" className="btnFilter"/>
            </Link>
        </div>
    );
};

export default BtnFilterView;
