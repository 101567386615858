import { formatTime } from '@/utils/util';
import MessageTimeView from './MessageTimeView';
import { Link } from 'react-router-dom';

const MessageDetailView = ({
	groupedMessagesDetail,
	srcPrefix,
	handleExitChat,
	myProfile,
	navigationRoom,
	isOtherUserLeft,
	messagesEndRef,
	setUserProfileOpen,
}) => {
	const organizeMessages = (messages) => {
		const sortedMessages = [
			...groupedMessagesDetail.my.flatMap((group) => group.messages),
			...groupedMessagesDetail.other.flatMap((group) => group.messages),
		].sort((a, b) => parseInt(a.createdAt) - parseInt(b.createdAt));

		// 메시지를 그룹화
		const groupedMessages: {
			sender: string | null;
			messages: (typeof groupedMessagesDetail.my)[0]['messages'];
			time: string | null;
		}[] = [];
		let currentGroup: {
			sender: string | null;
			messages: (typeof groupedMessagesDetail.my)[0]['messages'];
			time: string | null;
		} = {
			sender: null,
			messages: [] as (typeof groupedMessagesDetail.my)[0]['messages'],
			time: null,
		};

		sortedMessages.forEach((message, index) => {
			const messageTime = formatTime(message.createdAt);

			if (
				currentGroup?.sender === message.sender?.uuid &&
				currentGroup.time === messageTime
			) {
				// 같은 발신자의 연속된 메시지이고 같은 시간대면 현재 그룹에 추가
				currentGroup.messages.push(message);
			} else {
				// 새로운 그룹 시작
				if (currentGroup.messages.length > 0) {
					groupedMessages.push({ ...currentGroup });
				}
				currentGroup = {
					sender: message.sender?.uuid,
					messages: [message],
					time: messageTime,
				};
			}
		});

		// 마지막 그룹 추가
		if (currentGroup.messages.length > 0) {
			groupedMessages.push(currentGroup);
		}

		return groupedMessages;
	};

	const renderMessageGroup = (group, index, allGroups) => {
		const isMyMessage = group?.sender === myProfile?.userUid;

		return group.messages.map((message, msgIndex) => {
			const isFirstInGroup = msgIndex === 0;
			const isLastInGroup = msgIndex === group.messages.length - 1;

			return (
				<div key={`${message.messageId}-${msgIndex}`}>
					<div className={`chatBox ${isMyMessage ? 'chatRight' : 'chatLeft'}`}>
						{!isMyMessage && isFirstInGroup ? (
							<div className="proImg Sm">
								{isOtherUserLeft ? (
									<img
										src="/assets/img/common/profile_no.svg"
										alt="테스트이미지"
									/>
								) : (
									<Link
										to="/metaverse/dating/profile"
										onClick={() => {
											setUserProfileOpen(navigationRoom.otherUserInfo.uuid);
										}}
										state={{
											isOtherProfile:
												navigationRoom.otherUserInfo?.uuid !==
												myProfile.userUid,
										}}
									>
										<img
											src={srcPrefix + message.sender?.profileUrl}
											alt={message.sender?.name}
										/>
									</Link>
								)}
							</div>
						) : (
							<div className="proImg Sm" />
						)}
						<div className="chat">
							<div className={`bubbleBox ${isFirstInGroup ? 'tail' : ''}`}>
								<div className="bubble">
									<span>{message.content}</span>
								</div>
								{isLastInGroup && <MessageTimeView time={message.createdAt} />}
							</div>
						</div>
					</div>
				</div>
			);
		});
	};

	const organizedMessages = organizeMessages(groupedMessagesDetail);
	return (
		<div className="inner chatRoom">
			<div className="chatList">
				{organizedMessages.length === 0 && (
					<div className="chatStartBox">
						<p className="partnerStart">따듯한 인사로 대화를 시작해 보세요</p>
					</div>
				)}
				{organizedMessages.map((group, index, allGroups) =>
					renderMessageGroup(group, index, allGroups),
				)}

				{isOtherUserLeft && (
					<>
						<p className="partnerOut">
							{navigationRoom.otherUserInfo.name} 님이 대화를 나갔습니다
						</p>
						<button
							className="roomOutBtn"
							title="나가기"
							onClick={() => handleExitChat()}
						>
							나가기
						</button>
					</>
				)}
			</div>
			<div ref={messagesEndRef} style={{ height: '1px' }} />
		</div>
	);
};

export default MessageDetailView;
