import {Link} from 'react-router-dom';

interface props {
    todayType: boolean;
    changeHomeType: (type: boolean) => void;
}

const HomeHeaderView = ({
                            todayType,
                            changeHomeType,
                        }: props) => {

    return (
        <div className="conHeadWarp">
            <div className="tabList">
                <button type="button" className={`tabButton tab01 ${!todayType ? 'active' : ''}`}>
                    <Link to="/metaverse/dating/home/ideal"
                          onClick={() => {
                              changeHomeType(false);
                          }}>
                        이상형 찾기
                    </Link>
                </button>
                <button type="button" className={`tabButton tab02 ${todayType ? 'active' : ''}`}>
                    <Link to="/metaverse/dating/home/today"
                          onClick={() => {
                              changeHomeType(true);
                          }}>
                        TODAY
                    </Link>
                </button>
            </div>
        </div>
    );
};

export default HomeHeaderView;
