interface props {
    fnVerifyVerificationCode: () => void;
    occupancyFooterViewStyle: {
        notReturnNumber: {
            display: string;
        };
        checkBtn: {
            display: string;
        };
    };
    fnSendVerificationCode: () => void;
}

const PhoneVerificationFooterView = ({
                                         fnVerifyVerificationCode,
                                         occupancyFooterViewStyle,
                                         fnSendVerificationCode,
                                     }: props) => {
    return (
        <footer>
            <div
                className="f-btnWrap"
                style={occupancyFooterViewStyle.notReturnNumber}
            >
					<span
                        className="underline fz16 color-999 btn btnBig"
                        onClick={() => {
                            fnSendVerificationCode();
                        }}
                    >
						인증번호를 받지 못하셨나요?
					</span>
            </div>
            <div className="f-btnWrap" style={occupancyFooterViewStyle.checkBtn}>
                <button
                    type="button"
                    className="btn btnBig btnBlack"
                    onClick={() => {
                        fnVerifyVerificationCode();
                    }}
                >
                    <span>확인</span>
                </button>
            </div>
        </footer>
    );
};

export default PhoneVerificationFooterView;
