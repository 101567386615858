interface props {
    setOpenReport: (openReport: boolean) => void;
    setOpenBlock: (openBlock: boolean) => void;
    openMenuPop: boolean;
};

const BlockReportButtonView = ({
                                   setOpenReport,
                                   setOpenBlock,
                                   openMenuPop,
                               }: props) => {
    return (
        <div className={`${openMenuPop ? 'menuPop active' : 'menuPop'}`}>
            <button
                type="button"
                className="popOnBtn"
                onClick={() => {
                    setOpenReport(true);
                }}
            >
                신고하기
            </button>
            <a
                onClick={() => {
                    setOpenBlock(true);
                }}
            >
                차단하기
            </a>
        </div>
    );
};

export default BlockReportButtonView;
