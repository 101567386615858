import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  activeTab: 'top10' | 'recent' | 'ideal';
  tabOptions: {
    key: 'top10' | 'recent' | 'ideal';
    path: string;
    label: string;
  }[];
  setActiveTab: (tab: 'top10' | 'recent' | 'ideal') => void;
}

const IdealMatchFinderTabView = ({
  activeTab,
  tabOptions,
  setActiveTab,
}: Props) => {
  const tabListRef = useRef<HTMLDivElement>(null); // 스크롤 컨테이너 참조
  const navigate = useNavigate(); // navigate 훅 사용

  // 탭이 변경될 때마다 스크롤 위치를 업데이트
  useEffect(() => {
    if (tabListRef.current) {
      const activeButton = Array.from(tabListRef.current.children).find((child, index) => {
        const tabKey = tabOptions[index].key;
        return activeTab === tabKey;
      }) as HTMLElement;

      // 활성화된 탭 버튼으로 스크롤
      if (activeButton) {
        activeButton.scrollIntoView({
          behavior: 'smooth',
          inline: 'start', // 버튼이 왼쪽에 위치하도록 설정
        });
      }
    }
  }, [activeTab, tabOptions]);

  const handleTabClick = (tabKey: 'top10' | 'recent' | 'ideal', path: string) => {
    setActiveTab(tabKey); // 상태 업데이트
    navigate(path); // 페이지 이동
  };

  return (
    <div className="conHeadWarp overYHidden">
      <div className="tabList tabListMany" ref={tabListRef}>
        {tabOptions.map((tab, index) => (
          <button
            key={tab.key}
            type="button"
            className={`tabButton tab0${index + 1} ${activeTab === tab.key ? 'active' : ''}`}
            onClick={() => handleTabClick(tab.key, tab.path)} // 클릭 시 상태 변경 및 페이지 이동
          >
            {tab.label} {/* Link를 버튼 안에 포함시키지 않음 */}
          </button>
        ))}
      </div>
    </div>
  );
};

export default IdealMatchFinderTabView;