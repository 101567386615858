import AgreementTermsFooterView from "./views/AgreementTermsFooterView";
import AgreementTermsContentsView from "./views/AgreementTermsContentsView";
import {useAgreementTermsService} from "./service/useAgreementTermsService";
import React, {useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";

const AgreementTermsContainer = () => {
    const {
        allAgreement,
        checkedAgreements,
        btnDisabled,
        fnSaveUserTerms,
        handleAllCheckBox,
        checkboxHandler,
        termsList,
        statusSaveUserTerms,
        dataSaveUserTerms,
        setStep,
        resetRegisterInfo,
        registerSteps,
        setSelectedTerm,
    } = useAgreementTermsService();

    const navigate = useNavigate();
    useEffect(() => {
        if (statusSaveUserTerms === "success" && dataSaveUserTerms) {
            setStep(1);
            navigate(registerSteps[1].url);
        }
    }, [statusSaveUserTerms, dataSaveUserTerms]);

    return (
        <>
            <div className="wrap noFooter">
                <header className="h-fixed">
                    <div className="headerWrap">
                        <div className="h-left">
                            <Link to={"/metaverse/dating/register/phone"}
                                  onClick={() => {
                                      resetRegisterInfo();
                                  }}
                            >
                                <button
                                    className="btnBack"
                                    type="button"
                                />
                            </Link>
                        </div>
                        <div className="h-center"></div>
                    </div>
                </header>
                <AgreementTermsContentsView
                    allAgreement={allAgreement}
                    checkboxHandler={checkboxHandler}
                    checkedAgreements={checkedAgreements}
                    handleAllCheckBox={handleAllCheckBox}
                    termsList={termsList}
                    setSelectedTerm={setSelectedTerm}
                />
                <AgreementTermsFooterView
                    fnSaveUserTerms={fnSaveUserTerms}
                    btnDisabled={btnDisabled}
                />
            </div>
        </>
    );
};

export default AgreementTermsContainer;
