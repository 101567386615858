import {ReportUiCOVO} from "@/site/api";

interface props {
    setOpenReport: (openReport: boolean) => void;
    selectedOption: string,
    handleOptionSelect: (option: string) => void;
    commonReportCodes: Array<ReportUiCOVO>;
    reportValue: string;
    reportInputHandler: (value: string) => void;
    inputDisabled: boolean;
};

const ReportPopView = ({
                           setOpenReport,
                           selectedOption,
                           handleOptionSelect,
                           commonReportCodes,
                           reportValue,
                           reportInputHandler,
                           inputDisabled,
                       }: props) => {
    return (
        <div className="popWrap">
            <div className="popupInfo">
                <p className="fz20 bold-500 text-c padding-t8">신고</p>
                <ul className="popCheckList margin-b0">
                    {commonReportCodes != undefined && typeof commonReportCodes === "object" && commonReportCodes.length > 0 ?
                        commonReportCodes.map((item) => {
                            return (
                                <li key={item.code}>
                                    <span className="bold-500">{item.value}</span>
                                    <div>
                                        <input
                                            id={"checkbox" + item.code}
                                            className="checkbox"
                                            name="checkbox-group"
                                            type="checkbox"
                                            checked={selectedOption === item.code}
                                            value={item.code}
                                            onChange={() => {handleOptionSelect(item.code)}}
                                        />
                                        <label
                                            htmlFor={"checkbox" + item.code}
                                            className="checkbox-label"
                                        ></label>
                                    </div>
                                </li>
                            );
                        }) : null
                    }
                    <div className="input">
                        <input
                            type="text"
                            placeholder="사유를 입력해 주세요"
                            value={reportValue}
                            disabled={inputDisabled}
                            onChange={(e) => {
                                reportInputHandler(e.target.value);
                            }}
                        />
                    </div>
                </ul>
                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            setOpenReport(false);
                        }}
                    >
                        <span>취소</span>
                    </button>
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
                            setOpenReport(false);
                        }}
                    >
                        <span>확 인</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReportPopView;
