import ReactionButtonView from './views/ReactionButtonView';
import ActionButtonView from './views/ActionButtonView';
import { useLowerRightButtonService } from './service/useLowerRightButtonService';

const LowerRightButtonContainer = () => {
	const {
		showChat,
		closeButton,
		actionButtonActive,
		handleClick,
		handleHelloAction,
		handleDanceAction,
		handleReactionClick,
		setCloseButton,
	} = useLowerRightButtonService();
	return (
		<div
			className="rightEmt"
			style={{
				bottom: showChat ? '244px' : '136px',
			}}
		>
			<ReactionButtonView
				closeButton={closeButton}
				setCloseButton={setCloseButton}
				handleDanceAction={handleDanceAction}
				handleHelloAction={handleHelloAction}
				handleReactionClick={handleReactionClick}
			/>
			<ActionButtonView
				actionButtonActive={actionButtonActive}
				handleClick={handleClick}
			/>
		</div>
	);
};

export default LowerRightButtonContainer;
