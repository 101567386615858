import { create } from 'zustand';
import { IEmoji } from '../../../types/IMetaverseState';

interface ReactionButtonRepoState {
	reactionButtonActive: boolean;
	emojis: IEmoji[];
	resetReactionButtonState: () => void;
	setReactionButtonActive: (active: boolean) => void;
	setEmoji: (emoji: IEmoji) => void;
}

const initialState = {
	reactionButtonActive: false,
	emojis: [] as IEmoji[],
};

export const useReactionButtonRepo = create<ReactionButtonRepoState>((set) => ({
	...initialState,

	resetReactionButtonState: () => set(initialState),

	setReactionButtonActive: (active: boolean) =>
		set({ reactionButtonActive: active }),

	setEmoji: (emoji: IEmoji) =>
		set((state) => ({ emojis: [...state.emojis, emoji] })),
}));
