import BlockListView from './views/BlockListView';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import { useBlockListService } from './service/useBlockListService';

const BlockListContainer = () => {
	const {
		blockUsers,
		srcPrefix,
		fnSaveUnblockUser,
		title,
	} = useBlockListService()

	return (
		<div className="rightShow active">
			<ButtonHeaderView title={title} right={false} />
			<BlockListView
				blockUsers={blockUsers}
				srcPrefix={srcPrefix}
				fnSaveUnblockUser={fnSaveUnblockUser}
			/>
		</div>
	);
};

export default BlockListContainer;
