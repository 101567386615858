import { useMetaUserRepo } from '@/stores/useMetaUserRepo';

const ChatMessageView = (chatMessage) => {
	const { myMetaUser } = useMetaUserRepo();
	const isMine = chatMessage.chatMessage.uuid === myMetaUser.userUid;
	const isSystem = chatMessage.chatMessage.senderId === 'system';

	const getMessageColor = () => {
		if (isMine) return '#00FFA3';
		switch (chatMessage.chatMessage.gender) {
			case 'M':
				return '#6FC2FF';
			case 'F':
				return '#FF86C7';
			default:
				return '#fff'; // 기본 색상
		}
	};

	const messageColor = getMessageColor();
	const authorColor = { color: messageColor };

	return (
		<div data-chat-message="default">
			{chatMessage.chatMessage.author && (
				<span data-chat-message-name="true" style={authorColor}>
					{chatMessage.chatMessage.author}
				</span>
			)}
			{chatMessage.chatMessage.content && (
				<span
					style={{
						color: isSystem ? '#FFD700' : '#fff',
					}}
				>
					{chatMessage.chatMessage.content}
				</span>
			)}
		</div>
	);
};

export default ChatMessageView;
