interface props {
    titSet: { mainTit: string, subTit: string };
    index: number;
    scrollYIndex: number;
};
const LikeTitleView = ({
                           titSet,
                           index,
                           scrollYIndex,
                       }: props) => {
    return (
        <div
            className={`${
                index === scrollYIndex ? "activeTit" : ""
            } likeTit titleWrap padding-tb16`}
        >
            <h2 className="title02">{titSet.mainTit}</h2>
            <p className="titleSub01">{titSet.subTit}</p>
        </div>
    );
};

export default LikeTitleView;
