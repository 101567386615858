import { create } from 'zustand';

interface PaymentState {
	openPaymentPop: boolean;
	openPaymentPopUp: boolean;
	openMessageConfirm: boolean;
	openLikeConfirm: boolean;
	setOpenPaymentPop: (openPaymentPop: boolean) => void;
	setOpenPaymentPopUp: (openPaymentPopUp: boolean) => void;
	setOpenMessageConfirm: (openNoMsgConfirm: boolean) => void;
	setOpenLikeConfirm: (openLikeConfirm: boolean) => void;

	// 좋아요 보낼 유저 정보
	activeItem: {
		userUid: string;
		profileUrl: string;
		nickName: string;
	};
	setActiveItem: (activeItem: {
		userUid: string;
		profileUrl: string;
		nickName: string;
	}) => void;
	clearActiveItem: () => void;

	// 메세지 보낼 유저 userUid
	messageTargetUser: string;
	setMessageTargetUser: (messageTargetUser: string) => void;

	// 매칭성공 화면 및 개설할 메세지 방 정보
	matchedItem: {
		myProfileUrl: string;
		toProfileUrl: string;
		roomId: string;
		matchingYn: string;
	};
	setMatchedItem: (matchedItem: {
		myProfileUrl: string;
		toProfileUrl: string;
		roomId: string;
		matchingYn: string;
	}) => void;
	clearMatchedItem: () => void;

	// 매칭 성공 상태
	matchingSuccess: boolean;
	setMatchingSuccess: (matchingSuccess: boolean) => void;

	// 좋아요 화면명
	likedLocation: '' | 'ideal' | 'addIdeal' | 'today' | 'profile';
	setLikedLocation: (
		likedLocation: '' | 'ideal' | 'addIdeal' | 'today' | 'profile',
	) => void;
}

export const usePaymentRepo = create<PaymentState>((set) => ({
	openPaymentPop: false,
	openPaymentPopUp: false,
	openMessageConfirm: false,
	openLikeConfirm: false,
	openPaymentConfirm: false,
	setOpenPaymentPop: (openPaymentPop: boolean) => set({ openPaymentPop }),
	setOpenPaymentPopUp: (openPaymentPopUp: boolean) => set({ openPaymentPopUp }),
	setOpenMessageConfirm: (openMessageConfirm: boolean) =>
		set({ openMessageConfirm }),
	setOpenLikeConfirm: (openLikeConfirm: boolean) => set({ openLikeConfirm }),

	// 좋아요 보낼 유저 정보
	activeItem: {
		userUid: '',
		profileUrl: '',
		nickName: '',
	},
	setActiveItem: (activeItem: {
		userUid: string;
		profileUrl: string;
		nickName: string;
	}) => set({ activeItem }),
	clearActiveItem: () =>
		set({
			activeItem: {
				userUid: '',
				profileUrl: '',
				nickName: '',
			},
		}),

	// 메세지 보낼 유저 userUid
	messageTargetUser: '',
	setMessageTargetUser: (messageTargetUser: string) =>
		set({ messageTargetUser }),

	matchedItem: {
		myProfileUrl: '',
		toProfileUrl: '',
		roomId: '',
		matchingYn: '',
	},
	setMatchedItem: (matchedItem: {
		myProfileUrl: string;
		toProfileUrl: string;
		roomId: string;
		matchingYn: string;
	}) => set({ matchedItem }),
	clearMatchedItem: () =>
		set({
			matchedItem: {
				myProfileUrl: '',
				toProfileUrl: '',
				roomId: '',
				matchingYn: '',
			},
		}),

	// 매칭 성공 상태
	matchingSuccess: false,
	setMatchingSuccess: (matchingSuccess: boolean) => set({ matchingSuccess }),

	// 좋아요 화면명
	likedLocation: '',
	setLikedLocation: (likedLocation: '' | 'ideal' | 'addIdeal' | 'today' | 'profile') =>
		set({ likedLocation }),
}));