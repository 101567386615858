import {UserProfileCOVO} from '@/site/api';
import React from "react";
import Slider from "react-slick";

interface props {
    profile: UserProfileCOVO | undefined;
    srcPrefix: string;
    sliderSettings: {};
}

const ProfileBannerView = ({
                               profile,
                               srcPrefix,
                               sliderSettings,
                           }: props) => {
    return (
        <div className="profileBanner">
            <div className="profileSlide">
                <Slider {...sliderSettings}>
                    {
                        profile && profile.profileUrl.map((item, i) => {
                            return (
                                <img
                                    key={item + i}
                                    src={srcPrefix + item}
                                    alt={profile.nickName}
                                />
                            );
                        })
                    }
                </Slider>
            </div>
            <div className="shadowBox"></div>
        </div>
    );
};

export default ProfileBannerView;
