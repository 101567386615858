import {useState} from "react";

export const useFeedFormStateRepo = () => {
    // 해시태그 입력
    const [inputHashTag, setInputHashTag] = useState<string>("");

    // 뒤로가기
    const [prev, setPrev] = useState<boolean>(false);

    const [modifyPostId, setModifyPostId] = useState<string>("");

    return {
        inputHashTag,
        setInputHashTag,
        prev,
        setPrev,
        modifyPostId,
        setModifyPostId,
    };
};