export enum RoomType {
  LOBBY = "lobby",
  PUBLIC = "public",
  CUSTOM = "custom",
  DM = "dm",
}

export interface IRoomData {
  type: RoomType;
  name: string;
  password: string | null;
  autoDispose: boolean;
  headCount: number;
  roomTheme: {
    id: number;
    label: string;
    img: string;
    mapBackground: string;
    tileMap: string;
    wall: string;
    setUpPlayer: {
      x: number;
      y: number;
    };
    setBounds: number;
  };
  remainingTime?: number;
  players?: [];
  adapterMethods?: {};
}
