import { useRef } from 'react';

interface props {
    id: number;
    required: boolean;
    previewURLs: string[];
    updateFile: (index: number, file: File) => void;
    srcPrefix: string;
    removeImg: (id: number) => void;
}

const EditProfileImgAddBoxView = ({
                                      id,
                                      required,
                                      previewURLs,
                                      updateFile,
                                      srcPrefix,
                                      removeImg,
                                  }: props) => {
    const imgRef = useRef<HTMLInputElement>(null);

    return (
        <>
            {previewURLs[id] ? (
                <div
                    onClick={() => {
                        imgRef.current?.click();
                    }}
                >
                    <button
                        type="button"
                        className="deleteBtn"
                        onClick={(e) => {
                            removeImg(id);
                            e.stopPropagation();
                        }}/>
                    <label htmlFor={'img' + id}></label>
                    <img
                        src={previewURLs[id]}
                        alt="preview-img"
                        style={{width: '100%', height: '100%', objectFit: 'cover'}}
                    />
                    <input
                        type="file"
                        id={'img' + id}
                        ref={imgRef}
                        onChange={(e) => {
                            e.preventDefault();
                            const currentRef = imgRef?.current;
                            if (currentRef && currentRef.files) {
                                const file = currentRef.files[0];
                                updateFile(id, file);
                            }

                            e.target.value = '';
                        }}
                        style={{display: 'none'}}
                    />
                    {required ? <span className="fz12 color-white">필수</span> : null}
                </div>
            ) : (
                <div>
                    <label htmlFor={'img' + id}></label>
                    <input
                        type="file"
                        id={'img' + id}
                        ref={imgRef}
                        onChange={(e) => {
                            e.preventDefault();
                            const currentRef = imgRef?.current;
                            if (currentRef && currentRef.files) {
                                const file = currentRef.files[0];
                                updateFile(id, file);
                            }

                            e.target.value = '';
                        }}
                        style={{display: 'none'}}
                    />
                    {required ? <span className="fz12 color-white">필수</span> : null}
                </div>
            )}
        </>
    );
};

export default EditProfileImgAddBoxView;
