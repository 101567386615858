import { Route, Routes, useLocation } from 'react-router-dom';
import IdealTypeContainer from "@dating/ui/pages/home/idealType/IdealTypeContainer";
import TodayTypeContainer from "@dating/ui/pages/home/today/TodayTypeContainer";
const HomeRouter = () => {
	const location = useLocation();
	return (
		<Routes location={location}>
			<Route path="/ideal" element={<IdealTypeContainer />} />
			<Route path="/today" element={<TodayTypeContainer />} />
		</Routes>
	);
};

export default HomeRouter;
