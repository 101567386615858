import {useEffect, useRef} from 'react';

interface props {
    clickSearch: boolean;
    keyword: string;
    searchFeedOut: () => void;
    setKeyword: (value: string) => void;
}

const FeedSearchHeaderView = ({
                                  clickSearch,
                                  keyword,
                                  setKeyword,
                                  searchFeedOut,
                              }: props) => {

    const searchRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (clickSearch) {
            searchRef.current?.focus();
        }
    }, [clickSearch]);

    return (
        <>
            {clickSearch ? (
                /*FeedSearchResultView*/
                <div className="conHeadWarp searchPlus padding-0">
                    <div className="searchWrap">
                        <input
                            type="text"
                            placeholder="검색"
                            defaultValue={keyword}
                            onChange={(e) => {
                                setKeyword(e.target.value);
                            }}
                            ref={searchRef}
                            spellCheck="false"
                        />
                        <button type="button" className="searchBtn"/>
                    </div>
                    <button type="button" className="btnClose" onClick={searchFeedOut}/>
                </div>
            ) : (
                /*FeedListView*/
                <div className="conHeadWarp searchPlus padding-0">
                    <div className="searchWrap">
                        <input
                            type="text"
                            placeholder="검색"
                            onChange={(e) => {
                                setKeyword(e.target.value);
                            }}
                        />
                        <button type="button" className="searchBtn"/>
                    </div>
                </div>
            )}
        </>
    );
};
export default FeedSearchHeaderView;
