import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LoungeService } from '@/site/api/services/LoungeService';
import { CommentCIVO, DeleteCommentCIVO, PostIdCIVO, SearchPostCIVO } from '@/site/api';

export const useLoungeAdapter = () => {
	const queryClient = useQueryClient();

	/*
	 * getPosts
	 * 라운지 포스트 리스트 조회
	 */
	const getPosts = () => {
		return LoungeService.getPosts();
	};

	/*
	 * removePost
	 * 포스트 삭제
	 */
	const removePost = useMutation({
		mutationFn: (map: PostIdCIVO) => {
			return LoungeService.removePost(map);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['getMyPosts'] });
			queryClient.invalidateQueries({ queryKey: ['getPosts'] });
		},
	});

	/*
	 * savePost
	 * 신규 포스트 작성 및 저장
	 */
	const savePost = useMutation({
		mutationFn: (contents: {}) => {
			return LoungeService.savePost(contents);
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getPosts'] });
		},
	});

	/*
	 * saveComment
	 * 댓글 작성
	 */
	const saveComment = useMutation({
		mutationFn: (registComment: CommentCIVO) => {
			return LoungeService.saveComment(registComment);
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getPosts'] });
		},
	});

	/*
	 * deleteComment
	 * 댓글 삭제
	 */
	const deleteComment = (map: DeleteCommentCIVO) => {
		return LoungeService.deleteComment(map);
	};

	/*
	 * getComment
	 * 특정 포스트 댓글리스트 조회
	 */
	const getComment = async (postId: string) => {
		if (postId != "") {
			return LoungeService.getComment(postId);
		}
	};

	/*
	 * getMyPosts
	 * 내 프로필 화면에서 내가 작성한 포스트 조회
	 */
	const getMyPosts = () => {
		return LoungeService.getMyPosts();
	};

	/*
	 * getSearchPost
	 * 키워드로 포스트 조회
	 */
	const getSearchPost = (map: SearchPostCIVO) => {
		return LoungeService.getSearchPost(map);
	};

	/*
	 * getPostsByGender
	 * 게시글 성별 필터
	 */
	const getPostsByGender = (gender: string) => {
		return LoungeService.getFilteredPostsByGender(gender);
	}

	/*
	 * getPostDetail
	 * 게시물 상세조회 (1건)
	 */
	const getPostDetail = (postId: string) => {
		return LoungeService.getPostDetail(postId);
	}

	return {
		getPosts,
		//
		removePost: removePost.mutate,
		statusRemovePost: removePost.status,
		dataRemovePost: removePost.data,
		//
		savePost: savePost.mutate,
		statusSavePost: savePost.status,
		dataSavePost: savePost.data,
		//
		saveComment: saveComment.mutate,
		statusSaveComment: saveComment.status,
		dataSaveComment: saveComment.data,
		//
		getComment,
		getMyPosts,
		getSearchPost,
		getPostsByGender,
		getPostDetail,
		deleteComment,
	};
};
