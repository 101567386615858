import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { MetaUserAvatarCIVO } from '@/site/api/models/MetaUserAvatarCIVO';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import {
	parseAvatarString,
	avatarType,
	generateCharacterImages,
	make,
	randomAvatar,
	getCharacter,
} from '@/utils/avatarUtils';
import { useMetaUserAdapter } from '@dating/adapter/meta/useMetaUserAdapter';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCharacterService = () => {
	const navigate = useNavigate();
	const {
		title,
		myMetaUserOpen,
		myMetaUser,
		customizeAvatarOpen,
		otherMetaUser,
		modifyCostumeColor,
		modifyFaceColor,
		modifyHairColor,
		costumeColor,
		faceColor,
		hairColor,
		setCostumeColor,
		setFaceColor,
		setHairColor,
		setModifyCostumeColor,
		setModifyFaceColor,
		setModifyHairColor,
		setCustomizeAvatarOpen,
	} = useMetaUserRepo();
	const { saveMetaUserAvatar, statusMetaUserAvatar, getMetaUserAvatar } =
		useMetaUserAdapter({
			myMetaUserOpen,
			otherMetaUser,
		});
	const avatarName = myMetaUser.avatarName;
	const originalCharacter = parseAvatarString(avatarName);
	const [character, setCharacter] = useState<avatarType>(originalCharacter);
	const [activeTab, setActiveTab] = useState(0);
	const [showColorPicker, setShowColorPicker] = useState(false);

	useEffect(() => {
		const { colors } = getCharacter(make(character));
		if (customizeAvatarOpen) {
			setModifyCostumeColor(colors.costumeColor ?? costumeColor);
			setModifyFaceColor(colors.faceColor ?? faceColor);
			setModifyHairColor(colors.hairColor ?? hairColor);
		} else {
			setCostumeColor(colors.costumeColor ?? costumeColor);
			setFaceColor(colors.faceColor ?? faceColor);
			setHairColor(colors.hairColor ?? hairColor);
		}
	}, [make(character), customizeAvatarOpen]);

	const characterImages = useMemo(() => {
		return generateCharacterImages(
			make(character),
			modifyCostumeColor,
			modifyFaceColor,
			modifyHairColor,
		);
	}, [make(character), modifyCostumeColor, modifyFaceColor, modifyHairColor]);

	/*
	 * 메타버스 아바타 수정
	 */
	const handleModifyAvatar = () => {
		if (character === originalCharacter) {
			return;
		}
		const map: MetaUserAvatarCIVO = {
			avatarName: make(character),
		};
		saveMetaUserAvatar(map);
	};

	const onClickBack = () => {
		navigate(-1);
	};

	const handleTabClick = (index: number) => {
		setActiveTab(index);
	};
	const handleChangeAvatar = (type: keyof avatarType, idx: number) => {
		setCharacter((prevAvatar) => ({ ...prevAvatar, [type]: idx + 1 }));
	};
	const handleClick = (event) => {
		event.preventDefault();
		const randomCharacter = randomAvatar();
		setCharacter(randomCharacter);
		setModifyHairColor(`hairs${randomCharacter.hc}`);
		setModifyCostumeColor(`costumes${randomCharacter.cc}`);
		setModifyFaceColor(`faces${randomCharacter.fc}`);
	};
	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		reactEventEmitter.emit('react-my-player-change-avatar', make(character));
		handleModifyAvatar();
		setHairColor(modifyHairColor);
		setCostumeColor(modifyCostumeColor);
		setFaceColor(modifyFaceColor);
		setCustomizeAvatarOpen(false);
		onClickBack();
	};

	useEffect(() => {
		setShowColorPicker(activeTab === 0 || activeTab === 1 || activeTab === 2);
	}, [activeTab]);

	useEffect(() => {
		if (statusMetaUserAvatar === 'success' && getMetaUserAvatar) {
		}
	}, [statusMetaUserAvatar, getMetaUserAvatar]);

	return {
		title,
		characterImages,
		character,
		activeTab,
		showColorPicker,
		modifyHairColor,
		modifyCostumeColor,
		modifyFaceColor,
		setModifyHairColor,
		setModifyCostumeColor,
		setModifyFaceColor,
		onClickBack,
		handleTabClick,
		handleChangeAvatar,
		handleClick,
		handleSubmit,
	};
};
