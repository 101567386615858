import { Link } from 'react-router-dom';

interface props {
	fnCommonAlertPopOn: (message: string) => void;
}

const LeftMenuListView = ({
							  fnCommonAlertPopOn,
						  }: props) => {
	return (
		<div className="leftMenuList">
			<ul>
				<li className="noti">
					<Link to="/metaverse/dating/sideBar/notice">공지사항</Link>
				</li>
				{/*<li className="event">*/}
				{/*	<Link to="/metaverse/dating/sideBar/event">이벤트</Link>*/}
				{/*</li>*/}
				{/*<li className="mission">*/}
				{/*	<Link to="/metaverse/dating/sideBar/mission">미션</Link>*/}
				{/*</li>*/}
				{/*<li className="store">*/}
				{/*	<Link to="/metaverse/dating/sideBar/store">스토어</Link>*/}
				{/*</li>*/}
				{/*<li className="charge">*/}
				{/*	<a>무료 충전소</a>*/}
				{/*</li>*/}
				<li className="help"
					onClick={() => {
						fnCommonAlertPopOn('메타버스 도움말 \r\n준비중 입니다.');
					}}
				>
					<a>메타버스 도움말</a>
				</li>
			</ul>
		</div>
	);
};

export default LeftMenuListView;
