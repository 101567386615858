import AddProfileFooterView from './views/AddProfileFooterView';
import AddProfileInputBoxView from './views/AddProfileInputBoxView';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import AddProfileImgView from '@dating/ui/pages/profile/addProfile/views/AddProfileImgView';
import { useProfileAddService } from "@dating/ui/pages/profile/addProfile/service/useProfileAddService";
import { useImgCropService } from "@dating/ui/pages/imgCrop/service/useImgCropService";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProfileAddContainer = () => {
    const {
        title,
        previewURLs,
        btnActivate,
        fnSaveAddProfile,
        srcPrefix,
        removeImg,
        response,
        profileAddTitle,
        setProfileAddTitle,
        profileAddDetail,
        setProfileAddDetail,
        clearCropFiles,
    } = useProfileAddService();

    const {
        updateFile,
    } = useImgCropService();

    useEffect(() => {
        if (response) {
        }
    }, [response]);

    const navigate = useNavigate();

    return (
        <>
            <div className="rightShow active">
                {/*<ButtonHeaderView title={title} right={true}/>*/}
                <header>
                    <div className="headerWrap">
                        <div className="h-center">
                            <p className="title02">{title}</p>
                        </div>
                        <div className="h-right">
                            <button
                                type="button"
                                className="btnClose"
                                onClick={() => {
                                    navigate(-1);
                                    clearCropFiles();
                                }}
                            />
                        </div>
                    </div>
                </header>
                <div className="popConWrap padding-b0">
                    <div className="inner">
                        <div className="settingWrap">
                            <p className="bold-500 color-666 padding-b16">
                                매력을 어필하기 위해 증명할 수 있는 것을 사진으로 올려주세요.
                                검토 후 인증마크를 드립니다.
                            </p>
                            <AddProfileImgView
                                previewURLs={previewURLs}
                                updateFile={updateFile}
                                srcPrefix={srcPrefix}
                                removeImg={removeImg}
                            />
                            <AddProfileInputBoxView
                                profileAddTitle={profileAddTitle}
                                setProfileAddTitle={setProfileAddTitle}
                                profileAddDetail={profileAddDetail}
                                setProfileAddDetail={setProfileAddDetail}
                            />

                            <ul className="exTxtList">
                                <li>
                                    업로드한 이미지에 대한 명의 도용 및 법적 책임은 본인에게
                                    있습니다.
                                </li>
                            </ul>
                            <AddProfileFooterView
                                btnActivate={btnActivate}
                                fnSaveAddProfile={fnSaveAddProfile}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileAddContainer;
