import { useEffect, useRef } from "react";

interface props {
    step: number;
}

const StepProgressView = ({
                              step,
                          }: props) => {
	const progressBar = useRef<HTMLProgressElement>(null);

	useEffect(() => {
		setTimeout(function () {
			if (progressBar.current) {
				progressBar.current.value = step;
			}
		}, 200); // 0.2초 후에 실행
	}, [step]);

    return <progress id="stepProgress" ref={progressBar} value={step-1} max="9" className="active"/>;
};
export default StepProgressView;
