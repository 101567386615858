import {useEffect} from "react";
import {useBlockReportRepo} from "@dating/repository/dating/useBlockReportRepo";
import {useSettingAdapter} from "@dating/adapter/setting/useSettingAdapter";
import {useBlockReportStateRepo} from "@dating/ui/components/blockReport/useBlockReportStateRepo";

export const useBlockReportService = () => {
    const {
        openReport,
        setOpenReport,
        reportValue,
        setReportValue,
        commonReportCodes,
        setCommonReportCodes,
    } = useBlockReportStateRepo();

    const {
        openMenuPop,
        setOpenMenuPop,
        selectedOption,
        setSelectedOption,
        inputDisabled,
        setInputDisabled,
        openBlock,
        setOpenBlock,
        blockUserUid,
        setBlockUserUid,
    } = useBlockReportRepo();

    const {
        getCommonCode,
        saveBlockUser,
        statusSaveBlockUser,
        dataSaveBlockUser,
    } = useSettingAdapter();

    /////////////////////////////////////////////

    useEffect(() => {
        if (openReport) {
            getCommonCode().then((response) => {
                if (response.length > 0) {
                    setCommonReportCodes(response);
                }
            });
        }
    }, [openReport]);

    /*
     * menuDotClickHandler
     * menuDot 클릭 시 신고하기차단하기 모달 팝업 open/close
     */
    function menuDotClickHandler() {
        if (!openMenuPop) {
            setOpenMenuPop(true);
        } else {
            setOpenMenuPop(false);
        }
    }

    useEffect(() => {
        if (openMenuPop) {
            document.addEventListener("click", menuDotHandler);
        }
    }, [openMenuPop]);

    /*
     * menuDotHandler
     * 모달팝업 오픈 상태일 때 팝업 바깥쪽 클릭하면 팝업 닫기
     */
    function menuDotHandler() {
        setOpenMenuPop(false);
    }

    /*
     * handleOptionSelect
     * 신고 팝업에서 선택한 신고 내용 set
     */
    const handleOptionSelect = (option: string) => {
        setSelectedOption(option === selectedOption ? '' : option);
    };

    useEffect(() => {

    }, [reportValue, inputDisabled]);

    useEffect(() => {
        if (selectedOption === "000") {
            setInputDisabled(false);
        } else {
            setReportValue("");
            setInputDisabled(true);
        }
    }, [selectedOption]);

    /*
     * reportInputHandler
     * 신고하기 기타 사유 입력
     */
    const reportInputHandler = (value: string) => {
        if (selectedOption === "000") {
            setReportValue(value);
        } else {
            setReportValue("");
        }
    }

    useEffect(() => {
        if (!openMenuPop) {
            setSelectedOption("");
        }
    }, [openMenuPop]);

    /*
     * fnSaveBlockUser
     * 유저 차단하기
     */
    const fnSaveBlockUser = () => {
        const map = {
            blockUserUid: blockUserUid
        };
        saveBlockUser(map);
    };

    useEffect(() => {
        if (statusSaveBlockUser === "success" && dataSaveBlockUser) {
            setBlockUserUid("");
            setOpenBlock(false);
            setOpenMenuPop(false);
            alert("차단했습니다.");
        }
    }, [statusSaveBlockUser, dataSaveBlockUser]);

    return {
        setOpenMenuPop,
        openMenuPop,
        openReport,
        setOpenReport,
        openBlock,
        setOpenBlock,
        menuDotClickHandler,
        selectedOption,
        handleOptionSelect,
        reportValue,
        reportInputHandler,
        inputDisabled,
        commonReportCodes,
        setBlockUserUid,
        fnSaveBlockUser,
    };
};