import {create} from "zustand";

interface ReferralCodeState {
    referralCodeOpen: boolean;
    setReferralCodeOpen: (referralCodeOpen: boolean) => void;
}

export const useReferralCodeRepo = create<ReferralCodeState>(
    (set) => ({
        referralCodeOpen: false,
        setReferralCodeOpen: (referralCodeOpen: boolean) => set({referralCodeOpen}),
    }),
);