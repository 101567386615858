import { useEffect } from 'react';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { useMetaUserAdapter } from '@dating/adapter/meta/useMetaUserAdapter';
import { useFooterRepo } from '@dating/repository/dating/useFooterRepo';

export const useHeaderService = () => {
	const { myProfile, srcPrefix, setMyProfileOpen, setUserProfileOpen } =
		useDatingRepo();

	const { otherMetaUser, myMetaUserOpen, setMyMetaUser } = useMetaUserRepo();

	const { loungeOpen, likeOpen, homeOpen, metaOpen } = useFooterRepo();

	const { statusGetMyMetaUser, dataGetMyMetaUser } = useMetaUserAdapter({
		myMetaUserOpen,
		otherMetaUser,
	});

	useEffect(() => {
		if (statusGetMyMetaUser === 'success') {
			if (dataGetMyMetaUser) {
				setMyMetaUser(dataGetMyMetaUser);
			}
		}
	}, [statusGetMyMetaUser, dataGetMyMetaUser]);

	useEffect(() => {}, [homeOpen, loungeOpen, likeOpen, metaOpen]);

	return {
		myProfile,
		srcPrefix,
		setMyProfileOpen,
		setUserProfileOpen,
		loungeOpen,
	};
};
