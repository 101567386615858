import { useRegisterService } from '@dating/ui/pages/register/main/useRegisterService';
import RegisterMainView from '@dating/ui/pages/register/main/views/RegisterMainView';

const RegisterMainContainer = () => {
    // signUpSteps
    // 0. 이용약관 동의
    // 1. 성별 선택
    // 2. 닉네임
    // 3. 생년월일 등록
    // 4. 키선택
    // 5. 직업선택
    // 6. 사진등록
    // 7. 이상형선택
    // 8. 위치정보동의설정
    // 9. 추천인코드입력 (1차개발범위미포함)

    const {
        resetRegisterInfo,
    } = useRegisterService();

    return (
        <div className="wrap noFooter">
            <div className="mainBg text-c">
                <div className="mainLogo">
                    <img src="/assets/img/common/main-logo.svg" alt="HUSH metaverse" />
                </div>

                <RegisterMainView resetRegisterInfo={resetRegisterInfo} />

                <div className="text-c">
                    <img
                        src="/assets/img/common/logo-beaith.png"
                        width="80px"
                        alt="베리드 로고"
                        style={{
                            margin: '0 auto',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default RegisterMainContainer;
