import { Link, useNavigate } from 'react-router-dom';

interface props {
    btnDisabled: boolean;
    clickStartBtn: () => void;
}

const WaitingFooterView = ({
                               btnDisabled,
                               clickStartBtn,
                           }: props) => {
    const navigate = useNavigate();
    return (

        <div className="f-btnWrap noFixed">
            <Link
                to="/metaverse/dating/register/lifeStyle"
                className="btn btnBig margin-b10"
            >
                <span className={`underline fz16 bold-500 ${btnDisabled ? 'dn' : ''}`}>

                        기다리는 동안 매칭률 높이기
                </span>
            </Link>
            <button
                type="button"
                className="btn btnBig btnBlack"
                disabled={btnDisabled}
                onClick={() => {
                    clickStartBtn();
                }}
            >
                <span>시작하기</span>
            </button>
        </div>
    );
};

export default WaitingFooterView;