import {create} from "zustand";

interface HeaderState {
    // 프로필 설정
    settingOpen: boolean,
    setSettingOpen: (settingOpen: boolean) => void,
}

export const useHeaderRepo = create<HeaderState>(
    (set) => ({
        // 프로필 설정
        settingOpen: false,
        setSettingOpen: (settingOpen: boolean) => set({ settingOpen }),
    }),
);