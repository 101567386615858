interface props {
    setOpenFeedHidePop: (openFeedHidePop: boolean) => void;
    fnSaveBlockPost: () => void;
};

const FeedHidePopView = ({
                             setOpenFeedHidePop,
                             fnSaveBlockPost,
                         }: props) => {
    return (
        <div className="blockPopWrap">
            <div className="popupInfo">
                <p className="fz20 bold-500 text-c">
                    선택한 게시글을 숨기시겠어요?
                </p>
                <p className="fz16Fw500 color-999 text-c padding-t8">
                    숨긴 게시글은 더 이상 볼 수 없습니다.
                </p>
                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            setOpenFeedHidePop(false);
                        }}
                    >
                        <span>아니요</span>
                    </button>
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
                            fnSaveBlockPost();
                        }}
                    >
                        <span>숨기기</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FeedHidePopView;
