import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useLikeMatchingService } from '@dating/ui/pages/like/likeMatcing/useLikeMatchingService';
import { NavigationState } from '@/ui/components/dm/detail/service/useMessageDetailService';

const LikeMatchingContainer = () => {
	const {
		srcPrefix,
		matchedItem,
		fnOpenMessageRoom,
		setMatchingSuccess,
		createNavigationState,
	} = useLikeMatchingService();
	const navigate = useNavigate();

	return (
		<div className="successWrap">
			<div className="successBg text-c">
				<p className="succTxt">
					축하드립니다!
					<br />
					매칭에 성공 하셨습니다.
				</p>

				<div className="succPeople">
					<div className="peopleRight">
						<img
							src={srcPrefix + matchedItem.toProfileUrl}
							alt={matchedItem.toProfileUrl}
						/>
					</div>
					<div className="loader">
						<div className="dot"></div>
						<div className="dot"></div>
						<div className="dot"></div>
						<div className="dot"></div>
					</div>
					<div className="peopleLeft">
						<img
							src={srcPrefix + matchedItem.myProfileUrl}
							alt={matchedItem.myProfileUrl}
						/>
					</div>
				</div>

				<div className="succBtnWrap">
					<Link
						to={`/metaverse/messenger/message/${matchedItem.roomId}`}
						state={createNavigationState()}
					>
						<button type="button" className="successBtn01">
							<span>대화 열기</span>
						</button>
					</Link>
					<button
						type="button"
						className="successBtn02 margin-t16"
						onClick={() => {
							navigate(-1);
							setMatchingSuccess(false);
						}}
					>
						<span>나중에 하기</span>
					</button>
				</div>
			</div>
			<div className="flower-rotare">
				<span className="ic-flower flower01"></span>
				<span className="ic-flower flower02"></span>
				<span className="ic-flower flower03"></span>
			</div>
		</div>
	);
};

export default LikeMatchingContainer;
