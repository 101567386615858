import { create } from 'zustand';

interface KeyPressedRepoState {
	rKeyPressed: string;
	eKeyPressed: string;
	fKeyPressed: string;
	spaceKeyPressed: string;
	resetKeyPressedState: () => void;
	setRKeyPressed: (value: string) => void;
	setEKeyPressed: (value: string) => void;
	setFKeyPressed: (value: string) => void;
	setSpaceKeyPressed: (value: string) => void;
}

const initialState = {
	rKeyPressed: '',
	eKeyPressed: '',
	fKeyPressed: '',
	spaceKeyPressed: '',
};

export const useKeyPressedRepo = create<KeyPressedRepoState>((set) => ({
	...initialState,

	resetKeyPressedState: () => set(initialState),

	setRKeyPressed: (value: string) => set({ rKeyPressed: value }),

	setEKeyPressed: (value: string) => set({ eKeyPressed: value }),

	setFKeyPressed: (value: string) => set({ fKeyPressed: value }),

	setSpaceKeyPressed: (value: string) => set({ spaceKeyPressed: value }),
}));
