import StepProgressView from "@dating/ui/pages/register/common/stepProgress/views/StepProgressView";
import {useEffect, useRef} from "react";

interface props {
    inputHeight: string[];
    handleInputChange: (index: number, value: string) => void;
    handleInputKeyDown: (index: number, key: string) => void;
    step: number;
    activeIndex: number;
}

const HeightView = ({
                        inputHeight,
                        handleInputChange,
                        handleInputKeyDown,
                        step,
                        activeIndex,
                    }: props) => {

    const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(3).fill(null));
    useEffect(() => {
        inputRefs.current[activeIndex]?.focus();
    }, [activeIndex]);

    return (
        <>
            <div className="titleWrap">
                <StepProgressView step={step}/>
                <h2 className="title01">키 선택</h2>
                <p className="titleSub01">본인의 키를 입력해 주세요</p>
            </div>
            <div className="content">
                <div className="input certificationBox align-end">
                    {inputHeight.map((value, index) => (
                        <input
                            type="number"
                            pattern="\d*"
                            className="text-c padding-0"
                            key={index}
                            value={value}
                            onChange={(e) => {
                                const value = e.target.value;
                                handleInputChange(index, value);
                            }}
                            onKeyDown={(e) => {
                                const key = e.key;
                                handleInputKeyDown(index, key);
                            }}
                            ref={(ref) => (inputRefs.current[index] = ref)}
                        />
                    ))}
                    <span className="fz20 bold-500">CM</span>
                </div>
            </div>
        </>
    );
};

export default HeightView;
