import {create} from "zustand";

interface ImgCropState {
    // 크롭창 open, close
    showImgCrop: boolean;
    setShowImgCrop: (showImgCrop: boolean) => void;

    // 업로드 할 이미지 url (미리보기용)
    previewURLs: string[];
    setPreviewURLs: (previewURLs: string[]) => void;
    clearPreviewURLs: () => void;

    // 파일업로드한(focused) 이미지태그 index
    imgIndex: number;
    setImgIndex: (imgIndex: number) => void;

    // imgCropFile: 사용자가 업로드한 이미지파일. Cropper 컴포넌트에 props로 넘긴다. (크롭할 파일)
    imgCropFile: string;
    setImgCropFile: (imgCropFile: string) => void;

    // 크롭 결과 파일 (크롭한 파일)
    cropFiles: Blob[];
    setCropFiles: (cropFile: Blob[]) => void;
    clearCropFiles: () => void;
}

const useImgCropRepo = create<ImgCropState>(
    (set) => ({
        showImgCrop: false,
        setShowImgCrop: (showImgCrop: boolean) => set({showImgCrop}),

        previewURLs: [], //업로드 할 이미지 url (미리보기용)
        setPreviewURLs: (previewURLs: string[]) => set({previewURLs}),
        clearPreviewURLs: () => set({previewURLs: []}),

        imgIndex: 0,
        setImgIndex: (imgIndex: number) => set({imgIndex}),

        imgCropFile: "",
        setImgCropFile: (imgCropFile: string) => set({imgCropFile}),

        cropFiles: [],
        setCropFiles: (cropFiles: Blob[]) => set({cropFiles}),
        clearCropFiles: () => set({cropFiles: []}),
    }),
);

export default useImgCropRepo;