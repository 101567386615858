interface props {
    fnSaveBirthday : () => void
    btnActivate : boolean
}

const BirthdayFooterView = ({ fnSaveBirthday, btnActivate } : props) => {
  return (
    <footer>
      <div className="f-btnWrap">
        <button
          className="btn btnBig btnBlack"
          onClick={fnSaveBirthday}
          disabled={btnActivate}
        >
          <span>다음</span>
        </button>
      </div>
    </footer>
  );
};

export default BirthdayFooterView;
