import { formatMatchingTime, normalizeToTimestamp } from '@/utils/util';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useMessageRepo } from '@dating/repository/message/useMessageRepo';
import { useCallback, useEffect } from 'react';

export const useMessageListService = () => {
	const {
		showDirectMessageList,
		managerEmpty,
		directMessageList,
		unreadMessageCounts,
	} = useMessageRepo();

	const {
		myProfile,
		userProfile,
		srcPrefix,
		userProfileOpen,
		setUserProfile,
		setUserProfileOpen,
	} = useDatingRepo();

	const { getUserProfile } = useProfileAdapter();
	useEffect(() => {
		if (userProfileOpen != '') {
			getUserProfile(userProfileOpen).then((value) => {
				setUserProfile(value);
			});
		}
	}, []);

	interface Display {
		className: string;
		prefix: string;
	}

	interface User {
		uuid: string;
		name: string;
		profileUrl: string;
		location: string;
		age: string;
	}

	interface Message {
		messageId: string | number;
		content: string;
		createdAt: string;
		read: boolean;
		sender: User;
		receiver: User;
	}

	interface SortedMessage {
		billingStatus: number;
		myPaymentStatus: boolean;
		otherPaymentStatus: boolean;
		dmRoomType: string;
		roomId: string;
		messages: Message[];
		otherUserInfo: User;
		visibleTo: number;
		isUserLeft: boolean;
		isOtherUserLeft: boolean;
		unreadCount: number;
		display: Display;
		latestCreatedAt: number;
	}

	// 메시지 타입에 따른 표시 방식을 결정하는 함수
	const getMessageDisplay = useCallback(
		(message: any) => {
			const isUser1 = message.isUser1;
			const billingStatus = message.billingStatus;
			const messagesLength = message.messages.length;
			const dmRoomType = message.dmRoomType;
			const matchingTime = message.matchingTime;

			switch (dmRoomType) {
				case 'FIRST':
					if (
						(isUser1 && billingStatus === 2) ||
						(!isUser1 && billingStatus === 1)
					) {
						return {
							className: 'color-red',
							prefix: '상대방이 먼저 메시지를 보냈어요',
						};
					}
					break;

				case 'LIKE':
					// billingStatus가 0이고 메시지가 없는 경우
					if (billingStatus === 0 && messagesLength === 0) {
						return {
							className: 'color-red',
							prefix: `${matchingTime} 전 매칭되었어요`,
						};
					}

					// billingStatus가 3이고 메시지가 없는 경우
					if (billingStatus === 3 && messagesLength === 0) {
						return {
							className: 'color-red',
							prefix: '대화가 연결되었어요! 메시지를 보내주세요',
						};
					}

					// 내가 결제했고 메시지가 없는 경우
					if (billingStatus === 1 && isUser1 && messagesLength === 0) {
						return {
							className: 'color-red',
							prefix: '대화를 시작해보세요',
						};
					}

					// 상대방이 과금했고 메시지가 없는 경우
					if (
						((isUser1 && billingStatus === 2) ||
							(!isUser1 && billingStatus === 1)) &&
						messagesLength === 0
					) {
						return {
							className: 'color-red',
							prefix: `${matchingTime} 전 매칭되었어요`,
						};
					}

					// 상대방이 과금 후 메시지를 보냈을 때
					if (
						(isUser1 && billingStatus === 2 && messagesLength !== 0) ||
						(!isUser1 && billingStatus === 1 && messagesLength !== 0)
					) {
						return {
							className: 'color-red',
							prefix: '상대방이 먼저 메시지를 보냈어요',
						};
					}
					break;
			}

			// 기본 리턴값
			return {
				className: 'color-666',
				prefix: '',
			};
		},
		[directMessageList, myProfile.userUid, unreadMessageCounts],
	);
	const processAndSortMessages = useCallback(() => {
		const processed = directMessageList.reduce<SortedMessage[]>(
			(result, room) => {
				try {
					const isUser1 = room.user1Id === myProfile.userUid;

					if (room.visibleTo === -1) {
						return result;
					}

					if (
						(isUser1 && room.visibleTo === 1) ||
						(!isUser1 && room.visibleTo === 2)
					) {
						return result;
					}

					const otherUserInfo = isUser1 ? room.user2Info : room.user1Info;

					if (!otherUserInfo || !otherUserInfo.uuid) {
						return result;
					}

					const messagesArray = Array.isArray(room.messages)
						? room.messages
						: Array.from(room.messages.values());

					const sortedMessages = [...messagesArray].sort(
						(a, b) => parseInt(b.createdAt) - parseInt(a.createdAt),
					);

					if (messagesArray.length > 0) {
						const latestMessage = messagesArray[0];
						if (latestMessage?.sender && latestMessage?.receiver) {
							const messageOtherInfo =
								latestMessage.sender.uuid === otherUserInfo.uuid
									? latestMessage.sender
									: latestMessage.receiver.uuid === otherUserInfo.uuid
									? latestMessage.receiver
									: null;

							if (messageOtherInfo) {
								Object.assign(otherUserInfo, {
									name: otherUserInfo.name,
									profileUrl: otherUserInfo.profileUrl,
									location: otherUserInfo.location,
									age: otherUserInfo.age,
								});
							}
						}
					}

					const matchingTime = room.createdAt;
					const createdTime = normalizeToTimestamp(matchingTime);
					const formattedTime = formatMatchingTime(createdTime.toString());

					const display = getMessageDisplay({
						dmRoomType: room.dmRoomType,
						isUser1,
						messages: sortedMessages,
						billingStatus: room.billingStatus,
						matchingTime: formattedTime,
					});

					const processedRoom = {
						billingStatus: room.billingStatus,
						myPaymentStatus: isUser1
							? room.billingStatus === 1
							: room.billingStatus === 2,
						otherPaymentStatus: isUser1
							? room.billingStatus === 2
							: room.billingStatus === 1,
						dmRoomType: room.dmRoomType,
						roomId: room.roomId,
						messages: sortedMessages,
						latestCreatedAt:
							messagesArray.length > 0
								? parseInt(messagesArray[0].createdAt)
								: normalizeToTimestamp(room.createdAt), // 방 생성 시간 저장
						otherUserInfo,
						visibleTo: room.visibleTo,
						isUserLeft: isUser1 ? room.visibleTo === 1 : room.visibleTo === 2,
						isOtherUserLeft: isUser1
							? room.visibleTo === 2
							: room.visibleTo === 1,
						unreadCount: room.unreadCount,
						display,
						userInfo1: room.user1Info,
						userInfo2: room.user2Info,
					};

					result.push(processedRoom);
					return result;
				} catch (error) {
					console.error('Error processing room:', room.roomId, error);
					return result;
				}
			},
			[],
		);

		// 메시지가 있는 경우 마지막 메시지 시간, 없는 경우 방 생성 시간으로 정렬
		return processed.sort((a, b) => {
			const aTime =
				a.messages.length > 0
					? parseInt(a.messages[0].createdAt)
					: a.latestCreatedAt;
			const bTime =
				b.messages.length > 0
					? parseInt(b.messages[0].createdAt)
					: b.latestCreatedAt;
			return bTime - aTime; // 최신순 정렬
		});
	}, [
		directMessageList,
		myProfile.userUid,
		unreadMessageCounts,
		getMessageDisplay,
	]);

	const sortedMessages = processAndSortMessages();

	return {
		sortedMessages,
		showDirectMessageList,
		managerEmpty,
		myProfile,
		userProfile,
		srcPrefix,
		setUserProfileOpen,
	};
};
