import { getCharacter } from '@/utils/avatarUtils';
import Phaser, { Scene } from 'phaser';
import { animationFrame } from './AnimationFrame';

const cacheTexture: { [key: string]: boolean } = {};
const cacheTextureStart: { [key: string]: boolean } = {};

// 캐시된 이미지를 확인하고 이미 존재한다면 즉시 Promise를 반환
export const createCharacterAnims = async (
	scene: Scene,
	name: string,
): Promise<boolean> => {
	if (cacheTexture[name]) return true;

	// 이미지 로딩이 진행 중인 경우 대기
	while (cacheTextureStart[name]) {
		await new Promise((resolve) => setTimeout(resolve, 100));
	}

	// 이미지 로딩 시작을 표시
	cacheTextureStart[name] = true;

	// 캐릭터 이미지 정보
	const { parts, colors } = getCharacter(name);

	// 가져온 이미지가 없다면 로딩 실패로 처리
	if (parts.length === 0) return false;

	// Phaser 로더를 생성
	const load = new Phaser.Loader.LoaderPlugin(scene);

	// 이미지를 로드
	parts.forEach((elm) => {
		try {
			const file = new Phaser.Loader.File(load, { type: 'image', key: elm });

			// 이미지가 로드되지 않았다면 로드
			if (!load.keyExists(file)) {
				let fileName = '';
				let colorDirectory = '';
				let typeDirectory = '';

				// 이미지의 타입에 따라 디렉토리와 파일명을 설정
				const match = elm.match(/[a-zA-Z]+|[0-9]+(?:\.[0-9]+|)/g);
				if (match == null) return;

				switch (match[0]) {
					case 'body':
						typeDirectory = 'body';
						break;
					case 'costume':
						typeDirectory = 'costumes';
						colorDirectory = `${colors.costumeColor}`;
						break;
					case 'face':
						typeDirectory = 'faces';
						colorDirectory = `${colors.faceColor}`;
						break;
					case 'hair':
						typeDirectory = 'hairs';
						colorDirectory = `${colors.hairColor}`;
						break;
					case 'costumes':
					case 'faces':
					case 'hairs':
						colorDirectory = `${elm}`;
						return '';
					default:
						colorDirectory = '';
						break;
				}

				// 코스튬, 페이스, 헤어의 경우 컬러 디렉토리를 추가
				if (
					match[0] === 'costume' ||
					match[0] === 'face' ||
					match[0] === 'hair'
				) {
					fileName = `/assets/character/avatar/${typeDirectory}/${colorDirectory}/${elm}.png`;
				} else {
					fileName = `/assets/character/avatar/${typeDirectory}/${elm}.png`;
				}

				// 이미지를 로드
				load.image(elm, fileName);
			}
		} catch (e) {
			console.error(e);
		}
	});

	// 이미지 로드가 완료되면 Promise를 반환
	return new Promise((resolve) => {
		load.on('complete', async () => {
			// 텍스처 이미지를 가져와서 애니메이션 프레임을 설정
			const image = await getTextureImage(scene.textures, name, parts);
			const configs = animationFrame(scene.anims, name);
			configs.forEach((config) => scene.anims.create(config));

			// 조합된 이미지를 새로운 텍스처로 추가
			scene.textures.addImage(name, image);

			// 이미지 로딩이 완료되었음을 표시
			cacheTextureStart[name] = false;
			cacheTexture[name] = true;
			resolve(true);
		});

		// 로더를 시작
		load.start();
	});
};

// 캐시된 이미지를 저장하는 객체
const cache: { [key: string]: HTMLImageElement } = {};

// 텍스처 이미지를 가져오는 함수
export function getTextureImage(
	textures: Phaser.Textures.TextureManager,
	name: string,
	value: string[],
): Promise<HTMLImageElement> {
	// 이미지가 캐시되어 있다면 바로 Promise를 반환
	if (cache[name]) return Promise.resolve(cache[name]);

	// 캔버스를 생성하고 2D 컨텍스트에 접근
	const texture = textures.createCanvas(name + '_canvas', 384, 320);
	value.forEach((elm, index) => {
		// 특정 인덱스는 건너뛰기 (설정에 따라 예외적으로 처리됨)
		if (index === 4 || index === 5 || index === 6) {
			return;
		}
		const body01 = textures.get(elm).getSourceImage() as HTMLImageElement;
		texture?.draw(0, 0, body01);
	});

	// 이미지를 생성하고 텍스처에 추가
	const image = document.createElement('img') as HTMLImageElement;
	image.src = (texture?.getSourceImage() as HTMLCanvasElement).toDataURL();
	cache[name] = image;

	// Promise를 반환
	return new Promise((resolve, reject) => {
		image.onload = () => {
			if (!textures.exists(name)) {
				textures.addSpriteSheet(name, image, {
					frameWidth: 48,
					frameHeight: 64,
				});
			}
			resolve(image);
		};
	});
}
