import { useHomeHeaderService } from '@dating/ui/pages/home/useHomeHeaderService';
import { useHeaderService } from '@dating/ui/pages/header/useHeaderService';
import { useIdealTypeService } from '@dating/ui/pages/home/idealType/useIdealTypeService';
import HomeHeaderView from '@dating/ui/pages/home/HomeHeaderView';
import ProfileBoxView from '@dating/ui/pages/home/idealType/views/ProfileBoxView';
import Spinner from '@dating/ui/components/spinner/Spinner';
import DatingHeaderView from '@dating/ui/pages/header/DatingHeaderView';
import { usePaymentService } from '@dating/ui/components/payment/service/usePaymentService';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { HushPickConfirmPopView } from '@dating/ui/pages/hushPick/HushPickConfirmPopView';
import { useAppFooterService } from '@/ui/components/footer/service/useAppFooterService';
import { useEffect } from 'react';

const IdealTypeContainer = () => {
	const {
		isLoading,
		fadeIn,
		preferUsers,
		setUserProfileOpen,
		sliderSettings,
		idealPopOpen,
		setIdealPopOpen,
		fnAddPreferUsers,
		fnIdealPopOff,
		addedPreferUsers,
		isIdealLikeds,
		isAddIdealLikeds,
	} = useIdealTypeService();

	const { todayType, changeHomeType } = useHomeHeaderService();

	const { myProfile, srcPrefix, setMyProfileOpen } = useHeaderService();

	const {
		initFooterWrap,
	} = useAppFooterService();

	const {
		setOpenPaymentPop,
		fnSendLikePopOpen,
		setMessageTargetUser,
		setLikedLocation,
	} = usePaymentService();

	const navigate = useNavigate();
	useEffect(() => {
		//메인 진입 시 하단 메뉴 홈 선택 활성화
		initFooterWrap();
	}, []);

	return (
		<>
			<div className="wrap">
				<DatingHeaderView
					myProfile={myProfile}
					srcPrefix={srcPrefix}
					setMyProfileOpen={setMyProfileOpen}
				/>
				<div id="conWrap" className="metaPopWrap">
					<HomeHeaderView
						todayType={todayType}
						changeHomeType={changeHomeType}
					/>
					{isLoading ? (
						<Spinner />
					) : (
						<div
							className={`toDayWrap spinnerContent ${fadeIn ? 'fade-in' : ''}`}
						>
							<div className="tabConWrap inner">
								<div className="tabContent">
									<div
										className={
											!todayType ? 'tabCon tabCon01 active' : 'tabCon tabCon01'
										}
									>
										<div className="profileList">
											{/*이상형 설정에 따른 추천 리스트*/}
											{preferUsers.length > 0
												? preferUsers.map((profile, i) => {
													if (i < 3) {
														return (
															<ProfileBoxView
																key={profile.nickName + i}
																preferUser={profile}
																srcPrefix={srcPrefix}
																fnSendLikePopOpen={fnSendLikePopOpen}
																setUserProfileOpen={setUserProfileOpen}
																currentUserUid={myProfile.userUid}
																setOpenPaymentPop={setOpenPaymentPop}
																sliderSettings={sliderSettings}
																setMessageTargetUser={setMessageTargetUser}
																isLiked={isIdealLikeds[profile.matchingUserUid]}
																type={"ideal"}
																setLikedLocation={setLikedLocation}
															/>
														);
													}
												})
												: null}

											{/*HUSH pick*/}
											<div className="pickBanner"
												 onClick={() => {
													 navigate('/metaverse/dating/idealMatchFinder/top-10-picks');
												 }}
											>
												<img src="/assets/img/common/hush-pick-ic.svg" alt="PICK!" />
												<div className="pickBtnBox">
													<a href="#n">상위 10% 이성 추천 받기</a>
													<a href="#n">최근 접속 이성 추천 받기</a>
												</div>
											</div>

											{preferUsers.length > 0
												? preferUsers.map((profile, i) => {
													if (i >= 3) {
														return (
															<ProfileBoxView
																key={profile.nickName + i}
																preferUser={profile}
																srcPrefix={srcPrefix}
																fnSendLikePopOpen={fnSendLikePopOpen}
																setUserProfileOpen={setUserProfileOpen}
																currentUserUid={myProfile.userUid}
																setOpenPaymentPop={setOpenPaymentPop}
																sliderSettings={sliderSettings}
																setMessageTargetUser={setMessageTargetUser}
																isLiked={isIdealLikeds[profile.matchingUserUid]}
																type={"ideal"}
																setLikedLocation={setLikedLocation}
															/>
														);
													}
												})
												: null}

											{/*이상형 추천 과금 리스트*/}
											{addedPreferUsers.length > 0
												? addedPreferUsers.map((profile, i) => {
													return (
														<ProfileBoxView
															key={profile.nickName + i}
															preferUser={profile}
															srcPrefix={srcPrefix}
															fnSendLikePopOpen={fnSendLikePopOpen}
															setUserProfileOpen={setUserProfileOpen}
															currentUserUid={myProfile.userUid}
															setOpenPaymentPop={setOpenPaymentPop}
															sliderSettings={sliderSettings}
															setMessageTargetUser={setMessageTargetUser}
															isLiked={isAddIdealLikeds[profile.matchingUserUid]}
															type={"addIdeal"}
															setLikedLocation={setLikedLocation}
														/>
													);
												})
												: null}

											{/*더보기*/}
											<div className="padding-t8 padding-b16 text-c">
												<a
													className="color-999 underline bold-500"
													onClick={() => {
														// 과금 팝업 호출
														setIdealPopOpen(true);
													}}
												>
													더보기
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>

			<div
				className={`${
					idealPopOpen ? 'settingPopOn' : 'settingPop'
				}`}
			>
				<div className="settingDim" />
				<CSSTransition
					in={idealPopOpen}
					classNames="logoutWrap-transition"
					timeout={100}
				>
					<HushPickConfirmPopView
						popTitle={'이상형 더보기'}
						popDetail={'더 많은 이상형을 추천해 드려요'}
						fnCommonConfirmPopExecute={fnAddPreferUsers}
						fnHushPickPopOff={fnIdealPopOff}
					/>
				</CSSTransition>
			</div>
		</>

	);
};

export default IdealTypeContainer;
