export class PlayerDialogBubble {
	private playerDialogBubble?: Phaser.GameObjects.Container;
	private timeoutID?: number;

	constructor(
		private scene: Phaser.Scene,
		private playerContainer: Phaser.GameObjects.Container,
	) {
		if (!this.scene || !this.scene.add) {
			console.error('Invalid scene object in PlayerDialogBubble');
			return;
		}
		this.playerDialogBubble = this.scene.add.container(0, -45).setDepth(5000);
		this.playerContainer.add(this.playerDialogBubble);
	}

	updateDialogBubble(content: string) {
		if (!this.scene || !this.scene.add) {
			console.error('Invalid scene object in PlayerDialogBubble');
			return;
		}
		this.clearDialogBubble();

		const maxWidth = 300;
		const padding = 6;

		const innerText = this.scene.add
			.text(0, 0, content, {
				fontSize: '12px',
				color: '#000000',
				fontStyle: 'normal',
				fontFamily: 'NotoSansKR',
				wordWrap: { width: maxWidth - padding * 2, useAdvancedWrap: true },
				align: 'center',
			})
			.setOrigin(0.5)
			.setResolution(2);

		const bubbleWidth = Math.min(innerText.width + padding * 2, maxWidth);
		const bubbleHeight = innerText.height + padding * 2;
		const bubbleX = 0;
		const bubbleY = 0;

		const graphics = this.scene.add.graphics();
		graphics.fillStyle(0xfff5db, 1);
		graphics.lineStyle(1, 0xfff5db, 1);

		// Main bubble
		graphics.fillRoundedRect(
			bubbleX - bubbleWidth / 2,
			bubbleY,
			bubbleWidth,
			bubbleHeight,
			8,
		);

		// Tail
		graphics.fillTriangle(
			bubbleX - 5,
			bubbleY + bubbleHeight,
			bubbleX + 5,
			bubbleY + bubbleHeight,
			bubbleX,
			bubbleY + bubbleHeight + 5,
		);

		innerText.setPosition(bubbleX, bubbleY + bubbleHeight / 2);

		this.playerDialogBubble?.add(graphics);
		this.playerDialogBubble?.add(innerText);

		this.timeoutID = window.setTimeout(() => {
			this.clearDialogBubble();
		}, 6000);
	}

	private clearDialogBubble() {
		window.clearTimeout(this.timeoutID);
		this.playerDialogBubble?.removeAll(true);
	}
}
