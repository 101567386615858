import {
	CreateMessageRoomCIVO,
	MessageReadStatusCIVO,
	MessageService,
} from '@/site/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MessageRoomCIVO, MessageCIVO } from '@/site/api';
export const useMessageAdapter = () => {
	const queryClient = useQueryClient();

	/**
	 * createMessageRoomId
	 * 메시지 방 아이디 생성
	 */
	const createMessageRoomId = () => {
		return MessageService.createMessageRoomId();
	};

	/**
	 * 메시지 방 생성
	 * createMessageRoom
	 */
	const createMessageRoom = useMutation({
		mutationFn: (map: CreateMessageRoomCIVO) =>
			MessageService.createMessageRoom(map),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getMessageRoomList'] });
			return data;
		},
		onError: (error) => {
			console.error('Room creation error:', error);
		},
	});

	/**
	 * 메시지 방 여부 확인
	 * isMessageRoom
	 */
	const isMessageRoom = (toUserUid: string, userUid: string) => {
		return MessageService.isMessageRoom(toUserUid, userUid);
	};

	/**
	 * 메시지 방 정보 수정
	 * updateMessageRoom
	 */
	const updateMessageRoom = useMutation({
		mutationFn: (map: MessageRoomCIVO) => {
			return MessageService.updateMessgaeRoom(map);
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getMessageRoomList'] });
			return data;
		},
		onError: (error) => {
			console.error('updateMessageRoom 실패:', error); // 실패 확인
		},
	});

	/**
	 * 메시지 방 리스트 조회
	 * getMessageRoomList
	 */
	const getMessageRoomList = useQuery({
		queryKey: ['getMessageRoomList'],
		queryFn: () => MessageService.getMessageRoomList(),
	});

	/**
	 * 메시지 리스트 조회
	 * getMessageList
	 */
	const getMessageList = (roomId: string) => {
		return MessageService.getMessageList(roomId);
	};

	/**
	 * 메시지 내용 저장 (단건)
	 * saveMessage
	 */
	const saveMessage = useMutation({
		mutationFn: (map: MessageCIVO) => MessageService.saveMessage(map),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getMessageList'] });
			return data;
		},
	});

	/**
	 * 메시지 방 나가기
	 * leaveMessageRoom
	 */
	const leaveMessageRoom = (roomId: string) => {
		return MessageService.leaveMessageRoom(roomId);
	};

	/**
	 * 메시지 읽음 상태 수정
	 * updateMessageReadStatus
	 */

	const updateMessageReadStatus = useMutation({
		mutationFn: (map: MessageReadStatusCIVO) =>
			MessageService.updateMessageReadStatus(map),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getMessageList'] });
			return data;
		},
	});

	/**
	 * 메시지 방 정보 조회
	 * getMessageRoomInfo
	 */
	const getMessageRoomInfo = (roomId: string) => {
		return MessageService.getMessageRoomInfo(roomId);
	};

	return {
		createMessageRoomId,
		createMessageRoom: createMessageRoom.mutate,
		statusCreateMessageRoom: createMessageRoom.status,
		dataCreateMessageRoom: createMessageRoom.data,
		isMessageRoom,
		updateMessageRoom: updateMessageRoom.mutate,
		statusUpdateMessageRoom: updateMessageRoom.status,
		dataUpdateMessageRoom: updateMessageRoom.data,
		getMessageRoomList: getMessageRoomList.data,
		statusGetMessageRoomList: getMessageRoomList.status,
		dataGetMessageRoomList: getMessageRoomList.data,
		getMessageList,
		saveMessage: saveMessage.mutate,
		statusSaveMessage: saveMessage.status,
		dataSaveMessage: saveMessage.data,
		leaveMessageRoom: leaveMessageRoom,
		updateMessageReadStatus: updateMessageReadStatus.mutate,
		statusUpdateMessageReadStatus: updateMessageReadStatus.status,
		dataUpdateMessageReadStatus: updateMessageReadStatus.data,
		getMessageRoomInfo,
	};
};
