import SentLikeUserView from './views/SentLikeUserView';
import HighlySentRatedView from './views/HighlySentRatedView';
import {useLikeHeaderService} from "@dating/ui/pages/like/views/useLikeHeaderService";
import {useHeaderService} from "@dating/ui/pages/header/useHeaderService";
import React, { useRef } from "react";
import LikeHeaderView from "@dating/ui/pages/like/views/LikeHeaderView";
import {useLikeSendService} from "@dating/ui/pages/like/likeSend/useLikeSendService";
import DatingHeaderView from "@dating/ui/pages/header/DatingHeaderView";
import { usePaymentService } from "@dating/ui/components/payment/service/usePaymentService";
import LikedPostView from "@dating/ui/pages/like/likeReceive/views/LikedPostView";
import PostWithCommentView from "@dating/ui/pages/like/likeReceive/views/PostWithCommentView";

function LikeSendContainer() {
    const {
        myProfile,
        setMyProfileOpen,
    } = useHeaderService();

    const {
        isReceived,
        changeLikeType,
        handleScroll,
        scrollYIndex,
    } = useLikeHeaderService();

    const {
        sentLikeTitSets,
        noDataUserLike,
        fnGetSendUserLike,
        likeUsers,
        noDataHighlyRated,
        highlyRatedUsers,
        fnGetSendUserHighScore,
        srcPrefix,
        setUserProfileOpen,
        fnSaveUserLike,
        fnRemoveSendUserLike,
        fnRemoveSendHighlyRatedUsers,
        fnGetLikedPosts,
        noDataPost,
        likedPosts,
        fnGetSendPostComment,
        noDataComments,
        commentPosts,
        fadeIn,
        setSingleFeedId,
    } = useLikeSendService();

    const {
        setOpenPaymentPop,
        fnSendLikePopOpen,
        setMessageTargetUser,
    } = usePaymentService();

	const likeContainer = useRef<HTMLDivElement>(null);

    const a = () => {};

    return (
        <div className="wrap">
            <DatingHeaderView
                myProfile={myProfile}
                srcPrefix={srcPrefix}
                setMyProfileOpen={setMyProfileOpen}
            />
            <div id="conWrap" className="metaPopWrap">
                <LikeHeaderView
                    isReceived={isReceived}
                    changeLikeType={changeLikeType}
                />

                <div className="toDayWrap" ref={likeContainer} onScroll={() => {
					handleScroll(likeContainer.current?.scrollTop);
				}}>
                    <div className="inner">
                        <div className="tabContent">
                            <div className={`${isReceived ? '' : 'active'} tabCon tabCon02`}>
                                <div className={`likeList spinnerContent ${fadeIn ? 'fade-in' : ''}`}>
                                    {/*보낸 좋아요*/}
                                    <SentLikeUserView
                                        titSet={sentLikeTitSets[0]}
                                        index={1}
                                        scrollYIndex={scrollYIndex}
                                        setOpenPaymentPop={setOpenPaymentPop}
                                        noDataUserLike={noDataUserLike}
                                        fnGetSendUserLike={fnGetSendUserLike}
                                        likeUsers={likeUsers}
                                        srcPrefix={srcPrefix}
                                        setUserProfileOpen={setUserProfileOpen}
                                        fnRemoveSendUserLike={fnRemoveSendUserLike}
                                        setMessageTargetUser={setMessageTargetUser}
                                    />

                                    {/*높게 평가한 유저*/}
                                    <HighlySentRatedView
                                        titSet={sentLikeTitSets[1]}
                                        index={1}
                                        noDataHighlyRated={noDataHighlyRated}
                                        highlyRatedUsers={highlyRatedUsers}
                                        fnGetSendUserHighScore={fnGetSendUserHighScore}
                                        srcPrefix={srcPrefix}
                                        scrollYIndex={scrollYIndex}
                                        setUserProfileOpen={setUserProfileOpen}
                                        fnSaveUserLike={fnSaveUserLike}
                                        fnRemoveSendHighlyRatedUsers={fnRemoveSendHighlyRatedUsers}
                                        fnSendLikePopOpen={fnSendLikePopOpen}
                                    />

                                    {/*좋아요 보낸 게시글*/}
                                    <LikedPostView
                                        titSet={sentLikeTitSets[2]}
                                        index={2}
                                        scrollYIndex={scrollYIndex}
                                        noDataPost={noDataPost}
                                        fnGetReceivePostLike={fnGetLikedPosts}
                                        likePosts={likedPosts}
                                        srcPrefix={srcPrefix}
                                        setUserProfileOpen={setUserProfileOpen}
                                        currentUserUid={myProfile.userUid}
                                        setMyProfileOpen={setMyProfileOpen}
                                        setSingleFeedId={setSingleFeedId}
                                    />

                                    {/*댓글 단 게시글*/}
                                    <PostWithCommentView
                                        titSet={sentLikeTitSets[3]}
                                        index={3}
                                        scrollYIndex={scrollYIndex}
                                        srcPrefix={srcPrefix}
                                        noDataComment={noDataComments}
                                        commentPost={commentPosts}
                                        fnGetReceivePostComment={fnGetSendPostComment}
                                        setUserProfileOpen={setUserProfileOpen}
                                        currentUserUid={myProfile.userUid}
                                        setMyProfileOpen={setMyProfileOpen}
                                        setSingleFeedId={setSingleFeedId}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LikeSendContainer;
