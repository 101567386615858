import { create } from 'zustand';
import { MediaConnection } from 'peerjs';
import { sanitizeId } from '../utils/util';

export interface ComputerRepoState {
	computerDialogOpen: boolean;
	computerId: string | null;
	myStream: MediaStream | null;
	peerStreams: Map<string, { stream: MediaStream; call: MediaConnection }>;
	resetComputerState: () => void;
	openComputerDialog: (params: {
		computerId: string;
		myUserId: string;
	}) => void;
	closeComputerDialog: () => void;
	setMyStream: (stream: MediaStream | null) => void;
	addVideoStream: (params: {
		id: string;
		call: MediaConnection;
		stream: MediaStream;
	}) => void;
	removeVideoStream: (id: string) => void;
	setComputerDialogOpen: (open: boolean) => void;
}

export const useComputerRepo = create<ComputerRepoState>((set) => ({
	computerDialogOpen: false,
	computerId: null,
	myStream: null,
	peerStreams: new Map(),

	resetComputerState: () =>
		set({
			computerDialogOpen: false,
			computerId: null,
			myStream: null,
			peerStreams: new Map(),
		}),

	openComputerDialog: ({ computerId, myUserId }) =>
		set({
			computerDialogOpen: true,
			computerId,
		}),

	closeComputerDialog: () =>
		set({
			computerDialogOpen: false,
			myStream: null,
			computerId: null,
		}),

	setMyStream: (stream) => set({ myStream: stream }),

	addVideoStream: ({ id, call, stream }) =>
		set((state) => {
			const newPeerStreams = new Map(state.peerStreams);
			newPeerStreams.set(sanitizeId(id), { call, stream });
			return { peerStreams: newPeerStreams };
		}),

	removeVideoStream: (id) =>
		set((state) => {
			const newPeerStreams = new Map(state.peerStreams);
			newPeerStreams.delete(sanitizeId(id));
			return { peerStreams: newPeerStreams };
		}),

	setComputerDialogOpen: (open) => set({ computerDialogOpen: open }),
}));
