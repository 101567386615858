interface props {
    fnGetData: () => void;
    btnHandle: boolean;
};

const LikeMoreButtonView = ({
                                fnGetData,
                                btnHandle,
                            }: props) => {

    return (
        <div className="padding-t8 padding-b16 text-c">
            <a
                className="color-999 underline bold-500"
                style={{
                    "display": btnHandle? "block" : "none"
                }}
                onClick={() => {
                    fnGetData();
                }}>
                더보기
            </a>
        </div>
    );
};

export default LikeMoreButtonView;
