import { IChatMessage, IEmoji } from '../../../types/IMetaverseState';
import { IDirectMessage, IDMRoom } from '../../../types/IDirectMessageState';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { useRoomRepo } from '@/stores/useRoomRepo';
import { useChatRepo } from '@/stores/useChatRepo';
import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { useActionButtonRepo } from '@/stores/useActionButtonRepo';
import { useReactionButtonRepo } from '@/stores/useReactionButtonRepo';
import { useKeyPressedRepo } from '@/stores/useKeyPressedRepo';
import { useJoystickRepo } from '@/stores/useJoystickRepo';
import { useComputerRepo } from '@/stores/useComputerRepo';
import { useWhiteboardRepo } from '@/stores/useWhiteboardRepo';
import { useVideoRepo } from '@/stores/useVideoRepo';

export class StoreService {
	/* ---------- Init Room ---------- */
	resetState() {
		const { setRoomListOpen } = useRoomRepo.getState();
		const { clearChatMessages } = useChatRepo.getState();
		const { resetActionButtonActive } = useActionButtonRepo.getState();
		const { resetReactionButtonState } = useReactionButtonRepo.getState();
		const { resetKeyPressedState } = useKeyPressedRepo.getState();
		const { resetJoystickState } = useJoystickRepo.getState();
		const { resetComputerState } = useComputerRepo.getState();

		clearChatMessages();
		setRoomListOpen(false);
		resetActionButtonActive();
		resetReactionButtonState();
		resetKeyPressedState();
		resetJoystickState();
		resetComputerState();
	}

	/* ---------- Rooms ---------- */
	setAvailableRoomsData(rooms) {
		const { setAvailableRooms } = useRoomRepo.getState();
		setAvailableRooms(rooms);
	}
	setAddAvailableRoomData({ roomId, room }) {
		const { addAvailableRooms } = useRoomRepo.getState();
		addAvailableRooms({ roomId, room });
	}
	setRemoveAvailableRoomData(roomId: string) {
		const { removeAvailableRooms } = useRoomRepo.getState();
		removeAvailableRooms(roomId);
	}
	setRoomData(content: any) {
		const { setJoinedRoomData } = useRoomRepo.getState();
		setJoinedRoomData(content);
	}

	/* ---------- Chat ---------- */
	setChatMessage(item: IChatMessage) {
		const { pushChatMessage } = useChatRepo.getState();
		pushChatMessage(item);
	}

	/* ---------- DirectMessage ---------- */
	setDirectMessageRoomList(items: IDMRoom[]) {
		useDirectMessageRepo.getState().setDirectMessageRoomList(items);
	}

	addDirectMessageRoom(item: IDMRoom) {
		useDirectMessageRepo.getState().addDirectMessageRoom(item);
	}

	updateDirectMessageRoom(item: IDMRoom) {
		useDirectMessageRepo.getState().updateDirectMessageRoom(item);
	}

	removeDirectMessageRoom(roomId: string) {
		useDirectMessageRepo.getState().removeDirectMessageRoom(roomId);
	}

	setDirectMessage(item: IDirectMessage[]) {
		useDirectMessageRepo.getState().setDirectMessages(item);
	}
	setDirectMessageReceived(message: IDirectMessage) {
		useDirectMessageRepo.getState().addDirectMessage(message);
	}
	updateUnreadMessageCount(roomId: string, count: number): void {
		useDirectMessageRepo.getState().updateUnreadMessageCount(roomId, count);
	}
	setLeaveDirectMessage(item: any) {
		useDirectMessageRepo.getState().setLeaveDirectMessage(item);
	}
	setExitDirectMessage(item: any) {
		useDirectMessageRepo.getState().setExitDirectMessage(item);
	}

	/* ---------- Emoji ---------- */
	setEmojiMessage(item: IEmoji) {
		const { setEmoji } = useReactionButtonRepo.getState();
		setEmoji(item);
	}

	/* ---------- Whiteboard ---------- */
	setWhiteboard(key: string, roomId: string) {
		const { setWhiteboardUrls } = useWhiteboardRepo.getState();
		setWhiteboardUrls({
			whiteboardId: key,
			roomId: roomId,
		});
	}

	/* ---------- Player ---------- */
	clearAllPlayerNameMap() {
		const { clearPlayerNameMap } = useMetaUserRepo.getState();
		clearPlayerNameMap();
	}
	setOpenOtherPlayerProfile(item: any) {
		const { setOpenOtherPlayerProfile } = useMetaUserRepo.getState();
		setOpenOtherPlayerProfile(false, item);
	}
	setAudioStatus(status: boolean) {
		const { setAudioControl } = useMetaUserRepo.getState();
		setAudioControl(status);
	}
	playerLeft({ key }: { key: string }) {
		const { removePlayerNameMap } = useMetaUserRepo.getState();
		const { setOpenCamExpansion } = useVideoRepo.getState();
		removePlayerNameMap(key);
		setOpenCamExpansion(false, null);
	}
	playerJoined(
		key: string,
		uuid: string,
		name: string,
		anim: string,
		statusMessage: string,
		audioStatus: boolean,
		profileUrl: string,
	) {
		const { setPlayerNameMap } = useMetaUserRepo.getState();
		setPlayerNameMap({
			key,
			uuid,
			name,
			anim,
			statusMessage,
			audioStatus,
			profileUrl,
		});
	}
	playerUpdated(key: string, field: string, value: string | boolean | number) {
		const { setPlayerNameMapUpdate } = useMetaUserRepo.getState();
		setPlayerNameMapUpdate({ key, field, value });
	}
	setSessionId(sessionId: string) {
		const { setSessionId } = useMetaUserRepo.getState();
		setSessionId(sessionId);
	}
	setPlayerUUID(uuid: string) {
		const { setUUID } = useMetaUserRepo.getState();
		setUUID(uuid);
	}
	getPlayerUUID(): string {
		const { uuid } = useMetaUserRepo.getState();
		return uuid;
	}
}
