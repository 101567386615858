import Phaser from 'phaser';
import { ItemType } from '../types/Item';
import { Item } from './Item';
import { TextBox } from './TextBox';

export type ItemConfig = {
	scene: Phaser.Scene;
	x: number;
	y: number;
	texture: string;
	frame?: string | number;
};

// 아이템 추상 클래스
export default abstract class ItemAbst
	extends Phaser.Physics.Arcade.Sprite
	implements Item
{
	private dialogBox: TextBox;
	private statusBox: TextBox;

	constructor({ scene, x, y, texture, frame }: ItemConfig) {
		super(scene, x, y, texture, frame);

		// 대화 상자 및 상자 컨테이너 추가
		// 나중에 텍스트 추가 가능
		this.dialogBox = new TextBox(this);
		this.statusBox = new TextBox(this);
	}

	// 대화 상자 컨테이너에 텍스트 추가
	setDialogBox(
		content:
			| string
			| Phaser.GameObjects.Image
			| (string | Phaser.GameObjects.Image)[],
	) {
		this.dialogBox.setContent(content);
	}

	// 대화 상자 컨테이너 내용 지우기
	clearDialogBox() {
		this.dialogBox.clearText();
	}

	// 상태 상자 컨테이너에 텍스트 추가
	setStatusBox(text: string) {
		this.statusBox.setText(text);
	}
	// 상태 상자 컨테이너 내용 지우기
	clearStatusBox() {
		this.statusBox.clearText();
	}

	// 상호작용 시 대화 기능
	abstract onOverlapDialog();

	// 아이템 유형
	abstract readonly itemType: ItemType;
}
