import { IPlayer } from '../../../types/IMetaverseState';
import { ItemType } from '../types/Item';
import { MyEventEmitter } from './MyEventEmitter';

// 이벤트 타입 정의
export type PHASER_MY_PLAYER_TEXTURE_CHANGE = {
	x: number;
	y: number;
	texture: string;
};
export type PHASER_PLAYER_JOINED = { player: IPlayer; key: string };
export type PHASER_ITEM_USER_ADDED = {
	item: string;
	key: string;
	itemType: ItemType;
};
export type PHASER_ITEM_USER_REMOVED = PHASER_ITEM_USER_ADDED;
export type PHASER_UPDATE_DIALOG_BUBBLE = { playerId: string; content: string };
export type PHASER_PLAYER_UPDATED = {
	field: string;
	value: number | string | boolean;
	key: string;
};
export type PHASER_PLAYER_LEFT = string;
export type PHASER_PLAYER_DISCONNECTED = string;
export type PHASER_PLAYER_AUDIO_STATUS_CHANGE = boolean;
export type PHASER_MY_PLAYER_READY = void;
export type PHASER_SET_UP_PLAYER_UUID = void;
export type PHASER_MY_PLAYER_NAME_CHANGE = string;
export type PHASER_MY_PLAYER_VIDEO_CONNECTED = void;
export type PHASER_MY_PLAYER_ACTION = void;
export type PHASER_PLAYER_PROFILE_URL_CHANGE = string;
export type PHASER_PLAYER_STATUS_MESSAGE_CHANGE = string;
export type PHASER_UPDATE_EMOJI_BUBBLE = { playerId: string; emoji: string };

// 이벤트 타입에 대한 맵 정의
type EventMap = {
	['player-joined']: [args: PHASER_PLAYER_JOINED];
	['player-updated']: [args: PHASER_PLAYER_UPDATED];
	['player-left']: [args: PHASER_PLAYER_LEFT];
	['player-disconnected']: [args: PHASER_PLAYER_DISCONNECTED];
	['my-player-ready']: [args: PHASER_MY_PLAYER_READY];
	['set-up-player-uuid']: [args: PHASER_SET_UP_PLAYER_UUID];
	['my-player-name-change']: [args: PHASER_MY_PLAYER_NAME_CHANGE];
	['my-player-texture-change']: [args: PHASER_MY_PLAYER_TEXTURE_CHANGE];
	['my-player-video-connected']: [args: PHASER_MY_PLAYER_VIDEO_CONNECTED];
	['player-audio-status-change']: [args: PHASER_PLAYER_AUDIO_STATUS_CHANGE];
	['item-user-added']: [args: PHASER_ITEM_USER_ADDED];
	['item-user-removed']: [args: PHASER_ITEM_USER_REMOVED];
	['update-dialog-bubble']: [args: PHASER_UPDATE_DIALOG_BUBBLE];
	['my-player-action']: [args: PHASER_MY_PLAYER_ACTION];
	['player-profile-url-change']: [args: PHASER_PLAYER_PROFILE_URL_CHANGE];
	['my-player-status-message-change']: [
		args: PHASER_PLAYER_STATUS_MESSAGE_CHANGE,
	];
	['update-emoji-bubble']: [args: PHASER_UPDATE_EMOJI_BUBBLE];
};

export const phaserEventEmitter = new MyEventEmitter<EventMap>();
