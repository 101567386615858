import { useHomeAdapter } from '@dating/adapter/home/useHomeAdapter';
import { useEffect } from 'react';
import { useHomeRepo } from '@dating/repository/home/useHomeRepo';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useIdealTypeStateRepo } from '@dating/ui/pages/home/idealType/useIdealTypeStateRepo';
import { HomeMatchingCOVO } from '@/site/api';

export const useIdealTypeService = () => {

	const {
		isLoading,
		setIsLoading,
		fadeIn,
		setFadeIn,
	} = useIdealTypeStateRepo();

	const {
		todayType,
		setTodayType,
		preferUsers,
		setPreferUsers,
		idealPopOpen,
		setIdealPopOpen,
		addedPreferUsers,
		setAddedPreferUsers,
		isIdealLikeds,
		setIsIdealLikeds,
		isAddIdealLikeds,
		setIsAddIdealLikeds,
	} = useHomeRepo();

	const {
		setUserProfileOpen,
		sliderSettings,
	} = useDatingRepo();

	const {
		getPreferUser,
		saveScoreRandomUser,
		addRandomUser,
		deleteRandomUser,
	} = useHomeAdapter();
	////////////////////////////////////////////////

	/*
	 * fnGetPreferUsers
	 * 이상형 추천 리스트 조회
	 */
	const fnGetPreferUsers = () => {
		setIsLoading(true);
		setFadeIn(false);
		// const startTime = Date.now();
		getPreferUser().then((response) => {
			setIsLoading(false);
			setFadeIn(true);
			if (response.length > 0) {
				setPreferUsers(response);
			}

			// like버튼 상태 초기화
			let item = isIdealLikeds;
			response.map((user) => {
				item[user.matchingUserUid] = false;
			});
			setIsIdealLikeds(item);
		});
	};

	/*
     * fnAddPreferUsers
     * 이상형 추천 더보기(과금)
     */
	const fnAddPreferUsers = () => {
		getPreferUser().then(value => {
			let newItem: HomeMatchingCOVO[] = [];
			if (value.length > 4) {
				const list = value.slice(0, 5);
				newItem = [...addedPreferUsers, ...list];

				// like버튼 상태 초기화
				let item = isAddIdealLikeds;
				list.map((user) => {
					item[user.matchingUserUid] = false;
				});
				setIsAddIdealLikeds(item);
			} else {
				newItem = [...addedPreferUsers, ...value];
				// like버튼 상태 초기화
				let item = isAddIdealLikeds;
				value.map((user) => {
					item[user.matchingUserUid] = false;
				});
				setIsAddIdealLikeds(item);
			}
			setAddedPreferUsers(newItem);
		});
	};

	/*
     * fnIdealPopOff
     * 이상형 더보기 과금 팝업 닫기
     */
	const fnIdealPopOff = () => {
		setIdealPopOpen(false);
	};

	useEffect(() => {
		if (!todayType) {
			fnGetPreferUsers();
		}
	}, [todayType]);

	useEffect(() => {
		setTodayType(false);
	}, []);

	return {
		isLoading,
		fadeIn,
		saveScoreRandomUser,
		addRandomUser,
		deleteRandomUser,
		preferUsers,
		setUserProfileOpen,
		sliderSettings,
		idealPopOpen,
		setIdealPopOpen,
		fnAddPreferUsers,
		fnIdealPopOff,
		addedPreferUsers,
		isIdealLikeds,
		setIsIdealLikeds,
		isAddIdealLikeds,
		setIsAddIdealLikeds,
	};
};
