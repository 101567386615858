import { MyPlayerState, StateParams } from '../../MyPlayerState';

export class JumpState implements MyPlayerState {
	constructor(private param: StateParams) {}
	async update() {
		const { myPlayer, myKeyboard } = this.param;

		const { vx, vy } = myPlayer.myMove.velocity;

		if (
			// myKeyboard.downSpace ||
			myKeyboard.clickSpaceBar
		) {
			const currentDirection = this.getCurrentDirection();

			myPlayer.playWrab(
				`${myPlayer.playerTexture}_jump_${currentDirection}`,
				true,
			);

			// 플레이어의 세로 위치 변화에 따라 배경을 스크롤
			this.scrollBackground(myPlayer.y - 10); // 기존 플레이어 Y 좌표보다 10만큼 더 높게 설정

			await this.waitForJumpComplete();
			myPlayer.playWrab(
				`${myPlayer.playerTexture}_idle_${currentDirection}`,
				true,
			);

			this.scrollBackground(myPlayer.y);
			// myKeyboard.resetSpaceBar();
			myKeyboard.resetClickSpaceBar();
		} else {
			// 점프 중이 아니라면 기본적으로 idle 애니메이션 재생
			this.playIdleAnimation(vx, vy);
		}
	}

	private playIdleAnimation(vx: number, vy: number) {
		const { myPlayer } = this.param;

		// 방향에 따라 적절한 idle 애니메이션을 선택하여 재생
		if (vx > 0) {
			myPlayer.playWrab(`${myPlayer.playerTexture}_idle_right`, true);
		} else if (vx < 0) {
			myPlayer.playWrab(`${myPlayer.playerTexture}_idle_left`, true);
		} else if (vy > 0) {
			myPlayer.playWrab(`${myPlayer.playerTexture}_idle_down`, true);
		} else if (vy < 0) {
			myPlayer.playWrab(`${myPlayer.playerTexture}_idle_up`, true);
		}
	}

	// 현재 바라보는 방향을 반환하는 함수
	private getCurrentDirection(): string {
		const parts = this.param.myPlayer.anims.currentAnim?.key;
		const matchResult = parts!.match(/_([^_]+)$/);

		if (matchResult && matchResult.length > 1) {
			return matchResult[1];
		}

		return 'down'; // 기본값
	}

	private waitForJumpComplete(): Promise<void> {
		return new Promise((resolve) => {
			this.param.myPlayer.on('animationcomplete', function (animation) {
				if (animation.key.includes('jump')) {
					// Jump 애니메이션이 완료되면 resolve 호출
					resolve();
				}
			});
		});
	}

	private scrollBackground(distance: number) {
		// distance는 플레이어의 세로 위치 변화
		const scrollDuration = 100;

		// SetupCamera를 통해 가져온 카메라에 접근
		const camera = this.param.myPlayer.scene.cameras.main;

		const relativeDistance = distance;

		// 카메라의 Y 위치를 변경
		camera.pan(
			this.param.myPlayer.x,
			relativeDistance,
			scrollDuration,
			'Linear',
			true,
		);
	}
}
