import HighlySentRatedListView from './HighlySentRatedListView';
import LikeMoreButtonView from '../../views/LikeMoreButtonView';
import LikeTitleView from '../../views/LikeTitleView';
import {SendUserHighScoreCOVO} from "@/site/api";

interface props {
    titSet: { mainTit: string, subTit: string };
    index: number;
    noDataHighlyRated: boolean;
    highlyRatedUsers: SendUserHighScoreCOVO[];
    fnGetSendUserHighScore: () => void;
    srcPrefix: string;
    scrollYIndex: number;
    setUserProfileOpen: (userUid: string) => void;
    fnSaveUserLike: (userUid: string, state: boolean) => void;
    fnRemoveSendHighlyRatedUsers: (id: string, type: string) => void;
    fnSendLikePopOpen: (userUid: string, profileUrl: string, nickName: string) => void;
};

const HighlySentRatedView = ({
                                 titSet,
                                 index,
                                 noDataHighlyRated,
                                 highlyRatedUsers,
                                 fnGetSendUserHighScore,
                                 srcPrefix,
                                 scrollYIndex,
                                 setUserProfileOpen,
                                 fnSaveUserLike,
                                 fnRemoveSendHighlyRatedUsers,
                                 fnSendLikePopOpen,
                             }: props) => {
    return (
        <>
            <LikeTitleView
                titSet={titSet}
                scrollYIndex={scrollYIndex}
                index={index}
            />
            <HighlySentRatedListView
                highlyRatedUsers={highlyRatedUsers}
                srcPrefix={srcPrefix}
                setUserProfileOpen={setUserProfileOpen}
                fnSaveUserLike={fnSaveUserLike}
                fnRemoveSendHighlyRatedUsers={fnRemoveSendHighlyRatedUsers}
                fnSendLikePopOpen={fnSendLikePopOpen}
            />

            <LikeMoreButtonView
                fnGetData={fnGetSendUserHighScore}
                btnHandle={noDataHighlyRated}
            />
        </>
    );
};

export default HighlySentRatedView;
