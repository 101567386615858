interface props {
    fnSaveUserPrefer: () => void;
}

const IdealSettingEditFooterView = ({
                                        fnSaveUserPrefer
                                    }: props) => {

    return (
        <footer>
            <div className="f-btnWrap">
                <button
                    type="button"
                    className="btn btnBig btnBlack"
                    onClick={() => {
                        fnSaveUserPrefer();
                    }}
                >
                    <span>확 인</span>
                </button>
            </div>
        </footer>
    );
};

export default IdealSettingEditFooterView;
