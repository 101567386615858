import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { censor } from '@/utils/censor';
import { useRef, useEffect } from 'react';
import { useChatService } from '../service/useChatService';

const ChatFormView = () => {
	const ref = useRef(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const {
		focused,
		showChat,
		mobileChatInputClick,
		isInputFilled,
		chatInputValue,
		isInMetaverseRoot,
		handleSendChat,
		setShowChat,
		setMobileChatInputClick,
		setFocused,
		setIsInputFilled,
		setChatInputValue,
	} = useChatService();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChatInputValue(event.target.value);
	};

	const handleSubmit = (
		event:
			| React.FormEvent<HTMLFormElement>
			| React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();

		// 게임으로 포커스 이동
		inputRef.current?.blur();

		const val = censor(chatInputValue.trim());
		setChatInputValue('');
		if (val) {
			reactEventEmitter.emit('react-add-chat-message', val);
			handleSendChat(val);
			setMobileChatInputClick(false);
		}
	};

	useEffect(() => {
		setIsInputFilled(chatInputValue.trim().length > 0);
	}, [chatInputValue]);

	useEffect(() => {
		if (focused) {
			inputRef.current?.focus();
		} else {
			inputRef.current?.blur();
		}
	}, [focused]);

	const handleClickFirstInput = () => {
		inputRef.current?.focus();
	};

	return (
		<div
			className="chatInput input metaInput"
			style={{
				display: 'block',
			}}
		>
			<form onSubmit={handleSubmit} ref={ref}>
				<div className={`chatRightBox ${mobileChatInputClick ? 'active' : ''}`}>
					<div className="chat01">
						<button
							className={`chatWindowBtn ${showChat ? 'on' : ''}`}
							type="button"
							onClick={() => {
								setShowChat(!showChat);
							}}
						/>
						<input
							className="text-l"
							value="채팅을 입력해 주세요"
							type="text"
							readOnly
							onClick={() => {
								setMobileChatInputClick(true);
								handleClickFirstInput();
							}}
						/>
					</div>
					<div className="chat02">
						<button
							className="chatBackBtn"
							type="button"
							onClick={() => {
								setMobileChatInputClick(false);
							}}
						/>
						<input
							type="text"
							maxLength={192}
							ref={inputRef}
							value={chatInputValue}
							onChange={handleChange}
							onFocus={() => {
								if (!focused) {
									setFocused(true);
								}
							}}
							onBlur={() => {
								setFocused(true);
							}}
							enterKeyHint="send"
							placeholder="채팅을 입력해 주세요"
							disabled={!isInMetaverseRoot}
						/>
						<button
							className={`chatSendBtn ${
								isInputFilled && isInMetaverseRoot ? 'active' : ''
							}`}
							type="submit"
							onClick={() => {
								handleSubmit;
								setShowChat(true);
							}}
							disabled={!isInputFilled || !isInMetaverseRoot}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ChatFormView;
