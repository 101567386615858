import { useChatRepo } from '@/stores/useChatRepo';
import { useItemRepo } from '@/stores/useItemRepo';
import { useRoomRepo } from '@/stores/useRoomRepo';
import { useVideoRepo } from '@/stores/useVideoRepo';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import useImgCropRepo from '@dating/repository/imgCrop/useImgCropRepo';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import { useHeaderService } from '@dating/ui/pages/header/useHeaderService';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const usePhaserAppService = () => {
	const { joinedRoomData } = useRoomRepo();
	const isPublicRoom = joinedRoomData?.name.toLowerCase() === 'public';
	const { modal } = useItemRepo();
	const { showChat, focused, mobileChatInputClick, setFocused } = useChatRepo();
	const { openCamExpansion } = useVideoRepo();
	const isOpen = openCamExpansion.isOpen;
	const { myProfile, srcPrefix, loungeOpen } = useHeaderService();
	const { clickSearch } = useLoungeRepo();
	const { showImgCrop } = useImgCropRepo();
	const { setMyProfileOpen } = useDatingRepo();

	const navigate = useNavigate();
	useEffect(() => {
		if (loungeOpen) {
			if (clickSearch) {
				navigate('/metaverse/dating/lounge/search');
			} else {
				navigate('/metaverse/dating/lounge/list');
			}
		}
	}, [clickSearch]);

	useEffect(() => {
		if (showImgCrop) {
			navigate('/metaverse/dating/imgCrop');
		}
	}, [showImgCrop]);
	return {
		modal,
		isOpen,
		focused,
		showChat,
		myProfile,
		srcPrefix,
		isPublicRoom,
		mobileChatInputClick,
		setFocused,
		setMyProfileOpen,
	};
};
