import {FeedFormType} from "@dating/model/LoungeModel";

interface props {
    feedForm: FeedFormType;
    removeHashTag: (indexToRemove: number) => void;
    inputHashTag: string;
    changeHashTagInput: (value: string) => void;
    addHashTag: (code: string, value: string) => void;
}

const FeedFormTagView = ({
                             feedForm,
                             removeHashTag,
                             inputHashTag,
                             changeHashTagInput,
                             addHashTag,
                         }: props) => {

    return (
        <>
            <div
                className="input padding-t8"
                style={{display: "flex", flexWrap: "wrap"}}
            >
                {feedForm.tags?.map((tag, index) => (
                    <div key={tag + index} className="tagList">
                        <span>
                          {tag}
                            <button
                                type="button"
                                onClick={() => removeHashTag(index)}
                                style={{
                                    marginLeft: "2px",
                                    color: "black",
                                    border: "none",
                                    borderRadius: "2px",
                                    padding: "1.5px 3px 2px 2px",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    lineHeight: "1",
                                }}
                            >
                            {" "}
                                x{" "}
                          </button>
                        </span>
                    </div>
                ))}

                <input
                    type="text"
                    value={inputHashTag}
                    onChange={(e) => {
                        changeHashTagInput(e.target.value);
                    }}
                    onKeyUp={(e) => {
                        addHashTag(e.code, e.currentTarget.value);
                    }}
                    placeholder="#예시 #태그입력"
                />
            </div>
            <ul className="exTxtList">
                <li>
                    타인 비방 및 욕설 혹은 기준을 위반한 게시글을 작성 할 경우 제재될 수
                    있습니다.
                </li>
                <li>
                    업로드한 이미지에 대한 타인 이미지 도용 등 법적 책임은 본인에게 있습니다.
                </li>
            </ul>
        </>
    );
};

export default FeedFormTagView;
