import { Link } from 'react-router-dom';

const BtnMenuContainer = ({
	setMyMetaUserOpen,
}: {
	setMyMetaUserOpen: (myProfileOpen: boolean) => void;
}) => {
	return (
		<div className="h-left">
			<div className="h-metaMenuWrap">
				<Link
					to="/metaverse/dating/sideBar"
					onClick={() => {
						setMyMetaUserOpen(true);
					}}
				>
					<button className="btnMenu" />
				</Link>
			</div>
		</div>
	);
};

export default BtnMenuContainer;
