interface props {
    btnActivate: boolean;
    fnSaveAddProfile: () => void;
}

const AddProfileFooterView = ({
                                  btnActivate,
                                  fnSaveAddProfile,
                              }: props) => {
    return (
        <div className="f-btnWrap">
            <button
                type="button"
                className="btn btnBig btnBlack margin-t40"
                disabled={btnActivate ? false : true}
                onClick={fnSaveAddProfile}
            >
                <span>추가하기</span>
            </button>
        </div>
    );
};

export default AddProfileFooterView;
