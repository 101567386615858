import { Client } from 'colyseus.js';

export class CreateClient {
	private static instance: Client | null = null;

	static create() {
		if (!this.instance) {
			const endpoint = this.getEndPoint();
			this.instance = new Client(endpoint);
		}
		return this.instance;
	}

	// 인스턴스 재설정을 위한 메서드 추가
	static reset() {
		this.instance = null;
	}

	private static getEndPoint() {
		let hostname: string = location.hostname;
		let port: string = '7080';
		// let port: string = '30108';

		switch (import.meta.env.MODE) {
			case 'dev':
				hostname = 'dev-colyseus-metaverse.ibizsoftware.net';
				port = '443';
				break;
			case 'prd':
				hostname = 'colyseus.hush.berith.co';
				port = '443';
				break;
		}

		let protocol = location.protocol.replace('http', 'ws');
		if (import.meta.env.MODE !== 'local') {
			protocol = location.protocol.replace('https', 'wss');
		}
		const endpoint = `${protocol}//${hostname}:${port}`;

		console.log('meta.env.MODE', import.meta.env.MODE);
		console.log('endpoint', endpoint);

		return endpoint;
	}
}
