import { create } from 'zustand';
import { HomeMatchingCOVO } from '@/site/api';

interface HomeState {
	// 탭 클릭 (이상형추천 / Today)
	todayType: boolean;
	setTodayType: (todayType: boolean) => void;

	// 선호이상형 10명 (getPreferUser)
	preferUsers: HomeMatchingCOVO[];
	setPreferUsers: (preferUsers: HomeMatchingCOVO[]) => void;

	// 선호이상형 5명 추가 (getPreferUser)
	addedPreferUsers: HomeMatchingCOVO[];
	setAddedPreferUsers: (preferUsers: HomeMatchingCOVO[]) => void;

	// today (getRandomUser)
	randomUsers: HomeMatchingCOVO[];
	setRandomUsers: (randomUsers: HomeMatchingCOVO[]) => void;

	// 이상형찾기 - 더보기 버튼 클릭 시 과금 팝업 호출
	idealPopOpen: boolean;
	setIdealPopOpen: (idealPopOpen: boolean) => void;

	// 이상형찾기 likeBtn
	isIdealLikeds: {};
	setIsIdealLikeds: (isIdealLikeds: {}) => void;

	// 이상형찾기 더보기 likeBtn
	isAddIdealLikeds: {};
	setIsAddIdealLikeds: (isIdealLikeds: {}) => void;

	// TODAY likeBtn
	isTodayLikeds: {};
	setIsTodayLikeds: (isIdealLikeds: {}) => void;

	// profile likeBtn
	isProfileLiked: boolean;
	setIsProfileLiked: (isIdealLikeds: boolean) => void;
};

export const useHomeRepo = create<HomeState>(
	(set) => ({
		todayType: true,
		setTodayType: (todayType: boolean) => set({ todayType }),

		preferUsers: [],
		setPreferUsers: (preferUsers: HomeMatchingCOVO[]) => set({ preferUsers }),

		addedPreferUsers: [],
		setAddedPreferUsers: (addedPreferUsers: HomeMatchingCOVO[]) => set({ addedPreferUsers }),

		randomUsers: [],
		setRandomUsers: (randomUsers: HomeMatchingCOVO[]) => set({ randomUsers }),

		idealPopOpen: false,
		setIdealPopOpen: (idealPopOpen: boolean) => set({ idealPopOpen }),

		// 이상형찾기 likeBtn
		isIdealLikeds: {},
		setIsIdealLikeds: (isIdealLikeds: {}) => set({ isIdealLikeds }),

		// 이상형찾기 더보기 likeBtn
		isAddIdealLikeds: {},
		setIsAddIdealLikeds: (isAddIdealLikeds: {}) => set({ isAddIdealLikeds }),

		// TODAY likeBtn
		isTodayLikeds: {},
		setIsTodayLikeds: (isTodayLikeds: {}) => set({ isTodayLikeds }),

		// profile likeBtn
		isProfileLiked: false,
		setIsProfileLiked: (isProfileLiked: boolean) => set({isProfileLiked}),
	}),
);