import { Key } from 'react';
import RoomListItemView from './RoomListItemView';

const RoomListInfoView = ({
	myGender,
	roomItem,
	setRoomItem,
	roomListOpen,
	joinedRoomData,
	sortedRooms,
	handleJoinClick,
	handleClickMeta,
}) => {
	return (
		<div className="roomListInfo">
			<p className="commonTit">
				<span>미팅 방 목록</span>
			</p>
			<div className="roomList">
				{sortedRooms
					.filter(
						(room: { metadata: { name: string } }) =>
							room.metadata.name !== 'PUBLIC',
					)
					.filter(
						(room: { metadata: { name: string } }) =>
							room.metadata.name !== 'DM',
					)
					.map(
						(
							room: { roomId: any; locked: any; metadata: any },
							index: Key | null | undefined,
						) => {
							const { roomId, locked, metadata } = room;
							const {
								name,
								hasPassword,
								headCount,
								roomTheme,
								remainingTime,
								players,
							} = metadata;
							const safePlayersArray = Array.isArray(players) ? players : [];
							const isJoinedRoom = roomId === joinedRoomData?.id;
							return (
								<RoomListItemView
									roomKey={roomId}
									key={index}
									locked={locked}
									myGender={myGender}
									roomTitle={name}
									headCount={headCount}
									remainingTime={remainingTime}
									players={safePlayersArray}
									roomItem={roomItem}
									setRoomItem={setRoomItem}
									roomListOpen={roomListOpen}
									handleClickMeta={handleClickMeta}
									handleJoinClick={handleJoinClick}
									joinedRoomData={joinedRoomData}
									hasPassword={hasPassword}
									roomTheme={roomTheme}
									isJoinedRoom={isJoinedRoom}
								/>
							);
						},
					)}
			</div>
		</div>
	);
};

export default RoomListInfoView;
