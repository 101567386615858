const ActionButtonView = ({ actionButtonActive, handleClick }) => {
	return (
		<div className="emtBtmBox">
			<div
				className={`emtViewer  ${actionButtonActive ? 'active' : ''}`}
				onClick={handleClick}
			>
				<img src="/assets/img/common/ico-star_w.svg" alt="" />
			</div>
		</div>
	);
};

export default ActionButtonView;
