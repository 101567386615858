import {create} from "zustand";

interface BlockReportState {
    // 케밥메뉴 open/close
    openMenuPop: boolean;
    setOpenMenuPop: (openMenuPop) => void;

    // 선택한 신고내용
    selectedOption: string;
    setSelectedOption: (selectedOption: string) => void;

    // 신고 기타 입력 input
    inputDisabled: boolean;
    setInputDisabled: (inputDisabled: boolean) => void;

    // 차단한 지인 연락처 개수
    blockedUserCount: number;
    setBlockedUserCount: (blockedUserCount: number) => void;

    // 차단확인팝업 open/close
    openBlock: boolean;
    setOpenBlock: (openBlock: boolean) => void;

    // 차단할 userUid
    blockUserUid: string;
    setBlockUserUid: (blockUserUid: string) => void;
}

export const useBlockReportRepo = create<BlockReportState>(
    (set) => ({
        openMenuPop: false,
        setOpenMenuPop: (openMenuPop: boolean) => set({openMenuPop}),

        selectedOption: "",
        setSelectedOption: (selectedOption: string) => set({selectedOption}),

        inputDisabled: true,
        setInputDisabled: (inputDisabled: boolean) => set({inputDisabled}),

        blockedUserCount: 0,
        setBlockedUserCount: (blockedUserCount: number) => set({blockedUserCount}),

        openBlock: false,
        setOpenBlock: (openBlock: boolean) => set({openBlock}),

        blockUserUid: '',
        setBlockUserUid: (blockUserUid) => set({blockUserUid}),
    }),
);