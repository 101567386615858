import { HomePastMatchingUserCOVO } from "@/site/api";
import { create } from "zustand";

interface TabOption {
    key: 'top10' | 'recent' | 'ideal';
    path: string;
    label: string;
}

interface HushPickState {
    // 상단 탭
    activeTab: "top10" | "recent" | "ideal";
    setActiveTab: (activeTab: "top10" | "recent" | "ideal") => void;

    tabOptions: TabOption[];

    // 이성 추천 과금 팝업 타이틀 및 메세지
    hushPickPopMessage: {title: string, detail: string};
    setHushPickPopMessage: (hushPickPopMessage: {title: string, detail: string}) => void;
    clearHushPickPopMessage: () => void;

    // 이성 추천 과금 팝업 open/clolse
    hushPickPopOpen: boolean;
    setHushPickPopOpen: (hushPickPopOpen: boolean) => void;

    // 이성 추천 과금 팝업 확인 함수
    hushPickPopFunc: {param: any, func: (param?: any) => any | void};
    setHushPickPopFunc: (hushPickPopFunc: {param: any, func: () => any}) => void;
    initHushPickPopFunc: () => void;

    // 최근 추천 이성 (getPastPreferUser)
    pastPreferUsers: HomePastMatchingUserCOVO[];
    setPastPreferUsers: (pastPreferUsers: HomePastMatchingUserCOVO[]) => void;
};
export const useHushPickRepo = create<HushPickState>(
    (set) => ({
        activeTab: "top10",
        setActiveTab: (activeTab: "top10" | "recent" | "ideal") => set({activeTab}),

        tabOptions: [
            {
                key: 'top10',
                path: '/metaverse/dating/idealMatchFinder/top-10-picks',
                label: '상위 10% 추천',
            },
            {
                key: 'recent',
                path: '/metaverse/dating/idealMatchFinder/latest-picks',
                label: '최근 접속 추천',
            },
            {
                key: 'ideal',
                path: '/metaverse/dating/idealMatchFinder/perfect-picks',
                label: '이상형 추천',
            },
        ],

        hushPickPopMessage: {title: "", detail: ""},
        setHushPickPopMessage: (hushPickPopMessage: {title: string, detail: string}) => set({hushPickPopMessage}),
        clearHushPickPopMessage: () => set({hushPickPopMessage: {title: "", detail: ""}}),

        hushPickPopOpen: false,
        setHushPickPopOpen: (hushPickPopOpen: boolean) => set({hushPickPopOpen}),

        hushPickPopFunc: {param: null, func: (param: any) => null},
        setHushPickPopFunc: (hushPickPopFunc: {param: any, func: (param: any) => any}) => set({hushPickPopFunc}),
        initHushPickPopFunc: () => set({hushPickPopFunc: {param: null, func: (param: any) => null}}),
        
        pastPreferUsers: [],
        setPastPreferUsers: (pastPreferUsers: HomePastMatchingUserCOVO[]) => set({pastPreferUsers}),
    }),
);