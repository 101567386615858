import IdealRangeSettingView from '../../register/Ideal/views/IdealRangeSettingView';
import IdealSettingEditFooterView from './views/IdealSettingEditFooterView';
import IdealPopUpView from '../../register/Ideal/views/IdealPopUpView';
import IdealSettingEditHeaderView from './views/IdealSettingEditHeaderView';
import IdealCateListView from "@dating/ui/pages/register/Ideal/views/IdealCateListView";
import {useIdealSettingEditService} from "@dating/ui/pages/settings/idealSettingEdit/useIdealSettingEditService";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const IdealSettingEditContainer = () => {
    const {
        isPopupVisible,
        popupType,
        setPopupType,
        showPopup,
        hidePopup,
        formatAgeValue,
        ageRange,
        handleAgeChange,
        formatDistanceValue,
        nearRange,
        handleNearChange,
        formatHeightValue,
        heightRange,
        handleHeightChange,
        religion,
        handleReligionSelect,
        drinkingState,
        handleDrinkingStateSelect,
        smokingState,
        handleSmokingStateSelect,
        fnSaveUserPrefer,
        initAgeRange,
        initNearRange,
        initHeightRange,
        initReligion,
        initDrinkingState,
        initSmokingState,
        statusSaveUserPrefer,
        dataSaveUserPrefer,
        nearOverRange,
        ageOverRange,
        heightOverRange,
    } = useIdealSettingEditService();

    const navigate = useNavigate();
    useEffect(() => {
        if (statusSaveUserPrefer === "success" && dataSaveUserPrefer) {
            if (dataSaveUserPrefer.result) {
                navigate(-1);
            } else {
                alert(dataSaveUserPrefer.resultCode);
            }
        } else if (statusSaveUserPrefer === "error") {

        }
    }, [statusSaveUserPrefer, dataSaveUserPrefer]);

    return (
        <>
            <div className="wrap noFooter">
                <IdealSettingEditHeaderView/>
                <div className="contentsWrap">
                    <p className="color-999 fz16 margin-t16">
                        지나치게 한정적으로 설정시 원활한 추천이 되지 않을 수 있어요
                    </p>
                    <IdealRangeSettingView
                        formatAgeValue={formatAgeValue}
                        ageRange={ageRange}
                        handleAgeChange={handleAgeChange}
                        formatDistanceValue={formatDistanceValue}
                        nearRange={nearRange}
                        handleNearChange={handleNearChange}
                        formatHeightValue={formatHeightValue}
                        heightRange={heightRange}
                        handleHeightChange={handleHeightChange}
                        initAgeRange={initAgeRange}
                        initNearRange={initNearRange}
                        initHeightRange={initHeightRange}
                        nearOverRange={nearOverRange}
                        ageOverRange={ageOverRange}
                        heightOverRange={heightOverRange}
                    />
                    <IdealCateListView
                        showPopup={showPopup}
                        setPopupType={setPopupType}
                        religion={religion}
                        drinkingState={drinkingState}
                        smokingState={smokingState}
                    />
                </div>
                <IdealSettingEditFooterView
                    fnSaveUserPrefer={fnSaveUserPrefer}
                />
            </div>
            {
                isPopupVisible ? (
                    <IdealPopUpView
                        isPopupVisible={isPopupVisible}
                        hidePopup={hidePopup}
                        popupType={popupType}
                        religion={religion}
                        handleReligionSelect={handleReligionSelect}
                        drinkingState={drinkingState}
                        handleDrinkingStateSelect={handleDrinkingStateSelect}
                        smokingState={smokingState}
                        handleSmokingStateSelect={handleSmokingStateSelect}
                        initReligion={initReligion}
                        initDrinkingState={initDrinkingState}
                        initSmokingState={initSmokingState}
                    />
                ) : null
            }
        </>
    );
};

export default IdealSettingEditContainer;
