interface props {
    fnProfileUpload: () => void;
    btnActivate: boolean;
}

const photoFooterView = ({
                             fnProfileUpload,
                             btnActivate,
                         }: props) => {
    return (
        <footer>
            <div className="f-btnWrap">
                <button
                    type="button"
                    className="btn btnBig btnBlack"
                    onClick={fnProfileUpload}
                    disabled={btnActivate}
                >
                    <span>프로필 등록 완료</span>
                </button>
            </div>
        </footer>
    );
};

export default photoFooterView;