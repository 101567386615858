import { useState } from "react";

export const useWithdrawalService = () => {
    const [response, setResponse] = useState<boolean>(false);

    /*
     * fnDeactivateUser
     * 계정비활성화
     */
    const fnDeactivateUser = () => {
        setResponse(true);
    };

    return {
        fnDeactivateUser,
        response,
    };
};