import {HomePastMatchingUserCOVO, Top10PerUserCOVO} from "@/site/api";
import {useState} from "react";

export const useTop10PicksStateRepo = () => {
    // top10 유저 히스토리
    const [pastTop10Users, setPastTop10Users] = useState<HomePastMatchingUserCOVO[]>([]);

    // top10 유저 리스트
    const [top10Users, setTop10Users] = useState<Top10PerUserCOVO[]>([]);

    return {
        pastTop10Users,
        setPastTop10Users,
        top10Users,
        setTop10Users,
    };
};