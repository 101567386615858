import {useState} from "react";
import {PostInfoCOVO} from "@/site/api";

export const useSingleFeedStateRepo = () => {
    // 피드정보 (이미지, 내용 등)
    const [feedDetail, setFeedDetail] = useState<PostInfoCOVO>();

    // fnInitFeedEditHandler이 실행되었는지 여부
    const [isClickedFeedMenu, setIsClickedFeedMenu] = useState<{postId: string, state: boolean}>({postId: "", state: false});

    return {
        feedDetail,
        setFeedDetail,
        isClickedFeedMenu,
        setIsClickedFeedMenu,
    };
};