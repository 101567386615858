import {useNavigate} from "react-router-dom";

const LifeStyleHeaderView = () => {
    const navigate = useNavigate();
    return (
        <header>
        <div className="headerWrap border-b h-fixed">
          <div className="h-left">
            <button className="btnBack" onClick={() => navigate(-1)}></button>
          </div>
          <div className="h-center">
            <span className="fz18">나의 관심 &amp; 라이프 스타일</span>
          </div>
          <div className="h-right"></div>
        </div>
      </header>
    )
};

export default LifeStyleHeaderView;