import {useLifeStyleStateRepo} from "@dating/ui/pages/register/lifeStyle/useLifeStyleStateRepo";
import {useRegisterAdapter} from "@dating/adapter/register/useRegisterAdapter";
import type {UserStyleCIVO} from "@/site/api";
import {useEffect} from "react";
import {useSettingAdapter} from "@dating/adapter/setting/useSettingAdapter";
import {useRegisterRepo} from "@dating/repository/register/useRegisterRepo";

export default function useLifeStyleService() {
    const {
        lifeStyleValue,
        setLifeStyleValue,
        interestValue,
        setInterestValue,
        customVal,
        setCustomVal,
        drinkingValue,
        setDrinkingValue,
        smokingValue,
        setSmokingValue,
        religionValue,
        setReligionValue,
        customLifeStyleList,
        setCustomLifeStyleList,
        customLifeStyleValue,
        setCustomLifeStyleValue,
        lifeStyleList,
        setLifeStyleList,
        customInterestList,
        customInterestValue,
        setCustomInterestList,
        setCustomInterestValue,
        interestList,
        setInterestList,
        isPopOn,
        setIsPopOn,
        initReligion,
        setInitReligion,
        initDrinkingState,
        setInitDrinkingState,
        initSmokingState,
        setInitSmokingState,
    } = useLifeStyleStateRepo();

    const {
        userWaiting,
    } = useRegisterRepo();

    const {
        saveUserStyle,
        statusSaveUserStyle,
        dataSaveUserStyle,
    } = useRegisterAdapter();

    const {
        getStyles,
        getPrefer,
    } = useSettingAdapter();

    /////////////////////////////////////////////////////

    useEffect(() => {
        getStyles().then((response) => {
            if (response.interest.length > 0) {
                setInterestList(response.interest);
            }
            if (response.lifeStyle.length > 0) {
                setLifeStyleList(response.lifeStyle);
            }
        });

        getPrefer().then((response) => {
            setInitReligion(response.religion);
            setInitDrinkingState(response.drink);
            setInitSmokingState(response.smoke);
        });

        if (userWaiting.lifeStyle.length > 0) {
            const newItem = lifeStyleValue;
            userWaiting.lifeStyle.forEach((el) => {
                newItem[el] = true;
            });
            setLifeStyleValue(newItem);
        }
    }, []);

    /*
     * fnSaveUserStyle
     * 저장 (확인 버튼) - 나의 라이프스타일, 관심사 저장
     */
    const fnSaveUserStyle = () => {
        // 유저가 추가한 라이프 스타일
        const lifeTypeValue: string[] = []; // label(value) - ["독서", "1인가구"]
        for (const key in customLifeStyleValue) {
            if (customLifeStyleValue[key]) {
                lifeTypeValue.push(customLifeStyleList[key].styleValue);
            }
        }

        // 유저가 추가한 관심사
        const interestTypeValue: string[] = []; // label(value) - ["음악듣기", "여행"]
        for (const key in customInterestValue) {
            if (customInterestValue[key]) {
                interestTypeValue.push(customInterestList[key].styleValue);
            }
        }

        // 공통 라이프 스타일
        const commonLifeStyleCodes: string[] = [];
        for (const key in lifeStyleValue) {
            if (lifeStyleValue[key]) {
                commonLifeStyleCodes.push(key);
            }
        }

        // 공통 관심사
        const commonInterestCodes: string[] = [];
        for (const key in interestValue) {
            if (interestValue[key]) {
                commonInterestCodes.push(key);
            }
        }

        const map: UserStyleCIVO = {
            extraLifeStyleValue: lifeTypeValue,
            extraInterestValue: interestTypeValue,
            commonLifeStyleId: commonLifeStyleCodes,
            commonInterestId: commonInterestCodes,
            smokeId: smokingValue,
            drinkId: drinkingValue,
            religionId: religionValue,
        }

        saveUserStyle(map);
    };

    /*
     * popOnHandler
     * 나만의항목추가 팝업 show, hide 핸들러
     */
    const popOnHandler = (id: string) => {
        setIsPopOn({id: id, active: !isPopOn.active});
        setCustomVal("");
    };


    const interestHandler = (id: string) => {
        const newInterest = [...interestValue];
        if (interestValue[id]) {
            newInterest[id] = !newInterest[id];
        } else {
            newInterest[id] = true;
        }
        setInterestValue(newInterest);
    }


    const customInterestHandler = (id: string) => {
        const newInterest = [...customInterestValue];
        if (customInterestValue[id]) {
            newInterest[id] = !newInterest[id];
        } else {
            newInterest[id] = true;
        }
        setCustomInterestValue(newInterest);
    }


    const lifeStyleHandler = (id: string) => {
        const newLifeStyle = [...lifeStyleValue];
        if (lifeStyleValue[id]) {
            newLifeStyle[id] = !newLifeStyle[id];
        } else {
            newLifeStyle[id] = true;
        }
        setLifeStyleValue(newLifeStyle);
    }


    const customLifeStyleHandler = (id: string) => {
        const newCustomLifeStyle = [...customLifeStyleValue];
        if (customLifeStyleValue[id]) {
            newCustomLifeStyle[id] = !newCustomLifeStyle[id];
        } else {
            newCustomLifeStyle[id] = true;
        }
        setCustomLifeStyleValue(newCustomLifeStyle);
    };

    /*
     * popOnSaveHandler
     * 나만의 항목 추가 팝업 확인 버튼 클릭
     */
    const popOnSaveHandler = (value: string) => {
        if (value === "") {
            setIsPopOn({id: isPopOn.id, active: false});
            return;
        }
        if (value && value.length < 2) {
            setIsPopOn({id: isPopOn.id, active: false});
            return;
        }

        if (value && value.length > 1) {
            if (isPopOn.id === "interest") {
                const newInterest = [...customInterestList, {id: customInterestList.length.toString(), styleValue: value}];
                setCustomInterestList(newInterest);
                customInterestHandler(customInterestList.length.toString());
            } else {
                const newLifeStyle = [...customLifeStyleList, {id: customLifeStyleList.length.toString(), styleValue: value}];
                setCustomLifeStyleList(newLifeStyle);
                customLifeStyleHandler(customLifeStyleList.length.toString());
            }

            setIsPopOn({id: isPopOn.id, active: false});
            setCustomVal("");
        }
    }

    return {
        fnSaveUserStyle,
        isPopOn,
        setIsPopOn,
        popOnHandler,
        interestList,
        setInterestList,
        interestHandler,
        lifeStyleList,
        lifeStyleHandler,
        popOnSaveHandler,
        customInterestValue,
        customInterestList,
        customLifeStyleList,
        customLifeStyleValue,
        customInterestHandler,
        customLifeStyleHandler,
        customVal,
        setCustomVal,
        statusSaveUserStyle,
        dataSaveUserStyle,
        drinkingValue,
        smokingValue,
        religionValue,
        initReligion,
        initDrinkingState,
        initSmokingState,
        setSmokingValue,
        setReligionValue,
        setDrinkingValue,
        userWaiting,
        lifeStyleValue,
        interestValue,
    }
}