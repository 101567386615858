import { useEffect } from 'react';
import { useLikeAdapter } from '@dating/adapter/like/useLikeAdapter';
import { useLikeReceiveStateRepo } from '@dating/ui/pages/like/likeReceive/useLikeReceiveStateRepo';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useLikeRepo } from '@dating/repository/like/useLikeRepo';
import { type RemoveLikeViewCIVO, UserLikeCIVO } from '@/site/api';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import {usePaymentRepo} from '@dating/stores/PaymentStore';

export const useLikeReceiveService = () => {
	const limit = 3;

	const {
		noDataUserLike,
		setNoDataUserLike,
		likeUsers,
		setLikeUsers,
		likeUserPage,
		setLikeUserPage,
		noDataHighlyRated,
		setNoDataHighlyRated,
		highlyRatedUsers,
		setHighlyRatedUsers,
		highlyRatedPage,
		setHighlyRatedPage,
		noDataPost,
		setNoDataPost,
		likePosts,
		setLikePosts,
		likePostPage,
		setLikePostPage,
		noDataComment,
		setNoDataComment,
		commentPost,
		setCommentPost,
		commentPostPage,
		setCommentPostPage,
		fadeIn,
		setFadeIn,
	} = useLikeReceiveStateRepo();

	const { receivedLikeTitSets, setIsReceived } = useLikeRepo();

	const { setSingleFeedId } = useLoungeRepo();

	const { setUserProfileOpen } = useDatingRepo();

	const {
		getReceiveUserLike,
		getReceiveUserHighScore,
		getReceivePostLike,
		getReceivePostComment,
		saveUserLike,
		removeReceiveUserLike,
		removeReceiveHighlyRatedUsers,
	} = useLikeAdapter();

	const { setMessageTargetUser } = usePaymentRepo();
	////////////////////////////////////////////////

	/*
	 * fnGetReceiveUserLike
	 * 나에게 좋아요 보낸 유저 리스트 조회
	 */
	const fnGetReceiveUserLike = () => {
		const dateTime = new Date().getTime();
		getReceiveUserLike(likeUserPage, limit, dateTime).then((value) => {
			const newItem = likeUsers;
			const list = newItem.concat(value);

			if (value.length > 0) {
				setLikeUserPage(likeUserPage + 1);
				setNoDataUserLike(value[0].addButtonOnOff);
			}

			setLikeUsers(list);
		});
	};

	/*
	 * fnGetReceiveUserHighScore
	 * 나를 높게 평가한 유저 리스트 조회
	 */
	const fnGetReceiveUserHighScore = () => {
		const dateTime = new Date().getTime();
		getReceiveUserHighScore(highlyRatedPage, limit, dateTime).then((value) => {
			if (value.length > 0) {
				const newItem = highlyRatedUsers;
				const list = newItem.concat(value);
				setHighlyRatedUsers(list);
				setHighlyRatedPage(highlyRatedPage + 1);
				setNoDataHighlyRated(value[0].addButtonOnOff);
			}
		});
	};

	/*
	 * fnGetReceivePostLike
	 * 좋아요 받은 포스트 리스트 조회
	 */
	const fnGetReceivePostLike = () => {
		const dateTime = new Date().getTime();
		getReceivePostLike(likePostPage, limit, dateTime).then((value) => {
			if (value.length > 0) {
				const newItem = likePosts;
				const list = newItem.concat(value);
				setLikePosts(list);
				setLikePostPage(likePostPage + 1);
				setNoDataPost(value[0].addButtonOnOff);
			}
		});
	};

	/*
	 * fnGetReceivePostComment
	 * 댓글 달린 포스트 리스트 조회
	 */
	const fnGetReceivePostComment = () => {
		const dateTime = new Date().getTime();
		getReceivePostComment(commentPostPage, limit, dateTime).then((value) => {
			if (value.length > 0) {
				const newItem = commentPost;
				const list = newItem.concat(value);
				setCommentPost(list);
				setCommentPostPage(commentPostPage + 1);
				setNoDataComment(value[0].addButtonOnOff);
			}
		});
	};

	/*
	 * fnSaveUserLike
	 * 프로필 카드 좋아요 버튼 클릭
	 */
	const fnSaveUserLike = (userUid: string, state: boolean) => {
		const map: UserLikeCIVO = {
			likeUserUid: userUid,
			likeYn: state,
		};
		saveUserLike(map);
	};

	/*
     * fnRemoveReceiveUserLike
     * 보낸 좋아요 뷰 삭제
    9i */
	const fnRemoveReceiveUserLike = (id: string, type: string) => {
		const map: RemoveLikeViewCIVO = {
			likeViewId: id,
			removeType: type,
		};
		removeReceiveUserLike(map).then((value) => {
			if (value) {
				setLikeUsers([]);
				setLikeUserPage(1);
			}
		});
	};

	/*
	 * fnRemoveReceiveHighlyRatedUsers
	 * 높게 준 평가 뷰 삭제
	 */
	const fnRemoveReceiveHighlyRatedUsers = (id: string, type: string) => {
		const map: RemoveLikeViewCIVO = {
			likeViewId: id,
			removeType: type,
		};
		removeReceiveHighlyRatedUsers(map).then((value) => {
			if (value) {
				setHighlyRatedUsers([]);
				setHighlyRatedPage(1);
			}
		});
	};

	useEffect(() => {
		setIsReceived(true);
		fnGetReceiveUserLike();
		fnGetReceiveUserHighScore();
		fnGetReceivePostLike();
		fnGetReceivePostComment();
		setFadeIn(true);
	}, []);

	return {
		receivedLikeTitSets,
		noDataUserLike,
		fnGetReceiveUserLike,
		likeUsers,
		noDataHighlyRated,
		highlyRatedUsers,
		fnGetReceiveUserHighScore,
		noDataPost,
		fnGetReceivePostLike,
		likePosts,
		setUserProfileOpen,
		noDataComment,
		commentPost,
		fnGetReceivePostComment,
		fnSaveUserLike,
		fnRemoveReceiveUserLike,
		fnRemoveReceiveHighlyRatedUsers,
		fadeIn,
		setSingleFeedId,
		setMessageTargetUser,
	};
};
