import HeaderContainer from './components/header/containers/HeaderContainer';
import JoyStickContainer from './components/joystick/JoyStickContainer';
import PaymentContainer from '@dating/ui/components/payment/PaymentContainer';
import LowerRightButtonContainer from './components/lowerRightButton/LowerRightButtonContainer';
import CamWrapContainer from './components/videoUI/CamWrapContainer';
import AvatarProfileContainer from './components/avatarProfile/AvatarProfileContainer';
import CamExpansionView from './components/videoUI/views/CamExpansionView';
import RoomListContainer from './components/room/roomList/RoomListContainer';
import ChatContainer from './components/chat/ChatContainer';
import ChatFormView from './components/chat/views/ChatFormView';
import { usePhaserAppService } from './service/usePhaserAppService';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';
import { CSSTransition } from 'react-transition-group';
import { CommonAlertPopView } from '@dating/ui/components/commonAlertPop/views/CommonAlertPopView';
import { useCommonConfirmPopService } from '@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService';
import { CommonConfirmPopView } from '@dating/ui/components/commonConfirmPop/views/CommonConfirmPopView';
import { useBlockReportService } from '@dating/ui/components/blockReport/service/useBlockReportService';
import BlockPopView from '@dating/ui/components/blockReport/views/BlockPopView';

export function PhaserApp() {
	const {
		isOpen,
		focused,
		showChat,
		myProfile,
		srcPrefix,
		isPublicRoom,
		mobileChatInputClick,
		setFocused,
		setMyProfileOpen,
	} = usePhaserAppService();

	const { alertPopMessage, alertPopOpen, fnCommonAlertPopOff } =
		useCommonAlertPopService();

	const {
		confirmPopMessage,
		confirmPopOpen,
		fnCommonConfirmPopExecute,
		fnCommonConfirmPopOff,
	} = useCommonConfirmPopService();

	const { openBlock, setOpenBlock, fnSaveBlockUser } = useBlockReportService();

	return (
		<>
			<div className="IbizPlayGameInstance" id="phaser-container" />
			{getComponent(showChat)}
			<div className="PlayerResizer_container">
				<div
					className="Layout_layout"
					onClick={(event) => {
						const target = event.target as HTMLElement;
						const targetTagName = target.tagName.toLowerCase();
						// // 클릭된 요소가 input인 경우 아무것도 하지 않음
						if ('input' === targetTagName) return;

						if (showChat && focused) setFocused(false);
						else if (showChat && !focused) setFocused(false);
					}}
				>
					<main
						style={{
							flex: '1 1 auto',
						}}
					>
						<HeaderContainer
							myProfile={myProfile}
							srcPrefix={srcPrefix}
							isPublicRoom={isPublicRoom}
							setMyProfileOpen={setMyProfileOpen}
						/>

						{!isPublicRoom && (
							<>
								<CamWrapContainer />
								{isOpen && <CamExpansionView />}
							</>
						)}
					</main>
				</div>
			</div>
			<ChatFormView />
			{!mobileChatInputClick && (
				<>
					<JoyStickContainer />
					<LowerRightButtonContainer />
				</>
			)}
			{/* 방 리스트 관련 */}
			<RoomListContainer />

			{/* 캐릭터 관련 */}
			<AvatarProfileContainer />

			{/* 결제 관련 */}
			<PaymentContainer />

			<div
				className={`${
					alertPopOpen || confirmPopOpen || openBlock
						? 'settingPopOn'
						: 'settingPop'
				}`}
			>
				<div className="settingDim" />

				<CSSTransition
					in={alertPopOpen}
					classNames="withdrawalWrap-transition"
					timeout={100}
				>
					<CommonAlertPopView
						alertPopMessage={alertPopMessage}
						fnCommonAlertPopOff={fnCommonAlertPopOff}
					/>
				</CSSTransition>

				<CSSTransition
					in={confirmPopOpen}
					classNames="logoutWrap-transition"
					timeout={100}
				>
					<CommonConfirmPopView
						confirmPopMessage={confirmPopMessage}
						fnCommonConfirmPopExecute={fnCommonConfirmPopExecute}
						fnCommonConfirmPopOff={fnCommonConfirmPopOff}
					/>
				</CSSTransition>

				{/* 차단하기 */}
				<CSSTransition
					in={openBlock}
					classNames="blockPopWrap-transition"
					timeout={100}
				>
					<BlockPopView
						setOpenBlock={setOpenBlock}
						fnSaveBlockUser={fnSaveBlockUser}
					/>
				</CSSTransition>
			</div>
		</>
	);
}

function getComponent(showChat: boolean) {
	if (showChat) return <ChatContainer />;
}
