import { useDatingRepo } from "@dating/repository/dating/useDatingRepo";

export const useCommonAlertPopService = () => {
    const {
        alertPopMessage,
        setAlertPopMessage,
        alertPopOpen,
        setAlertPopOpen,
    } = useDatingRepo();

    /*
     * fnCommonAlertPopOn
     * 팝업 열기
     */
    const fnCommonAlertPopOn = (message: string) => {
        setAlertPopMessage(message);
        setAlertPopOpen(true);
    };

    /*
     * fnCommonAlertPopOff
     * 팝업 닫기
     */
    const fnCommonAlertPopOff = () => {
        setAlertPopMessage("");
        setAlertPopOpen(false);
    };

    return {
        alertPopMessage,
        alertPopOpen,
        setAlertPopOpen,
        fnCommonAlertPopOn,
        fnCommonAlertPopOff,
    };
};