import { useLoungeAdapter } from '@dating/adapter/lounge/useLoungeAdapter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import {
	type BlockPostCIVO,
	CommentCIVO,
	DeleteCommentCIVO,
	PostIdCIVO,
	PostInfoCOVO,
	PostLikeCIVO,
} from '@/site/api';
import { useFeedListStateRepo } from '@dating/ui/pages/lounge/feedList/useFeedListStateRepo';
import { useEffect } from 'react';
import useImgCropRepo from '@dating/repository/imgCrop/useImgCropRepo';
import { useLikeAdapter } from '@dating/adapter/like/useLikeAdapter';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import { useQuery } from '@tanstack/react-query';
import { useSettingAdapter } from '@dating/adapter/setting/useSettingAdapter';
import leaveChatStore from '@dating/stores/LeaveChatStore';

export const useFeedListService = () => {
	const {
		commentState,
		setCommentState,
		focusedPostId,
		setFocusedPostId,
		openComment,
		setOpenComment,
		commentResponse,
		setCommentResponse,
		commentValue,
		setCommentValue,
		postUser,
		setPostUser,
		postList,
		setPostList,
		checkedFilter,
		setCheckedFilter,
		fadeIn,
		setFadeIn,
		isClickedFeedMenu,
		setIsClickedFeedMenu,
	} = useFeedListStateRepo();

	const {
		setFeedCommentOpen,
		clearFeedForm,
		// keyword,
		openFeedHidePop,
		setOpenFeedHidePop,
		blockPostId,
		setBlockPostId,
		feedFilterOpen,
		setFeedFilterOpen,
		feedFilterList,
		feedEditPops,
		setFeedEditPops,
	} = useLoungeRepo();

	const { clearPreviewURLs } = useImgCropRepo();

	const {
		myProfile,
		myProfileOpen,
		setUserProfileOpen,
		setMyProfileOpen,
		srcPrefix,
		sliderSettings,
	} = useDatingRepo();

	const {
		getPosts,
		removePost,
		saveComment,
		statusSaveComment,
		dataSaveComment,
		getComment,
		getPostsByGender,
		deleteComment,
	} = useLoungeAdapter();

	const {
		savePostLike,
		statusSavePostLike,
		dataSavePostLike,
	} = useLikeAdapter();

	const {
		saveBlockPost,
		statusSaveBlockPost,
		dataSaveBlockPost,
	} = useSettingAdapter();

	const { status: statusGetPosts, data: dataGetPosts } = useQuery({
		queryKey: ['getPosts'],
		queryFn: () => {
			return getPosts();
		},
		enabled: !myProfileOpen,
	});
	////////////////////////////////////////////////

	useEffect(() => {
		if (statusSaveComment === 'success' && dataSaveComment) {
			commentViewHandler(false);

			// 댓글입력 input 초기화
			setCommentValue('');
		}
	}, [statusSaveComment, dataSaveComment]);

	useEffect(() => {
		if (statusGetPosts === 'pending') {
			setFadeIn(false);
		} else if (statusGetPosts === 'success' && dataGetPosts) {
			setPostList(dataGetPosts);

			// 케밥 control list set
			initFeedEditPops(dataGetPosts);

			setFadeIn(true);
		}
	}, [statusGetPosts, dataGetPosts]);

	useEffect(() => {
	}, [statusSavePostLike, dataSavePostLike]);

	/*
	 * deleteContent
	 * 피드삭제
	 */
	const deleteContent = (id: string) => {
		const map: PostIdCIVO = {
			postId: id.toString(),
		};
		removePost(map);
	};

	/*
	 * commentViewHandler
	 * 댓글창 오픈 시 container로 상태값 전달, wrap에 no-scroll 클래스 추가
	 */
	const commentViewHandler = (state: boolean) => {
		// 댓글창 열기
		setOpenComment(state);

		// wrap에 no-scroll class 추가
		setCommentState(state);

		// 댓글창 활성화 시 footer menu z-index 조정
		setFeedCommentOpen(state);
	};

	/*
	 * commentListHandler
	 * 선택한 postId로 commentList handle
	 */
	function commentListHandler(
		postId: string,
		postUser: { userUid: string; nickname: string },
	) {
		// 클릭한 postId repo에 저장
		setFocusedPostId(postId);

		getComment(postId).then((value) => {
			if (postId != '') {
				// 댓글리스트 repo에 저장
				setCommentResponse(value);

				// 작성자 닉네임 저장
				setPostUser(postUser);
			} else {
				setCommentResponse([]);
			}
		});
	}

	/*
	 * fnSaveComment
	 * 댓글 등록
	 */
	const fnSaveComment = (id: string, comment: string) => {
		if (comment != null) {
			let commentObject: CommentCIVO = {
				postId: id,
				comment: comment,
			};
			saveComment(commentObject);
		}
	};

	/*
	 * fnSavePostLike
	 * 게시글 좋아요
	 */
	const fnSavePostLike = (postId: string, likeYn: boolean) => {
		const map: PostLikeCIVO = {
			likePostId: postId,
			likeYn: likeYn,
		};
		savePostLike(map);
	};

	/*
	 * fnSaveBlockPost
	 * 게시글 숨기기
	 */
	const fnSaveBlockPost = () => {
		if (blockPostId != '') {
			const map: BlockPostCIVO = {
				postId: blockPostId,
			};
			saveBlockPost(map);
		}
	};


	useEffect(() => {
		if (statusSaveBlockPost === 'success' && dataSaveBlockPost) {
			setOpenFeedHidePop(false);
			setBlockPostId('');
		}
	}, [statusSaveBlockPost, dataSaveBlockPost]);

	/*
	 * fnGetPosts
	 * 게시글 전체 조회
	 */
	const fnGetPosts = () => {
		getPosts().then((value) => {
			setPostList(value);
			setFeedFilterOpen(false);
			setFadeIn(true);
		});
	};

	/*
	 * fnGetPostsByGender
	 * 성별 필터 적용 게시글 조회
	 */
	const fnGetPostsByGender = (gender: string) => {
		getPostsByGender(gender).then((value) => {
			setPostList(value);
			setFeedFilterOpen(false);
			setFadeIn(true);
		});
	};

	/*
	 * initFeedEditPops
	 * feedEditPop 값 전부 false로 초기화
	 */
	const initFeedEditPops = (feeds: PostInfoCOVO[]) => {
		let newItem = {};
		feeds.map((feed, i) => {
			newItem[feed.postId] = false;
		});
		setFeedEditPops(newItem);
		setIsClickedFeedMenu({ postId: '', state: false });
	};

	/*
	 * fnFeedEditHandler
	 * 클릭한 피드의 케밥만 open
	 */
	const fnFeedEditHandler = (postId: string) => {
		initFeedEditPops(postList);
		setIsClickedFeedMenu({ postId: postId, state: true });
	};

	useEffect(() => {
		if (isClickedFeedMenu.state && isClickedFeedMenu.postId != '') {
			let newItem = feedEditPops;
			newItem[isClickedFeedMenu.postId] = true;
			setFeedEditPops(newItem);
		}
	}, [feedEditPops, isClickedFeedMenu]);


	/*
	 * fnDeleteComment
	 * 댓글 삭제
	 */
	const fnDeleteComment = (param: {postId: string, commentId: number}) => {
		const map: DeleteCommentCIVO = {
			postId: param.postId,
			commentId: param.commentId,
		};
		deleteComment(map).then(value => {
			if (value) {
				getComment(param.postId).then(response => {
					if (response&& response.length > 0) {
						// 댓글리스트 repo에 저장
						setCommentResponse(response);
					} else {
						setCommentResponse([]);
					}

					// 작성자 닉네임 저장
					setPostUser(postUser);
				});
			}
		});
	};

	return {
		fadeIn,
		postList,
		deleteContent,
		commentState,
		commentListHandler,
		commentViewHandler,
		openComment,
		fnSaveComment,
		focusedPostId,
		commentResponse,
		sliderSettings,
		clearFeedForm,
		clearPreviewURLs,
		commentValue,
		setCommentValue,
		postUser,
		setUserProfileOpen,
		setMyProfileOpen,
		fnSavePostLike,
		srcPrefix,
		myProfile,
		openFeedHidePop,
		setOpenFeedHidePop,
		fnSaveBlockPost,
		setBlockPostId,
		feedFilterOpen,
		setFeedFilterOpen,
		checkedFilter,
		setCheckedFilter,
		feedFilterList,
		fnGetPosts,
		fnGetPostsByGender,
		setFadeIn,
		fnFeedEditHandler,
		feedEditPops,
		initFeedEditPops,
		isClickedFeedMenu,
		fnDeleteComment,
	};
};
