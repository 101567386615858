import { useKeyPressedRepo } from '@/stores/useKeyPressedRepo';
import { Keyboard, NavKeys } from '../../types/KeyboardState';

export class MyKeyboard {
	private keyboard: Phaser.Input.Keyboard.KeyboardPlugin;
	private keyF: Phaser.Input.Keyboard.Key;
	private keyR: Phaser.Input.Keyboard.Key;
	private keyE: Phaser.Input.Keyboard.Key;
	private cursors: NavKeys;

	constructor(keyboard: Phaser.Input.Keyboard.KeyboardPlugin) {
		this.keyboard = keyboard;
		this.keyF = keyboard.addKey('F');
		this.keyR = keyboard.addKey('R');
		this.keyE = keyboard.addKey('E');
		this.cursors = {
			...keyboard.createCursorKeys(),
			...(keyboard.addKeys('W,S,A,D') as Keyboard),
		};
	}

	// get downF() {
	// 	return this.keyF.isDown;
	// }

	// get downR() {
	// 	return this.keyR.isDown;
	// }

	// get downE() {
	// 	return this.keyE.isDown;
	// }

	// get downSpace() {
	// 	return this.cursors.space?.isDown;
	// }

	get clickActionF() {
		return useKeyPressedRepo.getState().fKeyPressed.includes('click');
	}

	get clickActionR() {
		return useKeyPressedRepo.getState().rKeyPressed.includes('click');
	}

	get clickActionE() {
		return useKeyPressedRepo.getState().eKeyPressed.includes('click');
	}

	get clickSpaceBar() {
		return useKeyPressedRepo.getState().spaceKeyPressed.includes('click');
	}

	// resetF() {
	// 	this.keyF.reset();
	// }

	// resetR() {
	// 	this.keyR.reset();
	// }

	// resetE() {
	// 	this.keyE.reset();
	// }

	// resetSpaceBar() {
	// 	this.cursors.space.reset();
	// }

	resetClickActionF() {
		const { setFKeyPressed } = useKeyPressedRepo.getState();
		setFKeyPressed('');
	}

	resetClickActionR() {
		const { setRKeyPressed } = useKeyPressedRepo.getState();
		setRKeyPressed('');
	}

	resetClickActionE() {
		const { setEKeyPressed } = useKeyPressedRepo.getState();
		setEKeyPressed('');
	}

	resetClickSpaceBar() {
		const { setSpaceKeyPressed } = useKeyPressedRepo.getState();
		setSpaceKeyPressed('');
	}
}
