interface props {
    fnSaveHeight: () => void;
    btnActivate: boolean;
}

const HeightFooterView = ({
                              fnSaveHeight,
                              btnActivate,
                          }: props) => {
    return (
        <footer>
            <div className="f-btnWrap">
                <button
                    className="btn btnBig btnBlack"
                    onClick={fnSaveHeight}
                    disabled={btnActivate}
                >
                    <span>다 음</span>
                </button>
            </div>
        </footer>
    );
};

export default HeightFooterView;
