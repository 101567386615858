interface props {
    popTitle: string;
    popDetail: string;
    fnCommonConfirmPopExecute: () => void;
    fnHushPickPopOff: () => void;
}

export const HushPickConfirmPopView
    = ({
           popTitle,
           popDetail,
           fnCommonConfirmPopExecute,
           fnHushPickPopOff,
       }: props) => {
    return (
        <div className="logoutWrap">
            <div className="popupInfo padding-t36">
                <p className="fz20 bold-500 text-c">
                    {popTitle}
                </p>
                <p className="fz16Fw500 color-999 text-c padding-t8">
                    {popDetail}
                </p>
                <div className="popFtBtnWrap">
                    <button
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            fnHushPickPopOff();
                        }}
                    >
                        <span>아니요</span>
                    </button>
                    <button
                        type="button"
                        className="btnBig btnBlack radiusNone"
                        onClick={() => {
                            fnCommonConfirmPopExecute();
                            fnHushPickPopOff();
                        }}
                    >
						<span
                            className="flexXYcenter"
                            style={{
                                fontSize: '16px',
                            }}
                        >
							추천받기
                            <span
                                style={{
                                    fontSize: '10px',
                                }}
                            >
                                &nbsp;
                                {/*<span>100</span>*/}
							</span>
                            <span className="ic-berith">0</span>
						</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
