import EventListView from './views/EventListView';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';

const EventContainer = () => {
	const title = '이벤트';

	return (
		<>
			<div className="rightShow active">
				<ButtonHeaderView title={title} right={false} />
				<EventListView />
			</div>
		</>
	);
};

export default EventContainer;
