import { Outlet } from 'react-router-dom';
import ManagerProfileCardView from '../manager/views/ManagerProfileCardView';
import MessageListView from './views/MessageListView';
import { useMessageListService } from './service/useMessageListService';
import { useHeaderService } from '@dating/ui/pages/header/useHeaderService';
import DatingHeaderView from '@dating/ui/pages/header/DatingHeaderView';
import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';

const MessageListContainer = () => {
	const { showDirectMessageList, managerEmpty, myProfile, srcPrefix } =
		useMessageListService();
	const { setManagerEmpty } = useDirectMessageRepo();
	const { setMyProfileOpen } = useHeaderService();

	const { fnCommonAlertPopOn } = useCommonAlertPopService();

	return (
		<>
			{showDirectMessageList && (
				<div className="wrap">
					<DatingHeaderView
						myProfile={myProfile}
						srcPrefix={srcPrefix}
						setMyProfileOpen={setMyProfileOpen}
					/>
					<div
						id="conWrap"
						className="metaPopWrap"
						style={{ padding: '63px 0' }}
					>
						<div className="messengerWrap">
							<div className="inner">
								<div
									className="profileType cm"
									onClick={() => {
										setManagerEmpty(!managerEmpty);
									}}
								>
									{/*<ManagerProfileCardView />*/}
									<img
										src="/assets/img/common/cp-banner01.png"
										alt="단순 채팅을 넘어 그 이상을 원한다면 커플 매니저 서비스를 이용해 보세요"
										width="100%"
										onClick={() => {
											fnCommonAlertPopOn('매니저 서비스 \r\n준비중 입니다.');
										}}
									/>
								</div>
								<MessageListView />
							</div>
						</div>
					</div>
				</div>
			)}
			<Outlet />
		</>
	);
};

export default MessageListContainer;
