const PhotoImageGuideView = () => {
    return (
        <div className="imgAddExBox margin-t24">
            <p className="text-c bold-500">사진 등록 가이드</p>
            <div className="imgAddEx padding-t8">
                <div>
                    <img
                        src="/assets/img/common/img-add-ex01.png"
                        alt="예제이미지 O"
                    />
                    <img
                        src="/assets/img/common/img-add-ex_o.png"
                        alt="예제이미지 O"
                        width="34"
                        height="34"
                        className="icoExOX"
                    />
                </div>
                <div>
                    <img
                        src="/assets/img/common/img-add-ex02.png"
                        alt="예제이미지 X"
                    />
                    <img
                        src="/assets/img/common/img-add-ex_x.png"
                        alt="예제이미지 X"
                        width="34"
                        height="34"
                        className="icoExOX"
                    />
                </div>
            </div>
        </div>
    )
};

export default PhotoImageGuideView;