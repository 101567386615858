interface props {
    setOpenBlock: (openBlock: boolean) => void;
    fnSaveBlockUser: () => void;
};

const BlockPopView = ({
                          setOpenBlock,
                          fnSaveBlockUser,
                      }: props) => {
    return (
        <div className="blockPopWrap">
            <div className="popupInfo">
                <p className="fz20 bold-500 text-c">차단 하시겠어요?</p>
                <p className="fz16Fw500 color-999 text-c padding-t8">
                    서로 더 이상 볼 수 없습니다.
                </p>
                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            setOpenBlock(false);
                        }}
                    >
                        <span>아니요</span>
                    </button>
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
                            fnSaveBlockUser();
                        }}
                    >
                        <span>차단하기</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BlockPopView;
