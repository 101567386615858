import React from 'react';
import { useTodayTypeService } from '@dating/ui/pages/home/today/useTodayTypeService';
import { useHomeHeaderService } from '@dating/ui/pages/home/useHomeHeaderService';
import { useHeaderService } from '@dating/ui/pages/header/useHeaderService';
import HomeHeaderView from '@dating/ui/pages/home/HomeHeaderView';
import DatingHeaderView from '@dating/ui/pages/header/DatingHeaderView';
import { usePaymentService } from '@dating/ui/components/payment/service/usePaymentService';
import ProfileBoxView from '@dating/ui/pages/home/idealType/views/ProfileBoxView';

const TodayTypeContainer = () => {
	const {
		randomUsers,
		sliderSettings,
		isTodayLikeds,
	} = useTodayTypeService();

	const {
		todayType,
		changeHomeType,
	} = useHomeHeaderService();

	const {
		myProfile,
		setMyProfileOpen,
		srcPrefix,
		setUserProfileOpen,
	} = useHeaderService();

	const {
		fnSendLikePopOpen,
		setOpenPaymentPop,
		setMessageTargetUser,
		setLikedLocation,
	} = usePaymentService();

	return (
		<div className="wrap">
			<DatingHeaderView
				myProfile={myProfile}
				srcPrefix={srcPrefix}
				setMyProfileOpen={setMyProfileOpen}
			/>
			<div id="conWrap" className="metaPopWrap">
				<HomeHeaderView todayType={todayType} changeHomeType={changeHomeType} />
				<div className="toDayWrap">
					<div className="tabConWrap inner">
						<div className="tabContent">
							<div
								className={
									todayType ? 'tabCon tabCon02 active' : 'tabCon tabCon02'
								}
							>
								<div className="profileList">
									{/*랜덤 유저 추천 리스트*/}
									{randomUsers.length > 0
										? randomUsers.map((profile, i) => {
											return (
												<ProfileBoxView
													key={profile.nickName + i}
													preferUser={profile}
													srcPrefix={srcPrefix}
													fnSendLikePopOpen={fnSendLikePopOpen}
													setUserProfileOpen={setUserProfileOpen}
													currentUserUid={myProfile.userUid}
													setOpenPaymentPop={setOpenPaymentPop}
													sliderSettings={sliderSettings}
													setMessageTargetUser={setMessageTargetUser}
													isLiked={isTodayLikeds[profile.matchingUserUid]}
													type={"today"}
													setLikedLocation={setLikedLocation}
												/>
											);
										})
										: null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TodayTypeContainer;
