import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import useLeaveChatStore from '@dating/stores/LeaveChatStore';
import { useBlockReportService } from '@dating/ui/components/blockReport/service/useBlockReportService';
import { useState, useEffect, useRef, useMemo } from 'react';

export const useMessageRepo = () => {
	const { openReport, setOpenReport, openBlock, setOpenBlock } =
		useBlockReportService();
	const {
		focused,
		dmSessionId,
		managerEmpty,
		openDirectMessageDetail,
		showDirectMessageList,
		messageInputValue,
		directMessageList,
		unreadMessageCounts,
		groupedMessages,
		detailDirectMessages,
		directMessageRoomList,
		setDirectMessageList,
		setDetailDirectMessages,
	} = useDirectMessageRepo();

	const inputRef = useRef<HTMLInputElement>(null);
	const messagesEndRef = useRef<HTMLDivElement>(null);

	const processedMessageList = useMemo(() => {
		if (!directMessageRoomList.length) return [];

		return directMessageRoomList
			.map((room) => {
				const messages = groupedMessages[room.roomId]?.messages;
				if (!messages) return null;

				try {
					const processedMessages = Array.isArray(messages)
						? messages // 이미 배열 형태라면 그대로 사용
						: Array.from(messages.values()); // MapSchema일 경우 배열로 변환

					return {
						roomId: room.roomId,
						user1Id: room.user1Id,
						user2Id: room.user2Id,
						user1Info: room.user1Info,
						user2Info: room.user2Info,
						dmRoomType: room.dmRoomType,
						billingStatus: room.billingStatus,
						visibleTo: room.visibleTo,
						unreadCount: room.unreadCount,
						createdAt: room.createdAt,
						messages: processedMessages,
					};
				} catch (error) {
					console.error('Error processing messages for room:', room.roomId);
					return null;
				}
			})
			.filter(Boolean);
	}, [directMessageRoomList, groupedMessages]);

	// 상세 메시지 처리를 메모이제이션
	const processedDetailMessages = useMemo(() => {
		return processedMessageList.reduce((acc, room) => {
			if (room?.messages) {
				acc[room.roomId] = {
					roomId: room.roomId,
					messages: room.messages,
				};
			}
			return acc;
		}, {} as Record<string, any>);
	}, [processedMessageList]);

	// 실제 상태 업데이트는 값이 변경되었을 때만
	useEffect(() => {
		if (processedMessageList.length > 0) {
			const currentMessageListString = JSON.stringify(directMessageList);
			const newMessageListString = JSON.stringify(processedMessageList);

			if (currentMessageListString !== newMessageListString) {
				setDirectMessageList(processedMessageList);
			}
		}
	}, [processedMessageList]);

	useEffect(() => {
		if (Object.keys(processedDetailMessages).length > 0) {
			const currentDetailString = JSON.stringify(detailDirectMessages);
			const newDetailString = JSON.stringify(processedDetailMessages);

			if (currentDetailString !== newDetailString) {
				setDetailDirectMessages(processedDetailMessages);
			}
		}
	}, [processedDetailMessages]);

	const { openChatMenuPop, setOpenChatMenuPop } = useLeaveChatStore();
	const [openLeaveChat, setOpenLeaveChat] = useState(false);

	function modifyMenuPop(e: { stopPropagation: () => void }) {
		e.stopPropagation();
		if (!openChatMenuPop) {
			setOpenChatMenuPop(true);
		} else {
			setOpenChatMenuPop(false);
		}
	}

	useEffect(() => {
		if (openChatMenuPop) {
			document.addEventListener('click', menuDotHandler);
		}
	}, [openChatMenuPop]);

	function menuDotHandler() {
		setOpenChatMenuPop(false);
	}

	return {
		openReport,
		openBlock,
		groupedMessages,
		detailDirectMessages,
		focused,
		openDirectMessageDetail,
		dmSessionId,
		directMessageList,
		inputRef,
		showDirectMessageList,
		messageInputValue,
		openLeaveChat,
		managerEmpty,
		messagesEndRef,
		unreadMessageCounts,
		setOpenReport,
		setOpenBlock,
		modifyMenuPop,
		setOpenLeaveChat,
		setDirectMessageList,
		setDetailDirectMessages,
	};
};
