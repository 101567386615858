const MissionListView = () => {
	return (
		<div className="popConWrap">
			<div className="inner">
				<ul className="missionList margin-t32">
					<li className="leftRightTxt">
						<div className="left">
							<p className="fz14Fw700">상품 1개 구매하기</p>
							<p className="fz12 color-666">구매시 베리드 추가 제공</p>
						</div>
						<div className="right">
							<p className="ic-berith">1000</p>
							<button className="btn btnSm btnBlack width-70">받기</button>
						</div>
					</li>
					<li className="leftRightTxt">
						<div className="left">
							<p className="fz14Fw700">다대다 미팅 1회 참여</p>
							<p className="fz12 color-666">미팅 참여후 제공</p>
						</div>
						<div className="right">
							<p className="ic-berith">100</p>
							<button className="btn btnSm btnBd999 width-70">미달성</button>
						</div>
					</li>

					<li className="leftRightTxt">
						<div className="left">
							<p className="fz14Fw700">라운지에서 댓글 달기</p>
							<p className="fz12 color-666">타인의 게시글에 댓글 남기기</p>
						</div>
						<div className="right">
							<p className="ic-berith">800</p>
							<button className="btn btnSm btnCCC width-70">완료</button>
						</div>
					</li>

					<li className="leftRightTxt">
						<div className="left">
							<p className="fz14Fw700">캠 켜기</p>
							<p className="fz12 color-666">다대다 미팅에서 캠 켜기</p>
						</div>
						<div className="right">
							<p className="ic-berith">4000</p>
							<button className="btn btnSm btnBlack width-70">받기</button>
						</div>
					</li>

					<li className="leftRightTxt">
						<div className="left">
							<p className="fz14Fw700">마이크 켜기</p>
							<p className="fz12 color-666">다대다 미팅에서 마이크 켜기</p>
						</div>
						<div className="right">
							<p className="ic-berith">2000</p>
							<button className="btn btnSm btnBd999 width-70">미달성</button>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default MissionListView;
