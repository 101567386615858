import {useState} from "react";

export const useRecommendCodeStateRepo = () => {
    const [recommendCode, setRecommendCode] = useState<string>("");

    const [codeFailed, setCodeFailed] = useState(false);

    // api 응답결과
    const [response, setResponse] = useState<boolean>(false);

    return {
        recommendCode,
        setRecommendCode,
        codeFailed,
        setCodeFailed,
        response,
        setResponse,
    };
};