import { useState } from 'react';

const NoticeListView = () => {
	// const [activeItems, setActiveItems] = useState([false, false, false]);
	const [activeItems, setActiveItems] = useState([false]);
	const toggleActive = (index) => {
		const newActiveItems = [...activeItems];
		newActiveItems[index] = !newActiveItems[index];
		setActiveItems(newActiveItems);
	};

	return (
		<div className="popConWrap">
			<ul className="acoWrap">
				{activeItems.map((isActive, index) => (
					<li key={index} className={`acoItem ${isActive ? 'active' : ''}`}>
						<button
							className="acoTit"
							onClick={() => {
								toggleActive(index);
							}}
						>
							<span>
								<span className="main-color">[공지] </span>
								HUSH 데이팅 앱의 베타버전이 오픈되었습니다.
								{/*{index === 0 ? (*/}
								{/*	<span className="main-color">[당첨자 발표]</span>*/}
								{/*) : index === 1 ? (*/}
								{/*	<span className="main-color">[당첨자 발표]</span>*/}
								{/*) : (*/}
								{/*	<span className="main-color">[공지]</span>*/}
								{/*)}{' '}*/}

								{/*{index === 2*/}
								{/*	? '2024년 구글 ”올해의 혁신적인 앱” 선정'*/}
								{/*	: '가입 이벤트 당첨자 발표'}*/}
							</span>
						</button>
						<div className="acoCon">
							<p>
								2024년 11월 01일 HUSH 데이팅 앱 베타버전이 오픈되었습니다.<br/>
								다양한 사용자의 피드백을 반영하여 더욱 편리하고 사용자 친화적인<br/>
								서비스를 제공하겠습니다.<br/>
								많은 참여 부탁드립니다.<br/><br/>
								감사합니다.
							</p>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};

export default NoticeListView;
