import {
	MetaService,
	MetaUserAvatarCIVO,
	MetaUserCIVO,
	MetaUserMediaCIVO,
	MetaUserWebRTCCIVO,
} from '@/site/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

interface props {
	myMetaUserOpen: boolean;
	otherMetaUser: string;
}

export const useMetaUserAdapter = ({
	myMetaUserOpen,
	otherMetaUser,
}: props) => {
	const queryClient = useQueryClient();

	/*
	 * saveMetaUser
	 * 메타버스 유저 프로필 저장
	 */
	const saveMetaUser = useMutation({
		mutationFn: (map: MetaUserCIVO) => {
			return MetaService.saveMetaUser(map);
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getMetaUser'] });
			return data;
		},
	});

	/*
	 * getMyMetaUser
	 * 내 메타버스 유저 프로필 상세조회
	 */
	const getMyMetaUser = useQuery({
		queryKey: ['getMyMetaUser'],
		queryFn: () => {
			if (myMetaUserOpen) {
				return MetaService.getMyMetaUser();
			}
			return null;
		},
	});

	/*
	 * getMetaUser
	 * 메타버스 유저 프로필 상세조회
	 */
	const getMetaUser = useQuery({
		queryKey: ['getMetaUser', otherMetaUser],
		queryFn: async () => {
			if (otherMetaUser !== '') {
				try {
					const result = await MetaService.getMetaUser(otherMetaUser);
					return result;
				} catch (error) {
					console.error('Error in getUserProfile:', error);
					throw error;
				}
			}

			return null;
		},
		select: (data) => {
			return data;
		},
	});

	/*
	 * saveMetaUserAvatar
	 * 메타버스 유저 아바타 정보 저장
	 */
	const saveMetaUserAvatar = useMutation({
		mutationFn: (map: MetaUserAvatarCIVO) =>
			MetaService.saveMetaUserAvatar(map),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getMyMetaUser'] });
			return data;
		},
	});

	/*
	 * saveUserMediaConnected
	 * 메타버스 회원 미디어 권한 설정 저장
	 */
	// const saveUserMediaConnected = useMutation({
	// 	mutationFn: (map: MetaUserMediaCIVO) =>
	// 		MetaService.saveUserMediaConnected(map),
	// 	onSuccess: (data) => {
	// 		queryClient.invalidateQueries({ queryKey: ['getUserMediaConnected'] });
	// 		return data;
	// 	},
	// });

	/*
	 * getUserMediaConnected
	 * 메타버스 회원 미디어 권한 설정 조회
	 */
	// const getUserMediaConnected = useQuery({
	// 	queryKey: ['getUserMediaConnected'],
	// 	queryFn: () => MetaService.getUserMediaConnected(),
	// });

	/*
	 * saveUserWebRtcConnect
	 * 메타버스 회원 WebRTC 연결 설정 저장
	 */
	// const saveUserWebRtcConnect = useMutation({
	// 	mutationFn: (map: MetaUserWebRTCCIVO) =>
	// 		MetaService.saveUserWebRtcConnect(map),
	// 	onSuccess: (data) => {
	// 		queryClient.invalidateQueries({ queryKey: ['getUserWebRtcConnect'] });
	// 		return data;
	// 	},
	// });

	/*
	 * getUserWebRtcConnect
	 * 메타버스 회원 WebRTC 연결 설정 조회
	 */
	// const getUserWebRtcConnect = useQuery({
	// 	queryKey: ['getUserWebRtcConnect'],
	// 	queryFn: () => MetaService.getUserWebRtcConnect(),
	// });

	return {
		statusGetMyMetaUser: getMyMetaUser.status,
		dataGetMyMetaUser: getMyMetaUser.data,
		statusGetMetaUser: getMetaUser.status,
		dataGetMetaUser: getMetaUser.data,
		// statusGetUserMediaConnected: getUserMediaConnected.status,
		// getUserMediaConnected: getUserMediaConnected.data,
		// statusGetUserWebRtcConnect: getUserWebRtcConnect.status,
		// getUserWebRtcConnect: getUserWebRtcConnect.data,
		statusMetaUserAvatar: saveMetaUserAvatar.status,
		getMetaUserAvatar: saveMetaUserAvatar.data,

		saveMetaUser: saveMetaUser.mutate,
		saveMetaUserAvatar: saveMetaUserAvatar.mutate,
		// saveUserMediaConnected: saveUserMediaConnected.mutate,
		// saveUserWebRtcConnect: saveUserWebRtcConnect.mutate,
	};
};
