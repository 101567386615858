import RegisterHeaderView from "../common/registerHeader/views/RegisterHeaderView";
import {useRecommendCodeService} from "./service/useRecommendCodeService";
import RecommendCodeFooterView from "./views/RecommendCodeFooterView";
import RecommendCodeContentsView from "./views/RecommendCodeContentsView";
import React, {useEffect} from "react";
import {
    useRegisterHeaderService
} from "@dating/ui/pages/register/common/registerHeader/service/useRegisterHeaderService";

import {useNavigate} from "react-router-dom";
import StepProgressView from "@dating/ui/pages/register/common/stepProgress/views/StepProgressView";

const RecommendCodeContainer = () => {
    const {
        setRecommendCode,
        fnSaveRecommender,
        codeFailed,
        response,
        registerSteps,
    } = useRecommendCodeService();

    const {
        referralCodeOpen,
        setReferralCodeOpen,
        skipHandler,
        skipRecommenderStatus,
        skipRecommenderData,
		step,
    } = useRegisterHeaderService();

    const navigate = useNavigate();
    useEffect(() => {
        if (skipRecommenderStatus === "success") {
            if (skipRecommenderData?.result) {
                navigate(registerSteps[9].url);
            }
        }
    }, [skipRecommenderStatus]);

    useEffect(() => {
        if (response) {
            navigate(registerSteps[9].url);
        }
    }, [response]);

    return (
        <div className="wrap noFooter">
            <RegisterHeaderView
                referralCodeOpen={referralCodeOpen}
                setReferralCodeOpen={setReferralCodeOpen}
                skipHandler={skipHandler}
            />

            <div className="contentsWrap">
                <div className="titleWrap">
                    <StepProgressView step={step}/>
                    <h2 className="title01">추천인 코드 입력</h2>
                    <p className="titleSub01">
                        초대 받은 발급 코드를 입력하시면 두 분 모두에게 각각 <br/>
                        500베리드를 드립니다.
                    </p>
                </div>
                <RecommendCodeContentsView
                    setRecommendCode={setRecommendCode}
                    codeFailed={codeFailed}
                />
            </div>

            <RecommendCodeFooterView
                fnSaveRecommender={fnSaveRecommender}
            />
        </div>
    );
};

export default RecommendCodeContainer;
