import { useMessageDetailService } from '@/ui/components/dm/detail/service/useMessageDetailService';
import StoreWrapView from './views/StoreWrapView';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';

const StoreContainer = () => {
	const title = '스토어';
	const berith = 17220;
	const { handleSendFirstTimeReceive } = useMessageDetailService();
	return (
		<div className="rightShow active">
			<ButtonHeaderView title={title} berith={berith} right={false} />
			<StoreWrapView handleSendFirstTimeReceive={handleSendFirstTimeReceive} />
		</div>
	);
};

export default StoreContainer;
