import { useAccountSettingService } from '@dating/ui/pages/settings/accountSetting/useAccountSettingService';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import AccountSettingListView from '@dating/ui/pages/settings/accountSetting/views/AccountSettingListView';
import { useCommonConfirmPopService } from '@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Network from '@/services/Network';
import { Message } from '../../../../../../../../types/Messages';

const AccountSettingContainer = () => {
	const {
		title,
		setOpenLogoutPop,
		myProfile,
		openLogoutPop,
		fnLogout,
		response,
	} = useAccountSettingService();

	const { fnCommonConfirmPopOn, fnCommonConfirmPopOff } =
		useCommonConfirmPopService();

	const [isLogout, setIsLogout] = useState({});

	const navigate = useNavigate();
	useEffect(() => {
		if (response) {
			fnCommonConfirmPopOff();

			const handleLogout = async () => {
				try {
					// 1. Flutter 처리 (if available)
					if (window.flutter_inappwebview?.callHandler) {
						try {
							const response = await window.flutter_inappwebview.callHandler(
								'logoutData',
							);
							console.log('Logout response:', response);
							setIsLogout(response);
						} catch (error) {
							console.error('Flutter handler error:', error);
						}
					}

					// 2. 페이지 이동
					navigate('/metaverse/dating/register');

					// 2. Network 정리
					await Network.cleanup();
				} catch (error) {
					console.error('Logout error:', error);
					// 에러가 발생해도 register 페이지로 이동
					navigate('/metaverse/dating/register');
				}
			};

			handleLogout();
		}
	}, [response, navigate]);

	return (
		<>
			<div className="rightShow active">
				<ButtonHeaderView title={title} right={false} />
				<AccountSettingListView
					setOpenLogoutPop={setOpenLogoutPop}
					myProfile={myProfile}
					fnCommonConfirmPopOn={fnCommonConfirmPopOn}
					fnLogout={fnLogout}
				/>
			</div>
			{/*<div*/}
			{/*    className={`${*/}
			{/*        openLogoutPop ? 'settingPopOn' : 'settingPop'*/}
			{/*    }`}*/}
			{/*>*/}
			{/*    <div className="settingDim"/>*/}
			{/*    <CSSTransition*/}
			{/*        in={openLogoutPop}*/}
			{/*        classNames="logoutWrap-transition"*/}
			{/*        timeout={100}*/}
			{/*    >*/}
			{/*        <LogoutPopView*/}
			{/*            setOpenLogoutPop={setOpenLogoutPop}*/}
			{/*            fnLogout={fnLogout}*/}
			{/*        />*/}
			{/*    </CSSTransition>*/}
			{/*</div>*/}
		</>
	);
};

export default AccountSettingContainer;
