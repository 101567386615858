import MessageDetailContainer from '@/ui/components/dm/detail/MessageDetailContainer';
import MessageListContainer from '@/ui/components/dm/list/MessageListContainer';
import ManagerMessageContainer from '@/ui/components/dm/manager/ManagerMessageContainer';
import { Route, Routes, useLocation } from 'react-router-dom';

const MessengerRouter = () => {
	const location = useLocation();

	return (
		<Routes location={location}>
			<Route path="/" element={<MessageListContainer />} />
			<Route path="/manager" element={<ManagerMessageContainer />} />
			<Route path="/message/:playerId" element={<MessageDetailContainer />} />
		</Routes>
	);
};

export default MessengerRouter;
