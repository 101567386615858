import {useMutation, useQueryClient} from "@tanstack/react-query";
import {type BlockPhoneNumberCIVO, type BlockPostCIVO, type BlockUserCIVO, SettingService} from "@/site/api";
import type {SettingOnOffCIVO} from "@/site/api";

export const useSettingAdapter = () => {
    const queryClient = useQueryClient();

    //설정 정보 조회
    const getUserSetting = () => {
        return SettingService.getUserSetting();
    };

    //프로필 공개 저장
    const saveSetProfileOpen = useMutation({
        mutationFn: (map: SettingOnOffCIVO) => {
            return SettingService.saveSetProfileOpen(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ["getUserSetting"]});
            return data;
        }
    });

    //소리 및 진동 저장
    const saveSetSound = useMutation({
        mutationFn: (map: SettingOnOffCIVO) => {
            return SettingService.saveSetSound(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ["getUserSetting"]});
            return data;
        }
    });

    //오늘의 추천 저장
    const saveSetTodaySuggest = useMutation({
        mutationFn: (map: SettingOnOffCIVO) => {
            return SettingService.saveSetTodaySuggest(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ["getUserSetting"]});
            return data;
        }
    });

    //좋아요 받음 저장
    const saveSetReceivedLike = useMutation({
        mutationFn: (map: SettingOnOffCIVO) => {
            return SettingService.saveSetReceivedLike(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ["getUserSetting"]});
            return data;
        }
    });

    //보낸 좋아요 확인 저장
    const saveSetSendLikeChk = useMutation({
        mutationFn: (map: SettingOnOffCIVO) => {
            return SettingService.saveSetSendLikeChk(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ["getUserSetting"]});
            return data;
        }
    });

    //대화 메세지 저장
    const saveSetChatMessage = useMutation({
        mutationFn: (map: SettingOnOffCIVO) => {
            return SettingService.saveSetChatMessage(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ["getUserSetting"]});
            return data;
        }
    });

    //라운지 저장
    const saveSetLounge = useMutation({
        mutationFn: (map: SettingOnOffCIVO) => {
            return SettingService.saveSetLounge(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ["getUserSetting"]});
            return data;
        }
    });

    //마케팅 수신 동의 저장
    const saveSetMarketing = useMutation({
        mutationFn: (map: SettingOnOffCIVO) => {
            return SettingService.saveSetMarketing(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ["getUserSetting"]});
            return data;
        }
    });

    /*
     * getCommonCode
     * 신고 항목 조회
     */
    const getCommonCode = async () => {
        return SettingService.getCommonCode();
    };

    /*
     * getJobList
     * 직업 항목 조회
     */
    const getJobList = () => {
        return SettingService.getJobList();
    };

    /*
     * getTerms
     * 이용약관 가져오기
     */
    const getTerms = () => {
        return SettingService.getTerms();
    };

    /*
     * getStyles
     * 스타일(음주, 흡연, 종교, 관심사, 라이프스타일) 항목 조회
     */
    const getStyles = () => {
        return SettingService.getStyles();
    };

    /*
     * getPrefer
     * 이상형 항목 조회
     */
    const getPrefer = () => {
        return SettingService.getPrefer();
    };

    /*
     * saveBlockUser
     * 유저 차단하기
     */
    const saveBlockUser = useMutation({
        mutationFn: (map: {blockUserUid: string}) => {
            return SettingService.saveBlockUser(map);
        }
    });

    /*
     * getBlockUser
     * 차단한 유저 조회
     */
    const getBlockUser = () => {
        return SettingService.getBlockUser();
    }

    /*
     * saveSetReceivedHighScore
     * 받은 관심(높게 받은 평가)
     */
    const saveSetReceivedHighScore = useMutation({
        mutationFn: (map: SettingOnOffCIVO) => {
            return SettingService.saveSetReceivedHighScore(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ["getUserSetting"]});
            return data;
        }
    });

    /*
     * saveSetConnectedLike
     * 연결됨(매칭됨)
     */
    const saveSetConnectedLike = useMutation({
        mutationFn: (map: SettingOnOffCIVO) => {
            return SettingService.saveSetConnectedLike(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ["getUserSetting"]});
            return data;
        }
    });

    /*
     * saveBlockPhoneNumberUser
     * 연락처 목록 차단하기
     */
    const saveBlockPhoneNumberUser = useMutation({
        mutationFn: (map: BlockPhoneNumberCIVO) => {
            return SettingService.saveBlockPhoneNumberUser(map);
        }
    });

    /*
     * saveUnblockUser
     * 유저 차단 해제
     */
    const saveUnblockUser = useMutation({
        mutationFn: (map: BlockUserCIVO) => {
            return SettingService.saveUnblockUser(map);
        }
    });

    /*
     * saveUnblockPhoneNumberUser
     * 연락처 목록 차단 해제
     */
    const saveUnblockPhoneNumberUser = useMutation({
        mutationFn: () => {
            return SettingService.saveUnblockPhoneNumberUser();
        }
    });

    /*
     * logout
     * 로그아웃
     */
    const logout = () => {
        return SettingService.logout();
    };

    /*
     * saveBlockPost
     * 게시글 숨기기
     */
    const saveBlockPost = useMutation({
        mutationFn: (map: BlockPostCIVO) => {
            return SettingService.saveBlockPost(map);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["getPosts"]});
        },
    });

    return {
        saveBlockPhoneNumberUser: saveBlockPhoneNumberUser.mutate,
        statusSaveBlockPhoneNumberUser: saveBlockPhoneNumberUser.status,
        dataSaveBlockPhoneNumberUser: saveBlockPhoneNumberUser.data,
        //
        saveUnblockUser: saveUnblockUser.mutate,
        statusSaveUnblockUser: saveUnblockUser.status,
        dataSaveUnblockUser: saveUnblockUser.data,
        //
        saveSetConnectedLike: saveSetConnectedLike.mutate,
        statusSaveSetConnectedLike: saveSetConnectedLike.status,
        dataSaveSetConnectedLike: saveSetConnectedLike.data,
        //
        saveSetReceivedHighScore: saveSetReceivedHighScore.mutate,
        statusSaveSetReceivedHighScore: saveSetReceivedHighScore.status,
        dataSaveSetReceivedHighScore: saveSetReceivedHighScore.data,
        //
        saveSetProfileOpen: saveSetProfileOpen.mutate,
        statusSaveSetProfileOpen: saveSetProfileOpen.status,
        dataSaveSetProfileOpen: saveSetProfileOpen.data,
        //
        saveSetSound: saveSetSound.mutate,
        statusSaveSetSound: saveSetSound.status,
        dataSaveSetSound: saveSetSound.data,
        //
        saveSetTodaySuggest: saveSetTodaySuggest.mutate,
        statusSaveSetTodaySuggest: saveSetTodaySuggest.status,
        dataSaveSetTodaySuggest: saveSetTodaySuggest.data,
        //
        saveSetReceivedLike: saveSetReceivedLike.mutate,
        statusSetReceivedLike: saveSetReceivedLike.status,
        dataSetReceivedLike: saveSetReceivedLike.data,
        //
        saveSetSendLikeChk: saveSetSendLikeChk.mutate,
        statusSetSendLikeChk: saveSetSendLikeChk.status,
        dataSetSendLikeChk: saveSetSendLikeChk.data,
        //
        saveSetChatMessage: saveSetChatMessage.mutate,
        statusSetChatMessage: saveSetChatMessage.status,
        dataSetChatMessage: saveSetChatMessage.data,
        //
        saveSetLounge: saveSetLounge.mutate,
        statusSetLounge: saveSetLounge.status,
        dataSetLounge: saveSetLounge.data,
        //
        saveSetMarketing: saveSetMarketing.mutate,
        statusSetMarketing: saveSetMarketing.status,
        dataSetMarketing: saveSetMarketing.data,
        //
        getUserSetting,
        getCommonCode,
        getJobList,
        getStyles,
        getPrefer,
        getBlockUser,
        getTerms,
        //
        saveBlockUser: saveBlockUser.mutate,
        statusSaveBlockUser: saveBlockUser.status,
        dataSaveBlockUser: saveBlockUser.data,
        //
        saveBlockPost: saveBlockPost.mutate,
        statusSaveBlockPost: saveBlockPost.status,
        dataSaveBlockPost: saveBlockPost.data,
        logout,
    };
};
