interface props {
	messageInputValue: string;
	handleChange: (value: string) => void;
	handlePaymentClick: () => void;
	messageTargetUser: string;
}

const MessagePopView = ({
	messageInputValue,
	handleChange,
	handlePaymentClick,
	messageTargetUser,
}: props) => {
	return (
		<div className="messageWrap">
			<div className="popupInfo padding-b16">
				<form
					onSubmit={(e) => {
						if (e) {
							e.preventDefault();
						}
					}}
				>
					<p
						className="fz20 bold-500 text-c"
						style={{
							padding: '16px',
						}}
					>
						베리드로 메시지 보내기
					</p>
					<div
						className="paymentBox"
						style={{
							padding: '0px 16px 24px 16px',
						}}
					>
						<input
							type="text"
							value={messageInputValue}
							maxLength={192}
							onChange={(e) => {
								handleChange(e.target.value);
							}}
							placeholder="함께 보낼 메시지를 작성해보세요"
						/>
					</div>
					<button
						type="button"
						className={`btnBig radiusNone flexXYcenter ${
							messageInputValue.trim() ? 'btnBlack' : 'btnCCC'
						}`}
						onClick={(e) => {
							handlePaymentClick();
						}}
						disabled={!messageInputValue.trim()}
					>
						<span
							className="ic-berith"
							style={{
								fontSize: '14px',
							}}
						>
							{/*100*/}0
						</span>
						&nbsp;&nbsp;
						<span className="fz16">베리드 보내기</span>
					</button>
				</form>
			</div>
		</div>
	);
};

export default MessagePopView;
