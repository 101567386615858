import { SceneReadyUtil } from '@/utils/util';
import {
	REACT_ADD_CHAT_MESSAGE,
	REACT_AUDIO_STATUS,
	REACT_CLICK_EMOJI,
	REACT_JOIN_ROOM,
	reactEventEmitter,
} from '../../events/ReactEventEmitter';
import GameScene from '../GameScene';

export class PhaserEventController {
	private listeners: Listener[];

	constructor(private scene: GameScene) {
		// 리스너 배열 생성
		this.listeners = [
			new JoinRoomListener(this.scene),
			new MyPlayerChatListener(this.scene),
			new UserMediaListener(this.scene),
			new MyPlayerAudioStatusListener(this.scene),
			new ChangeKeyState(this.scene),
			new DisconnectFromComputer(this.scene),
			new DisconnectFromWhiteboard(this.scene),
			new StopScreenShare(this.scene),
			new OfferComputerMap(this.scene),
			new MyPlayerChangeName(this.scene),
			new MyPlayerChangeAvatar(this.scene),
			new MyPlayerChangeStatusMessage(this.scene),
			new MyPlayerEmojiListener(this.scene),
			new MyPlayerProfileUrlListener(this.scene),
		];
	}

	listen() {
		// 리스너 동작 시작
		this.listeners.forEach((listener) => listener.listen());
	}

	onGameSceneReady() {
		this.listeners.forEach((listener) => {
			if (listener instanceof JoinRoomListener) {
				listener.onGameSceneReady();
			}
		});
	}

	removeAllListeners() {
		this.listeners.forEach((listener) => {
			if (listener.removeListeners) {
				listener.removeListeners();
			}
		});
	}
}

// 리스너 인터페이스
interface Listener {
	listen(): void;
	onGameSceneReady?(): void;
	removeListeners?(): void;
}

class JoinRoomListener implements Listener {
	private pendingJoinData: REACT_JOIN_ROOM | null = null;

	constructor(private scene: GameScene) {}

	listen() {
		reactEventEmitter.on('react-join-room', this.event.bind(this));
	}

	removeListeners() {
		reactEventEmitter.off('react-join-room', this.event.bind(this));
	}

	async event(joinData: REACT_JOIN_ROOM) {
		if (!this.isSceneReady()) {
			this.pendingJoinData = joinData;
			return;
		}

		await this.processJoinData(joinData);
	}

	private isSceneReady(): boolean {
		return !!(
			this.scene &&
			this.scene.sys.isActive() &&
			this.scene.myPlayer &&
			this.scene.roomService
		);
	}

	private async waitForSceneReady(): Promise<void> {
		return new Promise((resolve) => {
			const checkReady = () => {
				if (this.isSceneReady()) {
					resolve();
				} else {
					setTimeout(checkReady, 100);
				}
			};
			checkReady();
		});
	}

	async processJoinData(joinData: REACT_JOIN_ROOM) {
		await this.waitForSceneReady();

		if (!this.isSceneReady()) {
			return;
		}

		try {
			const { name, avatarName, statusMessage, audioStatus } = joinData;

			this.scene.myPlayer.setPlayerName(name);
			await this.scene.myPlayer.setPlayerTexture(avatarName);
			this.scene.myPlayer.setStatusMessage(statusMessage);
			this.scene.myPlayer.setAudioStatus(audioStatus);

			this.scene.roomService.readyToConnect();
			this.scene.roomService.setUpUUID();
		} catch (error) {
			console.error('Error in processJoinData:', error);
		}
	}

	onGameSceneReady() {
		if (this.pendingJoinData) {
			this.processJoinData(this.pendingJoinData);
			this.pendingJoinData = null;
		}
	}
}

// 내 플레이어 이름 변경 리스너
class MyPlayerChangeName implements Listener {
	private pendingName: string | null = null;

	constructor(private scene: GameScene) {}

	listen() {
		reactEventEmitter.on('react-my-player-change-name', this.event.bind(this));
	}

	removeListeners() {
		reactEventEmitter.off('react-my-player-change-name', this.event.bind(this));
	}

	async event(name: string) {
		if (!SceneReadyUtil.isSceneReady(this.scene)) {
			this.pendingName = name;
			return;
		}
		await this.processNameChange(name);
	}

	async processNameChange(name: string) {
		await SceneReadyUtil.waitForSceneReady(this.scene);

		if (!SceneReadyUtil.isSceneReady(this.scene)) {
			return;
		}

		try {
			this.scene.myPlayer.setPlayerName(name);
		} catch (error) {
			console.error('Error in processNameChange:', error);
		}
	}

	onGameSceneReady() {
		if (this.pendingName) {
			this.processNameChange(this.pendingName);
			this.pendingName = null;
		}
	}
}

// 내 플레이어 아바타 변경 리스너
class MyPlayerChangeAvatar implements Listener {
	private pendingAvatarName: string | null = null;

	constructor(private scene: GameScene) {}

	listen() {
		reactEventEmitter.on(
			'react-my-player-change-avatar',
			this.event.bind(this),
		);
	}

	removeListeners() {
		reactEventEmitter.off(
			'react-my-player-change-avatar',
			this.event.bind(this),
		);
	}

	async event(avatarName: string) {
		if (!SceneReadyUtil.isSceneReady(this.scene)) {
			this.pendingAvatarName = avatarName;
			return;
		}
		await this.processAvatarChange(avatarName);
	}

	async processAvatarChange(avatarName: string) {
		await SceneReadyUtil.waitForSceneReady(this.scene);

		if (!SceneReadyUtil.isSceneReady(this.scene)) {
			return;
		}

		try {
			await this.scene.myPlayer.setPlayerTexture(avatarName);
		} catch (error) {
			console.error('Error in processAvatarChange:', error);
		}
	}

	onGameSceneReady() {
		if (this.pendingAvatarName) {
			this.processAvatarChange(this.pendingAvatarName);
			this.pendingAvatarName = null;
		}
	}
}

// 내 플레이어 상태 메시지 변경 리스너
class MyPlayerChangeStatusMessage implements Listener {
	private pendingStatusMessage: string | null = null;
	constructor(private scene: GameScene) {}
	listen() {
		reactEventEmitter.on(
			'react-my-player-change-status-message',
			this.event.bind(this),
		);
	}
	removeListeners(): void {
		reactEventEmitter.off(
			'react-my-player-change-status-message',
			this.event.bind(this),
		);
	}
	async event(statusMessage: string) {
		if (!SceneReadyUtil.isSceneReady(this.scene)) {
			this.pendingStatusMessage = statusMessage;
			return;
		}
		await this.processStatusMessageChange(statusMessage);
	}

	async processStatusMessageChange(statusMessage: string) {
		await SceneReadyUtil.waitForSceneReady(this.scene);

		if (!SceneReadyUtil.isSceneReady(this.scene)) {
			return;
		}

		try {
			this.scene.myPlayer.setStatusMessage(statusMessage);
		} catch (error) {
			console.error('Error in processStatusMessageChange:', error);
		}
	}

	onGameSceneReady() {
		if (this.pendingStatusMessage) {
			this.processStatusMessageChange(this.pendingStatusMessage);
			this.pendingStatusMessage = null;
		}
	}
}

// 내 플레이어 오디오 상태 변경 리스너
class MyPlayerAudioStatusListener implements Listener {
	private pendingAudioStatus: REACT_AUDIO_STATUS | null = null;

	constructor(private scene: GameScene) {}

	listen() {
		reactEventEmitter.on('react-audio-status', this.event.bind(this));
	}
	removeListeners(): void {
		reactEventEmitter.off('react-audio-status', this.event.bind(this));
	}
	async event(content: REACT_AUDIO_STATUS) {
		if (!SceneReadyUtil.isSceneReady(this.scene)) {
			this.pendingAudioStatus = content;
			return;
		}
		await this.processAudioStatus(content);
	}

	async processAudioStatus(content: REACT_AUDIO_STATUS) {
		await SceneReadyUtil.waitForSceneReady(this.scene);

		if (!SceneReadyUtil.isSceneReady(this.scene)) {
			return;
		}

		try {
			this.scene.myPlayer.setAudioStatus(content);
		} catch (error) {
			console.error('Error in processAudioStatus:', error);
		}
	}

	onGameSceneReady() {
		if (this.pendingAudioStatus) {
			this.processAudioStatus(this.pendingAudioStatus);
			this.pendingAudioStatus = null;
		}
	}
}

// 프로필 url 변경 리스너
class MyPlayerProfileUrlListener implements Listener {
	private pendingProfileUrl: string | null = null;
	constructor(private scene: GameScene) {}
	listen() {
		reactEventEmitter.on(
			'react-player-change-profile-url',
			this.event.bind(this),
		);
	}
	removeListeners(): void {
		reactEventEmitter.off(
			'react-player-change-profile-url',
			this.event.bind(this),
		);
	}

	async event(profileUrl: string) {
		if (!SceneReadyUtil.isSceneReady(this.scene)) {
			this.pendingProfileUrl = profileUrl;
			return;
		}
		await this.processProfileUrlChange(profileUrl);
	}

	async processProfileUrlChange(profileUrl: string) {
		await SceneReadyUtil.waitForSceneReady(this.scene);

		if (!SceneReadyUtil.isSceneReady(this.scene)) {
			return;
		}

		try {
			this.scene.myPlayer.setProfileUrl(profileUrl);
		} catch (error) {
			console.error('Error in processProfileUrlChange:', error);
		}
	}

	onGameSceneReady() {
		if (this.pendingProfileUrl) {
			this.processProfileUrlChange(this.pendingProfileUrl);
			this.pendingProfileUrl = null;
		}
	}
}

// 채팅 리스너
class MyPlayerChatListener implements Listener {
	private pendingMessages: REACT_ADD_CHAT_MESSAGE[] = [];

	constructor(private scene: GameScene) {}

	listen() {
		reactEventEmitter.on('react-add-chat-message', this.event.bind(this));
	}

	removeListeners(): void {
		reactEventEmitter.off('react-add-chat-message', this.event.bind(this));
	}
	async event(content: REACT_ADD_CHAT_MESSAGE) {
		if (!SceneReadyUtil.isSceneReady(this.scene)) {
			this.pendingMessages.push(content);
			return;
		}
		await this.processMessage(content);
	}

	async processMessage(content: REACT_ADD_CHAT_MESSAGE) {
		await SceneReadyUtil.waitForSceneReady(this.scene);

		if (!SceneReadyUtil.isSceneReady(this.scene)) {
			return;
		}

		try {
			this.scene.roomService.addChatMessage(content);
			this.scene.myPlayer.updateDialogBubble(content);
		} catch (error) {
			console.error('Error in processMessage:', error);
		}
	}

	onGameSceneReady() {
		while (this.pendingMessages.length > 0) {
			const message = this.pendingMessages.shift();
			if (message) this.processMessage(message);
		}
	}
}

// 이모지 리스너
class MyPlayerEmojiListener implements Listener {
	private pendingEmojis: REACT_CLICK_EMOJI[] = [];

	constructor(private scene: GameScene) {}

	listen() {
		reactEventEmitter.on('react-click-emoji', this.event.bind(this));
	}

	removeListeners(): void {
		reactEventEmitter.off('react-click-emoji', this.event.bind(this));
	}

	async event(emoji: REACT_CLICK_EMOJI) {
		if (!SceneReadyUtil.isSceneReady(this.scene)) {
			this.pendingEmojis.push(emoji);
			return;
		}
		await this.processEmoji(emoji);
	}

	async processEmoji(emoji: REACT_CLICK_EMOJI) {
		await SceneReadyUtil.waitForSceneReady(this.scene);

		if (!SceneReadyUtil.isSceneReady(this.scene)) {
			return;
		}

		try {
			this.scene.roomService.sendEmoji(emoji.senderId, emoji.emoji);
			this.scene.myPlayer.updateReactionBubble(emoji.emoji);
		} catch (error) {
			console.error('Error in processEmoji:', error);
		}
	}

	onGameSceneReady() {
		while (this.pendingEmojis.length > 0) {
			const emoji = this.pendingEmojis.shift();
			if (emoji) this.processEmoji(emoji);
		}
	}
}

// 유저 미디어 리스너
class UserMediaListener implements Listener {
	constructor(private scene: GameScene) {}

	listen() {
		// "react-user-media" 이벤트 리스닝
		reactEventEmitter.on('react-user-media', this.event, this);
	}

	removeListeners(): void {
		reactEventEmitter.off('react-user-media', this.event);
	}

	async event() {
		// 룸 서비스에서 유저 미디어 가져오기
		await this.scene.roomService.webRTC?.getUserMedia();
	}
}

// 키 상태 변경 리스너
class ChangeKeyState implements Listener {
	constructor(private scene: GameScene) {}

	listen() {
		// "react-enable-key" 이벤트 리스닝
		reactEventEmitter.on('react-enable-key', this.event, this);
	}

	removeListeners(): void {
		reactEventEmitter.off('react-enable-key', this.event);
	}

	async event(state: boolean) {
		// 키보드 입력 상태 변경
		this.scene.input.keyboard!.enabled = state;
	}
}

// 컴퓨터 연결 끊기 리스너
class DisconnectFromComputer implements Listener {
	constructor(private scene: GameScene) {}

	listen() {
		// "react-disconnect-from-computer" 이벤트 리스닝
		reactEventEmitter.on('react-disconnect-from-computer', this.event, this);
	}

	removeListeners(): void {
		reactEventEmitter.off('react-disconnect-from-computer', this.event);
	}

	async event(id: string) {
		// 해당 ID에 대한 컴퓨터 연결 끊기
		this.scene.roomService.disconnectFromComputer(id);
	}
}

// 화이트보드 연결 끊기 리스너
class DisconnectFromWhiteboard implements Listener {
	constructor(private scene: GameScene) {}

	listen() {
		// "react-disconnect-from-whiteboard" 이벤트 리스닝
		reactEventEmitter.on('react-disconnect-from-whiteboard', this.event, this);
	}

	removeListeners(): void {
		reactEventEmitter.off('react-disconnect-from-whiteboard', this.event);
	}

	async event(id: string) {
		// 해당 ID에 대한 화이트보드 연결 끊기
		this.scene.roomService.disconnectFromWhiteboard(id);
	}
}

// 화면 공유 중지 리스너
class StopScreenShare implements Listener {
	constructor(private scene: GameScene) {}

	listen() {
		// "react-stop-screen-share" 이벤트 리스닝
		reactEventEmitter.on('react-stop-screen-share', this.event, this);
	}

	removeListeners(): void {
		reactEventEmitter.off('react-stop-screen-share', this.event);
	}

	async event(id: string) {
		// 해당 ID에 대한 화면 공유 중지
		this.scene.roomService.stopScreenShare(id);
	}
}

// 컴퓨터 맵 제공 리스너
class OfferComputerMap implements Listener {
	constructor(private scene: GameScene) {}

	listen() {
		// "react-offer-computer-map" 이벤트 리스닝
		reactEventEmitter.on('react-offer-computer-map', this.event, this);
	}

	removeListeners(): void {
		reactEventEmitter.off('react-offer-computer-map', this.event);
	}

	async event() {
		// 컴퓨터에 맵 정보 전송
		reactEventEmitter.emit('react-send-computer-map', this.scene.computerMap);
	}
}
