import {useState} from "react";
import {CommentCOVO, PostInfoCOVO} from "@/site/api";

export const useFeedListStateRepo = () => {
    // FeedListView > 댓글추가 에서 클릭이벤트가 일어나면 실행할 콜백함수
    const [commentState, setCommentState] = useState<boolean>(false);

    // 클릭한 댓글의 postId
    const [focusedPostId, setFocusedPostId] = useState<string>("");

    // 댓글창
    const [openComment, setOpenComment] = useState<boolean>(false);

    // 댓글 조회 api 응답결과
    const [commentResponse, setCommentResponse] = useState<CommentCOVO[] | undefined>([]);

    // 댓글입력input
    const [commentValue, setCommentValue] = useState<string>("");

    // 피드 작성자 닉네임, userUid
    const [postUser, setPostUser] = useState<{userUid: string, nickname: string}>({userUid: "", nickname: ""});

    // postList
    const [postList, setPostList] = useState<PostInfoCOVO[]>([]);

    // 체크된 성별 필터 값
    const [checkedFilter, setCheckedFilter] = useState<string>("A");

    // 피드 조회 시 화면 전환
    const [fadeIn, setFadeIn] = useState<boolean>(false);

    // fnInitFeedEditHandler이 실행되었는지 여부
    const [isClickedFeedMenu, setIsClickedFeedMenu] = useState<{postId: string, state: boolean}>({postId: "", state: false});

    return {
        isClickedFeedMenu,
        setIsClickedFeedMenu,
        fadeIn,
        setFadeIn,
        postList,
        setPostList,
        postUser,
        setPostUser,
        commentState,
        setCommentState,
        focusedPostId,
        setFocusedPostId,
        openComment,
        setOpenComment,
        commentResponse,
        setCommentResponse,
        commentValue,
        setCommentValue,
        checkedFilter,
        setCheckedFilter,
    };
};