import FeedSearchResultView from '@dating/ui/pages/lounge/feedSearch/views/FeedSearchResultView';
import FeedSearchHeaderView from './views/FeedSearchHeaderView';
import {useFeedSearchService} from "@dating/ui/pages/lounge/feedSearch/service/useFeedSearchService";
import React from "react";
import {useHeaderService} from "@dating/ui/pages/header/useHeaderService";
import {useFeedSearchHeaderService} from "@dating/ui/pages/lounge/feedSearch/views/useFeedSearchHeaderService";
import DatingHeaderView from "@dating/ui/pages/header/DatingHeaderView";

const FeedSearchContainer = () => {
    // keyword로 여기서 검색하는 service 호출
    // -> 검색결과 feed list 뿌리기만 하면 된다?

    const {
        thumbnailClick,
        searchResult,
    } = useFeedSearchService();

    const {
        myProfile,
        srcPrefix,
        setMyProfileOpen,
    } = useHeaderService();

    const {
        clickSearch,
        keyword,
        setKeyword,
        searchFeedOut,
    } = useFeedSearchHeaderService();

    return (
        <div className="wrap">
            <DatingHeaderView
                myProfile={myProfile}
                srcPrefix={srcPrefix}
                setMyProfileOpen={setMyProfileOpen}
            />
            <div id="conWrap" className="metaPopWrap">
                <FeedSearchHeaderView
                    clickSearch={clickSearch}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    searchFeedOut={searchFeedOut}
                />
                <div className="feedWrap padding-t4">
                    {
                        searchResult.length > 0 ?
                            <FeedSearchResultView
                                results={searchResult}
                                thumbnailClick={thumbnailClick}
                                srcPrefix={srcPrefix}
                            />
                            : "조회결과가 없습니다."
                    }
                </div>
            </div>
        </div>
    );
};

export default FeedSearchContainer;
