import { CommentCOVO, UserProfileCOVO } from '@/site/api';
import { Link } from 'react-router-dom';

interface props {
	focusedPostId: string;
	myProfile: UserProfileCOVO;
	fnSaveComment: (id: string, comment: string) => void;
	commentResponse: CommentCOVO[] | undefined;
	srcPrefix: string;
	commentValue: string;
	setCommentValue: (commentValue: string) => void;
	commentViewHandler: (state: boolean) => void;
	postUser: { userUid: string; nickname: string };
	setMyProfileOpen: (myProfileOpen: boolean) => void;
	setUserProfileOpen: (userProfileOpen: string) => void;
	currentUserUid: string;
	fnCommonConfirmPopOn: (message: string, func: any, param: any) => any;
	fnDeleteComment: (param: {postId: string, commentId: number}) => void;
}

const FeedCommentView = ({
							 focusedPostId,
							 myProfile,
							 fnSaveComment,
							 commentResponse,
							 srcPrefix,
							 commentValue,
							 setCommentValue,
							 commentViewHandler,
							 postUser,
							 setMyProfileOpen,
							 setUserProfileOpen,
							 currentUserUid,
							 fnCommonConfirmPopOn,
							 fnDeleteComment,
						 }: props) => {
	return (
		<>
			<div className="commentInfo">
				<p className="commonTit">
					<span>댓글</span>
				</p>
				<div className="commentList">
					{commentResponse != undefined &&
					typeof commentResponse === 'object' &&
					commentResponse.length > 0
						? commentResponse.map((comment, i) => {
							return (
								<div className="profileType" key={i}>
									<div className="proLeft">
										<Link
											to="/metaverse/dating/profile"
											onClick={() => {
												if (myProfile?.userUid === comment.commentUserUid) {
													setMyProfileOpen(true);
												} else {
													comment.commentUserUid
														? setUserProfileOpen(comment.commentUserUid)
														: null;
												}

												commentViewHandler(false);
											}}
											state={{
												isOtherProfile:
													comment.commentUserUid !== currentUserUid,
											}}
										>
											<div className="proImg Sm">
												<img
													src={srcPrefix + comment.commentUserProfileUrl}
													alt="유저프로필"
												/>
											</div>
										</Link>
										<div className="proName margin-l12">
											<p className="fz12Fw500">
												{comment.commentUserNickName}
												<span className="padding-l8 color-999 fz12">
														{comment.createTime}
													</span>
											</p>
											<p className="fz14Fw500 color-666">{comment.comment}</p>
										</div>
									</div>
									<div className="ProRight">
										{comment.isDeletable}
										{
											comment.isDeletable ?
												<button
													type="button"
													className="btnClose"
													onClick={() => {
														fnCommonConfirmPopOn("댓글을 삭제하시겠습니까?", fnDeleteComment, {postId: comment.postId, commentId: comment.commentId});
													}}
												/>
												: null
										}

									</div>
								</div>
							);
						})
						: null}
					<div className="chatInput input">
						<input
							type="text"
							placeholder={postUser.nickname + '님에게 댓글 추가'}
							value={commentValue}
							onChange={(e) => {
								setCommentValue(e.target.value);
							}}
						/>
						<button
							className="submitBtn"
							type="button"
							onClick={(e) => {
								if (commentValue.length > 0) {
									fnSaveComment(focusedPostId, commentValue);
								}
							}}
						></button>
					</div>
				</div>
			</div>
		</>
	);
};

export default FeedCommentView;
