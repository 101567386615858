import {useNavigate } from 'react-router-dom';

interface props {
    fnDeactivateUser: () => void;
}

const WithdrawalFooterView = ({
                                  fnDeactivateUser,
                              }: props) => {
    const navigate = useNavigate();


    return (
        <footer>
            <div className="f-btnWrap">
                <button
                    className="btn btnBig btnBlack popOnBtn"
                    onClick={() => {
                        fnDeactivateUser();
                    }}
                >
                    <span>계정 삭제</span>
                </button>
                <button
                    className="btn btnBig btnCCC margin-t8"
                    type="button"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <span>취 소</span>
                </button>
            </div>
        </footer>
    );
};

export default WithdrawalFooterView;
