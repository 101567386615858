import { create } from "zustand";

interface SettingState { 
  openWithdrawalPop: boolean;
  openLogoutPop: boolean;
  openBlockAcquaintancePop: boolean;

  setOpenWithdrawalPop: (openWithdrawalPop: boolean) => void;
  setOpenLogoutPop: (openLogoutPop: boolean) => void;
  setOpenBlockAcquaintancePop: (openBlockAcquaintancePop: boolean) => void;
}

export const useSettingRepo = create<SettingState>(
	(set) => ({
		openWithdrawalPop: false,
		openLogoutPop: false,
		openBlockAcquaintancePop: false,

		setOpenWithdrawalPop: (openWithdrawalPop: boolean) => set({ openWithdrawalPop }),
		setOpenLogoutPop: (openLogoutPop: boolean) => set({ openLogoutPop }),
		setOpenBlockAcquaintancePop: (openBlockAcquaintancePop: boolean) => set({ openBlockAcquaintancePop }),
	}),
);