import { CSSTransition } from 'react-transition-group';
import ReportPopView from '@dating/ui/components/blockReport/views/ReportPopView';
import BlockPopView from '@dating/ui/components/blockReport/views/BlockPopView';
import AvatarProfileInfoView from './views/AvatarProfileInfoView';
import { useBlockReportService } from '@dating/ui/components/blockReport/service/useBlockReportService';
import { useAvatarProfileService } from './service/useAvatarProfileService';
import { useCommonConfirmPopService } from "@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService";

const AvatarProfileContainer = () => {
	const {
		openMenuPop,
		openReport,
		setOpenReport,
		openBlock,
		setOpenBlock,
		menuDotClickHandler,
		selectedOption,
		handleOptionSelect,
		commonReportCodes,
		reportValue,
		reportInputHandler,
		inputDisabled,
		fnSaveBlockUser,
	} = useBlockReportService();

	const {
		myMetaUser,
		metaUser,
		getCharacterFile,
		openOtherPlayerProfile,
		handleInitAvatarProfile,
	} = useAvatarProfileService();

    const {
        fnCommonConfirmPopOn,
    } = useCommonConfirmPopService();

	return (
		<>
			<div
				className={`${
					openOtherPlayerProfile.isOpen ? 'avatarPopOn' : 'avatarPop'
				}`}
			>
				<div
					className="avatarDim"
					onClick={() => {
						handleInitAvatarProfile();
					}}
				/>
				<CSSTransition
					in={openOtherPlayerProfile.isOpen}
					classNames="avatarProInfo-transition"
					timeout={100}
				>
					<AvatarProfileInfoView
						setOpenReport={setOpenReport}
						setOpenBlock={setOpenBlock}
						openMenuPop={openMenuPop}
						menuDotClickHandler={menuDotClickHandler}
						myMetaUser={myMetaUser}
						metaUser={metaUser}
						getCharacterFile={getCharacterFile}
					/>
				</CSSTransition>
			</div>
			{/* 신고하기 */}
			<CSSTransition
				in={openReport}
				classNames="popWrap-transition"
				timeout={100}
			>
				<ReportPopView
					setOpenReport={setOpenReport}
					selectedOption={selectedOption}
					handleOptionSelect={handleOptionSelect}
					commonReportCodes={commonReportCodes}
					reportValue={reportValue}
					reportInputHandler={reportInputHandler}
					inputDisabled={inputDisabled}
				/>
			</CSSTransition>
		</>
	);
};

export default AvatarProfileContainer;
