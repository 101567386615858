import {useMutation, useQueryClient} from "@tanstack/react-query";
import {HomeService, RandomUserCIVO, RandomUserScoreCIVO} from "@/site/api";

export const useHomeAdapter = () => {
    const queryClient = useQueryClient();

    //이상형찾기 - 선호정보에 맞는 이상형 5명 조회
    const getPreferUser = () => {
        return HomeService.getPreferUser();
    };

    //최근 추천 이성 - 과거 이상형 10명 조회
    const getPastPreferUser = () => {
        return HomeService.getPastPreferUser();
    };

    //TODAY - 랜덤 추천 상대 5명 조회
    const getRandomUser = () => {
        return HomeService.getRandomUser();
    }

    //랜덤 추천 상대 점수 평가
    const saveScoreRandomUser = useMutation({
        mutationFn: (map: RandomUserScoreCIVO) => {
            return HomeService.saveScoreRandomUser(map);
        },
        onSuccess: (data) => {
            return data;
        }
    })

    //랜덤 추천 상대 추가
    const addRandomUser = () => {
        return HomeService.addRandomUser();
    };

    //랜덤 추천 상대 삭제
    const deleteRandomUser = useMutation({
        mutationFn: (map : RandomUserCIVO) => {
            return HomeService.deleteRandomUser(map);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ["getRandomUser"]})
            return data;
        }
    });

    // 상위 10% 이성 추천(과금)
    const getTop10PercentUsers = () => {
        return HomeService.getTop10PercentUsers();
    };

    // 상위 10% 이성 추천 히스토리
    const getPastTopUser = () => {
        return HomeService.getPastTopUser();
    };

    // 최근 접속한 이성 추천(과금)
    const getRecentLoginUsers = () => {
        return HomeService.getRecentLoginUsers();
    };

    // 최근 접속한 이성 추천 히스토리
    const getPastRecentLoginUser = () => {
        return HomeService.getPastRecentLoginUser();
    };

    return {
        getPreferUser,
        getPastPreferUser,
        getRandomUser,
        getTop10PercentUsers,
        getPastTopUser,
        getRecentLoginUsers,
        getPastRecentLoginUser,
        saveScoreRandomUser : saveScoreRandomUser.mutate,
        statusSaveScoreRandomUser : saveScoreRandomUser.status,
        dataSaveScoreRandomUser : saveScoreRandomUser.data,
        addRandomUser,
        deleteRandomUser : deleteRandomUser.mutate,
        statusDeleteRandomUser : deleteRandomUser.status,
        dataDeleteRandomUser : deleteRandomUser.data,
    };
};
