import { useHomeAdapter } from '@dating/adapter/home/useHomeAdapter';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useEffect } from 'react';
import type { RandomUserCIVO, RandomUserScoreCIVO } from '@/site/api';
import { useTodayTypeStateRepo } from '@dating/ui/pages/home/today/useTodayTypeStateRepo';
import { useHomeRepo } from '@dating/repository/home/useHomeRepo';

export const useTodayTypeService = () => {

	const {
		fadeIn,
		setFadeIn,
	} = useTodayTypeStateRepo();

	const {
		setTodayType,
		todayType,
		randomUsers,
		setRandomUsers,
		isTodayLikeds,
		setIsTodayLikeds,
	} = useHomeRepo();

	const {
		srcPrefix,
		myProfile,
		sliderSettings,
	} = useDatingRepo();

	const {
		getRandomUser,
		addRandomUser,
		deleteRandomUser,
		statusDeleteRandomUser,
		dataDeleteRandomUser,
	} = useHomeAdapter();

	useEffect(() => {
		if (todayType && randomUsers.length === 0) {
			fnGetRandomUser();
		} else {
			setFadeIn(true);
		}
	}, [todayType]);

	/*
	 * fnGetRandomUser
	 * 랜덤추천상태 리스트 조회
	 */
	const fnGetRandomUser = () => {
		setFadeIn(false);
		getRandomUser().then((response) => {
			if (response.length > 0) {
				const newItem = randomUsers;
				response.map((profile) => {
					if (profile.profileUrls.length !== 0) {
						newItem.push(profile);
					}
				});
				setRandomUsers(newItem);
				setFadeIn(true);

				// like버튼 상태 초기화
				let item = isTodayLikeds;
				response.map((user) => {
					item[user.matchingUserUid] = false;
				});
				setIsTodayLikeds(item);
			}
		});
	};

	const changeHomeType = (type: boolean) => {
		setTodayType(type);
	};

	/*
	 * fnAddRandomUser
	 * 이상형 더 찾기
	 */
	const fnAddRandomUser = () => {
		addRandomUser().then((response) => {
			if (response) {
				fnGetRandomUser();
			}
		});
	};

	/*
	 * fnDeleteRandomuser
	 * 새로고침(해당 프로필 제외)
	 */
	const fnDeleteRandomuser = (userUid: string) => {
		const map: RandomUserCIVO = {
			randomUserId: userUid,
		};
		deleteRandomUser(map);
	};

	useEffect(() => {
		if (statusDeleteRandomUser === 'success' && dataDeleteRandomUser) {
			const newItem = randomUsers;
			newItem.splice(0, 1);
			setRandomUsers(newItem);
		}
	}, [statusDeleteRandomUser, dataDeleteRandomUser]);

	return {
		fadeIn,
		randomUsers,
		srcPrefix,
		myProfile,
		changeHomeType,
		todayType,
		fnAddRandomUser,
		fnDeleteRandomuser,
		sliderSettings,
		isTodayLikeds,
	};
};
