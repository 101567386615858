import GameScene from '../GameScene';

export class SetupCamera {
	private roomTheme: any;
	constructor(private scene: GameScene, roomTheme: any) {
		this.roomTheme = roomTheme;
		// resize 이벤트에 대한 리스너 등록
		window.addEventListener('resize', this.handleResize.bind(this));
	}

	execute() {
		const camera = this.scene.cameras.main;
		camera.setBounds(0, 0, this.roomTheme.setBounds, this.roomTheme.setBounds);

		camera.startFollow(this.scene.myPlayer);

		// 초기 화면 크기에 따라 zoom 설정
		this.updateZoom();
	}

	// resize 이벤트 핸들러
	private handleResize() {
		// 화면 크기가 변경될 때마다 zoom 업데이트
		this.updateZoom();
	}

	// zoom 값을 화면 크기에 따라 업데이트하는 메서드
	private updateZoom() {
		const camera = this.scene.cameras.main;
		camera.zoom = window.innerWidth >= 1024 ? 1.5 : 1.2;
	}
}
