import RegisterHeaderView from "../common/registerHeader/views/RegisterHeaderView";
import PhotoFooterView from "./views/PhotoFooterView";
import { usePhotoService } from "./service/usePhotoService";
import React, { useEffect } from "react";
import {
    useRegisterHeaderService
} from "@dating/ui/pages/register/common/registerHeader/service/useRegisterHeaderService";
import StepProgressView from "@dating/ui/pages/register/common/stepProgress/views/StepProgressView";
import PhotoImageAddView from "@dating/ui/pages/register/photo/views/PhotoImageAddView";
import PhotoImageGuideView from "@dating/ui/pages/register/photo/views/PhotoImageGuideView";
import { useNavigate } from "react-router-dom";
import { useImgCropService } from "@dating/ui/pages/imgCrop/service/useImgCropService";

const PhotoContainer = () => {
    const {
        fnProfileUpload,
        previewURLs,
        btnActivate,
        response,
        registerSteps,
    } = usePhotoService();

    const {
        referralCodeOpen,
        setReferralCodeOpen,
        skipHandler,
        step,
    } = useRegisterHeaderService();

    const {
        updateFile,
    } = useImgCropService();

    const navigate = useNavigate();
    useEffect(() => {
        if (response) {
            navigate(registerSteps[7].url);
        }
    }, [response]);

    return (
        <div className="wrap noFooter" style={{overflowY: "auto"}}>
            <RegisterHeaderView
                referralCodeOpen={referralCodeOpen}
                setReferralCodeOpen={setReferralCodeOpen}
                skipHandler={skipHandler}
            />

            <div className="contentsWrap">
                <div className="titleWrap">
                    <StepProgressView step={step}/>
                    <h2 className="title01">사진 등록</h2>
                    <p className="titleSub01">프로필 사진 1장은 필수입니다</p>
                </div>

                <div className="content">
                    <PhotoImageAddView
                        previewURLs={previewURLs}
                        updateFile={updateFile}
                    />
                    <PhotoImageGuideView/>
                </div>
            </div>

            <PhotoFooterView
                fnProfileUpload={fnProfileUpload}
                btnActivate={btnActivate}
            />
        </div>
    );
};

export default PhotoContainer;
