import FeedEditMenuView from '@dating/ui/components/feedEdit/views/FeedEditMenuView';
import {Link} from 'react-router-dom';
import {PostInfoCOVO} from '@/site/api';
import FeedMenuView from "@dating/ui/pages/lounge/feedList/views/FeedMenuView";

interface props {
    post: PostInfoCOVO;
    menuDotClickHandler: () => void;
    setOpenReport: (openReport: boolean) => void;
    setOpenFeedHidePop: (openFeedHidePop: boolean) => void;
    deleteContent: (id: string) => void;
    openMenuPop: boolean;
    srcPrefix: string;
    setUserProfileOpen: (userProfileOpen: string) => void;
    setBlockUserUid: (blockUserUid: string) => void;
    currentUserUid: string;
    setModifyPostId: (postId: string) => void;
    setMyProfileOpen: (myProfileOpen: boolean) => void;
    fnCommonConfirmPopOn: (message: string, func: any, param: any) => any;
    setBlockPostId: (blockPostId: string) => void;
    fnFeedEditHandler: (postId: string) => void;
    feedEditPops: {};
}

const FeedProfBoxView = ({
                             post,
                             menuDotClickHandler,
                             setOpenReport,
                             setOpenFeedHidePop,
                             deleteContent,
                             openMenuPop,
                             srcPrefix,
                             setUserProfileOpen,
                             setBlockUserUid,
                             currentUserUid,
                             setModifyPostId,
                             setMyProfileOpen,
                             fnCommonConfirmPopOn,
                             setBlockPostId,
                             fnFeedEditHandler,
                             feedEditPops,
                         }: props) => {
    return (
        <>
            <div className="profileType">
                <div className="proLeft">
                    <Link
                        to="/metaverse/dating/profile"
                        onClick={() => {
                            post.postUserUid !== currentUserUid ?
                                setUserProfileOpen(post.postUserUid)
                                : setMyProfileOpen(true);
                        }}
                        state={{
                            isOtherProfile: post.postUserUid !== currentUserUid,
                        }}
                    >
                        <div className="proImg Mid">
                            <img
                                src={srcPrefix + post.postUserProfileUrl}
                                alt="테스트이미지"
                            />
                        </div>
                    </Link>

                    <div className="proName">
                        <p className="proFz20">{post.postNickName}</p>
                    </div>
                </div>
                <div className="ProRight">
                    <div className="menuDotWap">
                        <button
                            type="button"
                            className="menuDot"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!(post.isDeletable && post.isEditable)) {
                                    setBlockUserUid(post.postUserUid);
                                }
                                // menuDotClickHandler();
                                fnFeedEditHandler(post.postId);
                            }}
                        />

                        {post.isDeletable || post.isEditable ? (
                            <FeedEditMenuView
                                post={post}
                                deleteContent={deleteContent}
                                openMenuPop={feedEditPops[post.postId]}
                                setModifyPostId={setModifyPostId}
                                fnCommonConfirmPopOn={fnCommonConfirmPopOn}
                            />
                        ) : (
                            <FeedMenuView
                                openMenuPop={feedEditPops[post.postId]}
                                setOpenReport={setOpenReport}
                                setOpenFeedHidePop={setOpenFeedHidePop}
                                setBlockPostId={setBlockPostId}
                                postId={post.postId}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeedProfBoxView;
