const RoomNameBoxView = ({ handleRoomNameChange, customRoomNameResult }) => {
	return (
		<div className="roomNameBox margin-t24">
			<div className="leftRightTxt">
				<p className="fz16Fw700 padding-b8">방 이름</p>
				<span className="fz14 color-999">{customRoomNameResult}/12</span>
			</div>
			<div className="inputWrap">
				<div className="input">
					<input
						name="name"
						type="text"
						placeholder="1~12자로 입력해 주세요"
						autoFocus
						onChange={handleRoomNameChange('name')}
						maxLength={12}
					/>
				</div>
			</div>
		</div>
	);
};

export default RoomNameBoxView;
