import Peer, { MediaConnection } from 'peerjs';
import Network from '../services/Network';
import PermissionManager from './PermissionManager';
import WebRTCButtons from './WebRTCButtons';
import { replaceInvalidId } from '@/utils/util';
import { useVideoRepo } from '@/stores/useVideoRepo';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';

export default class WebRTC {
	private myPeer: Peer;
	private peers = new Map<
		string,
		{ call: MediaConnection; video: HTMLVideoElement }
	>();
	private onCalledPeers = new Map<
		string,
		{ call: MediaConnection; video: HTMLVideoElement }
	>();
	private myVideo = document.createElement('video');
	private myStream?: MediaStream;
	private network: Network;
	private webRtcButtons?: WebRTCButtons;
	private permissionManager: PermissionManager;

	constructor(userId: string, network: Network) {
		const sanitizedId = replaceInvalidId(userId);
		this.myPeer = new Peer(sanitizedId);
		this.network = network;
		this.myPeer.on('error', (err) => {
			console.log('WebRTC err.type', (err as any).type);
			console.error(err);
		});

		// 자신의 비디오 스트림 음소거 처리
		this.myVideo.muted = true;
		this.myVideo.id = sanitizedId;

		// PeerJS 설정
		this.initialize();

		this.permissionManager = new PermissionManager(this, this.network);
		this.permissionManager.checkPermissions();
		const { setAddedVideos } = useVideoRepo.getState();
		setAddedVideos(this.myVideo.id, this.myVideo);
	}

	initialize() {
		this.myPeer.on('call', (call) => {
			if (!this.onCalledPeers.has(call.peer)) {
				call.answer(this.myStream);
				const video = document.createElement('video');
				this.onCalledPeers.set(call.peer, { call, video });
				call.on('stream', (otherPlayerStream) => {
					this.addVideoStream(video, otherPlayerStream);
				});
			}
		});
	}

	getUserMedia(alertOnError = true): Promise<MediaStream> {
		const { setMediaConnected } = useMetaUserRepo.getState();
		const constraints = {
			video: true,
			audio: true,
		};

		return navigator.mediaDevices
			.enumerateDevices()
			.then((devices) => {
				return navigator.mediaDevices.getUserMedia(constraints);
			})
			.then((stream) => {
				const { setMyVideoStream } = useVideoRepo.getState();
				setMyVideoStream(stream);
				this.myStream = stream;

				if (this.myVideo) {
					this.myVideo.srcObject = stream;
					this.myVideo.addEventListener('loadedmetadata', () => {
						this.myVideo.play();
						this.myVideo.style.transform = 'scaleX(-1)';
					});
				}
				this.webRtcButtons = new WebRTCButtons(this.myStream);
				setMediaConnected(true);
				this.network.mediaConnected();
				return stream;
			})
			.catch((error) => {
				if (alertOnError) {
					window.alert(
						'카메라 전원이 켜져 있으며, 컴퓨터에 연결되어 있는지 확인해주세요.',
					);
				}
				throw error;
			});
	}

	// peer에 연결
	connectToNewUser(userId: string) {
		if (this.myStream) {
			const sanitizedId = replaceInvalidId(userId);
			if (!this.peers.has(sanitizedId)) {
				console.log('calling', sanitizedId);
				const call = this.myPeer.call(sanitizedId, this.myStream);
				const video = document.createElement('video');
				video.id = sanitizedId;

				this.peers.set(sanitizedId, {
					call,
					video,
				});
				call.on('stream', (otherPlayerStream) => {
					this.addVideoStream(video, otherPlayerStream);
				});
			}
		}
	}

	// 새로운 비디오 스트림을 videoGrid 요소에 추가
	addVideoStream(video: HTMLVideoElement, stream: MediaStream) {
		const { addedVideos, setAddedVideos } = useVideoRepo.getState();
		if (!addedVideos?.has(video.id)) {
			if (stream) {
				if (this.myVideo === video) {
					this.myVideo.srcObject = stream;
					this.myVideo.addEventListener('loadedmetadata', () => {
						this.myVideo.play();
						this.myVideo.style.transform = 'scaleX(-1)';
					});
				} else {
					video.srcObject = stream;
					video.addEventListener('loadedmetadata', () => {
						video.play();
						video.style.transform = 'scaleX(-1)';
					});
				}
			}
			setAddedVideos(video.id, video);
		}
	}

	// 비디오 스트림을 제거(호스트인 경우)
	deleteVideoStream(userId: string) {
		const { setDeletedVideos } = useVideoRepo.getState();
		const sanitizedId = replaceInvalidId(userId);
		if (this.peers.has(sanitizedId)) {
			const peer = this.peers.get(sanitizedId);
			peer?.call.close();
			const video = peer?.video;
			setDeletedVideos(video!.id);
			peer?.video.remove();
			this.peers.delete(sanitizedId);
		}
	}

	// 비디오 스트림을 제거(게스트인 경우)
	deleteOnCalledVideoStream(userId: string) {
		const { setDeletedVideos } = useVideoRepo.getState();
		const sanitizedId = replaceInvalidId(userId);
		if (this.onCalledPeers.has(sanitizedId)) {
			const onCalledPeer = this.onCalledPeers.get(sanitizedId);
			onCalledPeer?.call.close();
			const video = onCalledPeer?.video;
			setDeletedVideos(video!.id);
			onCalledPeer?.video.remove();
			this.onCalledPeers.delete(sanitizedId);
		}
	}

	cleanup() {
		const { setMediaConnected } = useMetaUserRepo.getState();
		const { setDeletedVideos, setMyVideoStream } = useVideoRepo.getState();
		// 모든 피어 연결 종료
		this.peers.forEach((peer, key) => {
			peer.call.close();
			peer.video.remove();
			this.peers.delete(key);
		});

		this.onCalledPeers.forEach((peer, key) => {
			peer.call.close();
			peer.video.remove();
			this.onCalledPeers.delete(key);
		});

		// 내 비디오 스트림 정지
		if (this.myStream) {
			this.myStream.getTracks().forEach((track) => track.stop());
			this.myStream = undefined;
		}

		// 내 비디오 요소 제거
		if (this.myVideo) {
			this.myVideo.remove();
			setDeletedVideos(this.myVideo.id);
		}

		// PeerJS 연결 종료
		if (this.myPeer) {
			this.myPeer.disconnect();
			this.myPeer.destroy();
		}

		// WebRTCButtons 정리
		if (this.webRtcButtons) {
			this.webRtcButtons.cleanup();
			this.webRtcButtons = undefined;
		}

		// Redux 스토어 상태 초기화
		setMediaConnected(false);
		setMyVideoStream(null);
	}
}
