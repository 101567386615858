import RegisterHeaderView from "../common/registerHeader/views/RegisterHeaderView";
import LocationInfoUnableFooterView from "./views/LocationInfoUnableFooterView";
import useLocationInfoService from "@dating/ui/pages/register/locationInfo/service/useLocationInfoService";
import React from "react";
import {useRegisterHeaderService} from "@dating/ui/pages/register/common/registerHeader/service/useRegisterHeaderService";
import StepProgressView from "@dating/ui/pages/register/common/stepProgress/views/StepProgressView";


// 에러화면 - 위치정보 못받아왔을때 여기로 토스
const LocationInfoUnableContainer = () => {

    const {
        fnSaveUserLocation,
    } = useLocationInfoService();

    const {
        referralCodeOpen,
        setReferralCodeOpen,
        skipHandler,
		step,
    } = useRegisterHeaderService();

    return (
        <div className="wrap noFooter">
            <RegisterHeaderView
                referralCodeOpen={referralCodeOpen}
                setReferralCodeOpen={setReferralCodeOpen}
                skipHandler={skipHandler}
            />

            <div className="contentsWrap hiddenCon">
                <div className="titleWrap padding-b0">
                    <StepProgressView step={step}/>
                    <h2 className="title01">연결할 수 없습니다</h2>
                    <p className="titleSub01">
                        위치 정보에 엑세스할 수 있어야 내 주변 사람들을 추천받을 수 있어요
                    </p>
                </div>

                <div className="myTypeNone text-c">
                    <img
                        src="/assets/img/common/ico-my-locate.png"
                        width="140"
                        alt=""
                    ></img>
                </div>
            </div>

            <LocationInfoUnableFooterView
                fnSaveUserLocation={fnSaveUserLocation}
            />
        </div>
    );
};

export default LocationInfoUnableContainer;
