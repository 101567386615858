const IdealSettingEditHeaderView = () => {
	return (
		<header className="h-fixed">
			<div className="headerWrap">
				<div className="h-left"></div>
				<div className="h-center">
					<p className="title02">이상형 설정</p>
				</div>
				<div className="h-right"></div>
			</div>
		</header>
	);
};

export default IdealSettingEditHeaderView;
