import { Room } from 'colyseus.js';
import { MapSchema } from '@colyseus/schema';
import { StoreService } from '../StoreService';
import {
	IDirectMessage,
	IDirectMessageState,
	IDMRoom,
	IUser,
} from '../../../../types/IDirectMessageState';
import { Message } from '../../../../types/Messages';
import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';

export class DirectMessageEventSetup {
	constructor(
		private dmRoom: Room<IDirectMessageState>,
		private storeService: StoreService,
	) {}

	setup() {
		this.setupDirectMessage();
	}

	private setupDirectMessage() {
		// 방 목록 수신 핸들러
		this.dmRoom?.onMessage(Message.GET_DM_ROOMS, (dmRooms: IDMRoom[]) => {
			const myUUID = useDatingRepo.getState().myProfile.userUid;
			dmRooms.forEach(async (room) => {
				// MapSchema 초기화
				const messagesSchema = new MapSchema<IDirectMessage>();

				// 기존 메시지가 배열이면 MapSchema에 넣기
				if (Array.isArray(room.messages)) {
					room.messages.forEach((messageData: any) => {
						if (messageData.createdAt) {
							// IDirectMessage 형태로 변환
							const message: IDirectMessage = {
								...messageData,
								sender: {
									uuid: messageData.sender.uuid || '',
									name: messageData.sender.name || '',
									profileUrl: messageData.sender.profileUrl || '',
									location: messageData.sender.location || '',
									age: messageData.sender.age || '',
								} as IUser,
								receiver: {
									uuid: messageData.receiver.uuid || '',
									name: messageData.receiver.name || '',
									profileUrl: messageData.receiver.profileUrl || '',
									location: messageData.receiver.location || '',
									age: messageData.receiver.age || '',
								} as IUser,
							} as IDirectMessage;

							messagesSchema.set(messageData.createdAt, message);
						}
					});
				}

				// 새로운 MapSchema 할당
				room.messages = messagesSchema;

				this.setupMessageListeners(room);
				this.updateRoomMessages(room);

				// 상대방 세션 체크
				this.dmRoom?.send(Message.CHECK_USER_SESSION, {
					targetUuid: room.user1Id === myUUID ? room.user2Id : room.user1Id,
				});
			});

			useDirectMessageRepo.getState().setDirectMessageRoomList(dmRooms);
			const groupedMessages = dmRooms.reduce((acc, room) => {
				acc[room.roomId] = room;
				return acc;
			}, {} as Record<string, IDMRoom>);

			useDirectMessageRepo.getState().setGroupedMessages(groupedMessages);
		});

		this.dmRoom?.state.dmRooms.onAdd((dmRoom: IDMRoom, key: string) => {
			const myUUID = useDatingRepo.getState().myProfile.userUid;
			if (dmRoom.user1Id === myUUID || dmRoom.user2Id === myUUID) {
				// 메시지 변경 감지 설정
				this.setupMessageListeners(dmRoom);
				// Room 정보 store 업데이트
				this.storeService.addDirectMessageRoom(dmRoom);
				// 최초 메시지 상태 저장
				this.updateRoomMessages(dmRoom);
			}
		});

		// Room 정보 변경 시
		this.dmRoom?.state.dmRooms.onChange((dmRoom: IDMRoom, key: string) => {
			const myUUID = useDatingRepo.getState().myProfile.userUid;
			if (dmRoom.user1Id === myUUID || dmRoom.user2Id === myUUID) {
				// 메시지 변경 감지 설정
				this.setupMessageListeners(dmRoom);
				// Room 정보 store 업데이트
				this.storeService.updateDirectMessageRoom(dmRoom);
				// 메시지 상태 업데이트
				this.updateRoomMessages(dmRoom);
			}
		});

		// Room 생성 완료
		this.dmRoom?.onMessage(
			Message.CREATE_DM_ROOM,
			(response: {
				currentRoomId: string;
				relatedRoomIds: string[];
				room?: IDMRoom;
			}) => {
				const room =
					response.room ||
					this.dmRoom?.state.dmRooms.get(response.currentRoomId);
				const myUUID = useDatingRepo.getState().myProfile.userUid;

				if (room) {
					if (!room.messages || !(room.messages instanceof MapSchema)) {
						room.messages = new MapSchema<IDirectMessage>();
					}

					if (room.user1Id === myUUID || room.user2Id === myUUID) {
						this.updateRoomMessages(room);
						useDirectMessageRepo
							.getState()
							.setCurrentRoom(response.currentRoomId);
						useDirectMessageRepo
							.getState()
							.setRelatedRooms(response.relatedRoomIds);
					}
				}
				reactEventEmitter.emit('react-created-dm-room');
			},
		);
		// 메시지 전송
		this.dmRoom?.onMessage(
			Message.SEND_DIRECT_MESSAGE,
			(message: IDirectMessage) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room && !this.isMessageExists(room, message)) {
					if (!room.messages) {
						room.messages = new MapSchema<IDirectMessage>();
					}
					// 상태 업데이트는 한 번만 수행
					this.updateRoomMessages(room);
				}
			},
		);

		// 메시지 수신 처리
		this.dmRoom?.onMessage(
			Message.RECEIVE_DIRECT_MESSAGE,
			(message: IDirectMessage) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room && !this.isMessageExists(room, message)) {
					// 상태 업데이트는 한 번만 수행
					this.updateRoomMessages(room);
				}
			},
		);

		// 읽지 않은 메시지 수 업데이트 핸들러 추가
		this.dmRoom?.onMessage(
			Message.REQUEST_UNREAD_COUNT,
			(message: { roomId: string; count: number }) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room) {
					room.unreadCount = message.count;
					this.updateRoomMessages(room);
				}
			},
		);

		// 채팅방 나가기
		this.dmRoom?.onMessage(
			Message.LEAVE_DIRECT_MESSAGE,
			(message: { roomId: string; leaverId: string }) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room) {
					const isUser1 = room.user1Id === message.leaverId;
					room.visibleTo = isUser1 ? 1 : 2;
					this.updateRoomMessages(room);
				}
			},
		);

		// 채팅방 퇴장
		this.dmRoom?.onMessage(
			Message.EXIT_DIRECT_MESSAGE,
			(message: { roomId: string; exit: boolean }) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room) {
					room.visibleTo = -1;
					this.updateRoomMessages(room);
				}
			},
		);

		// 결제 관련
		this.dmRoom?.onMessage(
			Message.SINGLE_PAYMENT_COMPLETED,
			(message: { roomId: string; payerId: string }) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room) {
					const isUser1 = room.user1Id === message.payerId;
					room.billingStatus = isUser1 ? 1 : 2;
					this.updateRoomMessages(room);
				}
			},
		);

		// 쌍방 결제 완료
		this.dmRoom?.onMessage(
			Message.MUTUAL_PAYMENT_COMPLETED,
			(message: { roomId: string; paid: boolean }) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room) {
					room.billingStatus = 3;
					this.updateRoomMessages(room);
				}
			},
		);

		// 방 타입 변경
		this.dmRoom?.onMessage(
			Message.CHANGE_DM_ROOM_TYPE,
			(message: { roomId: string; dmRoomType: string }) => {
				const room = this.dmRoom?.state.dmRooms.get(message.roomId);
				if (room) {
					room.dmRoomType = message.dmRoomType;
					this.updateRoomMessages(room);
				}
			},
		);

		// 상대방이 현재 접속 중인지 여부
		this.dmRoom?.onMessage(
			Message.USER_SESSION_STATUS,
			(message: { targetUuid: string; isOnline: boolean }) => {
				useDirectMessageRepo.getState().setUserSessionStatus(message);
			},
		);
	}

	private setupMessageListeners(dmRoom: IDMRoom) {
		dmRoom.messages.onAdd((message: IDirectMessage, key: string) => {
			this.updateRoomMessages(dmRoom);
		});

		dmRoom.messages.onChange((message: IDirectMessage, key: string) => {
			this.updateRoomMessages(dmRoom);
		});

		dmRoom.messages.onRemove((message: IDirectMessage, key: string) => {
			this.updateRoomMessages(dmRoom);
		});
	}

	private isMessageExists(room: IDMRoom, newMessage: IDirectMessage): boolean {
		return Array.from(room.messages.values()).some(
			(existingMessage) =>
				existingMessage.messageId === newMessage.messageId ||
				(existingMessage.createdAt === newMessage.createdAt &&
					existingMessage.content === newMessage.content &&
					existingMessage.sender?.uuid === newMessage.sender?.uuid),
		);
	}

	private updateRoomMessages(room: IDMRoom) {
		const currentGroupedMessages =
			useDirectMessageRepo.getState().groupedMessages;
		const currentRoomList =
			useDirectMessageRepo.getState().directMessageRoomList;
		// 기존 room 객체 유지
		const updatedRoom = room;

		// 메시지들을 UI 표시용 배열로 변환
		const messagesArray = Array.from(updatedRoom.messages.values())
			.sort((a, b) => parseInt(a.createdAt) - parseInt(b.createdAt))
			.map((message) => ({
				...message,
				sender: message.sender || {},
				receiver: message.receiver || {},
				messageId: message.messageId || Date.now(),
			}));

		// 그룹 메시지 업데이트 - Schema 인스턴스는 그대로 유지하고 UI용 데이터만 변환
		const updatedGroupedMessages = {
			...currentGroupedMessages,
			[room.roomId]: {
				...room,
				messages: messagesArray, // UI 표시용 배열
				billingStatus: room.billingStatus,
				visibleTo: room.visibleTo,
				dmRoomType: room.dmRoomType,
				unreadCount: room.unreadCount,
				createdAt: room.createdAt,
			},
		};

		useDirectMessageRepo.getState().setGroupedMessages(updatedGroupedMessages);

		// 방 목록 업데이트
		const existingRoomIndex = currentRoomList.findIndex(
			(existingRoom) => existingRoom.roomId === room.roomId,
		);

		let updatedRoomList;
		if (existingRoomIndex === -1) {
			updatedRoomList = [...currentRoomList, updatedRoom];
		} else {
			updatedRoomList = [...currentRoomList];
			updatedRoomList[existingRoomIndex] = updatedRoom;
		}

		// 중복 제거
		updatedRoomList = updatedRoomList.filter(
			(room, index, self) =>
				index === self.findIndex((r) => r.roomId === room.roomId),
		);

		useDirectMessageRepo.getState().setDirectMessageRoomList(updatedRoomList);
	}
}
