import FriendWrapView from './views/FriendWrapView';
import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';

const FriendInviteEventContainer = () => {
	const title = '친구 초대 이벤트';
	return (
		<>
			<div className="rightShow active">
				<ButtonHeaderView title={title} right={false} />
				<FriendWrapView />
			</div>
		</>
	);
};

export default FriendInviteEventContainer;
