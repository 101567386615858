import { usePhoneVerificationService } from './service/usePhoneVerificationService';
import PhoneVerificationInputView from '@dating/ui/pages/register/phoneVerification/views/PhoneVerificationInputView';
import PhoneVerificationTimeView from '@dating/ui/pages/register/phoneVerification/views/PhoneVerificationTimeView';
import PhoneVerificationFooterView from '@dating/ui/pages/register/phoneVerification/views/PhoneVerificationFooterView';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useCommonAlertPopService } from '@dating/ui/components/commonAlertPop/service/useCommonAlertPopService';

const PhoneVerificationContainer = () => {
	const {
		inputValues,
		formattedTime,
		occupancyFooterViewStyle,
		handleInputKeyDown,
		fnVerifyVerificationCode,
		activeIndex,
		fnSendVerificationCode,
		resetRegisterInfo,
		phoneVeriFailed,
		phoneVeriFailedCnt,
		inputTimeout,
		notValidCode,
		setNotValidCode,
		codeResend,
		setCodeResend,
		phoneNumber,
	} = usePhoneVerificationService();

	const { fnCommonAlertPopOn } = useCommonAlertPopService();

	const navigate = useNavigate();

	useEffect(() => {
		if (phoneVeriFailed || phoneVeriFailedCnt > 4) {
			fnCommonAlertPopOn('전화번호를 다시 인증해 주세요');
			resetRegisterInfo();
			navigate('/metaverse/dating/register/phone');
		}
	}, [phoneVeriFailed, phoneVeriFailedCnt]);

	useEffect(() => {
		if (inputTimeout) {
			fnCommonAlertPopOn(
				'입력시간이 초과되었습니다\r\n인증을 다시 진행해 주세요',
			);
			resetRegisterInfo();
			navigate('/metaverse/dating/register/phone');
		}
	}, [inputTimeout]);

	useEffect(() => {
		if (notValidCode && phoneVeriFailedCnt < 5) {
			fnCommonAlertPopOn('인증번호를 다시 확인해 주세요');
			setNotValidCode(false);
		}
	}, [notValidCode]);

	useEffect(() => {
		if (codeResend) {
			fnCommonAlertPopOn('인증번호가 다시 발송되었습니다');
			setCodeResend(false);
		}
	}, [codeResend]);

	return (
		<div className="wrap noFooter">
			<header className="h-fixed">
				<div className="headerWrap">
					<div className="h-left">
						<Link
							to={'/metaverse/dating/register/phone'}
							onClick={() => {
								resetRegisterInfo();
							}}
						>
							<button className="btnBack" type="button" />
						</Link>
					</div>
					<div className="h-center"></div>
				</div>
			</header>
			<div className="contentsWrap">
				<div className="titleWrap">
					<h2 className="title01">인증번호 입력</h2>
					<p className="titleSub01">전송된 인증번호를 입력해 주세요</p>
				</div>
				<div className="content">
					<div className="inputWrap">
						<PhoneVerificationInputView
							inputValues={inputValues}
							handleInputKeyDown={handleInputKeyDown}
							activeIndex={activeIndex}
						/>
						<PhoneVerificationTimeView formattedTime={formattedTime} />
					</div>
				</div>
			</div>
			<PhoneVerificationFooterView
				fnVerifyVerificationCode={fnVerifyVerificationCode}
				occupancyFooterViewStyle={occupancyFooterViewStyle}
				fnSendVerificationCode={fnSendVerificationCode}
			/>
		</div>
	);
};

export default PhoneVerificationContainer;
