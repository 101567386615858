import {useRef} from "react";

interface props {
    previewURLs: string[];
    updateFile: (index: number, file: File) => void;
    postId: string | undefined;
    srcPrefix: string;
};
const FeedFormImgView = ({
                             previewURLs,
                             updateFile,
                             postId,
                             srcPrefix,
                         }: props) => {

    const imgRefs = [
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
    ];

    return (
        <div className="imgAddBox">
            {previewURLs[0] ? (
                <div onClick={() => {
                    imgRefs[0].current?.click();
                }}>
                    <label htmlFor="img0"></label>
                    <img
                        src={previewURLs[0]}
                        alt="preview-img"
                        className="img0"
                        style={{width: "100%", height: "100%", objectFit: "cover"}}
                    />
                    <input
                        type="file"
                        id="img0"
                        ref={imgRefs[0]}
                        onChange={(e) => {
                            e.preventDefault();
                            const currentRef = imgRefs[0]?.current;
                            if (currentRef && currentRef.files) {
                                const file = currentRef.files[0];
                                updateFile(0, file);
                            }

                            e.target.value = "";
                        }}
                        style={{display: "none"}}
                    />
                </div>
            ) : (
                <>
                    <div>
                        <label htmlFor="img0"></label>
                        <input
                            type="file"
                            id="img0"
                            ref={imgRefs[0]}
                            onChange={(e) => {
                                e.preventDefault();
                                const currentRef = imgRefs[0]?.current;
                                if (currentRef && currentRef.files) {
                                    const file = currentRef.files[0];
                                    updateFile(0, file);
                                }

                                e.target.value = "";
                            }}
                            style={{display: "none"}}
                        />
                        <span className="fz12">필수</span>
                    </div>
                </>
            )}

            {previewURLs[1] ? (
                <div onClick={() => {
                    imgRefs[1].current?.click();
                }}>
                    <label htmlFor="img1"></label>
                    <img
                        src={previewURLs[1]}
                        alt="preview-img"
                        className="img1"
                        style={{width: "100%", height: "100%", objectFit: "cover"}}
                    />
                    <input
                        type="file"
                        id="img1"
                        ref={imgRefs[1]}
                        onChange={(e) => {
                            e.preventDefault();
                            const currentRef = imgRefs[1]?.current;
                            if (currentRef && currentRef.files) {
                                const file = currentRef.files[0];
                                updateFile(1, file);
                            }

                            e.target.value = "";
                        }}
                        style={{display: "none"}}
                    />
                </div>
            ) : (
                <>
                    <div>
                        <label htmlFor="img1"></label>
                        <input
                            type="file"
                            id="img1"
                            ref={imgRefs[1]}
                            onChange={(e) => {
                                e.preventDefault();
                                const currentRef = imgRefs[1]?.current;
                                if (currentRef && currentRef.files) {
                                    const file = currentRef.files[0];
                                    updateFile(1, file);
                                }

                                e.target.value = "";
                            }}
                            style={{display: "none"}}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default FeedFormImgView;
