import useImgCropRepo from "@dating/repository/imgCrop/useImgCropRepo";
import useLoungeRepo from "@dating/repository/lounge/useLoungeRepo";

export const useImgCropService = () => {
    const {
        imgCropFile,
        setImgCropFile,
        showImgCrop,
        setShowImgCrop,
        imgIndex,
        previewURLs,
        setImgIndex,
        setPreviewURLs,
        cropFiles,
        setCropFiles,
        clearCropFiles,
    } = useImgCropRepo();

    const {
        feedForm,
        setNewFeedForm,
        setSaveBtnDisabled,
        saveBtnDisabled,
    } = useLoungeRepo();

    /*
     * updateFile
     * 이미지 크롭 결과 repo에 저장 및 state 변경
     */
    const updateFile = (index: number, file: File) => {
        if (file) {
            if (file.type.includes("image")) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    setImgCropFile(reader.result as string);
                    setImgIndex(index);
                    setShowImgCrop(true); //크롭창열기
                };
                return;
            }
            alert("이미지 파일만 업로드 가능합니다.");
        }
    };

    /*
    * setCropData
    * 완료 버튼 클릭, 크롭 데이터 스토어에 set
    */
    const setCropData = (fileURL: string, file: Blob) => {
        if (file) {
            let newFiles: Blob[] = cropFiles;
            newFiles.splice(imgIndex, 1, file);
            let newUrls = previewURLs;
            newUrls.splice(imgIndex, 1, fileURL);
            setPreviewURLs(newUrls); //미리보기 url set
            setImgCropFile(""); //크롭 완료 후 초기화

            if (feedForm.postId != undefined) {
                setNewFeedForm({files: newFiles});
            }

            setCropFiles(newFiles);
            setShowImgCrop(false);
            setSaveBtnDisabled(false);
        }
    };

    return {
        setShowImgCrop,
        showImgCrop,
        imgCropFile,
        setCropData,
        previewURLs,
        imgIndex,
        setImgIndex,
        setPreviewURLs,
        saveBtnDisabled,
        setSaveBtnDisabled,
        updateFile,
        clearCropFiles,
        cropFiles,
    };
};
