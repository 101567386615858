interface props {
    nickName: string
}
const ProfileFeedHeader = ({
                               nickName,
                           }: props) => {
  return (
      <div className="text-c margin-t40 padding-b16">
          <p className="fz18 bold">{nickName}님의 게시글</p>
          <p className="color-666">허쉬 라운지에 올린 게시글 입니다</p>
      </div>
  )
};

export default ProfileFeedHeader;