import { ItemType } from '../../types/Item';
import ItemAbst, { ItemConfig } from '../ItemAbst';

export default class Chair extends ItemAbst {
	chairId?: string;
	currentUsers = new Set<string>();
	itemDirection?: string;

	constructor(param: ItemConfig) {
		super(param);
	}

	override readonly itemType = ItemType.CHAIR;

	private updateState() {
		if (!this.currentUsers) return;
		this.clearStatusBox();
	}

	addCurrentUser(userId: string) {
		if (!this.currentUsers || this.currentUsers.has(userId)) return;
		this.currentUsers.add(userId);
		this.updateState();
	}

	removeCurrentUser(userId: string) {
		if (!this.currentUsers || !this.currentUsers.has(userId)) return;
		this.currentUsers.delete(userId);
		this.updateState();
	}

	override onOverlapDialog() {
		const starImage = this.scene.add.image(0, 0, 'star').setScale(0.5);
		const message =
			window.innerWidth >= 1024 ? 'F를 눌러 앉기' : [starImage, '를 눌러 앉기'];
		this.setDialogBox(message);
	}
}
