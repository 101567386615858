const RoomPwdInputWrapView = ({ roomPwdResult, handleRoomPwdChange }) => {
	return (
		<div className="roomNameBox margin-t10">
			<div className="leftRightTxt">
				<p className="fz12 color-999">비밀번호</p>
				<span className="fz12 color-999">{roomPwdResult}/32</span>
			</div>
			<div className="inputWrap margin-t4">
				<div className="input">
					<input
						name="password"
						type="text"
						onChange={handleRoomPwdChange('password')}
						maxLength={32}
						placeholder="입장 비밀번호를 입력해 주세요"
					/>
				</div>
			</div>
		</div>
	);
};

export default RoomPwdInputWrapView;
