export class AssetLoader {
	private scene: Phaser.Scene;
	private roomTheme: any;

	constructor(scene: Phaser.Scene, roomTheme: any) {
		this.scene = scene;
		this.roomTheme = roomTheme;
	}

	preload() {
		this.scene.load.image('map_back', this.roomTheme.mapBackground);
		this.scene.load.tilemapTiledJSON('tilemap', this.roomTheme.tileMap);
		this.scene.load.spritesheet('wall', this.roomTheme.wall, {
			frameWidth: 32,
			frameHeight: 32,
		});
		this.scene.load.image('star', '/assets/actionBtn/ico-star_r.png');
	}

	async loadAssets() {
		// 기존 캐시 삭제
		this.scene.textures.remove('map_back');
		this.scene.cache.tilemap.remove('tilemap');
		this.scene.textures.remove('wall');

		// 새로운 자산 로드
		await Promise.all([
			this.scene.load.image('map_back', this.roomTheme.mapBackground),
			this.scene.load.tilemapTiledJSON('tilemap', this.roomTheme.tileMap),
			this.scene.load.spritesheet('wall', this.roomTheme.wall, {
				frameWidth: 32,
				frameHeight: 32,
			}),
		]);

		// 로딩 시작
		this.scene.load.start();

		// 로딩 완료 대기
		await new Promise<void>((resolve) => {
			this.scene.load.on('complete', resolve);
		});

		// 맵 이미지 추가
		this.scene.add.image(0, 0, 'map_back').setOrigin(0, 0);
	}
}
