import { useMetaverse } from '@/MetaverseProvider';
import { MetaRoomChatCIVO } from '@/site/api';
import { useChatRepo } from '@/stores/useChatRepo';
import { useRoomRepo } from '@/stores/useRoomRepo';
import { useMetaRoomAdapter } from '@dating/adapter/meta/useMetaRoomAdapter';
import { useCallback } from 'react';

export const useChatService = () => {
	const {
		focused,
		showChat,
		chatMessages,
		chatActive,
		mobileChatInputClick,
		isInputFilled,
		chatInputValue,
		setShowChat,
		setMobileChatInputClick,
		setFocused,
		setIsInputFilled,
		setChatInputValue,
	} = useChatRepo();
	const { isInMetaverseRoot } = useMetaverse();
	const { joinedRoomData } = useRoomRepo();
	const { saveRoomChat } = useMetaRoomAdapter();
	const handleSendChat = useCallback(
		async (content: string) => {
			try {
				if (joinedRoomData?.id) {
					const param: MetaRoomChatCIVO = {
						roomId: joinedRoomData.id,
						content,
					};
					saveRoomChat(param);
				}
			} catch (error) {
				console.error('Error in handleSendChat:', error); // 에러 로그
			}
		},
		[joinedRoomData, saveRoomChat],
	);
	const handleCloseChat = () => {
		setShowChat(false);
	};
	return {
		focused,
		showChat,
		chatMessages,
		chatActive,
		mobileChatInputClick,
		isInputFilled,
		chatInputValue,
		isInMetaverseRoot,
		handleSendChat,
		setShowChat,
		handleCloseChat,
		setMobileChatInputClick,
		setFocused,
		setIsInputFilled,
		setChatInputValue,
	};
};
