import {EditType} from "@dating/repository/profile/useEditProfileRepo";

interface props {
    focusItem: { label: string, value: string | string[] };
    popOnHandler: (id: string) => void;
    closeEditPopup: (key: string) => void;
    lifeStyleList: string[] | { id: string, styleValue: string }[];
    lifeStyleValue: { id: string, styleValue: string }[];
    customLifeStyleList: { id: string, styleValue: string }[];
    customLifeStyleValue: string[] | { id: string, state: boolean }[];
    lifeStyleValueHandler: (id: string) => void;
    customLifeStyleHandler: (id: string) => void;
    fnModifyProfileLifeStyle: () => void;
}

const EditLifeStylePopView = ({
                                  focusItem,
                                  popOnHandler,
                                  closeEditPopup,
                                  lifeStyleList,
                                  lifeStyleValue,
                                  customLifeStyleList,
                                  customLifeStyleValue,
                                  lifeStyleValueHandler,
                                  customLifeStyleHandler,
                                  fnModifyProfileLifeStyle,
                              }: props) => {
    return (
        <div className="popWrap editProfilePop">
            <div className="popupInfo padding-b16">
                <div className="frameWrap">
                    <div className="cateTitBox">
                        <span className="cateTit01 life">라이프 스타일</span>
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    className="subAddBtn popOnBtn"*/}
                        {/*    onClick={() => {*/}
                        {/*        popOnHandler("lifeStyle");*/}
                        {/*    }}>*/}
                        {/*    나만의 항목 추가*/}
                        {/*</button>*/}
                    </div>
                    <div className="myCateList">
                        {
                            lifeStyleList.length > 0 ?
                                lifeStyleList.map((item) => {
                                    return (
                                        <button
                                            key={item.id + item.styleValue}
                                            type="button"
                                            className={
                                                lifeStyleValue[item.id] ? "active" : ""
                                            }
                                            onClick={() => {
                                                lifeStyleValueHandler(item.id);
                                            }}
                                        >
                                            {item.styleValue}
                                        </button>
                                    );
                                })
                                : null
                        }

                        {
                            customLifeStyleList.length > 0 ?
                                customLifeStyleList?.map((item) => {
                                    return (
                                        <button
                                            key={item.id}
                                            type="button"
                                            className={
                                                customLifeStyleValue[item.id] ? "active" : ""
                                            }
                                            onClick={() => {
                                                customLifeStyleHandler(item.id);
                                            }}
                                        >
                                            {item.styleValue}
                                        </button>
                                    );
                                })
                                : null
                        }
                    </div>
                </div>

                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnCCC"
                        onClick={() => {
                            closeEditPopup(focusItem.label);
                        }}
                    >
                        <span>취소</span>
                    </button>
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
                            fnModifyProfileLifeStyle();
                        }}
                    >
                        <span>확 인</span>
                    </button>
                </div>
            </div>
        </div>
    )
};

export default EditLifeStylePopView;