import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import {usePaymentRepo} from '@dating/stores/PaymentStore';
import { useFooterRepo } from '@dating/repository/dating/useFooterRepo';
import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { useMessageAdapter } from '@dating/adapter/message/useMessageAdapter';
import { CreateMessageRoomCIVO } from '@/site/api';
import { useNavigate } from 'react-router-dom';
import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { useEffect } from 'react';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import useApiDirectMessageRepo from '@dating/repository/message/useApiDirectMessageRepo';

export const useLikeMatchingService = () => {
	const { createMessageRoomId, createMessageRoom, isMessageRoom, saveMessage } =
		useMessageAdapter();
	const { createdDMRoomId, setCreatedDMRoomId, setIsExistingRoom } =
		useApiDirectMessageRepo();
	const {
		matchedItem,
		matchingSuccess,
		messageTargetUser,
		setMatchingSuccess,
	} = usePaymentRepo();

	const { setShowDirectMessageList } = useDirectMessageRepo();

	const { setHomeOpen, setLoungeOpen, setLikeOpen, setMetaOpen } =
		useFooterRepo();
	const { directMessageRoomList } = useDirectMessageRepo();

	const { srcPrefix } = useDatingRepo();
	const navigate = useNavigate();
	const { myProfile, userProfile, setUserProfile } = useDatingRepo();
	const { getUserProfile } = useProfileAdapter();

	useEffect(() => {
		if (messageTargetUser != '') {
			getUserProfile(messageTargetUser).then((value) => {
				setUserProfile(value);
			});
		}
	}, [messageTargetUser]);

	useEffect(() => {
		const getMessageRoomId = async () => {
			try {
				const data = createMessageRoomId();
				if (data) {
					setCreatedDMRoomId(await data);
				}
			} catch (err) {
				console.error('Failed to create message room ID:', err);
			}
		};
		if (matchingSuccess) {
			getMessageRoomId();
		}
	}, [matchingSuccess]);

	useEffect(() => {
		const getIsMessageRoom = async () => {
			try {
				const toUserUid: string = userProfile.userUid;
				const userUid: string = myProfile.userUid;
				const data = await isMessageRoom(toUserUid, userUid);
				if (data) {
					setIsExistingRoom(data);
				}
			} catch (error) {
				console.error('방 존재 여부 확인 실패:', error);
			}
		};

		if (matchingSuccess && userProfile?.userUid && myProfile.userUid) {
			getIsMessageRoom();
		}
	}, [matchingSuccess, userProfile?.userUid, myProfile?.userUid]);

	// 방 존재 여부 체크 및 적절한 roomId 반환
	const checkAndGetRoomId = async (matchedRoomId: string) => {
		// 기존 방이 있는지 확인
		const existingRoom = Object.values(directMessageRoomList).find(
			(room) =>
				(room.user1Id === myProfile.userUid &&
					room.user2Id === userProfile.userUid) ||
				(room.user1Id === userProfile.userUid &&
					room.user2Id === myProfile.userUid),
		);

		if (existingRoom) {
			// 방이 존재하고 visibleTo가 1, 2, -1인 경우
			if (
				existingRoom.visibleTo === 1 ||
				existingRoom.visibleTo === 2 ||
				existingRoom.visibleTo === -1
			) {
				try {
					// 새로운 방 ID 생성
					const newRoomIdData = await createMessageRoomId();
					if (newRoomIdData) {
						setCreatedDMRoomId(newRoomIdData);
						return newRoomIdData.roomId;
					}
				} catch (err) {
					console.error('새 방 ID 생성 실패:', err);
					return matchedRoomId;
				}
			}
			return existingRoom.roomId;
		}

		return matchedRoomId; // 기존 방이 없으면 매칭된 roomId 사용
	};

	const fnOpenMessageRoom = () => {
		setHomeOpen(false);
		setLoungeOpen(false);
		setLikeOpen(false);
		setShowDirectMessageList(true);
		setMetaOpen(false);
		setMatchingSuccess(false);
	};

	const createNavigationState = () => {
		const user1Info = {
			uuid: myProfile.userUid,
			name: myProfile.nickName,
			profileUrl: myProfile.profileUrl[0],
			location: myProfile.location,
			age: myProfile.age,
		};

		const user2Info = {
			uuid: userProfile.userUid,
			name: userProfile.nickName,
			profileUrl: userProfile.profileUrl[0],
			location: userProfile.location,
			age: userProfile.age,
		};

		return {
			billingStatus: 0,
			dmRoomType: 'LIKE',
			roomId: matchedItem.roomId,
			visibleTo: 0,
			isUserLeft: false,
			isOtherUserLeft: false,
			user1Info: user1Info,
			user2Info: user2Info,
			otherUserInfo: user2Info,
			createdAt: new Date().toString(),
		};
	};

	const createMatingDMRoom = (roomId: string) => {
		const navigationState = createNavigationState();
		const roomData = {
			billingStatus: navigationState.billingStatus,
			dmRoomType: navigationState.dmRoomType,
			roomId: roomId,
			user1Id: navigationState.user1Info.uuid,
			user2Id: navigationState.user2Info.uuid,
			user1Info: navigationState.user1Info,
			user2Info: navigationState.user2Info,
			createdAt: navigationState.createdAt,
			unreadCount: 0,
		};
		// 채팅방 생성 이벤트 발생
		reactEventEmitter.emit('react-create-dm-room', roomData);

		// 채팅방 생성 API 호출
		const createRoomPayload: CreateMessageRoomCIVO = {
			roomId: roomId,
			userUid: myProfile.userUid,
			toUserUid: userProfile.userUid,
			billingStatus: 0,
			roomType: 'LIKE',
			createdAt: new Date().toString(),
		};

		createMessageRoom(createRoomPayload);

		// 채팅방으로 이동
		navigate(`/metaverse/dating/like/matchingSuccess`, {
			state: navigationState,
		});

		fnOpenMessageRoom();
	};

	return {
		srcPrefix,
		matchedItem,
		checkAndGetRoomId,
		fnOpenMessageRoom,
		createMatingDMRoom,
		setMatchingSuccess,
		createNavigationState,
	};
};
