import {useEffect} from "react";
import {useHomeRepo} from "@dating/repository/home/useHomeRepo";

export const useHomeHeaderService = () => {
    const {
        setTodayType,
        todayType,
    } = useHomeRepo();

    const changeHomeType = (type: boolean) => {
        setTodayType(type);
    };

    useEffect(() => {

    }, [todayType]);

    return {
        todayType,
        changeHomeType,
    };
};