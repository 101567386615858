import { useState } from 'react';

export const useNicknameStateRepo = () => {
	const [inputValue, setInputValue] = useState<string>('');
	const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
	const [lengthOver, setLengthOver] = useState<boolean>(false);
	const [isValueEntered, setIsValueEntered] = useState<boolean>(false);
	const [isAvailable, setIsAvailable] = useState<boolean>(true);

	// api 응답결과
	const [response, setResponse] = useState<boolean>(false);

	return {
		response,
		setResponse,
		inputValue,
		setInputValue,
		btnDisabled,
		setBtnDisabled,
		lengthOver,
		setLengthOver,
		isValueEntered,
		setIsValueEntered,
		isAvailable,
		setIsAvailable,
	};
};
