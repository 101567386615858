import { EditType } from '@dating/repository/profile/useEditProfileRepo';

interface props {
	editPop: EditType;
	setEditPop: (editPop: EditType) => void;
	focusItem: { label: string; value: string | string[] };
	nicknameValue: string;
	setNicknameValue: (nicknameValue: string) => void;
	fnModifyProfileNickname: () => void;
	closeEditPopup: (key: string) => void;
}

const EditNicknamePopView = ({
	focusItem,
	editPop,
	setEditPop,
	nicknameValue,
	setNicknameValue,
	fnModifyProfileNickname,
	closeEditPopup,
}: props) => {
	return (
		<div className="popWrap editProfilePop">
			<div className="popupInfo padding-b16">
				<div className="frameWrap">
					<div className="titleWrap">
						<h2 className="title01">닉네임</h2>
						<p className="titleSub01">활동할 닉네임을 등록해 주세요</p>
					</div>
					<div className="content">
						<div className="inputWrap">
							<div className="input">
								<input
									id="firstFocus"
									type="text"
									name="name"
									placeholder="2~8 자리로 입력해 주세요"
									value={nicknameValue}
									onChange={(e) => {
										setNicknameValue(e.target.value);
									}}
								/>
							</div>
							<p
								className="sub-color fz12 padding-t8 padding-b5"
								style={
									{
										// height: "16.797px",
									}
								}
							>
								{
									// isValueEntered ?
									//     lengthOver ?
									//         "입력 가능한 길이를 초과했습니다."
									//         : isAvailable ?
									//             ""
									//             : "이미 사용중인 닉네임 입니다."
									//     : ""
								}
							</p>
						</div>
					</div>
				</div>

				<div className="popFtBtnWrap">
					<button
						type="button"
						className="popCloseBtn btn btnBig btnCCC"
						onClick={() => {
							closeEditPopup(focusItem.label);
						}}
					>
						<span>취소</span>
					</button>
					<button
						className="popCloseBtn btn btnBig btnBlack"
						type="button"
						onClick={() => {
							fnModifyProfileNickname();
						}}
					>
						<span>확 인</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default EditNicknamePopView;
