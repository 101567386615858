interface props {
    alertPopMessage: string;
    fnCommonAlertPopOff: () => void;
}

export const CommonAlertPopView = ({
                                       alertPopMessage,
                                       fnCommonAlertPopOff,
                                   }: props) => {
    return (
        <div className="withdrawalWrap">
            <div className="popupInfo padding-t36"
                 style={{
                     "whiteSpace": "pre-wrap",
                 }}>
                <p className="blackTxt text-c bold-500">
                    {alertPopMessage}
                </p>
                <div className="popFtBtnWrap">
                    <button
                        type="button"
                        className="popCloseBtn btn btnBig btnBlack"
                        onClick={() => {
                            fnCommonAlertPopOff();
                        }}
                    >
                        <span>확 인</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
