import { useWaitingService } from './service/useWaitingService';
import WaitingFooterView from './views/WaitingFooterView';
import WaitingHeaderView from '@dating/ui/pages/register/waiting/views/WaitingHeaderView';
import WaitingProfileImgView from '@dating/ui/pages/register/waiting/views/WaitingProfileImgView';
import WaitingProfileInfoView from '@dating/ui/pages/register/waiting/views/WaitingProfileInfoView';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RegisterWaitingContainer = () => {
	const {
		btnDisabled,
		approvedAccount,
		userWaiting,
		srcPrefix,
		clickStartBtn,
		sliderSettings,
		response,
	} = useWaitingService();

	const navigate = useNavigate();
	useEffect(() => {
		if (response) {
			navigate('/metaverse/dating/home/ideal');
		}
	}, [response]);

	return (
		<div
			className="wrap noFooter"
			style={{ height: '100%', overflowY: 'auto' }}
		>
			<div className="contentsWrap padding-t24 padding-b0">
				<WaitingHeaderView approvedAccount={approvedAccount} />

				<div className="content margin-t24 padding-b0">
					<WaitingProfileImgView
						userData={userWaiting}
						srcPrefix={srcPrefix}
						sliderSettings={sliderSettings}
					/>
					<WaitingProfileInfoView userData={userWaiting} />
				</div>
			</div>
			<WaitingFooterView
				btnDisabled={btnDisabled}
				clickStartBtn={clickStartBtn}
			/>
		</div>
	);
};

export default RegisterWaitingContainer;
