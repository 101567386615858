interface props {
    fnSaveNickname: () => void;
    btnDisabled: boolean;
}

const NicknameFooterView = ({
                                fnSaveNickname,
                                btnDisabled,
                            }: props) => {
    return (
        <footer>
            <div className="f-btnWrap">
                <button
                    type="button"
                    className="btn btnBig btnBlack"
                    onClick={fnSaveNickname}
                    disabled={btnDisabled}
                >
                    <span>다 음</span>
                </button>
            </div>
        </footer>
    );
};

export default NicknameFooterView;