import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { useMetaUserAdapter } from '@dating/adapter/meta/useMetaUserAdapter';
import BlockReportButtonView from '@dating/ui/components/blockReport/views/BlockReportButtonView';
import { Link } from 'react-router-dom';

const AvatarProfileInfoView = ({
	setOpenReport,
	setOpenBlock,
	openMenuPop,
	menuDotClickHandler,
	myMetaUser,
	metaUser,
	getCharacterFile,
}) => {
	return (
		<>
			<div className="avatarProInfo">
				<p className="commonTit">
					<span />
				</p>
				<div className="avatarProBox">
					<div className="metaProfile">
						<div className="metaProImg">
							<div className="avatar_character avatar_front">
								{getCharacterFile.map((img, index) => (
									<img
										key={index}
										className="avatar_profile_object"
										style={{
											background: `url('${img}')`,
										}}
									/>
								))}
							</div>
						</div>
						<div className="metaProTxt">
							<p className="metaProGender">
								{metaUser.gender === 'M' ? (
									<span className="men">남성</span>
								) : (
									<span className="women">여성</span>
								)}
							</p>
							<p className="nickname padding-b4">{metaUser.nickName}</p>
							{metaUser.introduce === null ? (
								<p className="introTxt">상태 메시지가 없습니다.</p>
							) : (
								<p className="introTxt">{metaUser.introduce}</p>
							)}
						</div>
						<div className="metaProBtn menuDotWap">
							<button
								className="menuDot"
								onClick={(e) => {
									e.stopPropagation();
									menuDotClickHandler();
								}}
							/>
							<BlockReportButtonView
								setOpenReport={setOpenReport}
								setOpenBlock={setOpenBlock}
								openMenuPop={openMenuPop}
							/>
						</div>
					</div>
				</div>

				<Link
					to="/metaverse/dating/profile"
					state={{
						isOtherProfile: metaUser.userUid !== myMetaUser.userUid,
					}}
				>
					<button className="btnBig btnBlack radiusNone">
						<span>프로필 보기</span>
					</button>
				</Link>
			</div>
		</>
	);
};
export default AvatarProfileInfoView;
