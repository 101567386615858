import {useHushPickService} from "@dating/ui/pages/hushPick/services/useHushPickService";
import {IdealMatchFinderHeaderView} from "@dating/ui/pages/hushPick/views/IdealMatchFinderHeaderView";
import IdealMatchFinderTabView from "@dating/ui/pages/hushPick/views/IdealMatchFinderTabView";
import {DdayProfileBoxView} from "@dating/ui/pages/hushPick/views/DdayProfileBoxView";
import {usePerfectPicksService} from "@dating/ui/pages/hushPick/perfectPicks/usePerfectPicksService";

const PerfectPicksContainer = () => {
    const {
        activeTab,
        tabOptions,
        setActiveTab
    } = useHushPickService();

    const {
        srcPrefix,
        setUserProfileOpen,
        myProfile,
        pastPreferUsers,
    } = usePerfectPicksService();
    
    return (
        <div className="wrap noFooter">
            <IdealMatchFinderHeaderView/>

            <IdealMatchFinderTabView
                activeTab={activeTab}
                tabOptions={tabOptions}
                setActiveTab={setActiveTab}
            />

            <div className="toDayWrap padding-b0 headerExceptH">
                <div className="tabConWrap inner">
                    <div className="tabContent">
                        <div className="tabCon tabCon03 active">
                            <div className="profileList imgSmList">
                                {/*최근 접속 이성 추천 리스트*/}
                                {pastPreferUsers.length > 0
                                    ? pastPreferUsers.map((profile, i) => {
                                        return (
                                            <DdayProfileBoxView
                                                key={profile.nickName + i}
                                                type={"perfect"}
                                                profile={profile}
                                                srcPrefix={srcPrefix}
                                                setUserProfileOpen={setUserProfileOpen}
                                                currentUserUid={myProfile.userUid}
                                            />
                                        );
                                    })
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PerfectPicksContainer;
