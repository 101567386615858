import { useEffect, useRef } from 'react';

interface props {
	inputValues: string[];
	handleInputKeyDown: (index: number, eventKey: string) => void;
	activeIndex: number;
}

const PhoneVerificationInputView = ({
	inputValues,
	handleInputKeyDown,
	activeIndex,
}: props) => {
	const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null));
	useEffect(() => {
		inputRefs.current[activeIndex]?.focus();
	}, [activeIndex]);

	return (
		<div className="input certificationBox">
			{inputValues.map((value, index) => (
				<input
					type="number"
					pattern="\d*"
					className="text-c padding-0"
					key={index}
					value={value}
					onChange={(e) => {

					}}
					onKeyDown={(e) => {
						const eventKey: string = e.key;
						handleInputKeyDown(index, eventKey);
					}}
					ref={(ref) => {
						inputRefs.current ? (inputRefs.current[index] = ref) : null;
					}}
				/>
			))}
		</div>
	);
};

export default PhoneVerificationInputView;
