import { useState } from 'react';
import { PostInfoCOVO } from '@/site/api';

export const useProfileStateRepo = () => {
	/***** useState *****/
	const [scrollOn, setScrollOn] = useState(true);
	const [profileSliderInitialized, setProfileSliderInitialized] =
		useState(false);

	// 좋아요
	const [likeBtnState, setLikeBtnState] = useState<boolean>(false);

	const [myPosts, setMyPosts] = useState<PostInfoCOVO[]>([]);

	// StepLastBoxView content
	const [profileDetails, setProfileDetails] = useState<
		{ key: string; title: string; value: string | number }[]
	>([]);

	const [editBtnShow, setEditBtnShow] = useState<boolean>(false);

	const [fadeIn, setFadeIn] = useState<boolean>(false);

	// 별점평가 별 색칠여부
	const [isChecked, setIsChecked] = useState<number | null>(null);

	// fnInitFeedEditHandler이 실행되었는지 여부
	const [isClickedFeedMenu, setIsClickedFeedMenu] = useState<{postId: string, state: boolean}>({postId: "", state: false});

	return {
		isClickedFeedMenu,
		setIsClickedFeedMenu,
		fadeIn,
		setFadeIn,
		editBtnShow,
		setEditBtnShow,
		profileDetails,
		setProfileDetails,
		scrollOn,
		setScrollOn,
		profileSliderInitialized,
		setProfileSliderInitialized,
		likeBtnState,
		setLikeBtnState,
		myPosts,
		setMyPosts,
		isChecked,
		setIsChecked,
	};
};
