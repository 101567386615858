import { useNavigate } from 'react-router-dom';

const EventListView = () => {
	const navigate = useNavigate();
	return (
		<div className="popConWrap">
			<div className="inner">
				<div className="eventList margin-top223">
					<a
						onClick={() => {
							navigate('/metaverse/dating/sideBar/friendInviteEvent');
						}}
					>
						<img src="/assets/img/test/event1.png" alt="테스트이미지" />
					</a>
					<a
						onClick={() => {
							navigate('/metaverse/dating/sideBar/friendInviteEvent');
						}}
					>
						<img src="/assets/img/test/event2.png" alt="테스트이미지" />
					</a>
					<a
						onClick={() => {
							navigate('/metaverse/dating/sideBar/friendInviteEvent');
						}}
					>
						<img src="/assets/img/test/event3.png" alt="테스트이미지" />
					</a>
				</div>
			</div>
		</div>
	);
};

export default EventListView;
