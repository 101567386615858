import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { useNavigate } from 'react-router-dom';

const ManagerMessageContainer = () => {
	const navigate = useNavigate();
	const { setShowDirectMessageList } = useDirectMessageRepo();
	return (
		<div className="wrap">
			<header className="datingHeader">
				<div className="headerWrap">
					<div className="h-left">
						<button
							className="btnBack"
							onClick={() => {
								navigate('/metaverse/messenger');
								setShowDirectMessageList(true);
							}}
						></button>
						<p className="bold margin-l8">
							<span className="cmBadge h-Badge">커플 매니저</span>
							사랑이꽃피는나무
						</p>
					</div>
					<div className="h-right">
						<button className="menuDot"></button>
					</div>
				</div>
			</header>
			<div id="conWrap" className="metaPopWrap">
				<div className="messengerWrap">
					<div className="inner chatRoom">
						<div className="chatList">
							<div className="chatBox chatLeft">
								<div className="proImg Sm">
									<img
										src="../../assets/img/test/cmprofile1.png"
										alt="테스트이미지"
									/>
								</div>
								<div className="chat">
									<div className="bubbleBox tail">
										<div className="bubble">
											<span>오늘도 새로운 이성분을 소개시켜 드립니다</span>
										</div>
										<div className="chatTime">
											<span>오후 04:21</span>
										</div>
									</div>
								</div>
							</div>
							<div className="chatBox chatRight">
								<div className="proImg Sm">
									<img src="/assets/test/msgprofile1.png" alt="테스트이미지" />
								</div>
								<div className="chat">
									<div className="bubbleBox tail">
										<div className="bubble">
											<span>오~기대됩니다!!!</span>
										</div>
										<div className="chatTime">
											<span>오후 07:24</span>
										</div>
									</div>
								</div>
							</div>
							{/* <p class="partnerOut">블핑진스님이 나갔습니다</p>  */}
						</div>
					</div>
					<div className="chatInput input">
						<input type="text" placeholder="대화를 입력하세요" />
						<button className="submitBtn"></button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ManagerMessageContainer;
