const RoomHeadCountBoxView = ({
	headCountSelectedList,
	activeHeadCountIndex,
	handleHeadCountChange,
}) => {
	return (
		<div className="headCountBox margin-t24">
			<p className="fz16Fw700">인원 수 선택</p>
			<span className="fz12 color-999">
				방에 참가할 수 있는 인원 수를 제한할 수 있습니다.
			</span>
			<div className="txtRadio d-flex gap-16 margin-t16">
				{headCountSelectedList.map((headCount) => {
					return (
						<div key={headCount.id}>
							<input
								type="radio"
								id={headCount.id}
								name="headCount"
								value={headCount.headCount}
								checked={activeHeadCountIndex === headCount.headCount}
								onChange={() => handleHeadCountChange(headCount.headCount)}
							/>
							<label htmlFor={headCount.id}>{headCount.label}</label>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default RoomHeadCountBoxView;
