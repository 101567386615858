import SentLikeListView from './SentLikeListView';
import LikeMoreButtonView from '../../views/LikeMoreButtonView';
import LikeTitleView from '../../views/LikeTitleView';
import {SendUserLikeCOVO} from "@/site/api";

interface props {
    titSet: {mainTit: string, subTit: string};
    index: number;
    scrollYIndex: number;
    setOpenPaymentPop: (OpenPaymentPop: boolean) => void;
    noDataUserLike: boolean;
    fnGetSendUserLike: () => void;
    likeUsers: SendUserLikeCOVO[];
    srcPrefix: string;
    setUserProfileOpen: (userUid: string) => void;
    fnRemoveSendUserLike: (id: string, type: string) => void;
    setMessageTargetUser: (messageTargetUser: string) => void;
};

const SentLikeUserView = ({
                              titSet,
                              index,
                              scrollYIndex,
                              setOpenPaymentPop,
                              noDataUserLike,
                              fnGetSendUserLike,
                              likeUsers,
                              srcPrefix,
                              setUserProfileOpen,
                              fnRemoveSendUserLike,
                              setMessageTargetUser,
                          }: props) => {
    return (
        <>
            <LikeTitleView
                titSet={titSet}
                index={index}
                scrollYIndex={scrollYIndex}
            />
            <SentLikeListView
                setOpenPaymentPop={setOpenPaymentPop}
                likeUsers={likeUsers}
                srcPrefix={srcPrefix}
                setUserProfileOpen={setUserProfileOpen}
                fnRemoveSendUserLike={fnRemoveSendUserLike}
                setMessageTargetUser={setMessageTargetUser}
            />
            <LikeMoreButtonView
                fnGetData={fnGetSendUserLike}
                btnHandle={noDataUserLike}
            />
        </>
    );
};

export default SentLikeUserView;
