import { useCallback, useEffect, useRef, useState } from 'react';
import { IRoomData, RoomType } from '../../../../../../../types/Rooms';
import { useLocation, useNavigate } from 'react-router-dom';
import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { useRoomRepo } from '@/stores/useRoomRepo';
import { useMetaRoomAdapter } from '@dating/adapter/meta/useMetaRoomAdapter';
import { MetaRoomCIVO } from '@/site/api/models/MetaRoomCIVO';
import { MetaRoomUserInCIVO } from '@/site/api/models/MetaRoomUserInCIVO';
import { MetaRoomUserOutCIVO } from '@/site/api/models/MetaRoomUserOutCIVO';

export const useMakeRoomService = () => {
	const location = useLocation();
	const {
		title,
		preLeftRoomId,
		headCountSelectedList,
		roomPwdToggle,
		initialRoomTheme,
		roomThemeSelected,
		roomThemeSelectedList,
		joinedRoomData,
		roomName,
		roomHeadCount,
		setRoomName,
		setPreLeftRoomId,
		setRoomHeadCount,
		setRoomThemeSelected,
		setRoomPwdToggle,
	} = useRoomRepo();
	const { sessionId, myMetaUser, audioControl } = useMetaUserRepo();
	const {
		saveCreateRoom,
		saveRoomUserIn,
		saveRoomUserOut,
		statusSaveRoomUserIn,
		dataSaveRoomUserIn,
		statusSaveCreateRoom,
		dataSaveCreateRoom,
	} = useMetaRoomAdapter();
	const [isRoomDataReady, setIsRoomDataReady] = useState(false);
	const navigate = useNavigate();
	const [activeHeadCountIndex, setActiveHeadCountIndex] = useState(0);
	const [customRoomNameResult, setCustomRoomNameResult] = useState(0);
	const [activeRoomThemeIndex, setActiveRoomThemeIndex] = useState(0);
	const [joinedRoomId, setJoinedRoomId] = useState<string | null>(null);
	const hasCalledSaveRoomUserOut = useRef(false);
	const [roomPwdResult, setRoomPwdResult] = useState(0);
	const [nameFieldEmpty, setNameFieldEmpty] = useState(false);
	const [values, setValues] = useState<IRoomData>({
		type: RoomType.CUSTOM,
		name: '',
		password: null,
		autoDispose: true,
		headCount: 0,
		roomTheme: roomThemeSelected,
		remainingTime: 7200,
	});

	// 방 이름 변경 함수
	const handleRoomNameChange =
		(prop: keyof IRoomData) => (event: React.ChangeEvent<HTMLInputElement>) => {
			const newValue = event.target.value;
			const parsedValue = newValue.length;
			setCustomRoomNameResult(parsedValue);
			setRoomName(newValue);
			setValues({ ...values, [prop]: newValue });
		};

	// 방 비밀번호 함수
	const handleRoomPwdChange =
		(prop: keyof IRoomData) => (event: React.ChangeEvent<HTMLInputElement>) => {
			const newValue = event.target.value;
			const parsedValue = newValue.length;
			setRoomPwdResult(parsedValue);
			setValues({ ...values, [prop]: newValue });
		};

	// 방 인원 수 변경 함수
	const handleHeadCountChange = (selectedValue) => {
		setActiveHeadCountIndex(selectedValue);
		setRoomHeadCount(selectedValue);
		setValues((prevValues) => ({
			...prevValues,
			headCount: selectedValue,
		}));
	};

	// 방 테마 변경 함수
	const handleRoomThemeChange = (index: number) => {
		setActiveRoomThemeIndex(index);
		const selectedTheme = roomThemeSelectedList[index];
		setRoomThemeSelected(selectedTheme);
		setValues((prevValues) => ({
			...prevValues,
			roomTheme: selectedTheme,
		}));
	};

	// 방 비밀번호 토글 함수
	const toggleRoomPwdBox = () => {
		setRoomPwdToggle(!roomPwdToggle);
	};

	// 폼 제출 함수
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const isValidName = values.name !== '';
		if (isValidName === nameFieldEmpty) setNameFieldEmpty(!nameFieldEmpty);
		if (isValidName) {
			const roomData = {
				roomType: 'CUSTOM',
				uuid: myMetaUser.userUid,
				nickName: myMetaUser.nickName,
				gender: myMetaUser.gender,
				profileUrl: myMetaUser.profileUrl,
				x: roomThemeSelected.setUpPlayer.x,
				y: roomThemeSelected.setUpPlayer.y,
				anim: myMetaUser.avatarName,
				readyToConnect: myMetaUser.readyToConnect,
				mediaConnected: myMetaUser.mediaConnected,
				statusMessage: myMetaUser.introduce,
				audioStatus: audioControl,
				name: values.name,
				password: undefined,
				autoDispose: values.autoDispose,
				headCount: values.headCount,
				roomTheme: values.roomTheme,
				remainingTime: values.remainingTime,
				room: values,
			};
			reactEventEmitter.emit('react-start-room', roomData);
			reactEventEmitter.emit('react-join-room', {
				sessionId,
				uuid: myMetaUser.userUid,
				nickName: myMetaUser.nickName,
				gender: myMetaUser.gender,
				profileUrl: myMetaUser.profileUrl,
				name: myMetaUser.nickName,
				avatarName: myMetaUser.avatarName,
				statusMessage: myMetaUser.introduce,
				audioStatus: audioControl,
			});
		}
	};

	// 방 생성 함수
	const handleCreateRoom = useCallback(async () => {
		if (joinedRoomData?.id) {
			const param: MetaRoomCIVO = {
				roomId: joinedRoomData.id,
				roomName: roomName,
				isSecret: false,
				password: '',
				roomMaxUsers: roomHeadCount,
				roomThemeId: roomThemeSelected.id,
			};
			saveCreateRoom(param);
			setJoinedRoomId(joinedRoomData.id);
			setRoomThemeSelected(initialRoomTheme);
			setValues((prevValues) => ({
				...prevValues,
				roomTheme: initialRoomTheme,
			}));
			setActiveRoomThemeIndex(
				roomThemeSelectedList.findIndex(
					(theme) => theme.id === initialRoomTheme.id,
				),
			);
		}
	}, [
		joinedRoomData,
		roomName,
		roomHeadCount,
		roomThemeSelected,
		saveCreateRoom,
		preLeftRoomId,
	]);

	// 방 퇴장 함수
	const handleSaveRoomUserOut = useCallback(async () => {
		if (preLeftRoomId && !hasCalledSaveRoomUserOut.current) {
			hasCalledSaveRoomUserOut.current = true;
			const param: MetaRoomUserOutCIVO = {
				roomId: preLeftRoomId,
			};
			try {
				saveRoomUserOut(param);
			} catch (error) {
				console.error('saveRoomUserOut 호출 실패:', error);
			} finally {
				setPreLeftRoomId('');
			}
		}
	}, [preLeftRoomId, saveRoomUserOut]);

	useEffect(() => {
		if (preLeftRoomId) {
			handleSaveRoomUserOut();
		}
	}, [preLeftRoomId, handleSaveRoomUserOut]);

	// 방 참가 함수
	const handleSaveRoomUserIn = useCallback(async () => {
		if (joinedRoomId) {
			const param: MetaRoomUserInCIVO = {
				roomId: joinedRoomId,
				password: '',
			};
			saveRoomUserIn(param);
		}
	}, [joinedRoomId, saveRoomUserIn]);

	const resetRoomSettings = useCallback(() => {
		setRoomThemeSelected(initialRoomTheme);
		setValues((prevValues) => ({
			...prevValues,
			roomTheme: initialRoomTheme,
		}));
		setActiveRoomThemeIndex(
			roomThemeSelectedList.findIndex(
				(theme) => theme.id === initialRoomTheme.id,
			),
		);
		// 필요한 다른 초기화 로직 추가
		setRoomName('');
		setRoomHeadCount(headCountSelectedList[0].headCount);
		setRoomPwdToggle(false);
		// ... 기타 초기화할 상태들
	}, [initialRoomTheme, roomThemeSelectedList, headCountSelectedList]);

	useEffect(() => {
		resetRoomSettings();
	}, [location.pathname, resetRoomSettings]);

	useEffect(() => {
		const handleRoomDataChange = (isReady: boolean) => {
			setIsRoomDataReady(isReady);
		};
		const handleLeaveRoomId = (roomId: string) => {
			setPreLeftRoomId(roomId);
		};
		reactEventEmitter.on('react-leave-room', handleLeaveRoomId);
		reactEventEmitter.on('react-change-room-data', handleRoomDataChange);
		return () => {
			reactEventEmitter.off('react-leave-room', handleLeaveRoomId);
			reactEventEmitter.off('react-change-room-data', handleRoomDataChange);
		};
	}, []);

	useEffect(() => {
		if (isRoomDataReady && joinedRoomData?.id) {
			handleCreateRoom();
		}
	}, [joinedRoomData, isRoomDataReady, handleCreateRoom]);

	useEffect(() => {
		if (statusSaveCreateRoom === 'success' && dataSaveCreateRoom) {
			const leaveAndJoinRoom = async () => {
				if (preLeftRoomId) {
					await handleSaveRoomUserOut();
				}
				if (joinedRoomId) {
					await handleSaveRoomUserIn();
				}
			};
			leaveAndJoinRoom();
		}
	}, [
		statusSaveCreateRoom,
		dataSaveCreateRoom,
		joinedRoomId,
		preLeftRoomId,
		handleSaveRoomUserIn,
		handleSaveRoomUserOut,
	]);
	useEffect(() => {
		if (preLeftRoomId) {
			handleSaveRoomUserOut();
		} else {
			hasCalledSaveRoomUserOut.current = false;
		}
	}, [preLeftRoomId, handleSaveRoomUserOut]);

	useEffect(() => {
		if (statusSaveRoomUserIn === 'success' && dataSaveRoomUserIn) {
			navigate('/metaverse');
		}
	}, [statusSaveRoomUserIn, dataSaveRoomUserIn, navigate]);

	useEffect(() => {
		setActiveHeadCountIndex(headCountSelectedList[0].headCount);
		handleHeadCountChange(headCountSelectedList[0].headCount);
	}, []);

	return {
		title,
		values,
		customRoomNameResult,
		activeHeadCountIndex,
		headCountSelectedList,
		roomThemeSelected,
		roomThemeSelectedList,
		roomPwdToggle,
		nameFieldEmpty,
		roomPwdResult,
		activeRoomThemeIndex,
		handleRoomNameChange,
		handleHeadCountChange,
		handleRoomThemeChange,
		handleRoomPwdChange,
		setRoomPwdToggle,
		toggleRoomPwdBox,
		handleSubmit,
	};
};
