import FeedListContainer from '@dating/ui/pages/lounge/feedList/FeedListContainer';
import FeedSearchContainer from '@dating/ui/pages/lounge/feedSearch/FeedSearchContainer';
import { Route, Routes, useLocation } from 'react-router-dom';
import {FeedFormContainer} from "@dating/ui/components/feedFrom/FeedFormContainer";
import {SingleFeedContainer} from "@dating/ui/pages/lounge/singleFeed/SingleFeedContainer";

const LoungeRouter = () => {
	const location = useLocation();
	return (
		<>
			<Routes location={location}>
				<Route path="/list" element={<FeedListContainer/>} />
			</Routes>

			<Routes location={location}>
				<Route path="/search" element={<FeedSearchContainer />} />
				<Route path="/form" element={<FeedFormContainer />} />
				<Route path="/singleFeed" element={<SingleFeedContainer />} />
			</Routes>
		</>
	);
};

export default LoungeRouter;
