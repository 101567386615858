import CamVideoView from './views/CamVideoView';
import { useVideoService } from './service/useVideoService';
import { sanitizeId } from '@/utils/util';

const CamWrapContainer = () => {
	const {
		videoRef,
		myMetaUser,
		addedVideos,
		myVideoStream,
		audioControl,
		sessionId,
		playersInfo,
		clickFoldBtn,
		setClickFoldBtn,
		setOpenCamExpansion,
	} = useVideoService();

	return (
		<div className="camWrap">
			<button
				className={`camListBtn ${clickFoldBtn ? 'active' : ''}`}
				onClick={() => {
					setClickFoldBtn(!clickFoldBtn);
				}}
			/>
			<div className="camList">
				{[...addedVideos.values()].map((videoElement) =>
					videoElement.id ? (
						<CamVideoView
							playerInfo={playersInfo[sanitizeId(videoElement.id)]}
							setOpenCamExpansion={setOpenCamExpansion}
							sessionId={sessionId}
							audioControl={audioControl}
							myVideoStream={myVideoStream}
							key={videoElement.id}
							videoElement={videoElement}
							myMetaUser={myMetaUser}
						/>
					) : null,
				)}
			</div>
		</div>
	);
};

export default CamWrapContainer;
