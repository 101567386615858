import {BlockUserCOVO} from "@/site/api";

interface props {
    blockUsers: BlockUserCOVO[];
    srcPrefix: string;
    fnSaveUnblockUser: (blockUserUid: string) => void;
}

const BlockListView = ({
                           blockUsers,
                           srcPrefix,
                           fnSaveUnblockUser,
                       }: props) => {
    return (
        <div className="popConWrap padding-b0">
            <div className="inner padding-t8">
                {
                    blockUsers.length > 0 ?
                        blockUsers.map((item, i) => {
                            return (
                                <div
                                    key={item.nickName + i}
                                    className="profileType"
                                >
                                    <div className="proLeft">
                                        <div className="proImg">
                                            <img src={srcPrefix + item.profileUrl} alt="테스트이미지"/>
                                        </div>
                                        <div className="proName margin-l12">
                                            <p className="bold padding-b4">{item.nickName}</p>
                                            <p className="fz14 color-666">
                                                {/*{item.age}세, {item.location}*/}
                                                {item.age}세
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ProRight">
                                        <button
                                            type="button"
                                            className="closeBtn margin-l5"
                                            onClick={() => {
                                                fnSaveUnblockUser(item.blockUserUid);
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        })
                        : "차단한 유저가 없습니다."
                }
            </div>
        </div>
    );
};

export default BlockListView;
