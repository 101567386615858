import { useEffect, useState } from 'react';
import { useRoomService } from '../../room/service/useRoomService';
import { reactEventEmitter } from '@/events/ReactEventEmitter';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { useFooterRepo } from '@dating/repository/dating/useFooterRepo';
import Network from '@/services/Network';

export const useAppFooterService = () => {
	const [isDMRoomJoined, setIsDMRoomJoined] = useState(false);

	const {
		homeOpen,
		loungeOpen,
		likeOpen,
		metaOpen,
		setHomeOpen,
		setLoungeOpen,
		setLikeOpen,
		setMetaOpen,
	} = useFooterRepo();

	const {
		showDirectMessageList,
		setOpenDirectMessageDetail,
		setShowDirectMessageList,
	} = useDirectMessageRepo();

	const { myProfile } = useDatingRepo();

	const { feedCommentOpen, setFeedCommentOpen } = useLoungeRepo();

	const { openOtherPlayerProfile } = useMetaUserRepo();

	const { isMetaOpen, setIsMetaOpen, handleClickMeta } = useRoomService();

	const handleHomeClick = () => {
		setHomeOpen(true);
		setLoungeOpen(false);
		setLikeOpen(false);
		setShowDirectMessageList(false);
		setMetaOpen(false);
	};

	const handleLoungeClick = () => {
		setHomeOpen(false);
		setLoungeOpen(true);
		setLikeOpen(false);
		setShowDirectMessageList(false);
		setMetaOpen(false);
	};

	const handleLikeClick = () => {
		setHomeOpen(false);
		setLoungeOpen(false);
		setLikeOpen(true);
		setShowDirectMessageList(false);
		setMetaOpen(false);
	};

	const handleMessageClick = () => {
		// if (!isDMRoomJoined) {
		// 	reactEventEmitter.emit('react-join-or-create-dm-room', {
		// 		uuid: myProfile.userUid,
		// 		nickName: myProfile.nickName,
		// 		profileUrl: myProfile.profileUrl[0],
		// 		age: myProfile.age,
		// 		location: myProfile.location,
		// 	});
		// }
		setOpenDirectMessageDetail(false, '');
		handleMessageSendClick();
	};

	const handleMessageSendClick = () => {
		setHomeOpen(false);
		setLoungeOpen(false);
		setLikeOpen(false);
		setShowDirectMessageList(true);
		setMetaOpen(false);
	};

	const handleMetaClick = () => {
		console.log(
			'handleClickMeta called, Network instance2:',
			!!Network.instance,
		);

		console.log('isMetaOpen:', isMetaOpen);

		if (!isMetaOpen) {
			handleClickMeta();
			setIsMetaOpen(true);
		}
		setHomeOpen(false);
		setLoungeOpen(false);
		setLikeOpen(false);
		setMetaOpen(true);
		setOpenDirectMessageDetail(false, '');
		setShowDirectMessageList(false);
	};
	useEffect(() => {
		const handleDMRoomJoined = () => {
			setIsDMRoomJoined(true);
		};
		reactEventEmitter.on('react-dm-room-joined', handleDMRoomJoined);
		return () => {
			reactEventEmitter.off('react-dm-room-joined', handleDMRoomJoined);
		};
	}, []);

	/*
	 * initFooterWrap
	 * 하단메뉴 초기화(홈 active)
	 */
	const initFooterWrap = () => {
		setHomeOpen(true);
		setLoungeOpen(false);
		setLikeOpen(false);
		setMetaOpen(false);
		setShowDirectMessageList(false);
		setFeedCommentOpen(false);
	};

	useEffect(() => {
		initFooterWrap();
	}, []);

	return {
		feedCommentOpen,
		openOtherPlayerProfile,
		homeOpen,
		loungeOpen,
		likeOpen,
		metaOpen,
		showDirectMessageList,
		// idealMathFinderOpen,
		setHomeOpen,
		setLoungeOpen,
		setLikeOpen,
		setMetaOpen,
		handleHomeClick,
		handleLoungeClick,
		handleLikeClick,
		handleMessageClick,
		handleMetaClick,
		handleMessageSendClick,
		initFooterWrap,
	};
};
