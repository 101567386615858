import { RoomAvailable } from 'colyseus.js';
import { create } from 'zustand';

type RoomRepoState = {
	title: string;
	isMetaOpen: boolean;
	disposedRoomId: string;
	preLeftRoomId: string;
	roomType: string;
	roomId: string;
	roomName: string;
	roomHeadCount: number;
	joinedRoomData: {
		id: string;
		name: string;
		headCount: number;
		roomTheme: Record<string, any>;
		players: any[];
	};
	availableRooms: RoomAvailable[];
	headCountSelectedList: {
		id: string;
		label: string;
		headCount: number;
	}[];
	headCountSelected: {
		id: string;
		label: string;
		headCount: number;
	};
	roomListOpen: boolean;
	roomThemeSelectedList: Array<{
		id: number;
		label: string;
		img: string;
		mapBackground: string;
		tileMap: string;
		wall: string;
		setUpPlayer: {
			x: number;
			y: number;
		};
		setBounds: number;
	}>;
	initialRoomTheme: {
		id: number;
		label: string;
		img: string;
		mapBackground: string;
		tileMap: string;
		wall: string;
		setUpPlayer: {
			x: number;
			y: number;
		};
		setBounds: number;
	};
	roomThemeSelected: {
		id: number;
		label: string;
		img: string;
		mapBackground: string;
		tileMap: string;
		wall: string;
		setUpPlayer: {
			x: number;
			y: number;
		};
		setBounds: number;
	};
	roomPwdToggle: boolean;

	setIsMetaOpen: (isMetaOpen: boolean) => void;
	setRoomType: (roomType: string) => void;
	setDisposedRoomId: (roomId: string) => void;
	setPreLeftRoomId: (roomId: string) => void;
	setRoomName: (roomName: string) => void;
	setRoomHeadCount: (roomHeadCount: number) => void;
	setJoinedRoomData: (data: {
		id: string;
		name: string;
		headCount: number;
		roomTheme: Record<string, any>;
		players: any[];
	}) => void;
	setAvailableRooms: (rooms: RoomAvailable[]) => void;
	setHeadCountSelected: (headCount: {
		id: string;
		label: string;
		headCount: number;
	}) => void;
	addAvailableRooms: (data: { roomId: string; room: RoomAvailable }) => void;
	removeAvailableRooms: (roomId: string) => void;
	setRoomListOpen: (isOpen: boolean) => void;
	setRoomThemeSelected: (theme: any) => void;
	setRoomPwdToggle: (toggle: boolean) => void;
};

export const useRoomRepo = create<RoomRepoState>((set) => ({
	title: '방 설정',
	isMetaOpen: false,
	preLeftRoomId: '',
	disposedRoomId: '',
	roomType: '',
	roomId: '',
	roomName: '',
	roomHeadCount: 0,
	joinedRoomData: {
		id: '',
		name: '',
		headCount: 0,
		roomTheme: {},
		players: [],
	},
	availableRooms: new Array<RoomAvailable>(),
	headCountSelectedList: [
		{
			id: '2 : 2',
			label: '2 : 2',
			headCount: 4,
		},
		{
			id: '3 : 3',
			label: '3 : 3',
			headCount: 6,
		},
	],
	headCountSelected: {
		id: '2 : 2',
		label: '2 : 2',
		headCount: 4,
	},
	roomListOpen: false,
	initialRoomTheme: {
		id: 0,
		label: '해변 카페',
		img: '/assets/img/test/room_01.jpg',
		mapBackground: '/assets/map/Dating/Cafe/cafe.png',
		tileMap: '/assets/map/Dating/Cafe/cafe.json',
		wall: '/assets/map/Dating/Cafe/wall.png',
		setUpPlayer: {
			x: 512,
			y: 416,
		},
		setBounds: 1024,
	},
	roomThemeSelectedList: [
		{
			id: 0,
			label: '해변 카페',
			img: '/assets/img/test/room_01.jpg',
			mapBackground: '/assets/map/Dating/Cafe/cafe.png',
			tileMap: '/assets/map/Dating/Cafe/cafe.json',
			wall: '/assets/map/Dating/Cafe/wall.png',
			setUpPlayer: {
				x: 512,
				y: 416,
			},
			setBounds: 1024,
		},
		{
			id: 1,
			label: '영화관',
			img: '/assets/img/test/room_02.jpg',
			mapBackground: '/assets/map/Dating/Theater/theater.png',
			tileMap: '/assets/map/Dating/Theater/theater.json',
			wall: '/assets/map/Dating/Theater/wall.png',
			setUpPlayer: {
				x: 512,
				y: 736,
			},
			setBounds: 1024,
		},
		{
			id: 2,
			label: '루프탑',
			img: '/assets/img/test/room_03.png',
			mapBackground: '/assets/map/Dating/RoofTopBar/roofTopBar.png',
			tileMap: '/assets/map/Dating/RoofTopBar/roofTopBar.json',
			wall: '/assets/map/Dating/RoofTopBar/wall.png',
			setUpPlayer: {
				x: 640,
				y: 580,
			},
			setBounds: 1024,
		},
		{
			id: 3,
			label: '한강 공원',
			img: '/assets/img/test/room_04.png',
			mapBackground: '/assets/map/Dating/HanRiver/hanRiver.png',
			tileMap: '/assets/map/Dating/HanRiver/hanRiver.json',
			wall: '/assets/map/Dating/HanRiver/wall.png',
			setUpPlayer: {
				x: 416,
				y: 704,
			},
			setBounds: 1024,
		},
		{
			id: 4,
			label: '광장',
			img: '/assets/img/test/room_00.jpg',
			mapBackground: '/assets/map/Dating/Square/square.png',
			tileMap: '/assets/map/Dating/Square/square.json',
			wall: '/assets/map/Dating/Square/wall.png',
			setUpPlayer: {
				x: 1024,
				y: 1376,
			},
			setBounds: 2048,
		},
	],
	roomThemeSelected: {
		id: 1,
		label: '해변 카페',
		img: '/assets/img/test/room_01.jpg',
		mapBackground: '/assets/map/Dating/Cafe/cafe.png',
		tileMap: '/assets/map/Dating/Cafe/cafe.json',
		wall: '/assets/map/Dating/Cafe/wall.png',
		setUpPlayer: {
			x: 512,
			y: 416,
		},
		setBounds: 1024,
	},
	roomPwdToggle: false,

	setIsMetaOpen: (isMetaOpen) => set({ isMetaOpen }),
	setRoomType: (roomType) => set({ roomType }),
	setPreLeftRoomId: (roomId) => set({ preLeftRoomId: roomId }),
	setDisposedRoomId: (roomId) => set({ disposedRoomId: roomId }),
	setRoomName: (roomName) => set({ roomName }),
	setRoomHeadCount: (roomHeadCount) => set({ roomHeadCount }),
	setJoinedRoomData: (data) => set({ joinedRoomData: data }),
	setAvailableRooms: (rooms) => set({ availableRooms: rooms }),
	addAvailableRooms: (data) =>
		set((state) => ({
			availableRooms: state.availableRooms.some(
				(room) => room.roomId === data.roomId,
			)
				? state.availableRooms.map((room) =>
						room.roomId === data.roomId ? data.room : room,
				  )
				: [...state.availableRooms, data.room],
		})),
	removeAvailableRooms: (roomId) =>
		set((state) => ({
			availableRooms: state.availableRooms.filter(
				(room) => room.roomId !== roomId,
			),
		})),
	setHeadCountSelected: (headCount) => set({ headCountSelected: headCount }),
	setRoomListOpen: (isOpen) => set({ roomListOpen: isOpen }),
	setRoomThemeSelected: (theme) => set({ roomThemeSelected: theme }),
	setRoomPwdToggle: (toggle) => set({ roomPwdToggle: toggle }),
}));
