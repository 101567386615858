import RegisterHeaderView from "../common/registerHeader/views/RegisterHeaderView";
import LocationInfoFooterView from "./views/LocationInfoFooterView";
import useLocationInfoService from "@dating/ui/pages/register/locationInfo/service/useLocationInfoService";
import React, { useEffect, useState } from 'react';
import {
    useRegisterHeaderService
} from "@dating/ui/pages/register/common/registerHeader/service/useRegisterHeaderService";
import StepProgressView from "@dating/ui/pages/register/common/stepProgress/views/StepProgressView";
import { useNavigate } from "react-router-dom";
import { LocationInfoCIVO } from '@/site/api';

const LocationInfoContainer = () => {
    const {
        fnSaveUserLocation,
        statusSaveUserLocation,
        dataSaveUserLocation,
    } = useLocationInfoService();

    const {
        referralCodeOpen,
        setReferralCodeOpen,
        skipHandler,
        step,
    } = useRegisterHeaderService();

    const navigate = useNavigate();
    useEffect(() => {
        if (statusSaveUserLocation === "success" && dataSaveUserLocation) {
            navigate("/metaverse/dating/register/waiting");
        } else if (statusSaveUserLocation === "error") {
        }
    }, [statusSaveUserLocation, dataSaveUserLocation]);

    const [location, setLocation] = useState<LocationInfoCIVO>({
        latitude: "37.123456",
        altitude: "127.123456",
    });

    return (
        <div className="wrap noFooter">
            <RegisterHeaderView
                referralCodeOpen={referralCodeOpen}
                setReferralCodeOpen={setReferralCodeOpen}
                skipHandler={skipHandler}
            />

            <div className="contentsWrap hiddenCon">
                <div className="titleWrap padding-b0">
                    <StepProgressView step={step} />
                    <h2 className="title01">위치 기반 프로필 추천</h2>
                    <p className="titleSub01">
                        위치 정보를 공유하지 않으면 서비스를 이용할 수 없어요<br />
                        상세한 위치 정보는 공개되지 않습니다
                    </p>
                </div>
                <div className="myTypeNone text-c">
                    <img
                        src="/assets/img/common/ico-my-locate.png"
                        width="140"
                        alt=""
                    ></img>
                </div>
            </div>

            <LocationInfoFooterView
                fnSaveUserLocation={fnSaveUserLocation}
                setLocation={setLocation}
            />

        </div>
    );
};

export default LocationInfoContainer;
