import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { MetaRoomUserInCIVO } from '@/site/api/models/MetaRoomUserInCIVO';
import { MetaRoomUserOutCIVO } from '@/site/api/models/MetaRoomUserOutCIVO';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';
import { useRoomRepo } from '@/stores/useRoomRepo';
import { useMetaRoomAdapter } from '@dating/adapter/meta/useMetaRoomAdapter';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useRoomListService = () => {
	const { saveRoomUserIn, saveRoomUserOut } = useMetaRoomAdapter();
	const hasCalledSaveRoomUserOut = useRef(false);
	const { sessionId, myMetaUser, audioControl } = useMetaUserRepo();
	const myGender = myMetaUser.gender;
	const {
		availableRooms,
		joinedRoomData,
		roomListOpen,
		setPreLeftRoomId,
		setRoomListOpen,
	} = useRoomRepo();
	const [isRoomDataReady, setIsRoomDataReady] = useState(false);
	const [joinedRoomId, setJoinedRoomId] = useState<string | null>(null);
	const [roomItem, setRoomItem] = useState(false);

	// 사용 가능한 방을 필터링하고 정렬
	const sortedRooms = availableRooms
		.filter(
			(room) => room.metadata.name !== 'PUBLIC' && room.metadata.name !== 'DM',
		)
		.sort((a, b) => {
			// 현재 참여 중인 방을 맨 위로 정렬
			if (a.roomId === joinedRoomData?.id) return -1;
			if (b.roomId === joinedRoomData?.id) return 1;
			return 0;
		});

	// 방 입장 함수
	const handleSaveRoomUserIn = useCallback(
		async (roomId: string) => {
			const param: MetaRoomUserInCIVO = {
				roomId: roomId,
				password: '',
			};
			try {
				saveRoomUserIn(param);
			} catch (error) {
				console.error('saveRoomUserIn 호출 실패:', error);
			}
		},
		[saveRoomUserIn],
	);

	// 방 퇴장 함수
	const handleSaveRoomUserOut = useCallback(async () => {
		const currentRoomId = joinedRoomData?.id;
		if (currentRoomId && !hasCalledSaveRoomUserOut.current) {
			hasCalledSaveRoomUserOut.current = true;
			const param: MetaRoomUserOutCIVO = {
				roomId: currentRoomId,
			};
			try {
				saveRoomUserOut(param);
			} catch (error) {
				console.error('saveRoomUserOut 호출 실패:', error);
			} finally {
				setPreLeftRoomId(currentRoomId);
				hasCalledSaveRoomUserOut.current = false;
			}
		}
	}, [joinedRoomData, saveRoomUserOut, setPreLeftRoomId]);

	// 방 참여 클릭 함수
	const handleJoinClick = useCallback(
		async (roomId: string, password: string | undefined, roomTheme: any) => {
			// 먼저 현재 방에서 나가기
			await handleSaveRoomUserOut();
			reactEventEmitter.emit('react-start-room', {
				roomType: 'CUSTOM',
				uuid: myMetaUser.userUid,
				nickName: myMetaUser.nickName,
				gender: myMetaUser.gender,
				profileUrl: myMetaUser.profileUrl,
				x: roomTheme.setUpPlayer.x,
				y: roomTheme.setUpPlayer.y,
				anim: myMetaUser.avatarName,
				readyToConnect: myMetaUser.readyToConnect,
				mediaConnected: myMetaUser.mediaConnected,
				statusMessage: myMetaUser.introduce,
				audioStatus: audioControl,
				roomId,
				password,
				roomTheme,
			});
			reactEventEmitter.emit('react-join-room', {
				sessionId,
				uuid: myMetaUser.userUid,
				nickName: myMetaUser.nickName,
				gender: myMetaUser.gender,
				profileUrl: myMetaUser.profileUrl,
				name: myMetaUser.nickName,
				avatarName: myMetaUser.avatarName,
				statusMessage: myMetaUser.introduce,
				audioStatus: audioControl,
			});
			setJoinedRoomId(roomId);

			// 새 방에 입장 처리
			await handleSaveRoomUserIn(roomId);
		},
		[
			myMetaUser,
			sessionId,
			audioControl,
			handleSaveRoomUserOut,
			handleSaveRoomUserIn,
		],
	);

	useEffect(() => {
		const handleRoomDataChange = (isReady: boolean) => {
			setIsRoomDataReady(isReady);
		};
		const handleLeaveRoomId = (roomId: string) => {
			setPreLeftRoomId(roomId);
		};
		reactEventEmitter.on('react-leave-room', handleLeaveRoomId);
		reactEventEmitter.on('react-change-room-data', handleRoomDataChange);
		return () => {
			reactEventEmitter.off('react-leave-room', handleLeaveRoomId);
			reactEventEmitter.off('react-change-room-data', handleRoomDataChange);
		};
	}, [setPreLeftRoomId]);
	return {
		myGender,
		sortedRooms,
		roomListOpen,
		availableRooms,
		roomItem,
		joinedRoomData,
		setRoomListOpen,
		setRoomItem,
		handleJoinClick,
	};
};
