import PostWithCommentListView from './PostWithCommentListView';
import LikeMoreButtonView from '../../views/LikeMoreButtonView';
import LikeTitleView from '../../views/LikeTitleView';
import {ReceiveCommentPostCOVO} from "@/site/api";

interface props {
    titSet: { mainTit: string, subTit: string };
    index: number;
    scrollYIndex: number;
    noDataComment: boolean;
    srcPrefix: string;
    commentPost: ReceiveCommentPostCOVO[];
    fnGetReceivePostComment: () => void;
    setUserProfileOpen: (userUid: string) => void;
    currentUserUid: string;
    setMyProfileOpen: (myProfileOpen: boolean) => void;
    setSingleFeedId: (singleFeedId: string) => void;
};

const PostWithCommentView = ({
                                 titSet,
                                 index,
                                 scrollYIndex,
                                 noDataComment,
                                 srcPrefix,
                                 commentPost,
                                 fnGetReceivePostComment,
                                 setUserProfileOpen,
                                 currentUserUid,
                                 setMyProfileOpen,
                                 setSingleFeedId,
                             }: props) => {
    return (
        <>
            <LikeTitleView
                titSet={titSet}
                scrollYIndex={scrollYIndex}
                index={index}
            />

            <PostWithCommentListView
                srcPrefix={srcPrefix}
                commentPost={commentPost}
                setUserProfileOpen={setUserProfileOpen}
                currentUserUid={currentUserUid}
                setMyProfileOpen={setMyProfileOpen}
                setSingleFeedId={setSingleFeedId}
            />

            <LikeMoreButtonView
                fnGetData={fnGetReceivePostComment}
                btnHandle={noDataComment}
            />
        </>
    );
};

export default PostWithCommentView;
