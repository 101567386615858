import { SettingTermsCOVO } from "@/site/api";
import { Link } from "react-router-dom";

interface props {
    allAgreement: boolean;
    checkboxHandler: (id: string, checked: boolean) => void;
    checkedAgreements: {};
    handleAllCheckBox: (any) => void;
    termsList: SettingTermsCOVO[];
    setSelectedTerm: (selectedTerm) => void;
}

const AgreementTermsContentsView = ({
                                        allAgreement,
                                        checkboxHandler,
                                        checkedAgreements,
                                        handleAllCheckBox,
                                        termsList,
                                        setSelectedTerm,
                                    }: props) => {

    return (
        <div className="contentsWrap">
            <div className="titleWrap">
                <h2 className="title01">이용약관</h2>
                <p className="color-999 fz16 margin-t16">
                    서비스 이용에 꼭 필요한 사항입니다<br/>
                    정책 및 약관 내용을 확인해 주세요
                </p>
            </div>
            <div className="checkWrap checkList">
                {termsList?.map((term, i) => {
                    if (term.required) {
                        return (
                            <div key={term.type + term.termsId}>
                                <input
                                    id={term.termsId}
                                    className="checkbox"
                                    name="checkbox-group"
                                    type="checkbox"
                                    onChange={(e) => {
                                        checkboxHandler(e.target.id, e.target.checked);
                                    }}
                                    checked={checkedAgreements[term.termsId] ? checkedAgreements[term.termsId] : false}
                                />
                                <label htmlFor={term.termsId} className="checkbox-label">
                                    <Link
                                        to={'/metaverse/dating/settings/policy0' + (i + 1).toString()}
                                        onClick={() => {
                                            setSelectedTerm(term.contents);
                                        }}
                                    >
                                        <span className="main-color">(필수)</span>
                                        {term.contents}
                                    </Link>
                                </label>
                            </div>
                        );
                    } else {
                        return (
                            <div key={term.type + term.termsId}>
                                <input
                                    id={term.termsId}
                                    className="checkbox"
                                    name="checkbox-group"
                                    type="checkbox"
                                    onChange={(e) => {
                                        checkboxHandler(e.target.id, e.target.checked);
                                    }}
                                    checked={checkedAgreements[term.termsId] ? checkedAgreements[term.termsId] : false}
                                />
                                <label htmlFor={term.termsId} className="checkbox-label">
                                    <span>(선택)</span>
                                    {term.contents}
                                </label>
                            </div>
                        );
                    }

                })}

                <div className="border-t">
                    <div className="padding-t20">
                        <input
                            id="checkboxAll"
                            className="checkbox"
                            name="checkbox-group"
                            type="checkbox"
                            onChange={(e) => {
                                handleAllCheckBox(e.target.checked);
                            }}
                            checked={allAgreement}
                        />
                        <label htmlFor="checkboxAll" className="checkbox-label">
                            전체 동의
                        </label>
                        <span className="fz12 color-999 padding-l30 dp margin-t5">
                            (선택)이벤트/혜택 알림을 포함하여 모두 동의합니다.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgreementTermsContentsView;