import BtnMenuContainer from './BtnMenuContainer';
import FilterWrapContainer from './FilterWrapContainer';
import MediaBtnWrapContainer from '../mediaBtn/MediaBtnWrapContainer';
import RoomListWrapContainer from './RoomListWrapContainer';
import SmallProfileContainer from './SmallProfileContainer';
import { UserProfileCOVO } from '@/site/api';
import { useMetaUserRepo } from '@/stores/useMetaUserRepo';

interface props {
	myProfile: UserProfileCOVO;
	srcPrefix: string;
	isPublicRoom: boolean;
	setMyProfileOpen: (myProfileOpen: boolean) => void;
}

// 메타버스 헤더
const HeaderContainer = ({
	myProfile,
	srcPrefix,
	isPublicRoom,
	setMyProfileOpen,
}: props) => {
	const { otherMetaUser, myMetaUserOpen, setMyMetaUser, setMyMetaUserOpen } =
		useMetaUserRepo();

	return (
		<header className="metaHeader">
			<div className="headerWrap">
				<BtnMenuContainer setMyMetaUserOpen={setMyMetaUserOpen} />
				<div className="h-right">
					{!isPublicRoom && <MediaBtnWrapContainer />}
					<RoomListWrapContainer />
					<FilterWrapContainer />
					<SmallProfileContainer
						myProfile={myProfile}
						srcPrefix={srcPrefix}
						setMyProfileOpen={setMyProfileOpen}
					/>
				</div>
			</div>
		</header>
	);
};

export default HeaderContainer;
