import React from "react";
import {ReactCropperElement} from "react-cropper";

interface props {
    setCropData: (fileURL: string, file: Blob) => void
    cropperRef: React.RefObject<ReactCropperElement> | null;
    setShowImgCrop: (showImgCrop: boolean) => void;
}

const ImgCropHeaderView = ({
                               setCropData,
                               cropperRef,
                               setShowImgCrop,
                           }: props) => {
    return (
        <>
            <header className="cateHeader headerBlack">
                <div className="headerWrap">
                    <div className="h-left">
                        <button
                            type="button"
                            className="btnClose_w"
                            onClick={() => {
                                setShowImgCrop(false);
                            }}>
                            <span className="hide">닫기</span>
                        </button>
                    </div>
                    <div className="h-center"></div>
                    <div className="h-right">
                        <button type="button"
                                className="btn"
                                onClick={() => {
                                    if (cropperRef?.current && typeof cropperRef.current?.cropper !== "undefined") {
                                        const fileURL = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
                                        cropperRef.current?.cropper.getCroppedCanvas().toBlob((file) => {
                                            if (file != null) {
                                                setCropData(fileURL, file);
                                            }
                                        });
                                    }
                                }}>
                            <span className="color-fff">완료</span>
                        </button>
                    </div>
                </div>
            </header>
        </>
    );
};

export default ImgCropHeaderView;
