import { formatTime } from "@/utils/util";

const MessageTimeView = ({ time }) => {
  return (
    <div className="chatTime">
      <span>{formatTime(time)}</span>
    </div>
  );
};

export default MessageTimeView;
