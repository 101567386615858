import {
	CreateMessageRoomCIVO,
	Message,
	MessageCIVO,
	MessageRoomCIVO,
	MessageRoomCOVO,
	MessageRoomIdCOVO,
	MessageService,
} from '@/site/api';
import { create } from 'zustand';

interface ApiDirectMessageRepo {
	// 메시지 방 아이디 생성
	createdDMRoomId: MessageRoomIdCOVO;
	setCreatedDMRoomId: (createdDMRoomId: MessageRoomIdCOVO) => void;

	// 메시지 방 생성
	messageRoom: CreateMessageRoomCIVO;
	createMessageRoom(map: CreateMessageRoomCIVO): void;

	// 메시지 방 여부 확인
	isExistingRoom: MessageRoomIdCOVO;
	setIsExistingRoom(map: MessageRoomIdCOVO): void;

	// 메시지 방 정보 수정
	updateMessageRoom(map: MessageRoomCIVO): void;

	// 메시지 방 리스트 조회
	messageRoomList: MessageRoomCOVO;
	getMessageRoomList(map: MessageRoomCOVO): void;

	// 메시지 리스트 조회
	messageRoomId: string;
	setMessageRoomId(messageRoomId: string): void;
	getMessageList(messageRoomId: string): void;

	// 메시지 내용 저장 (단건)
	message: MessageCIVO;
	saveMessage(message: Message): void;
}

const useApiDirectMessageRepo = create<ApiDirectMessageRepo>((set) => ({
	createdDMRoomId: {
		roomId: '',
	},
	setCreatedDMRoomId(createdDMRoomId: MessageRoomIdCOVO) {
		set({ createdDMRoomId });
	},

	messageRoom: {
		roomId: '',
		toUserUid: '',
		billingStatus: 0,
		content: '',
		createdAt: '',
		roomType: '',
	},
	createMessageRoom(messageRoom: CreateMessageRoomCIVO) {
		set({ messageRoom });
	},
	isExistingRoom: {
		roomId: '',
	},
	setIsExistingRoom(isExistingRoom: MessageRoomIdCOVO) {
		set({ isExistingRoom });
	},

	updateMessageRoom(map: MessageRoomCIVO) {
		return MessageService.updateMessgaeRoom(map);
	},

	messageRoomList: {
		roomId: '',
		user1Info: {},
		user2Info: {},
		roomType: '',
		lastMessage: '',
		lastMessageAt: '',
		unReadCnt: 0,
		visibleTo: 0,
		billingStatus: 0,
		createdAt: '',
	},
	getMessageRoomList(messageRoomList: MessageRoomCOVO) {
		set({ messageRoomList });
	},

	messageRoomId: '',
	setMessageRoomId(messageRoomId: string) {
		set({ messageRoomId });
	},
	getMessageList(messageRoomId: string) {
		return MessageService.getMessageList(messageRoomId);
	},

	message: {
		roomId: '',
		content: '',
		read: false,
		createdAt: '',
	},
	saveMessage(message: MessageCIVO) {
		set({ message });
	},
}));

export default useApiDirectMessageRepo;
