const LeaveChatPopView = ({
	shouldExitChat,
	handleExitChat,
	setOpenLeaveChat,
	handleLeaveChat,
}) => {
	return (
		<div className="leaveChatPopWrap">
			<div className="popupInfo">
				<p className="fz20 bold-500 text-c">채팅방을 나가시겠어요?</p>
				<p className="fz16Fw500 color-999 text-c padding-t8">
					방을 나간 후에는 다시 되돌릴 수 없어요.
				</p>
				<div className="popFtBtnWrap">
					<button
						type="button"
						className="popCloseBtn btn btnBig btnCCC"
						onClick={() => {
							setOpenLeaveChat(false);
						}}
					>
						<span>아니요</span>
					</button>
					<button
						type="button"
						className="popCloseBtn btn btnBig btnBlack"
						onClick={shouldExitChat ? handleExitChat : handleLeaveChat}
					>
						<span>예</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default LeaveChatPopView;
