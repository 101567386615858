/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommentCIVO } from '../models/CommentCIVO';
import type { CommentCOVO } from '../models/CommentCOVO';
import type { DeleteCommentCIVO } from '../models/DeleteCommentCIVO';
import type { ModifyPostCOVO } from '../models/ModifyPostCOVO';
import type { PostIdCIVO } from '../models/PostIdCIVO';
import type { PostInfoCOVO } from '../models/PostInfoCOVO';
import type { SearchPostCIVO } from '../models/SearchPostCIVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LoungeService {
    /**
     * 게시글 저장
     * @param formData
     * @returns boolean
     * @throws ApiError
     */
    public static savePost(
        formData: {
            files?: Array<Blob>;
            /**
             * 게시글 content
             */
            content?: string;
            /**
             * 태그 정보
             */
            tag?: any[];
        },
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/lounge/savePost',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * 게시글 수정 조회
     * @param postId
     * @returns ModifyPostCOVO
     * @throws ApiError
     */
    public static getModifyPost(
        postId: string,
    ): CancelablePromise<ModifyPostCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/lounge/getModifyPost',
            query: {
                'postId': postId,
            },
        });
    }
    /**
     * 게시글 수정
     * @param formData
     * @returns boolean
     * @throws ApiError
     */
    public static modifyPost(
        formData: {
            files?: Array<Blob>;
            /**
             * 게시글 ID
             */
            postId?: string;
            /**
             * 게시글 content
             */
            content?: string;
            /**
             * 태그 정보
             */
            tag?: any[];
        },
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/lounge/modifyPost',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * 게시글 제거
     * @param requestBody 게시글 ID
     * @returns boolean
     * @throws ApiError
     */
    public static removePost(
        requestBody: PostIdCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/lounge/removePost',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 게시글 전체 조회
     * @returns PostInfoCOVO
     * @throws ApiError
     */
    public static getPosts(): CancelablePromise<Array<PostInfoCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/lounge/getPosts',
        });
    }
    /**
     * 나의 게시글 전체 조회
     * @returns PostInfoCOVO 게시글 리스트
     * @throws ApiError
     */
    public static getMyPosts(): CancelablePromise<Array<PostInfoCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/lounge/getMyPosts',
        });
    }
    /**
     * 게시글 댓글 조회
     * @param postId
     * @returns CommentCOVO
     * @throws ApiError
     */
    public static getComment(
        postId: string,
    ): CancelablePromise<Array<CommentCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/lounge/getComment',
            query: {
                'postId': postId,
            },
        });
    }
    /**
     * 게시글 댓글 저장
     * @param requestBody
     * @returns boolean
     * @throws ApiError
     */
    public static saveComment(
        requestBody: CommentCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/lounge/saveComment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 게시글 댓글 삭제
     * @param requestBody
     * @returns boolean
     * @throws ApiError
     */
    public static deleteComment(
        requestBody: DeleteCommentCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/lounge/deleteComment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 게시글 검색 조회
     * @param requestBody
     * @returns boolean
     * @throws ApiError
     */
    public static getSearchPost(
        requestBody: SearchPostCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/lounge/getSearchPost',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 성별로 게시글 필터
     * @param gender 회원 성별 - M : 남성, F: 여성
     * @returns PostInfoCOVO
     * @throws ApiError
     */
    public static getFilteredPostsByGender(
        gender: string,
    ): CancelablePromise<Array<PostInfoCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/lounge/getPostsByGender',
            query: {
                'gender': gender,
            },
        });
    }
    /**
     * 게시물 상세 조회
     * @param postId
     * @returns PostInfoCOVO
     * @throws ApiError
     */
    public static getPostDetail(
        postId: string,
    ): CancelablePromise<PostInfoCOVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/lounge/getPostDetail',
            query: {
                'postId': postId,
            },
        });
    }
}
