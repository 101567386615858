interface props {
    btnActivate: boolean;
    fnSaveUserGender: () => void;
}
const GenderSelectFooterView = ({
                                    btnActivate,
                                    fnSaveUserGender,
}: props) => {
  return (
    <footer>
      <div className="f-btnWrap">
        <button
          className="btn btnBig btnBlack"
          onClick={fnSaveUserGender}
          disabled={btnActivate}
        >
          <span>다 음</span>
        </button>
      </div>
    </footer>
  );
};

export default GenderSelectFooterView;
