import { useEffect, useRef } from 'react';
import ChatMessageView from './ChatMessageView';

const ChatListView = ({ messagesEndRef, chatMessages, handleCloseChat }) => {
	const lastMessageIndex = useRef<number | null>(null);

	// 마지막 메시지의 인덱스 저장
	useEffect(() => {
		lastMessageIndex.current = chatMessages.length - 1;
	}, [chatMessages]);

	useEffect(() => {
		if (lastMessageIndex.current !== null) {
			messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
		}
	}, [messagesEndRef]);

	useEffect(() => {
		if (lastMessageIndex.current !== null) return;
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	}, [chatMessages]);

	return (
		<div className="PlayChat_list_wrap">
			<div className="PlayChat_actions">
				<button
					type="button"
					className="chatClose"
					onClick={() => {
						handleCloseChat();
					}}
				/>
			</div>
			<div data-chat-list="true">
				{chatMessages.map((chatMessage, index) => (
					<ChatMessageView key={index} chatMessage={chatMessage} />
				))}
				<div ref={messagesEndRef} />
			</div>
		</div>
	);
};

export default ChatListView;
