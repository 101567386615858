interface props {
    profileAddTitle: string;
    setProfileAddTitle: (profileAddTitle: string) => void;
    profileAddDetail: string;
    setProfileAddDetail: (profileAddTitle: string) => void;
}

const AddProfileInputBoxView = ({
                                    profileAddTitle,
                                    setProfileAddTitle,
                                    profileAddDetail,
                                    setProfileAddDetail,
                                }: props) => {
    return (
        <div className="inputBox">
            <input
                className="margin-b8 input"
                type="text"
                placeholder="분류를 입력해 주세요 ex) 자격증, 수상경력..."
                value={profileAddTitle}
                onChange={(e) => {
                    if (e.target.value.length > 0) {
                        setProfileAddTitle(e.target.value);
                    }
                }}
            />
            <textarea
                name=""
                id=""
                cols={30}
                rows={10}
                placeholder="내용을 입력해 주세요
ex) 바리스타, 미술대회 입선..."
                value={profileAddDetail}
                onChange={(e) => {
                    if (e.target.value.length > 0) {
                        setProfileAddDetail(e.target.value);
                    }
                }}
            />
        </div>
    );
};

export default AddProfileInputBoxView;
