import MessengerRouter from './routers/MessengerRouter';
import MetaverseLayout from './MetaverseLayout';
import datingRoutes from '@dating/routers';
import { createBrowserRouter, Outlet, redirect } from 'react-router-dom';
import { MetaverseProvider } from './MetaverseProvider';
const Root = () => (
	<MetaverseProvider>
		<Outlet />
	</MetaverseProvider>
);
export const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		children: [
			{
				index: true,
				loader: () => redirect('metaverse/dating/register'),
			},
			{
				path: 'metaverse',
				element: <MetaverseLayout />,
				children: [
					{
						path: 'messenger/*',
						element: <MessengerRouter />,
					},
					{
						path: 'dating/*',
						children: datingRoutes,
					},
				],
			},
		],
	},
	{
		path: '*',
		loader: () => redirect('/'),
	},
]);
