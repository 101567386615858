import { RouteObject } from 'react-router-dom';
import ProfileRouter from '@dating/routers/ProfileRouter';
import HomeRouter from './HomeRouter';
import LoungeRouter from './LoungeRouter';
import LikeRouter from './LikeRouter';
import SettingRouter from './SettingRouter';
import SideBarRouter from './SideBarRouter';
import RegisterRouter from './RegisterRouter';
import ImgCropRouter from '@dating/routers/ImgCropRouter';
import IdealMatchFinderRouter from './IdealMatchFinderRouter';
const datingRoutes: RouteObject[] = [
	{
		path: 'register/*',
		element: <RegisterRouter />,
	},
	{
		path: 'home/*',
		element: <HomeRouter />,
	},
	{
		path: 'idealMatchFinder/*',
		element: <IdealMatchFinderRouter />,
	},
	{
		path: 'lounge/*',
		element: <LoungeRouter />,
	},
	{
		path: 'like/*',
		element: <LikeRouter />,
	},
	{
		path: 'profile/*',
		element: <ProfileRouter />,
	},
	{
		path: 'settings/*',
		element: <SettingRouter />,
	},
	{
		path: 'sideBar/*',
		element: <SideBarRouter />,
	},
	{
		path: 'imgCrop/*',
		element: <ImgCropRouter />,
	},
];

export default datingRoutes;
