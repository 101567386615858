interface props {
    approvedAccount: boolean;
};

const WaitingHeaderView = ({
                               approvedAccount,
                           }: props) => {
    return (
        <>
            <div className="titleWrap">
                <h2 className="title01">
                    {
                        approvedAccount ?
                            "가입 승인 완료"
                            : "가입 승인 대기 중"
                    }
                </h2>
                <p className="titleSub01">
                    {
                        approvedAccount ?
                            "이제 매칭 서비스를 이용할 수 있습니다.\n" +
                            "메타버스를 통해 새로운 인연과 함께 하세요"
                            : "안전한 사용을 위해 회원님의 정보를 확인하고 있습니다. 승인 완료 시 알림을 보내드립니다."
                    }
                </p>
            </div>
        </>
    );
};

export default WaitingHeaderView;