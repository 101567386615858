import MyPlayer from './myplayer/MyPlayer';
import OtherPlayer from './otherplayer/OtherPlayer';

// myPlayer 게임 객체 등록
declare global {
	namespace Phaser.GameObjects {
		interface GameObjectFactory {
			myPlayer(
				x: number,
				y: number,
				texture: string,
				id: string,
				uuid: string,
				frame?: string | number,
			): MyPlayer;
		}
	}
}

// otherPlayer 게임 객체 등록
declare global {
	namespace Phaser.GameObjects {
		interface GameObjectFactory {
			otherPlayer(
				x: number,
				y: number,
				texture: string,
				id: string,
				uuid: string,
				name: string,
				statusMessage: string,
				audioStatus: boolean,
				frame?: string | number,
			): OtherPlayer;
		}
	}
}

function registerGameObjects() {
	// myPlayer 게임 객체 생성 함수 등록
	Phaser.GameObjects.GameObjectFactory.register(
		'myPlayer',
		function (
			this: Phaser.GameObjects.GameObjectFactory,
			x: number,
			y: number,
			texture: string,
			id: string,
			uuid: string,
			name: string,
			statusMessage: string,
			audioStatus: boolean,
			frame?: string | number,
		) {
			const sprite = new MyPlayer(
				this.scene,
				x,
				y,
				texture,
				id,
				uuid,
				name,
				statusMessage,
				audioStatus,
				frame,
			);

			this.displayList.add(sprite);
			this.updateList.add(sprite);

			this.scene.physics.world.enableBody(
				sprite,
				Phaser.Physics.Arcade.DYNAMIC_BODY,
			);

			const collisionScale = [0.2, 0.2];
			sprite
				.body!.setSize(
					sprite.width * collisionScale[0],
					sprite.height * collisionScale[1],
				)
				.setOffset(
					sprite.width * (1 - collisionScale[0]) * 0.5,
					sprite.height * (1 - collisionScale[1]),
				);

			return sprite;
		},
	);
	Phaser.GameObjects.GameObjectFactory.register(
		'otherPlayer',
		function (
			this: Phaser.GameObjects.GameObjectFactory,
			x: number,
			y: number,
			texture: string,
			id: string,
			uuid: string,
			name: string,
			statusMessage: string,
			audioStatus: boolean,
			frame?: string | number,
		) {
			const sprite = new OtherPlayer(
				this.scene,
				x,
				y,
				texture,
				id,
				uuid,
				name,
				statusMessage,
				audioStatus,
				frame,
			);

			this.displayList.add(sprite);
			this.updateList.add(sprite);

			this.scene.physics.world.enableBody(
				sprite,
				Phaser.Physics.Arcade.DYNAMIC_BODY,
			);

			const collisionScale = [2, 1];
			sprite
				.body!.setSize(
					sprite.width * collisionScale[0],
					sprite.height * collisionScale[1],
				)
				.setOffset(
					sprite.width * (1 - collisionScale[0]) * 0.5,
					sprite.height * (1 - collisionScale[1]) * 0.5,
				);

			return sprite;
		},
	);
}

function unregisterGameObjects() {
	Phaser.GameObjects.GameObjectFactory.remove('myPlayer');
	Phaser.GameObjects.GameObjectFactory.remove('otherPlayer');
}

export { registerGameObjects, unregisterGameObjects };
