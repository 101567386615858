import { Link } from 'react-router-dom';

const BtnMenuView = () => {
	return (
		<Link to="/metaverse/dating/sideBar">
			<button type="button" className="btnMenu" />
		</Link>
	);
};

export default BtnMenuView;
