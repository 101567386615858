import {CSSTransition} from 'react-transition-group';
import {IdealMatchFinderHeaderView} from "@dating/ui/pages/hushPick/views/IdealMatchFinderHeaderView";
import IdealMatchFinderTabView from "@dating/ui/pages/hushPick/views/IdealMatchFinderTabView";
import {DdayProfileBoxView} from "@dating/ui/pages/hushPick/views/DdayProfileBoxView";
import {useHushPickService} from "@dating/ui/pages/hushPick/services/useHushPickService";
import {useLatestPicksService} from "@dating/ui/pages/hushPick/latestPicks/useLatestPicksService";
import {HushPickConfirmPopView} from "@dating/ui/pages/hushPick/HushPickConfirmPopView";

const LatestPicksContainer = () => {
    const {
        activeTab,
        tabOptions,
        setActiveTab,
        hushPickPopMessage,
        hushPickPopOpen,
        setHushPickPopOpen,
        fnHushPickPopOn,
        fnHushPickPopOff,
        fnHushPickPopExecute,
    } = useHushPickService();

    const {
        pastRecentLoginUsers,
        recentLoginUsers,
        srcPrefix,
        setUserProfileOpen,
        myProfile,
        fnGetRecentLoginUsers,
    } = useLatestPicksService();

    return (
        <>
            <div className="wrap noFooter">
                <IdealMatchFinderHeaderView/>

                <IdealMatchFinderTabView
                    activeTab={activeTab}
                    tabOptions={tabOptions}
                    setActiveTab={setActiveTab}
                />

                <div className="toDayWrap padding-b0 headerExceptH">
                    <div className="tabConWrap inner">
                        <div className="tabContent">
                            <div className="tabCon tabCon02 active">
                                <div className="profileList imgSmList">
                                    {/*최근 접속 이성 추천 리스트*/}
                                    {recentLoginUsers.length > 0
                                        ? recentLoginUsers.map((profile, i) => {
                                            return (
                                                <DdayProfileBoxView
                                                    key={profile.nickName + i}
                                                    type={"homePast"}
                                                    profile={profile}
                                                    srcPrefix={srcPrefix}
                                                    setUserProfileOpen={setUserProfileOpen}
                                                    currentUserUid={myProfile.userUid}
                                                />
                                            );
                                        })
                                        : null}

                                    {/*최근 접속 이성 추천 히스토리*/}
                                    {pastRecentLoginUsers.length > 0
                                        ? pastRecentLoginUsers.map((profile, i) => {
                                            return (
                                                <DdayProfileBoxView
                                                    key={profile.nickName + i}
                                                    type={"homePast"}
                                                    profile={profile}
                                                    srcPrefix={srcPrefix}
                                                    setUserProfileOpen={setUserProfileOpen}
                                                    currentUserUid={myProfile.userUid}
                                                />
                                            );
                                        })
                                        : null}
                                </div>
                                <div className="hushRecoBox grayBox">
                                    <p className="title02">최근 HUSH에 접속한 이성이 더 궁금해요</p>
                                    <button
                                        type="button"
                                        className="blackRoundBtn"
                                        onClick={() => {
                                            // 과금팝업호출
                                            setHushPickPopOpen(true);
                                        }}
                                    >최근 접속 이성 추천 받기
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`${
                    hushPickPopOpen ? 'settingPopOn' : 'settingPop'
                }`}
            >
                <div className="settingDim"/>
                <CSSTransition
                    in={hushPickPopOpen}
                    classNames="logoutWrap-transition"
                    timeout={100}
                >
                    <HushPickConfirmPopView
                        popTitle={"최근 접속 이성"}
                        popDetail={"24시간 이내 접속한 이성을 추천해 드려요"}
                        fnCommonConfirmPopExecute={fnGetRecentLoginUsers}
                        fnHushPickPopOff={fnHushPickPopOff}
                    />
                </CSSTransition>
            </div>
        </>
    );
};

export default LatestPicksContainer;
