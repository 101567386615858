import IdealRangeSettingView from "@dating/ui/pages/register/Ideal/views/IdealRangeSettingView";
import IdealCateListView from "@dating/ui/pages/register/Ideal/views/IdealCateListView";

interface props {
    step: number;
    showPopup: () => void;
    setPopupType: (popupType: string) => void;
    formatAgeValue: (age: number) => string;
    ageRange: { min: number, max: number };
    handleAgeChange: (ageRange: { min: number, max: number }) => void;
    formatDistanceValue: (distance: number) => string;
    nearRange: { min: number, max: number };
    handleNearChange: (nearRange: { min: number, max: number }) => void;
    formatHeightValue: (height: number) => string;
    heightRange: { min: number, max: number };
    handleHeightChange: (heightRange: { min: number, max: number }) => void;
    religion: {code: string, value: string};
    drinkingState: {code: string, value: string};
    smokingState: {code: string, value: string};
    initAgeRange: { min: number, max: number };
    initNearRange: { min: number, max: number };
    initHeightRange: { min: number, max: number };
    nearOverRange: boolean;
    ageOverRange: boolean;
    heightOverRange: boolean;
}

const IdealSettingView = ({
                              step,
                              showPopup,
                              setPopupType,
                              formatAgeValue,
                              ageRange,
                              handleAgeChange,
                              formatDistanceValue,
                              nearRange,
                              handleNearChange,
                              formatHeightValue,
                              heightRange,
                              handleHeightChange,
                              religion,
                              drinkingState,
                              smokingState,
                              initAgeRange,
                              initNearRange,
                              initHeightRange,
                              nearOverRange,
                              ageOverRange,
                              heightOverRange,
                          }: props) => {
    return (
            <div className="contents">
                <IdealRangeSettingView
                    formatAgeValue={formatAgeValue}
                    ageRange={ageRange}
                    handleAgeChange={handleAgeChange}
                    formatDistanceValue={formatDistanceValue}
                    nearRange={nearRange}
                    handleNearChange={handleNearChange}
                    formatHeightValue={formatHeightValue}
                    heightRange={heightRange}
                    handleHeightChange={handleHeightChange}
                    initAgeRange={initAgeRange}
                    initNearRange={initNearRange}
                    initHeightRange={initHeightRange}
                    nearOverRange={nearOverRange}
                    ageOverRange={ageOverRange}
                    heightOverRange={heightOverRange}
                />
                <IdealCateListView
                    showPopup={showPopup}
                    setPopupType={setPopupType}
                    religion={religion}
                    drinkingState={drinkingState}
                    smokingState={smokingState}
                />
            </div>
    );
};

export default IdealSettingView;