import { create } from 'zustand';

export interface VideoRepoState {
	myVideoStream: MediaStream | null;
	addedVideos: Map<string, HTMLVideoElement>;
	openCamExpansion: {
		isOpen: boolean;
		videoElement: HTMLVideoElement | null;
	};
	setMyVideoStream: (stream: MediaStream | null) => void;
	setAddedVideos: (videoId: string, videoElement: HTMLVideoElement) => void;
	setDeletedVideos: (videoId: string) => void;
	setOpenCamExpansion: (
		isOpen: boolean,
		videoElement: HTMLVideoElement | null,
	) => void;
}

export const useVideoRepo = create<VideoRepoState>((set) => ({
	myVideoStream: null,
	addedVideos: new Map<string, HTMLVideoElement>(),
	openCamExpansion: {
		isOpen: false,
		videoElement: null,
	},

	setMyVideoStream: (stream) => set({ myVideoStream: stream }),

	setAddedVideos: (videoId, videoElement) =>
		set((state) => ({
			addedVideos: new Map(state.addedVideos).set(videoId, videoElement),
		})),

	setDeletedVideos: (videoId) =>
		set((state) => {
			const newAddedVideos = new Map(state.addedVideos);
			newAddedVideos.delete(videoId);
			return { addedVideos: newAddedVideos };
		}),

	setOpenCamExpansion: (isOpen, videoElement) =>
		set({
			openCamExpansion: {
				isOpen,
				videoElement,
			},
		}),
}));
