import {useDatingRepo} from "@dating/repository/dating/useDatingRepo";
import {useSettingAdapter} from "@dating/adapter/setting/useSettingAdapter";
import {useSettingsRepo} from "@dating/repository/settings/useSettingsRepo";
import {useEffect} from "react";
import {type BlockUserCIVO} from "@/site/api";

export const useBlockListService = () => {
    const title = '차단 목록';

    const {
        blockUsers,
        setBlockUsers,
    } = useSettingsRepo();

    const {
        srcPrefix,
    } = useDatingRepo();

    const {
        getBlockUser,
        saveUnblockUser,
        statusSaveUnblockUser,
        dataSaveUnblockUser,
    } = useSettingAdapter();

    const fnGetBlockUser = () => {
        getBlockUser().then((response) => {
            setBlockUsers(response);
        });
    };

    useEffect(() => {
        fnGetBlockUser();
    }, []);

    const fnSaveUnblockUser = (blockUserUid: string) => {
        const map: BlockUserCIVO = {
            blockUserUid: blockUserUid,
        };
        saveUnblockUser(map);
    };

    useEffect(() => {
        if (statusSaveUnblockUser === "success" && dataSaveUnblockUser) {
            alert("해당 사용자가 차단해제 되었습니다.");
            fnGetBlockUser();
        }
    }, [statusSaveUnblockUser, dataSaveUnblockUser]);

    useEffect(() => {

    }, [blockUsers]);

    return {
        blockUsers,
        srcPrefix,
        fnSaveUnblockUser,
        title,
    };
};
