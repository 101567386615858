/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HomeMatchingCOVO } from '../models/HomeMatchingCOVO';
import type { HomePastMatchingUserCOVO } from '../models/HomePastMatchingUserCOVO';
import type { RandomUserCIVO } from '../models/RandomUserCIVO';
import type { RandomUserScoreCIVO } from '../models/RandomUserScoreCIVO';
import type { Top10PerUserCOVO } from '../models/Top10PerUserCOVO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HomeService {
    /**
     * 선호정보에 맞는 이상형 5명
     * @returns HomeMatchingCOVO
     * @throws ApiError
     */
    public static getPreferUser(): CancelablePromise<Array<HomeMatchingCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/home/getPreferUser',
        });
    }
    /**
     * 선호 정보에 맞는 이상형 과거 10명
     * @returns HomePastMatchingUserCOVO
     * @throws ApiError
     */
    public static getPastPreferUser(): CancelablePromise<Array<HomePastMatchingUserCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/home/getPastPreferUser',
        });
    }
    /**
     * 인기 있는 이성 추천 받기
     * @returns boolean
     * @throws ApiError
     */
    public static addPopularUser(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/addPopularUser',
        });
    }
    /**
     * 랜덤 추천 상대 5명
     * @returns HomeMatchingCOVO
     * @throws ApiError
     */
    public static getRandomUser(): CancelablePromise<Array<HomeMatchingCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/home/getRandomUser',
        });
    }
    /**
     *  랜덤 추천 상대 추가(매력 평가 더 하기)
     * @returns boolean
     * @throws ApiError
     */
    public static addRandomUser(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/addRandomUser',
        });
    }
    /**
     * 랜덤 추천 상대 삭제
     * @param requestBody 랜덤  회원 ID
     * @returns boolean
     * @throws ApiError
     */
    public static deleteRandomUser(
        requestBody: RandomUserCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/deleteRandomUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 랜덤 추천 상대 점수 평가
     * @param requestBody 랜덤 회원 ID및 점수
     * @returns boolean
     * @throws ApiError
     */
    public static saveScoreRandomUser(
        requestBody: RandomUserScoreCIVO,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/home/saveScoreRandomUser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * 상위 10% 이성 추천 받기
     * @returns Top10PerUserCOVO
     * @throws ApiError
     */
    public static getTop10PercentUsers(): CancelablePromise<Array<Top10PerUserCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/home/getTop10PercentUsers',
        });
    }
    /**
     * 추천 받은 상위 10% 과거 5명
     * @returns HomePastMatchingUserCOVO
     * @throws ApiError
     */
    public static getPastTopUser(): CancelablePromise<Array<HomePastMatchingUserCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/home/getPastTopUser',
        });
    }
    /**
     * 최근 접속한 이성 추천 받기
     * @returns Top10PerUserCOVO
     * @throws ApiError
     */
    public static getRecentLoginUsers(): CancelablePromise<Array<Top10PerUserCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/home/getRecentLoginUsers',
        });
    }
    /**
     * 24시간 이내 접속한 이성 추천 받은 내역
     * @returns HomePastMatchingUserCOVO
     * @throws ApiError
     */
    public static getPastRecentLoginUser(): CancelablePromise<Array<HomePastMatchingUserCOVO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/home/getPastRecentLoginUser',
        });
    }
}
