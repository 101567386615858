import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import AgreeListView from '@dating/ui/pages/settings/agree/views/AgreeListView';

export const Policy02Container = () => {
	return (
		<div className="rightShow active">
			<ButtonHeaderView right={false} />

			<div className="popConWrap bdNone">
				<div className="inner">
					<div className="agreeWrap">
						<div className="titleWrap">
							<h2 className="title01">개인정보 수집 및 이용동의</h2>
							<p className="titleSub01">
								<i>시행일자 : 2024.10.31 ~ 시행</i>
							</p>
						</div>

						<p>
							‘HUSH 서비스(이하 ‘본 서비스’)’를 운영하는 IBIZsoftware(이하’회사’)는「개인정보 보호법」30조에 의거 이용자의 개인정보보호와 권익을 보호하고
							관련된 고충
							및 애로사항을 신속하게 처리하기 위해 아래의 개인정보 수집및 이용동의를 받고 있습니다. 회사는 관계법령에서 규정하고 있는 책임과 의무를 준수하고 실천하기 위해 최선의
							노력을
							하고 있습니다.
						</p>

						<div className="section section01">
							<h3 className="title02 margin-t40">1. 개인정보의 처리 목적</h3>
							<p>
								회사는 다음의 목적을 위해 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는
								경우에는「개인정보
								보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
							</p>
							<ul className="dot">
								<li>
									고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별, 인증, 회원자격 유지, 관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는
									서비스의
									공급, 배송
								</li>
							</ul>
						</div>

						<div className="section section02">
							<h3 className="title02 margin-t40">2. 처리하는 개인정보의 항목</h3>
							<h4 className="titleSub02">1) 정보주체의 동의를 받지 않고 처리하는 개인정보 항목</h4>
							<p>
								회사는 다음의 목적을 위해 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는
								경우에는「개인정보
								보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
							</p>
							<ul className="dot">
								<li>
									고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별, 인증, 회원자격 유지, 관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는
									서비스의
									공급, 배송
								</li>
							</ul>
							<div className="tbl-list">
								<table>
									<thead>
									<tr>
										<th>법적 근거</th>
										<th>구분</th>
										<th>수집 목적</th>
										<th>수집 항목</th>
										<th>보유 기간</th>
									</tr>
									</thead>
									<tbody>
									<tr>
										<td>개인정보 보호법 제15조 제1항 제4호 (계약의 이행)</td>
										<td>회원가입/서비스 이용</td>
										<td>서비스 이용을 위한 이용자 식별 및 이용자 개별적 통지 고지</td>
										<td>전화번호, 닉네임, 생년월일, 키, 직업, 프로필 사진, 이상형 설정 (나이, 거리, 키, 종교, 음주, 흡연), 사용자 위치</td>
										<td>회원 탈퇴 시까지</td>
									</tr>
									<tr>
										<td>개인정보 보호법 제15조 제1항 제4호 (계약의 이행)</td>
										<td>본인인증</td>
										<td>앱 내 본인인증 서비스 제공</td>
										<td>CI, DI, 휴대폰 번호, 생년월일, 성별, 통신사, 내/외국인 정보</td>
										<td>회원 탈퇴 시까지</td>
									</tr>
									<tr>
										<td>개인정보 보호법 제15조 제1항 제4호 (계약의 이행)</td>
										<td>본인인증</td>
										<td>유료서비스 이용 시 서비스 이용료 지불을 위함</td>
										<td>이름, 구매상품 정보, 신용카드 정보 (카드번호, 카드 만료일), 은행 계좌 정보, 결제 기록</td>
										<td>회원 탈퇴 시까지</td>
									</tr>
									</tbody>
								</table>
							</div>

							<h4 className="titleSub02">2) 정보주체의 동의를 받아 처리하는 개인정보 항목</h4>
							<p>
								회사는 다음의 개인정보 항목을 「개인정보 보호법」 제15조 제1항 제1호 및 제22조 제1항 제7호에 따라 정보주체의 동의를 받아 처리하고 있습니다. 회사는
								회원가입
								시 명시된 목적 외의 다른 목적으로 회원의 개인정보를 활용하고자 할 경우, 사전에 회원에게 해당 목적을 안내하고 별도의 동의를 받습니다.
							</p>

							<div className="tbl-list">
								<table>
									<thead>
									<tr>
										<th
											style={{
												'width': '15%',
											}}
										>구분
										</th>
										<th>수집 목적</th>
										<th>수집 항목</th>
										<th>보유 및 이용 기간</th>
									</tr>
									</thead>
									<tbody>
									<tr>
										<td>사용자 추가정보</td>
										<td>이용자 선호도 매칭</td>
										<td>관심사, 라이프 스타일, 종교 여부, 음주 여부, 흡연 여부</td>
										<td>회원 탈퇴 시까지</td>
									</tr>
									<tr>
										<td>이벤트 참여</td>
										<td>이벤트 당첨 시 회원 확인을 위함</td>
										<td>성명, 휴대전화번호, 구매내역</td>
										<td>이벤트 종료 시 파기</td>
									</tr>
									<tr>
										<td>서비스 이용 통계</td>
										<td>신규 서비스 및 서비스 개발</td>
										<td>IP주소, 국가 정보, 접속 시간, 모바일 기기 정보 (OS버전, 디바이스 ID, 언어)</td>
										<td>수집일로부터 3개월</td>
									</tr>
									<tr>
										<td>광고성 발송</td>
										<td>각종 이벤트나 광고성 정보의 제공 및 참여 기회 제공, 맞춤형 광고 게재</td>
										<td>Facebook 광고 ID, 구글 광고 ID, 단말기 정보, 도시 기반 위치 정보 등</td>
										<td>수집일로부터 6개월 (전자상거래법 6조 3항)</td>
									</tr>
									<tr>
										<td>CS</td>
										<td>소비자의 불만 또는 분쟁 처리를 위함</td>
										<td>이름, 닉네임, 가입일자, 마지막 접속일자, 분쟁 내용, 문제 발생 시점, 상대방 정보 (타 사용자 관련된 경우), 증빙 자료, 처리
											기록
										</td>
										<td>수집일로부터 3년 (전자상거래법 6조)</td>
									</tr>
									</tbody>
								</table>
							</div>

							<h4 className="titleSub02">3) 기타</h4>

							<ul className="dot">
								<li>회사가 처리하는 회원정보의 목적과 항목이 변경될 경우에는 관련법령에 따라 사전에 동의를 요청합니다.</li>
								<li>회사는 만 17세 미만 아동에게 서비스를 제공하지 않으며 개인정보를 수집하지 않습니다.</li>
								<li>회사는 주민등록번호 처리를 원칙적으로 금지하며, 업무 수행 중 법률, 대통령령, 국회규칙, 대법원규칙, 헌법재판소규칙, 중앙선거관리위원회 규칙 및
									감사원규칙에서 구체적으로 주민등록번호의 처리를 요구할 경우에만 처리하고 있습니다.
								</li>
								<li>회사는 다음의 방법으로 이용자의 개인정보를 수집합니다.
									<ul className="dot">
										<li>회사가 운영하는 웹사이트와 어플리케이션에 이용자가 직접 입력하게 하는 방식</li>
										<li>서비스를 이용하는 과정에 쿠키, 접속로그 등 자동으로 생성 및 수집되는 방식</li>
										<li>서비스 이용 과정에서 이용자가 개인정보를 직접 입력하는 방식</li>
									</ul>
								</li>
								<li>회사는 모바일 앱 서비스를 위해 이용자의 이동통신단말기 내 정보 및 기능 중 아래 사항에 대해 접근을 합니다. 필수 접근 항목은 앱 설치 또는 최초
									실행 시
									안내의 동의를 받으며, 선택 접근 항목은 해당 서비스 진입 시 별도 동의를 받습니다. 선택 접근 항목의 경우 OS버전에 따라 동의 받는 방법이 다를 수
									있으나, 이용자가 거부하시더라도 기본 서비스 이용에는 제한이 없습니다.
								</li>
							</ul>

							<div className="tbl-list">
								<table>
									<thead>
									<tr>
										<th>보유/이용 기간</th>
										<th>접근 항목</th>
										<th>접근 목적</th>
									</tr>
									</thead>
									<tbody>
									<tr>
										<td>필수</td>
										<td>저장용량</td>
										<td>기기 사진/파일 접근 및 저장 권한</td>
									</tr>
									<tr>
										<td>필수</td>
										<td>사진</td>
										<td>프로필 사진 등록</td>
									</tr>
									<tr>
										<td>필수</td>
										<td>전화</td>
										<td>중복가입 및 부정 유저를 차단하기 위해 수집</td>
									</tr>
									<tr>
										<td>필수</td>
										<td>위치 정보</td>
										<td>현재 위치에 기반한 매칭 상대 추천을 위해 수집</td>
									</tr>
									<tr>
										<td>선택</td>
										<td>연락처</td>
										<td>연락처를 이용하여 지인들에게 고객의 개인정보가 노출되지 않도록 보호하기 위함</td>
									</tr>
									<tr>
										<td>선택</td>
										<td>카메라</td>
										<td>메타버스 내 카메라 기능 on 시 사용</td>
									</tr>
									<tr>
										<td>선택</td>
										<td>마이크</td>
										<td>메타버스 내 마이크 기능 on 시 사용</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div className="section section03">
							<h3 className="title02 margin-t40">3. 개인정보의 보유 및 이용 기간</h3>
							<p>
								회원가입시 및 서비스 이용 중 수집된 정보는 회원탈퇴시 혹은 개인정보 수집 및 이용목적이 달성된 이후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의
								정보에
								대해서는 하기의 이유로 회원탈퇴 후에도 명시한 기간 동안 해당 정보를 보존합니다.
							</p>
							<ul className="dot">
								<li>회사 내부방침에 의한 사유</li>
							</ul>
							<div className="tbl-list">
								<table>
									<thead>
									<tr>
										<th>항목</th>
										<th>근거법령</th>
										<th>보유기간</th>
									</tr>
									</thead>
									<tbody>
									<tr>
										<td>계약 또는 청약철회 등에 관한 기록</td>
										<td>전자상거래등에서의 소비자보호에 관한 법률</td>
										<td className="text-c">5년</td>
									</tr>
									<tr>
										<td>대금결제 및 재화 등의 공급에 관한 기록</td>
										<td>전자상거래등에서의 소비자보호에 관한 법률</td>
										<td className="text-c">5년</td>
									</tr>
									<tr>
										<td>전자금융 거래에 관한 기록</td>
										<td>전자금융거래법</td>
										<td className="text-c">5년</td>
									</tr>
									<tr>
										<td>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</td>
										<td>국세기본법</td>
										<td className="text-c">5년</td>
									</tr>
									<tr>
										<td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
										<td>전자상거래등에서의 소비자보호에 관한 법률</td>
										<td className="text-c">3년</td>
									</tr>
									<tr>
										<td>앱 접속 기록</td>
										<td>통신비밀보호법</td>
										<td className="text-c">2년</td>
									</tr>
									<tr>
										<td>경품 등의 제공에 따른 제세공과금 처리 관련 항목</td>
										<td>소득세법</td>
										<td className="text-c">5년</td>
									</tr>
									<tr>
										<td>서비스 이용 기록, 접속 로그, 접속 IP 정보</td>
										<td>통신비밀보호법</td>
										<td className="text-c">3개월</td>
									</tr>
									<tr>
										<td>광고에 관한 기록</td>
										<td>전자상거래등에서의 소비자보호에 관한 법률</td>
										<td className="text-c">6개월</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div className="section section04">
							<h3 className="title02 margin-t40">4. 개인정보의 파기 절차 및 방법</h3>
							<p>
								이용자의 개인정보는 수집 및 이용목적이 달성된 때에 지체 없이 파기합니다. ‘이용목적이 달성된 때’ 란 철회요청, 서비스계약 만료, 탈퇴 시를 의미합니다.
								정보주체의
								동의를 받은 개인정보는 수집 및 이용목적이 달성된 때에는 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 관계 법령에서 정한 일정한 기간 보관한 다음
								파기합니다.
								종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
								삭제합니다.
							</p>
						</div>

						<div className="section section05">
							<h3 className="title02 margin-t40">5. 개인정보의 제3자 제공 및 처리 위탁</h3>
							<p>
								고객이 서비스 이용과정 등에서 매니저 서비스 구매 등으로 따로 동의하는 경우나 법령에 규정된 경우를 제외하고는 고객의 개인 정보를 위에서 말씀드린 목적 범위를
								초과하여 이용하거나 제3자에게 제공 또는 공유하지 않습니다.
							</p>
							<ul className="dot">
								<li>
									관련법령에 근거한 사전동의 없는 제3자 제공안내
									<ul className="dot">
										<li>통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우</li>
										<li>관계법령에 의하여 국가기관으로부터 요구받은 경우</li>
										<li>범죄에 대한 수사상의 목적이 있거나, 정보통신 윤리위원회의 요청이 있는 경우</li>
										<li>기타 관계법령에서 정한 절차에 따른 요청이 있는 경우</li>
									</ul>
								</li>
							</ul>
							<p>
								하지만 서비스 향상, 법령이나 이용 약관에 반하여 다른 고객에게 피해를 줄 수 있는 잘못된 이용행위의 차단, 방지 및 안정적인 개인정보 취급을 위해서 고객의
								개인정보를 외부에 위탁하여 처리하고 있습니다. 위탁처리 기관 및 위탁업무의 내용은 아래를 참조해 주세요.
							</p>
							<ul className="dot">
								<li>
									위탁 계약 체결시 관련법령에 따라 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
								</li>
								<li>
									위탁업무의 내용이나 수탁자가 추가, 변경될 경우에는 지체없이 관련 법령에 따른 사전 동의 안내 또는 본 개인정보 처리방침을 통하여 공개하도록
									하겠습니다.
								</li>
							</ul>
							<div className="tbl-list">
								<table>
									<thead>
									<tr>
										<th>수탁자</th>
										<th>위탁업무</th>
										<th>연락처</th>
									</tr>
									</thead>
									<tbody>
									<tr>
										<td></td>
										<td className="text-c">전화번호 인증</td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td className="text-c">시스템 운영 및 데이터 보관</td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td className="text-c">인앱결제(안드로이드)</td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td className="text-c">인앱결제(OS)</td>
										<td></td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div className="section section06">
							<h3 className="title02 margin-t40">6. 정보주체와 법정대리인의 권리・의무 및 행사방법</h3>
							<ul className="num">
								<li>정보주체는 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</li>
								<li>개인정보의 열람 및 처리정지 요구는 개인정보 보호법에 의하여 정보주체의 권리가 제한될 수 있습니다.</li>
								<li>개인정보의 열람 및 정정은 '프로필 &#62; 프로필 수정'에서 가능하며, 회원탈퇴는 '프로필 &#62; 설정 &#62; 계정설정 &#62; 계정삭제'에서 가능합니다.</li>
								<li>정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</li>
							</ul>
						</div>

						<div className="section section07">
							<h3 className="title02 margin-t40">7. 개인정보의 안정성 확보조치</h3>
							<p>
								회사는「개인정보보호법」 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다
							</p>
							<ul className="num">
								<li>관리적 조치: 내부관리계획 수립·시행, 전담조직 운영, 정기적 직원 교육</li>
								<li>기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 개인정보의 암호화, 보안프로그램 설치 및 갱신</li>
								<li>물리적 조치: 전산실, 자료보관실 등의 접근통제</li>
							</ul>
						</div>

						<div className="section section08">
							<h3 className="title02 margin-t40">8. 개인정보의 안정성 확보조치</h3>
							<p>
								서비스 이용 과정에서 아래의 정보들이 자동으로 생성되어 수집될 수 있습니다.
							</p>
							<ul className="num">
								<li>IP주소, 기기정보, 방문기록, 서비스 이용 기록 등</li>
							</ul>
						</div>

						<div className="section section09">
							<h3 className="title02 margin-t40">9. 개인정보 보호책임자에 관한 사항</h3>
							<p>
								사용자가 서비스를 이용하면서 발생하는 모든 개인정보보호 관련 문의, 불만, 조언이나 기타 사항은 개인정보 보호책임자에게 연락해주세요. 회사는 사용자의 불편과
								필요에
								대해 귀 기울이고 신속하고 충분한 답변을 드릴 수 있도록 최선을 다하겠습니다.
							</p>
							<ul className="num">
								<li>이름:</li>
								<li>직위:</li>
								<li>부서:</li>
								<li>연락처:</li>
							</ul>
						</div>

						<div className="section section10">
							<h3 className="title02 margin-t40">10. 정보주체의 권익침해에 대한 구제방법</h3>
							<p>
								이용자는 아래의 기관에 개인정보 침해에 대한 피해구제, 상담등을 문의하실 수 있습니다.
							</p>
							<p>1. 개인정보 침해신고센터 (한국인터넷진흥원 운영)</p>
							<ul className="dot">
								<li>소관업무 : 개인정보 침해사실 신고, 상담 신청</li>
								<li>홈페이지 : <a className="underline" href="https://privacy.kisa.or.kr"
											  target="_blank">privacy.kisa.or.kr</a></li>
								<li>전화 : (국번없이) 118</li>
								<li>주소 : 전라남도 나주시 진흥길 9 한국인터넷진흥원</li>
							</ul>
							<p>2. 개인정보 분쟁조정위원회</p>
							<ul className="dot">
								<li>소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</li>
								<li>홈페이지 : <a className="underline" href="https://www.kopico.go.kr"
											  target="_blank">www.kopico.go.kr</a></li>
								<li>전화 : 1833-6972</li>
								<li>주소 : 서울특별시 종로구 세종대로 209 정부서울청사 12층</li>
							</ul>
							<p>3. 대검찰청 사이버수사과</p>
							<ul className="dot">
								<li>(국번없이) 1301, <a className="underline" href="mailto:privacy@spo.go.kr"
													target="_blank">privacy@spo.go.kr</a> (대검찰청 )
								</li>
							</ul>
							<p>4. 경찰청 사이버수사국</p>
							<ul className="dot">
								<li>(국번없이) 182 (사이버범죄 신고시스템 (ECRM) )</li>
							</ul>
						</div>

						<div className="section section11">
							<h3 className="title02 margin-t40">11. 개인정보처리방침의 시행 및 변경에 관한 사항</h3>
							<p>
								이 개인정보 처리방침은 2024년 10월 00일부터 시행되며 법률이나 서비스의 변경 사항을 반영하기 위한 목적 등으로 개인정보처리방침을 변경할 수 있습니다.
								개인정보처리방침이 변경되는 경우 최소 7일 전부터 공지사항을 통해 변경 사항을 고지 해드리도록 하겠습니다.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};