import { Link } from 'react-router-dom';
import { useDirectMessageRepo } from '@/stores/useDirectMessageRepo';
import { useMessageListService } from '../service/useMessageListService';

const MessageListView = () => {
	const { setOpenDirectMessageDetail } = useDirectMessageRepo();
	const {
		userProfile,
		myProfile,
		srcPrefix,
		setUserProfileOpen,
		sortedMessages,
	} = useMessageListService();

	const renderMessageItem = (message) => {
		const {
			billingStatus,
			dmRoomType,
			roomId,
			otherUserInfo,
			messages,
			latestCreatedAt,
			visibleTo,
			isUserLeft,
			isOtherUserLeft,
			unreadCount,
			display,
			user1Info,
			user2Info,
		} = message;

		const createMessageListState = () => {
			const isUser1 = user1Info?.uuid === myProfile.userUid;
			return {
				billingStatus,
				myPaymentStatus: isUser1 ? billingStatus === 1 : billingStatus === 2,
				otherPaymentStatus: isUser1 ? billingStatus === 2 : billingStatus === 1,
				dmRoomType,
				roomId,
				visibleTo,
				isUserLeft,
				isOtherUserLeft,
				user1Info: user1Info || {
					uuid: '',
					name: '',
					profileUrl: '',
					location: '',
					age: '',
				},
				user2Info: user2Info || {
					uuid: '',
					name: '',
					profileUrl: '',
					location: '',
					age: '',
				},
				otherUserInfo: otherUserInfo || {
					uuid: '',
					name: '',
					profileUrl: '',
					location: '',
					age: '',
				},
				createdAt: latestCreatedAt,
				source: 'messageList',
			};
		};

		if (isUserLeft || visibleTo === -1) {
			return null;
		}

		const sortedMessages = [...messages].sort(
			(a, b) => parseInt(b.createdAt) - parseInt(a.createdAt),
		);

		const latestMessage = sortedMessages[0]?.content;
		if (visibleTo !== -1) {
			return (
				<div className="profileType" key={roomId}>
					<div className="proLeft width-100p">
						{isOtherUserLeft ? (
							<div className="proImg">
								<img
									src="/assets/img/common/profile_no.svg"
									alt="테스트이미지"
								/>
							</div>
						) : (
							<Link
								to="/metaverse/dating/profile"
								onClick={() => {
									setUserProfileOpen(otherUserInfo.uuid);
								}}
								state={{
									isOtherProfile: userProfile.userUid !== myProfile.userUid,
								}}
							>
								<div className="proImg">
									<img
										src={srcPrefix + otherUserInfo.profileUrl}
										alt={otherUserInfo.name}
									/>
								</div>
							</Link>
						)}
						<Link
							className="margin-l8 flex-1"
							to={`/metaverse/messenger/message/${roomId}`}
							state={createMessageListState()}
							onClick={(e) => {
								e.stopPropagation();
								setOpenDirectMessageDetail(true, roomId);
								setUserProfileOpen(otherUserInfo.uuid);
							}}
						>
							<div className="proName margin-l8 flex-1">
								<p className="d-flex justify-between align-center">
									<span className="bold">{otherUserInfo.name}</span>
									{!isOtherUserLeft && (
										<span className="fz10 color-999 bold-500">
											{latestCreatedAt
												? new Date(latestCreatedAt).toISOString().split('T')[0]
												: ''}
										</span>
									)}
								</p>
								{!isOtherUserLeft && (
									<p className="fz12 color-999 padding-b4">
										{otherUserInfo.age}세
									</p>
								)}
								<p
									className={`d-flex justify-between align-center padding-b4 ${
										isOtherUserLeft ? 'margin-t8' : ''
									}`}
								>
									<span
										className={`${
											isOtherUserLeft ? 'color-red fz14' : 'fz12'
										} ${display?.className || ''}`}
									>
										{isOtherUserLeft
											? '상대방이 대화를 종료하였습니다.'
											: display?.prefix || latestMessage || ''}
									</span>
									{sortedMessages.length > 0 &&
										sortedMessages[0].sender.uuid === otherUserInfo.uuid &&
										unreadCount > 0 && (
											<span className="chatMsgOn">{unreadCount}</span>
										)}
								</p>
							</div>
						</Link>
					</div>
				</div>
			);
		} else {
			return null;
		}
	};

	return (
		<div className="chatRoomList">{sortedMessages.map(renderMessageItem)}</div>
	);
};

export default MessageListView;
