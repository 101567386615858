import { create } from 'zustand';
import { MetaUserCIVO, MetaUserCOVO } from '@/site/api';
import { sanitizeId } from '@/utils/util';

export interface PlayerInfo {
	uuid: string;
	name: string;
	anim: string;
	statusMessage: string;
	audioStatus: boolean;
	profileUrl: string;
}

export interface OtherPlayerInfo {
	playerId: string;
	playerInfo: any;
}

type MetaUserRepoState = {
	title: string;
	uuid: string;
	sessionId: string;
	audioControl: boolean;
	videoControl: boolean;
	mediaConnected: boolean;
	saveMetaUser: MetaUserCIVO;
	myMetaUser: MetaUserCOVO;
	metaUser: MetaUserCOVO;
	myMetaUserOpen: boolean;
	otherMetaUser: string;
	playerNameMap: Map<string, PlayerInfo>;
	openOtherPlayerProfile: {
		isOpen: boolean;
		otherPlayerInfo: OtherPlayerInfo | null;
	};
	customizeAvatarOpen: boolean;

	/** 아바타 수정 */
	costumeColor: string;
	faceColor: string;
	hairColor: string;
	modifyCostumeColor: string;
	modifyFaceColor: string;
	modifyHairColor: string;

	setUUID: (uuid: string) => void;
	setSessionId: (sessionId: string) => void;
	setAudioControl: (audioControl: boolean) => void;
	setVideoControl: (videoControl: boolean) => void;
	setMediaConnected: (mediaConnected: boolean) => void;
	setSaveMetaUser: (saveMetaUser: MetaUserCIVO) => void;
	setMyMetaUser: (myMetaUser: MetaUserCOVO) => void;
	setMetaUser: (metaUser: MetaUserCOVO) => void;
	setMyMetaUserOpen: (myMetaUserOpen: boolean) => void;
	setOtherMetaUser: (otherMetaUser: string) => void;
	setPlayerNameMap: (params: {
		key: string;
		uuid: string;
		name: string;
		anim: string;
		statusMessage: string;
		audioStatus: boolean;
		profileUrl: string;
	}) => void;
	setPlayerNameMapUpdate: (params: {
		key: string;
		field: string;
		value: string | boolean | number;
	}) => void;
	removePlayerNameMap: (key: string) => void;
	clearPlayerNameMap: () => void;
	setOpenOtherPlayerProfile: (isOpen: boolean, otherPlayerInfo: any) => void;
	setCustomizeAvatarOpen: (customizeAvatarOpen: boolean) => void;

	/** 아바타 수정 */
	setCostumeColor: (costumeColor: string) => void;
	setFaceColor: (faceColor: string) => void;
	setHairColor: (hairColor: string) => void;
	setModifyCostumeColor: (modifyCostumeColor: string) => void;
	setModifyFaceColor: (modifyFaceColor: string) => void;
	setModifyHairColor: (modifyHairColor: string) => void;
};

export const useMetaUserRepo = create<MetaUserRepoState>((set) => ({
	title: '내 아바타',
	uuid: '',
	sessionId: '',
	audioControl: false,
	videoControl: false,
	mediaConnected: false,
	saveMetaUser: {
		avatarName: '',
		readyToConnect: false,
		mediaConnected: false,
	},
	myMetaUser: {
		userUid: '',
		nickName: '',
		introduce: '',
		gender: '',
		profileUrl: '',
		avatarName: '',
		readyToConnect: false,
		mediaConnected: false,
	},
	metaUser: {
		userUid: '',
		nickName: '',
		introduce: '',
		gender: '',
		profileUrl: '',
		avatarName: '',
		readyToConnect: false,
		mediaConnected: false,
	},
	myMetaUserOpen: false,
	otherMetaUser: '',
	playerNameMap: new Map<string, PlayerInfo>(),
	openOtherPlayerProfile: {
		isOpen: false,
		otherPlayerInfo: null,
	},
	customizeAvatarOpen: false,

	/** 아바타 수정 */
	costumeColor: 'costumes1',
	faceColor: 'faces1',
	hairColor: 'hairs1',
	modifyCostumeColor: 'costumes1',
	modifyFaceColor: 'faces1',
	modifyHairColor: 'hairs1',

	setUUID: (uuid) => set((state) => ({ uuid })),
	setSessionId: (sessionId) => set((state) => ({ sessionId })),
	setAudioControl: (audioControl) => set((state) => ({ audioControl })),
	setVideoControl: (videoControl) => set((state) => ({ videoControl })),
	setMediaConnected: (mediaConnected) => set((state) => ({ mediaConnected })),
	setSaveMetaUser: (saveMetaUser) => set((state) => ({ saveMetaUser })),
	setMyMetaUser: (myMetaUser) => set((state) => ({ myMetaUser })),
	setMetaUser: (metaUser) => set((state) => ({ metaUser })),
	setMyMetaUserOpen: (myMetaUserOpen) => set((state) => ({ myMetaUserOpen })),
	setOtherMetaUser: (otherMetaUser) => set((state) => ({ otherMetaUser })),
	setPlayerNameMap: ({
		key,
		uuid,
		name,
		anim,
		statusMessage,
		audioStatus,
		profileUrl,
	}) =>
		set((state) => {
			const newPlayerNameMap = new Map(state.playerNameMap);
			newPlayerNameMap.set(sanitizeId(key), {
				uuid,
				name,
				anim,
				statusMessage,
				audioStatus,
				profileUrl,
			});
			return { playerNameMap: newPlayerNameMap };
		}),
	setPlayerNameMapUpdate: ({ key, field, value }) =>
		set((state) => {
			const newPlayerNameMap = new Map(state.playerNameMap);
			const playerInfo = newPlayerNameMap.get(sanitizeId(key));
			if (playerInfo) {
				playerInfo[field] = value;
				newPlayerNameMap.set(sanitizeId(key), playerInfo);
			}
			return { playerNameMap: newPlayerNameMap };
		}),
	removePlayerNameMap: (key) =>
		set((state) => {
			const newPlayerNameMap = new Map(state.playerNameMap);
			newPlayerNameMap.delete(sanitizeId(key));
			return { playerNameMap: newPlayerNameMap };
		}),
	clearPlayerNameMap: () =>
		set((state) => ({ playerNameMap: new Map<string, PlayerInfo>() })),
	setOpenOtherPlayerProfile: (isOpen, otherPlayerInfo) =>
		set((state) => ({ openOtherPlayerProfile: { isOpen, otherPlayerInfo } })),
	setCustomizeAvatarOpen: (customizeAvatarOpen) =>
		set((state) => ({ customizeAvatarOpen })),

	/** 아바타 수정 */
	setCostumeColor: (costumeColor) => set((state) => ({ costumeColor })),
	setFaceColor: (faceColor) => set((state) => ({ faceColor })),
	setHairColor: (hairColor) => set((state) => ({ hairColor })),
	setModifyCostumeColor: (modifyCostumeColor) =>
		set((state) => ({ modifyCostumeColor })),
	setModifyFaceColor: (modifyFaceColor) =>
		set((state) => ({ modifyFaceColor })),
	setModifyHairColor: (modifyHairColor) =>
		set((state) => ({ modifyHairColor })),
}));
