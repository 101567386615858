import { Route, Routes, useLocation } from 'react-router-dom';
import Top10PicksContainer from "@dating/ui/pages/hushPick/top10Picks/Top10PicksContainer";
import LatestPicksContainer from "@dating/ui/pages/hushPick/latestPicks/LatestPicksContainer";
import PerfectPicksContainer from "@dating/ui/pages/hushPick/perfectPicks/PerfectPicksContainer";

const IdealMatchFinderRouter = () => {
	const location = useLocation();
	return (
		<Routes location={location}>
            <Route path="top-10-picks" element={<Top10PicksContainer/>}/>
            <Route path="latest-picks" element={<LatestPicksContainer/>}/>
            <Route path="perfect-picks" element={<PerfectPicksContainer/>}/>
		</Routes>
	);
};

export default IdealMatchFinderRouter;
