import {
    FeedFormType,
    newFeedFormType,
} from "@dating/model/LoungeModel";
import {create} from "zustand";
import {PostInfoCOVO} from "@/site/api";

interface LoungeState {
    // 피드 작성 시 이미지 없는 경우 등록하기 버튼 disabled
    saveBtnDisabled: boolean;
    setSaveBtnDisabled: (saveBtnDisabled) => void;

    // 댓글 더보기 (댓글창 open)
    feedCommentOpen: boolean;
    setFeedCommentOpen: (feedCommentOpen) => void;

    //feedList에서 입력한 검색어
    keyword: string;
    setKeyword: (keyword) => void;

    //피드 검색결과 리스트
    searchResult: PostInfoCOVO[];
    setSearchResult: (searchResult) => void;

    // 피드 작성, 수정 타입 및 내용
    feedForm: FeedFormType;
    setFeedForm: (feedForm: FeedFormType) => void;
    setNewFeedForm: (newFeedForm: newFeedFormType) => void;
    clearFeedForm: () => void;

    // 피드 검색 활성화
    clickSearch: boolean;
    setClickSearch: (clickSearch: boolean) => void;

    // 수정할 postId
    modifyPostId: string;
    setModifyPostId: (modifyPostId: string) => void;
    clearModifyPostId: () => void;

    // 게시글 숨기기 팝업
    openFeedHidePop: boolean;
    setOpenFeedHidePop: (openFeedHidePop: boolean) => void;

    // 숨길 게시글 ID (postId)
    blockPostId: string;
    setBlockPostId: (blockPostId: string) => void;

    // 성별필터
    feedFilterOpen: boolean;
    setFeedFilterOpen: (feedFilterOpen: boolean) => void;

    // 필터리스트
    feedFilterList: {label: string, value: string}[];

    // 싱글피드에 넘길 postId
    singleFeedId: string;
    setSingleFeedId: (singleFeedId: string) => void;

    // feedEditMenu handle
    feedEditPops: {};
    setFeedEditPops: (feedEditPops: {}) => void;
};

const useLoungeStore = create<LoungeState>(
    (set) => ({
        saveBtnDisabled: true,
        setSaveBtnDisabled: (saveBtnDisabled: boolean) => set({saveBtnDisabled}),

        feedCommentOpen: false,
        setFeedCommentOpen: (feedCommentOpen: boolean) => set({feedCommentOpen}),

        keyword: "",
        setKeyword: (keyword: string) => set({keyword}),

        searchResult: [],
        setSearchResult: (searchResult: PostInfoCOVO[]) => set({searchResult}),

        feedForm: {
            // name: "",
            // title: "",
            postId: "",
            files: [],
            content: "",
            tags: [],
        },
        setFeedForm: (feedForm: FeedFormType) => set({feedForm}),
        setNewFeedForm: (newFeedForm: newFeedFormType) => set((state) => ({feedForm: {...state.feedForm, ...newFeedForm}})),
        clearFeedForm: () => set({
            feedForm: {
                postId: "",
                files: [],
                content: "",
                tags: [],
            }
        }),

        clickSearch: false,
        setClickSearch: (clickSearch: boolean) => set({clickSearch}),

        modifyPostId: "",
        setModifyPostId: (modifyPostId: string) => set({modifyPostId}),
        clearModifyPostId: () => set({modifyPostId: ""}),

        openFeedHidePop: false,
        setOpenFeedHidePop: (openFeedHidePop: boolean) => set({openFeedHidePop}),

        blockPostId: "",
        setBlockPostId: (blockPostId: string) => set({blockPostId}),

        feedFilterOpen: false,
        setFeedFilterOpen: (feedFilterOpen: boolean) => set({feedFilterOpen}),

        feedFilterList: [
            {
                label: "모두",
                value: "A",
            },
            {
                label: "여성",
                value: "F",
            },
            {
                label: "남성",
                value: "M",
            },
        ],

        singleFeedId: "",
        setSingleFeedId: (singleFeedId: string) => set({singleFeedId}),

        // feedEditMenu handle
        feedEditPops: {},
        setFeedEditPops: (feedEditPops: {}) => set({feedEditPops}),
    }),
);

export default useLoungeStore;