import { useState } from "react";

export const useProfileImgEditStateRepo = () => {
    // 프로필 등록 완료 버튼 활성화 여부
    const [btnActivate, setBtnActivate] = useState(true);

    // api 응답결과
    const [response, setResponse] = useState<boolean>(false);

    return {
        btnActivate,
        setBtnActivate,
        response,
        setResponse,
    };
};