import { useEffect, useRef } from 'react';
import MeetingPeopleListView from './MeetingPeopleListView';

const RoomListItemView = ({
	myGender,
	headCount,
	locked,
	roomTheme,
	roomKey,
	roomTitle,
	remainingTime,
	players,
	roomItem,
	hasPassword,
	joinedRoomData,
	setRoomItem,
	roomListOpen,
	handleJoinClick,
	handleClickMeta,
	isJoinedRoom,
}) => {
	const selectedItem = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// 미팅방목록이 close(false) 상태인 경우 모든 item 닫기
		if (!roomListOpen) {
			setRoomItem(false);
		} else {
			selectedItem.current?.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			});
		}
	}, [roomListOpen]);

	const openRoomInfo = () => {
		// 클릭한 요소가 이미 active 상태인지 확인
		const clickedItem = selectedItem.current;
		const isActive = clickedItem?.classList.contains('active');

		// 이미 active 상태인 경우 active 클래스 제거
		if (isActive) {
			clickedItem?.classList.remove('active');
			return;
		}

		// 클릭한 요소에만 active 클래스 추가하고, 다른 요소에서는 제거
		const roomItems = document.querySelectorAll('.roomListItem');
		roomItems.forEach((roomItem) => {
			roomItem.classList.remove('active');
		});
		clickedItem?.classList.add('active');

		// 클릭한 요소의 위치로 스크롤
		selectedItem.current?.scrollIntoView({
			block: 'center',
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		if (remainingTime === 1 && roomKey === joinedRoomData.id) {
			handleClickMeta();
		}
	}, [remainingTime]);

	const getStatusClass = () => {
		if (isJoinedRoom) return 'ing';
		if (locked) return 'ing';
		return 'waiting';
	};

	const getStatusText = () => {
		if (isJoinedRoom) return '참가중';
		if (locked) return '진행중';
		return '대기중';
	};

	return (
		<div
			className={`roomListItem ${roomItem ? 'active' : ''}`}
			ref={selectedItem}
		>
			<div className="roomListItemTit" onClick={openRoomInfo}>
				<div className="left">
					<div className={`statusBag ${getStatusClass()}`}>
						<span>{getStatusText()}</span>
					</div>
					<p className="fz14Fw700">{roomTitle}</p>
				</div>
				<div className="right">
					<span className="hdCount">{headCount === 4 ? '2:2' : '3:3'}</span>
				</div>
			</div>
			<MeetingPeopleListView
				myGender={myGender}
				roomKey={roomKey}
				locked={locked}
				roomTheme={roomTheme}
				players={players}
				hasPassword={hasPassword}
				headCount={headCount}
				joinedRoomData={joinedRoomData}
				handleClickMeta={handleClickMeta}
				handleJoinClick={handleJoinClick}
				remainingTime={remainingTime}
			/>
		</div>
	);
};

export default RoomListItemView;
