import {useEffect} from "react";
import {SearchPostCIVO} from "@/site/api";
import useLoungeRepo from "@dating/repository/lounge/useLoungeRepo";
import {useLoungeAdapter} from "@dating/adapter/lounge/useLoungeAdapter";

export const useFeedSearchHeaderService = () => {

    const {
        setSearchResult,
        keyword,
        setKeyword,
        clickSearch,
        setClickSearch,
    } = useLoungeRepo();

    const {
        getSearchPost,
    } = useLoungeAdapter();

    // useEffect(() => {
    //     // 검색결과 리스트가 변경되면 리렌더링
    // }, [searchResult, postList]);

    /*
     * searchFeedOut
     * 검색결과화면에서 나오기
     */
    const searchFeedOut = () => {
        setClickSearch(false);
        setKeyword("");
    };

    /*
     * fnSearchFeed
     * 키워드로 검색 수행 및 리스트 생성 후 set
     */
    const fnSearchFeed = (keyword: string) => {
        const map: SearchPostCIVO = {
            searchWord: keyword
        };
        getSearchPost(map).then((response) => {
            setSearchResult(response);
        });

        // const result = sampleFeedList
        //     .concat(sampleFeedList)
        //     .concat(sampleFeedList)
        //     .concat(sampleFeedList);
        // const page = 10;
        // const count: number = Math.ceil(result.length / page); //2
        // let list: PostInfoCOVO[][] = [];
        // for (let i = 0; i < count; i++) {
        //     const startIndex = i * page;
        //     const endIndex = i * page + page;
        //     //0~9
        //     //10~19
        //
        //     list.push(result.slice(startIndex, endIndex));
        // }
        // setSearchResult(list);
    };

    useEffect(() => {
        // 키워드 값 변경 시 조회
        if (keyword.length > 1) {
            setClickSearch(true);
            fnSearchFeed(keyword);
        }

    }, [keyword]);

    return {
        clickSearch,
        keyword,
        setKeyword,
        searchFeedOut,
    };
};