import { create } from 'zustand';

type ActionButtonRepoState = {
	actionButtonActive: boolean;
	setActionButtonActive: (active: boolean) => void;
	resetActionButtonActive: () => void;
};

export const useActionButtonRepo = create<ActionButtonRepoState>((set) => ({
	actionButtonActive: false,
	setActionButtonActive: (active) => set({ actionButtonActive: active }),
	resetActionButtonActive: () => set({ actionButtonActive: false }),
}));
