import { colors, faceColors, getRange } from '@/utils/avatarUtils';
import { FC, useEffect, useState } from 'react';

interface ColorPickerViewProps {
	type: string;
	length: number;
	onSelectAvatar: (index: number) => void;
	initialAvatarColor: number;
	setColor: (color: string) => void;
}

const ColorPickerView: FC<ColorPickerViewProps> = ({
	type,
	onSelectAvatar,
	initialAvatarColor,
	setColor,
}) => {
	const [selectedAvatarColor, setSelectedAvatarColor] = useState<number | null>(
		initialAvatarColor,
	);
	const colorArray = type === 'faces' ? faceColors : colors;
	const onClickAvatar = (idx: number) => {
		setSelectedAvatarColor(idx); // 클릭한 아바타 이미지 값 저장
		onSelectAvatar(idx); // 부모 컴포넌트의 state 변경 함수 호출
	};

	useEffect(() => {
		setSelectedAvatarColor(initialAvatarColor - 1);
	}, [initialAvatarColor]);
	return (
		<div className="colorWrap">
			{getRange(colorArray.length).map((_, i) => (
				<div
					key={i}
					className={`colorPicker ${selectedAvatarColor === i ? 'active' : ''}`}
					style={{
						backgroundColor: colorArray[i],
					}}
					onClick={() => {
						onClickAvatar(i);
						setColor(`${type}${i + 1}`);
					}}
				></div>
			))}
		</div>
	);
};

export default ColorPickerView;
