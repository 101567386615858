import ReceivedUserLikeView from './views/ReceivedUserLikeView';
import HighlyRatedView from './views/HighlyRatedView';
import LikedPostView from '@dating/ui/pages/like/likeReceive/views/LikedPostView';
import PostWithCommentView from './views/PostWithCommentView';
import { useHeaderService } from '@dating/ui/pages/header/useHeaderService';
import React, { useRef } from 'react';
import LikeHeaderView from '@dating/ui/pages/like/views/LikeHeaderView';
import { useLikeReceiveService } from '@dating/ui/pages/like/likeReceive/useLikeReceiveService';
import { useLikeHeaderService } from '@dating/ui/pages/like/views/useLikeHeaderService';
import DatingHeaderView from '@dating/ui/pages/header/DatingHeaderView';
import { usePaymentService } from '@dating/ui/components/payment/service/usePaymentService';

function LikeReceiveContainer() {
	const { myProfile, srcPrefix, setMyProfileOpen } = useHeaderService();

	const { isReceived, changeLikeType, handleScroll, scrollYIndex } =
		useLikeHeaderService();

	const { fnSendLikePopOpen } = usePaymentService();

	const {
		receivedLikeTitSets,
		noDataUserLike,
		fnGetReceiveUserLike,
		likeUsers,
		noDataHighlyRated,
		highlyRatedUsers,
		fnGetReceiveUserHighScore,
		noDataPost,
		fnGetReceivePostLike,
		likePosts,
		setUserProfileOpen,
		noDataComment,
		commentPost,
		fnGetReceivePostComment,
		fnSaveUserLike,
		fnRemoveReceiveUserLike,
		fnRemoveReceiveHighlyRatedUsers,
		fadeIn,
		setSingleFeedId,
		setMessageTargetUser,
	} = useLikeReceiveService();

	const likeContainer = useRef<HTMLDivElement>(null);

	return (
		<div className="wrap">
			<DatingHeaderView
				myProfile={myProfile}
				srcPrefix={srcPrefix}
				setMyProfileOpen={setMyProfileOpen}
			/>
			<div id="conWrap" className="metaPopWrap gfgfffafsda">
				<LikeHeaderView
					isReceived={isReceived}
					changeLikeType={changeLikeType}
				/>

				<div
					className="toDayWrap"
					ref={likeContainer}
					onScroll={() => {
						handleScroll(likeContainer.current?.scrollTop);
					}}
				>
					<div className="inner">
						<div className="tabContent">
							<div className={`${isReceived ? 'active' : ''} tabCon tabCon01`}>
								{/*<div className="likeList">*/}
								<div
									className={`likeList spinnerContent ${
										fadeIn ? 'fade-in' : ''
									}`}
								>
									{/*받은 좋아요*/}
									<ReceivedUserLikeView
										titSet={receivedLikeTitSets[0]}
										index={0}
										scrollYIndex={scrollYIndex}
										noDataUserLike={noDataUserLike}
										fnGetReceiveUserLike={fnGetReceiveUserLike}
										likeUsers={likeUsers}
										srcPrefix={srcPrefix}
										setUserProfileOpen={setUserProfileOpen}
										fnSaveUserLike={fnSaveUserLike}
										fnRemoveReceiveUserLike={fnRemoveReceiveUserLike}
										fnSendLikePopOpen={fnSendLikePopOpen}
										setMessageTargetUser={setMessageTargetUser}
									/>

									{/*높게 받은 평가*/}
									<HighlyRatedView
										titSet={receivedLikeTitSets[1]}
										index={1}
										scrollYIndex={scrollYIndex}
										noDataHighlyRated={noDataHighlyRated}
										highlyRatedUsers={highlyRatedUsers}
										fnGetReceiveUserHighScore={fnGetReceiveUserHighScore}
										srcPrefix={srcPrefix}
										setUserProfileOpen={setUserProfileOpen}
										fnSaveUserLike={fnSaveUserLike}
										fnRemoveReceiveHighlyRatedUsers={
											fnRemoveReceiveHighlyRatedUsers
										}
										fnSendLikePopOpen={fnSendLikePopOpen}
									/>

									{/*좋아요 받은 게시글*/}
									<LikedPostView
										titSet={receivedLikeTitSets[2]}
										index={2}
										scrollYIndex={scrollYIndex}
										noDataPost={noDataPost}
										fnGetReceivePostLike={fnGetReceivePostLike}
										likePosts={likePosts}
										srcPrefix={srcPrefix}
										setUserProfileOpen={setUserProfileOpen}
										currentUserUid={myProfile.userUid}
										setMyProfileOpen={setMyProfileOpen}
										setSingleFeedId={setSingleFeedId}
									/>

									{/*댓글 달린 게시글*/}
									<PostWithCommentView
										titSet={receivedLikeTitSets[3]}
										index={3}
										scrollYIndex={scrollYIndex}
										srcPrefix={srcPrefix}
										noDataComment={noDataComment}
										commentPost={commentPost}
										fnGetReceivePostComment={fnGetReceivePostComment}
										setUserProfileOpen={setUserProfileOpen}
										currentUserUid={myProfile.userUid}
										setMyProfileOpen={setMyProfileOpen}
										setSingleFeedId={setSingleFeedId}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LikeReceiveContainer;
