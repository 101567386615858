import ButtonHeaderView from '@dating/ui/components/buttonHeader/ButtonHeaderView';
import AgreeListView from '@dating/ui/pages/settings/agree/views/AgreeListView';

export const Policy04Container = () => {
	return (
		<div className="rightShow active">
			<ButtonHeaderView right={false} />

			<div className="popConWrap bdNone">
				<div className="inner">
					<div className="agreeWrap">
						<div className="titleWrap">
							<h2 className="title01">위치정보 이용약관</h2>
							<p className="titleSub01">
								<i>시행일자 : 2024.10.31 ~ 시행</i>
							</p>
						</div>

						<div className="section section01">
							<h3 className="title02 margin-t40">1. 목적</h3>
							<p>
								본 약관은 회원 (HUSH 서비스 약관에 동의한 자를 말하며 이하 '회원'이라고 합니다)이 베리드코리아 (이하 '회사'라고 합니다)가 제공하는 웹페이지 및
								'HUSH' (회사가 개발 운영하는 모바일 애플리케이션을 말합니다 이하 '모바일앱'이라고 합니다)의 서비스를 이용함에 있어 회원과 회사의 권리 및 의무, 기타
								제반 사항을 정하는 것을 목적으로 합니다.
							</p>
						</div>

						<div className="section section02">
							<h3 className="title02 margin-t40">2. 이용약관의 효력 및 변경</h3>
							<ul className="num">
								<li>본 약관은 서비스를 신청한 고객 또는 개인위치정보주체가 회사가 정한 소정의 절차에 따라 회원으로 등록함으로써 효력이 발생합니다.</li>
								<li>서비스를 신청한 고객 또는 개인위치정보주체가 온라인에서 본 약관을 모두 읽고 "동의하기" 버튼을 클릭하였을 경우 본 약관의 내용을 모두 읽고 이를
									충분히 이해하였으며, 그 적용에 동의한 것으로 봅니다. 본 약관에 대하여 동의하지 않은 경우, 회사가 개인위치정보를 기반으로 제공하는 각종 혜택 및
									편의제공에 일부 제한이 발생할 수 있습니다.
								</li>
								<li>회사는 필요한 경우 '위치 정보의 보호 및 이용 등에 관한 법률, '콘텐츠산업 진흥법', '전자상거래 등에서의 소비자보호에 관한 법률',
									'소비자기본법', '약관의 규제에 관한 법률 등 관계법령(이하 '관계법령'이라 합니다)의 범위 내에서 본 약관을 개정할 수 있습니다.
								</li>
								<li>회사가 약관을 개정할 경우 기존약관과 개정약관 및 개정약관의 적용일자와 개정사유를 명시하여 현행약관과 함께 그 적용일자 10일 전부터 적용일 이후
									상당한 기간 동안 회사의 웹페이지 및 모바일앱을 통해 공지합니다. 다만, 개정약관의 내용이 회원에게 새로운 의무를 부과하거나 권리를 제한하는 내용인
									경우 그 적용일자 30일 전부터 상당한 기간 동안 이를 회사의 웹페이지 및 모바일앱을 통해 공지하고, 회원에게 전자적 형태로 약관의 개정사실을 발송하여
									고지합니다.
								</li>
							</ul>
						</div>

						<div className="section section02">
							<h3 className="title02 margin-t40">3. 관계법령의 적용</h3>
							<p>
								본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항에 대하여는 관 계법령 및 건전한 거래관행에 따릅니다.
							</p>
						</div>

						<div className="section section04">
							<h3 className="title02 margin-t40">4. 서비스의 내용 및 이용요금</h3>
							<ul>
								<li>회사가 제공하는 "허쉬" 어플리케이션에서 위치정보를 이용하여 서비스를 제공할 수 있습니다.</li>
								<li>
									서비스의 요금은 기본적으로 무료입니다. 단, 별도의 유료부가서비스의 경우 해당 서비스에 명시된 요금을 지불하여야 가능합니다.
								</li>
								<li>유료서비스 이용을 통하여 결제된 대금에 대한 취소 및 환불은 회사의 결제 이용약관 및 관계법령에 따릅니다.</li>
								<li>회원의 개인정보 도용 및 결제사기로 인한 환불 요청 또는 결제자의 개인정보 요구는 법률이 정한 경우 외에는 거절될 수 있습니다.</li>
								<li>무선서비스 이용 시 발생되는 데이터 통신료는 별도이며, 회원이 가입한 각 이동통신사 정책에 따릅니다.</li>
							</ul>
						</div>

						<div className="section section05">
							<h3 className="title02 margin-t40">5. 개인정보 주체의 권리</h3>
							<ul className="num">
								<li>회원은 회사에 대하여 언제든지 개인위치정보의 수집, 이용 또는 제공의 일시적인 중지를 요구할 수 있습니다.</li>
								<li>회원은 회사에 대하여 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의의 전부 또는 일부를 철회 할 수 있습니다. 이 경우 회사는 수집한
									개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다.
								</li>
								<li>회원은 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있 는 경우에는 그 정정을 요구할 수 있습니다.
									이 경우 회사는 정당한 사유 없이 회원의 요구를 거절할 수 없 습니다.
								</li>
							</ul>
							<ul className="dot">
								<li>본인에 대한 위치정보 이용, 제공사실 확인자료</li>
								<li>본인의 위치정보가 제3자에게 제공된 이유 및 내용</li>
							</ul>
							<ul className="num">
								<li>회원은 회사가 정한 절차에 따라 1,2,3항의 권리를 행사할 수 있습니다.</li>
							</ul>
						</div>

						<div className="section section06">
							<h3 className="title02 margin-t40">6. 개인 위치 정보의 이용 또는 제공</h3>
							<ul className="num">
								<li>회사는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 미리 개인정보위치정보주체의 동의를 얻어야 합니다.</li>
								<li>회사는 위치정보 법 제 16조 제 2항에 따라 회원에 대한 위치정보 이용 제공사실 확인 자료를 요금정산 및 민원처리를 위해 위치정보시스템에 자동으로
									기록하고 보존 되도록 하며, 해당 자료는 기록 시점으로 부터 최대 1년간 보관합니다.
								</li>
								<li>회사는 개인위치정보를 회원이 지정하는 제 3자에게 제공하는 경우에는 제공받는 자, 제공일시 및 제공목적을 회원에게 고지합니다.</li>
							</ul>
						</div>

						<div className="section section07">
							<h3 className="title02 margin-t40">7. 손해배상</h3>
							<ul className="num">
								<li>회사가 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 제26조의 규정을 위반한 행위를 하여 회원 에게 손해가 발생한 경우 회원은 회사에 대하여
									손해배상 청구를 할 수 있습니다.
								</li>
								<li>회원이 고의 또는 과실로 본 약관의 규정을 위반하여 회사에 손해가 발생한 경우 회사는 회원에 대하여 손해배상을 청구할 수 있습니다. 이 경우 회원은
									고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할수 없습니다.
								</li>
							</ul>
						</div>

						<div className="section section08">
							<h3 className="title02 margin-t40">8. 면책</h3>
							<ul className="num">
								<li>회사는 다음 각 호의 사유로 서비스를 제공할 수 없는 경우 이로 인하여 회원에게 발생한 손해에 대한 책 임을 부담하지 않습니다.
									<ul className="dot">
										<li>천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</li>
										<li>제3자의 고의적인 서비스 방해가 있는 경우</li>
										<li>회원의 귀책사유로 서비스 이용에 장애가 있는 경우</li>
										<li>기타 회사의 고의 또는 과실이 없는 사유에 해당하는 경우</li>
									</ul>
								</li>
								<li>회사는 서비스 및 서비스에 게재된 정보, 자료, 사실의 신뢰도 및 정확성 등에 대한 보증을 하지 않으며 이 로 인하여 회원에게 발생한 손해에 대하여
									책임을 부담하지 않습니다.
								</li>
								<li>회사는 회원이 서비스를 이용하며 기대하는 수익을 상실한 것에 대한 책임과 그 밖의 서비스를 통하여 얻 은 자료로 인하여 회원에게 발생한 손해에 대한
									책임을 부담하지 않습니다.
								</li>
							</ul>
						</div>

						<div className="section section09">
							<h3 className="title02 margin-t40">9. 분쟁의 조정</h3>
							<ul className="num">
								<li>회사는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우 에는 '위치정보의 보호 및 이용 등에 관한 법률'
									제28조의 규정에 따라 방송통신위원회에 재정을 신청할 수 있습니다.
								</li>
								<li>회사 또는 고객은 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 '개인정보보호법' 제43조의 규정에
									따라 개인정보분쟁조정위원회에 조정을 신청할 수 있습니다.
								</li>
							</ul>
						</div>

						<div className="section section10">
							<h3 className="title02 margin-t40">10. 회사의 연락처</h3>
							<p>제19조(회사의 연락처) 회사의 상호 및 주소 등은 다음과 같습니다.</p>
							<ul className="num">
								<li>상호: 베리드코리아</li>
								<li>대표자 : 김택균</li>
								<li>주소 : 서울 영등포구 선유로 70 1301호</li>
								<li>대표전화 : 02)3662-7981</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};