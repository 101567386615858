import { Link, NavigateFunction } from 'react-router-dom';
import {UserProfileCOVO} from "@/site/api";

interface props {
    setOpenLogoutPop: (openLogoutPop: boolean) => void;
    myProfile: UserProfileCOVO;
    fnLogout: () => void;
    fnCommonConfirmPopOn: (message: string, func: any, param: any) => any;
};

const AccountSettingListView = ({
                                    setOpenLogoutPop,
                                    myProfile,
                                    fnLogout,
                                    fnCommonConfirmPopOn,
                                }: props) => {

    return (
        <div className="popConWrap">
            <div className="inner">
                <div className="accoList">
                    <div className="leftRightTxt margin-t24">
                        <span>구글 이메일 계정</span>
                        <button type="button" className="color-999">연동 안됨</button>
                    </div>
                    <div className="leftRightTxt margin-t24">
                        <span>휴대전화번호</span>
                        <button type="button" className="color-999">{myProfile?.phoneNumber}</button>
                    </div>
                    <div className="margin-t24">
                        <button
                            type="button"
                            className="bold-500 popOnBtn"
                            onClick={() => {
                                // setOpenLogoutPop(true);
                                fnCommonConfirmPopOn("로그아웃 하시겠습니까?", fnLogout, null);
                            }}
                        >
                            로그아웃
                        </button>
                    </div>
                    <div className="margin-t24">
                        <Link to='/metaverse/dating/settings/withdrawal'>
                            <button type="button" className="bold-500">
                                계정 삭제
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountSettingListView;
