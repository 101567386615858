interface props {
    fnSaveUserPrefer: () => void;
}

const IdealSettingFooterView = ({
                                    fnSaveUserPrefer
                                }: props) => {
    return (
        <footer>
            <div className="f-btnWrap">
                <button
                    className="btn btnBig btnBlack"
                    onClick={() => {
                        fnSaveUserPrefer();
                    }}
                >
                    <span>다 음</span>
                </button>
            </div>
        </footer>
    );
};

export default IdealSettingFooterView;
