import {
	Route,
	Routes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import ProfileEditContainer from '@dating/ui/pages/profile/editProfile/ProfileEditContainer';
import ProfileAddContainer from '@dating/ui/pages/profile/addProfile/ProfileAddContainer';
import ProfileContainer from '@dating/ui/pages/profile/ProfileContainer';
import RouteTransition from '@dating/routers/RouteTransition';
import { ProfileImgEditContainer } from "@dating/ui/pages/profile/editProfile/ProfileImgEditContainer";

export default function ProfileRouter() {
	const location = useLocation();
	const navigationType = useNavigationType();
	return (
		<>
			<RouteTransition location={location} navigationType={navigationType}>
				<Routes location={location}>
					<Route path="/" element={<ProfileContainer />} />
					<Route path="/editProfile" element={<ProfileEditContainer />} />
					<Route path="/addProfile" element={<ProfileAddContainer />} />
					<Route path="/editProfileImg" element={<ProfileImgEditContainer />} />
				</Routes>
			</RouteTransition>
		</>
	);
}
