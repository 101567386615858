import {UserProfileCOVO} from '@/site/api';
import React from 'react';

interface props {
    myModifyProfile: UserProfileCOVO;
    openPopupHandler: (detail: {}) => void;
}

const EditProfileDetailView = ({myModifyProfile, openPopupHandler}: props) => {
    const details = [
        {
            id: 'user',
            title: '닉네임',
            value: myModifyProfile?.nickName,
            key: 'nickName',
            isNull: myModifyProfile?.nickName ? false : true,
            editable: true,
        },
        {
            id: 'birthday',
            title: '생일',
            value: myModifyProfile?.birthday ? myModifyProfile?.birthday : 'null',
            key: 'birthday',
            isNull: true,
            editable: false,
        },
        {
            id: 'gender',
            title: '성별',
            value: myModifyProfile?.gender,
            key: 'gender',
            isNull: true,
            editable: false,
        },
        {
            id: 'location',
            title: '위치',
            value: myModifyProfile?.location ? myModifyProfile?.location : '위치정보가 없습니다',
            key: 'location',
            isNull: true,
            editable: false,
        },
        {
            id: 'job',
            title: '직업',
            value: myModifyProfile?.job,
            key: 'job',
            isNull: myModifyProfile?.job ? false : true,
            editable: true,
        },
        {
            id: 'height',
            title: '키',
            value: myModifyProfile?.height,
            key: 'height',
            isNull: myModifyProfile?.height ? false : true,
            editable: true,
        },
        {
            id: 'drink',
            title: '음주',
            value: myModifyProfile?.drink ? myModifyProfile?.drink : '음주여부를 선택해 주세요',
            key: 'drinking',
            isNull: myModifyProfile?.drink ? false : true,
            editable: true,
        },
        {
            id: 'smoking',
            title: '흡연',
            value:
                myModifyProfile?.smoking !== "" ?
                    myModifyProfile?.smoking
                    : '흡연여부를 선택해 주세요',
            key: 'smoking',
            isNull: myModifyProfile?.smoking !== "" ? false : true,
            editable: true,
        },
        {
            id: 'religion',
            title: '종교',
            value: myModifyProfile?.religion ? myModifyProfile?.religion : '종교를 선택해 주세요',
            key: 'religion',
            isNull: myModifyProfile?.religion ? false : true,
            editable: true,
        },
        {
            id: 'pen',
            title: '자기소개',
            value: myModifyProfile?.introduce
                ? myModifyProfile?.introduce
                : '자기소개를 등록해 주세요',
            key: 'introduce',
            isNull: myModifyProfile?.introduce ? false : true,
            editable: true,
        },
        {
            id: 'like',
            title: '관심사',
            value:
                myModifyProfile?.interest.concat(myModifyProfile?.extraInterest).length > 0
                    ? myModifyProfile?.interest.concat(myModifyProfile?.extraInterest).join(', ')
                    : '회원님의 관심사를 알려주세요',
            code: myModifyProfile?.interest.length > 0 ? myModifyProfile?.interestCode : [],
            key: 'interest',
            isNull: myModifyProfile?.interest.length > 0 ? false : true,
            editable: true,
        },
        {
            id: 'life',
            title: '라이프스타일',
            value:
                myModifyProfile?.lifeStyle.concat(myModifyProfile?.extraLifeStyle).length > 0
                    ? myModifyProfile?.lifeStyle.concat(myModifyProfile?.extraLifeStyle).join(', ')
                    : '회원님의 라이프스타일을 알려주세요',
            code: myModifyProfile?.lifeStyle.length > 0 ? myModifyProfile?.lifeStyleCode : [],
            key: 'lifeStyle',
            isNull: myModifyProfile?.lifeStyle.length > 0 ? false : true,
            editable: true,
        },
    ];

    return (
        <>
            <div className="stepLastBox">
                <ul className="infoList">
                    {details.map((detail, i) => {
                        if (detail.id === 'pen') {
                            // 자기소개
                            return (
                                <React.Fragment key={detail.id}>
                                    <li className={`ic-${detail.id} leftRightTxt w50p margin-b8`}>
                                        <p>{detail.title}</p>
                                    </li>
                                    <li>
                                        <p
                                            className={`bold-500 ${detail.isNull ? '' : 'color-000'}`}
                                            onClick={() => {
                                                openPopupHandler(detail);
                                            }}
                                        >
                                            {detail.value}
                                        </p>
                                    </li>
                                </React.Fragment>
                            );
                        } else if (detail.id === 'like') {
                            // 관심사
                            return (
                                <React.Fragment key={detail.id}>
                                    <li className={`ic-${detail.id} leftRightTxt w50p margin-b8`}>
                                        <p>{detail.title}</p>
                                    </li>
                                    <li className={`${detail.isNull ? '' : 'on'}`}>
                                        <p
                                            className="bold-500"
                                            onClick={() => {
                                                openPopupHandler(detail);
                                            }}
                                        >
                                            {typeof detail.value === 'object'
                                                ? detail.value
                                                : detail.value}
                                        </p>
                                    </li>
                                </React.Fragment>
                            );
                        } else if (detail.id === 'life') {
                            //라이프스타일
                            return (
                                <React.Fragment key={detail.id}>
                                    <li className={`ic-${detail.id} leftRightTxt w50p margin-b8`}>
                                        <p>{detail.title}</p>
                                    </li>
                                    <li className={`${detail.isNull ? '' : 'on'}`}>
                                        <p
                                            className="bold-500"
                                            onClick={() => {
                                                openPopupHandler(detail);
                                            }}
                                        >
                                            {typeof detail.value === 'object'
                                                ? detail.value
                                                : detail.value}
                                        </p>
                                    </li>
                                </React.Fragment>
                            );
                        } else {
                            return (
                                // 닉네임 ~ 종교
                                <li
                                    key={detail.id}
                                    className={`ic-${detail.id} leftRightTxt w50p ${
                                        detail.isNull ? '' : 'on'
                                    }`}
                                >
                                    <p>{detail.title}</p>
                                    <p
                                        onClick={() => {
                                            openPopupHandler(detail);
                                        }}
                                    >
                                        {detail.value}
                                    </p>
                                </li>
                            );
                        }
                    })}
                </ul>
            </div>
        </>
    );
};

export default EditProfileDetailView;
