import { CSSTransition } from 'react-transition-group';
import FeedListView from './views/FeedListView';
import { useFeedListService } from '@dating/ui/pages/lounge/feedList/service/useFeedListService';
import { Link } from 'react-router-dom';
import { useBlockReportService } from '@dating/ui/components/blockReport/service/useBlockReportService';
import ReportPopView from '@dating/ui/components/blockReport/views/ReportPopView';
import { useFeedFormService } from '@dating/ui/components/feedFrom/service/useFeedFormService';
import FeedCommentView from '@dating/ui/pages/lounge/feedComment/views/FeedCommentView';
import { useCommonConfirmPopService } from '@dating/ui/components/commonConfirmPop/service/useCommonConfirmPopService';
import FeedHidePopView from '@dating/ui/pages/lounge/feedList/views/FeedHidePopView';
import { FeedListHeaderView } from '@dating/ui/pages/lounge/feedList/views/FeedListHeaderView';
import { FeedFilterView } from '@dating/ui/pages/lounge/feedFilter/FeedFilterView';
import { useEffect, useRef, useState } from 'react'; // useState 추가


const FeedListContainer = () => {
	const {
		fadeIn,
		postList,
		deleteContent,
		commentState,
		commentListHandler,
		commentViewHandler,
		openComment,
		fnSaveComment,
		focusedPostId,
		commentResponse,
		commentValue,
		setCommentValue,
		postUser,
		setUserProfileOpen,
		setMyProfileOpen,
		fnSavePostLike,
		srcPrefix,
		myProfile,
		openFeedHidePop,
		setOpenFeedHidePop,
		fnSaveBlockPost,
		setBlockPostId,
		feedFilterOpen,
		setFeedFilterOpen,
		checkedFilter,
		setCheckedFilter,
		feedFilterList,
		fnGetPosts,
		fnGetPostsByGender,
		setFadeIn,
		sliderSettings,
		fnFeedEditHandler,
		feedEditPops,
		initFeedEditPops,
		isClickedFeedMenu,
		fnDeleteComment,
	} = useFeedListService();

	const {
		openMenuPop,
		openReport,
		setOpenReport,
		openBlock,
		setOpenBlock,
		menuDotClickHandler,
		selectedOption,
		handleOptionSelect,
		commonReportCodes,
		reportValue,
		reportInputHandler,
		inputDisabled,
		setBlockUserUid,
	} = useBlockReportService();

	// const {clickSearch, keyword, setKeyword, searchFeedOut} =
	//     useFeedSearchHeaderService();

	const { initFeedForm, setModifyPostId } = useFeedFormService();

	const {
		fnCommonConfirmPopOn,
	} = useCommonConfirmPopService();

	const feedTop = useRef(null);
	const [isTopBtnVisible, setIsTopBtnVisible] = useState(false);

	useEffect(() => {
		const feedWrap = feedTop.current;

		const toggleTopBtnVisibility = () => {
			if (feedWrap.scrollTop > 200) {
				setIsTopBtnVisible(true);
			} else {
				setIsTopBtnVisible(false);
			}
		};

		feedWrap.addEventListener('scroll', toggleTopBtnVisibility);
		return () => feedWrap.removeEventListener('scroll', toggleTopBtnVisibility);
	}, []);

	const scrollToTop = () => {
		const feedWrap = feedTop.current;
		const scrollStep = -feedWrap.scrollTop / 15;
		const scrollInterval = setInterval(() => {
			if (feedWrap.scrollTop !== 0) {
				feedWrap.scrollBy(0, scrollStep);
			} else {
				clearInterval(scrollInterval);
			}
		}, 15);
	};

	return (
		<>
			<div className={`${commentState ? 'wrap no-scroll' : 'wrap'}`}>
				<FeedListHeaderView
					myProfile={myProfile}
					srcPrefix={srcPrefix}
					setMyProfileOpen={setMyProfileOpen}
					setFeedFilterOpen={setFeedFilterOpen}
				/>
				<div id="conWrap" className="metaPopWrap">
					{/*피드검색바*/}
					{/*<FeedSearchHeaderView*/}
					{/*    clickSearch={clickSearch}*/}
					{/*    keyword={keyword}*/}
					{/*    setKeyword={setKeyword}*/}
					{/*    searchFeedOut={searchFeedOut}*/}
					{/*/>*/}
					<div
						className="feedWrap"
						style={{
							overflow: isClickedFeedMenu.state ? 'hidden' : 'auto',
						}}
						ref={feedTop}
					>
						<div
							className={`metaPopContent feedList spinnerContent ${
								fadeIn ? 'fade-in' : ''
							}`}
							onClick={() => {
								initFeedEditPops(postList);
							}}
						>
							{postList != undefined &&
							typeof postList === 'object' &&
							postList.length > 0
								? postList?.map((post) => {
									return (
										<FeedListView
											key={post.postId}
											post={post}
											deleteContent={deleteContent}
											myProfile={myProfile}
											menuDotClickHandler={menuDotClickHandler}
											setOpenReport={setOpenReport}
											setOpenFeedHidePop={setOpenFeedHidePop}
											commentListHandler={commentListHandler}
											openMenuPop={openMenuPop}
											selectedOption={selectedOption}
											handleOptionSelect={handleOptionSelect}
											sliderSettings={sliderSettings}
											srcPrefix={srcPrefix}
											commentCount={post.commentCount}
											commentViewHandler={commentViewHandler}
											setUserProfileOpen={setUserProfileOpen}
											fnSavePostLike={fnSavePostLike}
											setBlockUserUid={setBlockUserUid}
											currentUserUid={myProfile?.userUid}
											setModifyPostId={setModifyPostId}
											setMyProfileOpen={setMyProfileOpen}
											fnCommonConfirmPopOn={fnCommonConfirmPopOn}
											setBlockPostId={setBlockPostId}
											fnFeedEditHandler={fnFeedEditHandler}
											feedEditPops={feedEditPops}
										/>
									);
								})
								: '등록된 피드가 없습니다.'}

							{/* 댓글창 */}
							<div className={`${openComment ? 'commentPopOn' : 'commentPop'}`}>
								<div
									className="commentDim"
									onClick={() => {
										commentListHandler('', { userUid: '', nickname: '' });
										commentViewHandler(false);
									}}
								></div>
								<CSSTransition
									in={openComment}
									classNames="commentInfo-transition"
									timeout={100}
								>
									<FeedCommentView
										key={focusedPostId}
										focusedPostId={focusedPostId}
										myProfile={myProfile}
										fnSaveComment={fnSaveComment}
										commentResponse={commentResponse}
										srcPrefix={srcPrefix}
										commentValue={commentValue}
										setCommentValue={setCommentValue}
										commentViewHandler={commentViewHandler}
										postUser={postUser}
										setMyProfileOpen={setMyProfileOpen}
										setUserProfileOpen={setUserProfileOpen}
										currentUserUid={myProfile?.userUid}
										fnCommonConfirmPopOn={fnCommonConfirmPopOn}
										fnDeleteComment={fnDeleteComment}
									/>
								</CSSTransition>
							</div>

							{/* 새 피드 작성 및 상단 이동 버튼 */}
							<div className="topBtnWrap">
								{isTopBtnVisible && (
									<button
										id="topBtn"
										className="topBtn"
										onClick={scrollToTop}
										style={{
											display: 'block',
										}}
									>
										<span className="hide">상단으로 이동</span>
									</button>
								)}
								<Link to="/metaverse/dating/lounge/form" onClick={initFeedForm}>
									<button type="button" className="plusBtn" />
								</Link>
							</div>
						</div>
					</div>
				</div>

				{/* 신고하기 */}
				<CSSTransition
					in={openReport}
					classNames="popWrap-transition"
					timeout={100}
				>
					<ReportPopView
						setOpenReport={setOpenReport}
						selectedOption={selectedOption}
						handleOptionSelect={handleOptionSelect}
						commonReportCodes={commonReportCodes}
						reportValue={reportValue}
						reportInputHandler={reportInputHandler}
						inputDisabled={inputDisabled}
					/>
				</CSSTransition>
			</div>

			<div
				className={`${
					openFeedHidePop ? 'settingPopOn' : 'settingPop'
				}`}
			>
				<div className="settingDim" />
				{/* 게시글 숨기기 */}
				<CSSTransition
					in={openFeedHidePop}
					classNames="blockPopWrap-transition"
					timeout={100}
				>
					<FeedHidePopView
						setOpenFeedHidePop={setOpenFeedHidePop}
						fnSaveBlockPost={fnSaveBlockPost}
					/>
				</CSSTransition>
			</div>

			<FeedFilterView
				feedFilterOpen={feedFilterOpen}
				checkedFilter={checkedFilter}
				setCheckedFilter={setCheckedFilter}
				setFeedFilterOpen={setFeedFilterOpen}
				feedFilterList={feedFilterList}
				fnGetPosts={fnGetPosts}
				fnGetPostsByGender={fnGetPostsByGender}
				setFadeIn={setFadeIn}
			/>
		</>
	);
};

export default FeedListContainer;