type CharacterData = {
	parts: string[];
	colors: {
		costumeColor: string;
		faceColor: string;
		hairColor: string;
	};
};

export const characterLen = {
	b: 5, // body length
	c: 20, // costume length
	f: 5, // face length
	h: 20, // hair length
	cc: 10, // costume color length
	fc: 5, // face color length
	hc: 10, // hair color length
};

export type avatarType = {
	b: number;
	c: number;
	f: number;
	h: number;
	cc: number;
	fc: number;
	hc: number;
};

const MATCH = {
	b: 'body',
	c: 'costume',
	f: 'face',
	h: 'hair',
	cc: 'costumes',
	fc: 'faces',
	hc: 'hairs',
};

export function randomAvatar(): avatarType {
	return {
		b: Math.floor(Math.random() * characterLen.b) + 1,
		c: Math.floor(Math.random() * characterLen.c) + 1,
		f: Math.floor(Math.random() * characterLen.f) + 1,
		h: Math.floor(Math.random() * characterLen.h) + 1,
		cc: Math.floor(Math.random() * characterLen.cc) + 1,
		fc: Math.floor(Math.random() * characterLen.fc) + 1,
		hc: Math.floor(Math.random() * characterLen.hc) + 1,
	};
}

export function make(avatar: avatarType) {
	return `b${avatar.b}c${avatar.c}f${avatar.f}h${avatar.h}cc${avatar.cc}fc${avatar.fc}hc${avatar.hc}`;
}

export const defaultAvatarMan: avatarType = {
	b: 1,
	c: 1,
	f: 1,
	h: 1,
	cc: 1,
	fc: 1,
	hc: 1,
};

export const defaultAvatarWoman: avatarType = {
	b: 1,
	c: 7,
	f: 1,
	h: 7,
	cc: 1,
	fc: 1,
	hc: 1,
};

export function parseAvatarString(avatarString: string): avatarType {
	const regex = /b(\d+)c(\d+)f(\d+)h(\d+)cc(\d+)fc(\d+)hc(\d+)/;
	const match = avatarString.match(regex);

	if (match) {
		return {
			b: parseInt(match[1]),
			c: parseInt(match[2]),
			f: parseInt(match[3]),
			h: parseInt(match[4]),
			cc: parseInt(match[5]),
			fc: parseInt(match[6]),
			hc: parseInt(match[7]),
		};
	}
	return defaultAvatarMan;
}

export const getCharacter = (name: string) => {
	const split = name.match(/[a-zA-Z]+|[0-9]+(?:\.[0-9]+|)/g);
	if (split == null) return { parts: [], colors: {} };
	const len = split.length ? split.length / 2 : 0;
	const value: string[] = [];
	for (let i = 0; i < len; i++) {
		const k = split?.[i * 2];
		const v = split?.[i * 2 + 1];
		const key = MATCH[k];
		value.push(key + v);
	}
	return {
		parts: value,
		colors: {
			costumeColor: value[4],
			faceColor: value[5],
			hairColor: value[6],
		},
	};
};

export const generateCharacterImages = (
	name: string,
	costumeColor: string,
	faceColor: string,
	hairColor: string,
) => {
	const { parts, colors } = getCharacter(name);
	return parts.map((img) => {
		let fileName = '';
		let colorDirectory = '';
		let typeDirectory = '';
		const match = img.match(/[a-zA-Z]+|[0-9]+(?:\.[0-9]+|)/g);
		if (match == null) return '';
		switch (match[0]) {
			case 'body':
				typeDirectory = 'body';
				break;
			case 'costume':
				typeDirectory = 'costumes';
				colorDirectory = `${colors.costumeColor}`;
				break;
			case 'face':
				typeDirectory = 'faces';
				colorDirectory = `${colors.faceColor}`;
				break;
			case 'hair':
				typeDirectory = 'hairs';
				colorDirectory = `${colors.hairColor}`;
				break;
			case 'costumes':
			case 'faces':
			case 'hairs':
				colorDirectory = `${img}`;
				return '';
			default:
				colorDirectory = '';
				break;
		}
		// costume, face, and hair: add colorDirectory
		if (match[0] === 'costume' || match[0] === 'face' || match[0] === 'hair') {
			fileName = `/assets/character/avatar/${typeDirectory}/${colorDirectory}/${img}.png`;
		} else {
			fileName = `/assets/character/avatar/${typeDirectory}/${img}.png`;
		}
		return fileName;
	});
};

export const getOtherPlayerCharacter = (name: string): CharacterData => {
	const split = name.match(/[a-zA-Z]+|[0-9]+(?:\.[0-9]+|)/g);
	if (split == null)
		return {
			parts: [],
			colors: { costumeColor: '', faceColor: '', hairColor: '' },
		};
	const len = split.length ? split.length / 2 : 0;
	const value: string[] = [];
	for (let i = 0; i < len; i++) {
		const k = split?.[i * 2];
		const v = split?.[i * 2 + 1];
		const key = MATCH[k];
		value.push(key + v);
	}
	return {
		parts: value,
		colors: {
			costumeColor: value[4] || '',
			faceColor: value[5] || '',
			hairColor: value[6] || '',
		},
	};
};

export const getOtherPlayerCharacterFile = (characterData: {
	parts: string[];
	colors: { costumeColor: string; faceColor: string; hairColor: string };
}) => {
	const { parts, colors } = characterData;
	return parts.map((img) => {
		let fileName = '';
		let colorDirectory = '';
		let typeDirectory = '';
		const match = img.match(/[a-zA-Z]+|[0-9]+(?:\.[0-9]+|)/g);
		if (match == null) return '';
		switch (match[0]) {
			case 'body':
				typeDirectory = 'body';
				break;
			case 'costume':
				typeDirectory = 'costumes';
				colorDirectory = `${colors.costumeColor}`;
				break;
			case 'face':
				typeDirectory = 'faces';
				colorDirectory = `${colors.faceColor}`;
				break;
			case 'hair':
				typeDirectory = 'hairs';
				colorDirectory = `${colors.hairColor}`;
				break;
			case 'costumes':
			case 'faces':
			case 'hairs':
				colorDirectory = `${img}`;
				return '';
			default:
				colorDirectory = '';
				break;
		}
		if (match[0] === 'costume' || match[0] === 'face' || match[0] === 'hair') {
			fileName = `/assets/character/avatar/${typeDirectory}/${colorDirectory}/${img}.png`;
		} else {
			fileName = `/assets/character/avatar/${typeDirectory}/${img}.png`;
		}
		return fileName;
	});
};

export function getRange(length: number) {
	const range: number[] = [];
	for (let i = 1; i <= length; i++) {
		range.push(i);
	}
	return range;
}

export function removeAfterUnderscore(input_string: string | undefined) {
	if (input_string === undefined) {
		// 처리할 문자열이 없는 경우 또는 null일 경우에 대한 예외 처리
		return '';
	}

	// 첫 번째 언더스코어가 나오는 위치를 찾아서 그 전까지의 문자열을 추출
	const index = input_string.indexOf('_');
	const result = index !== -1 ? input_string.substring(0, index) : input_string;

	return result;
}

export const colors = [
	'#c92257',
	'#79b001',
	'#42bc7f',
	'#ff9545',
	'#4c7df6',
	'#ffdfc4',
	'#924fc4',
	'#d6e6f7',
	'#545984',
	'#ff82b9',
];

export const faceColors = [
	'#ffe1cf',
	'#ae5f79',
	'#65e78f',
	'#ffebee',
	'#f9cd8e',
];
