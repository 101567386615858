import RegisterHeaderView from "../common/registerHeader/views/RegisterHeaderView";
import JobSelectFooterView from "./views/JobSelectFooterView";
import useJobSelectService from "@dating/ui/pages/register/job/service/useJobSelectService";
import React, {useEffect} from "react";
import {useRegisterHeaderService} from "@dating/ui/pages/register/common/registerHeader/service/useRegisterHeaderService";
import {useNavigate} from "react-router-dom";
import StepProgressView from "@dating/ui/pages/register/common/stepProgress/views/StepProgressView";
import JobSelectListView from "@dating/ui/pages/register/job/views/JobSelectListView";

const JobSelectContainer = () => {
    const {
        setSelectedJob,
        btnActivate,
        fnSaveJob,
        jobList,
        selectedJob,
        response,
        registerSteps,
    } = useJobSelectService();

    const {
        referralCodeOpen,
        setReferralCodeOpen,
        skipHandler,
		step,
    } = useRegisterHeaderService();

    const navigate = useNavigate();
    useEffect(() => {
        if (response) {
            navigate(registerSteps[6].url);
        }
    }, [response]);

    return (
        <div className="wrap noFooter">
            <RegisterHeaderView
                referralCodeOpen={referralCodeOpen}
                setReferralCodeOpen={setReferralCodeOpen}
                skipHandler={skipHandler}
            />

            <div className="contentsWrap">
                <div className="titleWrap">
                    <StepProgressView
                        step={step}
                    />
                    <h2 className="title01 padding-b16">직업 선택</h2>
                    <p className="titleSub01">본인의 직업을 선택해 주세요</p>
                </div>
                <JobSelectListView
                    setSelectedJob={setSelectedJob}
                    jobList={jobList}
                    selectedJob={selectedJob}
                />
            </div>
            <JobSelectFooterView
                fnSaveJob={fnSaveJob}
                btnActivate={btnActivate}
            />
        </div>
    );
};

export default JobSelectContainer;
