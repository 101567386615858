import { useJoystickRepo } from '@/stores/useJoystickRepo';

// 조이스틱 동작을 처리하는 함수
export const handleJoystickAction = (event) => {
	const { setJoystickDirection, setJoystickMoreDirection } =
		useJoystickRepo.getState();
	const { type, direction, x, y } = event;
	const moreEventDirection = calculateMoreDirection(x, y, direction);

	// 조이스틱의 방향과 추가적인 방향을 저장하는 액션 디스패치
	setJoystickDirection(type);
	setJoystickDirection(direction);
	setJoystickMoreDirection(moreEventDirection);
};

export const calculateMoreDirection = (x, y, direction) => {
	const atanX = Math.atan(x);
	const atanY = Math.atan(y);

	// TODO: 임시 8방향 확장, 민감도와 각종 값은 설정값으로 빼서 관리할 수 있도록...
	switch (direction) {
		case 'LEFT':
			if (atanY <= -0.5) return 'LEFT-BACKWARD';
			if (atanY >= 0.5) return 'LEFT-FORWARD';
			return 'LEFT';
		case 'FORWARD':
			if (atanX <= -0.5) return 'LEFT-FORWARD';
			if (atanX >= 0.5) return 'RIGHT-FORWARD';
			return 'FORWARD';
		case 'RIGHT':
			if (y <= -0.5) return 'RIGHT-BACKWARD';
			if (y >= 0.5) return 'RIGHT-FORWARD';
			return 'RIGHT';
		case 'BACKWARD':
			if (atanX <= -0.5) return 'LEFT-BACKWARD';
			if (atanX >= 0.5) return 'RIGHT-BACKWARD';
			return 'BACKWARD';
		default:
			return '';
	}
};
