import {Link} from "react-router-dom";

interface props {
    isReceived: boolean;
    changeLikeType: (isReceived: boolean) => void;
}

const LikeHeaderView = ({
                            isReceived,
                            changeLikeType,
                        }: props) => {
    return (
        <div className="conHeadWarp">
            <div className="tabList">
                <button
                    type="button"
                    className={`tabButton tab01 ${isReceived ? 'active' : ''}`}
                >
                    <Link to="/metaverse/dating/like/receivedLike"
                          onClick={() => {
                              changeLikeType(true);
                          }}>
                        받은 관심
                    </Link>
                </button>
                <button
                    type="button"
                    className={`tabButton tab02 ${!isReceived ? 'active' : ''}`}
                >
                    <Link to="/metaverse/dating/like/sentLike"
                          onClick={() => {
                              changeLikeType(false);
                          }}>
                        보낸 관심
                    </Link>
                </button>
            </div>
        </div>
    );
};

export default LikeHeaderView;
