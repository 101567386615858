import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { useMediaBtnService } from './service/useMediaBtnService';

const MediaBtnWrapContainer = () => {
	const {
		mediaConnected,
		videoControl,
		audioControl,
		handleVideoConnected,
		handleAudioStatus,
	} = useMediaBtnService();
	return (
		<div
			className="h-mediaBtnWrap"
			onClick={() => {
				{
					!mediaConnected ? reactEventEmitter.emit('react-user-media') : null;
				}
			}}
		>
			<button
				className={`cameraBtn ${videoControl ? 'on' : ''}`}
				onClick={handleVideoConnected}
			/>
			<button
				className={`voiceBtn ${audioControl ? 'on' : ''}`}
				onClick={handleAudioStatus}
			/>
		</div>
	);
};

export default MediaBtnWrapContainer;
