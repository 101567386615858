import { useState } from "react";

export const useIdealTypeStateRepo = () => {
	// spinner handler
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// fade-in class handler
	const [fadeIn, setFadeIn] = useState<boolean>(false);

    return {
		isLoading,
		setIsLoading,
		fadeIn,
		setFadeIn,
    };
};