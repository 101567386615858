import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { create } from 'zustand';
import {
	IDirectMessage,
	IDMRoom,
	IUser,
} from '../../../types/IDirectMessageState';

type DirectMessageRepoState = {
	dmSessionId: string;
	currentRoomId: string | null;
	relatedRoomIds: string[];
	directMessageRoomList: Array<IDMRoom>;
	groupedMessages: Record<string, any>;
	detailDirectMessages: Record<string, any>;
	directMessages: Array<IDirectMessage>;
	directMessageList: any[];
	startDirectMessageList: any[];
	groupDirectMessageList: any[];
	focused: boolean;
	openDirectMessageDetail: {
		isOpen: boolean;
		roomId: string;
	};
	newDirectMessage: Partial<IDirectMessage>;
	showDirectMessageList: boolean;
	managerEmpty: boolean;
	leaveDirectMessage: Record<
		string,
		{
			roomId: string;
			leaverId: string;
		}
	>;
	exitDirectMessage: Record<
		string,
		{
			roomId: string;
			exit: boolean;
		}
	>;
	mutualPaymentCompleted: Record<
		string,
		{
			roomId: string;
			paid: boolean;
		}
	>;
	changeDirectRoomType: Record<
		string,
		{
			roomId: string;
			dmRoomType: string;
		}
	>;
	messageInputValue: string;
	unreadMessageCounts: Record<string, number>;
	userSessionStatus: Record<
		string,
		{
			targetUuid: string;
			isOnline: string;
		}
	>;

	setUserSessionStatus: (status: Record<string, any>) => void;
	setChangeDirectRoomType: (changeDirectRoomType: Record<string, any>) => void;
	setMutualPaymentCompleted: (completed: Record<string, any>) => void;
	setGroupedMessages: (groupedMessages: Record<string, any>) => void;
	setDetailDirectMessages: (detailDirectMessages: Record<string, any>) => void;
	setCurrentRoom: (roomId: string) => void;
	setRelatedRooms: (roomIds: string[]) => void;
	addRelatedRoom: (roomId: string) => void;
	updateUnreadMessageCount: (roomId: string, count: number) => void;
	setDmSessionId: (dmSessionId: string) => void;
	setDirectMessageRoomList: (rooms: Array<IDMRoom>) => void;
	addDirectMessageRoom: (room: IDMRoom) => void;
	updateDirectMessageRoom: (room: IDMRoom) => void;
	removeDirectMessageRoom: (roomId: string) => void;
	setDirectMessages: (message: IDirectMessage[]) => void;
	addDirectMessage: (message: IDirectMessage) => void;
	setDirectMessageList: (list: any[]) => void;
	setStartDirectMessageList: (list: any[]) => void;
	setGroupDirectMessageList: (list: any[]) => void;
	setFocused: (focused: boolean) => void;
	setOpenDirectMessageDetail: (isOpen: boolean, roomId: string) => void;
	setShowDirectMessageList: (show: boolean) => void;
	setManagerEmpty: (empty: boolean) => void;
	setLeaveDirectMessage: (leave: Record<string, any>) => void;
	setExitDirectMessage: (exit: Record<string, any>) => void;
	setMessageInputValue: (value: string) => void;
};

export const useDirectMessageRepo = create<DirectMessageRepoState>((set) => ({
	currentRoomId: null,
	relatedRoomIds: [],
	dmSessionId: '',
	directMessageRoomList: [],
	groupedMessages: {},
	detailDirectMessages: {},
	directMessages: [],
	directMessageList: [],
	startDirectMessageList: [],
	groupDirectMessageList: [],
	focused: false,
	openDirectMessageDetail: {
		isOpen: false,
		roomId: '',
	},
	newDirectMessage: {
		roomId: '',
		messageId: 0,
		sender: {} as IUser,
		receiver: {} as IUser,
		takerId: '',
		content: '',
		read: false,
	},
	showDirectMessageList: false,
	managerEmpty: false,
	leaveDirectMessage: {},
	exitDirectMessage: {},
	mutualPaymentCompleted: {},
	changeDirectRoomType: {},
	messageInputValue: '',
	unreadMessageCounts: {},
	userSessionStatus: {},

	// Message 맵으로 만들고 새로 만든 함수들

	setUserSessionStatus: (status) => set({ userSessionStatus: status }),
	setGroupedMessages: (groupedMessages) => set({ groupedMessages }),
	setDetailDirectMessages: (detailDirectMessages) =>
		set({ detailDirectMessages }),
	updateUnreadMessageCount: (roomId, count) =>
		set((state) => ({
			unreadMessageCounts: {
				...state.unreadMessageCounts,
				[roomId]: count ?? 0,
			},
		})),
	setCurrentRoom: (roomId: string) => set({ currentRoomId: roomId }),

	setRelatedRooms: (roomIds: string[]) => set({ relatedRoomIds: roomIds }),

	addRelatedRoom: (roomId: string) =>
		set((state) => ({
			relatedRoomIds: [...new Set([...state.relatedRoomIds, roomId])],
		})),
	setDmSessionId: (dmSessionId: string) => set({ dmSessionId }),
	setDirectMessageRoomList: (rooms: Array<IDMRoom>) =>
		set({ directMessageRoomList: rooms }),
	addDirectMessageRoom: (room: IDMRoom) =>
		set((state) => ({
			directMessageRoomList: [...state.directMessageRoomList, room],
		})),
	updateDirectMessageRoom: (updatedRoom: IDMRoom) =>
		set((state) => ({
			directMessageRoomList: state.directMessageRoomList.map((room) =>
				room.roomId === updatedRoom.roomId ? updatedRoom : room,
			),
		})),
	removeDirectMessageRoom: (roomId: string) =>
		set((state) => ({
			directMessageRoomList: state.directMessageRoomList.filter(
				(room) => room.roomId !== roomId,
			),
		})),
	setDirectMessages: (messages: IDirectMessage[]) =>
		set({ directMessages: messages }),
	addDirectMessage: (message: IDirectMessage) =>
		set((state) => ({
			directMessages: [...state.directMessages, message],
		})),
	setDirectMessageList: (list) => set({ directMessageList: list }),
	setStartDirectMessageList: (list) => set({ startDirectMessageList: list }),
	setGroupDirectMessageList: (list) => set({ groupDirectMessageList: list }),
	setFocused: (focused) => {
		set({ focused });
		reactEventEmitter.emit('react-enable-key', !focused);
	},
	setOpenDirectMessageDetail: (isOpen, roomId) =>
		set({ openDirectMessageDetail: { isOpen, roomId } }),
	setNewDirectMessage: (message) => set({ newDirectMessage: message }),
	setShowDirectMessageList: (show) => set({ showDirectMessageList: show }),
	setManagerEmpty: (empty) => set({ managerEmpty: empty }),
	setLeaveDirectMessage: (leave) => set({ leaveDirectMessage: leave }),
	setExitDirectMessage: (exit) => set({ exitDirectMessage: exit }),
	setMutualPaymentCompleted: (paid) => set({ mutualPaymentCompleted: paid }),
	setChangeDirectRoomType: (changeDirectRoomType) =>
		set({ changeDirectRoomType }),
	setMessageInputValue: (value) => set({ messageInputValue: value }),
}));
