interface props {
    fnSaveUserStyle: () => void;
};

const LifeStyleFooterView = ({
                                 fnSaveUserStyle,
                             }: props) => {
    return (
        <footer>
        <div className="f-btnWrap">
          <button className="btn btnBig btnBlack" onClick={fnSaveUserStyle}>
            <span>확 인</span>
          </button>
        </div>
      </footer>
    )
};

export default LifeStyleFooterView;




