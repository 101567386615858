import EditProfileDetailView from './views/EditProfileDetailView';
import EditProfileImgView from '@dating/ui/pages/profile/editProfile/views/EditProfileImgView';
import {useProfileEditService} from "@dating/ui/pages/profile/editProfile/service/useProfileEditService";
import {EditProfileHeaderView} from "@dating/ui/pages/profile/editProfile/views/EditProfileHeaderView";
import { EditProfilePopContainer } from '@dating/ui/components/editProfilePop/EditProfilePopContainer';

const ProfileEditContainer = () => {

    const {
        title,
        previewURLs,
        clearPreviewURLs,
        myModifyProfile,
        openPopupHandler,
    } = useProfileEditService();

    return (
        <>
            <div className="rightShow active">
                <EditProfileHeaderView
                    right={true}
                    title={title}
                    clearPreviewURLs={clearPreviewURLs}
                />
                <div className="popConWrap padding-b0">
                    <div className="inner">
                        <div className="settingWrap">
                            <p className="bold-500 color-666 padding-b16">
                                수정할 항목을 선택하면 변경할 수 있습니다.
                            </p>
                            <EditProfileImgView
                                previewURLs={previewURLs}
                            />
                            <EditProfileDetailView
                                myModifyProfile={myModifyProfile}
                                openPopupHandler={openPopupHandler}
                            />
                            {/*<EditProfileFooterView*/}
                            {/*    clearPreviewURLs={clearPreviewURLs}*/}
                            {/*/>*/}
                        </div>
                    </div>
                </div>
            </div>

            {/*프로필 수정 팝업*/}
            <EditProfilePopContainer />
        </>
    );
};

export default ProfileEditContainer;
