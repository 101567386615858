import { useNavigate } from 'react-router-dom';
interface ButtonHeaderContainerProps {
	right: boolean;
	title?: string;
	berith?: number;
}
const ButtonHeaderView = ({
	right,
	title,
	berith,
}: ButtonHeaderContainerProps) => {
	const navigate = useNavigate();
	const onClickBack = () => {
        navigate(-1);
	};

	return (
		<header>
			<div className="headerWrap">
				<div className="h-left">
					{!right && (
						<button type="button" className="btnBack" onClick={onClickBack} />
					)}
				</div>
				<div className="h-center">
					<p className="title02">{title}</p>
				</div>
				<div className="h-right">
					<p className="ic-berith sm">{berith}</p>
					{right && (
						<button type="button" className="btnClose" onClick={onClickBack} />
					)}
				</div>
			</div>
		</header>
	);
};

export default ButtonHeaderView;
