import { getSitAnimationNum } from "../myplayer/state/GetSitAnimationNum";
import OtherPlayer from "./OtherPlayer";

export class OtherPlayerState {
  constructor(private otherPlayer: OtherPlayer) {}
  update() {
    const animParts = this.otherPlayer.anims.currentAnim!.key.split("_");
    const animState = animParts[1];
    if (animState === "sit") {
      const animDir = animParts[2];
      const sittingShift = getSitAnimationNum(animDir);
      if (sittingShift) {
        // 플레이어가 앉을 경우 하드 코딩된 깊이(방향 사이의 간격) 설정
        this.otherPlayer.setDepth(this.otherPlayer.depth + sittingShift[2]);
      }
    }
  }
}
