import {UserProfileCOVO} from "@/site/api";

interface props {
    profile: UserProfileCOVO;
}

const StarBadgeWrapView = ({
                               profile,
                           }: props) => {
    const badges = [
        {
            id: "ceo",
            className: "ceoBadge",
            src: "/assets/img/common/ico-star-badge_ceo.png",
            alt: "사업가 뱃지",
            title: "사업가",
        },
        {
            id: "house",
            className: "myhomeBadge",
            src: "/assets/img/common/ico-star-badge_myhome.png",
            alt: "자가소유 뱃지",
            title: "자가소유",
        },
        {
            id: "salary",
            className: "billionBadge",
            src: "/assets/img/common/ico-star-badge_billion.png",
            alt: "억대연봉 뱃지",
            title: "억대연봉",
        },
        {
            id: "profession",
            className: "professionalBadge",
            src: "/assets/img/common/ico-star-badge_professional.png",
            alt: "전문직 뱃지",
            title: "전문직",
        },
        {
            id: "car",
            className: "mycarBadge",
            src: "/assets/img/common/ico-star-badge_mycar.png",
            alt: "자차소유 뱃지",
            title: "자차소유",
        },
    ];
    return (
        <div className="starBadgeWrap">
            <ul>
                {badges.map((badge, i) => {
                    return (
                        <li key={badge.className} className={badge.className}>
                            <img
                                src={badge.src}
                                alt={badge.alt}
                            />
                            <span>{badge.title}</span>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

export default StarBadgeWrapView;
