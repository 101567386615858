import { create } from "zustand";

interface LeaveChatState {
  openChatMenuPop: boolean;
  setOpenChatMenuPop: (openChatMenuPop: boolean) => void;
}

const useLeaveChatStore = create<LeaveChatState>(
	(set) => ({
		openChatMenuPop: false,
		setOpenChatMenuPop: (openChatMenuPop: boolean) =>
			set({ openChatMenuPop }),
	}),
);

export default useLeaveChatStore;
