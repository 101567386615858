import {usePaymentRepo} from '@dating/stores/PaymentStore';

const PaymentPopView = () => {
	const { setOpenPaymentPopUp } = usePaymentRepo();
	return (
		<div className="openPaymentPopUp">
			<div className="popupInfo padding-b16">
				<div className="frameWrap">
					<div className="refillWrap">
						<h2 className="title02">베리드 충전</h2>
						<div className="refillList">
							<button
								type="button"
								className="rfBox"
								onClick={() => {
									setOpenPaymentPopUp(false);
								}}
							>
								<div>
									<p className="ic-berith md">100개</p>
								</div>
								<span className="price">10,000원</span>
							</button>
							<button
								type="button"
								className="rfBox bestBox"
								onClick={() => {
									setOpenPaymentPopUp(false);
								}}
							>
								<div className="d-flex">
									<p className="ic-berith md">315개</p>
									<p className="cancell">300개</p>
								</div>
								<span className="price">30,000원</span>
							</button>
						</div>
					</div>
					<div className="popFtBtnWrap">
						<button
							type="button"
							className="popCloseBtn btn btnBig btnCCC"
							onClick={() => {
								setOpenPaymentPopUp(false);
							}}
						>
							<span>취소</span>
						</button>
						<button
							type="button"
							className="popCloseBtn btn btnBig btnBlack"
							onClick={() => {
								setOpenPaymentPopUp(false);
							}}
						>
							<span>확 인</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaymentPopView;
