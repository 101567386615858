import {UserProfileCOVO} from "@/site/api";
import BtnMenuView from "@dating/ui/pages/header/views/BtnMenuView";
import ProfileSmImgView from "@dating/ui/pages/header/views/ProfileSmImgView";

interface props {
    myProfile: UserProfileCOVO;
    srcPrefix: string;
    setMyProfileOpen: (myProfileOpen: boolean) => void;
    setFeedFilterOpen: (feedFilterOpen: boolean) => void;
}

export const FeedListHeaderView = ({
                                       myProfile,
                                       srcPrefix,
                                       setMyProfileOpen,
                                       setFeedFilterOpen,
                                   }: props
) => {
    return (
        <header className="datingHeader">
            <div className="headerWrap">
                <div className="h-left">
                    <BtnMenuView />
                </div>
                <div className="h-center" />
                <div className="h-right">
                    <button
                        type="button"
                        className="btnFilter"
                        onClick={() => {
                            setFeedFilterOpen(true);
                        }}
                    />

                    {/*내 프로필*/}
                    <ProfileSmImgView
                        myProfile={myProfile}
                        srcPrefix={srcPrefix}
                        setMyProfileOpen={setMyProfileOpen}
                    />
                </div>
            </div>
        </header>
    );
};