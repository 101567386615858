import { useHeaderRepo } from '@dating/repository/dating/useHeaderRepo';
import {usePaymentRepo} from '@dating/stores/PaymentStore';
import { useLoungeAdapter } from '@dating/adapter/lounge/useLoungeAdapter';
import { useProfileStateRepo } from '@dating/ui/pages/profile/useProfileStateRepo';
import { useDatingRepo } from '@dating/repository/dating/useDatingRepo';
import { useProfileAdapter } from '@dating/adapter/profile/useProfileAdapter';
import { useCallback, useEffect } from 'react';
import { useLikeAdapter } from '@dating/adapter/like/useLikeAdapter';
import useLoungeRepo from '@dating/repository/lounge/useLoungeRepo';
import { useLocation } from 'react-router-dom';
import { PostIdCIVO, PostInfoCOVO, PostLikeCIVO, type RandomUserScoreCIVO, UserProfileCOVO } from '@/site/api';
import { useQuery } from '@tanstack/react-query';
import { reactEventEmitter } from '@/events/ReactEventEmitter';
import { useEditProfileRepo } from '@dating/repository/profile/useEditProfileRepo';
import { useHomeAdapter } from '@dating/adapter/home/useHomeAdapter';
import { useHomeRepo } from '@dating/repository/home/useHomeRepo';

export const useProfileService = () => {
	const location = useLocation();
	const isOtherProfile = location.state?.isOtherProfile ?? false;

	const {
		fadeIn,
		setFadeIn,
		scrollOn,
		setScrollOn,
		profileSliderInitialized,
		setProfileSliderInitialized,
		likeBtnState,
		setLikeBtnState,
		myPosts,
		setMyPosts,
		profileDetails,
		setProfileDetails,
		editBtnShow,
		setEditBtnShow,
		isChecked,
		setIsChecked,
		isClickedFeedMenu,
		setIsClickedFeedMenu,
	} = useProfileStateRepo();

	const {
		setModifyPostId,
		feedEditPops,
		setFeedEditPops,
	} = useLoungeRepo();

	const { setSettingOpen } = useHeaderRepo();
	const {
		isProfileLiked,
		setIsProfileLiked,
	} = useHomeRepo();

	const {
		setLikedLocation,
	} = usePaymentRepo();

	const {
		srcPrefix,
		myProfile,
		setMyProfile,
		myProfileOpen,
		setMyProfileOpen,
		userProfile,
		setUserProfile,
		userProfileOpen,
		setUserProfileOpen,
		sliderSettings,
	} = useDatingRepo();

	const { modifyProfileUrl, setModifyProfileUrl } = useEditProfileRepo();

	const { getMyPosts, removePost, statusRemovePost, dataRemovePost } =
		useLoungeAdapter();

	const { getMyProfile, getUserProfile } = useProfileAdapter();

	const { savePostLike, statusSavePostLike, dataSavePostLike } =
		useLikeAdapter();

	const {
		saveScoreRandomUser,
		statusSaveScoreRandomUser,
		dataSaveScoreRandomUser,
	} = useHomeAdapter();

	const { status: statusGetMyPosts, data: dataGetMyPosts } = useQuery({
		queryKey: ['getMyPosts'],
		queryFn: () => {
			return getMyPosts();
		},
		enabled: myProfileOpen,
	});

	////////////////////////////////////////////////////////////

	/*
	 * initProfileDetail
	 * StepLastBoxView content list set
	 */
	function initProfileDetail(profile: UserProfileCOVO) {
		const lifeStyleValues = profile.lifeStyle.concat(profile.extraLifeStyle);
		const interestValues = profile.interest.concat(profile.extraInterest);

		const list = [
			{
				key: 'job',
				title: '직업',
				value: profile.job ? profile.job : '',
			},
			{
				key: 'height',
				title: '키',
				value: profile.height ? profile.height : '',
			},
			{
				key: 'life',
				title: '라이프 스타일',
				value: lifeStyleValues.join(', '),
			},
			{
				key: 'like',
				title: '관심사',
				value: interestValues.join(', '),
			},
			{
				key: 'smoking',
				title: '흡연여부',
				value: profile.smoking ? profile.smoking : '',
			},
		];

		setProfileDetails(list);
	}

	useEffect(() => {
		return setScrollOn(false);
	}, [myProfileOpen, userProfileOpen]);

	useEffect(() => {
		if (scrollOn) {
			setEditBtnShow(true);
		} else {
			setEditBtnShow(false);
		}
	}, [scrollOn]);

	function scrollHandler(y: number, rectTop: number) {
		if (y > 0 && rectTop <= 134) {
			setScrollOn(true);
		} else {
			setScrollOn(false);
		}
	}

	/*
	 * deleteContent
	 * 피드삭제
	 */
	const deleteContent = (id: string) => {
		const map: PostIdCIVO = {
			postId: id.toString(),
		};
		removePost(map);
	};

	useEffect(() => {
		if (statusGetMyPosts === 'success' && dataGetMyPosts && myProfileOpen) {
			setMyPosts(dataGetMyPosts);
			// 케밥 control list set
			initFeedEditPops(dataGetMyPosts);
		}
	}, [statusGetMyPosts, dataGetMyPosts]);

	/*
	 * fnSavePostLike
	 * 게시글 좋아요
	 */
	const fnSavePostLike = (postId: string, likeYn: boolean) => {
		const map: PostLikeCIVO = {
			likePostId: postId,
			likeYn: likeYn,
		};
		savePostLike(map);
	};

	/*
	 * initProfile
	 * 뒤로가기 클릭 시 프로필 관련 설정값 초기화
	 */
	const initProfile = () => {
		setMyProfileOpen(false);
		setProfileDetails([]);
		setUserProfileOpen('');
	};

	/*
	 * evalHandler
	 * 별점평가 및 애니메이션 처리
	 */
	let timer: NodeJS.Timeout;
	const evalHandler = (userUid: string, score: number) => {
		timer = setTimeout(
			() => {
				const map: RandomUserScoreCIVO = {
					randomUserId: userUid,
					score: score,
				};
				saveScoreRandomUser(map);
			},
			1000,
			userUid,
			score,
		);
	};

	/*
	 * initUserProfile
	 * 상대프로필 조회 후 data binding
	 */
	function initUserProfile(profile: UserProfileCOVO) {
		setUserProfile(profile);
		initProfileDetail(profile);
		setIsChecked(profile.LatestScore);
	};

	useEffect(() => {
		if (statusSaveScoreRandomUser === 'success' && dataSaveScoreRandomUser) {
			clearTimeout(timer);
			if (userProfileOpen != '' && isOtherProfile) {
				getUserProfile(userProfileOpen).then((value) => {
					initUserProfile(value);
				});
			}
		}
	}, [statusSaveScoreRandomUser, dataSaveScoreRandomUser]);

	useEffect(() => {
		// 내 프로필일 때
		if (myProfileOpen) {
			getMyProfile(myProfile.phoneNumber).then((value) => {
				setFadeIn(true);
				setMyProfile(value);
				initProfileDetail(value);
			});
			setFadeIn(false);
		}

		// 상대 프로필일 때
		if (userProfileOpen != '' && isOtherProfile) {
			getUserProfile(userProfileOpen).then((value) => {
				setFadeIn(true);
				initUserProfile(value);
				setIsProfileLiked(false);
			});
		}
	}, []);


	// 메타버스 Room 안에서 프로필 수정 시 프로필 이미지 변경
	const handleProfileUpdate = useCallback(() => {
		getMyProfile(myProfile.phoneNumber)
			.then((value) => {
				setMyProfile(value);
				initProfileDetail(value);
				if (value && value.profileUrl && value.profileUrl.length > 0) {
					reactEventEmitter.emit(
						'react-player-change-profile-url',
						value.profileUrl[0],
					);
				}
			})
			.catch((error) => {
				console.error('Error in getMyProfile:', error);
			})
			.finally(() => {
				setModifyProfileUrl(false);
			});
	}, []);

	useEffect(() => {
		if (modifyProfileUrl) {
			handleProfileUpdate();
		}
	}, [modifyProfileUrl, handleProfileUpdate]);

	/*
	 * initFeedEditPops
	 * feedEditPop 값 전부 false로 초기화
	 */
	const initFeedEditPops = (feeds: PostInfoCOVO[]) => {
		let newItem = {};
		feeds.map((feed, i) => {
			newItem[feed.postId] = false;
		});
		setFeedEditPops(newItem);
		setIsClickedFeedMenu({ postId: '', state: false });
	};

	/*
	 * fnFeedEditHandler
	 * 클릭한 피드의 케밥만 open
	 */
	const fnFeedEditHandler = (postId: string) => {
		initFeedEditPops(myPosts);
		setIsClickedFeedMenu({ postId: postId, state: true });
	};

	useEffect(() => {
		if (isClickedFeedMenu.state && isClickedFeedMenu.postId != '') {
			let newItem = feedEditPops;
			newItem[isClickedFeedMenu.postId] = true;
			setFeedEditPops(newItem);
		}
	}, [feedEditPops, isClickedFeedMenu]);

	return {
		fadeIn,
		scrollHandler,
		setSettingOpen,
		myPosts,
		deleteContent,
		profileSliderInitialized,
		setProfileSliderInitialized,
		sliderSettings,
		srcPrefix,
		myProfile,
		userProfile,
		fnSavePostLike,
		profileDetails,
		isOtherProfile,
		setModifyPostId,
		initProfile,
		editBtnShow,
		isChecked,
		setIsChecked,
		evalHandler,
		fnFeedEditHandler,
		feedEditPops,
		initFeedEditPops,
		isClickedFeedMenu,
		setLikedLocation,
		isProfileLiked,
	};
};
