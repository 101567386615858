import { useRoomRepo } from '@/stores/useRoomRepo';

const RoomListWrapContainer = () => {
	const { roomListOpen, setRoomListOpen } = useRoomRepo();
	return (
		<div className="h-roomListWrap">
			<button
				className="roomListBtn on"
				onClick={() => {
					setRoomListOpen(!roomListOpen);
				}}
			>
				<span>방 목록</span>
			</button>
		</div>
	);
};

export default RoomListWrapContainer;
