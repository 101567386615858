import { create } from 'zustand';
import type { UserProfileCOVO } from '@/site/api';

export type EditType = {
	nickName: boolean;
	job: boolean;
	height: boolean;
	drinking: boolean;
	smoking: boolean;
	religion: boolean;
	introduce: boolean;
	interest: boolean;
	lifeStyle: boolean;
};

interface EditProfileState {
	title: string;

	focusItem: { label: string; value: string | Array<string> };
	setFocusItem: (focusItem: { label: string; value: string }) => void;
	clearFocusItem: () => void;

	editPop: EditType;
	setEditPop: (editPop: EditType) => void;
	clearEditPop: () => void;

	// 내 프로필 수정
	myModifyProfile: UserProfileCOVO;
	setMyModifyProfile: (myModifyProfile: UserProfileCOVO) => void;

	modifyProfileUrl: boolean;
	setModifyProfileUrl: (modifyProfileUrl: boolean) => void;
}

export const useEditProfileRepo = create<EditProfileState>((set) => ({
	title: '프로필 수정',

	focusItem: { label: '', value: [] },
	setFocusItem: (focusItem) => set({ focusItem }),
	clearFocusItem: () =>
		set({
			focusItem: { label: '', value: [] },
		}),

	editPop: {
		nickName: false,
		job: false,
		height: false,
		drinking: false,
		smoking: false,
		religion: false,
		introduce: false,
		interest: false,
		lifeStyle: false,
	},
	setEditPop: (editPop: EditType) => set({ editPop }),
	clearEditPop: () =>
		set({
			editPop: {
				nickName: false,
				job: false,
				height: false,
				drinking: false,
				smoking: false,
				religion: false,
				introduce: false,
				interest: false,
				lifeStyle: false,
			},
		}),

	myModifyProfile: {
		profileUrl: [],
		lifeStyle: [],
		job: '',
		interest: [],
		extraLifeStyle: [],
		smoking: '',
		extraInterest: [],
		height: 0,
		userUid: '',
		age: '',
		nickName: '',
		location: '',
		drink: '',
		car: false,
		house: false,
		religion: '',
		ceo: false,
		salary: false,
		introduce: '',
		birthday: '',
		gender: '',
		profession: false,
		AverageScore: 0,
		LatestScore: 0,
		phoneNumber: "",
	},
	setMyModifyProfile: (myModifyProfile: UserProfileCOVO) =>
		set({ myModifyProfile }),

	modifyProfileUrl: false,
	setModifyProfileUrl: (modifyProfileUrl: boolean) => set({ modifyProfileUrl }),
}));
