import {
	MetaRoomCIVO,
	MetaService,
	MetaRoomUserInCIVO,
	MetaRoomUserOutCIVO,
	MetaRoomChatCIVO,
	MetaDisposeRoomCIVO,
} from '@/site/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useMetaRoomAdapter = () => {
	const queryClient = useQueryClient();

	/*
	 * saveCreateRoom
	 * 메타버스 방만들기 룸생성 저장
	 */
	const saveCreateRoom = useMutation({
		mutationFn: (map: MetaRoomCIVO) => MetaService.saveCreateRoom(map),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getUsableRoom'] });
			return data;
		},
		onError: (error) => {
			console.error('Room creation error:', error);
		},
	});

	/*
	 * getUsableRoom
	 * 메타버스 사용가능한 룸조회
	 */
	// const getUsableRoom = useQuery({
	// 	queryKey: ['getUsableRoom'],
	// 	queryFn: () => MetaService.getUsableRoom(),
	// });

	/*
	 * saveRoomUserIn
	 * 메타버스 유저 룸 입장 저장
	 */
	const saveRoomUserIn = useMutation({
		mutationFn: (map: MetaRoomUserInCIVO) => MetaService.saveRoomUserIn(map),
	});

	/*
	 * saveRoomUserOut
	 * 메타버스 유저 룸 퇴장 저장
	 */
	const saveRoomUserOut = useMutation({
		mutationFn: (map: MetaRoomUserOutCIVO) => MetaService.saveRoomUserOut(map),
	});

	/*
	 * saveRoomChat
	 * 메타버스 룸 채팅 저장
	 */
	const saveRoomChat = useMutation({
		mutationFn: (map: MetaRoomChatCIVO) => MetaService.saveRoomChat(map),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getRoomChat'] });
			return data;
		},
	});

	/*
	 * getRoomChat
	 * 메타버스 룸 채팅 조회
	 */
	const getRoomChat = async (roomId: string) => {
		if (roomId != '') {
			return MetaService.getRoomChat(roomId);
		}
	};

	/*
	 * saveDisposeRoom
	 * 메타버스 룸 삭제 저장
	 */
	const saveDisposeRoom = useMutation({
		mutationFn: (map: MetaDisposeRoomCIVO) => MetaService.saveDisposeRoom(map),
		onSuccess: (data) => {
			queryClient.invalidateQueries({ queryKey: ['getUsableRoom'] });
			return data;
		},
	});

	return {
		// statusGetUsableRoom: getUsableRoom.status,
		// getUsableRoom: getUsableRoom.data,

		getRoomChat,

		saveRoomChat: saveRoomChat.mutate,
		statusSaveRoomChat: saveRoomChat.status,
		dataSaveRoomChat: saveRoomChat.data,

		saveCreateRoom: saveCreateRoom.mutate,
		statusSaveCreateRoom: saveCreateRoom.status,
		dataSaveCreateRoom: saveCreateRoom.data,

		saveRoomUserIn: saveRoomUserIn.mutate,
		statusSaveRoomUserIn: saveRoomUserIn.status,
		dataSaveRoomUserIn: saveRoomUserIn.data,

		saveRoomUserOut: saveRoomUserOut.mutate,
		statusSaveRoomUserOut: saveRoomUserOut.status,
		dataSaveRoomUserOut: saveRoomUserOut.data,

		saveDisposeRoom: saveDisposeRoom.mutate,
		statusSaveDisposeRoom: saveDisposeRoom.status,
		dataSaveDisposeRoom: saveDisposeRoom.data,
	};
};
