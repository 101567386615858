import {PostInfoCOVO} from "@/site/api";
import FeedImgView from "@dating/ui/pages/lounge/feedList/views/FeedImgView";
import FeedEditMenuView from "@dating/ui/components/feedEdit/views/FeedEditMenuView";

interface props {
    post: PostInfoCOVO;
    deleteContent: (id: string) => void;
    menuDotClickHandler: () => void,
    openMenuPop: boolean;
    sliderSettings: {};
    srcPrefix: string;
    fnSavePostLike: (postId: string, likeYn: boolean) => void;
    setModifyPostId: (postId: string) => void;
    fnCommonConfirmPopOn: (message: string, func: any, param: any) => any;
    fnFeedEditHandler: (postId: string) => void;
    feedEditPops: {};
}

const ProfileFeedView = ({
                             post,
                             deleteContent,
                             menuDotClickHandler,
                             openMenuPop,
                             sliderSettings,
                             srcPrefix,
                             fnSavePostLike,
                             setModifyPostId,
                             fnCommonConfirmPopOn,
                             fnFeedEditHandler,
                             feedEditPops,
                         }: props) => {

    return (
        <>
            <div className="feedBox">
                <FeedImgView
                    post={post}
                    sliderSettings={sliderSettings}
                    srcPrefix={srcPrefix}
                    fnSavePostLike={fnSavePostLike}
                />

                <div className="inner padding-t8">
                    <div className="profileType">
                        <div className="proLeft">
                            <div className="proImg Mid">
                                <img src={srcPrefix + post?.postUserProfileUrl} alt="테스트이미지"/>
                            </div>
                            <div className="proName">
                                <p className="proFz20">{post.postNickName}</p>
                            </div>
                        </div>
                        <div className="ProRight">
                            <div className="menuDotWap">
                                <button
                                    className="menuDot"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        // menuDotClickHandler();
                                        fnFeedEditHandler(post.postId);
                                    }}
                                />
                                <FeedEditMenuView
                                    post={post}
                                    deleteContent={deleteContent}
                                    openMenuPop={feedEditPops[post.postId]}
                                    setModifyPostId={setModifyPostId}
                                    fnCommonConfirmPopOn={fnCommonConfirmPopOn}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="protxt01">
                        <p className="fz14 color-666">
                            {post?.content}
                        </p>
                        <div className="tagList">
                            {
                                post.tag?.map((item, i) => {
                                    return <a key={item + i} href="#n">{item}</a>
                                })
                            }
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default ProfileFeedView;