import { create } from 'zustand';

interface FooterState {
	homeOpen: boolean;
	loungeOpen: boolean;
	likeOpen: boolean;
	metaOpen: boolean;

	setHomeOpen: (homeOpen: boolean) => void;
	setLoungeOpen: (loungeOpen: boolean) => void;
	setLikeOpen: (likeOpen: boolean) => void;
	setMetaOpen: (metaOpen: boolean) => void;
}

export const useFooterRepo = create<FooterState>((set) => ({
	homeOpen: true,
	loungeOpen: false,
	likeOpen: false,
	metaOpen: false,

	setHomeOpen: (homeOpen: boolean) => set({ homeOpen }),
	setLoungeOpen: (loungeOpen: boolean) => set({ loungeOpen }),
	setLikeOpen: (likeOpen: boolean) => set({ likeOpen }),
	setMetaOpen: (metaOpen: boolean) => set({ metaOpen }),
}));
