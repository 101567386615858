import { Key } from 'react';

const WomenListView = ({ headCount, players, srcPrefix }) => {
	const maxWomenCount = headCount === 4 ? 2 : 3;
	const womenPlayers = players.filter(
		(player: { gender: string }) => player.gender === 'F',
	);

	return (
		<div className="womenList">
			{womenPlayers.map(
				(profile: { uuid: Key | null | undefined; profileUrl: any }) => (
					<div key={profile.uuid}>
						<img src={srcPrefix + profile.profileUrl} alt="Profile" />
					</div>
				),
			)}

			{[...Array(maxWomenCount - womenPlayers.length)].map((_, index) => (
				<div key={`empty-${index}`}></div>
			))}
		</div>
	);
};

export default WomenListView;
