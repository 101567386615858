import StarBadgeWrapView from './StarBadgeWrapView';
import StepLastBoxView from './StepLastBoxView';
import AuthAddWrapView from './AuthAddWrapView';
import ProfileFeedView from './ProfileFeedView';
import { PostInfoCOVO, UserProfileCOVO } from '@/site/api';
import ProfileFeedHeader from '@dating/ui/pages/profile/views/ProfileFeedHeader';
import React from "react";

interface props {
    posts: PostInfoCOVO[] | undefined;
    openMenuPop: boolean;
    deleteContent: (id: string) => void;
    menuDotClickHandler: () => void;
    sliderSettings: {};
    srcPrefix: string;
    profile: UserProfileCOVO;
    profileDetails: {key: string; title: string; value: string | number}[];
    fnSavePostLike: (postId: string, likeYn: boolean) => void;
    setModifyPostId: (postId: string) => void;
    fnCommonConfirmPopOn: (message: string, func: any, param: any) => any;
    starBoxHide: boolean;
    isChecked: number | null;
    setIsChecked: (isChecked: number) => void;
    evalHandler: (userUid: string, score: number) => void;
    fnFeedEditHandler: (postId: string) => void;
    feedEditPops: {};
    isClickedFeedMenu: {postId: string, state: boolean};
}

const ProInfo02View = ({
                           posts,
                           openMenuPop,
                           deleteContent,
                           menuDotClickHandler,
                           sliderSettings,
                           srcPrefix,
                           profile,
                           profileDetails,
                           fnSavePostLike,
                           setModifyPostId,
                           fnCommonConfirmPopOn,
                           starBoxHide,
                           isChecked,
                           setIsChecked,
                           evalHandler,
                           fnFeedEditHandler,
                           feedEditPops,
                           isClickedFeedMenu,
                       }: props) => {
    return (
        <div
            className="proInfo02"
            style={{
                overflow: isClickedFeedMenu.state ? 'hidden' : 'auto',
            }}
        >
            <div className="inner">
                <div className="text-c">
                    <p className="fz16 color-999">{profile.introduce}</p>
                </div>
                <StarBadgeWrapView profile={profile}/>

                {
                    !starBoxHide ?
                        (
                            <>
                            <div className='starWarp margin-b20'>
                                <div className="starBox">
                                    {/*별점평가*/}
                                    {[5, 4, 3, 2, 1].map((value, i) => {
                                        const id = 'star' + value.toString() + i;
                                        return (
                                            <React.Fragment key={id}>
                                                <input
                                                    type="radio"
                                                    name="rating"
                                                    id={id}
                                                    value={value}
                                                    checked={isChecked === value ? true : false}
                                                    onChange={(e) => {
                                                        // if (isChecked) {
                                                        //     e.preventDefault();
                                                        // } else {
                                                            setIsChecked(value);
                                                            evalHandler(profile.userUid, Number(e.target.value));
                                                        // }
                                                    }}
                                                />
                                                <label htmlFor={id}/>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                                <p className="fz12 color-666 text-c margin-t8">
                                    호감도를 표현하여 상대방에게 자신을 어필해 보세요
                                </p>
                            </div>
                            </>
                        ) :
                        null
                }

            </div>
            <StepLastBoxView profileDetails={profileDetails}/>
            <AuthAddWrapView/>

            {profile && profile.nickName && posts && posts.length > 0 ? (
                <>
                    <ProfileFeedHeader nickName={profile.nickName}/>
                    {/*feedBox가 반복*/}
                    {posts != undefined && typeof posts === 'object' && posts.length > 0
                        ? posts.map((post, i) => {
                            return (
                                <ProfileFeedView
                                    key={post.postId}
                                    post={post}
                                    deleteContent={deleteContent}
                                    menuDotClickHandler={menuDotClickHandler}
                                    openMenuPop={openMenuPop}
                                    sliderSettings={sliderSettings}
                                    srcPrefix={srcPrefix}
                                    fnSavePostLike={fnSavePostLike}
                                    setModifyPostId={setModifyPostId}
                                    fnCommonConfirmPopOn={fnCommonConfirmPopOn}
                                    fnFeedEditHandler={fnFeedEditHandler}
                                    feedEditPops={feedEditPops}
                                />
                            );
                        })
                        : null}
                    {/*{posts.length > 4 ? (*/}
                    {/*    <div className="padding-b8 text-c">*/}
                    {/*        <a href="#n" className="color-999 underline bold-500">*/}
                    {/*            더보기*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*) : null}*/}
                </>
            ) : null}
        </div>
    );
};

export default ProInfo02View;
