interface props {
    feedFilterOpen: boolean;
    checkedFilter: string;
    setCheckedFilter: (checkedFilter: string) => void;
    setFeedFilterOpen: (feedFilterOpen: boolean) => void;
    feedFilterList: { label: string, value: string }[];
    fnGetPosts: () => void;
    fnGetPostsByGender: (gender: string) => void;
    setFadeIn: (fadeIn: boolean) => void;
}

export const FeedFilterView = ({
                                   feedFilterOpen,
                                   setFeedFilterOpen,
                                   checkedFilter,
                                   setCheckedFilter,
                                   feedFilterList,
                                   fnGetPosts,
                                   fnGetPostsByGender,
                                   setFadeIn,
                               }: props) => {
    return (
        <>
            <div className={`topPopWrap ${feedFilterOpen ? "on" : ""}`}>
                <div className="topPopDim"
                     onClick={() => {
                         setFeedFilterOpen(false);
                     }}
                ></div>
                <div className="topPopInfo">
                    <div className="popRadioList">
                        {
                            feedFilterList.map((item) => {
                                return (
                                    <div key={item.value}>
                                        <label htmlFor={"radioBtn" + item.value}>{item.label}</label>
                                        <input
                                            id={"radioBtn" + item.value}
                                            className="radioBtn"
                                            name="checkbox-group"
                                            type="radio"
                                            value={item.value}
                                            checked={checkedFilter === item.value ? true : false}
                                            onChange={() => {
                                                setCheckedFilter(item.value);
                                                setFadeIn(false);

                                                if (item.value === "A") {
                                                    fnGetPosts();
                                                } else {
                                                    fnGetPostsByGender(item.value);
                                                }
                                            }}
                                        />
                                    </div>
                                );
                            })
                        }
                    </div>
                    <p className="topPopTit">
                        <span></span>
                    </p>
                </div>
            </div>
        </>
    );
};