import {useEffect} from 'react';
import {type UserHeightCIVO} from "@/site/api";
import {useRegisterAdapter} from "@dating/adapter/register/useRegisterAdapter";
import {useHeightStateRepo} from "@dating/ui/pages/register/height/useHeightStateRepo";
import {useRegisterRepo} from "@dating/repository/register/useRegisterRepo";

export const useHeightService = () => {
    const {
        activeIndex,
        setActiveIndex,
        btnActivate,
        setBtnActivate,
        response,
        setResponse,
    } = useHeightStateRepo();

    const {
        setStep,
        registerSteps,
        inputHeight,
        setInputHeight,
    } = useRegisterRepo();

    const {
        saveHeight,
        statusSaveHeight,
        dataSaveHeight,
    } = useRegisterAdapter();

    const fnSaveHeight = () => {
        let height: string = inputHeight.join("");
        const map: UserHeightCIVO = {
            height: Number(height)
        }
        saveHeight(map);
    };

    const handleInputChange = (index: number, value: string) => {
        if (/^\d$/.test(value)) {
            const newInputValues = [...inputHeight];
            newInputValues[index] = value;
            setInputHeight(newInputValues);

            if (index < inputHeight.length - 1 && value !== '') {
                setActiveIndex(index + 1);
            }
        }
    };

    const handleInputKeyDown = (index: number, key: string) => {
        if (key === 'Backspace' || key === 'Delete') {
            const newInputValues = [...inputHeight];
            newInputValues[index] = "";
            setInputHeight(newInputValues);
            setActiveIndex(index - 1);
        }

        if (key === 'ArrowLeft' && index > 0) {
            setActiveIndex(index - 1);
        } else if (key === 'ArrowRight' && index < inputHeight.length - 1) {
            setActiveIndex(index + 1);
        }
    };

    useEffect(() => {
        if (statusSaveHeight === "success" && dataSaveHeight) {
            if (dataSaveHeight.result) {
                setStep(5);
                setResponse(true);
            } else {
                alert(dataSaveHeight.resultCode);
            }
        } else if (statusSaveHeight === "error") {

        }
    }, [statusSaveHeight, dataSaveHeight]);

    useEffect(() => {
        if (inputHeight.indexOf('') == -1) {
            setBtnActivate(false);
        } else {
            setBtnActivate(true);
        }
    }, [inputHeight]);

    return {
        inputHeight,
        setInputHeight,
        fnSaveHeight,
        handleInputChange,
        handleInputKeyDown,
        btnActivate,
        setBtnActivate,
        activeIndex,
        response,
        registerSteps,
    };
};
